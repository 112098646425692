import { convertServerUTCDateStrToLocalDate, RootState, useAppSelector } from 'common';
import { ExpiredOrderDialog } from './ExpiredOrderDialog';
import { ExpiringOrderToast } from './ExpiringOrderToast';

export const EditOrderNotifications = (): JSX.Element => {
  const order = useAppSelector((s: RootState) => s.orders.order);
  const expirationDate = convertServerUTCDateStrToLocalDate(order?.ExpirationDate);

  return expirationDate ? (
    <>
      <ExpiringOrderToast expirationDate={expirationDate} />
      <ExpiredOrderDialog expirationDate={expirationDate} />
    </>
  ) : (
    <></>
  );
};
