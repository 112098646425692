import { AnyAction } from 'redux';
import { AppDispatch, AppThunk } from '../../store';

/**
 * QueueHelper utility is used to queue sequential API calls
 * like `SequenceProductListDetail`. This can be used anywhere
 * in the app wherever we need sequential execution instead of
 * parallel execution of API.
 *
 * How to use QueueHelper?
 * Please use enqueue to queue an API. While enqueuing start
 * the API processing as well. Rest of processing will be taken
 * care by QueueHelper.
 *
 * TODO: error handling of enqueued API calls, notification handling
 */
class QueueHelper<T> {
  private static classInstance: QueueHelper<AppThunk>;
  private items: T[];
  public isProcessing = false;

  // initializing queue
  private constructor() {
    this.items = [];
  }

  // Method to add an item to the queue
  enqueue(item: T): void {
    this.items.push(item);
  }

  // Method to remove an item from the queue
  dequeue(): T | string | AnyAction {
    if (this.isEmpty()) {
      return 'Queue is empty';
    }
    return this.items.shift() as T;
  }

  // Method to check if the queue is empty
  isEmpty(): boolean {
    return this.items.length === 0;
  }

  // Method to view the first item in the queue without removing it
  peek(): T | string {
    if (this.isEmpty()) {
      return 'Queue is empty';
    }
    return this.items[0];
  }

  // Method to get the size of the queue
  size(): number {
    return this.items.length;
  }

  empty(): void {
    this.items = [];
  }

  // Method to process queue items
  async process(dispatch: AppDispatch): Promise<void> {
    if (!this.isProcessing) {
      this.isProcessing = true;
      while (this.size() > 0) {
        const item = this.dequeue();
        if (typeof item === 'string') {
          return;
        } else {
          await dispatch((await item) as AnyAction);
        }
      }
    }
    if (this.isEmpty()) {
      this.isProcessing = false;
    }
  }

  public static getInstance(): QueueHelper<AppThunk> {
    if (!this.classInstance) {
      this.classInstance = new QueueHelper();
    }
    return this.classInstance;
  }
}

export default QueueHelper;
