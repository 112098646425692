import { AppRoute, AppRoutes, RouteName } from '../../models';

export const replaceParams = (routeName: RouteName, paramVals: string[]): string => {
  let p = AppRoutes[routeName].Path;
  const pp = AppRoutes[routeName].Params;
  pp?.forEach((param, index) => (p = p.replace(param, paramVals[index])));
  return p;
};

export const getQueryParamsString = (
  obj: string | Record<string, string> | URLSearchParams | string[][] | undefined
): string => {
  const result = new URLSearchParams(obj).toString();
  if (!result) return '';
  return '?' + result;
};

export const getQueryStringByKey = (key: string): string => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  return params[key] || '';
};

export const getAppRouteUrl = (route: AppRoute): string => {
  const path = route.Path;
  const params = '/' + route.Params?.join('/');
  const query = getQueryParamsString(route.QueryParams);
  return path + params + query;
};
