import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { FileResult } from '../../api/models/api-shared.models';
import {
  GetInvoiceDocumentRequest,
  GetInvoiceExportRequest,
  GetInvoiceHeaderRequest,
  GetInvoiceHeadersRequest,
  GetInvoiceProofOfDeliveryDocumentRequest,
  InvoiceExportCheckBox,
  InvoiceHeader,
} from '../../api/models/invoice.models';
import InvoiceService from '../../api/services/invoice.service';
import { toUTC } from '../../helpers';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { globalSlice } from '../common/global.slice';
import { setErrorDialogContent, setInformationDialogContent } from '../common/global.thunks';
import { AppDispatch, AppThunk, RootState } from '../store';
import { invoiceSlice } from './invoice.slice';

const invoiceService = InvoiceService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Resets the values in the invoice slice to their initial values
 *
 * @returns NULL
 */
export const resetInvoiceState = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(invoiceSlice.actions.resetState());
};

/**
 * Calls and stores the result of the GetInvoiceHeaders API call
 *
 * @param request - Stores customer ids, local start and end dates for usage in making the API call
 * @param onFailure - Method to call on failure to provide the request data
 * @returns NULL
 */
export const getInvoiceHeaders =
  (
    request: { customerIds: string[]; localStartDate: Date | string; localEndDate: Date | string },
    onFailure?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (request.customerIds.length === 0 || !request.localStartDate || !request.localEndDate) return onFailure?.();

      const apiRequest: GetInvoiceHeadersRequest = {
        Customers: request.customerIds,
        StartDate: toUTC(request.localStartDate),
        EndDate: toUTC(request.localEndDate),
      };

      // Data in store is for search with different parameters. Data must be serialized (dates are not).
      const stateRequest = getState().invoice.getInvoiceHeadersRequest;
      const apiRequestToCompareAndSave = {
        ...apiRequest,
        StartDate: apiRequest.StartDate.toString(),
        EndDate: apiRequest.EndDate.toString(),
      };

      if (JSON.stringify(apiRequestToCompareAndSave) !== JSON.stringify(stateRequest)) {
        dispatch(invoiceSlice.actions.resetInvoiceHeaders());
      } else {
        return;
      }

      dispatch(setSelectedInvoiceHeaderKeys([]));
      dispatch(invoiceSlice.actions.setInvoiceHeadersLoading(true));

      const { data } = await invoiceService.getInvoiceHeaders(apiRequest);
      if (data.IsSuccess) {
        dispatch(
          invoiceSlice.actions.setInvoiceHeaders({ request: apiRequestToCompareAndSave, result: data.ResultObject })
        );
        if (data.InformationMessages?.length > 0) {
          dispatch(setInformationDialogContent('Notice', data.InformationMessages));
        }
      } else {
        if (data.ErrorMessages.length > 0) {
          dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
        }
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(invoiceSlice.actions.setInvoiceHeadersLoading(false));
    }
  };

/**
 * Sets the selectedInvoiceHeaderKeys value in the invoice slice to be the invoice header ids
 * @returns NULL
 */
export const setAllSelectedInvoiceHeaderKeys = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(invoiceSlice.actions.setAllSelectedInvoiceHeaderKeys());
};

/**
 * Sets the selectedInvoiceHeaderKeys value in the invoice slice
 *
 * @param selectedInvoiceHeaderKeys - Array of the invoice header keys to set selectedInvoiceHeaderKeys to
 * @returns NULL
 */
export const setSelectedInvoiceHeaderKeys =
  (selectedInvoiceHeaderKeys: string[]): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(invoiceSlice.actions.setSelectedInvoiceHeaderKeys(selectedInvoiceHeaderKeys));
  };

/**
 * Sets the selectedInvoiceCustomers value in the invoice slice
 *
 * @param customerIds - Array of the customer ids to set selectedInvoiceCustomers to
 * @param callback - Method to call once this method completes
 * @returns NULL
 */
export const setSelectedInvoiceCustomers =
  (customerIds: string[], callback?: () => void): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(invoiceSlice.actions.setSelectedInvoiceCustomers(customerIds));
    callback?.();
  };

export const getInvoiceCustomers = (): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    dispatch(invoiceSlice.actions.setInvoiceCustomersLoading(true));

    const invoiceCustomers = getState().invoice.invoiceCustomers;
    if (invoiceCustomers && invoiceCustomers.length > 0) return;

    const { data } = await invoiceService.getInvoiceCustomers();
    if (data.IsSuccess) {
      dispatch(invoiceSlice.actions.setInvoiceCustomers(data.ResultObject));
    } else {
      dispatch(
        globalSlice.actions.setErrorDialogContent({
          messages: data.ErrorMessages,
        })
      );
    }
  } catch (error) {
    appInsightsLogger.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
  } finally {
    dispatch(invoiceSlice.actions.setInvoiceCustomersLoading(false));
  }
};

/**
 * Calls and stores the result of the GetInvoiceHeader API call
 *
 * @param request - The request to use in the API call
 * @param successCallback - Method to call on success
 * @returns NULL
 */
export const getInvoiceHeader =
  (request: GetInvoiceHeaderRequest, successCallback?: (header: InvoiceHeader) => void | Promise<void>): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await invoiceService.getInvoiceHeader(request);
      if (data.IsSuccess) {
        if (successCallback) successCallback(data.ResultObject);
      } else {
        dispatch(
          globalSlice.actions.setErrorDialogContent({
            messages: data.ErrorMessages,
          })
        );
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls the GetInvoiceDocument API call
 *
 * @param invoiceHeader - Invoice header to make the request from
 * @param onFileDownloadSuccess - Method to call on success to download
 * @param onFileDownloadFailure - Method to call on failure to download
 * @returns NULL
 */
export const getInvoiceDocument =
  (
    invoiceHeader: InvoiceHeader,
    onFileDownloadSuccess: (file: FileResult | undefined) => void,
    onFileDownloadFailure?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const request: GetInvoiceDocumentRequest = {
        InvoiceHeaderBusinessUnitERPKey: invoiceHeader.InvoiceHeaderBusinessUnitERPKey,
        InvoiceHeaderKey: invoiceHeader.InvoiceHeaderKey,
        OperationCompanyNumber: invoiceHeader.OperationCompanyNumber,
      };

      dispatch(invoiceSlice.actions.setInvoiceDocumentLoading(true));
      const { data } = await invoiceService.getInvoiceDocument(request);
      if (data.IsSuccess) {
        onFileDownloadSuccess(data.ResultObject);
      } else {
        onFileDownloadFailure?.();
        dispatch(
          globalSlice.actions.setErrorDialogContent({
            title: 'File download error',
            messages: data.ErrorMessages,
          })
        );
      }
    } catch (error) {
      onFileDownloadFailure?.();
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(invoiceSlice.actions.setInvoiceDocumentLoading(false));
    }
  };

/**
 * Calls and stores the result of the GetInvoiceProofOfDeliveryDocument API call
 *
 * @param invoiceHeader - Invoice header to make the request from
 * @param onFileDownloadSuccess - Method to call on success to download
 * @param onFileDownloadFailure - Method to call on failure to download
 * @returns NULL
 */
export const getInvoiceProofOfDeliveryDocument =
  (
    invoiceHeader: InvoiceHeader,
    onFileDownloadSuccess: (file: FileResult | undefined, fileUrl?: string) => void,
    onFileDownloadFailure?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const request: GetInvoiceProofOfDeliveryDocumentRequest = {
        InvoiceHeaderBusinessUnitERPKey: invoiceHeader.InvoiceHeaderBusinessUnitERPKey,
        InvoiceHeaderKey: invoiceHeader.InvoiceHeaderKey,
        OperationCompanyNumber: invoiceHeader.OperationCompanyNumber,
      };

      dispatch(invoiceSlice.actions.setInvoiceDocumentLoading(true));

      const { data } = await invoiceService.getInvoiceProofOfDeliveryDocument(request);
      if (data.IsSuccess) {
        const result = data.ResultObject;
        onFileDownloadSuccess(result.File, result.FileURL);
      } else {
        onFileDownloadFailure?.();
        dispatch(
          globalSlice.actions.setErrorDialogContent({
            title: 'File download error',
            messages: data.ErrorMessages,
          })
        );
      }
    } catch (error) {
      onFileDownloadFailure?.();
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(invoiceSlice.actions.setInvoiceDocumentLoading(false));
    }
  };

/**
 * Sets the invoiceLabel value in the invoice slice
 *
 * @param invoiceLabel - The value to set the invoiceLabel to
 * @returns NULL
 */
export const setInvoiceLabel =
  (invoiceLabel: string[]): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(invoiceSlice.actions.setInvoiceLabel(invoiceLabel));
  };

/**
 * Sets the resetInvoiceExport value in the invoice slice
 *
 * @param reset - The value to set the resetInvoiceExport to
 * @returns NULL
 */
export const resetInvoiceExport =
  (reset: boolean): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(invoiceSlice.actions.resetInvoiceExport(reset));
  };

/**
 * Sets the invoiceExportOrderedList value in the invoice slice
 *
 * @param invoice - The value to set the invoiceExportOrderedList to
 * @returns NULL
 */
export const setInvoiceExportOrderedList =
  (invoice: InvoiceExportCheckBox[]): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(invoiceSlice.actions.setInvoiceExportOrderedList(invoice));
  };

/**
 * Calls the GetInvoiceExport API call
 *
 * @param request - The request used to make the API call
 * @param onStatementDownloadSuccess - Method to call on download success
 * @returns NULL
 */
export const getInvoiceExport =
  (request: GetInvoiceExportRequest, onStatementDownloadSuccess: (file: FileResult) => void): AppThunk<Promise<void>> =>
  async (): Promise<void> => {
    try {
      const { data } = await invoiceService.getInvoiceExport(request);

      if (data.IsSuccess) {
        onStatementDownloadSuccess(data.ResultObject);
      }
      // [TODO]: Handle state if IsSuccess === false
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        //   severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    }
  };

/**
 * Calls the GetInvoiceExportOptions API call
 *
 * @returns NULL
 */
export const getInvoiceExportOptions = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    const { data } = await invoiceService.getInvoiceExportOptions();

    if (data.IsSuccess) {
      dispatch(invoiceSlice.actions.setInvoiceExportOptions(data.ResultObject));
    }
    // [TODO]: Handle state if IsSuccess === false
  } catch (error: unknown) {
    appInsightsLogger.trackException({
      exception: error,
      //   severityLevel: SeverityLevel.Error,
    });
    // [TODO]: Handle state if an error occurs here
  }
};

/**
 * Resets the invoiceExportOptions in the invoice slice to its initial value
 *
 * @returns NULL
 */
export const resetInvoiceExportOptions = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(invoiceSlice.actions.resetInvoiceExportOptions());
};
