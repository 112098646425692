import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { ProductListHeaderCustom } from '../../api/models/product-list-header.models';
import {
  ExtendedProductListNotification,
  ProductListNotification,
  ProductListNotificationGroup,
  ProductListNotificationTotal,
} from '../../api/models/product-list-notification.models';
import { normalizeKey } from '../../helpers/general/string';
import { RootState } from '../store';

// Adapters
const productListNotificationAdapter = createEntityAdapter<ExtendedProductListNotification>({
  selectId: (p: ExtendedProductListNotification) => normalizeKey(p.ProductListNotificationId),
});

// State
interface ProductListNotificationState {
  currentSelectedCustomerId: string;
  productListNotifications: EntityState<ExtendedProductListNotification>;
  notificationProductListHeaders: ProductListHeaderCustom[];
  productListNotificationTotalInfo: ProductListNotificationTotal | undefined;
  productListNotificationsLoading: boolean;
}

const initialState: ProductListNotificationState = {
  currentSelectedCustomerId: '',
  productListNotifications: productListNotificationAdapter.getInitialState(),
  notificationProductListHeaders: [],
  productListNotificationTotalInfo: undefined,
  productListNotificationsLoading: false,
};

// Reducers
export const productListNotificationSlice = createSlice({
  name: 'productListNotification',
  initialState: initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    setProductListNotificationGroups: (
      state: ProductListNotificationState,
      action: PayloadAction<ProductListNotificationGroup[]>
    ) => {
      const items: ExtendedProductListNotification[] = action.payload
        .map((p: ProductListNotificationGroup) => {
          return p.Notifications.map((n: ProductListNotification) => {
            const item: ExtendedProductListNotification = {
              IsActionNeeded: p.IsActionNeeded,
              NotificationType: p.NotificationType,
              ProductListNotificationId: n.ProductListNotificationId,
              ActionType: n.ActionType,
              ActionDate: n.ActionDate,
              Product: n.Product,
              ReplacedWithProduct: n.ReplacedWithProduct,
              ProductListTitle: n.ProductListTitle,
              CanViewOnly: n.CanViewOnly,
            };
            return item;
          });
        })
        .flat();
      productListNotificationAdapter.setAll(state.productListNotifications, items);
    },
    removeProductListNotifications: (state: ProductListNotificationState, action: PayloadAction<string[]>) => {
      productListNotificationAdapter.removeMany(state.productListNotifications, action.payload);
    },
    setProductListNotificationsLoading: (state: ProductListNotificationState, action: PayloadAction<boolean>) => {
      state.productListNotificationsLoading = action.payload;
    },
    setCurrentSelectedCustomer: (state: ProductListNotificationState, action: PayloadAction<string>) => {
      state.currentSelectedCustomerId = action.payload;
    },
    setNotificationProductListHeaders: (
      state: ProductListNotificationState,
      action: PayloadAction<ProductListHeaderCustom[]>
    ) => {
      state.notificationProductListHeaders = action.payload;
    },
    setProductListNotificationTotalInfo: (
      state: ProductListNotificationState,
      action: PayloadAction<ProductListNotificationTotal>
    ) => {
      state.productListNotificationTotalInfo = action.payload;
    },
  },
});

// Selectors
export const {
  selectAll: selectAllProductListNotifications,
  selectById: selectProductListNotificationById,
  selectIds: selectAllProductListNotificationIds,
} = productListNotificationAdapter.getSelectors<RootState>(
  (state: RootState) => state.productListNotification.productListNotifications
);

export const selectAllActionNeededProductListNotifications = createSelector([selectAllProductListNotifications], (n) =>
  n.filter((x) => x.IsActionNeeded)
);

export const selectAllActionNeededProductListNotificationIds = createSelector(
  [selectAllActionNeededProductListNotifications],
  (n) => n.map((x) => x.ProductListNotificationId)
);

export const selectHasActionNeededProductListNotifications = createSelector(
  selectAllActionNeededProductListNotifications,
  (n) => n.length > 0
);

export const selectActionNeededProductListNotificationCount = createSelector(
  [selectAllActionNeededProductListNotifications],
  (n) => n.length
);

export const selectAllAttentionRequestedProductListNotifications = createSelector(
  [selectAllProductListNotifications],
  (n) => n.filter((x) => !x.IsActionNeeded)
);

export const selectAllAttentionRequestedProductListNotificationIds = createSelector(
  [selectAllAttentionRequestedProductListNotifications],
  (n) => n.map((x) => x.ProductListNotificationId)
);

export const selectAllNonViewOnlyAttentionRequestedProductListNotificationIds = createSelector(
  [selectAllAttentionRequestedProductListNotifications],
  (n) => n.filter((x) => !x.CanViewOnly).map((y) => y.ProductListNotificationId)
);

export const selectHasAttentionRequestedProductListNotifications = createSelector(
  selectAllAttentionRequestedProductListNotifications,
  (n) => n.length > 0
);

export const selectAttentionRequestedProductListNotificationCount = createSelector(
  [selectAllAttentionRequestedProductListNotifications],
  (n) => n.length
);
