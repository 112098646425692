import { Grid, Typography } from '@mui/material';
import { RootState, selectUserPermissionSetting, toCurrencyString, useAppSelector } from 'common';
import TypographySkeleton from '../../../../_shared/components/TypographySkeleton';

export const OrderSummaryTotals = (): JSX.Element => {
  const orderEntryHeader = useAppSelector((x: RootState) => x.orders.order);
  const orderDetailsUpdating = useAppSelector((s: RootState) => s.orders.orderDetailsUpdating);
  const canViewPrice = useAppSelector((s: RootState) => selectUserPermissionSetting(s).CanViewPrices);
  return (
    <>
      <Grid container item md={12} justifyContent='space-between'>
        <Typography variant='responsiveParagraph' data-testid='summary-total-quantity-label'>
          Quantity:
        </Typography>
        {orderEntryHeader && !orderDetailsUpdating ? (
          <Typography variant='responsiveParagraph' noWrap data-testid='summary-total-quantity'>
            {orderEntryHeader.TotalQuantity.toLocaleString()}
          </Typography>
        ) : (
          <TypographySkeleton variant='responsiveParagraph' charCount={3} />
        )}
      </Grid>
      <Grid container item md={12} justifyContent='space-between'>
        <Typography variant='responsiveParagraph' data-testid='summary-total-line-items-label'>
          Line items:
        </Typography>
        {orderEntryHeader && !orderDetailsUpdating ? (
          <Typography variant='responsiveParagraph' noWrap data-testid='summary-total-lines'>
            {orderEntryHeader.TotalLines}
          </Typography>
        ) : (
          <TypographySkeleton variant='responsiveParagraph' charCount={3} />
        )}
      </Grid>

      {canViewPrice && (
        <Grid container item md={12} justifyContent='space-between'>
          <Typography variant='responsiveParagraph' data-testid='summary-extended-price-label'>
            Item subtotal:
          </Typography>
          {orderEntryHeader && !orderDetailsUpdating ? (
            <Typography variant='responsiveParagraph' noWrap data-testid='summary-extended-price'>
              {toCurrencyString(orderEntryHeader.TotalExtendedPrice, 2)}
            </Typography>
          ) : (
            <TypographySkeleton variant='responsiveParagraph' charCount={6} />
          )}
        </Grid>
      )}
      {canViewPrice && (
        <Grid container item md={12} justifyContent='space-between' sx={{ mt: (theme) => theme.spacing(0.5) }}>
          <Typography variant='responsiveParagraphBold' data-testid='summary-total-price-label'>
            Total:
          </Typography>
          {orderEntryHeader && !orderDetailsUpdating ? (
            <Typography variant='responsiveParagraphBold' noWrap data-testid='summary-total-price'>
              {toCurrencyString(orderEntryHeader.TotalOrderPrice, 2)}
            </Typography>
          ) : (
            <TypographySkeleton variant='responsiveParagraphBold' charCount={6} />
          )}
        </Grid>
      )}
    </>
  );
};
