import { TypographyPropsVariantOverrides } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { SxProps, Theme } from '@mui/system';
import { OverridableStringUnion } from '@mui/types';
import {
  AppNotification,
  NotificationKeys,
  RootState,
  updateOrderEntryHeaderPurchaseOrderNumber,
  useAppDispatch,
  useAppSelector,
} from 'common';
import { FC, useState } from 'react';
import CfEditableLabel from '../../../../../cf-ui/EditableLabel/CfEditableLabel';
import { DeviceType } from '../../../../../hooks/useBreakpoint';

export type EditablePurchaseOrderNumberProps = {
  deviceType: DeviceType;
  variant: OverridableStringUnion<Variant, TypographyPropsVariantOverrides>;
  labelVariant?: OverridableStringUnion<Variant, TypographyPropsVariantOverrides>;
  buttonContainerSx?: SxProps<Theme>;
  testId?: string;
};

export const EditablePurchaseOrderNumber: FC<EditablePurchaseOrderNumberProps> = (
  props: EditablePurchaseOrderNumberProps
): JSX.Element => {
  const dispatch = useAppDispatch();

  const requiredPONotification = useAppSelector((x: RootState) =>
    x.global.notifications.some((n: AppNotification) => n.Key === NotificationKeys.PORequired)
  );
  const orderEntryHeader = useAppSelector((x: RootState) => x.orders.order);
  const orderEntryPONumber = orderEntryHeader?.PurchaseOrderNumber ?? '';

  const [editMode, setEditMode] = useState<boolean>(false);
  const [refreshValue, setRefreshValue] = useState<boolean>(true);

  const onPONumberBlur = (value: string) => {
    const changed = orderEntryPONumber !== value;
    if (changed) {
      dispatch(
        updateOrderEntryHeaderPurchaseOrderNumber(value ?? '', (isSuccess: boolean) => {
          if (!isSuccess) setRefreshValue(!refreshValue);
        })
      );
    }
    setEditMode(false);
  };

  return (
    <CfEditableLabel
      isAutoFocused={true}
      buttonContainerSx={{ mb: 1, ...props.buttonContainerSx }}
      containerSx={props.deviceType === DeviceType.Tablet ? { width: 332 } : {}}
      data-testid='summary-editable-po-label'
      direction={props.deviceType === DeviceType.Tablet ? 'row' : 'column'}
      isEditMode={editMode}
      iconSx={requiredPONotification ? { color: (theme) => theme.palette.error.main } : undefined}
      inputDataTestId='po-num-text'
      isOptional
      label='PO #:'
      labelName='PO #'
      labelSx={{ ...(requiredPONotification && { color: (theme) => theme.palette.error.main }), whiteSpace: 'nowrap' }}
      labelVariant={props.labelVariant}
      isLoading={!orderEntryHeader}
      maxLength={50}
      onEdit={() => setEditMode(true)}
      onClose={() => setEditMode(false)}
      onSubmit={onPONumberBlur}
      forceReset={refreshValue}
      size={props.deviceType !== DeviceType.Desktop ? 'medium' : 'small'}
      skeletonCharCount={15}
      value={orderEntryPONumber}
      variant={props.variant}
      labelDataTestId={`${props.testId}-editable-label`}
      buttonDataTestId={`${props.testId}-button`}
      inputAltDataTestId={`${props.testId}-input-alt`}
    />
  );
};
