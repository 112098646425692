export const getPage = <TItem>(items: TItem[], pageIndex: number, pageSize: number): TItem[] => {
  const startIndex = pageIndex * pageSize;
  const endIndex = Math.min(startIndex + pageSize, items.length);
  return items.slice(startIndex, endIndex);
};

export const getTotalPageCount = <TItem>(items: TItem[], pageSize: number): number => {
  if (pageSize === 0) return 0;
  return Math.ceil(items.length / pageSize);
};

export const takeUntil = <TItem>(items: TItem[], predicate: (item: TItem, index: number) => boolean): TItem[] => {
  for (const [index, item] of items.entries()) if (predicate(item, index)) return items.slice(0, index);
  return items;
};

export const getChunks = <TItem>(items: TItem[], chunkSize: number): TItem[][] => {
  if (chunkSize <= 0) return [];
  const result: TItem[][] = [];
  for (let i = 0; i < items.length; i += chunkSize) {
    const chunk = items.slice(i, i + chunkSize);
    if (chunk) result.push(chunk);
  }
  return result;
};

export const groupBy = <T, K>(list: T[], keyGetter: (item: T) => K): Map<K, T[]> => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

export const arraysEqual = <T>(a: T[], b: T[]): boolean => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const getSortComparer =
  <T, K>(keyGetter: (item: T) => K, descending?: boolean): ((a: T, b: T) => number) =>
  (a, b) => {
    const factor = descending ? -1 : 1;
    const keyA = keyGetter(a);
    const keyB = keyGetter(b);
    if (typeof keyA === 'string' && typeof keyB === 'string') {
      return keyA.localeCompare(keyB);
    }

    if (keyA < keyB) return -1 * factor;
    if (keyA > keyB) return 1 * factor;
    return 0;
  };

export const sortBy = <T, K>(list: T[], keyGetter: (item: T) => K): T[] => {
  return list.slice().sort(getSortComparer(keyGetter));
};

export const sortByDesc = <T, K>(list: T[], keyGetter: (item: T) => K): T[] => {
  return list.slice().sort(getSortComparer(keyGetter, true));
};
