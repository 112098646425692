import { EntityId } from '@reduxjs/toolkit';
import { ProductListCategory } from '../../api/models/product-list-category.models';
import { ProductListDetail } from '../../api/models/product-list-detail.models';
import {
  ProductListCategoryData,
  ProductListProduct,
  ProductListSearchFieldType,
  SearchProductListCatalogFieldRequest,
} from '../../api/models/product-list-search.models';
import {
  ProductListCategoryCustom,
  ProductListCategoryProduct,
  ProductListGridData,
} from '../../models/product-list.models';
import { ProductListState } from '../../store/product-list-management';
import { normalizeProductKey } from './product';
import { normalizeKey } from './string';
import { generateId } from './system';

interface ProductListGridDataResult {
  categories: ProductListCategoryCustom[];
  categoryProducts: ProductListCategoryProduct[];
  products: ProductListProduct[];
}

// Keys
export const getCategoryProductId = (categoryId: string, productKey: string | undefined): string => {
  return `${categoryId}-${normalizeProductKey(productKey)}`;
};

export const getCategoryId = (
  parentCategoryId: string | undefined,
  productListCategoryId: string | undefined,
  level: number,
  title: string | undefined
): string => {
  return `${parentCategoryId}-${productListCategoryId}-${level}-${title}`;
};

// Mappers
export const getUncategorizedCategory = (): ProductListCategoryCustom => {
  return {
    Id: generateId(),
    ParentId: undefined,
    CategoryProductIds: [],
    ProductListCategoryId: '00000000-0000-0000-0000-000000000000',
    CategoryTitle: 'Uncategorized',
    Sequence: -1,
    ParentProductListCategoryId: undefined,
    Level: 0,
    SortValue: 0,
    ProductListDetailIds: [],
    IsCollapsed: false,
  } as ProductListCategoryCustom;
};

export const getCategoryProductData = (
  detail: ProductListDetail,
  product: ProductListProduct,
  categoryId: string
): ProductListCategoryProduct => {
  return {
    Id: getCategoryProductId(categoryId, product.ProductKey),
    CategoryId: categoryId,
    ProductListDetailId: detail.ProductListDetailId,
    ProductListCategoryId: detail.ProductListCategoryId,
    Sequence: detail.Sequence,
    ProductKey: normalizeProductKey(product.ProductKey),
  } as ProductListCategoryProduct;
};

export const getCategoryData = (data: ProductListCategory[] | undefined): ProductListCategoryData[] => {
  const result =
    data?.map?.((category) => {
      return {
        Id: getCategoryId(
          category.ParentProductListCategoryId,
          category.ProductListCategoryId,
          category.Level,
          category.CategoryTitle
        ),
        ProductListCategoryId: category.ProductListCategoryId,
        CategoryTitle: category.CategoryTitle,
        CategorySequence: category.Sequence,
        ParentCategoryId: category.ParentProductListCategoryId,
        CategoryLevel: category.Level,
        SortValue: -1,
        Products: [],
      } as ProductListCategoryData;
    }) ?? [];
  return result;
};

export const getNormalizedProductListGridData = (
  data: ProductListCategoryData[] | undefined,
  includeProductListBadges?: boolean,
  previousPage?: ProductListGridData[]
): ProductListGridDataResult => {
  const categories: ProductListCategoryCustom[] = [];
  const categoryProducts: ProductListCategoryProduct[] = [];
  const products: ProductListProduct[] = [];

  const productsByProductKey: Record<string, ProductListProduct> = {};
  const productListCategoryIdToId: Record<string, string> = {};

  // Resolve duplicate products added from load more
  const getExistingGridDataKey = (
    title: string | undefined,
    level: number,
    categoryProduct: ProductListCategoryProduct,
    product: ProductListProduct
  ) => `${title}-${level}-${categoryProduct.ProductListCategoryId}-${product.ProductKey}`;

  const existingGridData: Record<string, ProductListCategoryProduct> = {};
  previousPage?.forEach((d) => {
    if (!d.category || !d.categoryProduct || !d.product) return;
    const key = getExistingGridDataKey(d.category.CategoryTitle, d.category.Level, d.categoryProduct, d.product);
    existingGridData[key] = d.categoryProduct;
  });

  // Normalize grid data
  data?.forEach?.((category) => {
    const categoryId = getCategoryId(
      category.ParentCategoryId,
      category.ProductListCategoryId,
      category.CategoryLevel,
      category.CategoryTitle
    ); //generateId();

    if (category.ProductListCategoryId) productListCategoryIdToId[category.ProductListCategoryId] = categoryId;

    const categoryProductIds = new Set<string>();
    category.Products?.forEach?.((categoryProductData) => {
      const productKey = normalizeProductKey(categoryProductData.Product.ProductKey);
      const product = includeProductListBadges
        ? {
            ...categoryProductData.Product,
            IsHidden: categoryProductData.IsHidden,
            IsRetained: categoryProductData.IsRetained,
          }
        : categoryProductData.Product;

      const categoryProductId = getCategoryProductId(categoryId, categoryProductData.Product.ProductKey);

      const categoryProduct = {
        Id: categoryProductId,
        CategoryId: categoryId,
        ProductListDetailId: categoryProductData.ProductListDetailId,
        ProductListCategoryId: categoryProductData.ProductListCategoryId,
        Sequence: categoryProductData.Sequence,
        ProductKey: productKey,
      } as ProductListCategoryProduct;

      const uniqueKey = getExistingGridDataKey(
        category.CategoryTitle,
        category.CategoryLevel,
        categoryProduct,
        product
      );

      if (!existingGridData[uniqueKey]) {
        categoryProductIds.add(categoryProductId);
        categoryProducts.push(categoryProduct);
        if (!productsByProductKey[productKey]) {
          productsByProductKey[productKey] = product;
          products.push(product);
        }
      } else {
        //console.log('discarding', product, categoryProduct);
      }
    });

    categories.push({
      // Removed properties:
      //CategorySequence: number;
      //ParentCategoryId?: string;
      //CategoryLevel: number;
      //Products: ProductListCategoryProductData[];

      Id: categoryId,
      ParentId: category.ParentCategoryId ? productListCategoryIdToId[category.ParentCategoryId] : undefined,
      CategoryProductIds: Array.from(categoryProductIds.values()),
      ProductListDetailIds:
        category.Products?.map?.((categoryProduct) => normalizeKey(categoryProduct.ProductListDetailId)) ?? [],
      IsCollapsed: false,
      Sequence: category.CategorySequence,
      ParentProductListCategoryId: category.ParentCategoryId,
      Level: category.CategoryLevel,
      ProductListCategoryId: category.ProductListCategoryId,
      CategoryTitle: category.CategoryTitle,
      SortValue: category.SortValue,
    } as ProductListCategoryCustom);
  });

  const result = { categories, categoryProducts, products };
  //console.log(result, previousPage);

  return result;
};

// Category product helpers
export const moveCategoryProductBetweenCategories = (
  state: ProductListState,
  fromCategory: ProductListCategoryCustom | undefined,
  toCategory: ProductListCategoryCustom | undefined,
  categoryProductId: string,
  indexOffset: number
): void => {
  if (!fromCategory || !toCategory) return;
  // Remove from old category
  const oldIndex = fromCategory.CategoryProductIds.indexOf(categoryProductId);
  if (oldIndex > -1) fromCategory.CategoryProductIds.splice(oldIndex, 1);

  // Insert into new category
  let index = indexOffset;
  if (fromCategory.Id === toCategory.Id && index > oldIndex) index = indexOffset - 1;

  if (index < toCategory.CategoryProductIds.length && index >= 0) {
    toCategory.CategoryProductIds.splice(index, 0, categoryProductId);
  } else {
    toCategory.CategoryProductIds.push(categoryProductId);
  }

  // Update sequences
  sequenceCategoryProducts(state, fromCategory);
  if (fromCategory.Id !== toCategory.Id) sequenceCategoryProducts(state, toCategory);
};

const sequenceCategoryProducts = (state: ProductListState, category: ProductListCategoryCustom) => {
  category.CategoryProductIds.forEach((id, i) => {
    const categoryProduct = state.categoryProducts.entities[id];
    if (categoryProduct) categoryProduct.Sequence = i;
  });
};

// Category helpers
export const moveCategory = (
  state: ProductListState,
  categoryId: EntityId,
  request: {
    parentId: string | undefined;
    parentProductListCategoryId: string | undefined;
    sequence: number;
    level: number;
  }
): void => {
  const category = state.categories.entities[categoryId];
  if (!category) return;

  // update category
  category.ParentId = request.parentId;
  category.ParentProductListCategoryId = request.parentProductListCategoryId;
  category.Sequence = request.sequence;
  category.Level = request.level;

  // update category sequences and levels
  const nestedIds = new Set<EntityId>();
  nestedIds.add(category.Id);

  const ids = state.categories.ids;
  let currentLevelSequence = 0;
  ids.forEach((id) => {
    const item = state.categories.entities[id];
    if (!item) return;

    if (item.ParentId === category.ParentId) {
      item.Sequence = currentLevelSequence;
      currentLevelSequence = currentLevelSequence + 1;
    } else if (item.ParentId && nestedIds.has(item.ParentId)) {
      const parentItem = state.categories.entities[item.ParentId];
      if (parentItem) {
        item.Level = parentItem.Level + 1;
        nestedIds.add(item.Id);
      }
    }
  });
};

export const removeCategory = (
  state: ProductListState,
  categoryIds: EntityId[],
  category: ProductListCategoryCustom,
  categoryIndex: number
): EntityId[] => {
  const categoryCount = getSubCategoryCount(state, category.Id, categoryIndex) + 1;
  const removedIds = categoryIds.splice(categoryIndex, categoryCount);
  return removedIds;
};

export const getSubCategoryCount = (state: ProductListState, categoryId: string, categoryIndex: number): number => {
  const ids = state.categories.ids;
  const category = state.categories.entities[categoryId];
  if (!category) return 0;

  // Leverage the sorted nature of our grid
  let count = 0;
  let index = categoryIndex + 1;
  while (index < ids.length) {
    const id = ids[index];
    const item = state.categories.entities[id];
    if (item && item.Level > category.Level) count = count + 1;
    else break;
    index = index + 1;
  }
  return count;
};

export const getSearchProductListCatalogFieldRequestDefault = (): SearchProductListCatalogFieldRequest => {
  return {
    CustomerId: '',
    OrderEntryHeaderId: '',
    ProductListSearchFieldType: ProductListSearchFieldType.Product,
    Query: '',
  };
};
