import { useTheme } from '@mui/styles';
import {
  AppNotification,
  NotificationKeys,
  NotificationType,
  removeAppNotificationById,
  useAppDispatch,
  useTimer,
} from 'common';
import { useEffect } from 'react';
import { CfAlert, CfAlertVariant } from '../../../../../../cf-ui/Alert/CfAlert';
import { useBreakpoint } from '../../../../../../hooks/useBreakpoint';
import { StyledPageNotificationContainer } from './PageNotificationItem.styles';

interface PageNotificationItemProps {
  notification?: AppNotification;
}

export const PageNotificationItem = ({ notification }: PageNotificationItemProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { breakpoint } = useBreakpoint();

  const handleDismissClick = () => {
    notification && dispatch(removeAppNotificationById(notification?.Id));
  };

  const timer = useTimer(notification ? notification.AutoDismiss : 0, () => {
    if (notification && notification.AutoDismiss !== 0) dispatch(removeAppNotificationById(notification?.Id || '0'));
  });

  useEffect(() => {
    timer.restart(notification?.AutoDismiss || 0);
  }, [notification]);

  let variant: CfAlertVariant = 'system';
  switch (notification?.NotificationType) {
    case NotificationType.Error:
      variant = 'error';
      break;
    case NotificationType.Warning:
      variant = 'warning';
      break;
    case NotificationType.Success:
      variant = 'success';
      break;
    case NotificationType.Information:
      variant = 'info';
      break;
    default:
      variant = 'system';
      break;
  }

  let title = notification?.Title;
  let message = notification?.Message;
  if (notification?.Key === NotificationKeys.PageLevelMessage) {
    title = 'Attention';
    if (message?.includes(',')) message = message += ' are currently editing this order.';
    else message = message += ' is currently editing this order.';
  }

  if (!notification) return <></>;

  return (
    <StyledPageNotificationContainer>
      <CfAlert
        title={title}
        message={message}
        isOpen={Boolean(notification)}
        onClose={handleDismissClick}
        hasCloseButton={notification?.CanUserDismiss}
        variant={variant}
        wrapperDataTestId='page-notification-wrapper'
        messageDataTestId='page-notification-message'
        sx={{
          minHeight: { xs: 32, xl: 28 },
          ...(breakpoint === 'xl' && {
            width: {
              ...theme.custom.contentWidths,
            },
          }),
          ...(breakpoint !== 'xl' && {
            width: '100%',
            mr: { xs: 2, lg: 2 },
            ml: { xs: 2, lg: 1 },
          }),
        }}
      />
    </StyledPageNotificationContainer>
  );
};
