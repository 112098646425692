import { Box } from '@mui/material';
import { AppNotification, getRouteName, NotificationDisplayType, RootState, RouteName, useAppSelector } from 'common';
import { useCallback, useEffect, useState } from 'react';
import { setAlertHeight } from '../../../../../hooks/usePageNotification';
import { cfFreezeNames } from '../../../../../theme/theme';
import { PageNotificationItem } from './components/PageNotificationItem';

export const PageNotification = (): JSX.Element => {
  const routeName = getRouteName();

  const [height, setHeight] = useState<number | null>(null);

  const pageNotifications = useAppSelector((x: RootState) =>
    x.global.notifications
      .filter((notification: AppNotification) => notification.NotificationDisplayType === NotificationDisplayType.Page)
      .sort((a, b) => a.Key - b.Key)
  );

  useEffect(() => {
    if (height !== null) {
      setAlertHeight(height);
    }
  }, [height]);

  const measuredHeight = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        setHeight(node.getBoundingClientRect().height);
      } else {
        setHeight(0);
      }
    },
    [pageNotifications]
  );

  const filterPageNotificationsForRoutes = (): AppNotification[] => {
    const filteredNotifications: AppNotification[] = [];
    pageNotifications.map((n) => {
      const routes = n.RouteNames ?? [];
      if (routes.length === 0 || routes.includes(routeName as RouteName)) {
        filteredNotifications.push(n);
      }
    });

    return filteredNotifications;
  };
  const notifications: AppNotification[] = filterPageNotificationsForRoutes();

  if (!(notifications && notifications.length > 0)) return <></>;

  return (
    <Box
      sx={{
        position: 'sticky',
        zIndex: 10,
        top: (theme) => theme.custom.sticky.getTotalHeightOfComponents([cfFreezeNames.header, cfFreezeNames.subheader]),
        height: 0,
      }}
    >
      <div ref={measuredHeight}>
        {notifications &&
          notifications.map((notification: AppNotification) => {
            return <PageNotificationItem notification={notification} key={notification.Id} />;
          })}
      </div>
    </Box>
  );
};
