/* eslint-disable react/prop-types */
// Based off of: https://github.com/bvaughn/react-error-boundary

import React from 'react';
import { CfErrorBoundaryProps, ResetKeys } from './CfErrorBoundaryProps';

const changedArray = (a: Array<unknown> = [], b: Array<unknown> = []) =>
  a.length !== b.length || a.some((item, index) => !Object.is(item, b[index]));

type CfErrorBoundaryState = { error: Error | null };
const initialState: CfErrorBoundaryState = { error: null };

/**
 * Holds error state and returns appropriate compoenent, if error is present, else it returns the child components
 *
 * @param onResetKeysChange - Method to call on changing the reset keys
 * @param onReset - Method to call on error boundry reset
 * @param onError - Method to call if there is an error caught
 * @param resetKeys - Array of items to be reset on update
 * @param fallback - Holds the FallbackComponent to be displayed on error
 * @param children - Components that will be shown if there is no error caught
 */
export class CfErrorBoundary extends React.Component<
  React.PropsWithRef<React.PropsWithChildren<CfErrorBoundaryProps>>,
  CfErrorBoundaryState
> {
  static getDerivedStateFromError(error: Error): { error: Error } {
    return { error };
  }

  state = initialState;
  resetErrorBoundary = (...args: ResetKeys): void => {
    this.props.onReset?.(...args);
    this.reset();
  };

  reset(): void {
    this.setState(initialState);
  }

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    this.props.onError?.(error, info);
  }

  componentDidUpdate(prevProps: CfErrorBoundaryProps, prevState: CfErrorBoundaryState): void {
    const { error } = this.state;
    const { resetKeys } = this.props;

    // There's an edge case where if the thing that triggered the error
    // happens to *also* be in the resetKeys array, we'd end up resetting
    // the error boundary immediately. This would likely trigger a second
    // error to be thrown.
    // So we make sure that we don't check the resetKeys on the first call
    // of cDU after the error is set

    if (error !== null && prevState.error !== null && changedArray(prevProps.resetKeys, resetKeys)) {
      this.props.onResetKeysChange?.(prevProps.resetKeys, resetKeys);
      this.reset();
    }
  }

  render(): React.ReactNode {
    const { error } = this.state;

    const { fallback: FallbackComponent } = this.props;

    if (error !== null) {
      const props = {
        error,
        resetErrorBoundary: this.resetErrorBoundary,
      };
      if (FallbackComponent) return <FallbackComponent {...props} />;
      else throw new Error('cf-error-boundary requires either a fallback, fallbackRender, or FallbackComponent prop');
    }

    return this.props.children;
  }
}
