import { Grid, Skeleton, Typography } from '@mui/material';
import { useBreakpoint } from '../../../../../../../../../../../hooks/useBreakpoint';
import { StyledOverflowContainer } from './ProductHeader.styles';

export type ProductHeaderProps =
  | {
      title: string;
      loadingPlaceholder?: never;
    }
  | {
      loadingPlaceholder: true;
    };
export const ProductHeader = (props: ProductHeaderProps): JSX.Element => {
  const { breakpoint } = useBreakpoint();

  if (props.loadingPlaceholder) {
    return (
      <Grid item xs={12} mb={0.5}>
        <StyledOverflowContainer sx={{ color: (theme) => theme.palette.coolGrey[600] }}>
          <Skeleton>
            <Typography variant={breakpoint === 'xl' ? 'paragraphSmallBold' : 'mobileParagraphSmallBold'}>
              A title for a product of some type
            </Typography>
          </Skeleton>
        </StyledOverflowContainer>
      </Grid>
    );
  } else {
    return (
      <Grid item xs={12} mb={0.5}>
        <StyledOverflowContainer sx={{ color: (theme) => theme.palette.coolGrey[600] }}>
          <Typography variant={breakpoint === 'xl' ? 'paragraphSmallBold' : 'mobileParagraphSmallBold'}>
            {props.title}
          </Typography>
        </StyledOverflowContainer>
      </Grid>
    );
  }
};
