import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { CustomerProductPriceResult, SimilarCatalogProduct } from '../../api';
import { UnitOfMeasureOrderQuantity } from '../../api/models/api-shared.models';
import {
  CreateOrderEntryHeaderRequest,
  DeleteOrderEntryHeaderRequest,
  GetActiveOrderRequest,
  GetConfirmationOrderEntryHeadersRequest,
  GetCustomerOrderEntryHeadersRequest,
  OrderEntryHeader,
  RecallFutureOrderRequest,
  UpdateOrderEntryHeaderDeliveryDateRequest,
} from '../../api/models/order-entry-header.models';
import { IsOrderEntryPurchaseOrderNumberRequiredType } from '../../api/models/order.enums';
import { GetOrderRequest } from '../../api/models/order.models';
import { UserCustomer } from '../../api/models/site.models';
import OrderEntryHeaderService from '../../api/services/order-entry-header.service';
import OrderService from '../../api/services/orders.service';
import { AnalyticsContext, canOrderProduct, getProductPriceRequests } from '../../helpers';
import { AppNotificationsCenter } from '../../helpers/lookups/AppNotificationsCenter';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { NotificationKeys } from '../../models/notifications.models';
import { ProductData } from '../../models/product-list.models';
import { RouteName } from '../../models/routing.models';
import {
  getCustomerProductPrices,
  getOrderReviewCustomerProductPrices,
  updateSelectedCustomer,
} from '../common/customer.thunks';
import { globalSlice } from '../common/global.slice';
import { removeAppNotificationByKey, upsertAppNotification } from '../common/global.thunks';
import { resetProductComparisons } from '../search/product-compare.thunks';
import { resetProductSearchResults } from '../search/product-search.thunks';
import { AppDispatch, AppThunk, RootState } from '../store';
import { selectAllOrderEntryProducts } from './order-entry.slice';
import {
  exitOrderEntry,
  resetOrderEntryState,
  setOrderEntryProductPrices,
  updateOrderEntryDetailQuantity,
} from './order-entry.thunks';
import { getDidYouForgetCustomerProducts, removeOrderReviewProduct } from './order-review.thunks';
import { ordersSlice } from './orders.slice';
import { OrdersPageQueryParams } from './routing.models';

// Hooks
const orderService = OrderService.getInstance();
const orderEntryHeaderService = OrderEntryHeaderService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

// Thunks

/**
 * Calls and stores the results of the UpdateOrderEntryHeaderOrderEntryHeaderName API call
 *
 * @param orderEntryHeaderName - Name that the order entry header's name will be updated to
 * @param callBack - Method to call when this method is completed
 * @returns NULL
 */
export const updateCurrentOrderEntryHeaderName =
  (orderEntryHeaderName: string, callBack?: (isSuccess: boolean) => void | Promise<void>): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      const activeOrderId = getState().orders.activeOrder?.OrderEntryHeaderId;
      if (!activeOrderId) return;

      const { data } = await orderEntryHeaderService.updateOrderEntryHeaderName({
        OrderEntryHeaderId: activeOrderId,
        OrderEntryHeaderName: orderEntryHeaderName,
      });
      if (data.IsSuccess) {
        dispatch(
          ordersSlice.actions.updateCurrentOrderEntryHeaderName(data.ResultObject?.OrderEntryHeaderName as string)
        );
      } else {
        dispatch(
          globalSlice.actions.setErrorDialogContent({
            title: 'Order Name could not be updated',
            messages: data.ErrorMessages,
          })
        );
      }
      callBack?.(data.IsSuccess);
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls and stores the results of the UpdateOrderEntryHeaderPurchaseOrderNumber API call
 *
 * @param purchaseOrderNumber - Order number that the order entry header's order number will be updated to
 * @param callBack - Method to call when this method is completed
 * @returns NULL
 */
export const updateOrderEntryHeaderPurchaseOrderNumber =
  (purchaseOrderNumber: string, callback?: (isSuccess: boolean) => void | Promise<void>): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      const activeOrderId = getState().orders.activeOrder?.OrderEntryHeaderId;
      const currentOrder = getState().orders.order;
      if (!activeOrderId || !currentOrder) return;

      const { data: result } = await orderEntryHeaderService.updateOrderEntryHeaderPurchaseOrderNumber({
        OrderEntryHeaderId: activeOrderId,
        PurchaseOrderNumber: purchaseOrderNumber,
      });

      if (result.IsSuccess) {
        if (result.ResultObject.PurchaseOrderNumber) {
          dispatch(removeAppNotificationByKey(NotificationKeys.PORequired));
        } else {
          if (
            currentOrder.IsPurchaseOrderNumberRequired === IsOrderEntryPurchaseOrderNumberRequiredType.Yes ||
            currentOrder.IsPurchaseOrderNumberRequired === IsOrderEntryPurchaseOrderNumberRequiredType.YesNumbersOnly
          ) {
            dispatch(upsertAppNotification(AppNotificationsCenter.getNotificationByKey(NotificationKeys.PORequired)));
          }
        }
        dispatch(ordersSlice.actions.updatePurchaseOrderNumber(result.ResultObject.PurchaseOrderNumber as string));
      } else {
        dispatch(
          globalSlice.actions.setErrorDialogContent({
            title: 'PO # could not be updated',
            messages: result.ErrorMessages,
          })
        );
      }
      callback?.(result.IsSuccess);
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls and stores the results of the GetOrder API call
 *
 * @param callBack - Method to call when this method is completed
 * @returns NULL
 */
export const getMostRecentOrder =
  (callBack?: () => void | Promise<void>): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      const activeOrderId = getState().orders.activeOrder?.OrderEntryHeaderId;
      if (!activeOrderId) return;

      const request: GetOrderRequest = {
        orderEntryHeaderId: activeOrderId,
      };

      const { data } = await orderService.getOrder(request);
      if (data.IsSuccess) {
        if (
          !data.ResultObject.PurchaseOrderNumber?.trim() &&
          (data.ResultObject.IsPurchaseOrderNumberRequired === IsOrderEntryPurchaseOrderNumberRequiredType.Yes ||
            data.ResultObject.IsPurchaseOrderNumberRequired ===
              IsOrderEntryPurchaseOrderNumberRequiredType.YesNumbersOnly)
        ) {
          dispatch(upsertAppNotification(AppNotificationsCenter.getNotificationByKey(NotificationKeys.PORequired)));
        } else {
          dispatch(removeAppNotificationByKey(NotificationKeys.PORequired));
        }

        dispatch(ordersSlice.actions.setCurrentOrder(data.ResultObject));
        if (callBack) await callBack();
      }

      // [TODO]: Handle state if IsSuccess === false
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    } finally {
      dispatch(ordersSlice.actions.setOrderDetailsUpdating(false));
    }
  };

// export const getRecentOrders =
//   (callBack?: () => void | Promise<void>): AppThunk =>
//   async (dispatch: AppDispatch, getState) => {
//     try {
//       if (!getState().orders.recentOrdersLoading) dispatch(ordersSlice.actions.setRecentOrdersLoading(true));

//       const customerId = getState().customer.selectedCustomer?.CustomerId;
//       if (!customerId) return;

//       const today = new Date();
//       const prevMonth = new Date(subtractMonths(today, 1).setHours(0, 0, 0, 0));

//       const request: GetOrderHeadersRequest = {
//         StartDate: prevMonth,
//         EndDate: today,
//       };

//       const { data } = await orderService.getOrderHeaders(request);

//       if (data.IsSuccess) {
//         dispatch(ordersSlice.actions.setRecentOrders(data.ResultObject));
//         if (callBack) callBack();
//       }

//       // [TODO]: Handle state if IsSuccess === false
//     } catch (error) {
//       appInsightsLogger.trackException({
//         exception: error,
//         severityLevel: SeverityLevel.Error,
//       });
//       // [TODO]: Handle state if an error occurs here
//     } finally {
//       dispatch(ordersSlice.actions.setRecentOrdersLoading(false));
//     }
//   };

/**
 * Calls and stores the results of the GetActiveOrder API call
 *
 * @param customerId - Id of the customer who's active order is being retrieved
 * @returns NULL
 */
export const getActiveOrder =
  (customerId?: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const activeOrderEntryHeaderId = getState().orders.activeOrder?.OrderEntryHeaderId;
      const _customerId = customerId || getState().customer.selectedCustomer?.CustomerId;
      if (!_customerId) return;

      const request: GetActiveOrderRequest = {
        CustomerId: _customerId,
      };

      const {
        data: result,
        data: { ResultObject: getActiveOrderResult },
      } = await orderEntryHeaderService.getActiveOrder(request);

      if (result.IsSuccess) {
        if (activeOrderEntryHeaderId !== getActiveOrderResult.OrderEntryHeaderId) {
          dispatch(exitOrderEntry());
        }
        dispatch(ordersSlice.actions.setActiveOrder(getActiveOrderResult));
        if (getActiveOrderResult.OrderEntryHeaderId) {
          dispatch(getMostRecentOrder());
        }
      } else {
        dispatch(
          ordersSlice.actions.setActiveOrder({
            OrderEntryHeaderId: null,
            TotalQuantity: 0,
            DeliveryDate: new Date().toLocaleTimeString(),
          })
        );
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    }
  };

/**
 * Calls and stores the results of the CreateOrderEntryHeader API call
 *
 * @param callBack - Method to call when this method is completed
 * @returns NULL
 */
export const createOrderEntryHeader =
  (callBack?: (orderId: string) => void | Promise<void>): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      const customerId = getState().customer.selectedCustomer?.CustomerId;
      if (!customerId) return;

      const deliveryDate = new Date();
      const request: CreateOrderEntryHeaderRequest = {
        CustomerId: customerId,
        DeliveryDate: deliveryDate,
        PurchaseOrderNumber: '',
      };

      const { data } = await orderEntryHeaderService.createOrderEntryHeader(request);

      if (data.IsSuccess) {
        const orderEntryHeaderResult = data.ResultObject;

        // Clear Order Entry Previous State
        dispatch(resetOrderEntryState());

        // Clear existing Search & Comparisons
        dispatch(resetProductSearchResults());
        dispatch(resetProductComparisons());

        // Exit existing Order
        dispatch(exitOrderEntry());

        // Set Active Order to newly created Order
        dispatch(
          ordersSlice.actions.setActiveOrder({
            OrderEntryHeaderId: orderEntryHeaderResult.OrderEntryHeaderId,
            TotalQuantity: orderEntryHeaderResult.TotalQuantity,
            DeliveryDate: orderEntryHeaderResult.DeliveryDate,
          })
        );

        // Only on a successful preceding API call, fetches latest orders data and updates the Current Order State too
        dispatch(
          getMostRecentOrder(() => {
            if (callBack) callBack(orderEntryHeaderResult.OrderEntryHeaderId);
          })
        );
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Remove the specified product from the Order by calling the updateOrderEntryDetailQuantity from order-entry.thunks.ts and setting it's quantity to 0
 *
 * @param product - The product to be removed
 * @param analyticsContext - The analytics context to use
 * @returns NULL
 */
export const removeProductFromOrder =
  (product: ProductData, analyticsContext?: AnalyticsContext): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      if (!product || !product.ProductKey) return;

      const canOrder = canOrderProduct(product);

      const canRemoveProduct = !product.UnitOfMeasureOrderQuantities.some(
        (uom: UnitOfMeasureOrderQuantity) => (canOrder && uom.CanOrderUom) || !uom.CanEditDetail
      );

      product.UnitOfMeasureOrderQuantities.forEach((uom: UnitOfMeasureOrderQuantity) => {
        if ((canOrder && uom.CanOrderUom) || !uom.CanEditDetail) return;

        dispatch(
          updateOrderEntryDetailQuantity(
            uom,
            0,
            product,
            -1, // todo proper index
            {
              enabled: false,
              includeUOM: false,
            },
            analyticsContext
          )
        );
      });

      if (canRemoveProduct) {
        dispatch(removeOrderReviewProduct(product.ProductKey));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Remove the specified product from the Order by calling the updateOrderEntryDetailQuantity from order-entry.thunks.ts and setting it's quantity to 0
 *
 * @param product - The product to be removed
 * @param analyticsContext - The analytics context to use
 * @returns NULL
 */
export const removeSimilarProductFromOrder =
  (product: SimilarCatalogProduct, analyticsContext?: AnalyticsContext): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      if (!product || !product.Product.ProductKey || !product.Product.ProductKey) return;

      product.Product.UnitOfMeasureOrderQuantities.forEach((uom: UnitOfMeasureOrderQuantity) => {
        dispatch(
          updateOrderEntryDetailQuantity(
            uom,
            0,
            product.Product,
            -1, // todo proper index
            {
              enabled: false,
              includeUOM: false,
            },
            analyticsContext
          )
        );
      });
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Remove the specified product from the Order by calling the updateOrderEntryDetailQuantity from order-entry.thunks.ts and setting it's quantity to 0
 *
 * @param deliveryDate - The date to update the order's delivery date to
 * @param routeName - Used to check if the data needs to be refreshed
 * @returns NULL
 */
export const updateOrderDeliveryDate =
  (deliveryDate: string, routeName: RouteName | undefined): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      const activeOrder = getState().orders.activeOrder;
      if (!activeOrder?.OrderEntryHeaderId) return;

      const request: UpdateOrderEntryHeaderDeliveryDateRequest = {
        OrderEntryHeaderId: activeOrder.OrderEntryHeaderId,
        DeliveryDate: deliveryDate,
      };

      const { data } = await orderEntryHeaderService.updateDeliveryDate(request);
      if (data.IsSuccess) {
        dispatch(ordersSlice.actions.updateDeliveryDate(data.ResultObject.DeliveryDate as string));
        dispatch(getMostRecentOrder());
        dispatch(
          ordersSlice.actions.setActiveOrder({
            OrderEntryHeaderId: data.ResultObject.OrderEntryHeaderId,
            DeliveryDate: data.ResultObject.DeliveryDate,
            TotalQuantity: activeOrder.TotalQuantity,
          })
        );

        // Refetch Order Entry or Order Review Grid Data
        // Changing the Delivery Date means that Pricing could have changed, so refetch data
        if (routeName === RouteName.OrderEntry) {
          const prductList = getState().orderEntry.products;
          dispatch(
            getCustomerProductPrices(
              getProductPriceRequests(selectAllOrderEntryProducts(prductList)),
              (priceResults: CustomerProductPriceResult[]) => {
                dispatch(setOrderEntryProductPrices(priceResults));
              }
            )
          );
        } else if (routeName === RouteName.OrderReview) {
          dispatch(getDidYouForgetCustomerProducts());
          dispatch(getOrderReviewCustomerProductPrices());
        }
      } else {
        dispatch(
          globalSlice.actions.setErrorDialogContent({
            title: 'Delivery Date could not be updated',
            messages: data.ErrorMessages,
          })
        );
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    }
  };

/**
 * Calls and stores the result of the GetOrderEntryHeader API call in the activeOrder value of the orders slice
 *
 * @param orderEntryHeaderId - The order header id of the order entry header that the active cart will be setto
 * @param callBack - Method to call when this method completes
 * @returns NULL
 */
export const setActiveCartById =
  (orderEntryHeaderId: string, callBack?: () => void): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
      const currentOrderEntryHeaderId = getState().orders.order?.OrderEntryHeaderId;
      if (currentOrderEntryHeaderId && currentOrderEntryHeaderId !== orderEntryHeaderId) {
        dispatch(ordersSlice.actions.setCurrentOrder(undefined));
      }

      const getOrderRequest: GetOrderRequest = { orderEntryHeaderId: orderEntryHeaderId };
      const getOrderResult = await orderEntryHeaderService.getOrderEntryHeader(getOrderRequest);
      const orderResult = getOrderResult.data;

      if (orderResult.IsSuccess) {
        const order = orderResult.ResultObject;
        dispatch(setActiveCart(order, callBack));
      } else {
        dispatch(
          globalSlice.actions.setErrorDialogContent({
            messages: orderResult.ErrorMessages,
          })
        );
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    }
  };

/**
 * Sets the activeOrder value of the orders slice
 *
 * @param orderEntryHeader - The order entry header that the active cart is being set to
 * @param callBack - Method to call when this method completes
 * @returns NULL
 */
export const setActiveCart =
  (orderEntryHeader: OrderEntryHeader, callBack?: () => void): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState): Promise<void> => {
    const customer = getState().customer.selectedCustomer;
    if (customer) {
      try {
        if (orderEntryHeader.CustomerId.toLocaleLowerCase() !== customer.CustomerId.toLocaleLowerCase()) {
          const userSite = getState().user.userSite;
          if (userSite) {
            const newCustomer = userSite.UserCustomers.find(
              (c: UserCustomer) => c.CustomerId.toLocaleLowerCase() === orderEntryHeader.CustomerId.toLocaleLowerCase()
            );
            if (newCustomer) await dispatch(updateSelectedCustomer(newCustomer, { skipCartRefresh: true }));
          }
        }

        // Set the Active Order manually
        dispatch(
          ordersSlice.actions.setActiveOrder({
            OrderEntryHeaderId: orderEntryHeader.OrderEntryHeaderId,
            TotalQuantity: orderEntryHeader.TotalQuantity,
            DeliveryDate: orderEntryHeader.DeliveryDate,
          })
        );

        dispatch(removeAppNotificationByKey(NotificationKeys.PORequired));
        dispatch(removeAppNotificationByKey(NotificationKeys.InvalidDeliveryDate));

        const currentOrderEntryHeaderId = getState().orders.order?.OrderEntryHeaderId;
        if (currentOrderEntryHeaderId && currentOrderEntryHeaderId !== orderEntryHeader.OrderEntryHeaderId) {
          dispatch(ordersSlice.actions.setCurrentOrder(undefined));
        }

        if (callBack) callBack();
      } catch (error) {
        appInsightsLogger.trackException({
          exception: error,
          severityLevel: SeverityLevel.Error,
        });
        // [TODO]: Handle state if an error occurs here
      }
    }
  };

/**
 * Sets the value of the recentOrdersLoading value in the slice
 *
 * @param loading - The boolean value that recentOrdersLoading will be set to
 * @returns NULL
 */
export const setOrderTableLoading =
  (loading: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(ordersSlice.actions.setRecentOrdersLoading(loading));
  };

/**
 * Calls the getCustomerOrderEntryHeaders method
 *
 * @param showAllLocations - Boolean indicating if all location should be shown
 * @returns NULL
 */
export const getCustomerUnsubmittedOrders =
  (showAllLocations: boolean): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const customer = getState().customer.selectedCustomer;
    const user = getState().user.userSite;

    if (user && user.UserCustomers.length > 1 && showAllLocations) {
      dispatch(getCustomerOrderEntryHeaders());
    } else if (customer) {
      dispatch(getCustomerOrderEntryHeaders(customer.CustomerId));
    }
  };

/**
 * Calls and stores the result of the GetCustomerOrderEntryHeaders API call
 *
 * @param customerId - The customer id of the customer who's order entry header is being retrieved
 * @returns NULL
 */
export const getCustomerOrderEntryHeaders =
  (customerId?: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().orders.loadingCustomerOrderEntryHeaders) {
        dispatch(ordersSlice.actions.setLoadingCustomerOrderEntryHeaders(true));
      }

      const request: GetCustomerOrderEntryHeadersRequest = {
        customerId: customerId,
      };

      const { data } = await orderEntryHeaderService.getCustomerOrderEntryHeaders(request);
      if (data.IsSuccess) {
        dispatch(ordersSlice.actions.setCustomerOrderEntryHeaders(data.ResultObject));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        dispatch(ordersSlice.actions.resetCustomerOrderEntryHeaders());
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(ordersSlice.actions.setLoadingCustomerOrderEntryHeaders(false));
    }
  };

/**
 * Sets the customerOrderEntryHeaders value in the orders slice
 *
 * @param customerOrderEntryHeaders - The header to set customerOrderEntryHeaders to
 * @returns NULL
 */
export const setCustomerOrderEntryHeaders =
  (customerOrderEntryHeaders: OrderEntryHeader[]): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(ordersSlice.actions.setCustomerOrderEntryHeaders(customerOrderEntryHeaders));
  };

/**
 * Sets the customerOrderEntryHeaders value in the orders slice to its initial value
 *
 * @returns NULL
 */
export const resetCustomerOrderEntryHeaders = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(ordersSlice.actions.resetCustomerOrderEntryHeaders());
};

/**
 * Gets and stores the result of the GetConfirmationOrderEntryHeaders API call
 *
 * @param request - Information needed to make a call to the GetConfirmationOrderEntryHeaders API call
 * @returns NULL
 */
export const getConfirmationOrderEntryHeaders =
  (request: GetConfirmationOrderEntryHeadersRequest): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (JSON.stringify(getState().orders.confirmationsApiRequest) === JSON.stringify(request)) {
        if (getState().orders.loadingConfirmationOrderEntryHeaders) {
          dispatch(ordersSlice.actions.setLoadingConfirmationOrderEntryHeaders(false));
        }
        return;
      }

      dispatch(ordersSlice.actions.setConfirmationsApiRequest(request));

      if (!getState().orders.loadingConfirmationOrderEntryHeaders) {
        dispatch(ordersSlice.actions.setLoadingConfirmationOrderEntryHeaders(true));
      }

      const { data } = await orderEntryHeaderService.getConfirmationOrderEntryHeaders(request);
      if (data.IsSuccess) {
        dispatch(ordersSlice.actions.setConfirmationOrderEntryHeaders(data.ResultObject));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        dispatch(ordersSlice.actions.resetConfirmationOrderEntryHeaders());
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      dispatch(ordersSlice.actions.setConfirmationsApiRequest(undefined));
    } finally {
      dispatch(ordersSlice.actions.setLoadingConfirmationOrderEntryHeaders(false));
    }
  };

/**
 * Resets the value of the confirmationOrderEntryHeaders value and confirmationsApiRequest value
 *
 * @returns NULL
 */
export const resetConfirmationOrderEntryHeaders = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(ordersSlice.actions.resetConfirmationOrderEntryHeaders());
};

/**
 * Calls the DeleteOrderEntryHeader API call
 *
 * @param orderEntryHeaderId - The id of the order entry header being deleted
 * @returns NULL
 */
export const deleteOrderEntryHeader =
  (orderEntryHeaderId: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      if (!getState().orders.recentOrdersLoading) {
        dispatch(setOrderTableLoading(true));
      }
      const customer = getState().customer.selectedCustomer;
      const activeOrder = getState().orders.activeOrder;

      if (customer) {
        const request: DeleteOrderEntryHeaderRequest = {
          OrderEntryHeaderId: orderEntryHeaderId,
        };

        const { data } = await orderEntryHeaderService.deleteOrderEntryHeader(request);
        if (data.IsSuccess) {
          dispatch(ordersSlice.actions.deleteOrder(orderEntryHeaderId));
          if (activeOrder?.OrderEntryHeaderId === orderEntryHeaderId) {
            dispatch(getActiveOrder(customer.CustomerId));
          }
        }
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    } finally {
      dispatch(setOrderTableLoading(false));
    }
  };

/**
 * Resets the activeOrder value in the orders slice
 *
 * @returns NULL
 */
export const resetActiveOrder = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(ordersSlice.actions.setActiveOrder(undefined));
};

/**
 * Resets the orderCart value in the orders slice
 *
 * @returns NULL
 */
export const resetOrderCart = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(ordersSlice.actions.setOrderCart(undefined));
};

/**
 * Calls and stores the result of the GetOrderCart API call
 *
 * @returns NULL
 */
export const getOrderCart = (): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    if (!getState().orders.cartLoading) dispatch(ordersSlice.actions.setCartLoading(true));

    const orderId = getState().orders.activeOrder?.OrderEntryHeaderId;
    const customerId = getState().customer.selectedCustomer?.CustomerId;

    if (!orderId || !customerId) return;

    const { data } = await orderService.getOrderCart({ activeOrderEntryHeaderId: orderId, customerId });

    if (data.IsSuccess) {
      dispatch(ordersSlice.actions.setOrderCart(data.ResultObject));
    } else {
      dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
    }
  } catch (error) {
    appInsightsLogger.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
  } finally {
    dispatch(ordersSlice.actions.setCartLoading(false));
  }
};

/**
 * Sets the cartLoading value in the slice
 *
 * @param loading - The value that cartLoading should be set to
 * @returns NULL
 */
export const setCartLoading =
  (loading: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(ordersSlice.actions.setCartLoading(loading));
  };

/**
 * Sets the ordersPageQueryParams value in the slice
 *
 * @param queryParams - The value that ordersPageQueryParams should be set to
 * @returns NULL
 */
export const setOrdersPageQueryParams =
  (queryParams: OrdersPageQueryParams): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(ordersSlice.actions.setOrdersPageQueryParams(queryParams));
  };

export const setActiveOrderIsFutureSubmission =
  (value: boolean): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(ordersSlice.actions.setActiveOrderIsFutureSubmission(value));
  };

/**
 * Recalls a future order in slice
 *
 * @param orderEntryHeaderId - Future order to recall
 * @param onSuccess - callback function to call when operation is successful
 * @param onError - callback function to call when operation failed
 * @returns NULL
 */
export const recallFutureOrder =
  (orderEntryHeaderId: string, onSuccess: (orderId: string) => unknown, onError: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const timeZoneIdentifier = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const request: RecallFutureOrderRequest = {
        OrderEntryHeaderId: orderEntryHeaderId,
        TimeZoneIdentifier: timeZoneIdentifier ?? '',
      };

      const { data } = await orderEntryHeaderService.recallFutureOrder(request);
      if (data.IsSuccess) {
        if (onSuccess) onSuccess(data.ResultObject.OrderEntryHeaderId);
      } else {
        if (onError) onError();
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      if (onError) onError();
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };
