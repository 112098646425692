import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetMessagesRequest,
  GetMessagesResultData,
  GetUnreadMessagesCountResultData,
  MessagePreview,
} from '../../api/models/message.models';
import { normalizeKey } from '../../helpers/general/string';
import { createPagedEntityAdapter, EntityPageLoading, PagedEntityState } from '../paged-entity-adapter';
import { RootState } from '../store';

const messageAdapter = createPagedEntityAdapter<MessagePreview>({
  selectId: (message: MessagePreview) => normalizeKey(message.MessageId),
});

interface MessageState {
  messages: PagedEntityState<MessagePreview>;
  getMessagesRequest: GetMessagesRequest | undefined;
  hasMoreData: boolean | undefined;
  checkedMessageIds: string[];
  deletingMessages: boolean;
  exportingMessages: boolean;
  unreadMessagesCount: GetUnreadMessagesCountResultData | undefined;
}

const initialState: MessageState = {
  messages: messageAdapter.getInitialState(),
  getMessagesRequest: undefined,
  hasMoreData: undefined,
  checkedMessageIds: [],
  deletingMessages: false,
  exportingMessages: false,
  unreadMessagesCount: undefined,
};

export const messageSlice = createSlice({
  name: 'message',
  initialState: initialState,
  reducers: {
    resetState: () => initialState,
    setMessagePreviewAsRead: (state: MessageState, action: PayloadAction<MessagePreview>) => {
      messageAdapter.upsertOne(state.messages, { ...action.payload, IsRead: true });
    },
    setMessagePreviewsRead: (state: MessageState, action: PayloadAction<{ messageIds: string[]; isRead: boolean }>) => {
      action.payload.messageIds.forEach((id) => {
        const message = state.messages.entities[id];
        if (message) state.messages.entities[id] = { ...message, IsRead: action.payload.isRead };
      });
    },
    setMessages: (
      state: MessageState,
      action: PayloadAction<{ data: GetMessagesResultData; request: GetMessagesRequest }>
    ) => {
      state.getMessagesRequest = action.payload.request;
      state.hasMoreData = action.payload.data.HasMoreData;
      messageAdapter.setLoadedPage(state.messages, {
        pageIndex: action.payload.request.CurrentPage,
        entities: action.payload.data.Messages,
      });
    },
    setPageLoaded: (state: MessageState, action: PayloadAction<{ pageIndex: number }>) => {
      messageAdapter.setLoadedPage(state.messages, {
        pageIndex: action.payload.pageIndex,
        entities: [],
      });
    },
    setPageLoading: (state: MessageState, action: PayloadAction<EntityPageLoading>) => {
      messageAdapter.setLoadingPage(state.messages, action.payload);
    },
    removeMessages: (state: MessageState, action: PayloadAction<string[]>) => {
      messageAdapter.removeMany(state.messages, action.payload);
      const exclude = new Set<string>(action.payload);
      state.checkedMessageIds = state.checkedMessageIds.filter((id) => !exclude.has(id));
    },
    resetMessages: (state: MessageState) => {
      state.getMessagesRequest = initialState.getMessagesRequest;
      state.messages = initialState.messages;
      state.checkedMessageIds = initialState.checkedMessageIds;
    },
    resetGetMessagesRequest: (state: MessageState) => {
      state.getMessagesRequest = initialState.getMessagesRequest;
    },
    setCheckedMessages: (
      state: MessageState,
      action: PayloadAction<{ messages: MessagePreview[]; isChecked: boolean }>
    ) => {
      const newMessageIds = new Set<string>(state.checkedMessageIds);
      const messageIds = action.payload.messages.map((m) => m.MessageId);
      if (action.payload.isChecked) {
        messageIds.forEach((id) => newMessageIds.add(id));
      } else {
        messageIds.forEach((id) => newMessageIds.delete(id));
      }

      state.checkedMessageIds = Array.from(newMessageIds);
    },
    setDeletingMessages: (state: MessageState, action: PayloadAction<boolean>) => {
      state.deletingMessages = action.payload;
    },
    setExportingMessages: (state: MessageState, action: PayloadAction<boolean>) => {
      state.exportingMessages = action.payload;
    },
    setUnreadMessages: (state: MessageState, action: PayloadAction<GetUnreadMessagesCountResultData | undefined>) => {
      state.unreadMessagesCount = action.payload;
    },
  },
});

export const {
  selectAll: selectAllMessages,
  selectById: selectMessageById,
  selectIds: selectAllMessageIds,
} = messageAdapter.getSelectors<RootState>((state: RootState) => state.message.messages);

export const selectAllCheckedMessageIds = createSelector([(state: RootState) => state.message], (state) => {
  return state.checkedMessageIds;
});

export const selectAllCheckedMessages = createSelector([(state: RootState) => state.message], (state) => {
  return state.checkedMessageIds.map((id) => state.messages.entities[id]).filter((m) => m) as MessagePreview[];
});

export const selectMessageIsChecked = createSelector(
  [(state: RootState) => state.message, (_state: RootState, id: string) => id],
  (state, id) => state.checkedMessageIds.includes(id)
);

export const selectMessagesCheckedCount = createSelector([(state: RootState) => state], (state) => {
  return selectAllCheckedMessageIds(state).length;
});
