import { BusinessUnitKeyType, ProductListSortByType } from './api-shared.enums';
import { CatalogProduct } from './api-shared.models';
import { AdvanceFilter, Facets, SimilarCatalogProduct } from './product-catalog.models';

export enum ProductListSearchFieldType {
  Product = 0,
  CustomProduct = 1,
  Manufacturer = 2,
}

export interface SearchProductListCatalogRequest {
  BusinessUnitKey: BusinessUnitKeyType;
  OperationCompanyNumber?: string;
  CustomerId: string;
  ViewProductListHeaderId?: string;
  Skip: number;
  PageSize: number;
  QueryText?: string;
  AdvanceFilter?: AdvanceFilter;
  ProductNumberSearchOnly: boolean;
  pageIndex: number; // not actually used by API
  IncludeInactiveProducts?: boolean;
}

export interface SearchProductListCatalogFieldRequest {
  CustomerId: string;
  OrderEntryHeaderId: string;
  ProductListSearchFieldType: ProductListSearchFieldType;
  Query: string;
}

export interface SearchProductListRequest {
  CustomerId: string;
  ProductListHeaderId: string;
  QueryText: string | undefined;
  SortByType: ProductListSortByType;
  Skip?: number;
  PageSize?: number;
  DeliveryDate?: Date;
  IsOnManagedListProductListHeaderId?: string;
}

export interface SearchProductListResultDetail {
  Skip: number;
  TotalCount: number;
  HasLoadMore: boolean;
  ProductListCategories: ProductListCategoryData[] | undefined;
}

export interface ProductListCategoryData {
  ProductListCategoryId?: string;
  CategoryTitle?: string;
  CategorySequence: number;
  ParentCategoryId?: string;
  CategoryLevel: number;
  SortValue: number;
  Products: ProductListCategoryProductData[];
}

export interface ProductListCategoryProductData {
  ProductListDetailId: string;
  ProductListCategoryId?: string;
  Sequence: number;
  IsHidden: boolean;
  IsRetained: boolean;
  Product: ProductListProduct;
  OrderEntryDate: Date | string;
}

export type ProductListProduct = CatalogProduct & ProductListProductData;

export type SimilarProductListProduct = SimilarCatalogProduct & ProductListProductData;

interface ProductListProductData {
  IsHidden?: boolean;
  IsRetained?: boolean;
  IsChecked?: boolean;
  Editing?: boolean;
  PriceLoaded?: boolean;
}

export interface ProductListSortOption {
  Title?: string;
  Value: ProductListSortByType;
}

export interface SearchProductListCatalogResultDetail {
  Skip: number;
  PageSize: number;
  QueryText: string;
  CatalogProducts: ProductListCatalogProduct[];
  Facets: Facets;
  HasLoadMore: boolean;
  TotalCount: number;
  pageIndex: number; // not actually returned from API
}

export interface ProductListCatalogProduct {
  IsOnList: boolean;
  Product: CatalogProduct;
}

export interface ProductListSearchResult {
  Skip?: number;
  PageSize: number;
  QueryText?: string;
  TotalNumberOfItems: number;
  Facets: Facets;
  HasLoadMore: boolean;
  pageIndex: number;
}
