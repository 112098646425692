import { getRouteName, RouteName } from 'common';
import { DependencyList, useEffect } from 'react';

export const useRouteChangeCleanup = (cleanupFn: (newRouteName: RouteName) => void, deps?: DependencyList): void => {
  useEffect(() => {
    return () => {
      const routeName = getRouteName();
      if (routeName) {
        cleanupFn(routeName);
      }
    };
  }, deps ?? []);
};
