export interface GetMarketingCampaignsRequest {
  CustomerId?: string;
}

export interface UpdateMarketingCampaignStatisticsCountRequest {
  MarketingCampaignImageId: string;
}

export interface UpdateMarketingCampaignStatisticsViewRequest {
  MarketingCampaignImageId: string;
}
export interface MarketingZoneIncrementer {
  id: MarketingZoneEnum;
  incrementBy: number;
}

export interface MarketingZone {
  MarketingZoneEnum: MarketingZoneEnum;
  Images: MarketingZoneImage[];
  PriorityImages: MarketingZoneImage[];
  ActiveIndex: number;
  PriorityIndex: number;
}

export interface MarketingZoneImage {
  MarketingCampaignImageId: string;
  MarketingCampaignId: string;
  ImageUrl: string;
  ExternalUrl?: string;
  AltText: string;
  IsPriority: boolean;
}

export enum MarketingZoneEnum {
  unset = 0,
  Mz0 = 1,
}
