import { useTheme } from '@mui/material';
import { UserCustomer } from 'common';
import { FC } from 'react';
import { CfUncontrolledDrawer } from '../../../../../../../../cf-ui/Drawer/CfUncontrolledDrawer';
import { cfFreezeNames } from '../../../../../../../../theme/theme';
import LocationSearchRadioList from '../../../../../common/LocationSearch/LocationSearchRadioList';
import { LocationBarMobile } from '../../../common/LocationBarMobile';
import TooltipContent from '../common/TooltipContent';

export interface LocationMenuProps {
  customer: UserCustomer | undefined;
  customerOptions: UserCustomer[];
  selectedCustomer: UserCustomer | undefined;
  onSelect?: (customer: UserCustomer | undefined) => void;
  forcedToggleState?: boolean;
  onDrawerOpen?: () => void;
  onDrawerClose?: () => void;
  icon: string;
  caretUpIcon: string;
  caretDownIcon: string;
}

const LocationMenu: FC<LocationMenuProps> = (props: LocationMenuProps) => {
  const {
    customer,
    icon,
    caretUpIcon,
    caretDownIcon,
    customerOptions,
    selectedCustomer,
    onSelect,
    forcedToggleState,
    onDrawerOpen,
    onDrawerClose,
  } = {
    ...props,
  };

  const theme = useTheme();

  const handleDrawerButtonClick = () => {
    if (forcedToggleState) onDrawerClose?.();
    else onDrawerOpen?.();
  };

  const onClose = (): (() => void) | undefined => {
    return onDrawerClose;
  };

  return (
    <>
      <LocationBarMobile
        iconClassName={icon}
        caretUpIcon={caretUpIcon}
        caretDownIcon={caretDownIcon}
        onClick={handleDrawerButtonClick}
        forcedToggleState={forcedToggleState}
        toolTipTitle={<TooltipContent customer={customer} />}
      />
      <CfUncontrolledDrawer
        zIndex={theme.custom.sticky.zIndex[cfFreezeNames.header] - 1}
        mode='panel'
        open={Boolean(forcedToggleState)}
        placement='right'
        onClose={onClose()}
        containerSx={{ px: 1.5, paddingTop: 1, width: 350, overflowX: 'hidden' }}
      >
        <LocationSearchRadioList
          customerOptions={customerOptions}
          selectedCustomer={selectedCustomer}
          onSelect={onSelect}
          boxSx={{ px: 1.5, py: 2 }}
          radioSx={{ mt: 2.25, ml: 1.75, mr: -1 }}
          radioBoxSx={{ px: 1 }}
        />
      </CfUncontrolledDrawer>
    </>
  );
};

export default LocationMenu;
