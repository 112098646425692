import { SxProps, Theme } from '@mui/system';
import { UserCustomer } from 'common';
import { FC, useState } from 'react';
import LocationSearchRadioList from '../../../../site/components/common/LocationSearch/LocationSearchRadioList';
import LocationSearchDialogBase from '../../../../site/components/dialogs/LocationSearchDialog/LocationSearchDialogBase';

export interface CommunicationsLocationDialogProps {
  isOpen: boolean;
  userCustomers: UserCustomer[];
  selectedUserCustomer: UserCustomer | undefined;
  boxSx?: SxProps<Theme>;
  radioSx?: SxProps<Theme>;
  radioBoxSx?: SxProps<Theme>;
  onSubmit?: (customer: UserCustomer | undefined) => void;
  onClose?: () => void;
}

const CommunicationsLocationDialog: FC<CommunicationsLocationDialogProps> = (
  props: CommunicationsLocationDialogProps
) => {
  const {
    userCustomers: customerOptions,
    selectedUserCustomer: selectedCustomer,
    isOpen: isOpen,
    onSubmit: onSubmit,
    onClose,
  } = props;

  const [selectedRadioOption, setSelectedRadioOption] = useState<UserCustomer | undefined>(selectedCustomer);

  const handleSubmit = () => {
    onSubmit?.(selectedRadioOption);
    onClose?.();
  };

  const handleRadioSelect = (userCustomer: UserCustomer | undefined) => {
    setSelectedRadioOption(userCustomer);
  };

  return (
    <>
      <LocationSearchDialogBase
        title='Select location'
        isOpen={isOpen}
        contentContainerSx={{ maxWidth: (theme) => ({ lg: theme.spacing(38.5) }) }}
        dialogContainerSx={{ maxWidth: (theme) => ({ lg: theme.spacing(40.5) }) }}
        dialogFooterSx={{ maxWidth: (theme) => ({ lg: theme.spacing(38.5) }) }}
        paperSx={{ width: (theme) => ({ lg: theme.spacing(40.5) + ' !important' }) }}
        onSubmit={handleSubmit}
        onClose={onClose}
        data-testid='communications-location-search-dialog'
        applyButtonDataTestId='communications-location-search-dialog-yes-btn'
      >
        <LocationSearchRadioList
          customerOptions={customerOptions}
          selectedCustomer={selectedCustomer}
          menuOpen={isOpen}
          onSelect={handleRadioSelect}
          radioSx={{ mt: 2.1, ml: 1.6 }}
          radioBoxSx={{
            px: 1,
            py: 0,
            height: (theme) => `calc(100vh - ${theme.spacing(32.5)})`,
            maxHeight: (theme) => theme.spacing(41),
            overflowY: 'auto',
          }}
        />
      </LocationSearchDialogBase>
    </>
  );
};

export default CommunicationsLocationDialog;
