import { EntityId, EntitySelectors } from '@reduxjs/toolkit';

export interface EntityPageLoaded {
  pageIndex: number;
  ids: string[];
  isLoading?: never;
}

export interface EntityPageLoading {
  pageIndex: number;
  pageSize: number;
  isLoading: true;
}

export type EntityPage = EntityPageLoaded | EntityPageLoading;

export interface PagedEntityState<TEntity> {
  pages: { [pageIndex: number]: EntityPage };
  entities: { [id: string]: TEntity | undefined };
  totalPageCount: number;
}

export interface PagedEntitySelectors<TEntity, VState> extends EntitySelectors<TEntity, VState> {
  selectIsLoading: (state: VState) => boolean;
  selectByPage: (state: VState, pageIndex: number) => TEntity[];
  selectIdsByPage: (state: VState, pageIndex: number) => string[];
}
export interface PagedEntityAdapter<TEntity> {
  getInitialState: (initLoading?: boolean) => PagedEntityState<TEntity>;
  setLoadedPage: (state: PagedEntityState<TEntity>, page: { pageIndex: number; entities: TEntity[] }) => void;
  setLoadingPage: (state: PagedEntityState<TEntity>, page: EntityPageLoading) => void;
  upsertOne: (state: PagedEntityState<TEntity>, entity: TEntity) => void;
  removeMany: (state: PagedEntityState<TEntity>, ids: string[]) => void;
  removePage: (state: PagedEntityState<TEntity>, pageIndex: number) => void;
  getSelectors<VState>(
    selectState: (state: VState) => PagedEntityState<TEntity>
  ): PagedEntitySelectors<TEntity, VState>;
}

export function getPageIndex(skip: number, pageSize: number): number {
  if (pageSize === 0) return 0;
  return Math.floor(skip / pageSize) - 1;
}

export function createPagedEntityAdapter<TEntity>(options: {
  selectId: (entity: TEntity) => string;
}): PagedEntityAdapter<TEntity> {
  function removePage(state: PagedEntityState<TEntity>, pageIndex: number) {
    const existingPage = state.pages[pageIndex];
    if (existingPage) {
      delete state.pages[pageIndex];
      if ('ids' in existingPage) {
        existingPage.ids.forEach((id) => {
          delete state.entities[id];
        });
      }
    }
  }

  return {
    getInitialState: (initLoading?: boolean) => {
      const state = {
        pages: {},
        entities: {},
        totalPageCount: 0,
      } as PagedEntityState<TEntity>;
      if (initLoading) {
        state.pages[0] = {
          ...({ pageIndex: 0, pageSize: 25, isLoading: true } as EntityPageLoading),
        };
      }
      return state;
    },
    setLoadedPage: (state: PagedEntityState<TEntity>, page: { pageIndex: number; entities: TEntity[] }) => {
      removePage(state, page.pageIndex);
      const ids: string[] = [];

      page.entities.forEach((entity) => {
        const id = options.selectId(entity);
        if (!state.entities[id]) {
          ids.push(id);
          state.entities[id] = entity;
        } else {
          //console.log('duplicate key:', id);
        }
      });

      if (!state.pages[page.pageIndex]) state.totalPageCount = state.totalPageCount + 1;

      state.pages[page.pageIndex] = {
        pageIndex: page.pageIndex,
        ids,
      };
    },
    setLoadingPage: (state: PagedEntityState<TEntity>, page: EntityPageLoading) => {
      removePage(state, page.pageIndex);
      state.pages[page.pageIndex] = {
        ...page,
      };
    },
    removePage: (state: PagedEntityState<TEntity>, pageIndex: number) => {
      if (state.pages[pageIndex]) state.totalPageCount = state.totalPageCount - 1;
      return removePage(state, pageIndex);
    },

    upsertOne: (state: PagedEntityState<TEntity>, entity: TEntity) => {
      const id = options.selectId(entity);
      if (state.entities[id]) state.entities[id] = { ...entity };
    },
    removeMany: (state: PagedEntityState<TEntity>, ids: string[]) => {
      const set = new Set<string>(ids);
      for (let i = 0; i < state.totalPageCount; i++) {
        const page = state.pages?.[i];
        if (page && 'ids' in page) {
          const newIds = page.ids.filter((id: string) => !set.has(id));
          state.pages[i] = { ...page, ids: newIds };
        }
      }
      ids.forEach((_id: string) => state.entities[_id] && delete state.entities[_id]);
    },
    getSelectors: <VState>(selectState: (state: VState) => PagedEntityState<TEntity>) => {
      return {
        selectByPage: (state: VState, pageIndex: number) => {
          const _state = selectState(state);
          const page = _state.pages[pageIndex];
          if (!page || page.isLoading) return [];
          return page.ids.map((id) => {
            const entity = _state.entities[id];
            if (entity) return entity;
          });
        },
        selectIdsByPage: (state: VState, pageIndex: number) => {
          const _state = selectState(state);
          const page = _state.pages[pageIndex];
          if (!page || page.isLoading) return [];
          return page.ids;
        },
        selectIds: (state: VState) => Object.keys(selectState(state)?.entities ?? []),
        selectEntities: (state: VState) => selectState(state)?.entities,
        selectAll: (state: VState) => Object.values(selectState(state)?.entities ?? []),
        selectById: (state: VState, id: EntityId) => selectState(state).entities[id],
        selectTotal: (state: VState) => Object.keys(selectState(state).entities).length,
        selectIsLoading: (state: VState) =>
          Object.values(selectState(state).pages).filter((page) => page.isLoading).length > 0,
      } as PagedEntitySelectors<TEntity, VState>;
    },
  };
}
