import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BusinessUnitKeyType } from '../../api/models/api-shared.enums';
import { DebugFacets, DebugSearchProductCatalogResultDetail } from '../../api/models/product-catalog.models';

// State
export interface SearchAnalysisState {
  BusinessUnitKey: BusinessUnitKeyType;
  CustomerNumber?: string;
  OperationCompanyNumber?: string;
  SearchText?: string;
  PageIndex: number;
  PageSize: number;
  NumberOfPages: number;
  TotalResults: number;
  Facets: DebugFacets;
  DebugLogging: string[];
  ProductListLoading: boolean;
}

const initialState: SearchAnalysisState = {
  BusinessUnitKey: BusinessUnitKeyType.PerformanceFoodService,
  CustomerNumber: '',
  OperationCompanyNumber: '',
  SearchText: '',
  PageIndex: 0,
  PageSize: 25,
  NumberOfPages: 0,
  TotalResults: 0,
  Facets: { BadgeTitle: [], ProductCategory: [], PackSizeValue: [], StorageDisplay: [] },
  DebugLogging: [],
  ProductListLoading: false,
};

// Reducers
export const searchAnalysisSlice = createSlice({
  name: 'searchAnalysis',
  initialState: initialState,
  reducers: {
    resetSearchAnalysisState: () => initialState,
    setProductSearchResults: (
      state: SearchAnalysisState,
      action: PayloadAction<DebugSearchProductCatalogResultDetail>
    ) => {
      state.SearchText = action.payload.SearchText;
      state.PageSize = action.payload.PageSize;
      state.NumberOfPages = action.payload.NumberOfPages;
      state.TotalResults = action.payload.TotalResults;
      state.Facets = action.payload.Facets;
      state.DebugLogging = action.payload.DebugLogging;
    },
    setProductListLoading: (state: SearchAnalysisState, action: PayloadAction<boolean>) => {
      state.ProductListLoading = action.payload;
    },
    setPageIndex: (state: SearchAnalysisState, action: PayloadAction<number>) => {
      state.PageIndex = action.payload;
    },
    setPageSize: (state: SearchAnalysisState, action: PayloadAction<number>) => {
      state.PageSize = action.payload;
    },
    setBusinessUnitKey: (state: SearchAnalysisState, action: PayloadAction<BusinessUnitKeyType>) => {
      state.BusinessUnitKey = action.payload;
    },
    setCustomerNumber: (state: SearchAnalysisState, action: PayloadAction<string>) => {
      state.CustomerNumber = action.payload;
    },
    setOperationCompanyNumber: (state: SearchAnalysisState, action: PayloadAction<string>) => {
      state.OperationCompanyNumber = action.payload;
    },
    setSearchText: (state: SearchAnalysisState, action: PayloadAction<string>) => {
      state.SearchText = action.payload;
    },
  },
});
