import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import ScrollToTopButton from './ScrollToTopButton';

export const WindowScrollToTopButton = (): JSX.Element => {
  const scrollThreshold = 0;
  const [isScrollThresholdMet, setIsScrollThresholdMet] = useState<boolean>(false);

  // Reevaluate a threshold property because the reversing useScrollTrigger
  // essentially negates its threshold evaluation.
  const handleWindowScroll = () => {
    if (window.scrollY > scrollThreshold && !isScrollThresholdMet) {
      setIsScrollThresholdMet(true);
    } else {
      setIsScrollThresholdMet(false);
    }
  };
  const debounceHandleWindowScroll = debounce(() => handleWindowScroll(), 300);

  // Assign a scroll listener with a debounced handler when the component mounts.
  // Remove the scroll listener when the component unmounts.
  useEffect(() => {
    window.addEventListener('scroll', debounceHandleWindowScroll);
    return () => window.removeEventListener('scroll', debounceHandleWindowScroll);
  }, []);

  return <ScrollToTopButton scrollThreshold={scrollThreshold} isScrollThresholdMet={isScrollThresholdMet} />;
};

export default WindowScrollToTopButton;
