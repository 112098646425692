import { RootState, setErrorDialogContent, useAppDispatch, useAppSelector } from 'common';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CfErrorDialog } from '../../../../cf-ui/dialogs/ErrorDialog/CfErrorDialog';

const ErrorDialog = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const title = useAppSelector((x: RootState) => x.global.errorDialogTitle);
  const messages = useAppSelector((x: RootState) => x.global.errorDialogMessages);
  const pageRefresh = useAppSelector((x: RootState) => x.global.errorPageRefresh);

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (open) closeDialog();
  }, [location.pathname]);

  useEffect(() => {
    if (messages.length > 0) setOpen(true);
  }, [messages, title]);

  const closeDialog = () => {
    dispatch(setErrorDialogContent('', []));
    setOpen(false);
    if (pageRefresh) window.location.reload();
  };

  return <CfErrorDialog open={open} title={title} onClose={closeDialog} messages={messages} />;
};

export default ErrorDialog;
