import { AccountReceivableDetail } from '../api/models/account-receivable.models';
import { InvoiceHeader } from '../api/models/invoice.models';
import { UserCustomer } from '../api/models/site.models';

export enum InvoiceDetailsSelectOption {
  LineNumber = 'Invoice line #',
  ProductNumber = 'Product #',
  ShippedQuantity = 'Shipped qty',
  ExtendedPrice = 'Extended price',
}

export interface InvoiceDetailsHistoryState {
  accountReceivableDetail?: AccountReceivableDetail;
  invoiceHeader?: InvoiceHeader;
}

export interface InvoiceHistoryState {
  customers?: UserCustomer[];
  localStartDate?: string;
  localEndDate?: string;
  dateOption?: string;
}
