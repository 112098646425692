import { FC } from 'react';
import { CfContainedButton } from '../../../../cf-ui/buttons/CfContainedButton';
import { openAccountSideBarActions } from '../../../../hooks/account/message-center/useAccountSideBarActions';

const AccountSideBarDrawerButton: FC = (): JSX.Element => {
  return (
    <CfContainedButton
      sx={{ minWidth: 71 }}
      onClick={(e) => openAccountSideBarActions(e.currentTarget)}
      data-testid='account-sidebar-drawer-button'
    >
      Menu
    </CfContainedButton>
  );
};

export default AccountSideBarDrawerButton;
