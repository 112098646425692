import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import {
  convertTimeToUnit,
  convertToMilliseconds,
  getTimeBetween,
  getTimeUnitMillisecondFactor,
  getTimeUnitModulus,
  TimeUnit,
  TimeUnits,
  useTimer,
} from 'common';
import { FC, PropsWithChildren, useEffect } from 'react';

/**
 *
 */
export interface CfCountdownThresholdSx {
  milliseconds: number;
  sx?: SxProps<Theme>;
}

/**
 * the properties which describe how the CfCountdown component should present and behave
 */
export interface CfCountdownProps {
  date: Date | string | undefined;
  minUnit?: TimeUnit;
  maxUnit?: TimeUnit;
  padZeros?: boolean;
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  thresholds?: CfCountdownThresholdSx[];
  onExpiration?: () => unknown;
  sx?: SxProps<Theme>;
}

/**
 * Renders a countdown in the specfied format e.g. 01:23:59:59:999
 * @param date used to derive the total time of the countdown
 * @param minUnit determines the smallest division of time to render the countdown with
 * @param maxUnit determines the largest division of time to render the countdown with
 * @param padZeros determines whether to prepend a '0' to time values between zero (0) and nine (9)
 * @param prefix rendered before the countdown
 * @param suffix rendered after the countdown
 * @param thresholds determines how to style the component based on the remaining time of the countdown in milliseconds
 * @param onExpiration executed when the countdown reaches zero (0)
 * @param sx passed to the (root) Box component's sx property
 */
export const CfCountdown: FC<CfCountdownProps> = (props: PropsWithChildren<CfCountdownProps>) => {
  const now = new Date();
  const totalTime = getTimeBetween(now, props.date ?? now);
  const totalTimeSeconds = convertTimeToUnit(totalTime, 'millisecond', 'second', true);
  const minUnit = props.minUnit ?? 'millisecond';
  const maxUnit = props.maxUnit ?? 'day';

  const sx = {
    ...props.sx,
    ...props.thresholds?.find((t) => t.milliseconds >= totalTime)?.sx,
  };

  const displayText = () => {
    // Set up our active units and a results array.
    const units = TimeUnits.slice(TimeUnits.indexOf(minUnit), TimeUnits.indexOf(maxUnit) + 1);
    const results = units.map(() => 0);

    // Loop through our active units to calculate the remaining time
    // in whole numbers for each unit.
    units.reduce((time, unit, i, a) => {
      const factor = getTimeUnitMillisecondFactor(unit);
      const value =
        i === a.length - 1 ? Math.floor(time / factor) : Math.floor((time / factor) % getTimeUnitModulus(unit));
      results[i] = value > 0 ? value : 0;
      return time - convertToMilliseconds(value, unit);
    }, totalTime);

    return results
      .reverse()
      .map((v) => (props.padZeros && v <= 9 ? `0${v}` : v))
      .join(':');
  };

  useEffect(() => {
    if (props.date && totalTimeSeconds > 0) {
      restart();
    } else {
      stop();
    }
  }, [props.date]);

  const { restart, stop } = useTimer(totalTimeSeconds, () => {
    props.onExpiration?.();
  });

  return (
    <Box sx={sx}>
      {props.prefix} {displayText()} {props.suffix}
    </Box>
  );
};
