import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { EditUserSettingRequest, UserSettingPreference } from '../../api/models/user-setting.models';
import UserSettingService from '../../api/services/user-setting.service';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { AppDispatch, AppThunk } from '../store';
import { userSlice } from './user.slice';

// hooks
const userSettingService = UserSettingService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Retrieves user setting data from the api related to the authorized user
 *
 * @returns void
 */
export const getCurrentUserSetting =
  (): AppThunk<Promise<UserSettingPreference | undefined>> =>
  async (dispatch: AppDispatch): Promise<UserSettingPreference | undefined> => {
    try {
      const { data } = await userSettingService.getCurrentUserSetting();

      if (data.IsSuccess) {
        dispatch(userSlice.actions.setUserSettingPreference(data.ResultObject));
        return Promise.resolve(data.ResultObject);
      } else {
        return Promise.resolve(undefined);
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      return Promise.resolve(undefined);
    }
  };

/**
 * Sends a request to the api to update a user setting preference
 *
 * @param userSettingPreference - data representing user preferences related to the application interface
 * @param params.optimistic - boolean flag indicating whether to preemptively update the UserState before the api request is sent
 * @returns void
 */
export const updateCurrentUserSetting =
  (userSettingPreference: UserSettingPreference, params?: { optimistic?: boolean }): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const request: EditUserSettingRequest = {
        SettingsData: userSettingPreference,
        OrderHistoryViewDefault: undefined,
      };

      if (params?.optimistic) {
        dispatch(userSlice.actions.setUserSettingPreference(userSettingPreference));
      }

      const { data } = await userSettingService.editUserSetting(request);
      if (data.IsSuccess) {
        dispatch(userSlice.actions.setUserSettingPreference(data.ResultObject.SettingsData));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };
