import { CustomerSupportType, OverviewSlideGroups } from '../../../api/models/customer-support.enums';
import { CustomerSupportTutorialItem } from '../../../models';

export const accountTutorials: CustomerSupportTutorialItem[] = [
  {
    tutorialTitle: 'User profile and preferences',
    tutorialText:
      'Your profile allows you to update your display name, email, contact phone numbers and change your password. We show you how to manage these account settings. ​',
    customerSupportType: CustomerSupportType.Account,
    overviewGroup: OverviewSlideGroups.UserProfile,
  },
  {
    tutorialTitle: 'Message center',
    tutorialText:
      'If you want to learn more about how to view and compose messages to send to your team, or create and manage message groups, view this tutorial. Based on your profile settings, you may only have access to view messages. Contact your account manager to inquire about your settings. ',
    customerSupportType: CustomerSupportType.Account,
    overviewGroup: OverviewSlideGroups.MessageCenter,
  },
  {
    tutorialTitle: 'Communication preferences ',
    tutorialText:
      'View this tutorial to understand how to control the communication you receive and how you receive it. ​',
    customerSupportType: CustomerSupportType.Account,
    overviewGroup: OverviewSlideGroups.CommunicationsPreferences,
  },
];
