import { SeverityLevel } from '@microsoft/applicationinsights-web';
import CustomerGroupService from '../../api/services/customer-group.service';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { globalSlice } from '../common/global.slice';
import { AppDispatch, AppThunk, RootState } from '../store';
import { customerGroupSelectedSlice } from './customer-groups-selected.slice';
import { customerGroupsSlice } from './customer-groups.slice';

const customerGroupService = CustomerGroupService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Calls and stores the result of the GetCustomerGroups API call
 *
 * @returns NULL
 */
export const getCustomerGroups =
  (): AppThunk<Promise<void>> => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (getState().customerGroups.customerGroupsLoaded || getState().customerGroups.customerGroupsLoading) {
        return;
      }

      if (!getState().customerGroups.customerGroupsLoading) {
        dispatch(customerGroupsSlice.actions.setCustomerGroupsLoading(true));
      }

      const { data } = await customerGroupService.getCustomerGroups();

      if (data.IsSuccess) {
        dispatch(customerGroupsSlice.actions.setCustomerGroups(data.ResultObject));
        dispatch(customerGroupsSlice.actions.setCustomerGroupsLoaded(true));
        // refer to CustomerGroups.tsx comment for clear detail
        const selectedCustomerGroupHeaderId = getState().customerGroupSelected.selectedCustomerGroupHeaderId;
        const selectedCustomerGroup = data.ResultObject.find(
          (d) => d.CustomerGroupHeaderId === selectedCustomerGroupHeaderId
        );
        if (selectedCustomerGroupHeaderId && selectedCustomerGroup) {
          dispatch(
            customerGroupSelectedSlice.actions.setCustomerGroupBasic({
              CustomerGroupHeaderId: selectedCustomerGroup?.CustomerGroupHeaderId,
              CustomerGroupName: selectedCustomerGroup?.CustomerGroupName,
              CustomerCount: selectedCustomerGroup.CustomerCount,
            })
          );
        }
      } else {
        dispatch(customerGroupsSlice.actions.resetCustomerGroups());
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      dispatch(customerGroupsSlice.actions.resetCustomerGroups());
    } finally {
      dispatch(customerGroupsSlice.actions.setCustomerGroupsLoading(false));
    }
  };

/**
 * Resets the customer-group slice to it's initial values
 *
 * @returns NULL
 */
export const resetCustomerGroups = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(customerGroupsSlice.actions.resetCustomerGroups());
};

/**
 * Sets the unassigned location
 *
 * @param unassignedLocations - The list of unassigned location in customer group
 * @returns NULL
 */
export const setUnassignedLocationCustomerGroup = (): AppThunk => async (dispatch: AppDispatch) => {
  const { data } = await customerGroupService.getCustomerGroupsUnassignedLocations();
  if (data.IsSuccess) {
    dispatch(customerGroupsSlice.actions.setCustomerGroupUnassignedLocations(data.ResultObject));
  } else {
    dispatch(customerGroupsSlice.actions.resetCustomerGroupUnassignedLocations());
  }
};
