import { Link, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import {
  AppRoutes,
  customerSupportSlice,
  RootState,
  RouteName,
  updateViewedSlideGroup,
  useAppDispatch,
  useAppSelector,
} from 'common';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CfDialog } from '../../../../../../cf-ui/dialogs/Dialog/CfDialog';
import { DeviceType, useBreakpoint } from '../../../../../../hooks/useBreakpoint';

const SkipOverviewDialog: React.FC = () => {
  const deviceType = useBreakpoint().deviceType;
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const isSkipDialogOpen = useAppSelector((s: RootState) => s.customerSupport.showSkipDialog);
  const currentSlideGroup = useAppSelector((s: RootState) => s.customerSupport.currentSlideGroup);

  const handleOnSubmit = () => {
    if (currentSlideGroup !== undefined) {
      dispatch(updateViewedSlideGroup(currentSlideGroup));
      dispatch(customerSupportSlice.actions.resetSlideGroup());
    }
  };

  const handleRouteChange = () => {
    dispatch(customerSupportSlice.actions.resetSlideGroup());
    history.push(AppRoutes[RouteName.CustomerSupport].Path);
  };
  const handleKeyPressRouteChange = (event: React.KeyboardEvent) => {
    if ('key' in event && event.key === 'Enter') {
      event.preventDefault();
    }
    dispatch(customerSupportSlice.actions.resetSlideGroup());
    history.push(AppRoutes[RouteName.CustomerSupport].Path);
  };

  return (
    <CfDialog
      isOpen={isSkipDialogOpen}
      primaryButtonProps={{
        children: 'Yes, skip overview',
        color: 'primary',
        onClick: handleOnSubmit,
        size: deviceType === DeviceType.Desktop ? 'small' : 'medium',
      }}
      secondaryButtonProps={{
        children: 'Cancel',
        color: 'secondary',
        onClick: () => dispatch(customerSupportSlice.actions.setShowSkipDialog(false)),
        size: deviceType === DeviceType.Desktop ? 'small' : 'medium',
      }}
      onClose={() => dispatch(customerSupportSlice.actions.setShowSkipDialog(false))}
      closeButtonDataTestId='skip-overview-close-btn'
      primaryButtonDataTestId='skip-overview-yes-btn'
      titleDataTestId='skip-overview-dialog-title'
      paperSx={{
        width: (theme) => ({
          xs: theme.spacing(36.5) + ' !important',
          lg: theme.spacing(45) + ' !important',
          xl: theme.spacing(41) + ' !important',
        }),
        height: (theme) => ({
          xs: theme.spacing(27) + ' !important',
          lg: theme.spacing(24.5) + ' !important',
          xl: theme.spacing(23) + ' !important',
        }),
      }}
      headerSx={{ float: 'right', width: '30px', p: 0 }}
      dialogFooterSx={{
        display: 'flex',
        pt: 3,
      }}
      dialogCloseButtonSx={{ p: { xs: 0 } }}
      data-testid='skip-overview-dialog'
      hasCloseButton
    >
      <Typography variant='h4'>Skip overview?</Typography>
      <Stack direction='column' spacing={2} sx={{ pt: 2 }}>
        <Typography variant='responsiveParagraph'>
          {
            'You may complete or skip this overview and it will not show again. If you wish to view it another time, you can find it in '
          }
          <Link onClick={handleRouteChange} onKeyPress={handleKeyPressRouteChange} tabIndex={0}>
            <Typography variant='responsiveParagraph' color={theme.palette.primary.main} sx={{ cursor: 'pointer' }}>
              Customer support
            </Typography>
          </Link>
          .
        </Typography>
      </Stack>
    </CfDialog>
  );
};

export default SkipOverviewDialog;
