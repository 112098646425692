import { toCurrencyString } from './currency';
import { toLocalDateString, toLocalFullDateString } from './date';

export type CustomObjectFilterType = 'date' | 'currency' | 'custom';
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export type CustomObjectFilter<T extends Record<string, any>> = {
  field: keyof T;
  type: CustomObjectFilterType;
  filterFunction?: (val: string, queryString: string, obj: T) => boolean;
};

/**
 * Filter an array of objects T by the object's field's values.
 *
 * @param {T[]} objects The objects to be filtered.
 * @param {string} queryString The query string to filter against.
 * @param {(keyof T)[]?} includedFields Fields to include in the filter, defaults to all fields if not supplied.
 * @param {CustomObjectFilter<T>[]?} customFilters Custom filters applied to specific fields to overwrite default filtering functionality.
 */
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const filterObjects = <T extends Record<string, any>>(
  objects: T[],
  queryString: string,
  includedFields?: (keyof T)[],
  customFilters?: CustomObjectFilter<T>[]
): T[] => {
  if (!queryString) return objects;
  const filteredObjects: T[] = [];

  objects.forEach((obj: T) => {
    for (const [fieldName, value] of Object.entries(obj)) {
      const fieldsToCheck = includedFields ? includedFields : (Object.keys(obj) as (keyof T)[]);
      if (fieldsToCheck.some((field: keyof T) => field === fieldName)) {
        let found = false;

        const customFilter = customFilters?.find((cf: CustomObjectFilter<T>) => cf.field === fieldName);
        if (customFilter) {
          if (customFilter.filterFunction) {
            found = customFilter.filterFunction?.(
              value?.toString().toLocaleLowerCase(),
              queryString.trim().toLocaleLowerCase(),
              obj
            );
          } else {
            if (customFilter.type === 'date') {
              found =
                toLocalDateString(value).includes(queryString.trim().toLocaleLowerCase()) ||
                toLocalFullDateString(value).includes(queryString.trim().toLocaleLowerCase());
            } else if (customFilter.type === 'currency') {
              found =
                toCurrencyString(value).includes(queryString.trim().toLocaleLowerCase()) ||
                value?.toString().includes(queryString.trim().toLocaleLowerCase());
            }
          }
        } else {
          found = value.toString().toLocaleLowerCase().includes(queryString.trim().toLocaleLowerCase());
        }

        if (found) {
          filteredObjects.push(obj);
          break;
        }
      }
    }
  });

  return filteredObjects;
};
