import { Box, Collapse, Icon, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { FC, PropsWithChildren, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../hooks/useQueryParams';
import { StyledHeader, StyledHeaderBox, StyledSubheader } from './CfDropdown.styles';

// TODO: Revamp CfDropdownVariant to make use of enums for easy maintenance
export type CfDropdownVariant = 'header' | 'subheader' | 'card';

export interface CfDropdownProps {
  id?: string;
  variant?: CfDropdownVariant;
  title?: string | React.ReactNode;
  subTitle?: React.ReactNode;
  titleWhenOpen?: string | React.ReactNode;
  titleSx?: SxProps<Theme>;
  contentWhenHidden?: string | React.ReactNode;
  isCollapsedByDefault?: boolean;
  hideCaret?: boolean;
  disabled?: boolean;
  headerBoxSx?: SxProps<Theme>;
  subHeaderBoxSx?: SxProps<Theme>;
  caretClass?: 'angle' | 'caret' | 'chevron';
  caretSx?: SxProps<Theme>;
  caretSize?: 'small' | 'medium' | 'large' | 'inherit';
  childrenBoxSx?: SxProps<Theme>;
  sx?: SxProps<Theme>;
  onOpen?: () => void;
  onClose?: () => void;
  headerTestId?: string;
  additionalAction?: React.ReactNode;
  additionalActionSx?: SxProps<Theme>;
  disableHeaderClick?: boolean;
  displayDivider?: boolean;
  preserveDropdownState?: boolean;
}

/**
 * @param variant - Value indicating if the dropsown is of the header or subheader variant
 * @param title - The title for the dropdown header to use when it is not open
 * @param subTitle - The subtitle for the dropdown header to use
 * @param titleWhenOpen - The title for the dropdown to shown when it is open
 * @param titleSx - Styling for the title to use
 * @param contentWhenHidden - The content to show when the dropdown is not open
 * @param isCollapsedByDefault - Boolean indicating if the dropdown should be closed when it is rendered
 * @param hideCaret - Boolean indicatining if the caret icon should be hidden
 * @param disabled - Boolean indicating if the dropdown should be disabled
 * @param headerBoxSx - Additional styling for the header box to use
 * @param subHeaderBoxSx - Addotional stylling for the subheader box to use
 * @param caretClass - The class for the caret icon to use (angle, caret, chevron)
 * @param caretSx - Additional styling of the caret icon
 * @param caretSize - The size of the caret (small, medium, large, inherit)
 * @param childrenBoxSx - Styling of the box which contains the child components
 * @param sx - Styling of the box which wraps the dropdown
 * @param onOpen - Method to call on dropdown open
 * @param onClose - Method to call in dropdown close
 * @param headerTestId - Test id of the header
 * @param additionalAction - Component which preceeds the caret icon
 * @param additionalActionSx - Styling for the additionalAction component
 * @param disableHeaderClick - Boolean indicating if the header can be clicked on
 * @param displayDivider - Boolean indicating if the divider between the header and the rest of the dropdown should be displayed
 */
export const CfDropdown: FC<PropsWithChildren<CfDropdownProps>> = (props: PropsWithChildren<CfDropdownProps>) => {
  const history = useHistory<any>();
  const queryParams = useQueryParams<any>();

  const id = `${props.id ?? ''}-dropdown-expanded`;
  const priorIsExpanded = history.location?.state?.[id] ?? undefined;

  const [isOpen, setIsOpen] = useState(priorIsExpanded ?? !props.isCollapsedByDefault ?? true);

  let iconClassUp;
  let iconClassDown;

  if (props.variant === 'header' || props.variant === 'card') {
    iconClassUp = props.caretClass ? `fa-${props.caretClass}-up` : 'fa-angle-up';
    iconClassDown = props.caretClass ? `fa-${props.caretClass}-down` : 'fa-angle-down';
  } else if (props.variant === 'subheader') {
    iconClassUp = props.caretClass ? `fa-${props.caretClass}-up` : 'fa-caret-up';
    iconClassDown = props.caretClass ? `fa-${props.caretClass}-down` : 'fa-caret-down';
  }

  const iconClass = isOpen ? iconClassUp : iconClassDown;

  const isTitleString = typeof props.title === 'string' || props.title instanceof String;
  const isPlaceholderString = typeof props.titleWhenOpen === 'string' || props.titleWhenOpen instanceof String;

  const toggleIsOpen = () => {
    if (props.disabled != true) {
      if (isOpen) props.onClose?.();
      else props.onOpen?.();
      setIsOpen(!isOpen);
      if (props.preserveDropdownState) {
        const newState = {
          ...history.location.state,
        };
        newState[id] = !isOpen;
        history.replace(history.location.pathname + '?' + new URLSearchParams(queryParams).toString(), newState);
      }
    }
  };

  return (
    <Box sx={{ marginLeft: props.variant === 'subheader' ? '10px' : 'initial', ...props.sx }}>
      <StyledHeaderBox
        onClick={() => !props.disableHeaderClick && toggleIsOpen()}
        sx={{
          mb: 1,
          cursor: props.disabled || props.disableHeaderClick ? 'default' : 'pointer',
          ...props.headerBoxSx,
        }}
        data-testid={props.headerTestId}
      >
        {props.variant === 'header' && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              ...props.subHeaderBoxSx,
            }}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') toggleIsOpen();
            }}
          >
            {isOpen && props.titleWhenOpen && (
              <>
                {isPlaceholderString && (
                  <StyledHeader variant='h5' sx={props.titleSx}>
                    {props.titleWhenOpen}
                  </StyledHeader>
                )}
                {!isPlaceholderString && props.titleWhenOpen}
              </>
            )}
            {((props.titleWhenOpen && !isOpen) || !props.titleWhenOpen) && props.title && (
              <>
                {isTitleString && (
                  <StyledHeader data-testid={props.headerTestId} variant='h5' sx={props.titleSx}>
                    {props.title}
                  </StyledHeader>
                )}
                {!isTitleString && props.title}
              </>
            )}

            <Box sx={props.additionalActionSx}>
              {props.additionalAction}

              {!Boolean(props.hideCaret) && (
                <Icon
                  className={iconClass}
                  fontSize={props.caretSize ?? 'small'}
                  color='primary'
                  sx={{ height: 'fit-content', cursor: 'pointer', alignSelf: 'center', ...props.caretSx }}
                  onClick={() => toggleIsOpen()}
                />
              )}
            </Box>
          </Box>
        )}

        {props.variant === 'subheader' && (
          <Box
            sx={{ display: 'flex', ...props.subHeaderBoxSx }}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') toggleIsOpen();
            }}
          >
            {!isOpen && props.titleWhenOpen && (
              <>
                {isPlaceholderString && <StyledSubheader>{props.titleWhenOpen}</StyledSubheader>}
                {!isPlaceholderString && props.titleWhenOpen}
              </>
            )}
            {!props.titleWhenOpen && props.title && (
              <>
                {isTitleString && <StyledSubheader>{props.title}</StyledSubheader>}
                {!isTitleString && props.title}
              </>
            )}
            {!Boolean(props.hideCaret) && (
              <Icon
                className={iconClass}
                fontSize={props.caretSize ?? 'small'}
                color='primary'
                sx={{ paddingLeft: 1, ...props.caretSx }}
              />
            )}
          </Box>
        )}

        {props.variant === 'card' && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              ...props.subHeaderBoxSx,
            }}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') toggleIsOpen();
            }}
          >
            {!isOpen && props.titleWhenOpen && (
              <>
                {isPlaceholderString && (
                  <Typography variant='responsiveParagraphBold'>{props.titleWhenOpen}</Typography>
                )}
                {!isPlaceholderString && props.titleWhenOpen}
              </>
            )}
            {!props.titleWhenOpen && props.title && (
              <>
                {isTitleString && <Typography variant='responsiveParagraphBold'>{props.title}</Typography>}
                {!isTitleString && props.title}
              </>
            )}
            {!Boolean(props.hideCaret) && (
              <Icon
                className={iconClass}
                fontSize={props.caretSize ?? 'small'}
                color='primary'
                sx={{ paddingLeft: 1, ...props.caretSx }}
              />
            )}
          </Box>
        )}
      </StyledHeaderBox>
      {props.displayDivider && (
        <Box
          sx={{ width: '100%', height: 2, mt: -0.5, mb: 1, backgroundColor: (theme) => theme.palette.coolGrey[200] }}
        />
      )}

      <Typography variant='mobileParagraphSmall'>{props.subTitle}</Typography>
      <Collapse in={isOpen}>
        <Box display={isOpen ? 'hidden' : ''} sx={props.childrenBoxSx}>
          {props.children}
        </Box>
      </Collapse>
      {props.contentWhenHidden && !isOpen && <Box>{props.contentWhenHidden}</Box>}
    </Box>
  );
};

CfDropdown.defaultProps = {
  variant: 'header',
};
