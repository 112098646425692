import { Icon, Typography } from '@mui/material';
import { FC } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { StyledIconButton, StyledMonthPickerBox } from './Shared.styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface

/**
 * the properties which describe how the CfMonthPicker component should present and behave
 */
export interface CfMonthPickerProps {
  monthDate: Date;
  decreaseMonth(): void;
  increaseMonth(): void;
  testId?: string;
}

/**
 * Allows users to increment and decrement a date value to the next and previous month
 * @param monthDate the selected date value
 * @param decreaseMonth the handler method assigned to the decrementing button
 * @param increaseMonth the handler method assigned to the incrementing button
 * @param testId augmented and passed to both buttons and the month name's Typography component
 * @returns
 */
export const CfMonthPicker: FC<CfMonthPickerProps> = (props: CfMonthPickerProps) => {
  const { monthDate, decreaseMonth, increaseMonth } = props;
  return (
    <StyledMonthPickerBox>
      <StyledIconButton
        aria-label='Previous Month'
        onClick={decreaseMonth}
        sx={{ left: 0, marginLeft: '4.5px' }}
        tabIndex={-1}
        data-testid={`${props.testId}-prev-button`}
      >
        <Icon className='fa-caret-left' fontSize='medium' />
      </StyledIconButton>
      <Typography variant='paragraph' data-testid={`${props.testId}-label`}>
        {monthDate.toLocaleString('en-US', {
          month: 'long',
          year: 'numeric',
        })}
      </Typography>
      <StyledIconButton
        aria-label='Next Month'
        onClick={increaseMonth}
        sx={{ right: 0, marginRight: '4.5px' }}
        tabIndex={-1}
        data-testid={`${props.testId}-next-button`}
      >
        <Icon className='fa-caret-right' fontSize='medium' />
      </StyledIconButton>
    </StyledMonthPickerBox>
  );
};
