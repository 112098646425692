import { BrowserInfo } from '../../../models/common.models';

const getBrowser = (): BrowserInfo => {
  const userAgent = navigator.userAgent;
  let browser = 'unknown';
  // Detect browser name
  browser = /ucbrowser/i.test(userAgent) ? 'UCBrowser' : browser;
  browser = /edg/i.test(userAgent) ? 'Edge' : browser;
  browser = /googlebot/i.test(userAgent) ? 'GoogleBot' : browser;
  browser = /chromium/i.test(userAgent) ? 'Chromium' : browser;
  browser = /firefox|fxios/i.test(userAgent) && !/seamonkey/i.test(userAgent) ? 'Firefox' : browser;
  browser =
    /chrome|crios/i.test(userAgent) && !/opr|opera|chromium|edg|ucbrowser|googlebot/i.test(userAgent)
      ? 'Chrome'
      : browser;
  browser =
    /safari/i.test(userAgent) && !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i.test(userAgent)
      ? 'Safari'
      : browser;

  // detect browser version
  switch (browser) {
    case 'UCBrowser':
      return { name: browser, version: browserVersion(userAgent, /(ucbrowser)\/([\d\.]+)/i) };
    case 'Edge':
      return { name: browser, version: browserVersion(userAgent, /(edge|edga|edgios|edg)\/([\d\.]+)/i) };
    case 'GoogleBot':
      return { name: browser, version: browserVersion(userAgent, /(googlebot)\/([\d\.]+)/i) };
    case 'Chromium':
      return { name: browser, version: browserVersion(userAgent, /(chromium)\/([\d\.]+)/i) };
    case 'Firefox':
      return { name: browser, version: browserVersion(userAgent, /(firefox|fxios)\/([\d\.]+)/i) };
    case 'Chrome':
      return { name: browser, version: browserVersion(userAgent, /(chrome|crios)\/([\d\.]+)/i) };
    case 'Safari':
      return { name: browser, version: browserVersion(userAgent, /(version)\/([\d\.]+)/i) };
    case 'Opera':
      return { name: browser, version: browserVersion(userAgent, /(opera|opr)\/([\d\.]+)/i) };

    default:
      return { name: 'unknown', version: '0' };
  }

  function browserVersion(userAgent: string, regex: RegExp) {
    const match = userAgent.match(regex);
    return match ? match[2] : '0';
  }
};

export default getBrowser;
