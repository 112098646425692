import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { FileResult } from '../../api/models/api-shared.models';
import {
  ManagedUserStatusTypes,
  ManageUser,
  ManageUserCustomerPermissions,
  UserManagementFilterData,
} from '../../api/models/manage-users.models';
import { filterObjects, getTimeBetween, toLocalDateString } from '../../helpers';
import { normalizeKey } from '../../helpers/general/string';
import { RootState } from '../store';

//Adapters

const getSortComparer = (a: ManageUser, b: ManageUser): number => {
  // Defines the order of statuses
  const statusOrder: Record<ManagedUserStatusTypes, number> = {
    [ManagedUserStatusTypes.Expired]: 0,
    [ManagedUserStatusTypes.Pending]: 1,
    [ManagedUserStatusTypes.Inactive]: 2,
    [ManagedUserStatusTypes.Active]: 3,
  };

  // Compares by status order
  if (statusOrder[a.ManagedUserStatus] < statusOrder[b.ManagedUserStatus]) return -1;
  if (statusOrder[a.ManagedUserStatus] > statusOrder[b.ManagedUserStatus]) return 1;

  //If status is Inactive then first sort by last login date
  if (a.ManagedUserStatus === ManagedUserStatusTypes.Inactive)
    if (new Date(b?.LastLoginDate ?? '').valueOf() ^ new Date(a?.LastLoginDate ?? '').valueOf()) {
      if (!b.LastLoginDate) return 1;
      else if (!a.LastLoginDate) return -1;
      else {
        const dateDiff =
          new Date(toLocalDateString(new Date(a?.LastLoginDate ?? ''))).valueOf() -
          new Date(toLocalDateString(new Date(b?.LastLoginDate ?? ''))).valueOf();
        if (dateDiff !== 0) {
          return dateDiff;
        }
      }
    }

  // If status is the same, compares by first name
  if (a.FirstName.toLowerCase() < b.FirstName.toLowerCase()) return -1;
  if (a.FirstName.toLowerCase() > b.FirstName.toLowerCase()) return 1;

  // If first name is the same, compares by username or email
  if ((a.UserName || a.Email || '').toLowerCase() < (b.UserName || b.Email || '').toLowerCase()) return -1;
  if ((a.UserName || a.Email || '').toLowerCase() > (b.UserName || b.Email || '').toLowerCase()) return 1;

  // If all criteria are the same, no need to change the order
  return 0;
};

const filterManageUsersAdapter = createEntityAdapter<ManageUser>({
  selectId: (user: ManageUser) => normalizeKey(user.UserId),
  sortComparer: getSortComparer,
});

const removeManageUsersAdapter = createEntityAdapter<ManageUser>({
  selectId: (user: ManageUser) => normalizeKey(user.UserId),
  sortComparer: getSortComparer,
});

interface ManageUsersState {
  filteredManagedUsers: EntityState<ManageUser>;
  removeManageUsers: EntityState<ManageUser>;
  manageUsersLoading: boolean;
  reportIsLoading: boolean;
  manageUsersReport: FileResult | undefined;
  selectedUserCustomerPermissions: ManageUserCustomerPermissions[];
  checkedUserIds: string[];
  showReviewDialog: boolean;
  statusFilters: string[];
  userToEdit: ManageUser | undefined;
}

const initialState: ManageUsersState = {
  filteredManagedUsers: filterManageUsersAdapter.getInitialState(),
  removeManageUsers: removeManageUsersAdapter.getInitialState(),
  manageUsersLoading: false,
  reportIsLoading: false,
  manageUsersReport: undefined,
  selectedUserCustomerPermissions: [],
  checkedUserIds: [],
  showReviewDialog: false,
  statusFilters: [],
  userToEdit: undefined,
};

export const manageUsersSlice = createSlice({
  name: 'manageUsers',
  initialState: initialState,
  reducers: {
    resetState: () => initialState,
    updateUserCustomers: (state: ManageUsersState, action: PayloadAction<ManageUser>) => {
      filterManageUsersAdapter.updateOne(state.filteredManagedUsers, {
        id: action.payload.UserId,
        changes: { CustomerIds: action.payload.CustomerIds },
      });
      removeManageUsersAdapter.updateOne(state.filteredManagedUsers, {
        id: action.payload.UserId,
        changes: { CustomerIds: action.payload.CustomerIds },
      });
    },
    addUser: (state: ManageUsersState, action: PayloadAction<ManageUser>) => {
      filterManageUsersAdapter.addOne(state.filteredManagedUsers, action.payload);
    },
    removeUsers: (state: ManageUsersState, action: PayloadAction<{ userIds: string[]; customerId: string }>) => {
      removeManageUsersAdapter.removeMany(state.removeManageUsers, action.payload.userIds);
    },
    updateUserStatus: (state: ManageUsersState, action: PayloadAction<ManageUser>) => {
      filterManageUsersAdapter.updateOne(state.filteredManagedUsers, {
        id: action.payload.UserId,
        changes: { ManagedUserStatus: action.payload.ManagedUserStatus },
      });
    },
    setManageUsersLoading: (state: ManageUsersState, action: PayloadAction<boolean>) => {
      state.manageUsersLoading = action.payload;
    },
    setReportIsLoading: (state: ManageUsersState, action: PayloadAction<boolean>) => {
      state.reportIsLoading = action.payload;
    },
    setManageUsersReport: (state: ManageUsersState, action: PayloadAction<FileResult>) => {
      state.manageUsersReport = action.payload;
    },
    setSelectedUserCustomerPermissions: (
      state: ManageUsersState,
      action: PayloadAction<ManageUserCustomerPermissions[]>
    ) => {
      state.selectedUserCustomerPermissions = action.payload;
    },
    setCheckedUsers: (state: ManageUsersState, action: PayloadAction<{ usersId: string[]; isChecked: boolean }>) => {
      const newUserIds = new Set<string>(state.checkedUserIds);
      const userIds = action.payload.usersId;
      if (action.payload.isChecked) {
        userIds.forEach((id) => newUserIds.add(id));
      } else {
        userIds.forEach((id) => newUserIds.delete(id));
      }

      state.checkedUserIds = Array.from(newUserIds);
    },
    setManageUsersGrid: (state: ManageUsersState, action: PayloadAction<ManageUser[]>) => {
      filterManageUsersAdapter.setAll(state.filteredManagedUsers, action.payload);
    },
    setRemoveManageUsersGrid: (state: ManageUsersState, action: PayloadAction<ManageUser[]>) => {
      removeManageUsersAdapter.setAll(state.removeManageUsers, action.payload);
    },
    setShowReviewDialog: (state: ManageUsersState, action: PayloadAction<boolean>) => {
      state.showReviewDialog = action.payload;
    },
    setStatusFilters: (state: ManageUsersState, action: PayloadAction<string[]>) => {
      state.statusFilters = action.payload;
    },
    setUserToEdit: (state: ManageUsersState, action: PayloadAction<ManageUser>) => {
      state.userToEdit = action.payload;
    },
  },
});

// Selectors
export const {
  selectAll: selectAllFilteredManagedUsers,
  selectById: selectFilteredManagedUsersById,
  selectIds: selectFilteredManagedUsersByKeys,
} = filterManageUsersAdapter.getSelectors<RootState>((state: RootState) => state.manageUsers.filteredManagedUsers);

export const {
  selectAll: selectAllRemoveManageUsers,
  selectById: selectRemoveManageUsersById,
  selectIds: selectRemoveManageUsersByKeys,
} = removeManageUsersAdapter.getSelectors<RootState>((state: RootState) => state.manageUsers.removeManageUsers);

export const selectAllCheckedUserIds = createSelector([(state: RootState) => state.manageUsers], (state) => {
  return state.checkedUserIds;
});

export const selectAllCheckedUsers = createSelector([(state: RootState) => state.manageUsers], (state) => {
  return state.checkedUserIds.map((id) => state.removeManageUsers.entities[id]).filter((m) => m) as ManageUser[];
});

export const selectUserIsChecked = createSelector(
  [(state: RootState) => state.manageUsers, (_state: RootState, id: string) => id],
  (state, id) => state.checkedUserIds.includes(id)
);

// Custom selectors
function getFilterFields(): (keyof ManageUser)[] {
  const fields: (keyof ManageUser)[] = [];
  fields.push('FirstName', 'LastName', 'UserName', 'Email');
  return fields;
}

type FilterRequest = {
  filterText: string | undefined;
  filterData: UserManagementFilterData;
  filterActivity: number;
  customerId?: string;
};

export const selectFilteredManageUsersGrid = createSelector(
  (state: RootState) => state,
  (_state: RootState, filterRequest: FilterRequest) => filterRequest,
  (manageUsersGrid, request) => {
    let items = selectAllFilteredManagedUsers(manageUsersGrid);
    items = filterObjects(items, request.filterText ?? '', getFilterFields());
    items = items.filter((i) => i.CustomerIds.length !== 0);
    items = filterUserManagementData(items, request);
    return items.map((i) => normalizeKey(i.UserId) as string) as string[];
  }
);

export const selectRemoveManageUsersGrid = createSelector(
  (state: RootState) => state,
  (_state: RootState, filterRequest: FilterRequest) => filterRequest,
  (manageUsersGrid, request) => {
    let items = selectAllRemoveManageUsers(manageUsersGrid);
    items = filterObjects(items, request.filterText ?? '', getFilterFields());
    items = filterUserManagementData(items, request);
    return items.map((i) => normalizeKey(i.UserId) as string) as string[];
  }
);

const filterUserManagementData = (items: ManageUser[], request: FilterRequest) => {
  const activityFilter = request.filterActivity;

  const statusFilters = request.filterData.Status.items
    .filter((s) => s.value)
    .map((a) => {
      return a.name;
    });

  if (statusFilters.length <= 0 && activityFilter <= 0) return items;

  const date = new Date();
  return items.filter((item) => {
    const daysSinceLogin = getTimeBetween(item.LastLoginDate, date, 'day');
    if (request.customerId && !item.CustomerIds.includes(request.customerId)) return false;
    return (
      (activityFilter === 0
        ? checkIsActive(item, statusFilters) ||
          checkIsPendingUser(item, statusFilters) ||
          checkIsExpired(item, statusFilters) ||
          checkIsInactive(item, statusFilters)
        : checkIsActive(item, statusFilters) ||
          (activityFilter > 0 && checkDateFilter(item, activityFilter, daysSinceLogin))) ?? false
    );
  });
};

const checkIsActive = (user: ManageUser, statusFilters: string[]) => {
  return user.ManagedUserStatus === ManagedUserStatusTypes.Active && statusFilters.includes('IncludeActiveUsers');
};

const checkIsPendingUser = (user: ManageUser, statusFilters: string[]) => {
  return user.ManagedUserStatus === ManagedUserStatusTypes.Pending && statusFilters?.includes('IncludePendingUsers');
};

const checkIsExpired = (user: ManageUser, statusFilters: string[]) => {
  return user.ManagedUserStatus === ManagedUserStatusTypes.Expired && statusFilters?.includes('IncludeExpiredUsers');
};

const checkIsInactive = (user: ManageUser, statusFilters: string[]) => {
  return user.ManagedUserStatus === ManagedUserStatusTypes.Inactive && statusFilters.includes('IncludeInactiveUsers');
};

const checkDateFilter = (user: ManageUser, activityFilters: number, daysSinceLogin: number) => {
  if (!user.IsPendingUser) {
    let result = false;

    if (activityFilters === 12) {
      result = daysSinceLogin >= 365;
    } else if (daysSinceLogin >= activityFilters * 30) {
      result = true;
    }
    return result;
  }
};
