import {
  AppNotification,
  NotificationDisplayType,
  NotificationKeys,
  NotificationType,
} from '../../models/notifications.models';
import { RouteName } from '../../models/routing.models';
import { generateId } from '../general/system';

export class AppNotificationsCenter {
  public static Notifications: { [key in NotificationKeys]?: AppNotification } = {};
  public static DefaultAutoDismissTime = 3;

  public static init(): void {
    this.Notifications = {
      [NotificationKeys.Default]: {
        Id: '',
        AutoDismiss: this.DefaultAutoDismissTime,
        CanUserDismiss: true,
        NotificationDisplayType: NotificationDisplayType.Growler,
        NotificationType: NotificationType.Success,
        Key: NotificationKeys.Default,
      },
      [NotificationKeys.GrowlerSuccess]: {
        Id: '',
        AutoDismiss: this.DefaultAutoDismissTime,
        CanUserDismiss: true,
        NotificationDisplayType: NotificationDisplayType.Growler,
        NotificationType: NotificationType.Success,
        Key: NotificationKeys.GrowlerSuccess,
        Icon: 'fa-check-circle',
      },
      [NotificationKeys.GrowlerError]: {
        Id: '',
        AutoDismiss: this.DefaultAutoDismissTime,
        CanUserDismiss: true,
        NotificationDisplayType: NotificationDisplayType.Growler,
        NotificationType: NotificationType.Error,
        Key: NotificationKeys.GrowlerError,
      },
      [NotificationKeys.ItemAddedSuccessfully]: {
        Id: '',
        AutoDismiss: this.DefaultAutoDismissTime,
        CanUserDismiss: true,
        NotificationDisplayType: NotificationDisplayType.Growler,
        NotificationType: NotificationType.Success,
        Key: NotificationKeys.ItemAddedSuccessfully,
        Message: 'Item added successfully',
        Icon: 'fa-check-circle',
      },
      [NotificationKeys.PageLevelError]: {
        Id: '',
        AutoDismiss: this.DefaultAutoDismissTime * 3,
        CanUserDismiss: true,
        NotificationDisplayType: NotificationDisplayType.Page,
        NotificationType: NotificationType.Error,
        Key: NotificationKeys.PageLevelError,
      },
      [NotificationKeys.PageLevelMessage]: {
        Id: '',
        AutoDismiss: 0,
        CanUserDismiss: true,
        NotificationDisplayType: NotificationDisplayType.Page,
        NotificationType: NotificationType.Information,
        Key: NotificationKeys.PageLevelMessage,
        RouteNames: [RouteName.OrderEntry, RouteName.OrderReview],
      },
      [NotificationKeys.CustomerAlertWarning]: {
        Id: '',
        AutoDismiss: 0,
        CanUserDismiss: true,
        NotificationDisplayType: NotificationDisplayType.Page,
        NotificationType: NotificationType.Error,
        Key: NotificationKeys.CustomerAlertWarning,
        RouteNames: [RouteName.Home, RouteName.OrderReview],
      },
      [NotificationKeys.CustomerAlertInformation]: {
        Id: '',
        AutoDismiss: 0,
        CanUserDismiss: true,
        NotificationDisplayType: NotificationDisplayType.Page,
        NotificationType: NotificationType.Information,
        Key: NotificationKeys.CustomerAlertInformation,
        RouteNames: [RouteName.Home, RouteName.OrderReview],
      },
      [NotificationKeys.CustomerAlertSystem]: {
        Id: '',
        AutoDismiss: 0,
        CanUserDismiss: true,
        NotificationDisplayType: NotificationDisplayType.Page,
        NotificationType: NotificationType.System,
        Key: NotificationKeys.CustomerAlertSystem,
        RouteNames: [RouteName.Home],
      },
      [NotificationKeys.QuantityFieldNotification]: {
        Id: '',
        AutoDismiss: this.DefaultAutoDismissTime,
        CanUserDismiss: true,
        NotificationDisplayType: NotificationDisplayType.Field,
        NotificationType: NotificationType.Information,
        Key: NotificationKeys.TextFieldNotification,
        Message: '',
      },
      [NotificationKeys.TextFieldNotification]: {
        Id: '',
        AutoDismiss: this.DefaultAutoDismissTime,
        CanUserDismiss: true,
        NotificationDisplayType: NotificationDisplayType.Field,
        NotificationType: NotificationType.Information,
        Key: NotificationKeys.TextFieldNotification,
        Message: '',
      },
      [NotificationKeys.PORequired]: {
        Id: '',
        AutoDismiss: 0,
        CanUserDismiss: false,
        NotificationDisplayType: NotificationDisplayType.Component,
        NotificationType: NotificationType.Error,
        Key: NotificationKeys.PORequired,
        Message: 'A PO# is required. Please update to proceed.',
        Title: 'PO # Required',
      },
      [NotificationKeys.InvalidDeliveryDate]: {
        Id: '',
        AutoDismiss: 0,
        CanUserDismiss: false,
        NotificationDisplayType: NotificationDisplayType.Component,
        NotificationType: NotificationType.Error,
        Key: NotificationKeys.InvalidDeliveryDate,
        Message: 'Your date is invalid. Please update the date to proceed.',
        Title: 'Invalid Delivery Date',
      },
      [NotificationKeys.Toast]: {
        Id: '',
        AutoDismiss: 0,
        CanUserDismiss: true,
        NotificationDisplayType: NotificationDisplayType.Toast,
        NotificationType: NotificationType.Success,
        Key: NotificationKeys.Toast,
      },
      [NotificationKeys.CustomErrorInformation]: {
        Id: '',
        AutoDismiss: this.DefaultAutoDismissTime,
        CanUserDismiss: true,
        NotificationDisplayType: NotificationDisplayType.Page,
        NotificationType: NotificationType.Information,
        Key: NotificationKeys.CustomErrorInformation,
      },
    };
  }

  public static getNotificationByKey(
    key: NotificationKeys,
    message?: string,
    title?: string,
    id?: string
  ): AppNotification {
    let notification = this.Notifications[key];
    if (!notification) notification = <AppNotification>this.Notifications[NotificationKeys.Default];
    return {
      ...notification,
      Id: id ?? generateId(),
      ...(message && { Message: message }),
      ...(title && { Title: title }),
    };
  }

  public static getNotificationByKeyAndId(
    key: NotificationKeys,
    params: {
      id: string;
      message?: string;
      title?: string;
      icon?: string;
      type?: NotificationType;
    }
  ): AppNotification {
    let notification = this.Notifications[key];
    if (!notification) notification = <AppNotification>this.Notifications[NotificationKeys.Default];
    return {
      ...notification,
      Id: params.id,
      ...(params.message && { Message: params.message }),
      ...(params.title && { Title: params.title }),
      ...(params.icon && { Icon: params.icon }),
      ...(params.type && { NotificationType: params.type }),
    };
  }
}

export const generateStaticId = (type: string, args?: string[]): string => {
  const notificationIdName: { [key: string]: string } = {
    poNumber: 'po-number-notification',
    quantityField: args ? `product-${args[0]}-${args[1]}-quantity` : '',
    quantityFieldProductLevel: args ? `product-${args[0]}-quantity` : '',
    prodDescription: 'product-desc-notification',
    prodNumber: 'product-num-notification',
    orderName: 'order-name-notification',
    toastNotification: args ? `toast-notification-${args[0]}` : '',
  };
  return notificationIdName[type];
};
