import { trackException, useAppDispatch } from 'common';
import { FC, PropsWithChildren } from 'react';
import { throttle } from 'throttle-debounce';
import { AppErrorBoundaryFallback } from './AppErrorBoundaryFallback';
import { CfErrorBoundary } from './cf-ui/ErrorBoundary/CfErrorBoundary';

type AppErrorBoundaryProps = {
  //
};

export const AppErrorBoundary: FC<PropsWithChildren<AppErrorBoundaryProps>> = (
  props: PropsWithChildren<AppErrorBoundaryProps>
) => {
  const dispatch = useAppDispatch();

  const _handleError = (error: Error) => {
    dispatch(trackException(error));
  };

  const throttledHandleError = throttle(100, true, _handleError);

  return (
    <CfErrorBoundary fallback={AppErrorBoundaryFallback} onError={throttledHandleError}>
      {props.children}
    </CfErrorBoundary>
  );
};
