import { ButtonProps, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, PropsWithChildren } from 'react';
import { CfDialog } from '../../../../../cf-ui/dialogs/Dialog/CfDialog';
import { DeviceType, useBreakpoint } from '../../../../../hooks/useBreakpoint';

export interface LocationSearchDialogBaseProps {
  isDisabled?: boolean;
  isOpen: boolean;
  title: string;
  subTitle?: string;
  secondaryButtonProps?: ButtonProps;
  contentContainerSx?: SxProps<Theme>;
  dialogContainerSx?: SxProps<Theme>;
  dialogFooterSx?: SxProps<Theme>;
  paperSx?: SxProps<Theme>;
  onClose?: () => void;
  onSubmit?: () => void;
  disableCloseOnSubmit?: boolean; //Option to circumvent default closing of dialog after submission.
  applyButtonDataTestId?: string;
  closeButtonDataTestId?: string;
  dialogTitleDataTestId?: string;
}

const LocationSearchDialogBase: FC<PropsWithChildren<LocationSearchDialogBaseProps>> = (
  props: PropsWithChildren<LocationSearchDialogBaseProps>
) => {
  const { deviceType } = useBreakpoint();

  const {
    children,
    isDisabled,
    isOpen,
    title,
    subTitle,
    secondaryButtonProps,
    contentContainerSx,
    dialogContainerSx,
    dialogFooterSx,
    paperSx,
    onClose,
    onSubmit,
    disableCloseOnSubmit,
    applyButtonDataTestId,
    closeButtonDataTestId,
    dialogTitleDataTestId,
  } = props;

  const handleOnSubmit = () => {
    onSubmit?.();
    if (disableCloseOnSubmit) return;
    onClose?.();
  };

  return (
    <CfDialog
      isOpen={isOpen}
      primaryButtonProps={{
        children: 'Apply',
        onClick: handleOnSubmit,
        disabled: isDisabled,
        size: deviceType === DeviceType.Desktop ? 'small' : 'medium',
        sx: { width: { xs: '100%', lg: 'unset' } },
      }}
      secondaryButtonProps={secondaryButtonProps}
      title={title}
      subTitle={subTitle}
      contentContainerSx={contentContainerSx}
      dialogContainerSx={dialogContainerSx}
      dialogFooterSx={dialogFooterSx}
      paperSx={paperSx}
      onClose={onClose}
      closeButtonDataTestId={closeButtonDataTestId}
      primaryButtonDataTestId={applyButtonDataTestId}
      titleDataTestId={dialogTitleDataTestId}
    >
      {children}
    </CfDialog>
  );
};

export default LocationSearchDialogBase;
