import { Box, Icon, Stack, Typography, TypographyPropsVariantOverrides } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { SxProps, Theme } from '@mui/system';
import { OverridableStringUnion } from '@mui/types';
import { validateStringEmptyOrMaxLength } from 'common/src/helpers/validation/general';
import React, { FC, useEffect, useState } from 'react';
import TypographySkeleton from '../../features/_shared/components/TypographySkeleton';
import { CfTextField } from '../TextField/CfTextField';
import { CfContainedButton } from '../buttons/CfContainedButton';
import { StyledContainer } from './CfEditableLabel.styles';

interface CfEditableLabelProps {
  isIconRightAligned?: boolean;
  isAutoFocused?: boolean;
  buttonContainerSx?: SxProps<Theme>;
  buttonDataTestId?: string;
  containerSx?: SxProps<Theme>;
  defaultValue?: string;
  defaultValueSx?: SxProps<Theme>;
  direction?: 'column' | 'row';
  isLabelEditingDisabled?: boolean;
  isEditMode: boolean;
  isIconHidden?: boolean;
  iconSize?: 'small' | 'medium';
  iconSx?: SxProps<Theme>;
  inputAltDataTestId?: string;
  inputDataTestId?: string;
  isOptional?: boolean;
  label?: string;
  labelName?: string;
  labelSx?: SxProps<Theme>;
  labelDataTestId?: string;
  labelVariant?: OverridableStringUnion<Variant, TypographyPropsVariantOverrides>;
  lines?: number;
  isLoading?: boolean;
  maxLength?: number;
  maxRows?: number;
  onChange?: (value: string) => void;
  onEdit?: () => void;
  onClose?: () => void;
  onSubmit?: (value: string) => void;
  /** toggling this property's value forces the input value to reset */
  forceReset?: boolean;
  secondaryValue?: string;
  hasError?: boolean;
  size?: 'extra-small' | 'small' | 'medium';
  skeletonCharCount?: number;
  value?: string;
  variant: OverridableStringUnion<Variant, TypographyPropsVariantOverrides>;
  allowEmptyString?: boolean;
}

/**
 * @param isIconRightAligned - Boolean indicating if the icon should align right
 * @param isAutoFocused - Boolen indicating if the label should be automatically focused on
 * @param buttonContainerSx - Additional styling for the button container
 * @param buttonDataTestId - Test id of the button
 * @param containerSx - Styling of the container which holds the text input
 * @param defaultValue - The default value of the text label
 * @param defaultValueSx - Additional styling of the text label
 * @param direction - Value representing the direction of the stack which holds the input and buttons
 * @param isLabelEditingDisabled - Boolean indicating if the ediable label or the label component is shown
 * @param isEditMode - Boolean indicating if the user is trying to edit the label
 * @param isIconHidden - Boolean representing if the pencil icon should be hidden
 * @param iconSize - Value representing the size of the pencil icon (small, medium)
 * @param inputAltDataTestId - Alternative test id for the text input
 * @param inputDataTestId - Test id for the text input
 * @param isOptional - Boolean indicating if the user can input a blank value
 * @param label - Value to set the label to
 * @param labelName - Name to use in the validation/error messaging
 * @param labelSx - Additional styling for the label
 * @param labelDataTestId - Test id for the label
 * @param labelVariant - The styling variant to use for the label
 * @param lines - Count of the number of lines in the text input. Used to calculate box height for multiline editing
 * @param isLoading - Boolean indicating if the label is loading
 * @param maxLength - The maximum length of characters that an input can be
 * @param maxRows - The maximum number of rows than the input can be
 * @param onChange - Method to call on change of the text input
 * @param onEdit - Method to call when the pencil icon is clicked
 * @param onClose - Method to call when canceling the input or pressing the escape button
 * @param onSubmit - Method to call when submitting the text input
 * @param forceReset - Forces the component to re-render when value hasn't changed but text has (i.e. when validation fails)
 * @param secondaryValue - Label text to use when the text of the input is undefined and when the default value is undefined
 * @param hasError - Boolean indicating that there is an error coming from outside the component that should prevent this component from updating the text
 * @param size - Used to calculate and set the sizes of the label and text input
 * @param skeletonCharCount - The character count for the loading skeleton to use
 * @param value - The value to set the text input to
 * @param variant - The styling that the label and text input should use
 */
const CfEditableLabel: FC<CfEditableLabelProps> = (props: CfEditableLabelProps) => {
  const [text, setText] = useState<string | undefined>(undefined);
  const [hasError, setHasError] = useState(false);
  const [helpLabel, setHelpLabel] = useState('');

  useEffect(() => {
    setText(props.value);
  }, [props.forceReset, props.value]);

  useEffect(() => {
    if (!props.isEditMode) {
      setHelpLabel('');
      setHasError(false);
    }
  }, [props.isEditMode]);

  const handleOnClickEdit = () => {
    setText(props.value);
    props.onEdit?.();
  };

  const closeInputAndSubmit = () => {
    props.onSubmit?.(text ?? '');
  };

  const closeInput = () => {
    setText(props.value);
    setHelpLabel('');
    setHasError(false);
    props.onClose?.();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event && event.key === 'Escape') closeInput();
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { isOptional, labelName, maxLength, onChange } = props;
    const inputText = e?.target?.value;
    const validationMessages = validateStringEmptyOrMaxLength(
      labelName ?? '',
      inputText,
      maxLength,
      props.allowEmptyString
    );

    if (maxLength === undefined) setHasError(false);
    else {
      setHasError(validationMessages.length > 0);
      if (
        isOptional &&
        !(inputText.length > 0 && inputText.trim().length === 0) &&
        inputText.trim().length <= maxLength
      ) {
        setHelpLabel('');
        setHasError(false);
      } else {
        setHelpLabel(validationMessages.join(' '));
      }
    }
    if (onChange) onChange?.(inputText);
    else setText(inputText);
  };

  const setLabelTextStyling = () => {
    return (
      <Box style={{ display: 'inline' }}>
        <Typography variant='h1' display='inline'>
          {props.defaultValue}
        </Typography>
        <Typography
          variant='h1'
          display='inline'
          sx={{ fontWeight: 'normal', ml: Boolean(props.secondaryValue) ? 0.5 : 0 }}
        >
          ({props.secondaryValue})
        </Typography>
      </Box>
    );
  };

  const icon = (
    <Icon
      className='fa-pencil-alt'
      fontSize={props.iconSize ?? 'small'}
      sx={{
        lineHeight: (theme) => `${theme.typography[props.variant].lineHeight} !important`,
        cursor: 'pointer',
        color: (theme) => theme.palette.common.black,
        ml: 1,
        ...props.iconSx,
      }}
      tabIndex={0}
      data-testid={props.buttonDataTestId}
      onClick={handleOnClickEdit}
      onKeyPress={(e: React.KeyboardEvent<HTMLSpanElement>) => e.key === 'Enter' && handleOnClickEdit()}
    />
  );

  const lines = props.lines ?? 1;
  const multiLineEnabled = lines > 1;
  let height = 32;
  switch (props.size) {
    case 'medium':
      height = 40 * lines;
      break;
    case 'small':
      height = 32 * lines;
      break;
    case 'extra-small':
      height = 24 * lines;
      break;
  }

  const paddingSx =
    props.size === 'extra-small'
      ? { paddingY: 0.5, paddingX: 1, height: '100%' }
      : props.size === 'small'
      ? { paddingY: 1, paddingX: 2 }
      : { paddingY: 2.5, paddingX: 2 };

  const labelComponent = (
    <>
      <Typography
        data-testid={props.inputDataTestId}
        data-alt-testid={props.inputAltDataTestId}
        variant={props.variant}
        sx={{ overflowWrap: 'anywhere', ...props.defaultValueSx }}
      >
        {text
          ? text
          : props.defaultValue && props.secondaryValue && !props.value
          ? setLabelTextStyling()
          : props.defaultValue}

        {props.isIconRightAligned && !props.isIconHidden && icon}
      </Typography>
      {!props.isIconRightAligned && !props.isIconHidden && icon}
    </>
  );
  if (props.isEditMode) {
    return (
      <Box sx={{ width: '100%' }}>
        {props.label && (
          <Typography
            variant={props.labelVariant || 'paragraphSmall'}
            sx={{
              pr: (theme) => theme.spacing(0.5),
              whiteSpace: 'normal',
              color: (theme) => theme.palette.grey[700],
              pt: (theme) => theme.spacing(1),
              pb: (theme) => theme.spacing(0.5),
              lineClamp: 3,
              overflow: 'hidden',
              ...props.labelSx,
            }}
            data-testid={props.labelDataTestId}
          >
            {props.label}
          </Typography>
        )}
        <Stack direction={props.direction} sx={{ justifyContent: { xs: 'start', lg: 'space-between' } }}>
          <StyledContainer
            sx={{
              height: 'fit-content',
              '& .cf-editable-label.MuiInput-root.MuiInputBase-root': {
                backgroundColor: (theme) => theme.palette.common.white,
                height: height,
              },
              '& .cf-editable-label > .MuiInput-input.MuiInputBase-input': {
                typography: props.size === 'extra-small' ? 'responsiveParagraphSmall' : 'responsiveParagraph',
                ...paddingSx,
              },
              '& .MuiInputBase-inputMultiline': {
                ...(multiLineEnabled && {
                  height: (theme) => `${height - theme.custom.spacing * lines}px !important}`,
                }),
              },

              ...props.containerSx,
            }}
          >
            {props.isLabelEditingDisabled ? (
              labelComponent
            ) : (
              <CfTextField
                className='cf-editable-label'
                multiline={multiLineEnabled}
                autoFocus={props.isAutoFocused}
                fullWidth
                defaultValue={props.defaultValue}
                value={text}
                onKeyPress={handleKeyPress}
                onFocus={(e) => e.target.select()}
                onChange={handleChange}
                error={hasError}
                helpLabel={helpLabel}
                inputSizeOverride={props.size}
                maxRows={props.maxRows}
                inputTestId={`${props.inputDataTestId}-text-field`}
                sx={{ ...props.containerSx }}
              />
            )}
          </StyledContainer>
          {props.onSubmit && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                mt: props.direction === 'column' ? 1 : 0,
                ml: props.direction === 'row' ? 1 : 0,
                ...props.buttonContainerSx,
              }}
            >
              <CfContainedButton
                color='secondary'
                onClick={closeInput}
                sx={{ mr: 1 }}
                dataTestId={`${props.buttonDataTestId}-cancel`}
              >
                Cancel
              </CfContainedButton>
              <CfContainedButton
                color='primary'
                onClick={closeInputAndSubmit}
                isDisabled={hasError || props.hasError}
                dataTestId={`${props.buttonDataTestId}-update`}
              >
                Update
              </CfContainedButton>
            </Box>
          )}
        </Stack>
      </Box>
    );
  } else {
    return (
      <StyledContainer sx={{ ...props.containerSx }}>
        {props.label && (
          <Typography
            variant={props.labelVariant || 'paragraph'}
            sx={{ pr: (theme) => theme.spacing(0.5), whiteSpace: 'normal', ...props.labelSx }}
          >
            {props.label}
          </Typography>
        )}
        {!props.isLoading ? (
          labelComponent
        ) : (
          <TypographySkeleton charCount={props.skeletonCharCount || 10} variant={props.variant} />
        )}
      </StyledContainer>
    );
  }
};

CfEditableLabel.defaultProps = {
  direction: 'column',
};

export default CfEditableLabel;
