import preval from 'preval.macro';

export interface BuildInfo {
  buildTimestamp: string;
  gitSha: string;
  gitBranch: string;
}

export const getBuildInfo = (): BuildInfo => {
  const buildTimestamp: string = preval`module.exports = new Date().toISOString().slice(0, 19) + 'Z'` || '';
  const gitSha: string = (
    preval`
    const exec = require('node:child_process').execSync;
    module.exports = exec('git describe --always --dirty --exclude "*"').toString()` || ''
  ).trim();

  const branch: string = preval`
  const exec = require('node:child_process').execSync;
  const gitShaFull = exec('git rev-parse HEAD').toString().trim();
  let rows = exec('git show-ref').toString().split("\\n");
  rows = rows.filter(l => l.startsWith(gitShaFull));
  let branchName = '';
  if (rows.length > 0) {
    const row = rows[rows.length - 1];
    const parts = row ? row.split(' ') : [];
    if (parts.length > 0) {
      branchName = parts[parts.length - 1];
    }
  }
  module.exports = branchName.trim();`;

  const gitBranch = branch.replace(/^refs\/remotes\/origin\//i, '');

  return {
    buildTimestamp,
    gitSha,
    gitBranch,
  };
};
