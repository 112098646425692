import { PopperPlacementType } from '@mui/material';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { CfUncontrolledMenu } from './CfUncontrolledMenu';

type CfNavMenuProps = {
  anchorElement: HTMLElement | undefined;
  anchorComponent?: React.ReactNode;
  isOpen?: boolean;
  closeOnBlur?: boolean;
  onClose?: () => void;
  minWidth?: number;
  maxWidth?: number;
  width?: number;
  placement?: PopperPlacementType;

  dataTestId?: string;
  prefix?: ReactNode;
};

export const CfNavMenu: FC<PropsWithChildren<CfNavMenuProps>> = (props: PropsWithChildren<CfNavMenuProps>) => {
  return (
    <CfUncontrolledMenu
      {...props}
      keepMounted
      mode='menu'
      variant='square'
      divider='full'
      popperSx={{ boxShadow: '0px 2px 8px 0px #00000026', border: '1px solid #DDE1E6' }}
      containerSx={{ maxHeight: 'calc(65vh)', overflowY: 'auto' }}
      inputElement={props.anchorComponent}
    />
  );
};
