import { SeverityLevel } from '@microsoft/applicationinsights-web';
import {
  DeleteCustomerGroupRequest,
  GetCustomerGroupRequest,
  UpdateCustomerGroupName,
  UpdateCustomersGroupRequest,
} from '../../api';
import CustomerGroupService from '../../api/services/customer-group.service';
import { generateId, normalizeKey } from '../../helpers';
import { validateStringEmptyOrMaxLength } from '../../helpers/validation/general';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { globalSlice } from '../common/global.slice';
import { AppDispatch, AppThunk, RootState } from '../store';
import { customerGroupSelectedSlice, selectAllRecipientsKeyedByObjectValues } from './customer-groups-selected.slice';
import { selectAllCustomerGroups } from './customer-groups.slice';
import { getCustomerGroups } from './customer-groups.thunks';
import { CustomerRecipient, UserRecipient } from './recipients.models';

const customerGroupService = CustomerGroupService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Sets the customerGroup to the specified customer group
 *
 * @param CustomerGroupHeaderId - The id of the customer group the customerGroup value is being set to
 * @returns NULL
 */
export const setCustomerGroupBasic =
  (CustomerGroupHeaderId: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const customerGroup = getState().customerGroups.customerGroups.entities[normalizeKey(CustomerGroupHeaderId)];
    if (customerGroup) {
      dispatch(customerGroupSelectedSlice.actions.setCustomerGroupBasic(customerGroup));
      dispatch(customerGroupSelectedSlice.actions.setValidationMessages({ customerGroupName: [] }));
    }
  };

export const setSelectedCustomerGroupHeaderId =
  (CustomerGroupHeaderId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(customerGroupSelectedSlice.actions.setSelectedCustomerGroupHeaderId(CustomerGroupHeaderId));
  };

/**
 * Sets the customerGroup's name to the specified name
 *
 * @param customerGroupName - The name of the customer group the customerGroup's name attribute is being set to
 * @returns NULL
 */
// export const setCustomerGroupName =
//   (customerGroupName: string): AppThunk =>
//   async (dispatch: AppDispatch, getState: () => RootState) => {
//     const customerGroup = getState().customerGroupSelected.customerGroup;
//     if (customerGroupName !== customerGroup?.CustomerGroupName)
//       dispatch(
//         customerGroupSelectedSlice.actions.setCustomerGroupBasic({
//           CustomerGroupHeaderId: customerGroup?.CustomerGroupHeaderId || '',
//           CustomerGroupName: customerGroupName,
//           // CustomerCount: getState().customerGroupSelected.recipients.ids.length,
//           CustomerCount: 2,
//         })
//       );
//     dispatch(validateCustomerGroup());
//   };

/**
 * Validates and sets the customerGroup's customer recipients
 *
 * @param customerRecipients - Array of the customer recipients that are being assigned to the customerGroup
 * @returns NULL
 */
export const setCustomerGroupCustomerRecipients =
  (customerRecipients: CustomerRecipient[]): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const recipientsKeyedByObjectValue = selectAllRecipientsKeyedByObjectValues(getState());
    customerRecipients.forEach((c) => {
      const key = normalizeKey(c.customerId);
      if (recipientsKeyedByObjectValue[key] && !c.selected) {
        delete recipientsKeyedByObjectValue[key];
      } else if (!recipientsKeyedByObjectValue[key] && c.selected) {
        recipientsKeyedByObjectValue[key] = {
          CustomerGroupHeaderId: key,
          CustomerId: key,
          CustomerGroupCustomerId: generateId(),
          CustomerName: c.customerName || '',
          CustomerGroupAdditionalDisplayOne: c.customerAddress || '',
          CustomerGroupAdditionalDisplayTwo: `${c.customerLocation} ${c.customerZip}`,
          CustomerGroupAdditionalDisplayThree: `${c.operationCompanyName} - ${c.customerNumber}`,
        };
      }
    });

    const recipients = Object.values(recipientsKeyedByObjectValue);

    dispatch(
      customerGroupSelectedSlice.actions.setCustomerGroupRecipients({
        recipients,
      })
    );
  };

/**
 * Validates and sets the customerGroup's user recipients
 *
 * @param userRecipients - Array of the user recipients that are being assigned to the customerGroup
 * @returns NULL
 */
export const setCustomerGroupUserRecipients =
  (userRecipients: UserRecipient[]): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const recipientsKeyedByObjectValue = selectAllRecipientsKeyedByObjectValues(getState());
    userRecipients.forEach((u) => {
      const key = normalizeKey(u.userId);
      if (recipientsKeyedByObjectValue[key] && !u.selected) {
        delete recipientsKeyedByObjectValue[key];
      } else if (!recipientsKeyedByObjectValue[key] && u.selected) {
        recipientsKeyedByObjectValue[key] = {
          CustomerGroupHeaderId: key,
          CustomerId: key,
          CustomerGroupCustomerId: generateId(),
          CustomerName: u.userDisplayName,
          CustomerGroupAdditionalDisplayOne: u.userName,
          CustomerGroupAdditionalDisplayTwo: '',
          CustomerGroupAdditionalDisplayThree: '',
        };
      }
    });

    const recipients = Object.values(recipientsKeyedByObjectValue);

    dispatch(
      customerGroupSelectedSlice.actions.setCustomerGroupRecipients({
        recipients,
      })
    );
  };

/**
 * Calls and stores the result of the GetCustomerGroup API call
 *
 * @param request - The information needed to make a GetCustomerGroup API call
 * @returns NULL
 */
export const getCustomerGroupFull =
  (request: GetCustomerGroupRequest): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (
        getState().customerGroupSelected.customerGroup?.CustomerGroupHeaderId?.toLowerCase() ===
          request.customerGroupHeaderId.toLowerCase() &&
        getState().customerGroupSelected.customerIdsLoading
      ) {
        return;
      }

      if (!getState().customerGroupSelected.customerIdsLoading) {
        dispatch(customerGroupSelectedSlice.actions.setCustomerIdsLoading(true));
      }

      const { data } = await customerGroupService.getCustomerGroup(request);

      if (data.IsSuccess) {
        dispatch(customerGroupSelectedSlice.actions.setCustomerGroupFull(data.ResultObject));
        dispatch(customerGroupSelectedSlice.actions.setCustomerIdsLoading(false));
      } else {
        dispatch(customerGroupSelectedSlice.actions.resetCustomerGroupRecipients());
        dispatch(customerGroupSelectedSlice.actions.setCustomerIdsLoading(false));
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      dispatch(customerGroupSelectedSlice.actions.resetCustomerGroupRecipients());
      console.error(error);
    } finally {
      dispatch(customerGroupSelectedSlice.actions.setCustomerIdsLoading(false));
    }
  };

/**
 * Removes the specified recipient from the customerGroup's recipients
 *
 * @param recipientId - The id of the recipient to be removed
 * @returns NULL
 */
export const removeCustomerGroupRecipientById =
  (recipientId: string): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(customerGroupSelectedSlice.actions.removeCustomerGroupRecipientById(recipientId));
  };

/**
 * Sets all values in the customer-group.selected slice to their initial values
 *
 * @returns NULL
 */
export const resetCustomerGroupSelected = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(customerGroupSelectedSlice.actions.resetState());
};

/**
 * Validates the customer group's name
 *
 * @param customerGroupName - The name to be validated
 * @returns Returns the customers gotten from the validation
 */
export const validateCustomerGroupName =
  (customerGroupName: string | undefined): AppThunk<Promise<string[]>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<string[]> => {
    const customers = validateStringEmptyOrMaxLength('Name', customerGroupName, 100);

    if (customers.length === 0) {
      await dispatch(getCustomerGroups());
      const currentCustomerGroupHeaderId = getState().customerGroupSelected.customerGroup?.CustomerGroupHeaderId;
      if (
        selectAllCustomerGroups(getState()).filter(
          (mg) =>
            currentCustomerGroupHeaderId !== mg.CustomerGroupHeaderId &&
            mg.CustomerGroupName.trim() === customerGroupName?.trim()
        ).length > 0
      ) {
        customers.push('Name unavailable');
      }
    }
    return customers;
  };

/**
 * Sets validationCustomers to the result of the validateCustomerGroupName method
 *
 * @returns Boolean indicating if the length of the validation customer is 0
 */
export const validateCustomerGroup =
  (): AppThunk<Promise<boolean>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<boolean> => {
    const validationCustomers = await dispatch(
      validateCustomerGroupName(getState().customerGroupSelected.customerGroup?.CustomerGroupName)
    );
    dispatch(
      customerGroupSelectedSlice.actions.setValidationMessages({
        customerGroupName: validationCustomers,
      })
    );

    return validationCustomers.length === 0;
  };

/**
 * Calls the DeleteCustomerGroup API call
 *
 * @param request - The request to send with the API call
 * @param successCallback - Method to call on success
 * @returns NULL
 */
export const deleteCustomerGroup =
  (request: DeleteCustomerGroupRequest, successCallback?: () => void): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await customerGroupService.deleteCustomerGroup(request);
      if (data.IsSuccess) {
        // dispatch(customerGroupsSlice.actions.removeMessageGroup(request.MessageGroupId));
        successCallback?.();
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      console.error(error);
    }
  };

export const updateCustomerGroup =
  (request: UpdateCustomerGroupName, successCallback?: () => void): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await customerGroupService.updateCustomerGroupName(request);
      if (data.IsSuccess) {
        successCallback?.();
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      console.error(error);
    }
  };

export const updateCustomerGroupLocations =
  (request: UpdateCustomersGroupRequest, successCallback?: () => void): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await customerGroupService.updateCustomerGroupLocations(request);
      if (data.IsSuccess) {
        successCallback?.();
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      console.error(error);
    }
  };
