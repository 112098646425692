import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BusinessUnitLink } from '../../api/models/business-unit.models';
import { UpdateIsCriticalItemResultDetail } from '../../api/models/customer-product.models';
import { Customer } from '../../api/models/customer.models';
import { UserCustomer } from '../../api/models/site.models';

// State
interface CustomerState {
  selectedCustomer: UserCustomer | undefined;
  customerBillto?: Customer;
  deliveryDates: string[];
  updateIsCriticalItemResultDetail?: UpdateIsCriticalItemResultDetail;
  businessUnitLinks: BusinessUnitLink[];
}

const initialState: CustomerState = {
  selectedCustomer: undefined,
  customerBillto: undefined,
  deliveryDates: [],
  updateIsCriticalItemResultDetail: undefined,
  businessUnitLinks: [],
};

// Reducers
export const customerSlice = createSlice({
  name: 'customer',
  initialState: initialState,
  reducers: {
    setSelectedCustomer: (state: CustomerState, action: PayloadAction<UserCustomer>) => {
      state.selectedCustomer = action.payload;
    },
    setCustomerBillto: (state: CustomerState, action: PayloadAction<Customer>) => {
      state.customerBillto = action.payload;
    },
    setCustomerDeliveryDates: (state: CustomerState, action: PayloadAction<string[]>) => {
      state.deliveryDates = action.payload;
    },
    setIsCriticalItem: (state: CustomerState, action: PayloadAction<UpdateIsCriticalItemResultDetail>) => {
      state.updateIsCriticalItemResultDetail = action.payload;
    },
    setBusinessUnitLinks: (state: CustomerState, action: PayloadAction<BusinessUnitLink[]>) => {
      state.businessUnitLinks = action.payload;
    },
  },
});
