import { Box, Stack, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

import { FC } from 'react';
import LoadingSkeleton from '../../../../../_shared/components/LoadingSkeleton/LoadingSkeleton';
import { LocationSearchItem } from '../../../common/LocationSearch/LocationSearch';

export type LocationLabelProps =
  | {
      item: LocationSearchItem;
      customerNameTestId?: string;
      customerAddressTestId?: string;
      customerZipTestId?: string;
      customerNumberTestId?: string;
      variant?: 'small' | 'medium';
      color?: 'black' | 'grey';
      sx?: SxProps<Theme>;
      loadingPlaceholder?: never;
    }
  | {
      creditTermDisplay?: boolean;
      variant?: 'small' | 'medium';
      sx?: SxProps<Theme>;
      loadingPlaceholder: true;
    };

const LocationLabel: FC<LocationLabelProps> = (props: LocationLabelProps) => {
  if (props.loadingPlaceholder)
    return (
      <LoadingSkeleton
        lines={props.creditTermDisplay ? 5 : 4}
        dataTestId='location-dialog-customer-address'
        sx={{ width: '100%' }}
      />
    );

  const { item } = props;

  return (
    <Stack sx={{ p: 0, ...props.sx }}>
      <Typography
        data-testid={props.customerNameTestId ?? 'location-dialog-customer-name'}
        variant='responsiveParagraphBold'
        sx={{ color: (theme) => theme.palette.common.black }}
      >
        {item.customerName}
      </Typography>
      <Typography
        variant={props.variant === 'medium' ? 'responsiveParagraph' : 'responsiveParagraphSmall'}
        sx={{ color: (theme) => (props.color === 'black' ? theme.palette.common.black : theme.palette.grey[600]) }}
      >
        <Box data-testid='location-dialog-customer-address' sx={{ display: 'flex', flexDirection: 'column' }}>
          <span data-testid={props.customerAddressTestId}>{item.customerAddress}</span>
          <span data-testid={props.customerZipTestId}>
            {item.customerLocation} {item.customerZip}
          </span>
          <span data-testid={props.customerNumberTestId}>
            {item.operationCompanyName} - {item.customerNumber}
          </span>
          <span>{item.creditTermDisplay}</span>
        </Box>
      </Typography>
    </Stack>
  );
};

export default LocationLabel;
