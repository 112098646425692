import { ServiceResult } from '../models/api-shared.models';
import {
  ParListDetail,
  ParListDetailsByProductsRequest,
  ParListDetailsResultDetail,
  UpdateParListDetailRequest,
} from '../models/par-list-detail.models';
import ApiService from './api-service';

class ParListDetailService extends ApiService {
  private static classInstance: ParListDetailService;

  private constructor() {
    super('ParListDetail');
  }

  public getParListDetailsByProducts(
    request: ParListDetailsByProductsRequest
  ): ServiceResult<ParListDetailsResultDetail> {
    return this.post(`${this.baseUrl}/GetParListDetailsByProducts`, request);
  }

  public getParListDetails(parListHeaderId: string): ServiceResult<ParListDetail[]> {
    return this.get(`${this.baseUrl}/GetParListDetails`, { parListHeaderId: parListHeaderId });
  }

  public updateParListDetail(request: UpdateParListDetailRequest): ServiceResult<ParListDetail> {
    return this.post(`${this.baseUrl}/UpdateParListDetail`, request);
  }

  public static getInstance(): ParListDetailService {
    if (!this.classInstance) {
      this.classInstance = new ParListDetailService();
    }
    return this.classInstance;
  }
}

export default ParListDetailService;
