import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { AwaitedServiceResult } from '../../api/models/api-shared.models';
import { UpdateProductListNotificationRequest } from '../../api/models/product-list-notification.models';
import ProductListHeaderService from '../../api/services/product-list-header.service';
import ProductListNotificationService from '../../api/services/product-list-notification.service';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { NotificationKeys } from '../../models/notifications.models';
import { selectUserPermissionSetting } from '../common';
import { addAppNotification, setErrorDialogContent } from '../common/global.thunks';
import { AppDispatch, AppThunk, RootState } from '../store';
import {
  productListNotificationSlice,
  selectAllNonViewOnlyAttentionRequestedProductListNotificationIds,
} from './product-list-notification.slice';

const productListHeaderService = ProductListHeaderService.getInstance();
const productListNotificationService = ProductListNotificationService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Resets all values in the product-list-notification slice to their initial values
 *
 * @returns NULL
 */
export const resetProductListNotificationState = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(productListNotificationSlice.actions.resetState());
};

/**
 * Calls and stores the result of the GetProductListNotifications API call
 *
 * @param customerId - The id of the customer whos notifications are being retrieved
 * @returns NULL
 */
export const getProductListNotifications =
  (customerId: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().productListNotification.productListNotificationsLoading) {
        dispatch(productListNotificationSlice.actions.setProductListNotificationsLoading(true));
      }

      const { data } = await productListNotificationService.getProductListNotifications(customerId);

      if (data.IsSuccess) {
        dispatch(productListNotificationSlice.actions.setProductListNotificationGroups(data.ResultObject));
        dispatch(productListNotificationSlice.actions.setCurrentSelectedCustomer(customerId));
      } else dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(productListNotificationSlice.actions.setProductListNotificationsLoading(false));
    }
  };

/**
 * Calls and stores the result of the GetNotificationProductListHeaders API call
 *
 * @param customerId - The id of the customer whos notifications are being retrieved
 * @returns NULL
 */
export const getNotificationProductListHeaders =
  (customerId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await productListHeaderService.getNotificationProductListHeaders(customerId);

      if (data.IsSuccess)
        dispatch(productListNotificationSlice.actions.setNotificationProductListHeaders(data.ResultObject));
      else dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls the UpdateProductListNotification API call
 *
 * @param notificationPreferences - The information about the preferences being updated
 * @param callBack - Method to call once this method has completed
 * @returns NULL
 */
export const updateProductListNotificationPreferences =
  (
    notificationPreferences: Record<string, boolean>,
    callBack?: () => void | Promise<void>
  ): AppThunk<Promise<number | undefined>> =>
  async (dispatch: AppDispatch, getState): Promise<number | undefined> => {
    try {
      const customerId = getState().customer.selectedCustomer?.CustomerId;
      if (!customerId) return;

      const requests: UpdateProductListNotificationRequest[] = [];

      for (const [productListHeaderId, enableNotification] of Object.entries(notificationPreferences)) {
        requests.push({
          ProductListHeaderId: productListHeaderId,
          CustomerId: customerId,
          DisableNotifications: !enableNotification,
        });
      }

      const responses = await Promise.all(
        requests.map((req: UpdateProductListNotificationRequest) =>
          productListNotificationService.updateProductListNotification(req)
        )
      );

      const errorMessageRes = responses
        .filter((res: AwaitedServiceResult<undefined>) => typeof res !== 'undefined')
        .find((res: AwaitedServiceResult<undefined>) => res.data.ErrorMessages.length > 0);

      if (errorMessageRes) dispatch(setErrorDialogContent('Error occurred', errorMessageRes.data.ErrorMessages));

      callBack?.();
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls and stores the result of the GetProductListNotificationTotal API call
 *
 * @param customerId - The id of the customer whos notifications are being retrieved
 * @returns NULL
 */
export const getProductListNotificationTotal =
  (customerId: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const canManageList = selectUserPermissionSetting(getState()).CanManageList;
      if (!canManageList) return;

      if (!getState().productListNotification.productListNotificationsLoading) {
        dispatch(productListNotificationSlice.actions.setProductListNotificationsLoading(true));
      }

      const { data } = await productListNotificationService.getProductListNotificationTotal(customerId);

      if (data.IsSuccess)
        dispatch(productListNotificationSlice.actions.setProductListNotificationTotalInfo(data.ResultObject));
      else dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(productListNotificationSlice.actions.setProductListNotificationsLoading(false));
    }
  };

/**
 * Calls the DismissProductListNotification API call
 *
 * @param productListNotificationIds - The id of the notifications to be dismissed
 * @returns NULL
 */
export const dismissProductListNotification =
  (productListNotificationId: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const notificationId = 'dismiss-product-list-notifications';
      const customerId = getState().customer.selectedCustomer?.CustomerId;
      const { data } = await productListNotificationService.dismissProductListNotification(
        productListNotificationId,
        customerId
      );
      if (data.IsSuccess) {
        dispatch(productListNotificationSlice.actions.removeProductListNotifications([productListNotificationId]));
        dispatch(addAppNotification(NotificationKeys.GrowlerSuccess, '', 'Notification dismissed', notificationId));
      } else dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls the DismissProductListNotification API call
 *
 * @returns NULL
 */
export const dismissAllAttentionRequestedProductListNotifications =
  (): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const notificationId = 'dismiss-product-list-notifications';
      const customerId = getState().customer.selectedCustomer?.CustomerId;
      const productListNotificationIds = selectAllNonViewOnlyAttentionRequestedProductListNotificationIds(getState());

      dispatch(productListNotificationSlice.actions.setProductListNotificationsLoading(true));

      const { data } = await productListNotificationService.dismissActionRequestedProductListNotifications(customerId);
      if (data.IsSuccess) {
        dispatch(productListNotificationSlice.actions.removeProductListNotifications(productListNotificationIds));
        dispatch(addAppNotification(NotificationKeys.GrowlerSuccess, '', 'Notification dismissed', notificationId));
      } else dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(productListNotificationSlice.actions.setProductListNotificationsLoading(false));
    }
  };
