import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { UserCustomer } from 'common';
import { FC } from 'react';
import { CfMenuItem } from '../../../../../../../../cf-ui/Menu/MenuItem/CfMenuItem';
import LocationLabel from '../../../../../dialogs/LocationSearchDialog/components/LocationLabel';

export interface LocationMenuItemProps {
  customer: UserCustomer | undefined;
  onSelect?: (customer: UserCustomer | undefined) => void;
  customerNameTestId?: string;
  customerAddressTestId?: string;
  customerZipTestId?: string;
  customerNumberTestId?: string;
  customNameSx?: SxProps<Theme>;
  customAddressSx?: SxProps<Theme>;
  menuItemSx?: SxProps<Theme>;
}

const LocationMenuItem: FC<LocationMenuItemProps> = (props: LocationMenuItemProps) => {
  return (
    <CfMenuItem
      dataTestId={`location-menu-item-${props.customer?.CustomerId}`}
      sx={{ lineHeight: '12px', ...props.menuItemSx }}
      onSelect={() => props.onSelect?.(props.customer)}
    >
      <Box sx={{ display: 'flex' }}>
        <LocationLabel
          item={{
            selected: false,
            customerId: props.customer?.CustomerId ?? '',
            customerName: props.customer?.CustomerName ?? '',
            customerAddress: props.customer?.CustomerAddress,
            customerLocation: props.customer?.CustomerLocation,
            customerZip: props.customer?.CustomerZip,
            customerNumber: props.customer?.CustomerNumber,
            operationCompanyName: props.customer?.OperationCompanyName,
          }}
          customerNameTestId={props.customerNameTestId}
          customerAddressTestId={props.customerAddressTestId}
          customerZipTestId={props.customerZipTestId}
          customerNumberTestId={props.customerNumberTestId}
        />
      </Box>
    </CfMenuItem>
  );
};

export default LocationMenuItem;
