import { Typography, TypographyPropsVariantOverrides } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { SxProps, Theme } from '@mui/system';
import { OverridableStringUnion } from '@mui/types';
import { RootState, updateCurrentOrderEntryHeaderName, useAppDispatch, useAppSelector } from 'common';
import { OrderEntryType } from 'common/src/models/order.enums';
import { useState } from 'react';
import CfEditableLabel from '../../../../../cf-ui/EditableLabel/CfEditableLabel';

interface EditableHeaderNameProps {
  variant: OverridableStringUnion<Variant, TypographyPropsVariantOverrides>;
  labelVariant?: OverridableStringUnion<Variant, TypographyPropsVariantOverrides>;
  label?: string;
  size?: 'small' | 'medium';
  direction?: 'column' | 'row';
  containerSx?: SxProps<Theme>;
  inputAltTestId?: string;
  buttonContainerSx?: SxProps<Theme>;
  isEditable?: boolean;
}

export const EditableHeaderName = (props: EditableHeaderNameProps): JSX.Element => {
  const { variant, label, size, direction, containerSx, labelVariant, inputAltTestId, buttonContainerSx } = props;
  const isEditable = props.isEditable ?? true;
  // hooks
  const dispatch = useAppDispatch();

  // selectors
  const currentOrder = useAppSelector((x: RootState) => x.orders.order);

  // state
  const [refreshHeaderName, setRefreshHeaderName] = useState<boolean>(true);
  const [editMode, setEditMode] = useState<boolean>(false);

  const onOrderEntryHeaderNameBlur = (value: string) => {
    if (value !== currentOrder?.OrderEntryHeaderName) {
      dispatch(
        updateCurrentOrderEntryHeaderName(value ?? '', (isSuccess: boolean) => {
          if (!isSuccess) setRefreshHeaderName(!refreshHeaderName);
          setEditMode(false);
        })
      );
    } else {
      setEditMode(false);
    }
  };

  return isEditable ? (
    <CfEditableLabel
      label={label}
      labelName='Order name'
      labelVariant={labelVariant}
      size={size}
      direction={direction}
      isEditMode={editMode}
      forceReset={refreshHeaderName}
      onEdit={() => setEditMode(true)}
      onSubmit={onOrderEntryHeaderNameBlur}
      onClose={() => setEditMode(false)}
      value={currentOrder?.OrderEntryHeaderName ?? ''}
      labelDataTestId='order-name-text'
      inputDataTestId='order-name-text-input'
      inputAltDataTestId={inputAltTestId}
      buttonDataTestId='order-name-btn'
      variant={variant}
      maxLength={50}
      isLoading={!currentOrder}
      skeletonCharCount={18}
      containerSx={containerSx}
      buttonContainerSx={buttonContainerSx}
      isAutoFocused={true}
    />
  ) : (
    <Typography variant='h3'>
      {currentOrder?.OrderType === OrderEntryType.Edit
        ? `Order #: ${currentOrder?.OrderNumber}`
        : currentOrder?.OrderEntryHeaderName}
    </Typography>
  );
};
