import { useMsal } from '@azure/msal-react';
import { Typography } from '@mui/material';
import { AppRoutes, RouteName, useTimer } from 'common';
import { useCallback, useEffect, useState } from 'react';
import { CfDialog } from '../../../../cf-ui/dialogs/Dialog/CfDialog';
import { LocalStorageService } from '../../../../utilities/local-storage-service';

const eventsToListen = ['mousemove', 'keydown', 'scroll', 'mousedown'];

export const IdleTimeoutDialog = (): JSX.Element => {
  const [showTimeout, setShowTimeout] = useState(false);
  const { instance: msalInstance } = useMsal();
  const localStorageService = new LocalStorageService();
  const sessionTimeout = +(process.env.REACT_APP_CONFIG_SESSION_TIMEOUT || 1200) - 300;

  const sessionTimer = useTimer(sessionTimeout, () => {
    showTimeoutModal();
  });
  const popupTimer = useTimer(300, logout);

  const showTimeoutModal = () => {
    setShowTimeout(true);
    sessionTimer.stop();
    popupTimer.restart();
    removeEventListeners();
  };

  async function logout() {
    sessionTimer.stop();
    popupTimer.stop();
    localStorageService.clear();
    await msalInstance.logoutRedirect({ postLogoutRedirectUri: AppRoutes[RouteName.Home].Path });
  }

  const restartFullTimer = useCallback(() => {
    sessionTimer.restart();
    popupTimer.stop();
  }, []);

  const addEventListeners = () => {
    eventsToListen.map((event) => window.addEventListener(event, restartFullTimer, true));
  };

  const removeEventListeners = () => {
    eventsToListen.map((event) => window.removeEventListener(event, restartFullTimer, true));
  };

  const onLogoutConfirmed = async () => {
    setShowTimeout(false);
    await logout();
  };

  const onLogoutCancel = () => {
    setShowTimeout(false);
    popupTimer.stop();
    sessionTimer.restart();
    addEventListeners();
  };

  useEffect(() => {
    sessionTimer.restart();
    popupTimer.stop();
    addEventListeners();

    // cleanup
    return () => {
      sessionTimer.stop();
      popupTimer.stop();
      removeEventListeners();
    };
  }, []);

  // take only mm:ss
  const remainingTimeAsShortStr = popupTimer.remainingTimeAsStr.slice(14, 19);

  return (
    <CfDialog
      isOpen={showTimeout}
      title='Session Expire Warning'
      primaryButtonProps={{ children: 'Stay signed in', onClick: onLogoutCancel }}
      secondaryButtonProps={{ children: 'Sign out', onClick: onLogoutConfirmed }}
      onClose={onLogoutCancel}
    >
      <Typography variant='paragraph'>
        You will be logged out in {remainingTimeAsShortStr} due to inactivity. Do you want to continue?
      </Typography>
    </CfDialog>
  );
};
