import { BusinessUnitERPKeyType } from './api-shared.enums';
import { UserCustomer } from './site.models';

export interface GetAccountReceivableStatementRequest {
  CustomerId: string;
}

export interface GetAccountReceivableBalanceRequest {
  Customers: string[];
}

export interface AccountReceivableBalance {
  PastDue: number;
  CurrentDue: number;
  TotalDue: number;
  MakePaymentURL: string;
  ShowAlertMessage: boolean;
}

export interface GetAccountReceivableDetailRequest {
  Customers: string[];
  CurrentStatementsOnly: boolean;
  StartDate: Date | string;
  EndDate: Date | string;
  AccountReceivableDetailStatusType: AccountReceivableDetailStatusType[];
}

export interface AccountReceivableDetail {
  AccountReceivableDetailBusinessUnitERPKey: BusinessUnitERPKeyType;
  AccountReceivableDetailKey: string;
  CustomerId: string;
  CustomerName?: string;
  CustomerCity?: string;
  CustomerState?: string;
  CustomerZip?: string;
  CustomerNumber?: string;
  OperationCompanyName?: string;
  CustomerBusinessUnitERPKey: BusinessUnitERPKeyType;
  CustomerKey?: string;
  OperationCompanyNumber?: string;
  InvoiceHeaderBusinessUnitERPKey: BusinessUnitERPKeyType;
  InvoiceHeaderKey?: string;
  CanViewInvoiceDetail: boolean;
  AccountReceivableDetailType: AccountReceivableDetailType;
  AccountReceivableDetailTypeOtherDisplay?: string;
  AccountReceivableDetailStatusType: AccountReceivableDetailStatusType;
  DocumentNumber?: string;
  DueDate: string | Date;
  PaidDate: string | Date;
  Amount: number;
  CheckNumber?: string;
  PurchaseOrderNumber?: string;
  ReferenceNumber?: string;
  TransactionDate: string | Date;
}

export interface GetAccountReceivableDetailExportRequest extends GetAccountReceivableDetailRequest {
  Filter?: string;
  TimeZone: string;
}

export enum AccountReceivableDetailStatusType {
  Open = 0,
  PastDue = 1,
  Closed = 2,
}

export const AccountReceivableDetailStatus: Record<AccountReceivableDetailStatusType, string> = {
  [AccountReceivableDetailStatusType.Open]: 'Open',
  [AccountReceivableDetailStatusType.PastDue]: 'Past due',
  [AccountReceivableDetailStatusType.Closed]: 'Closed',
};

export enum AccountReceivableDetailType {
  Invoice = 0,
  Credit = 1,
  Adjustment = 2,
  Payment = 3,
  Other = 4,
}

export interface AccountReceivablePageOptions {
  Customers: UserCustomer[];
  CurrentStatementsOnly: boolean;
  DateOption: string;
  StartDate: string;
  EndDate: string;
  AccountReceivableDetailStatusTypes: AccountReceivableDetailStatusType[];
}
