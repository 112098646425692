import { Stack, Typography } from '@mui/material';
import { deleteOrderConfirmationNotification, OrderConfirmationNotification, useAppDispatch } from 'common';
import React, { FC } from 'react';
import CommunicationsDialogBase, { CommunicationsDialogBaseProps } from './CommunicationsDialogBase';

export interface CommunicationsDeleteEmailDialogProps extends CommunicationsDialogBaseProps {
  notification?: OrderConfirmationNotification;
}

const CommunicationsDeleteEmailDialog: FC<CommunicationsDeleteEmailDialogProps> = (
  props: CommunicationsDeleteEmailDialogProps
) => {
  const {
    isLoading,
    isOpen,
    primaryButtonProps,
    secondaryButtonProps,
    selectedCustomer,
    title,
    applyButtonTestId,
    checkboxTestId,
    closeButtonTestId,
    dialogTitleTestId,
    searchButtonTestId,
    selectAllCheckboxTestId,
    onClose,
    onSubmit,
  } = props;

  const dispatch = useAppDispatch();

  const handleSubmit = async () => {
    if (props.selectedCustomer && props.notification?.NotifyValue) {
      dispatch(
        deleteOrderConfirmationNotification(
          props.notification?.OrderConfirmationNotificationId,
          props.notification?.NotifyValue,
          props.selectedCustomer.CustomerId
        )
      );
    }
    onSubmit?.();
    onClose?.();
  };

  return (
    <CommunicationsDialogBase
      isOpen={isOpen}
      isLoading={isLoading}
      selectedCustomer={selectedCustomer}
      secondaryButtonProps={secondaryButtonProps}
      primaryButtonProps={{
        ...primaryButtonProps,
        children: 'Delete',
      }}
      title={title}
      applyButtonTestId={applyButtonTestId}
      checkboxTestId={checkboxTestId}
      closeButtonTestId={closeButtonTestId}
      dialogTitleTestId={dialogTitleTestId}
      searchButtonTestId={searchButtonTestId}
      selectAllCheckboxTestId={selectAllCheckboxTestId}
      onSubmit={handleSubmit}
      onClose={onClose}
    >
      <Stack sx={{ gap: 2, mt: 1 }}>
        <Typography variant='responsiveParagraphBold'>
          {`Are you sure you want to delete "${props.notification?.NotifyValue}"?`}
        </Typography>
        <Typography variant='responsiveParagraph'>
          Deleting this email cannot be undone, but you may add it again as a new one at any time.
        </Typography>
      </Stack>
    </CommunicationsDialogBase>
  );
};

export default CommunicationsDeleteEmailDialog;
