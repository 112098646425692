import { ButtonProps, Typography } from '@mui/material';
import { RootState, useAppSelector, UserCustomer } from 'common';
import { FC, PropsWithChildren } from 'react';
import { CfDialog } from '../../../../../../cf-ui/dialogs/Dialog/CfDialog';
import { DeviceType, useBreakpoint } from '../../../../../../hooks/useBreakpoint';

export interface CommunicationsDialogBaseProps {
  isLoading?: boolean;
  isOpen: boolean;
  primaryButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  selectedCustomer?: UserCustomer;
  title?: string;
  applyButtonTestId?: string;
  checkboxTestId?: string;
  closeButtonTestId?: string;
  dialogTitleTestId?: string;
  searchButtonTestId?: string;
  selectAllCheckboxTestId?: string;
  onClose?: () => void;
  onSubmit?: (value?: string) => void;
}

const CommunicationsDialogBase: FC<PropsWithChildren<CommunicationsDialogBaseProps>> = (
  props: PropsWithChildren<CommunicationsDialogBaseProps>
) => {
  const { deviceType } = useBreakpoint();

  const {
    isOpen,
    applyButtonTestId,
    children,
    closeButtonTestId,
    dialogTitleTestId,
    primaryButtonProps,
    secondaryButtonProps,
    title,
    onClose,
    onSubmit,
  } = props;

  const loading = useAppSelector((s: RootState) => s.orderConfirmation.orderConfirmationNotificationsLoading);

  return (
    <CfDialog
      isOpen={isOpen}
      primaryButtonProps={{
        children: 'Submit',
        color: 'primary',
        onClick: () => onSubmit?.(),
        size: deviceType === DeviceType.Desktop ? 'small' : 'medium',
        sx: { width: { xs: '100%', lg: 'unset' } },
        ...primaryButtonProps,
      }}
      secondaryButtonProps={{
        children: 'Cancel',
        color: 'secondary',
        onClick: () => onClose?.(),
        size: deviceType === DeviceType.Desktop ? 'small' : 'medium',
        sx: { width: { xs: '100%', lg: 'unset' }, mb: { xs: 1, lg: 0 } },
        ...secondaryButtonProps,
      }}
      onClose={onClose}
      closeButtonDataTestId={closeButtonTestId}
      primaryButtonDataTestId={applyButtonTestId}
      titleDataTestId={dialogTitleTestId}
      isLoading={loading}
      paperSx={{
        width: (theme) => ({
          xs: theme.spacing(41) + ' !important',
          lg: theme.spacing(44.5) + ' !important',
          xl: theme.spacing(53) + ' !important',
        }),
      }}
      headerSx={{ float: 'right', width: (theme) => theme.spacing(4), p: 0 }}
      dialogContainerSx={{ overflow: 'auto' }}
      dialogFooterSx={{
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        '& > button:not(:last-of-type)': { mr: { xs: 0, lg: 1 } },
        pt: 3,
      }}
      dialogCloseButtonSx={{ p: { xs: 0 } }}
    >
      <Typography variant='h4' sx={{ pb: 1 }}>
        {title}
      </Typography>

      {children}
    </CfDialog>
  );
};

export default CommunicationsDialogBase;
