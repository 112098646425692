import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import {
  GetShareProductListHeaderCustomersResponse,
  ProductListHeader,
  ProductListHeaderCustom,
  ProductListType,
} from '../../api/models/product-list-header.models';
import { RootState } from '../store';

// Adapters
const productListHeadersAdapter = createEntityAdapter<ProductListHeaderCustom>({
  selectId: (header: ProductListHeaderCustom) => header.ProductListHeaderId.toString(),
});

// State
interface ProductListManagement {
  listsLoading: boolean;
  productListHeaders: EntityState<ProductListHeaderCustom>;
  shareProductListCustomers: GetShareProductListHeaderCustomersResponse[];
  customListsCountsLoading: boolean;
  managedListsCountsLoading: boolean;
  multiUnitFindAndReplaceLoading: boolean;
}

const initialState: ProductListManagement = {
  listsLoading: true,
  productListHeaders: productListHeadersAdapter.getInitialState(),
  shareProductListCustomers: [],
  customListsCountsLoading: true,
  managedListsCountsLoading: true,
  multiUnitFindAndReplaceLoading: false,
};
// Reducers
export const productListManagementSlice = createSlice({
  name: 'productListManagement',
  initialState: initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    setListsLoading: (state: ProductListManagement, action: PayloadAction<boolean>) => {
      state.listsLoading = action.payload;
    },
    setProductListHeaders: (state: ProductListManagement, action: PayloadAction<ProductListHeaderCustom[]>) => {
      productListHeadersAdapter.setAll(state.productListHeaders, action.payload);
    },
    addProductListHeader: (state: ProductListManagement, action: PayloadAction<ProductListHeaderCustom>) => {
      productListHeadersAdapter.addOne(state.productListHeaders, action.payload);
    },
    deleteProductListHeader: (state: ProductListManagement, action: PayloadAction<string>) => {
      productListHeadersAdapter.removeOne(state.productListHeaders, action.payload);
    },
    updateProductList: (state: ProductListManagement, action: PayloadAction<ProductListHeader>) => {
      productListHeadersAdapter.updateOne(state.productListHeaders, {
        id: action.payload.ProductListHeaderId.toString(),
        changes: { ProductListTitle: action.payload.ProductListTitle, IsPrivate: action.payload.IsPrivate },
      });
    },
    updateProductListNotificationsDisabled: (
      state: ProductListManagement,
      action: PayloadAction<{ productListHeaderId: string; notificationsDisabled: boolean }>
    ) => {
      productListHeadersAdapter.updateOne(state.productListHeaders, {
        id: action.payload.productListHeaderId.toString(),
        changes: { IsNotificationsDisabled: action.payload.notificationsDisabled },
      });
    },
    updateProductListDetailCount: (
      state: ProductListManagement,
      action: PayloadAction<UpdateProductListDetailCount>
    ) => {
      productListHeadersAdapter.updateOne(state.productListHeaders, {
        id: action.payload.ProductListHeaderId.toString(),
        changes: { ProductListDetailCount: action.payload.ProductListDetailCount },
      });
    },
    setCustomListsCountsLoading: (state: ProductListManagement, action: PayloadAction<boolean>) => {
      state.customListsCountsLoading = action.payload;
    },
    setManagedListsCountsLoading: (state: ProductListManagement, action: PayloadAction<boolean>) => {
      state.managedListsCountsLoading = action.payload;
    },
    setMultiUnitFindAndReplaceLoading: (state: ProductListManagement, action: PayloadAction<boolean>) => {
      state.multiUnitFindAndReplaceLoading = action.payload;
    },
  },
});

// Selectors
export const { selectAll: selectAllProductListHeaders, selectById: selectProductListHeaderById } =
  productListHeadersAdapter.getSelectors<RootState>(
    (state: RootState) => state.productListManagement.productListHeaders
  );

// Custom Selectors
export const selectAllManagedProductListHeaderIds = (state: RootState): string[] =>
  selectAllProductListHeaders(state)
    .filter((h) => h.ProductListType === ProductListType.Managed || h.ProductListType === ProductListType.Marketing)
    .map((h) => h.ProductListHeaderId);

export const selectAllCustomProductListHeaderIds = (state: RootState): string[] =>
  selectAllProductListHeaders(state)
    .filter((h) => h.ProductListType === ProductListType.Custom)
    .map((h) => h.ProductListHeaderId);

// state models
export interface UpdateProductListDetailCount {
  ProductListHeaderId: string;
  ProductListDetailCount: number;
}
