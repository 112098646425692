import { AnyAction, applyMiddleware, configureStore } from '@reduxjs/toolkit';
import { RouterState, connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, createMemoryHistory } from 'history';
import thunkMiddleware, { ThunkAction } from 'redux-thunk';
import { customerGroupCreateSlice } from './account/customer-groups-create.slice';
import { customerGroupSelectedSlice } from './account/customer-groups-selected.slice';
import { customerGroupsSlice } from './account/customer-groups.slice';
import { customerSupportSlice } from './account/customer-support.slice';
import { manageUsersSlice } from './account/manage-users.slice';
import { messageCreateSlice } from './account/message-create.slice';
import { messageGroupSelectedSlice } from './account/message-group-selected.slice';
import { messageGroupSlice } from './account/message-group.slice';
import { messageSelectedSlice } from './account/message-selected.slice';
import { messageSlice } from './account/message.slice';
import { customerSlice } from './common/customer.slice';
import { globalSlice } from './common/global.slice';
import { userSlice } from './common/user.slice';
import { deliveriesSlice } from './deliveries/deliveries.slice';
import { accountReceivablesSlice } from './finances/account-receivable.slice';
import { insightsReportSlice } from './finances/insights-report.slice';
import { insightsSlice } from './finances/insights.slice';
import { invoiceDetailsSlice } from './finances/invoice-details.slice';
import { invoiceSlice } from './finances/invoice.slice';
import { marketingZonesSlice } from './marketing-zones/marketing-zones.slice';
import { itemOrderEntrySlice } from './orders/item-order-entry.slice';
import { orderConfirmationSlice } from './orders/order-confirmation.slice';
import { orderEntrySlice } from './orders/order-entry.slice';
import { orderReviewSlice } from './orders/order-review.slice';
import { orderSlice } from './orders/order.slice';
import { ordersSlice } from './orders/orders.slice';
import { productDetailSlice } from './orders/product-detail.slice';
import { reorderSlice } from './orders/reorder.slice';
import { parMaintenanceSlice } from './par-management/par-maintenance.slice';
import { parManagementSlice } from './par-management/par-management.slice';
import { deleteProductListDetailsSlice } from './product-list-management/delete-product-list-details.slice';
import { productListAddFromListSlice } from './product-list-management/product-list-add-from-list.slice';
import { productListDiarySlice } from './product-list-management/product-list-diary.slice';
import { productListManagementSlice } from './product-list-management/product-list-management.slice';
import { productListNotificationSlice } from './product-list-management/product-list-notification.slice';
import { productListReportSlice } from './product-list-management/product-list-report.slice';
import { productListSearchSlice } from './product-list-management/product-list-search.slice';
import { productListSlice } from './product-list-management/product-list.slice';
import { productComparisonSlice } from './search/product-compare.slice';
import { productSearchSlice } from './search/product-search.slice';
import { searchAnalysisProductsSlice } from './search/search-analysis-products.slice';
import { searchAnalysisSlice } from './search/search-analysis.slice';
import { similarProductSearchSlice } from './search/similar-products-search.slice';

const isClient = (): boolean => {
  try {
    if (window && window.document && window.document.createElement !== undefined) return true;
    return false;
  } catch (err) {
    return false;
  }
};
const getHistory = () => {
  if (isClient()) return createBrowserHistory();
  return createMemoryHistory();
};
export const reduxHistory = getHistory();

export const rootReducer = {
  router: connectRouter(reduxHistory),
  accountReceivables: accountReceivablesSlice.reducer,
  customer: customerSlice.reducer,
  customerGroups: customerGroupsSlice.reducer,
  customerGroupSelected: customerGroupSelectedSlice.reducer,
  customerGroupCreate: customerGroupCreateSlice.reducer,
  customerSupport: customerSupportSlice.reducer,
  deleteProductListDetails: deleteProductListDetailsSlice.reducer,
  deliveries: deliveriesSlice.reducer,
  global: globalSlice.reducer,
  insights: insightsSlice.reducer,
  insightsReport: insightsReportSlice.reducer,
  invoice: invoiceSlice.reducer,
  invoiceDetails: invoiceDetailsSlice.reducer,
  itemOrderEntry: itemOrderEntrySlice.reducer,
  marketingZones: marketingZonesSlice.reducer,
  message: messageSlice.reducer,
  messageSelected: messageSelectedSlice.reducer,
  messageCreate: messageCreateSlice.reducer,
  messageGroup: messageGroupSlice.reducer,
  messageGroupSelected: messageGroupSelectedSlice.reducer,
  orderConfirmation: orderConfirmationSlice.reducer,
  orderEntry: orderEntrySlice.reducer,
  orderReview: orderReviewSlice.reducer,
  order: orderSlice.reducer,
  orders: ordersSlice.reducer,
  parMaintenance: parMaintenanceSlice.reducer,
  parManagement: parManagementSlice.reducer,
  productComparison: productComparisonSlice.reducer,
  productDetail: productDetailSlice.reducer,
  productList: productListSlice.reducer,
  productListAddFromList: productListAddFromListSlice.reducer,
  productListDiary: productListDiarySlice.reducer,
  productListManagement: productListManagementSlice.reducer,
  productListNotification: productListNotificationSlice.reducer,
  productListSearch: productListSearchSlice.reducer,
  productListReport: productListReportSlice.reducer,
  productSearch: productSearchSlice.reducer,
  reorder: reorderSlice.reducer,
  similarProductSearch: similarProductSearchSlice.reducer,
  searchAnalysis: searchAnalysisSlice.reducer,
  searchAnalysisProducts: searchAnalysisProductsSlice.reducer,
  user: userSlice.reducer,
  manageUsers: manageUsersSlice.reducer,
};

export const store = configureStore({
  reducer: rootReducer,
  enhancers: [applyMiddleware(...[thunkMiddleware], routerMiddleware(reduxHistory))],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type Selector<S> = (state: RootState) => S;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, any, AnyAction>;
// Workaround for bug in preloadedState type (converting 'unknown' to 'never' for some reason)
export type PreloadedRootState = RootState & { router: RouterState<never> };
