import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { DeleteMessageGroupRequest } from '../../api';
import MessageGroupService from '../../api/services/message-group.service';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { globalSlice } from '../common/global.slice';
import { AppDispatch, AppThunk, RootState } from '../store';
import { messageGroupSlice } from './message-group.slice';

const messageGroupService = MessageGroupService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Calls and stores the result of the GetMessageGroups API call
 *
 * @returns NULL
 */
export const getMessageGroups =
  (): AppThunk<Promise<void>> => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (getState().messageGroup.messageGroupsLoaded || getState().messageGroup.messageGroupsLoading) {
        return;
      }

      if (!getState().messageGroup.messageGroupsLoading) {
        dispatch(messageGroupSlice.actions.setMessageGroupsLoading(true));
      }
      const { data } = await messageGroupService.getMessageGroups();
      if (data.IsSuccess) {
        dispatch(messageGroupSlice.actions.setMessageGroups(data.ResultObject));
        dispatch(messageGroupSlice.actions.setMessageGroupsLoaded(true));
      } else {
        dispatch(messageGroupSlice.actions.resetMessageGroups());
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      dispatch(messageGroupSlice.actions.resetMessageGroups());
      console.error(error);
    } finally {
      dispatch(messageGroupSlice.actions.setMessageGroupsLoading(false));
    }
  };

/**
 * Resets the message-group slice to it's initial values
 *
 * @returns NULL
 */
export const resetMessageGroups = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(messageGroupSlice.actions.resetMessageGroups());
};

/**
 * Calls the DeleteMessageGroup API call
 *
 * @param request - The request to send with the API call
 * @param successCallback - Method to call on success
 * @returns NULL
 */
export const deleteMessageGroup =
  (request: DeleteMessageGroupRequest, successCallback?: () => void): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await messageGroupService.deleteMessageGroup(request);
      if (data.IsSuccess) {
        dispatch(messageGroupSlice.actions.removeMessageGroup(request.MessageGroupId));
        successCallback?.();
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      console.error(error);
    }
  };
