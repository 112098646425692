import { Typography } from '@mui/material';
import { deleteEditedOrder, RootState, selectIsEditingOrder, useAppDispatch, useAppSelector } from 'common';
import { useEffect } from 'react';
import { CfSimpleDialog } from '../../../../cf-ui/dialogs/SimpleDialog/CfSimpleDialog';
import {
  closeSwitchFromEditedOrderDialog,
  setSwitchFromEditedOrderDialog,
  useSwitchFromEditedOrder,
} from '../../../../hooks/orders/order-entry/useSwitchFromEditedOrder';

export const SwitchFromEditedOrderDialog = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const orderHeaderId = useAppSelector((s: RootState) => s.orders.orderCart?.OrderEntryHeaderId);
  const hookState = useSwitchFromEditedOrder();
  const isEditingOrder = useAppSelector((s) => selectIsEditingOrder(s));

  const handleConfirm = async () => {
    await dispatch(deleteEditedOrder(orderHeaderId));
    hookState?.onSwitchDialogConfirm?.();
    closeSwitchFromEditedOrderDialog();
  };

  const handleDismiss = () => {
    closeSwitchFromEditedOrderDialog();
  };

  useEffect(() => {
    setSwitchFromEditedOrderDialog(isEditingOrder);
  }, [isEditingOrder]);

  return (
    <>
      <CfSimpleDialog
        isOpen={hookState?.isOpen ?? false}
        title='Cancel order editing?'
        primaryButtonProps={{ children: 'Yes, cancel editing', onClick: handleConfirm }}
        secondaryButtonProps={{ children: 'Back', onClick: handleDismiss }}
        onClose={handleDismiss}
        dialogFooterSx={{
          flexDirection: { xs: 'column', lg: 'row' },
          alignItems: 'stretch',
        }}
      >
        <Typography variant='responsiveParagraph' component='p' sx={{ mb: 2 }}>
          Are you sure you want to switch orders? This will cancel the order editing that is currently in progress, and
          any changes will be lost.
        </Typography>
      </CfSimpleDialog>
    </>
  );
};
