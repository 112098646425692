export enum MessageStatusType {
  Inbox = 0,
  Sent = 1,
  Draft = 2,
}

export enum MessageRecipientType {
  User,
  Customer,
  CustomerGroup,
  MessageGroup,
}
