import { SeverityLevel } from '@microsoft/applicationinsights-web';
import MessageService from '../../api/services/message.service';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { globalSlice } from '../common/global.slice';
import { AppDispatch, AppThunk, RootState } from '../store';
import { messageSelectedSlice } from './message-selected.slice';
import { messageSlice, selectMessageById } from './message.slice';
import { getUnreadMessagesCount } from './message.thunks';

const messageService = MessageService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Sets the message and requestedMessageId valyes in the slice to their initial values
 *
 * @returns NULL
 */
export const resetMessage = (): AppThunk => (dispatch: AppDispatch) =>
  dispatch(messageSelectedSlice.actions.resetMessage());

/**
 * Calls and stores the results of the GetMessage API call
 *
 * @param messageId - Id of the message to retrieve
 * @returns NULL
 */
export const getMessage =
  (messageId: string): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (getState().messageSelected.requestedMessageId === messageId) return;

      dispatch(messageSelectedSlice.actions.setRequestedMessageId(messageId));

      const { data } = await messageService.getMessage(messageId);
      if (messageId !== getState().messageSelected.requestedMessageId) return;

      if (data.IsSuccess) {
        dispatch(messageSelectedSlice.actions.setMessage(data.ResultObject.Message));
        const messagePreview = selectMessageById(getState(), messageId);
        if (messagePreview && !messagePreview.IsRead) {
          dispatch(messageSlice.actions.setMessagePreviewAsRead(messagePreview));
          dispatch(getUnreadMessagesCount());
        }
      } else {
        dispatch(messageSelectedSlice.actions.resetMessage());
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      dispatch(messageSelectedSlice.actions.resetMessage());
      console.error(error);
    }
  };
