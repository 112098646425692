import {
  AdvanceFilter,
  DeliveryOptions,
  Facets,
  Nutritional,
  ProductSearchFilter,
  ProductSearchFilterType,
  UnitOfMeasureType,
} from '../api';
import { AppRoute, ProductData } from '../models';

export type FindSimilarMode = 'order-confirmation' | 'order-entry' | 'product-list' | 'product-list-notification';

export interface FindSimilarData {
  product: ProductData;
  isSelected: boolean;
  replacedBy: { productKey: string; uom: UnitOfMeasureType; quantity: number }[];
}
export interface ProductSearchHistoryState {
  advanceFilter?: AdvanceFilter;
  cachedFacets?: Facets;
  parentRoute?: AppRoute;
  parentRouteDisplayName?: string;
  findSimilarProducts?: FindSimilarData[];
  activeProductIndex?: number;
  notificationToDismiss?: string;
}

export function isAdvanceFilterUnset(advanceFilter: AdvanceFilter | undefined): boolean {
  return (
    !advanceFilter ||
    (!advanceFilter.Badges.length &&
      !advanceFilter.Brand &&
      !advanceFilter.Brands.length &&
      !advanceFilter.CategoryIds.length &&
      !Object.keys(advanceFilter.DeliveryOptions).length &&
      advanceFilter.HasOrderedInLastNumberOfDays === undefined &&
      !advanceFilter.HasPreviousPurchase &&
      !advanceFilter.IsCriticalItem &&
      !advanceFilter.IsNewInStock &&
      !Object.keys(advanceFilter.Nutritional).length &&
      !advanceFilter.PackSize?.length &&
      !advanceFilter.StorageTypes.length)
  );
}

export function updateAdvanceFilterObject(
  advanceFilter: AdvanceFilter | undefined,
  filters: ProductSearchFilter[]
): AdvanceFilter {
  const result: AdvanceFilter = {
    IsCriticalItem: advanceFilter?.IsCriticalItem,
    IsNewInStock: advanceFilter?.IsNewInStock,
    HasOrderedInLastNumberOfDays: advanceFilter?.HasOrderedInLastNumberOfDays,
    HasPreviousPurchase: advanceFilter?.HasPreviousPurchase,
    Badges: [...(advanceFilter?.Badges || [])],
    CategoryIds: [...(advanceFilter?.CategoryIds || [])],
    Brand: advanceFilter?.Brand,
    Brands: [...(advanceFilter?.Brands || [])],
    PackSize: advanceFilter?.PackSize,
    StorageTypes: [...(advanceFilter?.StorageTypes || [])],
    DeliveryOptions: { ...advanceFilter?.DeliveryOptions },
    Nutritional: { ...advanceFilter?.Nutritional },
  };
  filters.forEach((filter) => {
    switch (filter.position) {
      case ProductSearchFilterType.Base:
        (result[filter.name as keyof AdvanceFilter] as unknown) = filter.value;
        break;
      case ProductSearchFilterType.NullableBase:
        (result[filter.name as keyof AdvanceFilter] as unknown) = filter.value || undefined;
        break;
      case ProductSearchFilterType.Badges:
      case ProductSearchFilterType.CategoryIds:
      case ProductSearchFilterType.StorageTypes:
      case ProductSearchFilterType.Brands:
        const tempArray = (result[filter.name as keyof AdvanceFilter] as string[]) || [];
        if (filter.value) {
          tempArray.push(filter.label as string);
        } else {
          const itemIndex = tempArray.indexOf(filter.label as string);
          tempArray.splice(itemIndex, 1);
        }
        (result[filter.name as keyof AdvanceFilter] as string[]) = tempArray;
        break;
      case ProductSearchFilterType.DeliveryOptions:
        result.DeliveryOptions[filter.name as keyof DeliveryOptions] = filter.value || undefined;
        break;
      case ProductSearchFilterType.Nutritional:
      case ProductSearchFilterType.Origin:
        result.Nutritional[filter.name as keyof Nutritional] = filter.value || undefined;
        break;
    }
  });

  return result;
}
