import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { APIMessages } from '../../api/models/api-shared.models';
import { ProductListCategory } from '../../api/models/product-list-category.models';
import ProductListCategoryService from '../../api/services/product-list-category.service';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { ProductListCategoryCustom } from '../../models/product-list.models';
import { globalSlice } from '../common/global.slice';
import { setErrorDialogContent } from '../common/global.thunks';
import { AppDispatch, AppThunk, RootState } from '../store';
import { productListSlice, selectCategoryById } from './product-list.slice';
import { restoreProductListSearch } from './product-list.thunks';

// Hooks
const productListCategoryService = ProductListCategoryService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

//Thunks
/**
 * Swaps the position of two categories in a product list
 *
 * @param currentCategoryId - the id of the category in the destined slot
 * @param selectedCategoryId - the id of the category being moved
 * @returns void
 */
export const dropProductListCategory =
  (currentCategoryId: string, selectedCategoryId: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const currentCategory = selectCategoryById(getState().productList.categories, currentCategoryId);
    const selectedCategory = selectCategoryById(getState().productList.categories, selectedCategoryId);
    if (!currentCategory || !selectedCategory?.ProductListCategoryId) return;

    const isCategoryUpdating = getState().productList.isListSequencing;
    if (isCategoryUpdating) return;
    dispatch(productListSlice.actions.setIsListSequencing(true));

    dispatch(
      productListSlice.actions.sequenceCategory({
        selectedCategoryId: selectedCategoryId,
        destinationCategoryId: currentCategoryId,
      })
    );

    dispatch(
      sequenceProductListCategory(
        selectedCategory.ProductListCategoryId,
        currentCategory.ParentProductListCategoryId,
        currentCategory.Sequence,
        undefined,
        (errors: string[]) => {
          dispatch(setErrorDialogContent('Error occurred', errors));
          dispatch(restoreProductListSearch());
        }
      )
    );

    dispatch(productListSlice.actions.setIsListSequencing(false));
  };

/**
 * Converts a category into the subcategory of another category (also applies to subcategories)
 *
 * @param destinationCategoryId - the id of the category in the destined slot
 * @param selectedCategoryId - the id of the category being moved
 * @returns void
 */
export const nestProductListCategory =
  (destinationCategoryId: string, selectedCategoryId: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const destinationCategory = selectCategoryById(getState().productList.categories, destinationCategoryId);
    const selectedCategory = selectCategoryById(getState().productList.categories, selectedCategoryId);
    if (!destinationCategory?.ProductListCategoryId || !selectedCategory?.ProductListCategoryId) return;

    const isCategoryUpdating = getState().productList.isListSequencing;
    if (isCategoryUpdating) return;
    dispatch(productListSlice.actions.setProductListSearchBackup());
    dispatch(productListSlice.actions.setIsListSequencing(true));

    dispatch(
      productListSlice.actions.nestCategory({
        selectedCategoryId: selectedCategoryId,
        destinationCategoryId: destinationCategoryId,
      })
    );

    dispatch(
      sequenceProductListCategory(
        selectedCategory.ProductListCategoryId,
        destinationCategory.ProductListCategoryId,
        0,
        undefined,
        (errors: string[]) => {
          dispatch(setErrorDialogContent('Error occurred', errors));
          dispatch(restoreProductListSearch());
        }
      )
    );

    dispatch(productListSlice.actions.setIsListSequencing(false));
  };

/**
 * Sends a request to the api to update the position of a (sub)category within a product list
 *
 * @param productListCategoryId - the (sub)category to be repositioned
 * @param parentProductListCategoryId - the parent category of the repositioned item
 * @param sequence - the new position of the item within its parent category
 * @param successCallback - executed when the api response is successful
 * @param errorCallback - executed when the api response is unsuccessful
 * @returns void
 */
export const sequenceProductListCategory =
  (
    productListCategoryId: string,
    parentProductListCategoryId: string | undefined,
    sequence: number,
    successCallback?: (productListCategories: ProductListCategory) => void | Promise<void>,
    errorCallback?: (errors: string[]) => void | Promise<void>
  ): AppThunk =>
  async () => {
    try {
      const { data } = await productListCategoryService.sequenceProductListCategory({
        ProductListCategoryId: productListCategoryId,
        ParentProductListCategoryId: parentProductListCategoryId,
        Sequence: sequence,
      });

      if (data.IsSuccess) {
        successCallback?.(data.ResultObject);
      } else {
        errorCallback?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sends a request to the api to update the data of a product list category (e.g. Title)
 *
 * @param category - the category data to be used for the api request
 * @returns void
 */
export const updateProductListCategory =
  (category: ProductListCategoryCustom): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      if (!category.ProductListCategoryId || !category.CategoryTitle) return;
      const { data } = await productListCategoryService.updateProductListCategory({
        ProductListCategoryId: category.ProductListCategoryId,
        CategoryTitle: category.CategoryTitle,
      });

      if (data.IsSuccess) {
        dispatch(productListSlice.actions.updateCategoryTitle({ updatedCategory: category }));
        return { IsError: false };
      } else dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sends a request to the api to delete a category
 *
 * @param productListCategoryId - the id representing the category to be deleted
 * @param onSuccess - executed when the api response is successful
 * @returns void
 */
export const deleteProductListCategory =
  (productListCategoryId: string, onSuccess?: () => void): AppThunk<Promise<APIMessages>> =>
  async (dispatch: AppDispatch): Promise<APIMessages> => {
    try {
      const { data } = await productListCategoryService.deleteProductListCategory({
        ProductListCategoryId: productListCategoryId,
      });

      if (data.IsSuccess) {
        onSuccess?.();
        return { IsError: false };
      } else dispatch(setErrorDialogContent('Error occured', data.ErrorMessages));

      return {
        IsError: true,
        ErrorMessages: data.ErrorMessages,
      };
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      return {
        IsError: true,
        ErrorMessages: ['An error occurred while deleting the product list category.'],
      };
    }
  };

/**
 * Sends an api request to create a product list category
 *
 * @param productListHeaderId - the product list to associate the new category with
 * @param createCategoryTitle - the title of the new category
 * @param parentCategoryId - the parent category id to place the new subcategory under
 * @param successCallback - executed when the api response is successful
 * @param errorCallback - executed when the api response is unsuccessful
 * @returns void
 */
export const createProductListCategory =
  (
    productListHeaderId: string,
    createCategoryTitle?: string,
    parentCategoryId?: string,
    successCallback?: (productListCategory: ProductListCategory) => void | Promise<void>,
    errorCallback?: (errors: string[]) => void | Promise<void>
  ): AppThunk<Promise<void>> =>
  async (dispatch): Promise<void> => {
    try {
      const { data } = await productListCategoryService.createProductListCategory({
        ProductListHeaderId: productListHeaderId,
        CategoryTitle: createCategoryTitle,
        ParentProductListCategoryId: parentCategoryId,
      });

      if (data.IsSuccess) successCallback?.(data.ResultObject);
      else {
        dispatch(setErrorDialogContent('Error occured', data.ErrorMessages));
        errorCallback?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Retrieves category data from the api related to the specified product list header
 *
 * @param productListHeaderId - specifies which product list to retrieve the categories data for
 * @param includeUncategorized - specifies whether to include products with no specified category for the product list
 * @param successCallback - executed when the api response is successful
 * @param errorCallback - executed when the api response is unsuccessful
 * @returns void
 */
export const getProductListCategories =
  (
    productListHeaderId: string,
    includeUncategorized: boolean,
    successCallback?: (productListCategories: ProductListCategory[]) => void | Promise<void>,
    errorCallback?: (errors: string[]) => void | Promise<void>
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const { data } = await productListCategoryService.getProductListCategories({
        ProductListHeaderId: productListHeaderId,
        IncludeUncategorized: includeUncategorized,
      });

      if (data.IsSuccess) {
        successCallback?.(data.ResultObject);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        errorCallback?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };
