import { Typography } from '@mui/material';
import { Box, SxProps, Theme } from '@mui/system';
import { UserCustomer } from 'common';
import { FC } from 'react';
import { CfRadioList } from '../../../../../cf-ui/RadioList/CfRadioList';
import { RadioItem } from '../../../../../cf-ui/RadioList/Radio/CfRadio';
import LocationMenuItem from '../../Header/components/Location/components/common/LocationMenuItem';
import LocationSearch, { LocationSearchItem, mapToLocationSearchItems } from './LocationSearch';

export interface LocationSearchRadioListProps {
  customerOptions: UserCustomer[];
  searchActionArea?: React.ReactNode;
  selectedCustomer: UserCustomer | undefined;
  boxSx?: SxProps<Theme>;
  radioSx?: SxProps<Theme>;
  radioBoxSx?: SxProps<Theme>;
  menuOpen?: boolean;
  searchInputRef?: React.RefObject<HTMLInputElement>;
  onSelect?: (customer: UserCustomer | undefined) => void;
  onSearchInputKeyDown?: React.KeyboardEventHandler;
  onLastRadioKeyDown?: React.KeyboardEventHandler;
  inputTestId?: string;
  disabledCustomerOptionIds?: string[];
}

const LocationSearchRadioList: FC<LocationSearchRadioListProps> = (props: LocationSearchRadioListProps) => {
  const {
    customerOptions,
    selectedCustomer,
    boxSx,
    radioSx,
    radioBoxSx,
    menuOpen,
    searchInputRef,
    onSelect,
    onSearchInputKeyDown,
    onLastRadioKeyDown,
    inputTestId,
    disabledCustomerOptionIds,
  } = props;

  const onRadioClick = (customerString: string) => {
    customerOptions.map((customer) => {
      if (customer.CustomerId === customerString && onSelect) {
        onSelect(customer);
      }
    });
  };

  const locationItems = mapToLocationSearchItems({
    ucs: customerOptions,
    selectedCustomerIds: selectedCustomer?.CustomerId ? [selectedCustomer.CustomerId] : [],
    hideCreditTerm: true,
    initialSelectedCustomerId: disabledCustomerOptionIds,
  });

  return (
    <Box sx={boxSx}>
      <LocationSearch
        items={locationItems}
        isOpen={menuOpen}
        inputDataTestId={inputTestId}
        searchInputRef={searchInputRef}
        onSearchInputKeyDown={onSearchInputKeyDown}
        searchActionArea={props.searchActionArea}
      >
        {(filteredItems) => (
          <Box
            tabIndex={-1} // We must explicitly set a negative tab index in the event firefox interprets a need to make this div focusable when a scrollbar is rendered via a height/maxHeight restriction.
            sx={{
              px: 1,
              py: 0,
              borderRadius: '4px',
              backgroundColor: (theme: Theme) => theme.palette.coolGrey[100],
              ...radioBoxSx,
            }}
          >
            {filteredItems.length === 0 ? (
              <Box display='flex' alignItems='center' justifyContent='center' sx={{ mt: 2 }}>
                <Typography variant='responsiveParagraph'>No results found</Typography>
              </Box>
            ) : (
              <CfRadioList
                items={getInitialItems(filteredItems, customerOptions, radioSx, new Set(disabledCustomerOptionIds))}
                defaultValue={selectedCustomer?.CustomerId}
                currentValue={selectedCustomer?.CustomerId}
                sx={{ pb: { xs: 0, xl: 0 } }}
                onChange={onRadioClick}
                onLastRadioKeyDown={onLastRadioKeyDown}
                applyFocusToInput={true}
                preventGroupOnChange={true}
              />
            )}
          </Box>
        )}
      </LocationSearch>
    </Box>
  );
};

const getInitialItems = (
  locations: LocationSearchItem[],
  customerOptions: UserCustomer[],
  radioSx?: SxProps<Theme>,
  disabledCustomerIds?: Set<string>
): RadioItem[] => {
  const items: RadioItem[] = [];
  locations.map((location: LocationSearchItem) => {
    items.push({
      label: (
        <Box key={location.customerId} sx={{ width: '100%', mt: { xs: -0.5, xl: 0 }, mb: { xs: -0.25, xl: 0 } }}>
          <LocationMenuItem
            key={location.customerId}
            customer={customerOptions.find((c) => c.CustomerId === location.customerId)}
            customerNameTestId='location-menu-customer-name'
            customerAddressTestId='location-menu-customer-address'
            customerZipTestId='location-menu-customer-zip'
            customerNumberTestId='location-menu-customer-number'
            customNameSx={{ typography: 'responsiveParagraphBold' }}
            customAddressSx={{
              typography: 'responsiveParagraphSmall',
              color: (theme: Theme) => theme.palette.grey[600],
            }}
            menuItemSx={{ marginLeft: { xl: -2 } }}
          />
        </Box>
      ),
      value: location.customerId,
      sx: {
        alignItems: 'start',
      },
      radioSx: { mt: 2.1, ml: 1.6, ...radioSx },
      isDisabled: disabledCustomerIds ? disabledCustomerIds.has(location.customerId) : false,
    });
  });

  return items;
};

export default LocationSearchRadioList;
