import { ServiceResult } from '../models/api-shared.models';
import {
  BaseGroupByRequest,
  BaseSearchRequest,
  DeleteSearchHeaderRequest,
  GenericCriteriaFilterOption,
  GetCriteriaFilterOptionsRequest,
  GetSearchCriteriaGroupBysResult,
  GetSearchResultsRequest,
  InvoiceReportSearchHeader,
  InvoiceReportSearchResultData,
  ResequenceSearchGroupBysRequest,
  ResequenceSearchGroupBysResult,
  SaveSearchCriteriaRequest,
  SaveSearchCriteriaResultData,
  SearchCriteriaCustomersResultCustomer,
  SearchCriteriaData,
  SearchDrilldownRequest,
  SearchGroupBy,
  SetSearchAsFavoriteRequest,
  UpdateSearchDetailOptionsBatchRequest,
  UpdateSearchDetailOptionsRequest,
} from '../models/invoice-report.models';
import ApiService from './api-service';

class InvoiceReportSearchService extends ApiService {
  private static classInstance: InvoiceReportSearchService;

  private constructor() {
    super('InvoiceReportSearch');
  }

  public getInvoiceReportSearchHeaders(request: {
    invoiceReportSearchDirectoryId: string;
  }): ServiceResult<InvoiceReportSearchHeader[]> {
    return this.get(`${this.baseUrl}/GetSearchHeaders`, request);
  }

  public getSearchResults(request: GetSearchResultsRequest): ServiceResult<InvoiceReportSearchResultData> {
    return this.get(`${this.baseUrl}/GetSearchResults`, request);
  }

  public searchDrilldown(request: SearchDrilldownRequest): ServiceResult<InvoiceReportSearchResultData> {
    return this.post(`${this.baseUrl}/SearchDrilldown`, request);
  }

  public setSearchAsFavorite(request: SetSearchAsFavoriteRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/SetSearchAsFavorite`, request);
  }

  public getSearchCriteria(request: BaseSearchRequest): ServiceResult<SearchCriteriaData> {
    return this.get(`${this.baseUrl}/GetSearchCriteria`, request);
  }
  public getSearchCriteriaCustomers(
    request: BaseSearchRequest
  ): ServiceResult<SearchCriteriaCustomersResultCustomer[]> {
    return this.get(`${this.baseUrl}/GetSearchCriteriaCustomers`, request);
  }

  public saveSearchCriteria(request: SaveSearchCriteriaRequest): ServiceResult<SaveSearchCriteriaResultData> {
    return this.post(`${this.baseUrl}/SaveSearchCriteria`, request);
  }

  public getAvailableGroupBys(): ServiceResult<SearchGroupBy[]> {
    return this.get(`${this.baseUrl}/GetAvailableGroupBys`);
  }

  public getSearchCriteriaGroupBys(request: BaseSearchRequest): ServiceResult<GetSearchCriteriaGroupBysResult> {
    return this.get(`${this.baseUrl}/GetSearchCriteriaGroupBys`, request);
  }

  public removeSearchCriteriaGroupBy(request: BaseGroupByRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/RemoveSearchCriteriaGroupBy`, request);
  }

  public getCriteriaFilterOptions(
    request: GetCriteriaFilterOptionsRequest
  ): ServiceResult<GenericCriteriaFilterOption[]> {
    return this.get(`${this.baseUrl}/GetCriteriaFilterOptions`, request);
  }

  public updateSearchDetailOptionsBatch(request: UpdateSearchDetailOptionsBatchRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/UpdateSearchDetailOptionsBatch`, request);
  }
  public updateSearchDetailOptions(request: UpdateSearchDetailOptionsRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/UpdateSearchDetailOptions`, request);
  }

  public resequenceSearchGroupBys(
    request: ResequenceSearchGroupBysRequest
  ): ServiceResult<ResequenceSearchGroupBysResult> {
    return this.post(`${this.baseUrl}/ResequenceSearchGroupBys`, request);
  }

  public deleteSearchHeader(request: DeleteSearchHeaderRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/DeleteSearchHeader`, request);
  }

  public static getInstance(): InvoiceReportSearchService {
    if (!this.classInstance) {
      this.classInstance = new InvoiceReportSearchService();
    }
    return this.classInstance;
  }
}

export default InvoiceReportSearchService;
