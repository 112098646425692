import { styled } from '@mui/material/styles';
import { Popper, Typography } from '@mui/material/';

export const StyledList = styled('ul')(({ theme }) => ({
  listStyleType: 'none',
  paddingBottom: theme.spacing(1.5),
  paddingLeft: 0,
}));

export const StyledListItem = styled('li')(({ theme }) => ({
  padding: '8px 8px 4px 8px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.coolGrey[100],
    '& > div > span': {
      fontWeight: 600,
    },
  },
}));

export const StyledListItemHeader = styled('li')({
  padding: '8px 8px 4px 8px',
  display: 'flex',
  alignItems: 'center',
});

export const StyledListItemActive = styled('li')(({ theme }) => ({
  padding: '8px 8px 4px 8px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: theme.palette.coolGrey[100],
  '& > div > span': {
    fontWeight: 600,
  },
}));

export const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: 1110,
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.coolGrey[100]}`,
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
  borderRadius: '8px',
}));

export const StyledHeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.coolGrey[600],
  [theme.breakpoints.up('xs')]: {
    ...theme.typography.mobileParagraphSmall,
  },
  [theme.breakpoints.up('xl')]: {
    ...theme.typography.paragraphSmall,
  },
}));
