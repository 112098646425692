import { Box, Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const OrderSummaryContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.up('xl')]: {
    width: 272,
    marginLeft: theme.spacing(2),
    marginBottom: 0,
  },
}));

export const OrderSummaryCard = styled(Card)(({ theme }) => ({
  alignSelf: 'start',
  padding: theme.spacing(2),
  borderRadius: +theme.shape.borderRadius * 2,
  backgroundColor: theme.palette.common.white,
}));

export const StyledLabelTypography = styled(Typography)(() => ({
  marginRight: 5,
}));

export const Divider = styled('div')(({ theme }) => ({
  width: '100%',
  borderTop: `1px solid ${theme.palette.coolGrey[200]}`,
  margin: '0.4rem 0',
}));
