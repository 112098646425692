import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { DebugCatalogProduct } from '../../api/models/product-catalog.models';
import { normalizeProductKey } from '../../helpers/general/product';
import { RootState } from '../store';

const productsAdapter = createEntityAdapter<DebugCatalogProduct>({
  selectId: (product: DebugCatalogProduct) => normalizeProductKey(product.ProductKey),
  sortComparer: (productA: DebugCatalogProduct, productB: DebugCatalogProduct) =>
    productB.searchscore - productA.searchscore,
});

export const searchAnalysisProductsSlice = createSlice({
  name: 'searchAnalysisProducts',
  initialState: productsAdapter.getInitialState(),
  reducers: {
    resetProducts: () => {
      return productsAdapter.getInitialState();
    },
    setProducts: (state: EntityState<DebugCatalogProduct>, action: PayloadAction<DebugCatalogProduct[]>) => {
      productsAdapter.setAll(state, action.payload);
    },
  },
});

export const { selectAll: selectAllProducts, selectById: selectProductByProductKey } =
  productsAdapter.getSelectors<RootState>((state) => state.searchAnalysisProducts);
