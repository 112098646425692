import { GetActiveOrderResult, OrderCart } from 'common';
import { FC, useEffect, useRef } from 'react';
import { CfUncontrolledMenu } from '../../../../../../../cf-ui/Menu/CfUncontrolledMenu';
import { Menus, useHeaderMenus } from '../../../hooks/useHeaderMenus';
import { CartButton } from '../CartButton/CartButton';
import { CartContent } from '../common/CartContent/CartContent';

export interface CartMenuProps {
  activeOrder: GetActiveOrderResult | undefined;
  cartData: OrderCart | undefined;
  isLoading: boolean;
  closeShoppingCart: () => void;

  onClickDelete: () => void;

  onMenuOpen?: () => void;
  onMenuClose?: () => void;
}

export const CartMenu: FC<CartMenuProps> = (props: CartMenuProps) => {
  const { activeOrder, cartData, isLoading, onClickDelete, onMenuOpen, onMenuClose } = {
    ...props,
  };

  const { openedMenu } = useHeaderMenus();
  const isOpen = openedMenu === Menus.CartMenu;
  const firstButtonRef = useRef<HTMLButtonElement>(null);
  const lastButtonRef = useRef<HTMLButtonElement>(null);
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const anchorComponent = (
    <CartButton
      buttonRef={anchorRef}
      totalQuantity={activeOrder?.TotalQuantity}
      testId='active-cart-btn'
      onClick={() => (isOpen ? onMenuClose?.() : onMenuOpen?.())}
      onKeyDown={(e) => {
        if (isOpen && e.code.toLowerCase() === 'tab') {
          if (e.shiftKey) {
            onMenuClose?.();
          } else {
            e.preventDefault();
            firstButtonRef.current?.focus();
          }
        }
      }}
    />
  );

  const handleFirstButtonKeyDown: React.KeyboardEventHandler<HTMLElement> = (event) => {
    if (event.code.toLowerCase() === 'tab' && event.shiftKey) {
      onMenuClose?.();
    }
  };

  const handleLastButtonKeyDown: React.KeyboardEventHandler<HTMLElement> = (event) => {
    if (event.code.toLowerCase() === 'tab' && !event.shiftKey) {
      onMenuClose?.();
    }
  };

  useEffect(() => {
    anchorRef.current?.focus();
  }, [isOpen]);

  return (
    <CfUncontrolledMenu
      anchorElement={anchorRef.current}
      dataTestId='active-cart'
      containerSx={{ maxHeight: 'calc(65vh)', overflowY: 'auto' }}
      displayArrow={true}
      inputElement={anchorComponent}
      isOpen={isOpen}
      keepMounted
      mode='panel'
      placement='bottom-end'
      popperOffset={[0, 22]}
      popperSx={{ boxShadow: '0px 2px 8px 0px #00000026', border: '1px solid #DDE1E6' }}
      variant='round'
      width={310}
      onClose={onMenuClose}
    >
      <CartContent
        handleClickDelete={onClickDelete}
        activeOrder={activeOrder}
        cartData={cartData}
        isLoading={isLoading}
        firstButtonRef={firstButtonRef}
        onFirstButtonKeyDown={handleFirstButtonKeyDown}
        lastButtonRef={lastButtonRef}
        onLastButtonKeyDown={handleLastButtonKeyDown}
        closeShoppingCart={props.closeShoppingCart}
      />
    </CfUncontrolledMenu>
  );
};
