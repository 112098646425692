import { AxiosInstance } from 'axios';
import { ServiceResult } from '../models/api-shared.models';
import { getAxiosInstance } from './axios-instance';

abstract class ApiService {
  protected readonly axiosInstance: AxiosInstance;
  protected readonly baseUrl: string;
  protected readonly persistUrl: string;

  protected constructor(serviceName: string, displayErrors = true) {
    this.baseUrl = this.configureBaseUrl(serviceName, process.env.REACT_APP_API_BASE_URL);
    this.persistUrl =
      (process.env.REACT_APP_API_PERSIST_URL?.length ?? 0) > 0
        ? this.configureBaseUrl(serviceName, process.env.REACT_APP_API_PERSIST_URL)
        : this.baseUrl;
    this.axiosInstance = getAxiosInstance(displayErrors);
  }

  public get<T>(url: string, params?: unknown, signal?: AbortSignal): ServiceResult<T> {
    return this.axiosInstance.get(url, { params, signal });
  }

  public post<T>(
    url: string,
    body: unknown,
    requestType: 'body' | 'queryParams' = 'body',
    signal?: AbortSignal
  ): ServiceResult<T> {
    return requestType === 'body'
      ? this.axiosInstance.post(url, body, { signal })
      : this.axiosInstance.post(url, {}, { params: body, signal });
  }

  public put<T>(
    url: string,
    body: unknown,
    requestType: 'body' | 'queryParams' = 'body',
    signal?: AbortSignal
  ): ServiceResult<T> {
    return requestType === 'body'
      ? this.axiosInstance.put(url, body, { signal })
      : this.axiosInstance.put(url, {}, { params: body, signal });
  }

  public delete<T>(url: string): ServiceResult<T> {
    return this.axiosInstance.delete(url);
  }

  public configureBaseUrl(serviceName: string, apiBaseUrl?: string): string {
    let baseUrl = '';
    // [TODO]: Specific environments made need separate configuration
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const apiVersion = process.env.REACT_APP_API_VERSION;

    const isTestEnvironment = process.env.NODE_ENV === 'test';
    if (isTestEnvironment) {
      baseUrl = serviceName;
    } else {
      baseUrl = apiBaseUrl?.includes('localhost:3001')
        ? `${apiBaseUrl}`
        : `${apiBaseUrl}/${serviceName}/V${apiVersion}`;
    }

    return baseUrl;
  }
}

export default ApiService;
