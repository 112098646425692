import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { createCachedSelector, LruObjectCache } from 're-reselect';
import { GetProductListHeadersRequest, ProductListHeaderCustom, UpdateCustomProductRequest } from '../../api';
import { UnitOfMeasureOrderQuantity } from '../../api/models/api-shared.models';
import {
  CustomerProductPriceRequest,
  CustomerProductPriceResult,
} from '../../api/models/customer-product-price.models';
import {
  ProductListCategoryResult,
  ProductListOrderEntrySearchRequest,
} from '../../api/models/order-entry-search.models';
import { ProductDetailTransfer } from '../../api/models/product-detail.models';
import { ProductListProduct, ProductListSortOption } from '../../api/models/product-list-search.models';
import { normalizeProductKey, updateProductCustomAttributesFromPayload } from '../../helpers/general/product';
import { getNormalizedProductListGridData } from '../../helpers/general/product-list';
import {
  ProductListCategoryCustom,
  ProductListCategoryProduct,
  ProductListGridData,
} from '../../models/product-list.models';
import { createPagedEntityAdapter, EntityPage, EntityPageLoading, PagedEntityState } from '../paged-entity-adapter';
import { RootState } from '../store';

// Adapters
const categoriesAdapter = createEntityAdapter<ProductListCategoryCustom>({
  selectId: (category: ProductListCategoryCustom) => category.Id,
});

const categoryProductsAdapter = createPagedEntityAdapter<ProductListCategoryProduct>({
  selectId: (categoryProduct: ProductListCategoryProduct) => categoryProduct.Id,
});

const productsAdapter = createEntityAdapter<ProductListProduct>({
  selectId: (product: ProductListProduct) => normalizeProductKey(product.ProductKey),
});

// State
export interface OrderEntryState {
  apiRequest?: ProductListOrderEntrySearchRequest;
  productSearchResult?: ProductListCategoryResult;

  categories: EntityState<ProductListCategoryCustom>;
  categoryProducts: PagedEntityState<ProductListCategoryProduct>;
  products: EntityState<ProductListProduct>;
  getSortByOptionsProductListHeaderId?: string;
  sortByOptions: ProductListSortOption[];
  isLoadingSortByOptions: boolean;
  getProductListHeadersRequest?: GetProductListHeadersRequest;
  productListHeaderOptions: ProductListHeaderCustom[];
  isLoadingProductListHeaderOptions: boolean;
  isOrderEntryHeaderSubmitted: boolean;
  orderEntryHeaderSubmitting: boolean;
  queryText?: string;
  exitingOrderView: boolean;
}

const initialState: OrderEntryState = {
  categories: categoriesAdapter.getInitialState(),
  categoryProducts: categoryProductsAdapter.getInitialState(),
  products: productsAdapter.getInitialState(),
  getSortByOptionsProductListHeaderId: undefined,
  sortByOptions: [],
  isLoadingSortByOptions: false,
  getProductListHeadersRequest: undefined,
  productListHeaderOptions: [],
  isLoadingProductListHeaderOptions: true,
  isOrderEntryHeaderSubmitted: false,
  orderEntryHeaderSubmitting: false,
  exitingOrderView: false,
};

// Reducers
export const orderEntrySlice = createSlice({
  name: 'orderEntry',
  initialState: initialState,
  reducers: {
    resetState: () => {
      selectOrderEntryGridDataById.clearCache();
      return initialState;
    },
    setOrderEntryState: (_state: OrderEntryState, action: PayloadAction<OrderEntryState>) => {
      return action.payload;
    },
    resetSearchProductListOrderEntry: (state: OrderEntryState) => {
      state.categories = categoriesAdapter.getInitialState();
      state.categoryProducts = categoryProductsAdapter.getInitialState();
      state.products = productsAdapter.getInitialState();
      state.productSearchResult = initialState.productSearchResult;
      state.apiRequest = initialState.apiRequest;
    },
    setApiRequest: (state: OrderEntryState, action: PayloadAction<ProductListOrderEntrySearchRequest | undefined>) => {
      state.apiRequest = action.payload;
    },
    searchProductListOrderEntry: (
      state: OrderEntryState,
      action: PayloadAction<{
        request: ProductListOrderEntrySearchRequest;
        result: ProductListCategoryResult;
        previousPage?: ProductListGridData[];
      }>
    ) => {
      const { request, result, previousPage } = action.payload;
      const data = getNormalizedProductListGridData(result.ProductListCategories, false, previousPage);

      categoriesAdapter.addMany(state.categories, data.categories);
      categoryProductsAdapter.setLoadedPage(state.categoryProducts, {
        pageIndex: result.pageIndex,
        entities: data.categoryProducts,
      });
      productsAdapter.addMany(state.products, data.products);
      state.apiRequest = request;
      state.productSearchResult = result;
    },
    setPageLoaded: (
      state: OrderEntryState,
      action: PayloadAction<{ pageIndex: number; categoryProducts: ProductListCategoryProduct[] }>
    ) => {
      categoryProductsAdapter.setLoadedPage(state.categoryProducts, {
        pageIndex: action.payload.pageIndex,
        entities: action.payload.categoryProducts,
      });
    },
    setPageLoading: (state: OrderEntryState, action: PayloadAction<EntityPageLoading>) => {
      categoryProductsAdapter.setLoadingPage(state.categoryProducts, action.payload);
    },
    resetProductListHeaderOptions: (state: OrderEntryState) => {
      state.getProductListHeadersRequest = initialState.getProductListHeadersRequest;
      state.productListHeaderOptions = initialState.productListHeaderOptions;
    },
    setProductListHeaderOptions: (
      state: OrderEntryState,
      action: PayloadAction<{ request: GetProductListHeadersRequest; result: ProductListHeaderCustom[] }>
    ) => {
      state.getProductListHeadersRequest = action.payload.request;
      state.productListHeaderOptions = action.payload.result ?? [];
    },
    setIsLoadingProductListHeaderOptions: (state: OrderEntryState, action: PayloadAction<boolean>) => {
      state.isLoadingProductListHeaderOptions = action.payload;
    },
    resetSortByOptions: (state: OrderEntryState) => {
      state.getSortByOptionsProductListHeaderId = initialState.getSortByOptionsProductListHeaderId;
      state.sortByOptions = initialState.sortByOptions;
    },
    setSortByOptions: (
      state: OrderEntryState,
      action: PayloadAction<{ productListHeaderId: string; sortByOptions: ProductListSortOption[] }>
    ) => {
      state.getSortByOptionsProductListHeaderId = action.payload.productListHeaderId;
      state.sortByOptions = action.payload.sortByOptions ?? [];
    },
    setIsOrderEntryHeaderSubmitted: (state: OrderEntryState, action: PayloadAction<boolean>) => {
      state.isOrderEntryHeaderSubmitted = action.payload;
    },
    setOrderEntryHeaderSubmitting: (state: OrderEntryState, action: PayloadAction<boolean>) => {
      state.orderEntryHeaderSubmitting = action.payload;
    },
    setExitingOrderView: (state: OrderEntryState, action: PayloadAction<boolean>) => {
      state.exitingOrderView = action.payload;
    },
    setProductPrices: (state: OrderEntryState, action: PayloadAction<CustomerProductPriceResult[]>) => {
      action.payload.forEach((priceResult: CustomerProductPriceResult) => {
        if (priceResult.ProductKey) {
          const product = state.products.entities[normalizeProductKey(priceResult.ProductKey)];
          if (product) {
            product.PriceLoaded = true;
            product.UnitOfMeasureOrderQuantities.forEach((uom: UnitOfMeasureOrderQuantity) => {
              if (uom.UnitOfMeasure === priceResult.UnitOfMeasureType) {
                uom.Price = priceResult.Price;
                if (priceResult.ProductAverageWeight > 0) {
                  uom.ProductAverageWeight = priceResult.ProductAverageWeight;
                }
              }
            });
          }
        }
      });
    },
    setProductPricesReload: (state: OrderEntryState, action: PayloadAction<CustomerProductPriceRequest[]>) => {
      action.payload.forEach((priceResult: CustomerProductPriceRequest) => {
        if (priceResult.ProductKey) {
          const product = state.products.entities[normalizeProductKey(priceResult.ProductKey)];
          if (product) {
            product.PriceLoaded = false;
          }
        }
      });
    },
    setIsLoadingSortByOptions: (state: OrderEntryState, action: PayloadAction<boolean>) => {
      state.isLoadingSortByOptions = action.payload;
    },
    updateProduct: (state: OrderEntryState, action: PayloadAction<ProductDetailTransfer>) => {
      const product = state.products.entities[normalizeProductKey(action.payload.productKey)];
      if (product) {
        product.UnitOfMeasureOrderQuantities.forEach((uom: UnitOfMeasureOrderQuantity) => {
          if (uom.UnitOfMeasure === action.payload.uom) {
            uom.ExtendedPrice = action.payload.extendedPrice;
            uom.Quantity = action.payload.quantity;
            uom.ShowOrderQuantityAlert = action.payload.showOrderQuantityAlert;
          }
        });
      }
    },
    updateCategoryIsCollapsed: (
      state: OrderEntryState,
      action: PayloadAction<{ isCollapsed: boolean; categoryId: string }>
    ) => {
      const category = state.categories.entities[action.payload.categoryId];
      if (category) category.IsCollapsed = action.payload.isCollapsed;
    },
    updateAllCategoryIsCollapsed: (state: OrderEntryState, action: PayloadAction<boolean>) => {
      const categories = Object.values(state.categories.entities);
      categories.map((c) => {
        if (c) c.IsCollapsed = action.payload;
        return c;
      });
    },
    updateLastCategoryIsCollapsed: (state: OrderEntryState, action: PayloadAction<boolean>) => {
      const categories = selectAllOrderEntryCategories(state.categories);
      if (categories.length === 0) return;
      const lastCategory = categories[categories.length - 1];

      const category = state.categories.entities[lastCategory.Id];
      if (category && lastCategory) category.IsCollapsed = action.payload;
    },
    updateProductCustomAttributes: (state: OrderEntryState, action: PayloadAction<UpdateCustomProductRequest>) => {
      const product = state.products.entities[normalizeProductKey(action.payload.ProductKey)];
      if (!product) return;

      updateProductCustomAttributesFromPayload(product, action.payload);
    },
  },
});

// Selectors
export const { selectAll: selectAllOrderEntryCategories, selectById: selectOrderEntryCategoryById } =
  categoriesAdapter.getSelectors<EntityState<ProductListCategoryCustom>>(
    (state: EntityState<ProductListCategoryCustom>) => state
  );

export const {
  selectAll: selectAllOrderEntryCatalogProducts,
  selectById: selectOrderEntryCatalogProductById,
  selectIsLoading: selectOrderEntryProductListIsLoading,
  selectByPage: selectOrderEntryCatalogProductsByPage,
} = categoryProductsAdapter.getSelectors<PagedEntityState<ProductListCategoryProduct>>(
  (state: PagedEntityState<ProductListCategoryProduct>) => state
);

export const { selectAll: selectAllOrderEntryProducts, selectById: selectOrderEntryProductById } =
  productsAdapter.getSelectors<EntityState<ProductListProduct>>((state: EntityState<ProductListProduct>) => state);

const getState = (state: RootState): RootState => state;
const getIndex = (state: RootState, index: number): number => index;

export const selectOrderEntryProductsByPage = createSelector([getState, getIndex], (state, pageIndex) => {
  const categoryProducts = selectOrderEntryCatalogProductsByPage(state.orderEntry.categoryProducts, pageIndex);
  const products = [] as ProductListProduct[];
  categoryProducts.forEach((cp) => {
    const product = selectOrderEntryProductById(state.orderEntry.products, cp.ProductKey);
    if (product) products.push(product);
  });
  return products;
});

export const selectOrderEntryProductListGridDataByPage = createSelector([getState, getIndex], (state, index) => {
  const result: ProductListGridData[] = [];
  const categoryProducts = selectOrderEntryCatalogProductsByPage(state.orderEntry.categoryProducts, index);
  categoryProducts.forEach((cp) => {
    if (cp) {
      const data = selectOrderEntryGridDataById(state, cp.Id);
      if (data) result.push(data);
    }
  });
  return result;
});

export const selectAllOrderEntryCategoriesCollapsed = createSelector(
  (state: RootState) => state.orderEntry.categories,
  (state) => {
    const categories = selectAllOrderEntryCategories(state);
    if (categories.length === 0) return false;
    return categories.filter((c) => !c.IsCollapsed).length === 0;
  }
);

export const selectOrderEntryGridDataById = createCachedSelector(
  (state: RootState) => state.orderEntry.categories,
  (state: RootState) => state.orderEntry.categoryProducts,
  (state: RootState) => state.orderEntry.products,
  (_state: RootState, id: string) => id,
  (categories, categoryProducts, products, id) => {
    const category = selectOrderEntryCategoryById(categories, id);
    if (category) return { category: category, categoryProduct: undefined, product: undefined } as ProductListGridData;

    const categoryProduct = selectOrderEntryCatalogProductById(categoryProducts, id);
    if (categoryProduct) {
      const category = selectOrderEntryCategoryById(categories, categoryProduct.CategoryId);
      const product = selectOrderEntryProductById(products, normalizeProductKey(categoryProduct.ProductKey));
      if (product) return { category, categoryProduct, product } as ProductListGridData;
    }
    return undefined;
  }
)({
  keySelector: (_state: RootState, categoryProductId: string) => categoryProductId,
  cacheObject: new LruObjectCache({ cacheSize: 100 }),
});

export const selectAllOrderEntryIds = createSelector(
  (state: RootState) => state.orderEntry.categories,
  (state: RootState) => state.orderEntry.categoryProducts,
  (state: RootState) => state.user.userSettingPreference?.IsCardView,
  (
    categories,
    categoryProducts,
    isCardView
  ): {
    [pageIndex: number]: EntityPage;
  } => {
    if (isCardView) return categoryProducts.pages;
    const categorySet = new Set<string>();

    const pages: {
      [pageIndex: number]: EntityPage;
    } = {};

    for (let i = 0; i <= categoryProducts.totalPageCount; i++) {
      const page = categoryProducts.pages?.[i];
      if (!page) continue; // Loading pages are not included in the `totalPageCount`. Check for loading page

      if (page.isLoading) {
        pages[i] = { ...page };
      } else {
        const ids: string[] = [];
        page.ids.forEach((id) => {
          const categoryProduct = selectOrderEntryCatalogProductById(categoryProducts, id);
          if (!categoryProduct) return;
          const category = selectOrderEntryCategoryById(categories, categoryProduct?.CategoryId);
          if (!category) return;

          if (isCardView || !category.IsCollapsed) {
            ids.push(id);
          } else if (category.IsCollapsed && !categorySet.has(category.Id)) {
            ids.push(category.Id);
            categorySet.add(category.Id);
          }
        });
        pages[i] = { ...page, ids };
      }
    }
    return pages;
  }
);

export const selectOrderEntryProductsMissingExtendedPrice = createSelector(
  (state: RootState) => selectAllOrderEntryProducts(state.orderEntry.products),
  (products) =>
    products.flatMap((p) =>
      p.UnitOfMeasureOrderQuantities.map((uom) => ({ product: p, uom })).filter(
        (data) => data.uom.Quantity && !data.uom.ExtendedPrice
      )
    )
);

export const isOrderEntryProductsPagePricesLoaded = createSelector(
  (state: RootState) => selectAllOrderEntryProducts(state.orderEntry.products),
  (products) => products.filter((p) => p.PriceLoaded).length > 0
);
