import { CustomerSupportType } from '../../../api/models/customer-support.enums';
import { AppRoutes, CustomerSupportTutorialItem, RouteName } from '../../../models';
import { CustomerSupportPageLookup } from '../CustomerSupportPageLookup';

export interface TutorialBreadCrumbItem {
  routeName: string;
  displayName: string;
}

export interface CustomerSupportRouteItems {
  title: string | undefined;
  tutorialItems: CustomerSupportTutorialItem[] | undefined;
  customerSupportType: CustomerSupportType | undefined;
  breadcrumbItem: TutorialBreadCrumbItem[];
}

export const getCustomerSupportRouteItems = (url: string): CustomerSupportRouteItems => {
  let title: string | undefined = undefined;
  let tutorialItems: CustomerSupportTutorialItem[] | undefined = undefined;
  let customerSupportType: CustomerSupportType | undefined = undefined;
  const breadcrumbItem: TutorialBreadCrumbItem[] = [
    {
      routeName: RouteName.CustomerSupport,
      displayName: AppRoutes[RouteName.CustomerSupport].DisplayName,
    },
  ];
  switch (true) {
    case url.includes(AppRoutes[RouteName.AccountTutorials].Path):
      title = CustomerSupportPageLookup.CustomerSupportPages[CustomerSupportType.Account].title;
      tutorialItems = CustomerSupportPageLookup.CustomerSupportPages[CustomerSupportType.Account].tutorialItems;
      customerSupportType = CustomerSupportType.Account;
      breadcrumbItem.push({
        routeName: RouteName.AccountTutorials,
        displayName: AppRoutes[RouteName.AccountTutorials].DisplayName,
      });
      break;
    case url.includes(AppRoutes[RouteName.OrderingTutorials].Path):
      title = CustomerSupportPageLookup.CustomerSupportPages[CustomerSupportType.Ordering].title;
      tutorialItems = CustomerSupportPageLookup.CustomerSupportPages[CustomerSupportType.Ordering].tutorialItems;
      customerSupportType = CustomerSupportType.Ordering;
      breadcrumbItem.push({
        routeName: RouteName.OrderingTutorials,
        displayName: AppRoutes[RouteName.OrderingTutorials].DisplayName,
      });
      break;
    case url.includes(AppRoutes[RouteName.InvoiceTutorials].Path):
      title = CustomerSupportPageLookup.CustomerSupportPages[CustomerSupportType.Invoices].title;
      tutorialItems = CustomerSupportPageLookup.CustomerSupportPages[CustomerSupportType.Invoices].tutorialItems;
      customerSupportType = CustomerSupportType.Invoices;
      breadcrumbItem.push({
        routeName: RouteName.InvoiceTutorials,
        displayName: AppRoutes[RouteName.InvoiceTutorials].DisplayName,
      });
      break;
    case url.includes(AppRoutes[RouteName.ListManagementTutorials].Path):
      title = CustomerSupportPageLookup.CustomerSupportPages[CustomerSupportType.ListManagement].title;
      tutorialItems = CustomerSupportPageLookup.CustomerSupportPages[CustomerSupportType.ListManagement].tutorialItems;
      customerSupportType = CustomerSupportType.ListManagement;
      breadcrumbItem.push({
        routeName: RouteName.ListManagementTutorials,
        displayName: AppRoutes[RouteName.ListManagementTutorials].DisplayName,
      });
      break;
    case url.includes(AppRoutes[RouteName.ParManagementTutorials].Path):
      title = CustomerSupportPageLookup.CustomerSupportPages[CustomerSupportType.ParManagement].title;
      tutorialItems = CustomerSupportPageLookup.CustomerSupportPages[CustomerSupportType.ParManagement].tutorialItems;
      customerSupportType = CustomerSupportType.ParManagement;
      breadcrumbItem.push({
        routeName: RouteName.ParManagementTutorials,
        displayName: AppRoutes[RouteName.ParManagementTutorials].DisplayName,
      });
      break;
    default:
      break;
  }

  return {
    title: title,
    tutorialItems: tutorialItems,
    customerSupportType: customerSupportType,
    breadcrumbItem: breadcrumbItem,
  };
};
