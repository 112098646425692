import { DeliveryStatus } from '../api/models/delivery.enums';
import { Delivery } from '../api/models/delivery.models';
import { ConfirmationStatusType } from '../api/models/order-confirmation.models';
import { OrderEntryHeader } from '../api/models/order-entry-header.models';
import { OrderViewType } from '../api/models/order.enums';
import { SubmittedOrderStatus } from '../api/models/submitted-order.enums';
import { OpenOrderHeadersResult } from '../api/models/submitted-order.models';
import { OrderBasic, OrderDelivery, OrderSubmitted } from './order.models';

/* FROM */
export const getOrderBasicFromOrderEntryHeader = (data: OrderEntryHeader): OrderBasic => {
  const view =
    data.OrderStatus === 7
      ? OrderViewType.FutureOrder
      : !data.IsSubmitted
      ? OrderViewType.Unsubmitted
      : data.ConfirmationStatus === ConfirmationStatusType.Cancelled
      ? OrderViewType.Cancelled
      : undefined;

  const result: OrderBasic = {
    // Custom
    View: view,

    // Common
    BusinessUnitERPKey: data.BusinessUnitERPKey,
    CustomerAddress: data.CustomerAddress,
    CustomerId: data.CustomerId,
    CustomerName: data.CustomerName,
    CustomerNumber: data.CustomerNumber,
    DeliveryDate: data.DeliveryDate,
    OrderedByDisplay: data.OrderByUserDisplay, // renamed
    OperationCompanyName: data.OperationCompanyName,
    TotalLines: data.TotalLines,
    TotalOrderQuantity: data.TotalQuantity, // renamed

    // OrderEntryHeader specific
    BusinessUnitKey: data.BusinessUnitKey,
    CanDeleteOrder: data.CanDeleteOrder,
    CanViewOrderCutOff: data.CanViewOrderCutOff,
    ConfirmationOrderNumber: data.ConfirmationOrderNumber,
    ConfirmationStatus: data.ConfirmationStatus,
    CustomerCity: data.CustomerCity,
    CustomerState: data.CustomerState,
    CustomerZip: data.CustomerZip,
    CutoffDateTime: data.CutoffDateTime,
    IsSubmitted: data.IsSubmitted,
    MinimumOrderAmount: data.MinimumOrderAmount,
    OrderEntryHeaderId: data.OrderEntryHeaderId,
    OrderEntryHeaderName: data.OrderEntryHeaderName,
    PurchaseOrderNumber: data.PurchaseOrderNumber,
    SubmittedDate: data.SubmittedDate,
    Surcharges: data.Surcharges,
    TotalExtendedItemPrice: data.TotalExtendedItemPrice,
    TotalOrderPrice: data.TotalOrderPrice,
  };

  return result;
};

export const getOrderDeliveryFromDelivery = (data: Delivery): OrderDelivery => {
  const view =
    data.DeliveryStatus === DeliveryStatus.Delivered
      ? OrderViewType.Delivered
      : data.DeliveryStatus === DeliveryStatus.OutForDelivery
      ? OrderViewType.OutForDelivery
      : data.DeliveryStatus === DeliveryStatus.ReadyForPickup
      ? OrderViewType.ReadyForPickup
      : undefined;

  const result: OrderDelivery = {
    // Custom
    View: view,

    // Common
    BusinessUnitERPKey: data.DeliveryBusinessUnitERPKey, // renamed
    CustomerAddress: data.CustomerAddress,
    CustomerId: data.CustomerId,
    CustomerName: data.CustomerName,
    CustomerNumber: data.CustomerNumber,
    DeliveryDate: data.DeliveryDate,
    OrderedByDisplay: data.OrderedByDisplay,
    OperationCompanyName: data.OperationCompanyName,
    TotalLines: data.TotalLines,
    TotalOrderQuantity: data.TotalOrderQuantity,

    // Delivery specific
    CustomerBusinessUnitKey: data.CustomerBusinessUnitKey,
    CustomerLocation: data.CustomerLocation,
    DeliveryKey: data.DeliveryKey,
    DeliveryOperationCompanyNumber: data.DeliveryOperationCompanyNumber,
    DeliveryStatus: data.DeliveryStatus,
    DeliveryTimeDisplay: data.DeliveryTimeDisplay,
    HasMultipleShipments: data.HasMultipleShipments,
    Invoices: data.Invoices,
    PurchaseOrderNumbers: data.PurchaseOrderNumbers,
    ShippedDate: data.ShippedDate,
    ShippingTrackings: data.ShippingTrackings,
    TotalDollars: data.TotalDollars,
    TotalShippedQuantity: data.TotalShippedQuantity,
    IsWillCall: data.IsWillCall,
  };

  return result;
};

export const getOrderSubmittedFromOpenOrderHeader = (data: OpenOrderHeadersResult): OrderSubmitted => {
  const view =
    data.OrderStatus === SubmittedOrderStatus.PreparingDelivery
      ? OrderViewType.PreparingOrder
      : data.OrderStatus === SubmittedOrderStatus.Open
      ? OrderViewType.Submitted
      : undefined;

  const result: OrderSubmitted = {
    // Custom
    View: view,

    // Common
    BusinessUnitERPKey: data.OrderBusinessUnitERPKey,
    CustomerAddress: data.DeliverToAddress,
    CustomerId: data.CustomerId,
    CustomerName: data.DeliverToCustomerName, // renamed
    CustomerNumber: data.DeliverToCustomerNumber, // renamed
    DeliveryDate: data.DeliveryDate,
    OrderedByDisplay: data.OrderedByDisplay,
    OperationCompanyName: data.DeliverToOperationCompanyName, // renamed
    TotalLines: data.OrderTotalLines, // renamed
    TotalOrderQuantity: data.OrderTotalQuantity, // renamed

    // OpenOrderHeadersResult specific
    BillToAddress: data.BillToAddress,
    BillToCity: data.BillToCity,
    BillToCustomerName: data.BillToCustomerName,
    BillToCustomerNumber: data.BillToCustomerNumber,
    BillToOperationCompanyName: data.BillToOperationCompanyName,
    BillToState: data.BillToState,
    BillToZip: data.BillToZip,
    CanEditOrder: data.CanEditOrder,
    DeliverToCity: data.DeliverToCity,
    DeliverToState: data.DeliverToState,
    DeliverToZip: data.DeliverToZip,
    HasMultipleShipments: data.HasMultipleShipments,
    OrderEntryHeaderId: data.OrderEntryHeaderId,
    OrderKey: data.OrderKey,
    OrderNumber: data.OrderNumber,
    OrderOperationCompanyNumber: data.OrderOperationCompanyNumber,
    OrderStatus: data.OrderStatus,
    OrderTotalSales: data.OrderTotalSales,
    PurchaseOrderNumber: data.PurchaseOrderNumber,
    IsWillCall: data.IsWillCall,
    IsDummySubmittedOrder: data.IsDummySubmittedOrder,
  };

  return result;
};

/* TO */
export const getOrderEntryHeaderFromOrderBasic = (data: OrderBasic): OrderEntryHeader => {
  const result: OrderEntryHeader = {
    BusinessUnitKey: data.BusinessUnitKey,
    BusinessUnitERPKey: data.BusinessUnitERPKey,
    OrderEntryHeaderId: data.OrderEntryHeaderId,
    CustomerId: data.CustomerId,
    DeliveryDate: data.DeliveryDate,
    PurchaseOrderNumber: data.PurchaseOrderNumber,
    TotalQuantity: data.TotalOrderQuantity,
    TotalLines: data.TotalLines,
    TotalExtendedItemPrice: data.TotalExtendedItemPrice,
    Surcharges: data.Surcharges,
    TotalOrderPrice: data.TotalOrderPrice,
    OrderEntryHeaderName: data.OrderEntryHeaderName,
    ConfirmationOrderNumber: data.ConfirmationOrderNumber,
    CutoffDateTime: data.CutoffDateTime,
    MinimumOrderAmount: data.MinimumOrderAmount,
    IsSubmitted: data.IsSubmitted,
    SubmittedDate: data.SubmittedDate,
    OrderByUserDisplay: data.OrderedByDisplay,
    CustomerName: data.CustomerName,
    CustomerNumber: data.CustomerNumber,
    CustomerAddress: data.CustomerAddress,
    CustomerCity: data.CustomerCity,
    CustomerState: data.CustomerState,
    CustomerZip: data.CustomerZip,
    OperationCompanyName: data.OperationCompanyName,
    ConfirmationStatus: data.ConfirmationStatus,
    CanDeleteOrder: data.CanDeleteOrder,
    CanViewOrderCutOff: data.CanViewOrderCutOff,
    OrderStatus: data.View,
  };

  return result;
};

export const getDeliveryFromOrderDelivery = (data: OrderDelivery): Delivery => {
  const result: Delivery = {
    DeliveryBusinessUnitERPKey: data.BusinessUnitERPKey,
    DeliveryKey: data.DeliveryKey,
    DeliveryOperationCompanyNumber: data.DeliveryOperationCompanyNumber,
    CustomerBusinessUnitKey: data.CustomerBusinessUnitKey,
    CustomerId: data.CustomerId,
    CustomerName: data.CustomerName ?? '',
    CustomerAddress: data.CustomerAddress ?? '',
    CustomerLocation: data.CustomerLocation,
    CustomerNumber: data.CustomerNumber ?? '',
    OperationCompanyName: data.OperationCompanyName ?? '',
    ShippedDate: data.ShippedDate,
    DeliveryDate: data.DeliveryDate,
    DeliveryTimeDisplay: data.DeliveryTimeDisplay,
    DeliveryStatus: data.DeliveryStatus,
    HasMultipleShipments: data.HasMultipleShipments,
    TotalOrderQuantity: data.TotalOrderQuantity,
    TotalShippedQuantity: data.TotalShippedQuantity,
    TotalLines: data.TotalLines,
    TotalDollars: data.TotalDollars,
    Invoices: data.Invoices,
    PurchaseOrderNumbers: data.PurchaseOrderNumbers,
    ShippingTrackings: data.ShippingTrackings,
    IsWillCall: data.IsWillCall,
    OrderedByDisplay: data.OrderedByDisplay,
  };

  return result;
};

export const getOpenOrderHeaderFromOrderSubmitted = (data: OrderSubmitted): OpenOrderHeadersResult => {
  const result: OpenOrderHeadersResult = {
    CustomerId: data.CustomerId,
    OrderOperationCompanyNumber: data.OrderOperationCompanyNumber,
    OrderBusinessUnitERPKey: data.BusinessUnitERPKey,
    OrderEntryHeaderId: data.OrderEntryHeaderId,
    OrderKey: data.OrderKey,
    DeliveryDate: data.DeliveryDate,
    OrderStatus: data.OrderStatus,
    OrderNumber: data.OrderNumber,
    PurchaseOrderNumber: data.PurchaseOrderNumber,
    OrderTotalQuantity: data.TotalOrderQuantity,
    OrderTotalLines: data.TotalLines,
    OrderTotalSales: data.OrderTotalSales,
    CanEditOrder: data.CanEditOrder,
    HasMultipleShipments: data.HasMultipleShipments,
    DeliverToCustomerNumber: data.CustomerNumber,
    DeliverToCustomerName: data.CustomerName,
    DeliverToOperationCompanyName: data.OperationCompanyName,
    DeliverToAddress: data.CustomerAddress,
    DeliverToCity: data.DeliverToCity,
    DeliverToState: data.DeliverToState,
    DeliverToZip: data.DeliverToZip,
    BillToCustomerNumber: data.BillToCustomerNumber,
    BillToCustomerName: data.BillToCustomerName,
    BillToOperationCompanyName: data.BillToOperationCompanyName,
    BillToAddress: data.BillToAddress,
    BillToCity: data.BillToCity,
    BillToState: data.BillToState,
    BillToZip: data.BillToZip,
    OrderedByDisplay: data.OrderedByDisplay,
    IsWillCall: data.IsWillCall,
    IsDummySubmittedOrder: data.IsDummySubmittedOrder,
  };

  return result;
};
