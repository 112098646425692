import { FC, PropsWithChildren } from 'react';
import { StyledDialogContent } from '../common/Shared.styles';
import { CfDialog, CfDialogProps } from '../Dialog/CfDialog';

export interface CfSimpleDialogProps extends CfDialogProps {
  icon?: 'alert';
}

/**
 * The CfSimpleDialog extends the {@link CfDialog} component.
 * This component's primary use is to display simple strings messages within a dialog
 * with the appropriate resposive styling and padding.
 * @param {React.Node} children May be used to populate the dialog with messages.
 * @param icon - Indicated if the warning icon ('fa-exclamation-triangle') should be displayed
 */

export const CfSimpleDialog: FC<PropsWithChildren<CfSimpleDialogProps>> = (
  props: PropsWithChildren<CfSimpleDialogProps>
) => {
  return (
    <CfDialog
      iconProps={
        props.icon
          ? { className: 'fa-exclamation-triangle', sx: { color: (theme) => theme.palette.error.main } }
          : undefined
      }
      {...props}
      paperSx={{ width: 446, ...props.paperSx }}
    >
      <StyledDialogContent>{props.children}</StyledDialogContent>
    </CfDialog>
  );
};
