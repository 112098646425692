import { Stack } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { Customer, UserCustomer, filterObjects } from 'common';
import { useEffect, useState } from 'react';
import { CfSearchInput, CfSearchInputVariant } from '../../../../../cf-ui/SearchInput/CfSearchInput';
import { DeviceType, useBreakpoint } from '../../../../../hooks/useBreakpoint';
import { LocationCheckboxItem } from '../LocationCheckboxList/LocationCheckboxList';

export interface LocationSearchItem {
  selected: boolean;
  disabled?: boolean;
  customerId: string;
  customerName?: string;
  customerAddress?: string;
  customerLocation?: string;
  customerZip?: string;
  customerNumber?: string;
  operationCompanyName?: string;
  creditTermDisplay?: string;
  actionArea?: React.ReactNode;
}

export interface LocationSearchProps {
  items: LocationSearchItem[];
  searchActionArea?: React.ReactNode;
  sx?: SxProps<Theme>;
  greyBackground?: boolean;
  filterVariant?: CfSearchInputVariant;
  inputDataTestId?: string;
  searchButtonDataTestId?: string;
  isAutoFocused?: boolean;
  children?: (items: LocationSearchItem[]) => React.ReactNode;
  isOpen?: boolean;
  searchInputRef?: React.RefObject<HTMLInputElement>;
  onSearchInputKeyDown?: React.KeyboardEventHandler;
}

const LocationSearch = (props: LocationSearchProps): JSX.Element => {
  const { deviceType } = useBreakpoint();
  const { items, sx, filterVariant } = props;
  const [filterText, setFilterText] = useState<string>('');

  useEffect(() => {
    if (props.isOpen) setFilterText('');
  }, [props.isOpen]);

  const getFilteredOptions = (): LocationCheckboxItem[] => {
    const filteredItems = filterObjects(
      items,
      filterText.toLowerCase().trim(),
      [
        'customerName',
        'customerAddress',
        'customerLocation',
        'customerZip',
        'customerNumber',
        'operationCompanyName',
        'creditTermDisplay',
      ],
      [
        {
          field: 'creditTermDisplay',
          type: 'custom',
          filterFunction: (val: string, queryString: string) => {
            if (!val) return false;
            return val.toLocaleLowerCase().includes(queryString);
          },
        },
      ]
    );

    const filteredCustomerIds = new Set(filteredItems.map((val: LocationSearchItem) => val.customerId));
    return items.filter((o: LocationSearchItem) => filteredCustomerIds.has(o.customerId.toLowerCase()));
  };

  const filteredItems = getFilteredOptions();
  if (props.isOpen === false) return <></>;
  return (
    <Stack spacing={{ xs: 2, lg: 1 }} sx={sx}>
      <CfSearchInput
        autoFocus={props.isAutoFocused ?? true}
        placeholder='Search locations'
        onSubmit={(text) => setFilterText(text)}
        onClear={() => setFilterText('')}
        variant={filterVariant}
        testId={props.inputDataTestId}
        searchButtonTestId={props.searchButtonDataTestId}
        size={deviceType === DeviceType.Tablet ? 'medium' : 'small'}
        isResponsive={true}
        inputRef={props.searchInputRef}
        onInputKeyDown={props.onSearchInputKeyDown}
      />
      {props.searchActionArea}
      {props.children?.(filteredItems)}
    </Stack>
  );
};

export const mapToLocationSearchItems = (options: {
  ucs: UserCustomer[];
  selectedCustomerIds: string[];
  initialSelectedCustomerId?: string[];
  availableCustomers?: Customer[];
  hideCreditTerm?: boolean;
  getActionArea?: (uc: UserCustomer) => React.ReactNode;
}): LocationSearchItem[] => {
  const filteredCustomers = options.availableCustomers
    ? options.ucs.filter((uc) => options.availableCustomers?.some((c) => c.CustomerId === uc.CustomerId))
    : options.ucs;

  const selectedCustomerIdSet = new Set(options.selectedCustomerIds);

  return filteredCustomers.map((uc: UserCustomer) => {
    return {
      disabled: options.initialSelectedCustomerId?.includes(uc.CustomerId) ?? false,
      selected: selectedCustomerIdSet.has(uc.CustomerId),
      customerId: uc.CustomerId,
      customerName: uc.CustomerName,
      customerAddress: uc.CustomerAddress,
      customerLocation: uc.CustomerLocation,
      customerZip: uc.CustomerZip,
      customerNumber: uc.CustomerNumber,
      operationCompanyName: uc.OperationCompanyName,
      creditTermDisplay: options.hideCreditTerm ? undefined : uc.CustomerCreditTermDisplay,
      actionArea: options.getActionArea?.(uc),
    };
  });
};

export default LocationSearch;
