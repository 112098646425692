import { useMsal } from '@azure/msal-react';
import { Typography } from '@mui/material';
import {
  AppRoutes,
  RouteName,
  UserActivityAction,
  UserActivityActionSummary,
  UserActivityPageName,
  logUserActivity,
  selectIsPunchoutUser,
  selectUserIsMultiUnit,
  selectUserPermissionSetting,
  useAppDispatch,
  useAppSelector,
} from 'common';
import React, { FC, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { CfMenuItem } from '../../../../../../cf-ui/Menu/MenuItem/CfMenuItem';
import { logout } from '../../../../../../utilities/auth';
import { getResolution } from '../../../../../../utilities/resolution';
import { UnreadMessagesBadge } from '../../../../../account/components/message-center/UnreadMessagesBadge';
import { Menus, setMenu, useHeaderMenus } from '../../hooks/useHeaderMenus';
import { AccountDrawer } from './AccountDrawer/AccountDrawer';
import { AccountMenu } from './AccountMenu/AccountMenu';

export interface AccountProps {
  variant: 'menu' | 'drawer';
}

export const Account: FC<AccountProps> = (props: AccountProps) => {
  const { openedMenu } = useHeaderMenus();

  const handleOpen = () => setMenu(Menus.ProfileMenu);
  const handleClose = () => setMenu(Menus.None);
  const open = openedMenu === Menus.ProfileMenu;

  const dispatch = useAppDispatch();
  const history = useHistory();
  const { instance } = useMsal();

  const userSite = useAppSelector((x) => x.user.userSite);
  const businessUnitKey = userSite?.BusinessUnitKey;

  const canManageUserAccounts = useAppSelector((s) => selectUserPermissionSetting(s).CanManageUserAccounts);
  // const canCustomerGroupAccounts = useAppSelector((s) => selectUserAreaSetting(s).IsMultiAccountEnabled);
  const isUserMultiAccount = useAppSelector((s) => selectUserIsMultiUnit(s));
  const isPunchoutUser = useAppSelector(selectIsPunchoutUser);

  const handleLogout = async () => {
    dispatch(
      logUserActivity({
        action: UserActivityAction.Logout,
        pageName: UserActivityPageName.Logout,
        actionSummary: UserActivityActionSummary.SuccessfullyLoggedOut,
        resolution: getResolution(),
        callBack: async () => logout(instance, businessUnitKey),
      })
    );
  };

  const firstItemRef = useRef<HTMLDivElement>();
  const menuItems: React.ReactNode[] = [];
  menuItems.push(
    <CfMenuItem
      key='profile'
      rootRef={firstItemRef}
      onSelect={() => history.push(AppRoutes[RouteName.UserProfile].Path)}
      dataTestId='account-menu-item-profile'
    >
      <Typography variant='responsiveParagraphBold'>Profile</Typography>
    </CfMenuItem>
  );
  menuItems.push(
    <CfMenuItem
      isDisabled={!canManageUserAccounts}
      key='manage-users'
      rootRef={firstItemRef}
      onSelect={() => history.push(AppRoutes[RouteName.ManageUsers].Path)}
      dataTestId='account-menu-item-manage-users'
    >
      <Typography variant='responsiveParagraphBold'>Manage users</Typography>
    </CfMenuItem>
  );
  menuItems.push(
    <CfMenuItem
      isDisabled={!isUserMultiAccount || isPunchoutUser}
      key='customer-groups'
      rootRef={firstItemRef}
      onSelect={() => history.push(AppRoutes[RouteName.CustomerGroups].Path)}
      dataTestId='account-menu-item-customer-groups'
    >
      <Typography variant='responsiveParagraphBold'>Customer Groups</Typography>
    </CfMenuItem>
  );
  menuItems.push(
    <CfMenuItem
      key='communications'
      onSelect={() => history.push(AppRoutes[RouteName.Communications].Path)}
      dataTestId='account-menu-item-communications'
    >
      <Typography variant='responsiveParagraphBold'>Communications</Typography>
    </CfMenuItem>
  );
  menuItems.push(
    <CfMenuItem
      key='message-center'
      onSelect={() => history.push(AppRoutes[RouteName.InboxMessages].Path)}
      dataTestId='account-menu-item-message-center'
    >
      <Typography variant='responsiveParagraphBold'>Message center</Typography>
      <UnreadMessagesBadge sx={{ ml: 'auto', mr: 1 }} />
    </CfMenuItem>
  );
  menuItems.push(
    <CfMenuItem
      key='contact-us'
      onSelect={() => history.push(AppRoutes[RouteName.CustomerSupport].Path)}
      dataTestId='account-menu-item-contact-us'
    >
      <Typography variant='responsiveParagraphBold'>Customer support</Typography>
    </CfMenuItem>
  );
  menuItems.push(
    <CfMenuItem key='log-out' onSelect={() => handleLogout()} dataTestId='account-menu-item-log-out'>
      <Typography variant='responsiveParagraphBold'>Sign out</Typography>
    </CfMenuItem>
  );

  if (props.variant === 'menu') {
    return (
      <AccountMenu isOpen={open} onOpen={handleOpen} onClose={handleClose} firstItemRef={firstItemRef}>
        {menuItems}
      </AccountMenu>
    );
  } else {
    return <AccountDrawer isOpen={open} onOpen={handleOpen} onClose={handleClose} />;
  }
};
