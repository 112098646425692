import {
  CreateCustomerGroupRequest,
  CreateCustomerGroupResult,
  CustomerGroupBasic,
  CustomerGroupRecipient,
  DeleteCustomerGroupRequest,
  GetCustomerGroupRequest,
  UpdateCustomerGroupName,
  UpdateCustomerGroupResult,
  UpdateCustomersGroupRequest,
} from '../models';
import { ServiceResult } from '../models/api-shared.models';
import ApiService from './api-service';

class CustomerGroupService extends ApiService {
  private static classInstance: CustomerGroupService;

  private constructor() {
    super('CustomerGroup');
  }

  public async getCustomerGroups(): ServiceResult<CustomerGroupBasic[]> {
    return this.get(`${this.persistUrl}/GetCustomerGroups`);
  }

  public async getCustomerGroup(request: GetCustomerGroupRequest): ServiceResult<CustomerGroupRecipient[]> {
    return this.get(`${this.persistUrl}/GetCustomersByCustomerGroup`, request);
  }

  public async createCustomerGroup(request: CreateCustomerGroupRequest): ServiceResult<CreateCustomerGroupResult> {
    return this.post(`${this.persistUrl}/CreateCustomerGroup`, request);
  }

  public async updateCustomerGroupName(request: UpdateCustomerGroupName): ServiceResult<UpdateCustomerGroupResult> {
    return this.post(`${this.persistUrl}/UpdateCustomerGroup`, request);
  }

  public async updateCustomerGroupLocations(
    request: UpdateCustomersGroupRequest
  ): ServiceResult<UpdateCustomerGroupResult> {
    return this.post(`${this.persistUrl}/UpdateCustomerGroupLocations`, request);
  }

  public deleteCustomerGroup(request: DeleteCustomerGroupRequest): ServiceResult<undefined> {
    return this.post(`${this.persistUrl}/DeleteSiteUserCustomerGroup`, request);
  }

  public getCustomerGroupsUnassignedLocations(): ServiceResult<string[]> {
    return this.get(`${this.persistUrl}/GetCustomersUnassignedToCustomerGroupsBySiteUser`);
  }

  public static getInstance(): CustomerGroupService {
    if (!this.classInstance) {
      this.classInstance = new CustomerGroupService();
    }
    return this.classInstance;
  }
}

export default CustomerGroupService;
