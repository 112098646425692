import { DebugSearchProductCatalogRequest } from '../../api/models/product-catalog.models';
import ProductSearchService from '../../api/services/product-search.service';
import { AppDispatch, AppThunk, RootState } from '../store';
import { searchAnalysisProductsSlice } from './search-analysis-products.slice';
import { searchAnalysisSlice } from './search-analysis.slice';

// hooks
const productSearchService = ProductSearchService.getInstance();

/**
 * Resets all values stores in the search analysis slice to their initial values
 *
 * @returns NULL
 */
export const resetSearchAnalysis = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(searchAnalysisSlice.actions.resetSearchAnalysisState());
  dispatch(searchAnalysisProductsSlice.actions.resetProducts());
};

/**
 * Calls and stores the results of the DebugSearchProductCatalog API call
 *
 * @returns NULL
 */
export const getSearchAnalysis = (): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    if (!getState().searchAnalysis.ProductListLoading) {
      dispatch(searchAnalysisSlice.actions.setProductListLoading(true));
    }

    const requestData = getState().searchAnalysis;

    const request: DebugSearchProductCatalogRequest = {
      BusinessUnitKey: requestData.BusinessUnitKey,
      CustomerNumber: requestData.CustomerNumber,
      OperationCompanyNumber: requestData.OperationCompanyNumber,
      QueryText: requestData.SearchText,
    };

    const { data } = await productSearchService.debugSearchProductCatalog(request);

    if (data.IsSuccess) {
      dispatch(searchAnalysisSlice.actions.setProductSearchResults(data.ResultObject));
      dispatch(searchAnalysisProductsSlice.actions.setProducts(data.ResultObject.Products));
    } else {
      dispatch(searchAnalysisProductsSlice.actions.resetProducts());
    }
  } catch (error) {
    // [TODO]: Handle state if an error occurs here
    dispatch(searchAnalysisProductsSlice.actions.resetProducts());
  } finally {
    dispatch(searchAnalysisSlice.actions.setProductListLoading(false));
  }
};

/**
 * Sets the PageIndex and PageSize values in the search analysis slice
 *
 * @param pageIndex - The number to set the PageIndex value in the search analysis slice to
 * @param pageSize - The number to set the PageSize value in the search analysis slice to
 * @returns NULL
 */
export const setSearchAnalysisGridPageParameters =
  (pageIndex: number, pageSize: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(searchAnalysisSlice.actions.setPageIndex(pageIndex));
    dispatch(searchAnalysisSlice.actions.setPageSize(pageSize));
  };
