import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Theme } from '@mui/system';
import {
  AppRoutes,
  RouteName,
  selectIsPunchoutUser,
  selectUserIsMultiUnit,
  selectUserPermissionSetting,
  useAppSelector,
} from 'common';
import { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { CfDropdown } from '../../../../cf-ui/Dropdown/CfDropdown';
import { DeviceType, useBreakpoint } from '../../../../hooks/useBreakpoint';
import { useRouteName } from '../../../../hooks/useRouteName';
import { Menus, setMenu } from '../../../site/components/Header/hooks/useHeaderMenus';
import { UnreadMessagesBadge } from '../message-center/UnreadMessagesBadge';
import { StyledMenuHeaderWrapper, StyledUserProfileMenuItemWrapper } from './AccountSideBar.styles';

export type MenuHeader =
  | {
      title: string;
      options: MenuOption[];
      disabled?: boolean;
    }
  | {
      title: string;
      routeName: RouteName;
      disabled?: boolean;
      options?: never;
    };

export type MenuOption = {
  title: string;
  suffix?: ReactNode;
  routeName: RouteName;
  relatedRoutes: RouteName[];
  disabled?: boolean;
};

const AccountSideBar = (): JSX.Element => {
  const theme = useTheme();
  const history = useHistory();
  const deviceType = useBreakpoint().deviceType;
  const routeName = useRouteName();

  const canSendMessages = useAppSelector((s) => selectUserPermissionSetting(s).CanSendMessages);
  const canManageUserAccounts = useAppSelector((s) => selectUserPermissionSetting(s).CanManageUserAccounts);
  // const canCustomerGroupAccounts = useAppSelector((s) => selectUserAreaSetting(s).IsMultiAccountEnabled);
  const isUserMultiAccount = useAppSelector((s) => selectUserIsMultiUnit(s));
  const isPunchoutUser = useAppSelector(selectIsPunchoutUser);

  const menuHeaders: MenuHeader[] = [
    {
      title: 'Profile',
      routeName: RouteName.UserProfile,
    },
    {
      title: 'Manage users',
      routeName: RouteName.ManageUsers,
      disabled: !canManageUserAccounts,
    },
    {
      title: 'Customer groups',
      routeName: RouteName.CustomerGroups,
      disabled: !isUserMultiAccount || isPunchoutUser,
    },
    {
      title: 'Communications',
      routeName: RouteName.Communications,
    },
    {
      title: 'Message center',
      options: [
        {
          title: 'Inbox',
          routeName: RouteName.InboxMessages,
          relatedRoutes: [RouteName.InboxMessages, RouteName.ViewInboxMessage],
          suffix: <UnreadMessagesBadge sx={{ ml: 'auto', mr: 2 }} />,
        },
        ...(deviceType !== DeviceType.Mobile && canSendMessages
          ? [
              {
                title: 'Draft',
                routeName: RouteName.DraftMessages,
                relatedRoutes: [RouteName.DraftMessages, RouteName.ViewDraftMessage],
              },
            ]
          : []),
        ...(canSendMessages
          ? [
              {
                title: 'Sent',
                routeName: RouteName.SentMessages,
                relatedRoutes: [RouteName.SentMessages, RouteName.ViewSentMessage],
              },
            ]
          : []),
        ...(deviceType !== DeviceType.Mobile && canSendMessages
          ? [
              {
                title: 'Message groups',
                routeName: RouteName.MessageGroups,
                relatedRoutes: [RouteName.MessageGroups, RouteName.ViewMessageGroup, RouteName.CreateMessageGroup],
              },
            ]
          : []),
      ],
    },
    { title: 'Customer support', routeName: RouteName.CustomerSupport },
  ];

  const handleMenuHeaderClick = (itemRouteName: RouteName) => {
    if (itemRouteName !== routeName) {
      history.push(AppRoutes[itemRouteName].Path);
      setMenu(Menus.None);
    }
  };

  const handleMenuOptionClick = (option: MenuOption) => {
    if (!option.disabled && option.routeName !== routeName) {
      history.push(AppRoutes[option.routeName].Path);
      setMenu(Menus.None);
    }
  };

  if (!routeName) return <></>;
  return (
    <Stack
      direction='column'
      sx={{
        width: '100%',
      }}
    >
      {menuHeaders.map((header: MenuHeader, index: number) => {
        return 'routeName' in header ? (
          <StyledMenuHeaderWrapper
            key={index}
            header={header}
            isSelected={header.routeName === routeName}
            onClick={() => handleMenuHeaderClick(header.routeName)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleMenuHeaderClick(header.routeName);
              }
            }}
            sx={{
              marginBottom: theme.spacing(2),
              ...(!header.disabled && {
                pointerEvents: 'auto',
                '&:hover': {
                  backgroundColor: theme.palette.coolGrey[100],
                },
              }),
              ...(header.disabled && { pointerEvents: 'none' }),
            }}
            tabIndex={0}
          >
            <Typography variant='h4' data-testid={`menu-header-${header.title}`}>
              {header.title}
            </Typography>
          </StyledMenuHeaderWrapper>
        ) : (
          <StyledMenuHeaderWrapper
            key={index}
            header={header}
            isSelected={false}
            sx={{
              marginBottom: theme.spacing(1),
            }}
          >
            <CfDropdown
              variant='header'
              title={header.title}
              disabled={header.disabled}
              hideCaret={header.options?.length === 0}
              caretSize='small'
              caretSx={{ color: (theme: Theme) => theme.palette.coolGrey[700] }}
              titleSx={{
                ...theme.typography.h4,
                ...(header.disabled ? { color: theme.palette.coolGrey[300] } : {}),
                textTransform: 'none',
              }}
              childrenBoxSx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {header.options.map((option: MenuOption, optionIndex: number) => {
                return (
                  <StyledUserProfileMenuItemWrapper
                    key={optionIndex}
                    option={option}
                    isSelected={option.relatedRoutes.includes(routeName)}
                    onClick={() => handleMenuOptionClick(option)}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        handleMenuOptionClick(option);
                      }
                    }}
                    tabIndex={0}
                  >
                    <Typography variant='responsiveParagraph' data-testid={`header-option-${option.title}`}>
                      {option.title}
                    </Typography>
                    {option.suffix}
                  </StyledUserProfileMenuItemWrapper>
                );
              })}
            </CfDropdown>
          </StyledMenuHeaderWrapper>
        );
      })}
    </Stack>
  );
};

export default AccountSideBar;
