import { Box, Card, Stack, Theme } from '@mui/material';
import {
  AppRoutes,
  OverviewSlideGroups,
  RootState,
  RouteName,
  UserActivityAction,
  UserActivityActionSummary,
  UserActivityPageName,
  UserCustomer,
  getCurrentUser,
  getOrderConfirmationNotifications,
  getOverviewViewStatus,
  logUserActivity,
  updateSelectedCustomer,
  useAppDispatch,
  useAppSelector,
} from 'common';
import { useEffect, useState } from 'react';
import { CfBreadcrumbs } from '../../../cf-ui/Breadcrumbs/CfBreadcrumbs';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { getResolution } from '../../../utilities/resolution';
import { PageContentContainer, getPaneBorderStyle } from '../../_shared/components/shared-styled-components.styles';
import AccountSideBar from '../components/AccountSideBar/AccountSideBar';
import AccountSideBarDrawer from '../components/AccountSideBarDrawer/AccountSideBarDrawer';
import CommunicationsPanel from '../components/communications/CommunicationsPanel';

const Communications = (): JSX.Element => {
  const { breakpoint } = useBreakpoint();
  const dispatch = useAppDispatch();
  const [selectedCustomer, setSelectedCustomer] = useState<UserCustomer | undefined>(undefined);
  // Get communications customers
  const initialCustomer = useAppSelector((s: RootState) => s.customer.selectedCustomer);

  useEffect(() => {
    setSelectedCustomer(initialCustomer);
  }, [initialCustomer]);

  useEffect(() => {
    if (selectedCustomer) {
      dispatch(getOrderConfirmationNotifications(selectedCustomer?.CustomerId));
      dispatch(getCurrentUser());
    }
  }, [selectedCustomer]);

  useEffect(() => {
    dispatch(getOverviewViewStatus(OverviewSlideGroups.CommunicationsPreferences));

    if (initialCustomer) {
      dispatch(
        logUserActivity({
          action: UserActivityAction.Visit,
          pageName: UserActivityPageName.CommunicationPreferences,
          actionSummary: UserActivityActionSummary.VisitedCommunicationPreferences,
          resolution: getResolution(),
          customerId: initialCustomer.CustomerId,
        })
      );
    }
  }, []);

  const handleCustomerChange = (customer?: UserCustomer) => {
    if (customer) {
      dispatch(updateSelectedCustomer(customer, { updateLocationAllSetting: true }));
      setSelectedCustomer(customer);
    }
  };

  return (
    <Box>
      <CfBreadcrumbs
        breadcrumbs={[
          {
            routeName: RouteName.Communications,
            displayName: AppRoutes[RouteName.Communications].DisplayName,
          },
        ]}
      />
      {breakpoint === 'xl' ? (
        <PageContentContainer sx={{ p: { xs: 0 }, height: (theme) => ({ lg: `calc(100vh - ${theme.spacing(25)})` }) }}>
          <Card sx={{ height: '100%' }}>
            <Stack direction='row' sx={{ height: '100%' }}>
              <Box
                sx={{
                  width: (theme) => theme.spacing(32),
                  p: 2,
                  borderRight: getPaneBorderStyle,
                  overflowY: 'auto',
                }}
              >
                <AccountSideBar />
              </Box>
              <Box flexGrow={1} sx={{ overflowY: { xs: 'auto' } }}>
                {selectedCustomer && (
                  <CommunicationsPanel selectedCustomer={selectedCustomer} onCustomerChange={handleCustomerChange} />
                )}
              </Box>
            </Stack>
          </Card>
        </PageContentContainer>
      ) : (
        <>
          {breakpoint === 'lg' ? (
            <Stack
              direction='row'
              sx={{
                alignContent: 'stretch',
                alignItems: 'flex-start',
                height: (theme) => `calc(100vh - ${theme.spacing(32.5)})`,
              }}
            >
              <Card
                sx={{
                  minWidth: (theme) => theme.spacing(25.5),
                  p: 1,
                  pt: 1.5,
                  height: (theme) => `calc(100vh - ${theme.spacing(32.5)})`,
                  borderRadius: 2,
                  overflowY: 'auto',
                }}
              >
                <AccountSideBar />
              </Card>
              <Box sx={{ color: (theme: Theme) => theme.palette.coolGrey[100], width: (theme) => theme.spacing(2) }} />
              <Card
                sx={{
                  minWidth: (theme) => theme.spacing(59),
                  flex: 1,
                  p: 2,
                  height: (theme) => `calc(100vh - ${theme.spacing(32.5)})`,
                  overflowY: 'auto',
                  borderRadius: 2,
                }}
              >
                <Box flexGrow={1}>
                  {selectedCustomer && (
                    <CommunicationsPanel selectedCustomer={selectedCustomer} onCustomerChange={handleCustomerChange} />
                  )}
                </Box>
              </Card>
            </Stack>
          ) : (
            //mobile view
            <PageContentContainer sx={{ p: { xs: 0 } }}>
              <Box flexGrow={1}>
                {selectedCustomer && (
                  <CommunicationsPanel selectedCustomer={selectedCustomer} onCustomerChange={handleCustomerChange} />
                )}
              </Box>
            </PageContentContainer>
          )}
        </>
      )}
      <AccountSideBarDrawer />
    </Box>
  );
};

export default Communications;
