import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

interface CfTooltipCustomProps {
  toolTipStyle?: {
    maxWidth?: string | number;
    margin?: string | number;
    padding?: string | number;
    borderRadius?: string | number;
    top?: string | number;
    right?: string | number;
  };
}
/**
 * the properties which describe how the CfTooltip component should present and behave (extends MUI TooltipProps)
 */
export type CfTooltipProps = CfTooltipCustomProps & TooltipProps;

export const CfTooltip: FC<CfTooltipProps> = (props: CfTooltipProps) => {
  return <StyledTooltip {...props} />;
};

/**
 * Utilizes and styles the MUI Tooltip component
 * @param toolTipStyle a subset of css properties to apply to a Tooltip component (maxWidth, margin, padding, borderRadius, top, right)
 * @param ... TooltipProps passed to the Tooltip component
 */
const StyledTooltip = styled(
  ({ className, ...props }: CfTooltipProps): JSX.Element => <Tooltip {...props} classes={{ popper: className }} />,
  {
    shouldForwardProp: (prop) => prop !== 'toolTipStyle',
  }
)<CfTooltipProps>(({ theme, toolTipStyle }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    transition:
      'opacity 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',
    maxWidth: `${toolTipStyle?.maxWidth} !important`,
    margin: `${toolTipStyle?.margin} !important`,
    padding: `${toolTipStyle?.padding} !important`,
    borderRadius: `${toolTipStyle?.borderRadius} !important`,
    top: `${toolTipStyle?.top} !important`,
    right: `${toolTipStyle?.right} !important`,
    [theme.breakpoints.up('xs')]: {
      ...theme.typography.mobileParagraph,
      maxWidth: '287px !important',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '256px !important',
    },
    [theme.breakpoints.up('xl')]: {
      ...theme.typography.paragraphSmall,
    },
  },
}));
