import React from 'react';
import { NotificationKeys, RootState, useAppSelector } from 'common';
import { NotificationContainer } from './ComponentNotification.styles';

interface ComponentNotificationProps {
  notificationKeys: NotificationKeys[];
  messageComponent: JSX.Element;
}

const ComponentNotification = ({ messageComponent, notificationKeys }: ComponentNotificationProps): JSX.Element => {
  const notifications = useAppSelector((x: RootState) =>
    x.global.notifications.filter((y) => notificationKeys.includes(y.Key))
  );

  return (
    <>
      {notifications &&
        notifications.length > 0 &&
        notifications.map((n) => (
          <NotificationContainer key={n.Id} sx={{ mb: 2 }}>
            {React.cloneElement(messageComponent, {
              message: n.Message,
              title: n.Title,
              notificationType: n.NotificationType,
            })}
          </NotificationContainer>
        ))}
    </>
  );
};

export default ComponentNotification;
