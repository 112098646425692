export enum UserStatus {
  Active,
  Pending,
  Expired,
  Inactive,
}

export enum ManageUsersViewPDFSortOptionType {
  Alphabetical = 0,
  Status = 1,
  LastActiveDate = 2,
}
