import { ServiceResult } from '../models/api-shared.models';
import {
  Delivery,
  DeliveryDetail,
  GetDeliveriesRequest,
  GetDeliveryDetailRequest,
  GetDeliveryRequest,
} from '../models/delivery.models';
import { ReorderDeliveryRequest, ReorderResult } from '../models/reorder.models';
import ApiService from './api-service';

class DeliveryService extends ApiService {
  private static classInstance: DeliveryService;
  private getDeliveriesAbortController: AbortController;

  private constructor() {
    super('Delivery');
    this.getDeliveriesAbortController = new AbortController();
  }

  public abortGetDeliveries = (): void => {
    this.getDeliveriesAbortController.abort();
  };

  public getDeliveries(request: GetDeliveriesRequest): ServiceResult<Delivery[]> {
    this.getDeliveriesAbortController = new AbortController();
    return this.post(`${this.persistUrl}/GetDeliveries`, request, undefined, this.getDeliveriesAbortController.signal);
  }

  public getDelivery(request: GetDeliveryRequest): ServiceResult<Delivery> {
    return this.post(`${this.baseUrl}/GetDelivery`, request);
  }

  public getDeliveryDetail(request: GetDeliveryDetailRequest): ServiceResult<DeliveryDetail[]> {
    return this.post(`${this.baseUrl}/GetDeliveryDetail`, request);
  }

  public reorderDelivery(request: ReorderDeliveryRequest): ServiceResult<ReorderResult> {
    return this.post(`${this.persistUrl}/ReorderDelivery`, request);
  }

  public static getInstance(): DeliveryService {
    if (!this.classInstance) {
      this.classInstance = new DeliveryService();
    }
    return this.classInstance;
  }
}

export default DeliveryService;
