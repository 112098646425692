import { Backdrop, CircularProgress } from '@mui/material';
import { FC } from 'react';
import { circularProgressPropsBase } from '../Shared/constants';
import { StyledCenteredBox } from '../Shared/Shared.styles';

export interface CfPageLoaderProps {
  isLoading: boolean;
}

/**
 * Wraps the Wraps the MUI CircularProgress API so it is centered and styled consistently, and covers the whole page
 *
 * @param loading - Boolean indicating if the component should be showing
 */
export const CfPageLoader: FC<CfPageLoaderProps> = (props: CfPageLoaderProps) => {
  return (
    <Backdrop open={props.isLoading} sx={{ zIndex: 1500, opacity: '50%' }} transitionDuration={{ exit: 200 }}>
      <StyledCenteredBox>
        <CircularProgress color='primary' {...circularProgressPropsBase} />
      </StyledCenteredBox>
    </Backdrop>
  );
};
