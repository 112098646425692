import { ServiceResult } from '../models/api-shared.models';
import {
  InvoiceReportSearchDirectory,
  CreateInvoiceReportSearchDirectoryRequest,
  UpdateInvoiceReportSearchDirectoryRequest,
  DeleteInvoiceReportSearchDirectoryRequest,
} from '../models/invoice-report.models';
import ApiService from './api-service';

class InvoiceReportSearchDirectoryService extends ApiService {
  private static classInstance: InvoiceReportSearchDirectoryService;

  private constructor() {
    super('InvoiceReportSearchDirectory');
  }

  public getInvoiceReportSearchDirectories(): ServiceResult<InvoiceReportSearchDirectory[]> {
    return this.get(`${this.baseUrl}/GetSearchDirectories`);
  }

  public createInvoiceReportSearchDirectory(
    request: CreateInvoiceReportSearchDirectoryRequest
  ): ServiceResult<InvoiceReportSearchDirectory> {
    return this.post(`${this.baseUrl}/CreateSearchDirectory`, request);
  }

  public updateInvoiceReportSearchDirectory(
    request: UpdateInvoiceReportSearchDirectoryRequest
  ): ServiceResult<InvoiceReportSearchDirectory> {
    return this.post(`${this.baseUrl}/UpdateSearchDirectory`, request);
  }

  public deleteInvoiceReportSearchDirectory(
    request: DeleteInvoiceReportSearchDirectoryRequest
  ): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/DeleteSearchDirectory`, request);
  }

  public static getInstance(): InvoiceReportSearchDirectoryService {
    if (!this.classInstance) {
      this.classInstance = new InvoiceReportSearchDirectoryService();
    }
    return this.classInstance;
  }
}

export default InvoiceReportSearchDirectoryService;
