import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { FileResult } from '../../api';
import { GetCustomerRequest } from '../../api/models/customer.models';
import { ConfirmationStatusType, GetOrderConfirmationReportRequest } from '../../api/models/order-confirmation.models';
import CustomerService from '../../api/services/customer.service';
import OrderConfirmationNotificationService from '../../api/services/order-confirmation-notifications.service';
import OrderConfirmationService from '../../api/services/order-confirmation.service';
import { getTimeZone } from '../../helpers';
import { generateStaticId } from '../../helpers/lookups';
import { ConfirmationStatusLookup } from '../../helpers/lookups/ConfirmationStatus';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { NotificationDisplayType, NotificationKeys, NotificationType } from '../../models/notifications.models';
import { updateSelectedCustomer } from '../common/customer.thunks';
import { globalSlice } from '../common/global.slice';
import {
  removeAppNotificationById,
  removeAppNotificationByKey,
  upsertAppNotification,
  upsertAppNotificationByKeyAndId,
} from '../common/global.thunks';
import { AppDispatch, AppThunk, RootState } from '../store';
import { orderConfirmationSlice } from './order-confirmation.slice';

// Hooks
const orderConfirmationService = OrderConfirmationService.getInstance();
const customerService = CustomerService.getInstance();
const orderConfirmationNotificationService = OrderConfirmationNotificationService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

// Thunks

/**
 * Resets the OrderConfirmationState's properties to their initial values and dispatches the removeAppNotificationByKey thunk to remove all toasts
 * @returns void
 */
export const resetOrderConfirmation = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(removeAppNotificationByKey(NotificationKeys.Toast));
  dispatch(orderConfirmationSlice.actions.resetOrderConfirmation());
};

/**
 * Updates the OrderConfirmationState's submittedOrderData property and dispatches updateSelectedCustomer if the customerId doesn't match the CustomerState's selectedCustomer
 * @param orderId - the id representing the confirmed order
 * @param customerId - the id representhing the customer associated to the confirmed order
 * @param callback - executed after updating the OrderConfirmationState and dispatching the updateSelectedCustomer thunk
 * @returns void
 */
export const setSubmittedOrderData =
  (orderId: string, customerId: string, callBack?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(orderConfirmationSlice.actions.setSubmittedOrderData({ CustomerId: customerId, OrderId: orderId }));
    const customer = getState().customer.selectedCustomer;
    if (customer) {
      if (customerId.toLocaleLowerCase() !== customer.CustomerId.toLocaleLowerCase()) {
        const userSite = getState().user.userSite;
        if (userSite) {
          const newCustomer = userSite.UserCustomers.find(
            (c) => c.CustomerId.toLocaleLowerCase() === customerId.toLocaleLowerCase()
          );
          if (newCustomer) dispatch(updateSelectedCustomer(newCustomer));
        }
      }
    }
    if (callBack) callBack();
  };

export const getDummySubmittedOrderConfirmation =
  (customerId: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const orderId = getState().orderConfirmation.submittedOrderData?.OrderId;
      if (!orderId || !customerId) return;
      const confirmation = getState().orderConfirmation.orderConfirmation;
      if (confirmation && confirmation.OrderEntryHeaderId === orderId && confirmation.IsConfirmationReady) {
        return;
      }
      if (!getState().orderConfirmation.orderConfirmationLoading) {
        dispatch(orderConfirmationSlice.actions.setOrderConfirmationLoading(true));
      }
      const { data } = await orderConfirmationService.getOrderConfirmation({
        orderEntryHeaderId: orderId,
        isDummyConfirmation: true,
      });
      if (data.IsSuccess) {
        dispatch(
          upsertAppNotification(
            {
              Id: generateStaticId('toastNotification', [ConfirmationStatusType.AwaitingConfirmation.toString()]),
              NotificationType: NotificationType.Success,
              NotificationDisplayType: NotificationDisplayType.Toast,
              AutoDismiss: 10,
              CanUserDismiss: true,
              Key: NotificationKeys.Toast,
              Message: 'Your order has been received. You will receive a confirmation once your order is processed.',
            },
            0
          )
        );
        dispatch(orderConfirmationSlice.actions.setOrderConfirmation(data.ResultObject));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    } finally {
      dispatch(orderConfirmationSlice.actions.setOrderConfirmationLoading(false));
    }
  };
/**
 * Sends a request to the api to retrieve order confirmation data using the OrderConfirmationState's submittedOrderData
 * and the specified customerId and updates the OrderConfirmationState's orderConfirmation property with the successful response
 * @param customerId - the id represetning the customer associated with the order confirmation
 * @returns void
 */
export const getOrderConfirmation =
  (customerId: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const orderId = getState().orderConfirmation.submittedOrderData?.OrderId;
      if (!orderId || !customerId) return;

      const confirmation = getState().orderConfirmation.orderConfirmation;
      if (confirmation && confirmation.OrderEntryHeaderId === orderId && confirmation.IsConfirmationReady) {
        return;
      }
      if (!getState().orderConfirmation.orderConfirmationLoading) {
        dispatch(orderConfirmationSlice.actions.setOrderConfirmationLoading(true));
      }

      const { data } = await orderConfirmationService.getOrderConfirmation({ orderEntryHeaderId: orderId });

      if (data.IsSuccess) {
        if (data.ResultObject.ConfirmationStatus !== ConfirmationStatusType.AwaitingConfirmation) {
          dispatch(
            removeAppNotificationById(
              generateStaticId('toastNotification', [ConfirmationStatusType.AwaitingConfirmation.toString()])
            )
          );

          const confirmationStatus = ConfirmationStatusLookup.ConfirmationStatus[data.ResultObject.ConfirmationStatus];
          if (confirmationStatus.NotificationType !== NotificationType.Information) {
            dispatch(
              upsertAppNotificationByKeyAndId(
                NotificationKeys.Toast,
                confirmationStatus.Id,
                confirmationStatus.Message,
                undefined,
                confirmationStatus.Icon,
                confirmationStatus.NotificationType
              )
            );
          }
        }
        dispatch(orderConfirmationSlice.actions.setOrderConfirmation(data.ResultObject));
      } else {
        dispatch(
          globalSlice.actions.setErrorDialogContent({
            messages: data.ErrorMessages,
          })
        );
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    } finally {
      dispatch(orderConfirmationSlice.actions.setOrderConfirmationLoading(false));
    }
  };

/**
 * Sends a request to retrieve report data related to the orderId of the OrderConfirmationState's submittedOrderData property and updates
 * the OrderConfirmationState's orderConfirmationReportFileData property with the successful response.
 * @param lorem - lorem
 * @returns void
 */
export const getOrderConfirmationReport =
  (mockOrderConfirmation?: boolean | undefined): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setOrderConfirmationReportLoading(true));
      const orderId = getState().orderConfirmation.submittedOrderData?.OrderId;

      if (!orderId) return;
      const request: GetOrderConfirmationReportRequest = {
        orderEntryHeaderId: orderId,
        timeZone: getTimeZone(),
        isDummyConfirmation: mockOrderConfirmation ? mockOrderConfirmation : false,
      };
      const { data } = await orderConfirmationService.getOrderConfirmationReport(request);
      if (data.IsSuccess) {
        if (data.ResultObject.FileData) {
          dispatch(orderConfirmationSlice.actions.setOrderConfirmationReportFileData(data.ResultObject as FileResult));
        } else {
          dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        }
      }
      // [TODO]: Handle state if IsSuccess === false
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(setOrderConfirmationReportLoading(false));
    }
  };

/**
 * Updates the OrderConfirmationState's orderConfirmationReportFileDataLoading property
 * @param loading - the value to update the property with
 * @returns void
 */
export const setOrderConfirmationReportLoading =
  (loading: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(orderConfirmationSlice.actions.setOrderConfirmationReportFileDataLoading(loading));
  };

/**
 * Retrieves customer data from the api for the specified customerId and updates the OrderConfrirmationState's customer property with the successful response.
 * @param customerId - the id representing the customer associated to a confirmed order
 * @returns void
 */
export const getConfirmationCustomer =
  (customerId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      if (!customerId) return;

      const getCustomer: GetCustomerRequest = { customerId: customerId };
      const { data } = await customerService.getCustomer(getCustomer);

      if (data.IsSuccess) {
        dispatch(orderConfirmationSlice.actions.setOrderConfirmationCustomer(data.ResultObject));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Retrieves notification data from the api related to the specified customerId and updates the OrderConfirmationState's orderConfirmationNotifications property with the successful response
 * @param customerId - the id related to the customer to retrieve notifications for
 * @returns void
 */
export const getOrderConfirmationNotifications =
  (customerId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      if (!customerId) return;
      dispatch(orderConfirmationSlice.actions.setOrderConfirmationNotificationsLoading(true));
      const req = {
        CustomerId: customerId,
      };

      const { data } = await orderConfirmationNotificationService.getOrderConfirmationNotifications(req);
      if (data.IsSuccess) {
        dispatch(orderConfirmationSlice.actions.setOrderConfirmationNotifications(data.ResultObject));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(orderConfirmationSlice.actions.setOrderConfirmationNotificationsLoading(false));
    }
  };

/**
 * Sends a request to the api for the specified email to receive a notification when the specified customer submits an order - and dispatches getOrderConfirmationNotifications if the response is successful
 * @param customerId - the id related to the customer to add a notification for
 * @param email - the email address to send the notification to
 * @returns void
 */
export const addOrderConfirmationNotification =
  (customerId: string, email: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      if (!customerId) return;
      dispatch(orderConfirmationSlice.actions.setOrderConfirmationNotificationsLoading(true));
      const req = {
        CustomerId: customerId,
        Email: email,
      };

      const { data } = await orderConfirmationNotificationService.addOrderConfirmationNotification(req);

      if (data.IsSuccess) {
        dispatch(getOrderConfirmationNotifications(customerId));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(orderConfirmationSlice.actions.setOrderConfirmationNotificationsLoading(false));
    }
  };

/**
 * Sends a request to the api to update the email address value of a recipient of order confirmation notifications - and dispatches getOrderConfirmationNotifications if the response is successful
 * @param orderConfirmationNotificationId - the id representing the order confirmation notification record to be updated
 * @param email - the email address of the recipient
 * @param customerId - the id representing the customer
 * @returns void
 */
export const updateOrderConfirmationNotification =
  (orderConfirmationNotificationId: string, customerId: string, email: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      if (!orderConfirmationNotificationId) return;
      dispatch(orderConfirmationSlice.actions.setOrderConfirmationNotificationsLoading(true));
      const req = {
        OrderConfirmationNotificationId: orderConfirmationNotificationId,
        Email: email,
      };

      const { data } = await orderConfirmationNotificationService.updateOrderConfirmationNotification(req);

      if (data.IsSuccess) {
        dispatch(getOrderConfirmationNotifications(customerId));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(orderConfirmationSlice.actions.setOrderConfirmationNotificationsLoading(false));
    }
  };

/**
 * Sends a request to the api to remove a recipient of order confirmation notifications - and dispatches the getOrderConfirmationNotifications thunk if the response is successful
 * @param orderConfirmationNotificationId - the id representing the order confirmation notification record to be deleted
 * @param email - the email address of the recipient
 * @param customerId - the id representing the customer
 * @returns void
 */
export const deleteOrderConfirmationNotification =
  (orderConfirmationNotificationId: string, email: string, customerId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      if (!orderConfirmationNotificationId) return;
      dispatch(orderConfirmationSlice.actions.setOrderConfirmationNotificationsLoading(true));
      const req = {
        OrderConfirmationNotificationId: orderConfirmationNotificationId,
      };

      const { data } = await orderConfirmationNotificationService.deleteOrderConfirmationNotification(req);

      if (data.IsSuccess) {
        dispatch(getOrderConfirmationNotifications(customerId));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(orderConfirmationSlice.actions.setOrderConfirmationNotificationsLoading(false));
    }
  };
