import { Box, FormControl, RadioGroup } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import React, { FC, useEffect, useState } from 'react';
import { CfRadio, CfRadioSize, CfRadioVariant, RadioItem } from './Radio/CfRadio';

export interface CfRadioListProps {
  label?: string;
  items: RadioItem[];
  variant?: CfRadioVariant;
  defaultValue?: unknown;
  currentValue?: unknown;
  disabled?: boolean;
  radioStyle?: SxProps<Theme>;
  testid?: string;
  sx?: SxProps<Theme>;
  size?: CfRadioSize;
  applyFocusToInput?: boolean;
  radioRefs?: React.RefObject<HTMLInputElement>[];
  preventGroupOnChange?: boolean;
  onChange?: (value: string) => void;
  onLastRadioKeyDown?: React.KeyboardEventHandler<HTMLElement>;
}

/**
 * @param label - Will be used for the aria-label, gives context to what the element is
 * @param items - Array of the radio items in the list
 * @param variant - Styling variant (primary, secondary)
 * @param defaultValue - The default selected radio button's value
 * @param currentValue - The selected radio button's value
 * @param disabled - Boolean indicating if the radio buttons are disabled
 * @param radioStyle - Additional styling of the radio buttons
 * @param testid - Test id for the Radio group, which will be used to create test ids for each button and the name of the radio group
 * @param sx - Styling for the box component which wraps the radio group
 * @param size - The size of the radio buttons
 * @param applyFocusToInput - Boolean indicating if the focus should switch to the input element
 * @param radioRefs - Array of references to each of the radio buttons
 * @param preventGroupOnChange - Prevents the RadioGroup from firing its onChangeMethod, used to fix double onChange call
 * @param onChange - Method to call on change
 * @param onLastRadioKeyDown - Method to call if the last radio button has a key down event occur
 */
export const CfRadioList: FC<CfRadioListProps> = (props: CfRadioListProps) => {
  const [value, setValue] = useState(
    props.items.length > 0
      ? typeof props.defaultValue !== 'undefined'
        ? props.defaultValue
        : props.items[0].value
      : ''
  );

  useEffect(() => {
    if (props.currentValue) {
      setValue(props.currentValue);
    }
  }, [props.currentValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setValue(value);
    props.onChange?.(value);
  };

  const handleRadioKeyDown = (event: React.KeyboardEvent<HTMLElement>, index: number) => {
    if (!props.radioRefs?.length) return;

    const nextIndex = index + 1;
    const nextRadio = props.radioRefs?.[nextIndex]?.current;

    if (nextIndex === props.items.length && props.onLastRadioKeyDown) {
      props.onLastRadioKeyDown(event);
    } else if ([event.key.toLowerCase(), event.code.toLowerCase()].includes('tab') && !event.shiftKey) {
      event.preventDefault();
      nextRadio?.focus();
    }
  };

  const children = props.items.map((item, index) => {
    const sx = {
      ...props.radioStyle,
      ...item.radioSx,
    };
    return (
      <CfRadio
        key={index}
        inputRef={props.radioRefs?.[index]}
        item={item}
        sx={sx}
        variant={props.variant ?? 'primary'}
        dataTestId={`${props.testid}-radio`}
        size={props.size}
        isDisabled={props.disabled}
        isFocusAppliedToInput={props.applyFocusToInput}
        onChange={(v) => {
          setValue(v.value);
          props.onChange?.(v.value as string);
        }}
        onKeyDown={(e) => handleRadioKeyDown(e, index)}
      />
    );
  });

  return (
    <Box sx={props.sx ?? { pb: 1 }}>
      <FormControl>
        <RadioGroup
          name={`cf-radio-list-${props.testid}`}
          value={value}
          data-testid={props.testid}
          aria-label={props.label}
          onChange={props?.preventGroupOnChange ? undefined : handleChange}
        >
          {children}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
