import { CatalogProduct, FileResult, ServiceResult } from '../models/api-shared.models';
import { Customer } from '../models/customer.models';
import {
  CopyProductListRequest,
  CreateProductListHeaderRequest,
  DeleteProductListHeaderRequest,
  GetProductListHeaderRequest,
  GetProductListHeadersByProductKeyRequest,
  GetProductListHeadersRequest,
  ProductListHeader,
  ProductListHeaderCustom,
  ProductListHeaderCustomer,
  UpdateProductListCustomerRequest,
  UpdateProductListHeaderRequest,
} from '../models/product-list-header.models';
import {
  CreateImportProductListRequest,
  CreateImportProductListResponse,
  GetCopyToProductListHeadersRequest,
  GetExportOptionDefaultsResultData,
  GetProductListHeadersForAdditionByProductKeyRequest,
  GetProductListReportCategoryOptionsRequest,
  GetProductListReportRequest,
  GetProductListReportSortOptionResponse,
  GetShareProductListHeaderCustomersRequest,
  GetShareProductListHeaderCustomersResponse,
  ProductListHeaderForAddition,
  UpdateShareProductListHeaderRequest,
} from './../models/product-list-header.models';
import ApiService from './api-service';

class ProductListHeaderService extends ApiService {
  private static classInstance: ProductListHeaderService;
  private getProductListHeadersAbortController: AbortController;

  private constructor() {
    super('ProductListHeader');
    this.getProductListHeadersAbortController = new AbortController();
  }

  public getNotificationProductListHeaders(customerId: string): ServiceResult<ProductListHeaderCustom[]> {
    return this.get(`${this.baseUrl}/GetNotificationProductListHeaders`, { customerId: customerId });
  }

  public abortGetProductListHeaders = (): void => {
    this.getProductListHeadersAbortController.abort();
  };

  public getProductListHeaders(request: GetProductListHeadersRequest): ServiceResult<ProductListHeaderCustom[]> {
    this.getProductListHeadersAbortController = new AbortController();
    return this.get(`${this.baseUrl}/GetProductListHeaders`, request, this.getProductListHeadersAbortController.signal);
  }

  public getCopyToProductListHeaders(
    request: GetCopyToProductListHeadersRequest
  ): ServiceResult<ProductListHeaderCustom[]> {
    return this.get(`${this.baseUrl}/GetCopyToProductListHeaders`, request);
  }

  public getProductListHeadersByProductKey(
    request: GetProductListHeadersByProductKeyRequest
  ): ServiceResult<ProductListHeader[]> {
    return this.post(`${this.baseUrl}/GetProductListHeadersByProductKey`, request);
  }

  public getProductListHeadersForAdditionByProductKey(
    request: GetProductListHeadersForAdditionByProductKeyRequest
  ): ServiceResult<ProductListHeaderForAddition[]> {
    return this.post(`${this.baseUrl}/GetProductListHeadersForAdditionByProductKey`, request);
  }

  public getProductListHeader(request: GetProductListHeaderRequest): ServiceResult<ProductListHeaderCustom> {
    return this.get(`${this.baseUrl}/GetProductListHeader`, request);
  }

  public createProductListHeader(request: CreateProductListHeaderRequest): ServiceResult<ProductListHeader> {
    return this.post(`${this.baseUrl}/CreateProductListHeader`, request);
  }

  public copyProductList(request: CopyProductListRequest): ServiceResult<ProductListHeaderCustom[]> {
    return this.post(`${this.baseUrl}/CopyProductList`, request);
  }

  public updateProductListHeader(request: UpdateProductListHeaderRequest): ServiceResult<ProductListHeader> {
    return this.post(`${this.baseUrl}/UpdateProductListHeader`, request);
  }

  public updateProductListCustomer(
    request: UpdateProductListCustomerRequest
  ): ServiceResult<ProductListHeaderCustomer> {
    return this.post(`${this.baseUrl}/UpdateProductListCustomer`, request);
  }

  public getCustomersUserCanManageProductLists(): ServiceResult<Customer[]> {
    return this.get(`${this.baseUrl}/GetCustomersUserCanManageProductLists`);
  }

  public deleteProductListHeader(request: DeleteProductListHeaderRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/DeleteProductListHeader`, request);
  }

  public getShareProductListHeaderCustomers(
    request: GetShareProductListHeaderCustomersRequest
  ): ServiceResult<GetShareProductListHeaderCustomersResponse[]> {
    return this.get(`${this.baseUrl}/GetShareProductListHeaderCustomers`, request);
  }

  public updateShareProductListHeader(request: UpdateShareProductListHeaderRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/UpdateShareProductListHeader`, request);
  }

  public getProductListImportTemplate(): ServiceResult<FileResult> {
    return this.get(`${this.baseUrl}/DownloadProductListImportTemplate`);
  }

  public getExportOptionDefaults(productListHeaderId: string): ServiceResult<GetExportOptionDefaultsResultData> {
    return this.get(`${this.baseUrl}/GetExportOptionDefaults`, { productListHeaderId: productListHeaderId });
  }

  public getProductListReportSortOptions(
    productListHeaderId: string
  ): ServiceResult<GetProductListReportSortOptionResponse[]> {
    return this.get(`${this.baseUrl}/GetProductListReportSortOptions`, { productListHeaderId: productListHeaderId });
  }

  public getProductListReportCategoryOptions(
    request: GetProductListReportCategoryOptionsRequest
  ): ServiceResult<string[]> {
    return this.get(`${this.baseUrl}/GetProductListReportCategories`, request);
  }

  public getProductListReport(request: GetProductListReportRequest): ServiceResult<FileResult> {
    return this.post(`${this.baseUrl}/GetProductListReport`, request);
  }

  public createProductListFromImportCSV(
    request: CreateImportProductListRequest
  ): ServiceResult<CreateImportProductListResponse> {
    return this.post(`${this.baseUrl}/CreateImportProductList`, request);
  }

  public getProductListRecentPurchase(
    productListHeaderId: string,
    customerId: string
  ): ServiceResult<CatalogProduct[]> {
    return this.get(`${this.baseUrl}/GetProductListRecentPurchase`, {
      productListHeaderId: productListHeaderId,
      customerId: customerId,
    });
  }

  public static getInstance(): ProductListHeaderService {
    if (!this.classInstance) {
      this.classInstance = new ProductListHeaderService();
    }
    return this.classInstance;
  }
}

export default ProductListHeaderService;
