import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { History } from 'history';
import { getResolution } from 'web/src/utilities/resolution';
import { ConfirmationStatusType } from '../../api';
import { GetDeliveriesRequest } from '../../api/models/delivery.models';
import {
  DeleteOrderEntryHeaderRequest,
  GetConfirmationOrderEntryHeaderByCustomersRequest,
  GetCustomersOrderEntryHeadersRequest,
  GetFutureOrderEntryHeadersRequest,
} from '../../api/models/order-entry-header.models';
import { GetSubmittedOrderHeadersRequest } from '../../api/models/submitted-order.models';
import DeliveryService from '../../api/services/delivery.service';
import OrderEntryHeaderService from '../../api/services/order-entry-header.service';
import SubmittedOrderService from '../../api/services/submitted-order.service';
import { AppNotificationsCenter, getOrderType } from '../../helpers';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { NotificationKeys, UserActivityAction, UserActivityActionSummary, UserActivityPageName } from '../../models';
import { OrderHistoryMode } from '../../models/order.enums';
import {
  getDeliveryFromOrderDelivery,
  getOpenOrderHeaderFromOrderSubmitted,
  getOrderBasicFromOrderEntryHeader,
  getOrderDeliveryFromDelivery,
  getOrderEntryHeaderFromOrderBasic,
  getOrderSubmittedFromOpenOrderHeader,
} from '../../models/order.mappers';
import { Order, OrderBasic, OrderDelivery, OrderHistoryGridHistory, OrderSubmitted } from '../../models/order.models';
import { AppRoutes, RouteName } from '../../models/routing.models';
import { logUserActivity, updateSelectedCustomerByCustomerId, upsertAppNotification } from '../common';
import { deliveriesSlice } from '../deliveries';
import { setDelivery } from '../deliveries/deliveries.thunks';
import { AppDispatch, AppThunk, RootState } from '../store';
import { resetOrderConfirmation } from './order-confirmation.thunks';
import { orderSlice } from './order.slice';
import { ordersSlice } from './orders.slice';
import {
  getActiveOrder,
  resetActiveOrder,
  resetOrderCart,
  setActiveCart,
  setActiveOrderIsFutureSubmission,
} from './orders.thunks';

const deliveryService = DeliveryService.getInstance();
const submittedOrderService = SubmittedOrderService.getInstance();
const orderEntryHeaderService = OrderEntryHeaderService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

// Get orders by page
/**
 * Calls the getOrderUnsubmitted, getOrderDeliveries, and getOrderSubmitted thunks with the specified customerIds available to the authorized user
 * @param request - the request data passed to each thunk
 * @returns
 */
export const getHomeOrders =
  (request: { customerIds: string[]; startDate: string; endDate: string }): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const user = getState().user.userSite;
    let errorMessages: string[] = [];

    if (user && user.UserCustomers.length) {
      const customerIdSet = new Set(request.customerIds);
      const customerIds = user.UserCustomers.map((uc) => uc.CustomerId).filter((customerId) =>
        customerIdSet.has(customerId)
      );

      // Fetch all orders - except confirmations
      errorMessages = errorMessages.concat(await dispatch(getOrderUnsubmitted(customerIds)));
      errorMessages = errorMessages.concat(
        await dispatch(
          getOrderDeliveries({
            CustomerIds: customerIds,
            StartDate: request.startDate,
            EndDate: request.endDate,
          })
        )
      );
      errorMessages = errorMessages.concat(
        await dispatch(
          getOrderSubmitted({
            CustomerIds: customerIds,
          })
        )
      );
      errorMessages = errorMessages.concat(await dispatch(getFutureOrderEntryHeaders(customerIds)));

      if (errorMessages.length > 1) {
        dispatch(
          upsertAppNotification(
            AppNotificationsCenter.getNotificationByKey(
              NotificationKeys.CustomErrorInformation,
              'Sorry, some recent order information is not available. Please check back later.'
            ),
            10
          )
        );
      } else if (errorMessages.length > 0) {
        dispatch(
          upsertAppNotification(
            AppNotificationsCenter.getNotificationByKey(NotificationKeys.CustomErrorInformation, errorMessages.at(0)),
            10
          )
        );
      }
    }
  };

/**
 * Calls the getOrderConfirmations, getOrderDeliveries, getOrderSubmitted, and getFutureOrderEntryHeaders thunks with the specified customerIds available to the authorized user
 * @param request - the request data passed to each thunk
 * @returns
 */
export const getOrderHistoryOrders =
  (request: { customerIds: string[]; startDate: string; endDate: string }, orderMode?: OrderHistoryMode): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const user = getState().user.userSite;

    let errorMessages: string[];
    errorMessages = [];

    if (user && user.UserCustomers.length) {
      const customerIdSet = new Set(request.customerIds);
      const customerIds = user.UserCustomers.map((uc) => uc.CustomerId).filter((customerId) =>
        customerIdSet.has(customerId)
      );

      //Fetch all orders - (except unsubmitted)
      errorMessages = errorMessages.concat(
        await dispatch(
          getOrderConfirmations({
            CustomerIds: customerIds,
            StartDate: request.startDate,
            EndDate: request.endDate,
            ConfirmationStatus: ConfirmationStatusType.Cancelled,
          })
        )
      );
      errorMessages = errorMessages.concat(
        await dispatch(
          getOrderDeliveries({
            CustomerIds: customerIds,
            StartDate: request.startDate,
            EndDate: request.endDate,
          })
        )
      );
      errorMessages = errorMessages.concat(
        await dispatch(
          getOrderSubmitted({
            CustomerIds: customerIds,
            StartDate: request.startDate,
            EndDate: request.endDate,
          })
        )
      );
      if (orderMode !== OrderHistoryMode.RepeatOrder) {
        errorMessages = errorMessages.concat(await dispatch(getFutureOrderEntryHeaders(customerIds)));
      }

      if (errorMessages.length > 1) {
        dispatch(
          upsertAppNotification(
            AppNotificationsCenter.getNotificationByKey(
              NotificationKeys.CustomErrorInformation,
              'Sorry, some order history information is not available. Please check back later.'
            ),
            10
          )
        );
      } else if (errorMessages.length > 0) {
        dispatch(
          upsertAppNotification(
            AppNotificationsCenter.getNotificationByKey(NotificationKeys.CustomErrorInformation, errorMessages.at(0)),
            10
          )
        );
      }
    }
  };

// Get orders by type
/**
 * Retrieves order data from the api pertaining to unsubmitted orders and updates the OrderState properties related to unsubmitted orders
 * @param customerIds - the id values representing customers whose orders should be retrieved
 * @returns
 */
export const getOrderUnsubmitted =
  (customerIds: string[]): AppThunk<Promise<string[]>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<string[]> => {
    const request: GetCustomersOrderEntryHeadersRequest = {
      customerIds,
    };

    if (JSON.stringify(getState().order.unsubmittedOrdersRequest) === JSON.stringify(request)) {
      return [];
    }

    let errorMessages: string[] = [];

    dispatch(orderSlice.actions.setUnsubmittedOrdersRequest(request));
    if (!getState().order.unsubmittedOrdersLoading) {
      dispatch(orderSlice.actions.setUnsubmittedOrdersLoading(true));
    }

    orderEntryHeaderService.abortGetCustomersOrderEntryHeaders();

    try {
      const { data } = await orderEntryHeaderService.getCustomersOrderEntryHeaders(request);
      if (data.IsSuccess) {
        const orders = data.ResultObject.map(getOrderBasicFromOrderEntryHeader);
        dispatch(orderSlice.actions.setUnsubmittedOrders(orders));
      } else {
        errorMessages = data.ErrorMessages;
        dispatch(orderSlice.actions.setUnsubmittedOrders([]));
      }
      dispatch(orderSlice.actions.setUnsubmittedOrdersLoading(false));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(orderSlice.actions.setUnsubmittedOrdersLoading(false));
    }
    return errorMessages;
  };

/**
 * Retrieves order data from the api pertaining to order confirmations and updates the OrderState properties related to order confirmations
 * @param customerIds - the id values representing customers whose orders should be retrieved
 * @returns
 */
export const getOrderConfirmations =
  (request: GetConfirmationOrderEntryHeaderByCustomersRequest): AppThunk<Promise<string[]>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<string[]> => {
    if (JSON.stringify(getState().order.confirmationsRequest) === JSON.stringify(request)) {
      return [];
    }

    let errorMessages: string[] = [];
    dispatch(orderSlice.actions.setConfirmationsRequest(request));

    if (!getState().order.confirmationsLoading) {
      dispatch(orderSlice.actions.setConfirmationsLoading(true));
    }

    orderEntryHeaderService.abortGetConfirmationOrderEntryHeaderByCustomers();

    try {
      const { data } = await orderEntryHeaderService.getConfirmationOrderEntryHeaderByCustomers(request);

      if (data.IsSuccess) {
        const orders = data.ResultObject.map(getOrderBasicFromOrderEntryHeader);
        dispatch(orderSlice.actions.setConfirmations(orders));
      } else {
        errorMessages = data.ErrorMessages;
        dispatch(orderSlice.actions.setConfirmations([]));
      }
      dispatch(orderSlice.actions.setConfirmationsLoading(false));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(orderSlice.actions.setConfirmationsLoading(false));
    }
    return errorMessages;
  };

/**
 * Retrieves order data from the api pertaining to delivery orders and updates the OrderState properties related to delivery orders
 * @param customerIds - the id values representing customers whose orders should be retrieved
 * @returns
 */
export const getOrderDeliveries =
  (request: GetDeliveriesRequest): AppThunk<Promise<string[]>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<string[]> => {
    if (JSON.stringify(getState().order.deliveriesRequest) === JSON.stringify(request)) {
      return [];
    }

    let errorMessages: string[] = [];
    dispatch(orderSlice.actions.setDeliveriesRequest(request));

    if (!getState().order.deliveriesLoading) {
      dispatch(orderSlice.actions.setDeliveriesLoading(true));
    }

    deliveryService.abortGetDeliveries();

    try {
      const { data } = await deliveryService.getDeliveries(request);

      if (data.IsSuccess) {
        const orders = data.ResultObject.map(getOrderDeliveryFromDelivery);
        dispatch(orderSlice.actions.setDeliveries(orders));
      } else {
        errorMessages = data.ErrorMessages;
      }
      dispatch(orderSlice.actions.setDeliveriesLoading(false));
    } catch (error) {
      console.error(error);
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(orderSlice.actions.setDeliveriesLoading(false));
    }
    return errorMessages;
  };

/**
 * Retrieves order data from the api pertaining to submitted orders and updates the OrderState properties related to submitted orders
 * @param customerIds - the id values representing customers whose orders should be retrieved
 * @returns
 */
export const getOrderSubmitted =
  (request: GetSubmittedOrderHeadersRequest): AppThunk<Promise<string[]>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<string[]> => {
    if (JSON.stringify(getState().order.submittedOrdersRequest) === JSON.stringify(request)) {
      return [];
    }
    let errorMessages: string[] = [];

    dispatch(orderSlice.actions.setSubmittedOrdersRequest(request));

    if (!getState().order.submittedOrdersLoading) {
      dispatch(orderSlice.actions.setSubmittedOrdersLoading(true));
    }

    submittedOrderService.abortGetSubmittedOrderHeaders();

    try {
      const { data } = await submittedOrderService.getSubmittedOrderHeaders(request);

      if (data.IsSuccess) {
        const orders = data.ResultObject.map(getOrderSubmittedFromOpenOrderHeader);
        dispatch(orderSlice.actions.setSubmittedOrders(orders));
      } else {
        errorMessages = data.ErrorMessages;
      }
      dispatch(orderSlice.actions.setSubmittedOrdersLoading(false));
    } catch (error) {
      console.error(error);
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(orderSlice.actions.setSubmittedOrdersLoading(false));
    }

    return errorMessages;
  };

/**
 * Gets and stores the result of the GetFutureOrderEntryHeaders API call
 *
 * @param customerId - Id of the customer to make the GetFutureOrderEntryHeaders call with
 * @returns NULL
 */
export const getFutureOrderEntryHeaders =
  (customerId?: string[]): AppThunk<Promise<string[]>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<string[]> => {
    let errorMessages: string[] = [];
    try {
      if (!getState().order.futureOrdersLoading) {
        dispatch(orderSlice.actions.setFutureOrderLoading(true));
      }

      const request: GetFutureOrderEntryHeadersRequest = {
        customerId: customerId,
      };

      const { data } = await orderEntryHeaderService.GetFutureOrderEntryHeaders(request);
      if (data.IsSuccess) {
        const orders = data.ResultObject.map(getOrderBasicFromOrderEntryHeader);
        dispatch(orderSlice.actions.setFutureOrder(orders));
      } else {
        errorMessages = data.ErrorMessages;
        dispatch(orderSlice.actions.setFutureOrder([]));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(orderSlice.actions.setFutureOrderLoading(false));
    }
    return errorMessages;
  };

/**
 * Sends a request to the api to delete an edited order and dispatches the resetActiveOrder and resetOrderCart thunks
 * @param orderEntryHeaderId - the id representing the order to be deleted
 * @returns
 */
export const deleteEditedOrder =
  (orderEntryHeaderId: string | undefined): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (!orderEntryHeaderId) return;

    dispatch(
      logUserActivity({
        action: UserActivityAction.CancelEditOrder,
        pageName: UserActivityPageName.OrderEntry,
        actionSummary: UserActivityActionSummary.CancelledEditingOrder,
        resolution: getResolution(),
      })
    );

    await dispatch(resetActiveOrder());
    await dispatch(resetOrderCart());

    dispatch(deleteOrderUnsubmitted(orderEntryHeaderId));
  };

// Misc
/**
 * Sends a request to the api to delete an unsubmitted order and dispatches the getActiveOrder thunk if the response is successful and it matches the current active order
 * @param orderEntryHeaderId - the id representing the unsubmitted order to be deleted
 * @returns
 */
export const deleteOrderUnsubmitted =
  (orderEntryHeaderId: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      // TODO: add appropriate lock

      if (!getState().order.deletingUnsubmittedOrder) {
        dispatch(orderSlice.actions.setDeletingUnsubmittedOrder(true));
      }
      const customer = getState().customer.selectedCustomer;
      const activeOrder = getState().orders.activeOrder; // TODO: replace with reference to new slice
      // order-active, or order-selected

      if (customer) {
        const request: DeleteOrderEntryHeaderRequest = {
          OrderEntryHeaderId: orderEntryHeaderId,
        };

        const { data } = await orderEntryHeaderService.deleteOrderEntryHeader(request);
        if (data.IsSuccess) {
          dispatch(orderSlice.actions.deleteUnsubmittedOrder(orderEntryHeaderId));
          if (activeOrder?.OrderEntryHeaderId === orderEntryHeaderId) {
            dispatch(getActiveOrder(customer.CustomerId)); // TODO: replace with call in new thunk
          }
        }
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(orderSlice.actions.setDeletingUnsubmittedOrder(false));
    }
  };

/**
 * Determines type of order (delivery, submitted, confirmation, unsubmitted) and pushes the designated url to the history state.
 * Deliveries dispatch the setDelivery thunk and Unsubmitted orders dispatch the setActiveCart thunk.
 * @param history - the history object used to navigate the user to the proper route
 * @param order - the order data to be evaluated and used to navigate the user to the proper route
 * @param state - the state of the order history page's grid that is passed as url parameters
 * @returns
 */
export const navigateToOrder =
  (history: History, order: Order, state?: OrderHistoryGridHistory): AppThunk =>
  async (dispatch: AppDispatch) => {
    // If necessary, change customer before navigating. Ensures proper locks are in place
    await dispatch(updateSelectedCustomerByCustomerId(order.CustomerId));
    dispatch(setActiveOrderIsFutureSubmission(false));
    // wait for new permissions before navigating
    let _order = undefined;
    switch (getOrderType(order)) {
      case 'Delivery':
        dispatch(deliveriesSlice.actions.resetDeliveryDetails());

        _order = getDeliveryFromOrderDelivery(order as OrderDelivery);
        dispatch(setDelivery(_order));
        history.push(`${AppRoutes[RouteName.DeliveryDetails].Path}/${_order.DeliveryKey}`, {
          locationsSelected: state?.selectedCustomerIds,
          dateSelection: state?.dateSelection,
          startDate: state?.startDate,
          endDate: state?.endDate,
          mode: state?.mode,
        });
        break;
      case 'Submitted':
        dispatch(ordersSlice.actions.resetSubmittedOrderDetails());
        _order = getOpenOrderHeaderFromOrderSubmitted(order as OrderSubmitted);
        // User needs to be pushed to order confirmation page if IsDummySubmittedOrder true
        if (_order.IsDummySubmittedOrder && _order.OrderEntryHeaderId) {
          history.push(
            `${AppRoutes[RouteName.OrderConfirmation].Path}/${_order.CustomerId}/${_order.OrderEntryHeaderId}/`,
            {
              isDummySubmittedOrder: true,
              locationsSelected: state?.selectedCustomerIds,
              dateSelection: state?.dateSelection,
              startDate: state?.startDate,
              endDate: state?.endDate,
              mode: state?.mode,
            }
          );
        } else {
          // Otherwise, navigate to order details
          history.push(`${AppRoutes[RouteName.OrderDetails].Path}/${_order.CustomerId}/${_order.OrderKey}`, {
            locationsSelected: state?.selectedCustomerIds,
            dateSelection: state?.dateSelection,
            startDate: state?.startDate,
            endDate: state?.endDate,
            mode: state?.mode,
          });
        }
        break;
      case 'Confirmation':
        dispatch(resetOrderConfirmation());

        _order = getOrderEntryHeaderFromOrderBasic(order as OrderBasic);
        history.push(
          `${AppRoutes[RouteName.OrderConfirmation].Path}/${_order.CustomerId}/${_order.OrderEntryHeaderId}`,
          {
            locationsSelected: state?.selectedCustomerIds,
            dateSelection: state?.dateSelection,
            startDate: state?.startDate,
            endDate: state?.endDate,
            mode: state?.mode,
          }
        );
        break;
      case 'FutureSubmission':
        _order = getOrderEntryHeaderFromOrderBasic(order as OrderBasic);
        dispatch(setActiveOrderIsFutureSubmission(true));
        history.push(
          `${AppRoutes[RouteName.OrderConfirmation].Path}/${_order.CustomerId}/${_order.OrderEntryHeaderId}`,
          {
            locationsSelected: state?.selectedCustomerIds,
            dateSelection: state?.dateSelection,
            startDate: state?.startDate,
            endDate: state?.endDate,
            mode: state?.mode,
          }
        );
        break;
      case 'Unsubmitted':
        _order = getOrderEntryHeaderFromOrderBasic(order as OrderBasic);
        await dispatch(setActiveCart(_order));
        history.push(`${AppRoutes[RouteName.OrderEntry].Path}/${_order.OrderEntryHeaderId}`, {
          locationsSelected: state?.selectedCustomerIds,
          dateSelection: state?.dateSelection,
          startDate: state?.startDate,
          endDate: state?.endDate,
          mode: state?.mode,
        });
        break;
    }
  };

/**
 * Updates the OrderState's customerIds property
 * @param customerIds - the value to set the property to
 * @returns
 */
export const setOrderGridCustomerIds =
  (customerIds: string[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(orderSlice.actions.setCustomerIds(customerIds));
  };

/**
 * Updates the OrderState's showAll property
 * @param showAll - the value to set the property to
 * @returns
 */
export const setOrderGridShowAll =
  (showAll: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(orderSlice.actions.setShowAll(showAll));
  };

/**
 * Updates the OrderState by removing all values from its unsubmittedOrders, submittedOrders, confirmations, and deliveries properties
 * @returns
 */
export const clearAllOrders = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(orderSlice.actions.clearAllOrders());
};

/**
 * Aborts api requests to retrieve order data
 * @returns
 */
export const abortAllGetOrders = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(orderSlice.actions.clearAllOrders());
  orderEntryHeaderService.abortGetCustomersOrderEntryHeaders();
  orderEntryHeaderService.abortGetConfirmationOrderEntryHeaderByCustomers();
  deliveryService.abortGetDeliveries();
  submittedOrderService.abortGetSubmittedOrderHeaders();
};
