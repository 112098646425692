import { styled } from '@mui/material/styles';

export const StyledPageNotificationContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  position: 'relative',
  zIndex: 10,
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));
