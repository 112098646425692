import { RouteName, AppRoutes as Routes } from 'common';
import { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { usePageTracking } from '../../hooks/usePageTracking';

export interface TitledRouteProps extends RouteProps {
  route: RouteName;
}

export const TitledRoute = (props: TitledRouteProps): JSX.Element => {
  const { route, ...rest } = props;
  usePageTracking(props.location?.pathname ?? '', route);
  useEffect(() => {
    document.title = `CustomerFirst - ${Routes[route].DisplayName}`;
  });
  return <Route {...rest} />;
};
