import { Breakpoint, Theme } from '@mui/material';
import { PaletteOptions, ThemeOptions } from '@mui/material/styles';
import { BreakpointsOptions } from '@mui/system';
import React from 'react';
import { LineHeights, ResponsiveTypographyVariant, cfFreezeNames } from './theme';

const breakPointValues: { [key in Breakpoint]: number } = {
  xs: 0, // small mobile
  sm: 375, // large mobile
  md: 414, // large mobile
  lg: 744, // tablet
  xl: 1184, // desktop
};

const breakPointsOptions: BreakpointsOptions = { values: breakPointValues };

const lineHeights: LineHeights = {
  h1: 32,
  h2: 24,
  h3: 30,
  h4: 24,
  h5: 14,
  h6: 18,
  paragraph: 18,
  paragraphBold: 18,
  paragraphLink: 18,
  paragraphLarge: 18,
  paragraphSmall: 16,
  paragraphSmallBold: 14,
  paragraphSmallLink: 16,
  paragraphHeaderBold: 16,

  numberedList: 18,

  mobileParagraph: 20,
  mobileParagraphBold: 20,
  mobileParagraphExtraSmall: 14,
  mobileParagraphSmall: 18,
  mobileParagraphSmallBold: 18,
  mobileParagraphExtraSmallBold: 14,
  mobileLabel: 22,

  iconExtraSmall: 15,
  iconSmall: 18,
  iconMedium: 20,
  iconLarge: 29,

  label: 20,
};

const paragraph = {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: lineHeights.paragraph + 'px',
  letterSpacing: 0,
};

const paragraphBold = {
  fontSize: 14,
  fontWeight: 700,
  lineHeight: lineHeights.paragraphBold + 'px',
  letterSpacing: 0,
};

const paragraphLink = {
  fontSize: 14,
  fontWeight: 700,
  lineHeight: lineHeights.paragraphLink + 'px',
  letterSpacing: 0,
};

const paragraphSmall = {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: lineHeights.paragraphSmall + 'px',
  letterSpacing: 0,
};

const paragraphLarge = {
  fontSize: 16,
  fontWeight: 400,
  lineHeight: lineHeights.paragraphLarge + 'px',
  letterSpacing: 0,
};

const paragraphSmallBold = {
  fontSize: 11,
  fontWeight: 700,
  lineHeight: lineHeights.paragraphSmallBold + 'px',
  letterSpacing: 0,
};

const paragraphSmallLink = {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: lineHeights.paragraphSmallLink + 'px',
  letterSpacing: 0,
};

const paragraphHeaderBold = {
  fontSize: 12,
  fontWeight: 700,
  lineHeight: lineHeights.paragraphHeaderBold + 'px',
  letterSpacing: 0,
};

const mobileParagraph = {
  fontSize: 16,
  fontWeight: 400,
  lineHeight: lineHeights.mobileParagraph + 'px',
  letterSpacing: 0,
};

const mobileParagraphBold = {
  fontSize: 16,
  fontWeight: 700,
  lineHeight: lineHeights.mobileParagraphBold + 'px',
  letterSpacing: 0,
};

const mobileParagraphSmall = {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: lineHeights.mobileParagraphSmall + 'px',
  letterSpacing: 0,
};

const mobileParagraphSmallBold = {
  fontSize: 14,
  fontWeight: 700,
  lineHeight: lineHeights.mobileParagraphSmallBold + 'px',
  letterSpacing: 0,
};

const mobileParagraphExtraSmall = {
  fontSize: 11,
  lineHeight: lineHeights.mobileParagraphExtraSmall + 'px',
  fontWeight: 400,
  letterSpacing: 0,
};

const mobileParagraphExtraSmallBold = {
  fontSize: 11,
  fontWeight: 700,
  lineHeight: lineHeights.mobileParagraphExtraSmallBold + 'px',
  letterSpacing: 0,
};

const responsiveUnset = {
  fontSize: 0,
  fontWeight: 0,
  lineHeight: 0,
  letterSpacing: 0,
};

const label = {
  fontSize: 16,
  fontWeight: 700,
  lineHeight: lineHeights.label + 'px',
  letterSpacing: 0,
};

export const getResponsiveTypographyRules = (
  theme: Theme
): {
  [key in ResponsiveTypographyVariant]: React.CSSProperties;
} => ({
  responsiveParagraph: {
    [theme.breakpoints.up('xs')]: {
      ...theme.typography.mobileParagraph,
    },
    [theme.breakpoints.up('xl')]: {
      ...theme.typography.paragraph,
    },
  },
  responsiveParagraphBold: {
    [theme.breakpoints.up('xs')]: {
      ...theme.typography.mobileParagraphBold,
    },
    [theme.breakpoints.up('xl')]: {
      ...theme.typography.paragraphBold,
    },
  },
  responsiveParagraphSmall: {
    [theme.breakpoints.up('xs')]: {
      ...theme.typography.mobileParagraphSmall,
    },
    [theme.breakpoints.up('xl')]: {
      ...theme.typography.paragraphSmall,
    },
  },
  responsiveParagraphSmallBold: {
    [theme.breakpoints.up('xs')]: {
      ...theme.typography.mobileParagraphSmallBold,
    },
    [theme.breakpoints.up('xl')]: {
      ...theme.typography.paragraphSmallBold,
    },
  },
});

const spacing = 8;

const headerHeights = {
  xs: 72,
  sm: 72,
  md: 72,
  lg: 54,
  xl: 34,
};

const getStartedHeaderHeights = {
  xs: 68,
  sm: 68,
  md: 68,
  lg: 68,
  xl: 80,
};

const footerHeights = {
  xs: 100,
  sm: 100,
  md: 100,
  lg: 64,
  xl: 53,
};

const pageNotificationHeights = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 24,
};

export function getThemeBase(palette: PaletteOptions): ThemeOptions {
  return {
    custom: {
      headerHeights: {
        ...headerHeights,
      },
      getStartedHeaderHeights: {
        ...getStartedHeaderHeights,
      },
      pageNotificationHeights: {
        ...pageNotificationHeights,
      },
      footerHeights: {
        ...footerHeights,
      },
      contentWidths: {
        xs: 327,
        sm: 366,
        md: 381,
        lg: 720,
        xl: 1136,
      },
      pageWidths: {
        xs: 375,
        sm: 414,
        md: 428,
        lg: 768,
        xl: 1280,
      },
      containedButtonBorderWidth: 2,
      outlinedButtonBorderWidth: 1.5,
      lineHeights,
      spacing,
      sticky: {
        zIndex: {
          'cf-header': 101,
          'cf-subheader': 99,
          'cf-breadcrumb': 7,
          'cf-aside': 5,
          'cf-page-header': 7,
          'cf-content-header': 6,
          'cf-table-header': 5,
          'cf-footer': 1,
        },
        getTotalHeightOfComponents: (freezeNames?: (cfFreezeNames | string)[]) => {
          if (!freezeNames?.length) return 0;

          // get the first HTML element of each respective freeze name
          const elements = freezeNames.map((a) => document.querySelector(`.${a}`));
          const htmlElements = elements.filter((e) => !!e).map((e) => e as HTMLElement);

          // map the heights
          const offsetTopVals = htmlElements.map((e) => e?.offsetHeight ?? 0);

          return offsetTopVals.reduce((p, c) => p + c, 0);
        },
      },
    },
    spacing,
    palette,
    typography: {
      fontFamily: 'Source Sans Pro',
      fontWeightLight: 400,
      fontSize: 14,
      h1: {
        fontSize: 26,
        fontWeight: 700,
        lineHeight: lineHeights.h1 + 'px',
        letterSpacing: -0.5,
      },
      h2: {
        fontSize: 22,
        fontWeight: 700,
        lineHeight: lineHeights.h2 + 'px',
        letterSpacing: 0,
      },
      h3: {
        fontSize: 26,
        fontWeight: 700,
        lineHeight: lineHeights.h3 + 'px',
        letterSpacing: -0.5,
      },
      h4: {
        fontSize: 18,
        fontWeight: 700,
        lineHeight: lineHeights.h4 + 'px',
        letterSpacing: 0,
      },
      h5: {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: lineHeights.h5 + 'px',
        letterSpacing: 0.5,
      },
      h6: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: lineHeights.h6 + 'px',
        letterSpacing: 0,
      },
      paragraph,
      paragraphBold,
      paragraphLink,
      paragraphLarge,
      paragraphSmall,
      paragraphSmallBold,
      paragraphSmallLink,
      paragraphHeaderBold,
      numberedList: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: lineHeights.numberedList + 'px',
        letterSpacing: 0,
      },
      mobileParagraph,
      mobileParagraphBold,
      mobileParagraphExtraSmall,
      mobileParagraphExtraSmallBold,
      mobileParagraphSmall,
      mobileParagraphSmallBold,
      mobileLabel: {
        fontSize: 18,
        fontWeight: 700,
        lineHeight: lineHeights.mobileLabel + 'px',
        letterSpacing: 0,
      },
      label,
      // The responsive variants are overridden after theme is created in theme.ts
      responsiveParagraph: responsiveUnset,
      responsiveParagraphBold: responsiveUnset,
      responsiveParagraphSmall: responsiveUnset,
      responsiveParagraphSmallBold: responsiveUnset,
      iconExtraSmall: {
        fontSize: 10,
        lineHeight: lineHeights.iconExtraSmall + 'px',
      },
      iconSmall: {
        fontSize: 14,
        lineHeight: lineHeights.iconSmall + 'px',
      },
      iconMedium: {
        fontSize: 20,
        lineHeight: lineHeights.iconMedium + 'px',
      },
      iconLarge: {
        fontSize: 26,
        lineHeight: lineHeights.iconLarge + 'px',
      },

      body1: paragraph,
      body2: paragraphBold,
      subtitle1: paragraphSmall,
      subtitle2: paragraphSmallBold,
      button: {
        ...paragraphBold,
        textTransform: 'none',
      },
    },
    shape: {
      borderRadius: 4,
    },
    breakpoints: breakPointsOptions,
  };
}
