import { ServiceResult } from '../models/api-shared.models';
import {
  GetDidYouForgetCustomerProductsRequest,
  GetDidYouForgetCustomerProductsResult,
  UpdateCustomProductRequest,
  UpdateCustomProductResult,
  UpdateIsCriticalItemRequest,
  UpdateIsCriticalItemResultDetail,
} from '../models/customer-product.models';
import ApiService from './api-service';

class CustomerProductService extends ApiService {
  private static classInstance: CustomerProductService;

  private constructor() {
    super('CustomerProduct');
  }

  public updateIsCriticalItem(request: UpdateIsCriticalItemRequest): ServiceResult<UpdateIsCriticalItemResultDetail> {
    return this.post(`${this.baseUrl}/UpdateIsCriticalItem`, request);
  }

  public updateCustomProduct(request: UpdateCustomProductRequest): ServiceResult<UpdateCustomProductResult> {
    return this.post(`${this.baseUrl}/UpdateCustomProduct`, request);
  }

  public getDidYouForgetCustomerProducts(
    request: GetDidYouForgetCustomerProductsRequest
  ): ServiceResult<GetDidYouForgetCustomerProductsResult> {
    return this.post(`${this.baseUrl}/GetDidYouForgetCustomerProducts`, request);
  }

  public static getInstance(): CustomerProductService {
    if (!this.classInstance) {
      this.classInstance = new CustomerProductService();
    }
    return this.classInstance;
  }
}

export default CustomerProductService;
