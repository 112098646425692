import { ButtonPropsSizeOverrides, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { OverridableStringUnion } from '@mui/types';
import { AppRoutes, RootState, RouteName, useAppSelector } from 'common';
import { OrderEntryType } from 'common/src/models/order.enums';
import { useHistory } from 'react-router-dom';
import { CfContainedButton } from '../../../../../cf-ui/buttons/CfContainedButton';
import { DeviceType, useBreakpoint } from '../../../../../hooks/useBreakpoint';

interface OrderSummaryContinueButtonProps {
  onNavigateToOrderEntry?: () => void;
  size?: OverridableStringUnion<'small' | 'medium' | 'large', ButtonPropsSizeOverrides>;
  sx?: SxProps<Theme>;
}

export const OrderSummaryContinueButton = (props: OrderSummaryContinueButtonProps): JSX.Element => {
  const history = useHistory();
  const { deviceType } = useBreakpoint();
  const orderEntryHeader = useAppSelector((x: RootState) => x.orders.order);

  const handleClickContinue = () => {
    history.push(`${AppRoutes[RouteName.OrderEntry].Path}/${orderEntryHeader?.OrderEntryHeaderId}`);
    props.onNavigateToOrderEntry?.();
  };

  return (
    <CfContainedButton
      color='secondary'
      isFullWidth={deviceType === DeviceType.Tablet ? false : true}
      onClick={handleClickContinue}
      data-testid='summary-continue-shopping-button'
      isDisabled={!orderEntryHeader}
      size={props.size}
      sx={props.sx}
    >
      {orderEntryHeader?.OrderType === OrderEntryType.Edit ? 'Continue updating' : 'Continue shopping'}
    </CfContainedButton>
  );
};
