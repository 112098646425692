export const validateStringEmptyOrMaxLength = (
  propertyName: string,
  propertyValue?: string,
  maxLength?: number,
  allowEmptyString?: boolean
): string[] => {
  const _propertyValue = propertyValue?.trim?.() ?? '';
  const validationMessages: string[] = [];
  if (!allowEmptyString && !_propertyValue) validationMessages.push(`${propertyName} is required`);
  if (maxLength !== undefined && _propertyValue.length > maxLength)
    validationMessages.push(`${propertyName} must be ${maxLength} characters or less`);
  return validationMessages;
};
