export enum OrderHistoryDateCardSelection {
  UpcomingAndRecent = 'uar',
  Last7Days = '7d',
  Last30Days = '30d',
  Last90Days = '90d',
  Last12Months = '12m',
  YearToDate = 'ytd',
  Custom = 'custom',
}

export enum SubmittedOrderStatusSearchFilter {
  Submitted = 0,
  PreparingOrder = 1,
}

export enum OrderHistoryMode {
  OrderHistory = 0,
  RepeatOrder = 1,
}

export enum OrderEntryType {
  Standard,
  Edit,
}
