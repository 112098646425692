import { CustomerSupportType } from '../../api/models/customer-support.enums';
import { AppRoutes, CustomerSupportPagesModel, RouteName } from '../../models';
import { accountTutorials } from './tutorials/account-tutorials';
import { invoiceTutorials } from './tutorials/finance-tutorials';
import { listManagementTutorials } from './tutorials/list-management-tutorials';
import { orderingTutorials } from './tutorials/ordering-tutorials';
import { parManagementTutorials } from './tutorials/par-management-tutorials';

export class CustomerSupportPageLookup {
  public static CustomerSupportPages: { [key in CustomerSupportType]: CustomerSupportPagesModel } = {
    [CustomerSupportType.Account]: {
      title: 'Account',
      iconClass: 'fa-user',
      link: AppRoutes[RouteName.AccountTutorials],
      tutorialItems: accountTutorials,
    },
    [CustomerSupportType.Invoices]: {
      title: 'Invoices',
      iconClass: 'fa-file-invoice-dollar',
      link: AppRoutes[RouteName.InvoiceTutorials],
      tutorialItems: invoiceTutorials,
    },
    [CustomerSupportType.Ordering]: {
      title: 'Ordering',
      iconClass: 'fa-shopping-cart',
      link: AppRoutes[RouteName.OrderingTutorials],
      tutorialItems: orderingTutorials,
    },
    [CustomerSupportType.ListManagement]: {
      title: 'List Management',
      iconClass: 'fa-list-alt',
      link: AppRoutes[RouteName.ListManagementTutorials],
      tutorialItems: listManagementTutorials,
    },
    [CustomerSupportType.ParManagement]: {
      title: 'Par Management',
      iconClass: 'fas fa-clipboard-list',
      link: AppRoutes[RouteName.ParManagementTutorials],
      tutorialItems: parManagementTutorials,
    },
  };
}
