export class UOMLookup {
  public static UOMTypes: {
    [key: number]: { description: string; shortDescription: string; quantityReference: string | undefined };
  } = {
    0: {
      description: 'case',
      shortDescription: 'CS',
      quantityReference: 'per',
    },
    1: {
      description: 'each',
      shortDescription: 'EA',
      quantityReference: undefined,
    },
    2: {
      description: 'box',
      shortDescription: 'BX',
      quantityReference: 'per',
    },
  };
}
