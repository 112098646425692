import { singletonHook } from 'react-singleton-hook';
import { useState } from 'react';

export enum Menus {
  None,
  NavigationDrawer,
  LocationMenu,
  ProfileMenu,
  CartMenu,
  OrdersMenu,
  FinancesMenu,
  InventoryMenu,
}

export interface HeaderMenusState {
  openedMenu: Menus;
}

const initState = {
  openedMenu: Menus.None,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const assignMenu = (_val: Menus): void => {
  console.warn('use setMenu hook to access this method');
};

export const useHeaderMenus = singletonHook<HeaderMenusState>(initState, () => {
  const [headerMenusState, setHeaderMenusState] = useState<HeaderMenusState>(initState);

  setMenu = (val: Menus) => {
    setHeaderMenusState({
      openedMenu: val,
    });
  };

  return headerMenusState;
});

export let setMenu = (value: Menus): void => assignMenu(value);
