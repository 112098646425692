import { ServiceResult } from '../models/api-shared.models';
import { UserSite, AreaFeaturePermissionSetting } from '../models/site.models';
import ApiService from './api-service';

class SiteService extends ApiService {
  private static classInstance: SiteService;

  private constructor() {
    super('Site');
  }

  public getCurrentUserSite(): ServiceResult<UserSite> {
    return this.get(`${this.baseUrl}/GetCurrentUserSite`);
  }

  public getAreaFeaturePermissionSetting(customerId: string): ServiceResult<AreaFeaturePermissionSetting> {
    return this.get(`${this.baseUrl}/GetAreaFeaturePermission`, { customerId });
  }

  public static getInstance(): SiteService {
    if (!this.classInstance) {
      this.classInstance = new SiteService();
    }
    return this.classInstance;
  }
}

export default SiteService;
