import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { GetProductListDiaryRequest, ProductListDiaryEntry } from '../../api/models/product-list-diary.models';
import { filterObjects, getSortComparer } from '../../helpers';

const diaryAdapter = createEntityAdapter<ProductListDiaryEntry>({
  selectId: (log: ProductListDiaryEntry) => log.ProductListDiaryLogId,
  sortComparer: getSortComparer((item) => new Date(item.ChangeDate), true),
});

export interface ProductListDiaryState {
  apiRequest?: GetProductListDiaryRequest;
  isLoadingGridData: boolean;
  gridData: EntityState<ProductListDiaryEntry>;
}

const initialState: ProductListDiaryState = {
  apiRequest: undefined,
  isLoadingGridData: false,
  gridData: diaryAdapter.getInitialState(),
};

export const productListDiarySlice = createSlice({
  name: 'product-list-diary',
  initialState: initialState,
  reducers: {
    resetState: () => initialState,
    setApiRequest: (state: ProductListDiaryState, action: PayloadAction<GetProductListDiaryRequest | undefined>) => {
      state.apiRequest = action.payload;
    },
    setIsLoadingGridData: (state: ProductListDiaryState, action: PayloadAction<boolean>) => {
      state.isLoadingGridData = action.payload;
    },
    setGridData: (state: ProductListDiaryState, action: PayloadAction<ProductListDiaryEntry[]>) => {
      diaryAdapter.setAll(state.gridData, action.payload);
    },
  },
});

const { selectAll } = diaryAdapter.getSelectors<ProductListDiaryState>(
  (state: ProductListDiaryState) => state.gridData
);

const selectLogs = (s: ProductListDiaryState, _f: string | undefined) => selectAll(s);
const selectFilter = (_s: ProductListDiaryState, filterText: string | undefined) => filterText;

export const selectProductListDiary = createSelector(
  [selectLogs, selectFilter],
  (data: ProductListDiaryEntry[], filterText?: string): ProductListDiaryEntry[] => {
    return filterGridData(data, filterText);
  }
);

const filterGridData = (data: ProductListDiaryEntry[], filterText?: string): ProductListDiaryEntry[] => {
  if (!filterText) return data;
  return filterObjects(
    data,
    filterText,
    ['ActionName', 'ActionSummary', 'ChangeByUser'],
    [
      {
        field: 'ActionName',
        type: 'custom',
        filterFunction: (val: string, queryString: string) =>
          val.toLocaleLowerCase().includes(queryString.toLocaleLowerCase()),
      },
      {
        field: 'ActionSummary',
        type: 'custom',
        filterFunction: (val: string, queryString: string) =>
          val.toLocaleLowerCase().includes(queryString.toLocaleLowerCase()),
      },
      {
        field: 'ChangeByUser',
        type: 'custom',
        filterFunction: (val: string, queryString: string) =>
          val.toLocaleLowerCase().includes(queryString.toLocaleLowerCase()),
      },
    ]
  );
};
