import ApiService from './api-service';
import {
  AddOrderConfirmationNotificationRequest,
  DeleteOrderConfirmationNotificationRequest,
  GetOrderConfirmationNotificationsRequest,
  OrderConfirmationNotification,
  UpdateOrderConfirmationNotificationRequest,
} from '../models/order-confirmation.models';
import { ServiceResult } from '../models/api-shared.models';

class OrderConfirmationNotificationService extends ApiService {
  private static classInstance: OrderConfirmationNotificationService;

  private constructor() {
    super('OrderConfirmationNotification');
  }

  public getOrderConfirmationNotifications(
    request: GetOrderConfirmationNotificationsRequest
  ): ServiceResult<OrderConfirmationNotification[]> {
    return this.get(`${this.baseUrl}/GetOrderConfirmationNotifications`, request);
  }

  public addOrderConfirmationNotification(
    request: AddOrderConfirmationNotificationRequest
  ): ServiceResult<OrderConfirmationNotification> {
    return this.post(`${this.baseUrl}/AddOrderConfirmationNotification`, request);
  }

  public updateOrderConfirmationNotification(
    request: UpdateOrderConfirmationNotificationRequest
  ): ServiceResult<OrderConfirmationNotification> {
    return this.post(`${this.baseUrl}/UpdateOrderConfirmationNotification`, request);
  }

  public deleteOrderConfirmationNotification(
    request: DeleteOrderConfirmationNotificationRequest
  ): ServiceResult<OrderConfirmationNotification> {
    return this.post(`${this.baseUrl}/DeleteOrderConfirmationNotification`, request, 'queryParams');
  }

  public static getInstance(): OrderConfirmationNotificationService {
    if (!this.classInstance) {
      this.classInstance = new OrderConfirmationNotificationService();
    }
    return this.classInstance;
  }
}

export default OrderConfirmationNotificationService;
