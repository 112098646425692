import { Box, Icon, IconButton, Stack, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import {
  addOrderConfirmationNotification,
  deleteOrderConfirmationNotification,
  OrderConfirmationNotification,
  updateOrderConfirmationNotification,
  useAppDispatch,
  UserCustomer,
} from 'common';
import { useEffect, useState } from 'react';
import { debounce } from 'throttle-debounce';
import { CfCheckbox, CheckboxItem } from '../../../../../../cf-ui/CheckboxList/components/Checkbox/CfCheckbox';
import { DeviceType, useBreakpoint } from '../../../../../../hooks/useBreakpoint';
import CommunicationsDeleteEmailDialog from '../dialogs/CommunicationsDeleteEmailDialog';
import CommunicationsEmailDialog from '../dialogs/CommunicationsEmailDialog';
import { EmailActions } from './components/EmailActions';

interface CommunicationsEmailBodyProps {
  selectedCustomer: UserCustomer;
  notificationSubscriptions: OrderConfirmationNotification[] | undefined;
  defaultUserEmail: string;
}

const CommunicationsEmailBody: React.FC<CommunicationsEmailBodyProps> = (
  props: CommunicationsEmailBodyProps
): JSX.Element => {
  const { deviceType } = useBreakpoint();
  const dispatch = useAppDispatch();

  const defaultUserNotification = props.notificationSubscriptions?.filter(
    (n) => n.NotifyValue === props.defaultUserEmail
  )[0];

  const filteredNotifications = props.notificationSubscriptions?.filter(
    (n) => n.NotifyValue !== props.defaultUserEmail
  );

  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState<boolean>(false);
  const [currentUpdateNotification, setCurrentUpdateNotification] = useState<OrderConfirmationNotification | undefined>(
    undefined
  );
  const [currentDeleteNotification, setCurrentDeleteNotification] = useState<OrderConfirmationNotification | undefined>(
    undefined
  );
  const [checkedItem, setCheckedItem] = useState<CheckboxItem>({
    label: (
      <Typography
        variant='responsiveParagraphSmall'
        sx={{ color: (theme: Theme) => theme.palette.coolGrey[600] }}
        data-testid='communications-email-list-checkbox-text'
      >
        I want to receive all order confirmations for this location
      </Typography>
    ),
    checked: !!defaultUserNotification,
    name: 'defaultEmailsCheckbox',
    disabled: false,
  });

  useEffect(() => {
    setCheckedItem({ ...checkedItem, checked: !!defaultUserNotification });
  }, [defaultUserNotification]);

  const onCheckedChanged = (item: CheckboxItem) => {
    if (item.checked) {
      dispatch(addOrderConfirmationNotification(props.selectedCustomer.CustomerId, props.defaultUserEmail));
    } else if (!item.checked && defaultUserNotification?.NotifyValue) {
      dispatch(
        deleteOrderConfirmationNotification(
          defaultUserNotification.OrderConfirmationNotificationId,
          defaultUserNotification.NotifyValue,
          props.selectedCustomer.CustomerId
        )
      );
    }
    setCheckedItem({ ...checkedItem, checked: item.checked });
  };

  const debouncedOnCheckedChanged = debounce(300, onCheckedChanged);

  const handleAddDialogClick = () => {
    setAddDialogOpen(true);
  };
  const handleDeleteDialogClick = (notification: OrderConfirmationNotification) => () => {
    setCurrentDeleteNotification(notification);
    setDeleteDialogOpen(true);
  };
  const handleUpdateDialogClick = (notification: OrderConfirmationNotification) => () => {
    setCurrentUpdateNotification(notification);
    setUpdateDialogOpen(true);
  };

  return (
    <Stack direction='column' spacing={1} sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
      <Box
        sx={{
          width: '100%',
          backgroundColor: (theme: Theme) => theme.palette.coolGrey[100],
          p: 2,
          borderRadius: '4px',
        }}
      >
        <Stack direction='column' spacing={2} sx={{ width: '100%' }}>
          <CfCheckbox
            item={checkedItem}
            size={deviceType === DeviceType.Desktop ? 'small' : 'medium'}
            onCheckedChanged={debouncedOnCheckedChanged}
            data-testid='communications-email-list-checkbox'
          />
          <Stack direction='column' spacing={1}>
            {defaultUserNotification && checkedItem.checked && (
              <Stack
                direction='row'
                justifyContent='space-between'
                key={defaultUserNotification.OrderConfirmationNotificationId}
              >
                <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <Typography
                    variant='responsiveParagraphSmall'
                    sx={{ color: (theme: Theme) => theme.palette.coolGrey[600] }}
                  >
                    {defaultUserNotification.NotifyValue}
                  </Typography>
                </Box>
              </Stack>
            )}
            {filteredNotifications?.map((n: OrderConfirmationNotification) => (
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                key={n.OrderConfirmationNotificationId}
                sx={{
                  height: (theme) => ({ xs: theme.spacing(2.5), xl: theme.spacing(2) }),
                }}
              >
                <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <Typography
                    variant='responsiveParagraphSmall'
                    noWrap
                    sx={{
                      color: (theme: Theme) => theme.palette.coolGrey[600],
                      maxWidth: (theme) => ({ xs: theme.spacing(23), lg: theme.spacing(28), xl: theme.spacing(23) }),
                    }}
                    data-testid='communications-email-list-notification-value'
                  >
                    {n.NotifyValue}
                  </Typography>
                </Box>
                <Stack
                  direction='row'
                  sx={{
                    mb: { xs: 0, lg: 1, xl: 0 },
                  }}
                >
                  <EmailActions
                    notificationId={n.OrderConfirmationNotificationId}
                    onClickEditIcon={handleUpdateDialogClick(n)}
                    onClickDeleteIcon={handleDeleteDialogClick(n)}
                  />
                </Stack>
              </Stack>
            ))}
            <Box justifyContent='flex-start'>
              <IconButton onClick={handleAddDialogClick} sx={{ p: 0 }}>
                <Icon
                  className='fa-plus'
                  fontSize='small'
                  color='primary'
                  data-testid='communications-email-list-notification-add-new-icon'
                />
                <Typography
                  sx={{ pl: 1, typography: 'responsiveParagraphSmallBold', fontSize: { xs: 14, xl: 12 } }}
                  color='primary'
                  data-testid='communications-email-list-notification-add-new-text'
                >
                  Add new
                </Typography>
              </IconButton>
            </Box>
          </Stack>
        </Stack>
      </Box>
      <CommunicationsEmailDialog
        isOpen={addDialogOpen}
        label='Add email address'
        selectedCustomer={props.selectedCustomer}
        title='Add new email address'
        onClose={() => setAddDialogOpen(false)}
        onSubmit={(value) => {
          if (props.selectedCustomer && value) {
            dispatch(addOrderConfirmationNotification(props.selectedCustomer.CustomerId, value));
          }
        }}
      />
      <CommunicationsEmailDialog
        defaultValue={currentUpdateNotification?.NotifyValue}
        isOpen={updateDialogOpen}
        label='Edit email address'
        selectedCustomer={props.selectedCustomer}
        title='Edit email address'
        onClose={() => setUpdateDialogOpen(false)}
        onSubmit={(value) => {
          if (props.selectedCustomer && currentUpdateNotification?.OrderConfirmationNotificationId && value) {
            dispatch(
              updateOrderConfirmationNotification(
                currentUpdateNotification?.OrderConfirmationNotificationId,
                props.selectedCustomer.CustomerId,
                value
              )
            );
          }
          setUpdateDialogOpen(false);
        }}
      />
      <CommunicationsDeleteEmailDialog
        isOpen={deleteDialogOpen}
        notification={currentDeleteNotification}
        selectedCustomer={props.selectedCustomer}
        title='Delete email'
        onClose={() => setDeleteDialogOpen(false)}
      />
    </Stack>
  );
};

export default CommunicationsEmailBody;
