import { Box, IconButton, Popper } from '@mui/material/';
import { styled } from '@mui/material/styles';

export const StyledDatePickerBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  '& .react-datepicker__day--highlighted:hover': {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: '4px',
    color: theme.palette.text.primary,
  },
  '& .react-datepicker__day--selected:hover': {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '4px',
    color: theme.palette.text.secondary,
  },
  '& .react-datepicker__day--disabled': {
    color: theme.palette.coolGrey[300],
    backgroundColor: 'transparent !important',
  },
  '& .react-datepicker__day--selected.react-datepicker__day--disabled': {
    fontWeight: 'normal',
    color: theme.palette.coolGrey[300],
  },
  '& .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before':
    {
      borderColor: theme.palette.primary.main,
    },
  '& .react-datepicker__navigation:hover *::before': {
    borderColor: theme.palette.primary.main,
  },

  // Popper Styles
  '& .react-datepicker-popper': {
    zIndex: 2000,
  },
  '& .react-datepicker-popper[data-placement^=bottom]': {
    paddingTop: 0,
  },
  // Container Styles
  '& .react-datepicker': {
    border: 'none',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
    fontFamily: theme.typography.fontFamily,
  },
  '& .react-datepicker__month-container': {
    // paddingTop: theme.spacing(3),
    // paddingLeft: theme.spacing(3),
    // paddingRight: theme.spacing(3),
    // paddingBottom: theme.spacing(2),
    //height: '282px',
    //width: '246px',
  },

  // Header Styles
  '& .react-datepicker__header': {
    backgroundColor: theme.palette.common.white,
    border: 'none',
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
  },
  '& .react-datepicker__day-names': {
    textTransform: 'uppercase',
  },
  '& .react-datepicker__day-name': {
    ...theme.typography.paragraphSmall,
    color: theme.palette.coolGrey[500],
    height: '26px',
    width: '26px',
    margin: 'unset',
  },
  '& .react-datepicker__month': {
    width: 182,
    margin: 'unset',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  // Date Styles
  '& .react-datepicker__day': {
    ...theme.typography.paragraphSmall,
    height: '26px',
    width: '26px',
    lineHeight: '26px',
    margin: 'unset',
    //color: theme.palette.coolGrey[300],
  },
  '& .react-datepicker__day--outside-month': {
    visibility: 'hidden',
  },
  '& .react-datepicker__day--highlighted': {
    backgroundColor: 'initial',
    color: theme.palette.text.primary,
  },
  '& .react-datepicker__day--selected': {
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    outline: 'none',
  },
  '& .react-datepicker__day--keyboard-selected': {
    outline: 'none',
    background: 'transparent',
    color: theme.palette.coolGrey[300],
    '&:active,&:focus': {
      fontWeight: 'bold',
      backgroundColor: theme.palette.coolGrey[100],
      color: theme.palette.common.black,
    },
    '&:focus': {
      fontWeight: 'bold',
      color: theme.palette.coolGrey[300],
    },
  },
  // DateRange Styles
  '& .react-datepicker__day--in-range': {
    backgroundColor: theme.palette.coolGrey[200],
    color: theme.palette.common.black,
    outline: 'none',
    borderRadius: '0px 0px 0px 0px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
  },
  '& .react-datepicker__day--range-start': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    outline: 'none',
    borderRadius: '4px 0px 0px 4px',
  },
  '& .react-datepicker__day--range-end': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    outline: 'none',
    borderRadius: '0px 4px 4px 0px',
  },
  '& .react-datepicker__day--range-start.react-datepicker__day--range-end': {
    borderRadius: '4px 4px 4px 4px',
  },

  '& .react-datepicker__day--in-selecting-range': {
    backgroundColor: `${theme.palette.coolGrey[200]} !important`,
    color: theme.palette.common.black,
    outline: 'none',
    borderRadius: '0px 0px 0px 0px',
    fontWeight: 'bold',
  },
  '& .react-datepicker__day--selecting-range-start': {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: theme.palette.common.white,
    outline: 'none',
    borderRadius: '4px 0px 0px 4px',
  },
  '& .react-datepicker__day:hover.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selected)': {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: theme.palette.common.white,
    borderRadius: '0px 4px 4px 0px',
  },
}));

export const StyledIconButton = styled(IconButton)({
  //position: 'absolute',
  padding: 0,
});

export const StyledMonthPickerBox = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  display: 'flex',
  maxWidth: 182,
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
}));

export const StyledCalendarPopper = styled(Popper)(({ theme }) => ({
  zIndex: 2000,
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.coolGrey[200]}`,
  borderRadius: '8px',
  boxSizing: 'border-box',
  p: 0,
}));

export const StyledCalendarBox = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(2),
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.coolGrey[200]}`,
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));
