import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { CustomerGroupBasic } from '../../api/models/customer-groups.models';
import { getSortComparer } from '../../helpers';
import { normalizeKey } from '../../helpers/general/string';
import { RootState } from '../store';

const customerGroupAdapter = createEntityAdapter<CustomerGroupBasic>({
  selectId: (customerGroups: CustomerGroupBasic) => normalizeKey(customerGroups.CustomerGroupHeaderId),
  sortComparer: getSortComparer((customerGroups) => customerGroups.CustomerGroupName),
});

interface CustomerGroupState {
  customerGroups: EntityState<CustomerGroupBasic>;
  customerGroupsLoading: boolean;
  customerGroupsLoaded: boolean;
  customerGroupsUnassignedLocations: string[];
}

const initialState: CustomerGroupState = {
  customerGroups: customerGroupAdapter.getInitialState(),
  customerGroupsLoading: false,
  customerGroupsLoaded: false,
  customerGroupsUnassignedLocations: [],
};

export const customerGroupsSlice = createSlice({
  name: 'customerGroups',
  initialState: initialState,
  reducers: {
    resetState: () => initialState,
    setCustomerGroups: (state: CustomerGroupState, action: PayloadAction<CustomerGroupBasic[]>) => {
      customerGroupAdapter.setAll(state.customerGroups, action.payload);
      state.customerGroupsLoading = false;
    },
    resetCustomerGroups: (state: CustomerGroupState) => {
      state.customerGroups = initialState.customerGroups;
      state.customerGroupsLoading = false;
      state.customerGroupsLoaded = false;
    },
    removeCustomerGroup: (state: CustomerGroupState, action: PayloadAction<string>) => {
      customerGroupAdapter.removeOne(state.customerGroups, action.payload);
    },
    setCustomerGroupsLoading: (state: CustomerGroupState, action: PayloadAction<boolean>) => {
      state.customerGroupsLoading = action.payload;
    },
    setCustomerGroupsLoaded: (state: CustomerGroupState, action: PayloadAction<boolean>) => {
      state.customerGroupsLoaded = action.payload;
    },
    setCustomerGroupUnassignedLocations: (state: CustomerGroupState, action: PayloadAction<string[]>) => {
      state.customerGroupsUnassignedLocations = action.payload;
    },
    resetCustomerGroupUnassignedLocations: (state: CustomerGroupState) => {
      state.customerGroupsUnassignedLocations = initialState.customerGroupsUnassignedLocations;
    },
  },
});

export const {
  selectAll: selectAllCustomerGroups,
  selectById: selectCustomerGroupById,
  selectIds: selectAllCustomerGroupHeaderIds,
} = customerGroupAdapter.getSelectors<RootState>((state: RootState) => state.customerGroups.customerGroups);
