import { Grid, Skeleton } from '@mui/material';
import {
  GetActiveOrderResult,
  OrderCart,
  RootState,
  RouteName,
  getRouteName,
  selectUserPermissionSetting,
  toCurrencyString,
  useAppSelector,
} from 'common';
import { FC } from 'react';
import { useBreakpoint } from '../../../../../../../../hooks/useBreakpoint';
import { OrderSummaryActionButtons } from '../../../../../../../orders/components/OrderSummary/components';
import { StyledEmptyCartMessage, StyledEstimatedCostLabel, StyledExtendedItemPrice } from './CartContent.styles';
import { CartCountdown } from './components/CartCountdown/CartCountdown';
import CartProducts from './components/CartProducts/CartProducts';

export interface CartContentProps {
  activeOrder: GetActiveOrderResult | undefined;
  cartData: OrderCart | undefined;
  isLoading: boolean;
  handleClickDelete?: () => void;
  firstButtonRef?: React.RefObject<HTMLButtonElement>;
  lastButtonRef?: React.RefObject<HTMLButtonElement>;
  onFirstButtonKeyDown?: React.KeyboardEventHandler<HTMLElement>;
  onLastButtonKeyDown?: React.KeyboardEventHandler<HTMLElement>;
  closeShoppingCart: () => void;
}

export const CartContent: FC<CartContentProps> = (props: CartContentProps) => {
  const { breakpoint } = useBreakpoint();
  const canViewPrice = useAppSelector((s: RootState) => selectUserPermissionSetting(s).CanViewPrices);
  const { activeOrder, cartData, isLoading, handleClickDelete } = {
    ...props,
  };

  const routeName = getRouteName();
  const mode = routeName === RouteName.OrderReview ? 'review' : 'entry';

  return (
    <>
      {activeOrder?.OrderEntryHeaderId ? (
        <>
          <CartCountdown />

          {canViewPrice && (
            <Grid container alignItems='center'>
              <Grid item xs={6} justifyContent='flex-start'>
                <StyledEstimatedCostLabel data-testid='cart-estimated-cost-label'>
                  Estimated cost
                </StyledEstimatedCostLabel>
              </Grid>
              {isLoading ? (
                <Grid item xs={6} display='flex' alignItems='center' justifyContent='right'>
                  <Skeleton>
                    <StyledExtendedItemPrice data-testid='cart-estimated-cost'>
                      {toCurrencyString(1000.0, 2)}
                    </StyledExtendedItemPrice>
                  </Skeleton>
                </Grid>
              ) : (
                <Grid item xs={6} justifyContent='flex-end'>
                  <StyledExtendedItemPrice data-testid='cart-estimated-cost'>
                    {toCurrencyString(cartData?.EstimatedCost, 2)}
                  </StyledExtendedItemPrice>
                </Grid>
              )}
            </Grid>
          )}

          <OrderSummaryActionButtons
            mode={mode}
            size={breakpoint === 'xl' ? 'extraSmall' : 'small'}
            showContinueShoppingButton={true}
            onNavigateToOrderEntry={props.closeShoppingCart}
            onNavigateToOrderReview={props.closeShoppingCart}
            onNavigateToOrderConfirmation={props.closeShoppingCart}
            sx={{ mt: 1 }}
          />
          {isLoading ? (
            <CartProducts loadingPlaceholder />
          ) : (
            <CartProducts
              items={cartData ? cartData.RecentProducts.slice(0, 5) : []}
              onClickDelete={handleClickDelete}
              {...(cartData?.RecentProducts.length && {
                lastButtonRef: props.lastButtonRef,
                onLastButtonKeyDown: props.onLastButtonKeyDown,
              })}
              data-testid='cart-item-listing'
            />
          )}
        </>
      ) : (
        <Grid container alignItems='center'>
          <Grid item xs justifyContent='flex-start'>
            <StyledEmptyCartMessage data-testid='cart-empty-cart-messaging'>
              Cart is currently empty
            </StyledEmptyCartMessage>
          </Grid>
        </Grid>
      )}
    </>
  );
};
