import { Breakpoint, TypographyPropsVariantOverrides } from '@mui/material';
import { PaletteColor, PaletteColorOptions, PaletteOptions, Theme, createTheme } from '@mui/material/styles';
import { Variant } from '@mui/material/styles/createTypography';
import { OverridableStringUnion } from '@mui/types';
import { BusinessUnitKeyType } from 'common';
import React from 'react';
import pfgLogo_Light from '../assets/images/pfgLogo_Light.png';
import pfgLogo_Dark from '../assets/images/pfglogo_Dark.png';
import vistarLogo_Dark from '../assets/images/vistarLogo_Dark.png';
import vistarLogo_Light from '../assets/images/vistarLogo_Light.png';
import { getResponsiveTypographyRules, getThemeBase } from './base.theme';
import { getMuiThemeOptions } from './mui.theme';
import { getPalettePFS, getPaletteVistar } from './palettes';

export type StickyComponentName = 'site-header' | 'page-notification' | 'breadcrumb';
export type StickyComponentHeights = {
  [key in Breakpoint]: number;
};

export enum cfFreezeNames {
  header = 'cf-header',
  subheader = 'cf-subheader',
  breadcrumb = 'cf-breadcrumb',
  pageHeader = 'cf-page-header',
  contentHeader = 'cf-content-header',
  aside = 'cf-aside',
  tableHeader = 'cf-table-header',
  footer = 'cf-footer',
}

export type HeaderHeights = { [key in Breakpoint]: number };
export type GetStartedHeaderHeights = { [key in Breakpoint]: number };
export type FooterHeights = { [key in Breakpoint]: number };
export type PageNotificationHeights = { [key in Breakpoint]: number };
export type ContentWidths = { [key in Breakpoint]: number };
export type PageWidths = { [key in Breakpoint]: number };

export type ResponsiveTypographyVariant =
  | 'responsiveParagraph'
  | 'responsiveParagraphBold'
  | 'responsiveParagraphSmall'
  | 'responsiveParagraphSmallBold';

export type NonResponsiveTypographyVariant = OverridableStringUnion<
  Variant,
  Omit<TypographyPropsVariantOverrides, ResponsiveTypographyVariant>
>;
export type LineHeights = {
  [key in NonResponsiveTypographyVariant]: number;
};
export interface CustomThemeOptions {
  headerHeights: HeaderHeights;
  getStartedHeaderHeights: GetStartedHeaderHeights;
  pageNotificationHeights: PageNotificationHeights;
  footerHeights: FooterHeights;
  contentWidths: ContentWidths;
  pageWidths: PageWidths;
  containedButtonBorderWidth: number;
  outlinedButtonBorderWidth: number;
  lineHeights: LineHeights;
  spacing: number;
  sticky: {
    zIndex: {
      [key in cfFreezeNames]: number;
    };
    getTotalHeightOfComponents: (freezeNames?: (cfFreezeNames | string)[]) => number;
  };
}

// Utilize TypeScript Module Augmentation to Augment Custom Theme Colors
declare module '@mui/material/styles' {
  interface Theme {
    custom: CustomThemeOptions;
  }
  interface ThemeOptions {
    custom: CustomThemeOptions;
  }
  interface Palette {
    coolGrey: Palette['grey'];
    tertiary: PaletteColor;
    userAcceptanceBackground: string;
  }
  interface PaletteOptions {
    coolGrey: PaletteOptions['grey'];
    tertiary?: PaletteColorOptions;
    userAcceptanceBackground: string;
  }
  //https://mui.com/customization/typography/#adding-amp-disabling-variants
  interface TypographyVariants {
    paragraph: React.CSSProperties;
    paragraphBold: React.CSSProperties;
    paragraphLink: React.CSSProperties;
    paragraphLarge: React.CSSProperties;
    paragraphSmall: React.CSSProperties;
    paragraphSmallBold: React.CSSProperties;
    paragraphSmallLink: React.CSSProperties;
    paragraphHeaderBold: React.CSSProperties;
    numberedList: React.CSSProperties;

    mobileParagraph: React.CSSProperties;
    mobileParagraphBold: React.CSSProperties;
    mobileParagraphExtraSmall: React.CSSProperties;
    mobileParagraphExtraSmallBold: React.CSSProperties;
    mobileParagraphSmall: React.CSSProperties;
    mobileParagraphSmallBold: React.CSSProperties;
    mobileLabel: React.CSSProperties;

    responsiveParagraph: React.CSSProperties;
    responsiveParagraphBold: React.CSSProperties;
    responsiveParagraphSmall: React.CSSProperties;
    responsiveParagraphSmallBold: React.CSSProperties;

    iconExtraSmall: React.CSSProperties;
    iconSmall: React.CSSProperties;
    iconMedium: React.CSSProperties;
    iconLarge: React.CSSProperties;

    label: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    paragraph: React.CSSProperties;
    paragraphBold: React.CSSProperties;
    paragraphLink: React.CSSProperties;
    paragraphLarge: React.CSSProperties;
    paragraphSmall: React.CSSProperties;
    paragraphSmallBold: React.CSSProperties;
    paragraphSmallLink: React.CSSProperties;
    paragraphHeaderBold: React.CSSProperties;
    numberedList: React.CSSProperties;

    mobileParagraph: React.CSSProperties;
    mobileParagraphBold: React.CSSProperties;
    mobileParagraphExtraSmall: React.CSSProperties;
    mobileParagraphExtraSmallBold: React.CSSProperties;
    mobileParagraphSmall: React.CSSProperties;
    mobileParagraphSmallBold: React.CSSProperties;
    mobileLabel: React.CSSProperties;

    responsiveParagraph: React.CSSProperties;
    responsiveParagraphBold: React.CSSProperties;
    responsiveParagraphSmall: React.CSSProperties;
    responsiveParagraphSmallBold: React.CSSProperties;

    iconExtraSmall: React.CSSProperties;
    iconSmall: React.CSSProperties;
    iconMedium: React.CSSProperties;
    iconLarge: React.CSSProperties;

    label: React.CSSProperties;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
  interface ButtonPropsSizeOverrides {
    extraSmall: true;
    extraLarge: true;
  }
  interface ToggleButtonPropsSizeOverrides {
    extraSmall: true;
    extraLarge: true;
  }
}
declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    tertiary: true;
  }
}
declare module '@mui/material/Icon' {
  interface IconPropsSizeOverrides {
    extraSmall: true;
  }
}

declare module '@mui/material/InputBase' {
  interface InputBasePropsSizeOverrides {
    extraSmall: true;
    large: true;
    extraLarge: true;
  }
}
declare module '@mui/material/TextField' {
  interface TextFieldPropsSizeOverrides {
    extraSmall: true;
    large: true;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body1: false;
    body2: false;
    button: false;
    caption: false;
    overline: false;
    subtitle1: false;
    subtitle2: false;

    paragraph: true;
    paragraphBold: true;
    paragraphLink: true;
    paragraphLarge: true;
    paragraphSmall: true;
    paragraphSmallBold: true;
    paragraphSmallLink: true;
    paragraphHeaderBold: true;
    numberedList: true;

    mobileParagraph: true;
    mobileParagraphBold: true;
    mobileParagraphSmall: true;
    mobileParagraphSmallBold: true;
    mobileParagraphExtraSmall: true;
    mobileParagraphExtraSmallBold: true;
    mobileLabel: true;

    responsiveParagraph: true;
    responsiveParagraphBold: true;
    responsiveParagraphSmall: true;
    responsiveParagraphSmallBold: true;

    iconExtraSmall: true;
    iconSmall: true;
    iconMedium: true;
    iconLarge: true;

    label: true;
  }
}

const initializeResponsiveTypographyVariants = (theme: Theme): Theme => {
  const rules = getResponsiveTypographyRules(theme);
  return {
    ...theme,
    typography: {
      ...theme.typography,
      ...rules,
    },
    components: {
      ...theme.components,
      MuiBadge: {
        defaultProps: {
          color: 'error',
        },
        styleOverrides: {
          dot: {
            height: 5,
            width: 5,
            minHeight: 5,
            minWidth: 5,
          },

          standard: {
            [theme.breakpoints.up('xs')]: {
              ...theme.typography.mobileParagraphSmallBold,
              width: 'unset',
              minWidth: '18px',
              maxWidth: 'unset',
              height: '18px',
              minHeight: '18px',
              maxHeight: '18px',
            },
            [theme.breakpoints.up('xl')]: {
              ...theme.typography.paragraphSmallBold,
              width: 'unset',
              minWidth: '16px',
              maxWidth: 'unset',
              height: '16px',
              minHeight: '16px',
              maxHeight: '16px',
            },
            padding: 'unset',
            paddingLeft: '2px',
            paddingRight: '2px',
            border: '2px solid',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
          },
        },
      },
      MuiTab: {
        ...theme.components?.MuiTab,
        styleOverrides: {
          ...theme.components?.MuiTab?.styleOverrides,
          root: {
            '&.MuiButtonBase-root': {
              ...rules.responsiveParagraph,
              paddingBottom: '4px',
              paddingTop: '4px',
              paddingLeft: '0px',
              paddingRight: '0px',
              minWidth: 'unset',
              minHeight: 'unset',
              maxWidth: 'unset',
              maxHeight: 'unset',
              marginRight: '18px',
              textTransform: 'none',
              color: theme.palette.coolGrey[900],
              '&.Mui-selected': {
                ...rules.responsiveParagraphBold,
              },
              '&.Mui-disabled': {
                color: theme.palette.grey[400],
              },
            },
          },
        },
      },
    },
  };
};

export const getTheme = (businessUnit: BusinessUnitKeyType): Theme => {
  let getPalette: () => PaletteOptions = getPalettePFS;
  if (businessUnit === BusinessUnitKeyType.Vistar) getPalette = getPaletteVistar;
  const palette = getPalette();
  const themeBase = getThemeBase(palette);
  const muiThemeOptions = getMuiThemeOptions(themeBase);
  return initializeResponsiveTypographyVariants(createTheme(muiThemeOptions));
};

export const getLandingPageTheme = (businessUnit: BusinessUnitKeyType): Theme => {
  const theme = getTheme(businessUnit);
  return {
    ...theme,
    typography: {
      ...theme.typography,
      h1: {
        ...theme.typography.h1,
        [theme.breakpoints.up('xl')]: {
          fontSize: 42,
          lineHeight: 1,
        },
      },

      responsiveParagraph: {
        ...theme.typography.responsiveParagraph,
        [theme.breakpoints.up('xl')]: { fontSize: 18, lineHeight: 1.28 },
      },
    },
  };
};

export enum LogoVariationType {
  Light,
  Dark,
}

export const getLogo = (businessUnit: BusinessUnitKeyType, logoVariationType: LogoVariationType): string => {
  switch (businessUnit) {
    case BusinessUnitKeyType.PerformanceFoodService:
      return logoVariationType === LogoVariationType.Light ? pfgLogo_Light : pfgLogo_Dark;
    case BusinessUnitKeyType.Vistar:
      return logoVariationType === LogoVariationType.Light ? vistarLogo_Light : vistarLogo_Dark;
    default:
      return logoVariationType === LogoVariationType.Light ? pfgLogo_Light : pfgLogo_Dark;
  }
};
