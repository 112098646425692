import { Box, Icon } from '@mui/material/';
import { styled } from '@mui/material/styles';
import { CfRadioSize } from './CfRadio';

interface RadioIconProps {
  isDisabled: boolean;
  size?: CfRadioSize;
}

export const StyledIcon = styled(Icon)({
  '&.MuiIcon-root': {
    height: 0,
    fontSize: 'unset !important',
  },
});

export const StyledDot = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isDisabled' && prop !== 'size',
})<RadioIconProps>(({ theme, isDisabled, size }) => ({
  position: 'absolute',
  margin: theme.spacing(0.25),
  borderRadius: '50%',
  ...(size === 'medium'
    ? {
        height: theme.spacing(1.5),
        width: theme.spacing(1.5),
        transform: 'translate(2px, 2px)',
      }
    : {
        height: theme.spacing(1),
        width: theme.spacing(1),
      }),
  ...(isDisabled
    ? {
        backgroundColor: theme.palette.coolGrey[300],
      }
    : {
        backgroundColor: theme.palette.primary.main,
      }),
}));

export const StyledPrimaryCircle = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isDisabled' && prop !== 'size',
})<RadioIconProps>(({ theme, isDisabled, size }) => ({
  position: 'absolute',
  borderRadius: '50%',
  ...(size === 'medium'
    ? {
        height: theme.spacing(3),
        width: theme.spacing(3),
      }
    : {
        height: theme.spacing(2),
        width: theme.spacing(2),
      }),
  ...(isDisabled
    ? {
        backgroundColor: theme.palette.coolGrey[100],
        border: `2px solid ${theme.palette.coolGrey[300]} !important`,
      }
    : {
        backgroundColor: theme.palette.common.white,
        border: `2px solid ${theme.palette.primary.main}`,
      }),
}));

export const StyledSecondaryCircle = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isDisabled' && prop !== 'size',
})<RadioIconProps>(({ theme, isDisabled, size }) => ({
  position: 'absolute',
  borderRadius: '50%',
  ...(size === 'medium'
    ? {
        height: theme.spacing(3),
        width: theme.spacing(3),
      }
    : {
        height: theme.spacing(2),
        width: theme.spacing(2),
      }),
  ...(isDisabled
    ? {
        backgroundColor: theme.palette.coolGrey[100],
        border: `2px solid ${theme.palette.coolGrey[300]}`,
      }
    : {
        backgroundColor: theme.palette.common.white,
        border: `2px solid ${theme.palette.action.focus}`,
      }),
}));

export const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size',
})<{ size: CfRadioSize }>(({ theme, size }) => ({
  position: 'relative',
  ...(size === 'medium'
    ? {
        height: theme.spacing(3),
        width: theme.spacing(3),
      }
    : {
        height: theme.spacing(2),
        width: theme.spacing(2),
      }),
}));
