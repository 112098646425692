import {
  CreateMessageGroupRequest,
  CreateMessageGroupResult,
  DeleteMessageGroupRequest,
  GetMessageGroupRequest,
  MessageGroup,
  MessageGroupBasic,
  UpdateMessageGroupRequest,
  UpdateMessageGroupResult,
} from '../models';
import { ServiceResult } from '../models/api-shared.models';
import ApiService from './api-service';

class MessageGroupService extends ApiService {
  private static classInstance: MessageGroupService;

  private constructor() {
    super('MessageGroup');
  }

  public getMessageGroups(): ServiceResult<MessageGroupBasic[]> {
    return this.get(`${this.baseUrl}/GetMessageGroups`);
  }

  public getMessageGroup(request: GetMessageGroupRequest): ServiceResult<MessageGroup> {
    return this.get(`${this.baseUrl}/GetMessageGroup`, request);
  }

  public createMessageGroup(request: CreateMessageGroupRequest): ServiceResult<CreateMessageGroupResult> {
    return this.post(`${this.baseUrl}/CreateMessageGroup`, request);
  }

  public updateMessageGroup(request: UpdateMessageGroupRequest): ServiceResult<UpdateMessageGroupResult> {
    return this.post(`${this.baseUrl}/UpdateMessageGroup`, request);
  }

  public deleteMessageGroup(request: DeleteMessageGroupRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/DeleteMessageGroup`, request);
  }

  public static getInstance(): MessageGroupService {
    if (!this.classInstance) {
      this.classInstance = new MessageGroupService();
    }
    return this.classInstance;
  }
}

export default MessageGroupService;
