import { ServiceResult } from '../models/api-shared.models';
import { DeleteSearchHistoryRequest, TypeAheadRequest, TypeAheadResponse } from '../models/product-catalog.models';
import ApiService from './api-service';

class TypeaheadService extends ApiService {
  private static classInstance: TypeaheadService;

  private constructor() {
    super('TypeAhead');
  }

  public deleteSearchHistory(request: DeleteSearchHistoryRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/DeleteSearchHistory`, request);
  }

  public getTypeAheadSearchResults(request: TypeAheadRequest): ServiceResult<TypeAheadResponse> {
    return this.get(`${this.baseUrl}/GetTypeAhead`, request);
  }

  public static getInstance(): TypeaheadService {
    if (!this.classInstance) {
      this.classInstance = new TypeaheadService();
    }
    return this.classInstance;
  }
}

export default TypeaheadService;
