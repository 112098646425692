import { AppBar, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { cfFreezeNames } from '../../../../theme/theme';

export const StyledHeaderSpacer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    paddingTop: 128,
  },
  [theme.breakpoints.up('xl')]: {
    paddingTop: 98,
  },
}));

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  justifyContent: 'space-between',
  flexDirection: 'row',
  boxShadow: 'none',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',

  marginRight: 'auto',
  marginLeft: 'auto',

  wrap: 'nowrap',

  paddingRight: '0px !important', // Fixes drawer header contents - MUI dialog adds padding

  [theme.breakpoints.up('xs')]: {
    width: 'calc(100% - 24px)',
  },
  [theme.breakpoints.up('xl')]: {
    width: theme.custom.contentWidths['xl'],
    maxWidth: theme.custom.pageWidths['xl'],
  },
}));

export const StyledHeaderContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: theme.custom.sticky.zIndex[cfFreezeNames.header],

  display: 'flex',
  justifyContent: 'center',
  width: '100vw',
  paddingRight: '0px !important', // Fixes drawer header contents - MUI drawer adds padding
  backgroundColor: theme.palette.common.white,
  boxShadow: '0px 1px 0px #E5E5E5',

  [theme.breakpoints.up('xs')]: {
    maxHeight: theme.custom.headerHeights['xs'],
    height: theme.custom.headerHeights['xs'],
  },
  [theme.breakpoints.up('sm')]: {
    maxHeight: theme.custom.headerHeights['sm'],
    height: theme.custom.headerHeights['sm'],
  },
  [theme.breakpoints.up('md')]: {
    maxHeight: theme.custom.headerHeights['md'],
    height: theme.custom.headerHeights['md'],
  },
  [theme.breakpoints.up('lg')]: {
    maxHeight: theme.custom.headerHeights['lg'],
    height: theme.custom.headerHeights['lg'],
  },
  [theme.breakpoints.up('xl')]: {
    maxHeight: theme.custom.headerHeights['xl'],
    height: theme.custom.headerHeights['xl'],
  },
}));

export const StyledSubHeaderContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  left: 0,
  right: 0,
  zIndex: theme.custom.sticky.zIndex[cfFreezeNames.header] - 2,

  display: 'flex',
  justifyContent: 'center',
  width: '100vw',
  backgroundColor: theme.palette.common.white,
  boxShadow: '0px 1px 0px #E5E5E5',

  [theme.breakpoints.up('xs')]: {
    top: 72,
    maxHeight: 56,
    height: 56,
  },
  [theme.breakpoints.up('sm')]: {
    top: 72,
    maxHeight: 56,
    height: 56,
  },
  [theme.breakpoints.up('md')]: {
    top: 72,
    maxHeight: 56,
    height: 56,
  },
  [theme.breakpoints.up('lg')]: {
    top: 54,
    maxHeight: 72,
    height: 72,
  },
  [theme.breakpoints.up('xl')]: {
    top: 34,
    maxHeight: 64,
    height: 64,
  },
}));

export const StyledGetStartedHeaderContainer = styled(StyledHeaderContainer)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    maxHeight: theme.custom.getStartedHeaderHeights['xs'],
    height: theme.custom.getStartedHeaderHeights['xs'],
  },
  [theme.breakpoints.up('sm')]: {
    maxHeight: theme.custom.getStartedHeaderHeights['sm'],
    height: theme.custom.getStartedHeaderHeights['sm'],
  },
  [theme.breakpoints.up('md')]: {
    maxHeight: theme.custom.getStartedHeaderHeights['md'],
    height: theme.custom.getStartedHeaderHeights['md'],
  },
  [theme.breakpoints.up('lg')]: {
    maxHeight: theme.custom.getStartedHeaderHeights['lg'],
    height: theme.custom.getStartedHeaderHeights['lg'],
  },
  [theme.breakpoints.up('xl')]: {
    maxHeight: theme.custom.getStartedHeaderHeights['xl'],
    height: theme.custom.getStartedHeaderHeights['xl'],
  },
}));

export const StyledGetStartedHeaderSpacer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    paddingTop: 68,
  },
  [theme.breakpoints.up('xl')]: {
    paddingTop: 80,
  },
}));
