import { SeverityLevel } from '@microsoft/applicationinsights-web';
import {
  GetParEntrySearchRequest,
  ParListHeader,
  ProductListSortByType,
  ProductListSortOption,
  ResetParListDetailsRequest,
  UnitOfMeasureType,
  UpdateParListDetailRequest,
  UpdateParListHeaderRequest,
} from '../../api';
import ParEntrySearchService from '../../api/services/par-entry-search.service';
import ParListDetailService from '../../api/services/par-list-detail.service';
import ParListHeaderService from '../../api/services/par-list-header.service';
import ProductListHeaderService from '../../api/services/product-list-header.service';
import ProductListSearchService from '../../api/services/product-list-search.service';
import { AnalyticsContext, getAnalyticsItemData } from '../../helpers';
import { useAppInsightsLogger } from '../../logging/index';
import { Resolution, UserActivityAction, UserActivityActionSummary, UserActivityPageName } from '../../models';
import { globalSlice, logUserActivity, setErrorDialogContent } from '../common/index';
import { AppDispatch, AppThunk, RootState } from '../store';
import { parMaintenanceSlice, selectParMaintenanceProductListGridDataByPage } from './par-maintenance.slice';

const appInsightsLogger = useAppInsightsLogger();
const parListHeaderService = ParListHeaderService.getInstance();
const productListHeaderService = ProductListHeaderService.getInstance();
const parEntrySearchService = ParEntrySearchService.getInstance();
const parListDetailService = ParListDetailService.getInstance();
const productListSearchService = ProductListSearchService.getInstance();

export const getParListHeader =
  (parHeaderId: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().parManagement.isLoadingParListHeaders)
        dispatch(parMaintenanceSlice.actions.setIsLoadingParListHeader(true));

      const { data } = await parListHeaderService.getParListHeader(parHeaderId);

      if (data.IsSuccess) {
        dispatch(parMaintenanceSlice.actions.setParListHeader(data.ResultObject));
      } else {
        dispatch(parMaintenanceSlice.actions.resetParListHeader());
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(parMaintenanceSlice.actions.setIsLoadingParListHeader(false));
    }
  };

export const resetParListHeader = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(parMaintenanceSlice.actions.resetParListHeader());
};

export const setParListHeader =
  (parListHeader: ParListHeader): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(parMaintenanceSlice.actions.setParListHeader(parListHeader));
  };

export const updateParMaintenanceHeader =
  (
    parListHeaderId: string,
    parGuideName: string,
    customerId: string,
    successCallback?: (parListHeader: ParListHeader) => void | Promise<void>,
    errorCallback?: () => void | Promise<void>
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().parMaintenance.isLoadingParListHeader) {
        dispatch(parMaintenanceSlice.actions.setIsLoadingParListHeader(true));
      }

      const request: UpdateParListHeaderRequest = {
        ParListTitle: parGuideName,
        ParListHeaderId: parListHeaderId,
        CustomerId: customerId,
      };

      const { data } = await parListHeaderService.updateParListHeader(request);

      if (data.IsSuccess) {
        dispatch(parMaintenanceSlice.actions.setParListHeader(data.ResultObject));
        successCallback?.(data.ResultObject);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        errorCallback?.();
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(parMaintenanceSlice.actions.setIsLoadingParListHeader(false));
    }
  };

export const getProductListParEntrySortBy =
  (productListHeaderId?: string, successCallback?: (sortByOptions: ProductListSortOption[]) => void): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(
        parMaintenanceSlice.actions.setPageLoading({
          pageIndex: 0,
          pageSize: 75,
          isLoading: true,
        })
      );

      const { data } = await productListSearchService.getProductListParEntrySort(productListHeaderId);

      if (data.IsSuccess) {
        dispatch(parMaintenanceSlice.actions.setSortByOptions(data.ResultObject));
        successCallback?.(data.ResultObject);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

export const resetProductListHeaderOptionsForPar = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(parMaintenanceSlice.actions.resetProductListHeadersLoading());
  dispatch(parMaintenanceSlice.actions.resetProductListHeaders());
};

export const getProductListHeaderOptionsForPar =
  (parId: string, successCallback: (productHeaderIds: string[]) => void): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const customerId = getState().customer.selectedCustomer?.CustomerId;
      if (!customerId) return;

      const apiRequest = {
        excludeReadOnlyLists: false,
        isForOrderEntry: true,
        customerId: customerId,
        parListHeaderId: parId,
      };

      dispatch(
        parMaintenanceSlice.actions.setPageLoading({
          pageIndex: 0,
          pageSize: 75,
          isLoading: true,
        })
      );

      const { data } = await productListHeaderService.getProductListHeaders(apiRequest);

      dispatch(parMaintenanceSlice.actions.setProductListHeaders(data.ResultObject));
      successCallback?.(data.ResultObject.map((header) => header.ProductListHeaderId));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

export const resetSearchProductListParMaintenance = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(parMaintenanceSlice.actions.resetSearchProductListParMaintenance());
  dispatch(parMaintenanceSlice.actions.setIsLoadingProductSearchResult(true));
  dispatch(parMaintenanceSlice.actions.resetCategories());
};

export const getProductListParSearch =
  (
    request: {
      parListHeaderId: string;
      productListHeaderId?: string;
      sortByType?: ProductListSortByType;
      filterText?: string;
      pageIndex?: number;
      itemsWithQuantityOnly?: boolean;
    },
    analyticsContext?: AnalyticsContext
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const productListHeaderId =
        request.productListHeaderId ?? getState().parMaintenance.parListHeader?.ParListHeaderId;
      const sortByType = request?.sortByType;
      const previousAPIRequest = getState().parMaintenance.apiRequest;

      const pageSize = 75;
      const pageIndex = request.pageIndex ?? 0;
      const skip =
        (request.pageIndex === undefined ||
        request.pageIndex === 0 ||
        (previousAPIRequest && request.itemsWithQuantityOnly !== previousAPIRequest.ItemsWithQuantityOnly)
          ? 0
          : getState().parMaintenance.productSearchResult?.Skip) ?? 0;
      const filterText = request.filterText;

      if (!request.parListHeaderId || !productListHeaderId || sortByType === undefined) {
        return;
      }

      const apiRequest: GetParEntrySearchRequest = {
        ParListHeaderId: request.parListHeaderId,
        ProductListHeaderId: productListHeaderId,
        QueryText: filterText ?? '',
        SortByType: sortByType.valueOf(),
        Skip: skip,
        PageSize: pageSize,
        ItemsWithQuantityOnly: request.itemsWithQuantityOnly ?? false,
      };

      // Data in store is for search with different parameters (excluding skip)
      if (
        JSON.stringify({ ...apiRequest, Skip: 0 }) !==
        JSON.stringify({ ...getState().parMaintenance.apiRequest, Skip: 0 })
      ) {
        dispatch(resetSearchProductListParMaintenance());
      }

      dispatch(parMaintenanceSlice.actions.setApiRequest(apiRequest));

      if (pageIndex > 0) {
        analyticsContext?.analytics?.event?.(analyticsContext.listId, 'load_more', request.parListHeaderId, pageIndex);
      }

      dispatch(
        parMaintenanceSlice.actions.setPageLoading({
          pageIndex: pageIndex,
          pageSize: 75,
          isLoading: true,
        })
      );

      const { data } = await parEntrySearchService.getParEntrySearch(apiRequest);

      if (data.IsSuccess) {
        data.ResultObject.pageSize = pageSize;
        data.ResultObject.pageIndex = pageIndex;
        const previousIndex = pageIndex - 1;
        const previousPage =
          previousIndex >= 0 ? selectParMaintenanceProductListGridDataByPage(getState(), previousIndex) : undefined;

        dispatch(
          parMaintenanceSlice.actions.searchProductListParMaintenance({
            request: apiRequest,
            result: data.ResultObject,
            previousPage,
          })
        );

        analyticsContext?.analytics?.viewItemList?.(
          data.ResultObject.ProductListCategories.flatMap((c, index) =>
            c.Products.flatMap((p) =>
              p.Product.UnitOfMeasureOrderQuantities.map((uom) =>
                getAnalyticsItemData(
                  p.Product.ProductKey ?? '',
                  uom.UnitOfMeasure.toString(),
                  uom.Price ?? 0,
                  uom.Quantity,
                  index,
                  uom,
                  p.Product,
                  analyticsContext.listId,
                  analyticsContext.listName
                )
              )
            )
          ),
          analyticsContext.listId,
          analyticsContext.listName
        );

        if (!data.ResultObject.HasLoadMore)
          analyticsContext?.analytics?.event?.(analyticsContext.listId, 'end_of_list', analyticsContext.listName);
      } else {
        dispatch(parMaintenanceSlice.actions.setApiRequest(undefined));
        dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      dispatch(parMaintenanceSlice.actions.setApiRequest(undefined));
    }
  };

export const resetAllParLevels =
  (resolution: Resolution, parListHeaderId?: string, customerId?: string, successCallback?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      if (!parListHeaderId || !customerId) return;

      dispatch(
        logUserActivity<{ ProductListHeaderId: string }>({
          action: UserActivityAction.ResetParLevels,
          pageName: UserActivityPageName.ParDetail,
          actionSummary: UserActivityActionSummary.ResetParLevels,
          resolution: resolution,
          additionalData: { ProductListHeaderId: parListHeaderId },
        })
      );

      const request: ResetParListDetailsRequest = { ParListHeaderId: parListHeaderId, CustomerId: customerId };

      const { data } = await parListHeaderService.resetParListDetails(request);

      if (data.IsSuccess) {
        dispatch(parMaintenanceSlice.actions.resetParDetails());
        dispatch(setIsShowCompletedToggled(false));
        successCallback?.();
      } else if (!data.IsSuccess) {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

export const updateParListDetail =
  (
    parListHeaderId: string,
    unitOfMeasureType: UnitOfMeasureType,
    quantity: number,
    customerId: string,
    productKey: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().parMaintenance.isParDetailsLoading)
        dispatch(parMaintenanceSlice.actions.setIsParDetailUpdating(true));

      const request: UpdateParListDetailRequest = {
        ParListHeaderId: parListHeaderId,
        UnitOfMeasureType: unitOfMeasureType,
        Quantity: quantity,
        CustomerId: customerId,
        ProductKey: productKey,
      };

      const { data } = await parListDetailService.updateParListDetail(request);

      if (data.IsSuccess) {
        dispatch(parMaintenanceSlice.actions.setParDetailUpdateResult(data.ResultObject));
      } else {
        dispatch(parMaintenanceSlice.actions.setParDetailUpdateResult(undefined));
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(parMaintenanceSlice.actions.setIsParDetailUpdating(false));
    }
  };

export const setIsShowCompletedToggled =
  (newValue: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(parMaintenanceSlice.actions.setIsShowCompletedToggled(newValue));
  };
