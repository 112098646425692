import { CustomerSupportType, OverviewSlideGroups } from '../../../api/models/customer-support.enums';
import { CustomerSupportTutorialItem } from '../../../models';

export const invoiceTutorials: CustomerSupportTutorialItem[] = [
  {
    tutorialTitle: 'Viewing and exporting your invoices',
    tutorialText: 'Learn how to view and export your invoices. ',
    overviewGroup: OverviewSlideGroups.Invoices,
    customerSupportType: CustomerSupportType.Invoices,
  },
  {
    tutorialTitle: 'A/P statements and payments',
    tutorialText:
      'View this tutorial to see how to view and download your A/P statements. ​If your account does not have online payment, please contact your Sales Account Representative to activate online payments.',
    overviewGroup: OverviewSlideGroups.ARStatements,
    customerSupportType: CustomerSupportType.Invoices,
  },
  {
    tutorialTitle: 'Invoice insights',
    tutorialText:
      'This is where you can build and modify reports. Watch and see how to generate reports and save templates to reuse in Invoice Insights.',
    overviewGroup: OverviewSlideGroups.Insights,
    customerSupportType: CustomerSupportType.Invoices,
  },
];
