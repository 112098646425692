export enum LocalStorageKeys {
  KEY_SELECTED_CUSTOMER_ID = 'SELECTED_CUSTOMER_ID',
  KEY_VALID_USER_EMAIL_ID = 'VALID_USER_EMAIL_ID',
  KEY_INVALID_USER_EMAIL_ID = 'INVALID_USER_EMAIL_ID',
}

export class LocalStorageService {
  public setItem = (key: LocalStorageKeys, value: string): void => {
    localStorage.setItem(key, value);
  };

  public getItem = (key: LocalStorageKeys): string | null => {
    return localStorage.getItem(key);
  };

  public removeItem = (key: LocalStorageKeys): void => {
    localStorage.removeItem(key);
  };

  public clear = (): void => {
    localStorage.clear();
  };
}
