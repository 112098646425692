import { BusinessUnitERPKeyType, BusinessUnitKeyType } from './api-shared.enums';

export interface Customer {
  CustomerId: string;
  BusinessUnitKey: BusinessUnitKeyType;
  BusinessUnitERPKey: BusinessUnitERPKeyType;
  OperationCompanyNumber?: string;
  OperationCompanyName?: string;
  CustomerNumber?: string;
  CustomerName?: string;
  Address?: string;
  AddressTwo?: string;
  City?: string;
  State?: string;
  Zip?: string;
  IsAllowedSundayDelivery: boolean;
  IsAllowedMondayDelivery: boolean;
  IsAllowedTuesdayDelivery: boolean;
  IsAllowedWednesdayDelivery: boolean;
  IsAllowedThursdayDelivery: boolean;
  IsAllowedFridayDelivery: boolean;
  IsAllowedSaturdayDelivery: boolean;
  IsSkipDayRequired: boolean;
  Timezone: number;
  BillToCustomerNumber?: string;
  BillToCustomerName?: string;
  BillToAddress?: string;
  BillToAddressTwo?: string;
  BillToCity?: string;
  BillToState?: string;
  BillToZip?: string;
  PropietaryFlag?: string;
  MinimumOrderQuantity: number;
  CreditTermDisplay?: string;
}

export interface Customers {
  ResultObject: Customer[];
}

export interface CustomerStopShipDetail {
  CustomerKey?: string;
  BusinessUnitKey: BusinessUnitKeyType;
  BusinessUnitERPKey: BusinessUnitERPKeyType;
  OperationCompanyNumber?: string;
  IsOnStopShip: boolean;
}

export interface CustomerStopShipDetails {
  ResultObject: CustomerStopShipDetail[];
}

export interface GetCustomerDeliveryDatesRequest {
  customerId: string;
}

export interface GetCustomerRequest {
  customerId: string;
}

export interface GetCustomersRequest {
  businessUnitKey: BusinessUnitKeyType;
  operationCompanyNumber: string;
}

export const hasDeliverAddress = (customer: Customer | undefined): string | undefined =>
  customer?.CustomerName ||
  customer?.CustomerNumber ||
  customer?.Address ||
  customer?.AddressTwo ||
  customer?.City ||
  customer?.State ||
  customer?.Zip;

export const hasBillToAddress = (customer: Customer | undefined): string | undefined =>
  customer?.BillToCustomerName ||
  customer?.BillToCustomerNumber ||
  customer?.BillToAddress ||
  customer?.BillToAddressTwo ||
  customer?.BillToCity ||
  customer?.BillToState ||
  customer?.BillToZip;
