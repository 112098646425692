import { useTheme, Breakpoint } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export type BreakpointOrNull = Breakpoint | null;

export enum DeviceType {
  Desktop = 0,
  Tablet = 1,
  Mobile = 2,
}

export type BreakpointProperties = {
  breakpoint: Breakpoint;
  deviceType: DeviceType;
};

export const useBreakpoint = (): BreakpointProperties => {
  const theme = useTheme();

  const keys = [...theme.breakpoints.keys].reverse();
  const breakpoint =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
      const matches = useMediaQuery(theme.breakpoints.up(key), {
        noSsr: true,
      });
      return !output && matches ? key : output;
    }, null) || 'xs';

  const deviceType =
    breakpoint === 'xl' ? DeviceType.Desktop : breakpoint === 'lg' ? DeviceType.Tablet : DeviceType.Mobile;

  return {
    breakpoint,
    deviceType,
  };
};
