import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { History } from 'history';
import { openFileInCurrentWindow } from 'web/src/utilities/download-file';
import { getResolution } from 'web/src/utilities/resolution';
import { GetManagedUserExistsRequest, UserManagementFilter } from '../../api';
import { FileResult } from '../../api/models/api-shared.models';
import {
  CreatePendingUserRequest,
  GetManagedUsersByCustomerRequest,
  GetManagedUsersReportRequest,
  ManageUser,
  ManageUserCustomerPermissions,
  ManagedUserStatusTypes,
  RemoveUserFromLocationRequest,
  ResendInvitationRequest,
  SetUserCustomersRequest,
} from '../../api/models/manage-users.models';
import ManageUsersService from '../../api/services/manage-users.service';
import { getManageUsersURL, getRemoveMultipleUsersURL } from '../../helpers/general/routing';
import { useAppInsightsLogger } from '../../logging';
import {
  NotificationKeys,
  UserActivityAction,
  UserActivityActionSummary,
  UserActivityPageName,
} from '../../models/index';
import { UserManagementHistoryState, updateUserManagementAdvanceFilterObject } from '../../models/manage-users.models';
import { globalSlice, logUserActivity, userSlice } from '../common';
import { upsertAppNotificationByKey, upsertAppNotificationByKeyAndId } from '../common/global.thunks';
import { AppDispatch, AppThunk, RootState } from '../store';
import { manageUsersSlice, selectAllRemoveManageUsers } from './manage-users.slice';

const appInsightsLogger = useAppInsightsLogger();
const manageUsersService = ManageUsersService.getInstance();

export const getManagedUserExists =
  (request: GetManagedUserExistsRequest): AppThunk<Promise<ManageUser | undefined>> =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await manageUsersService.getManagedUserExists(request);

      if (data.IsSuccess) {
        return data.ResultObject;
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        return undefined;
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      return undefined;
    }
  };

export const createPendingUser =
  (user: CreatePendingUserRequest, callBack?: () => void): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await manageUsersService.createPendingUser(user);
      if (data.IsSuccess) {
        const newUser = {
          ...data.ResultObject,
          CustomerIds: user.CustomerIds,
          ManagedUserStatus: ManagedUserStatusTypes.Pending,
        } as ManageUser;
        dispatch(manageUsersSlice.actions.addUser(newUser));
        dispatch(
          upsertAppNotificationByKey(NotificationKeys.Toast, '', `Invitation sent to ${data.ResultObject.Email}`)
        );
        dispatch(
          logUserActivity({
            action: UserActivityAction.InviteNewUser,
            pageName: UserActivityPageName.ManageUsers,
            actionSummary: UserActivityActionSummary.InviteNewUser,
            resolution: getResolution(),
            pendingUserId: data.ResultObject.UserId,
          })
        );
        dispatch(
          logUserActivity({
            action: UserActivityAction.UpdatePermissions,
            pageName: UserActivityPageName.ManageUsers,
            actionSummary: UserActivityActionSummary.UpdateNewUserPermissions,
            resolution: getResolution(),
            pendingUserId: data.ResultObject.UserId,
          })
        );
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      callBack?.();
    }
  };

export const addUserToLocation =
  (user: ManageUser, locations: string[], callBack?: () => void): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch) => {
    if (!locations.length) return;

    try {
      const { data } = await manageUsersService.addUserToLocation({
        UserId: user.UserId,
        CustomerIds: locations,
        IsPendingUser: user.IsPendingUser,
      });

      if (data.IsSuccess) {
        dispatch(
          manageUsersSlice.actions.updateUserCustomers({
            UserId: user.UserId,
            CustomerIds: [...user.CustomerIds, ...locations],
          } as ManageUser)
        );
        dispatch(
          logUserActivity({
            action: UserActivityAction.AddUserToLocation,
            pageName: UserActivityPageName.ManageUsers,
            actionSummary: UserActivityActionSummary.AddUserToLocation,
            resolution: getResolution(),
            userId: user.UserId,
            customerId: locations.toString().replace(',', ', '),
          })
        );
        dispatch(upsertAppNotificationByKey(NotificationKeys.Toast, '', `${user?.Email} has been added`));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      callBack?.();
    }
  };

export const updateUserStatus =
  (user: ManageUser, status: ManagedUserStatusTypes): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(
        manageUsersSlice.actions.updateUserStatus({
          ...user,
          ManagedUserStatus: status,
        } as ManageUser)
      );
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Updates the search filters
 *
 * @param history - Used to fix the URL to match the updated filters
 * @param filters - The filter information to be set
 * @returns NULL
 */
export const updateUserManagementFilters =
  (history: History<UserManagementHistoryState>, filterText: string, ...filters: UserManagementFilter[]): AppThunk =>
  () => {
    let historyPath = history.location.pathname;
    if (history.location.state?.isRemoveMultipleUsersMode) {
      historyPath = getRemoveMultipleUsersURL(history.location.state?.removeUserGridAdvanceFilter?.FilterText);
    } else {
      historyPath = getManageUsersURL(history.location.state?.manageUserGridAdvanceFilter?.FilterText);
    }
    const filterObject = updateUserManagementAdvanceFilterObject(
      history.location.state?.isRemoveMultipleUsersMode
        ? history.location.state?.removeUserGridAdvanceFilter
        : history.location.state?.manageUserGridAdvanceFilter,
      filters
    );
    filterObject.FilterText = filterText ?? '';
    history.replace(historyPath, {
      ...history.location.state,
      [history.location.state?.isRemoveMultipleUsersMode
        ? 'removeUserGridAdvanceFilter'
        : 'manageUserGridAdvanceFilter']: filterObject,
    });
  };

export const updateLastActiveOption =
  (history: History<UserManagementHistoryState>, appliedLastActiveOption: number | undefined): AppThunk =>
  () => {
    let historyPath = history.location.pathname;
    if (history.location.state?.isRemoveMultipleUsersMode) {
      historyPath = getRemoveMultipleUsersURL(history.location.state?.removeUserGridAdvanceFilter?.FilterText);
    } else {
      historyPath = getManageUsersURL(history.location.state?.manageUserGridAdvanceFilter?.FilterText);
    }
    history.replace(historyPath, {
      ...history.location.state,
      [history.location.state?.isRemoveMultipleUsersMode
        ? 'appliedLastActiveOptionForRemoveUserGridPane'
        : 'appliedLastActiveOptionForManageUserGridPane']: appliedLastActiveOption,
    });
  };

export const resendUserInvitation =
  (userEmail: string, userId?: string, callBack?: () => void): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch) => {
    const request: ResendInvitationRequest = {
      UserEmail: userEmail,
    };

    try {
      const { data } = await manageUsersService.resendInvitation(request);
      if (data.IsSuccess) {
        dispatch(upsertAppNotificationByKey(NotificationKeys.Toast, '', `Invitation sent to ${userEmail}`));
        if (userId) {
          dispatch(
            logUserActivity({
              action: UserActivityAction.ResendInvite,
              pageName: UserActivityPageName.ManageUsers,
              actionSummary: UserActivityActionSummary.ResendInvite,
              resolution: getResolution(),
              pendingUserId: userId,
            })
          );
        }
        if (data.InformationMessages?.length > 0) {
          dispatch(
            globalSlice.actions.setErrorDialogContent({
              title: 'Notice',
              messages: data.InformationMessages,
              pageRefresh: true,
            })
          );
        }
        callBack?.();
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

export const getUserCustomerPermissions =
  (
    user: ManageUser,
    customerId: string,
    onSuccess?: (data: ManageUserCustomerPermissions) => void
  ): AppThunk<Promise<void>> =>
  async () => {
    try {
      const { data } = await manageUsersService.getUserCustomerPermissions({
        UserId: user.UserId,
        IsPendingUser: user.IsPendingUser,
        CustomerId: customerId,
      });

      if (data.IsSuccess) {
        onSuccess?.(data.ResultObject);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

export const setUserCustomerPermissions =
  (
    manageUserCustomerPermissions: ManageUserCustomerPermissions[],
    onSuccess?: () => void,
    onFailure?: () => void,
    toastMessage?: string
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await manageUsersService.setUserCustomerPermissions({
        UpdatedUserCustomerPermissions: manageUserCustomerPermissions,
      });

      if (data.IsSuccess) {
        onSuccess?.();
        dispatch(
          upsertAppNotificationByKey(NotificationKeys.Toast, '', toastMessage ?? 'Permissions successfully updated')
        );
        dispatch(
          logUserActivity({
            action: UserActivityAction.UpdatePermissions,
            pageName: UserActivityPageName.ManageUsers,
            actionSummary: UserActivityActionSummary.UpdateExistingUserPermissions,
            resolution: getResolution(),
            userId: manageUserCustomerPermissions
              .map((users) => users.UserId)
              .toString()
              .replace(',', ', '),
            customerId: manageUserCustomerPermissions
              .map((users) => users.CustomerId)
              .toString()
              .replace(',', ', '),
          })
        );
      } else {
        onFailure?.();
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

export const getManagedUsersByCustomer =
  (customerIds: string[]): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const request: GetManagedUsersByCustomerRequest = {
      CustomerIds: customerIds,
    };

    try {
      if (!getState().manageUsers.manageUsersLoading) {
        dispatch(manageUsersSlice.actions.setManageUsersLoading(true));
      }

      const { data } = await manageUsersService.getManagedUsersByCustomer(request);
      if (data.IsSuccess) {
        dispatch(manageUsersSlice.actions.setManageUsersGrid(data.ResultObject));

        // Pre-load remove manage users grid
        // if (customerIds.length > 0) {
        //   const customerId = customerIds[0];
        //   const users = data.ResultObject.filter((c) => c.CustomerIds.includes(customerId));
        //   dispatch(manageUsersSlice.actions.setRemoveManageUsersGrid(users));
        // }
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        dispatch(manageUsersSlice.actions.setManageUsersGrid([]));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    } finally {
      dispatch(manageUsersSlice.actions.setManageUsersLoading(false));
    }
  };

export const getRemoveManagedUsersByCustomer =
  (customerIds: string[]): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const request: GetManagedUsersByCustomerRequest = {
      CustomerIds: customerIds,
    };

    try {
      if (!getState().manageUsers.manageUsersLoading) {
        dispatch(manageUsersSlice.actions.setManageUsersLoading(true));
      }

      const { data } = await manageUsersService.getManagedUsersByCustomer(request);
      if (data.IsSuccess) {
        dispatch(manageUsersSlice.actions.setRemoveManageUsersGrid(data.ResultObject));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        dispatch(manageUsersSlice.actions.setRemoveManageUsersGrid([]));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    } finally {
      dispatch(manageUsersSlice.actions.setManageUsersLoading(false));
    }
  };

export const setUserCustomers =
  (request: SetUserCustomersRequest, callBack?: () => void): AppThunk<Promise<void>> =>
  async () => {
    try {
      const { data } = await manageUsersService.setUserCustomers(request);

      if (data.IsSuccess) {
        /* This will likely need to update the redux state value for
         * ManageUserCustomerPermissions such that the new list of IDs is
         * represented accordingly without the need for an additional API call */
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      callBack?.();
    }
  };

export const setManagedUsersGrid =
  (users: ManageUser[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(manageUsersSlice.actions.setManageUsersGrid(users));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls and sets the result of the GetManageUsersReport API call
 *
 * @param request - The request information for the API call
 * @returns NULL
 */
export const getManageUsersReport =
  (request: GetManagedUsersReportRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(manageUsersSlice.actions.setReportIsLoading(true));
      const { data } = await manageUsersService.getManagedUsersReport(request);
      if (data.IsSuccess) {
        dispatch(manageUsersSlice.actions.setManageUsersReport(data.ResultObject as FileResult));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(manageUsersSlice.actions.setReportIsLoading(false));
    }
  };

/**
 * Calls and displays the result of the GetManageUsersReport API call
 *
 * @param request - The request information for the API call
 * @returns NULL
 */
export const exportManageUsersReport =
  (request: GetManagedUsersReportRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await manageUsersService.getManagedUsersReport(request);
      if (data.IsSuccess) {
        openFileInCurrentWindow(data.ResultObject as FileResult);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sets the checkedUserIds value in the managed user slice
 *
 * @param user - The user being checked/unchecked
 * @param isChecked - The boolean value indicating if the value should be added to or removed from checkedUserIds
 * @returns NULL
 */
export const checkManageUser =
  (user: ManageUser, isChecked: boolean): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch) => {
    dispatch(manageUsersSlice.actions.setCheckedUsers({ usersId: [user.UserId], isChecked }));
  };

/**
 * Sets checkedUserIds to either contain all messages or none of them
 *
 * @param isChecked - The boolean value indicating if the value should be added to or removed from checkedUserIds
 * @returns NULL
 */
export const checkAllManageUsers =
  (isChecked: boolean): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(
      manageUsersSlice.actions.setCheckedUsers({
        usersId: selectAllRemoveManageUsers(getState()).map((users) => users.UserId),
        isChecked,
      })
    );
  };

export const removeCheckedUsersFromLocation =
  (usersToRemove: ManageUser[], customerId: string): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch) => {
    try {
      const request = {
        UserCustomers: usersToRemove.map((user) => ({
          UserId: user.UserId,
          IsPendingUser: user.IsPendingUser,
          CustomerId: customerId,
        })),
      } as RemoveUserFromLocationRequest;
      const { data } = await manageUsersService.removeUsersFromLocation(request);
      if (data.IsSuccess) {
        const userIds = usersToRemove.map((users) => users.UserId);
        dispatch(manageUsersSlice.actions.setCheckedUsers({ usersId: userIds, isChecked: false }));
        dispatch(manageUsersSlice.actions.removeUsers({ userIds, customerId }));
        dispatch(
          upsertAppNotificationByKeyAndId(
            NotificationKeys.Toast,
            '',
            'User(s) successfully removed from the selected location',
            undefined,
            'fa-check-circle'
          )
        );
        dispatch(
          logUserActivity({
            action: UserActivityAction.RemoveUserFromLocation,
            pageName: UserActivityPageName.ManageUsers,
            actionSummary: UserActivityActionSummary.RemoveUserFromLocation,
            resolution: getResolution(),
            userId: usersToRemove
              .map((user) => user.UserId)
              .toString()
              .replace(',', ', '),
            customerId: customerId,
          })
        );
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Updates the UserState's showReviewDialog property
 *
 * @param display - the value to set the property to
 * @returns void
 */
export const toggleReviewUsersDialog =
  ({ display }: { display: boolean }): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(manageUsersSlice.actions.setShowReviewDialog(display));
  };

export const setStatusFilters =
  (statusFilters: string[]): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(manageUsersSlice.actions.setStatusFilters(statusFilters));
  };

/**
 * Resets the stored user checkup date. This value is used to detemine the last time the user has check in on manage users.
 *
 * @returns null
 */
export const resetIsPastUserCheckup = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    const { data } = await manageUsersService.resetUserCheckupDate();
    if (data.IsSuccess) {
      dispatch(userSlice.actions.setIsUserPastCheckupDate(false));
    } else {
      dispatch(
        globalSlice.actions.setErrorDialogContent({
          title: 'Request to reset user checkup date could not be completed',
          messages: data.ErrorMessages,
        })
      );
    }
  } catch (error) {
    appInsightsLogger.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
  }
};

export const setUserToEdit =
  (user: ManageUser, callBack?: () => void): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(manageUsersSlice.actions.setUserToEdit(user));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      callBack?.();
    }
  };

export const updateUserLocations =
  (
    user: ManageUser,
    addedLocations: string[],
    removedLocations: string[],
    callBack?: () => void
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch) => {
    try {
      const promises = [];
      if (addedLocations.length > 0)
        promises.push(
          manageUsersService.addUserToLocation({
            UserId: user.UserId,
            CustomerIds: addedLocations,
            IsPendingUser: user.IsPendingUser,
          })
        );
      if (removedLocations.length > 0)
        promises.push(
          manageUsersService.removeUsersFromLocation({
            UserCustomers: removedLocations.map((id) => ({
              UserId: user.UserId,
              IsPendingUser: user.IsPendingUser,
              CustomerId: id,
            })),
          })
        );

      const result = await Promise.all(promises);
      const addResult = addedLocations.length > 0 ? result[0].data : undefined;
      const addSucceeded = addResult?.IsSuccess ?? true;
      const removeResult =
        removedLocations.length > 0 ? (addedLocations.length > 0 ? result[1].data : result[0].data) : undefined;
      const removeSucceeded = removeResult?.IsSuccess ?? true;

      let newCustomerIds = [] as string[];

      if (addSucceeded && removeSucceeded) {
        newCustomerIds = user.CustomerIds.filter((id) => !removedLocations.includes(id)).concat(...addedLocations);
      } else if (!addSucceeded && removeSucceeded) {
        newCustomerIds = user.CustomerIds.filter((id) => !removedLocations.includes(id));
        if (addResult)
          dispatch(
            globalSlice.actions.setErrorDialogContent({
              messages: addResult.ErrorMessages,
            })
          );
      } else if (addSucceeded && !removeSucceeded) {
        newCustomerIds = user.CustomerIds.concat(...addedLocations);
        if (removeResult)
          dispatch(
            globalSlice.actions.setErrorDialogContent({
              messages: removeResult.ErrorMessages,
            })
          );
      } else {
        if (addResult && removeResult)
          dispatch(
            globalSlice.actions.setErrorDialogContent({
              messages: addResult.ErrorMessages.concat(removeResult.ErrorMessages),
            })
          );
      }
      const editedUser = { ...user, CustomerIds: newCustomerIds } as ManageUser;
      dispatch(manageUsersSlice.actions.setUserToEdit(editedUser));
      dispatch(manageUsersSlice.actions.updateUserCustomers(editedUser));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      callBack?.();
    }
  };
