import { Badge, Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useAppSelector } from 'common';
import { FC, PropsWithChildren } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UnreadMessagesBadgeProps {
  sx?: SxProps<Theme>;
}

export const UnreadMessagesBadge: FC<PropsWithChildren<UnreadMessagesBadgeProps>> = (
  props: PropsWithChildren<UnreadMessagesBadgeProps>
) => {
  const unreadMessages = useAppSelector((s) => s.message.unreadMessagesCount);
  const badgeContent = unreadMessages?.NumberUnread ?? 0;
  const color = unreadMessages?.IsAnyHighPriority ? 'error' : 'primary';
  return (
    <Box sx={props.sx}>
      <Badge badgeContent={badgeContent} color={color}>
        {props.children}
      </Badge>
    </Box>
  );
};
