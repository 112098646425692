import { OrderViewType } from '../../api';
import { DeliveryStatus } from '../../api/models/delivery.enums';
import { DeliveryInvoice } from '../../api/models/delivery.models';
import { ConfirmationStatusType } from '../../api/models/order-confirmation.models';
import { SubmittedOrderStatusSearchFilter } from '../../models/order.enums';
import { Order, OrderBasic, OrderDelivery, OrderSubmitted } from '../../models/order.models';
import { toLocalDateTimeString, toWeekdayFormatLong } from './date';
import { filterObjects } from './filter';
import { addSpacesBetweenWords } from './string';

export const filterOrderBasicOrders = (
  orders: OrderBasic[],
  filterText: string | undefined,
  confirmations: boolean
): OrderBasic[] => {
  if (!filterText) {
    return orders;
  }

  return filterObjects(
    orders,
    filterText,
    [
      'CustomerName',
      'CustomerAddress',
      'CustomerCity',
      'CustomerState',
      'CustomerZip',
      'OperationCompanyName',
      'CustomerNumber',
      'ConfirmationStatus',
      'ConfirmationOrderNumber',
      'CutoffDateTime',
      'DeliveryDate',
      'OrderEntryHeaderName',
      'PurchaseOrderNumber',
      'SubmittedDate',
      'TotalOrderQuantity',
      'TotalLines',
      'TotalOrderPrice',
    ],
    [
      {
        field: 'ConfirmationStatus',
        type: 'custom',
        filterFunction: (val: string, queryString: string) => {
          if (!confirmations) return false;
          return addSpacesBetweenWords(ConfirmationStatusType[+val] || 'Unknown Status')
            .toLocaleLowerCase()
            .includes(queryString);
        },
      },
      {
        field: 'CutoffDateTime',
        type: 'custom',
        filterFunction: (val: string, queryString: string) =>
          !confirmations
            ? `Cutoff: ${toLocalDateTimeString(val, true)}`.toLocaleLowerCase().includes(queryString)
            : false,
      },
      {
        field: 'ConfirmationOrderNumber',
        type: 'custom',
        filterFunction: (val: string, queryString: string) => {
          if (!val || !confirmations) return false;
          return `order #: ${val}`.includes(queryString);
        },
      },
      {
        field: 'DeliveryDate',
        type: 'date',
        filterFunction: (val: string, queryString: string) =>
          toWeekdayFormatLong(val).slice(0, -1).toLocaleLowerCase().includes(queryString),
      },
      {
        field: 'PurchaseOrderNumber',
        type: 'custom',
        filterFunction: (val: string, queryString: string) => {
          if (!val) return false;
          return `po #: ${val}`.includes(queryString);
        },
      },
      {
        field: 'SubmittedDate',
        type: 'custom',
        filterFunction: (val: string, queryString: string) => {
          if (!val || !confirmations) return false;
          return `${toLocalDateTimeString(val)}`.toLocaleLowerCase().includes(queryString);
        },
      },
      {
        field: 'TotalOrderQuantity',
        type: 'custom',
        filterFunction: (val: string, queryString: string) => `qty: ${val}`.includes(queryString),
      },
      {
        field: 'TotalLines',
        type: 'custom',
        filterFunction: (val: string, queryString: string) => `lines: ${val}`.includes(queryString),
      },
      {
        field: 'TotalOrderPrice',
        type: 'currency',
      },
    ]
  );
};

export const filterOrderDeliveryOrders = (deliveries: OrderDelivery[], filterText?: string): OrderDelivery[] => {
  if (!filterText) return deliveries;

  const deliveriesWithInvoices: (OrderDelivery & { InvoiceNumbers: string[] })[] = deliveries.map(
    (d: OrderDelivery) => {
      const invoicesNumbers: string[] = d.Invoices.map((i: DeliveryInvoice) => i.InvoiceNumber);
      return {
        ...d,
        InvoiceNumbers: invoicesNumbers,
      };
    }
  );

  return filterObjects(
    deliveriesWithInvoices,
    filterText,
    [
      'CustomerName',
      'CustomerAddress',
      'CustomerLocation',
      'OperationCompanyName',
      'CustomerNumber',
      'DeliveryStatus',
      'DeliveryDate',
      'InvoiceNumbers',
      'OrderedByDisplay',
      'PurchaseOrderNumbers',
      'TotalOrderQuantity',
      'TotalShippedQuantity',
      'TotalLines',
      'TotalDollars',
      'IsWillCall',
      'View',
    ],
    [
      {
        field: 'DeliveryStatus',
        type: 'custom',
        filterFunction: (val: string, queryString: string) =>
          addSpacesBetweenWords(DeliveryStatus[+val] || 'Unknown Status')
            .toLocaleLowerCase()
            .includes(queryString),
      },
      {
        field: 'DeliveryDate',
        type: 'date',
        filterFunction: (val: string, queryString: string) =>
          toWeekdayFormatLong(val).slice(0, -1).toLocaleLowerCase().includes(queryString),
      },
      {
        field: 'InvoiceNumbers',
        type: 'custom',
        filterFunction: (val: string, queryString: string) => {
          if (!val) return false;
          const arr: string[] = val.split(',');
          return `invoice: ${arr.join(', ')}`.includes(queryString);
        },
      },
      {
        field: 'OrderedByDisplay',
        type: 'custom',
        filterFunction: (val: string, queryString: string) => {
          if (!val) return false;
          const arr: string[] = val.split(',');
          return `ordered by: ${arr.join(', ')}`.includes(queryString);
        },
      },
      {
        field: 'PurchaseOrderNumbers',
        type: 'custom',
        filterFunction: (val: string, queryString: string) => {
          if (!val) return false;
          const arr: string[] = val.split(',');
          return `po #: ${arr.join(', ')}`.includes(queryString);
        },
      },
      {
        field: 'TotalOrderQuantity',
        type: 'custom',
        filterFunction: (val: string, queryString: string) => `order qty: ${val}`.includes(queryString),
      },
      {
        field: 'TotalShippedQuantity',
        type: 'custom',
        filterFunction: (val: string, queryString: string) => `shipped qty: ${val}`.includes(queryString),
      },
      {
        field: 'TotalLines',
        type: 'custom',
        filterFunction: (val: string, queryString: string) => `lines: ${val}`.includes(queryString),
      },
      {
        field: 'TotalDollars',
        type: 'currency',
      },
      {
        field: 'IsWillCall',
        type: 'custom',
        filterFunction: (val: string, queryString: string) =>
          val.toLowerCase() === 'true' && 'will call'.includes(queryString),
      },
    ]
  );
};

export const filterOrderSubmittedOrders = (orders: OrderSubmitted[], filterText?: string): OrderSubmitted[] => {
  if (!filterText) return orders;

  return filterObjects(
    orders,
    filterText,
    [
      'CustomerName',
      'CustomerAddress',
      'DeliverToCity',
      'DeliverToZip',
      'DeliverToState',
      'OperationCompanyName',
      'CustomerNumber',
      'OrderStatus',
      'DeliveryDate',
      'OrderNumber',
      'PurchaseOrderNumber',
      'TotalLines',
      'TotalOrderQuantity',
      'OrderTotalSales',
      'IsWillCall',
      'View',
    ],
    [
      {
        field: 'OrderStatus',
        type: 'custom',
        filterFunction: (val: string, queryString: string) =>
          addSpacesBetweenWords(SubmittedOrderStatusSearchFilter[+val] || 'Unknown Status')
            .toLocaleLowerCase()
            .includes(queryString),
      },
      {
        field: 'DeliveryDate',
        type: 'date',
        filterFunction: (val: string, queryString: string) =>
          `${toWeekdayFormatLong(val).slice(0, -1)} delivery`.toLocaleLowerCase().includes(queryString),
      },
      {
        field: 'PurchaseOrderNumber',
        type: 'custom',
        filterFunction: (val: string, queryString: string) => {
          if (!val) return false;
          const arr: string[] = val.split(',');
          return `po #: ${arr.join(', ')}`.includes(queryString);
        },
      },
      {
        field: 'TotalOrderQuantity',
        type: 'custom',
        filterFunction: (val: string, queryString: string) => `order qty: ${val}`.includes(queryString),
      },
      {
        field: 'TotalLines',
        type: 'custom',
        filterFunction: (val: string, queryString: string) => `lines: ${val}`.includes(queryString),
      },
      {
        field: 'OrderTotalSales',
        type: 'currency',
      },
      {
        field: 'IsWillCall',
        type: 'custom',
        filterFunction: (val: string, queryString: string) =>
          val.toLowerCase() === 'true' && 'will call'.includes(queryString),
      },
    ]
  );
};

export const getOrderType = (
  order: Order
): 'Delivery' | 'Submitted' | 'Unsubmitted' | 'Confirmation' | 'FutureSubmission' | undefined => {
  return 'DeliveryKey' in order
    ? 'Delivery'
    : 'OrderKey' in order
    ? 'Submitted'
    : 'OrderEntryHeaderId' in order && order.IsSubmitted && order.View !== OrderViewType.FutureOrder
    ? 'Confirmation'
    : 'OrderEntryHeaderId' in order && order.View === OrderViewType.FutureOrder
    ? 'FutureSubmission'
    : 'OrderEntryHeaderId' in order
    ? 'Unsubmitted'
    : undefined;
};
