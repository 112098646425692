export interface ValidEmailRequest {
  value: string;
  error: boolean;
  required: boolean;
  regex?: RegExp;
}

export type ValidEmailRequestValue = {
  value: string;
  error: boolean;
  required: boolean;
  regex?: RegExp;
};

export type RequestValidationResults = {
  RequestValues: ValidEmailRequestValue;
  HasErrors: boolean;
};

export const validateRequestValues = (properties: ValidEmailRequest): boolean => {
  if (properties.required && properties.value.length === 0) {
    return true;
  }
  if (properties.regex) {
    if (properties.value.length > 0 && !properties.regex.test(properties.value)) {
      return true;
    }
  }
  return false;
};
