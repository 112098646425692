import { Box, Divider, Stack, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import { RootState, useAppSelector, UserCustomer } from 'common';
import { DeviceType, useBreakpoint } from '../../../../hooks/useBreakpoint';
import AccountSideBarDrawerButton from '../AccountSideBarDrawer/AccountSideBarDrawerButton';
import CommsLocation from './components/CommunicationsLocation';
import CommunicationsOrderConfirmations from './components/CommunicationsOrderConfirmations';

interface CommunicationsPanelProps {
  selectedCustomer: UserCustomer;
  onCustomerChange?: (customer?: UserCustomer) => void;
}

const CommunicationsPanel: React.FC<CommunicationsPanelProps> = (props: CommunicationsPanelProps): JSX.Element => {
  const { deviceType } = useBreakpoint();
  const isMobile = deviceType === DeviceType.Mobile;

  const multipleCustomers = useAppSelector(
    (s: RootState) => s.user.userSite?.UserCustomers && s.user.userSite?.UserCustomers.length > 1
  );

  return (
    <>
      <Box sx={{ p: { xs: 2, lg: 0 } }}>
        {isMobile && (
          <Box sx={{ mb: 2 }}>
            <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Typography variant='h3' data-testid='communications-title'>
                Communications
              </Typography>
              <AccountSideBarDrawerButton />
            </Stack>
          </Box>
        )}
        {multipleCustomers && (
          <>
            <Stack sx={{ gap: 3, mb: 2 }}>
              <CommsLocation
                canHide={deviceType !== DeviceType.Desktop}
                defaultSelectedCustomer={props.selectedCustomer}
                titleTestId='communications-location-title'
                actionLabelTestId='communications-location-action'
                sx={{
                  pl: { xs: 0, xl: 3 },
                  pt: { xs: 0, xl: 3 },
                  pr: { xs: 0 },
                  mb: { xs: -2, lg: -1 },
                  maxWidth: { xl: 242 },
                }}
                onCustomerChange={props.onCustomerChange}
              />
            </Stack>
            <Divider
              sx={{
                borderBottomWidth: 2,
                borderColor: (theme: Theme) => theme.palette.grey[100],
                ml: { xl: 3 },
                width: { xs: 295, lg: 344, xl: 242 },
              }}
            />
          </>
        )}
        <Box sx={{ mt: { xs: 2, lg: multipleCustomers ? 3 : 0, xl: 3 }, ml: { xl: 3 } }}>
          <CommunicationsOrderConfirmations selectedCustomer={props.selectedCustomer} />
        </Box>
      </Box>
    </>
  );
};

export default CommunicationsPanel;
