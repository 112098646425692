import { CfGa } from 'common';
import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

export const useGaState = singletonHook<
  [CfGa | undefined, React.Dispatch<React.SetStateAction<CfGa | undefined>>] | undefined
>(undefined, () => useState<CfGa | undefined>(undefined));

/*
 * useGA:
 * Fetches the CfGa object, exposing Google Analytics
 */
export const useGa = (): CfGa | undefined => useGaState()?.[0];
