import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useQueryParams<TQueryParams extends { [key: string]: string }>(): TQueryParams {
  const { search } = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(search);
    return Object.fromEntries(params) as TQueryParams;
  }, [search]);
}
