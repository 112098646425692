import { Box, Typography } from '@mui/material/';
import { styled } from '@mui/material/styles';

export const StyledHeaderBox = styled(Box)({
  display: 'flex',
  position: 'relative',
  WebkitTapHighlightColor: 'transparent', // removes blue highlight over container when clicked in mobile views
});

export const StyledHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  textTransform: 'uppercase',
}));

export const StyledSubheader = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  ...theme.typography.paragraphBold,
}));
