import { Box, Skeleton, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC } from 'react';
import { CfCheckbox, CfCheckboxSize } from './CfCheckbox';

interface CfLoadingCheckboxProps {
  containerSx?: SxProps<Theme>;
  skeletonContainerSx?: SxProps<Theme>;
  size?: CfCheckboxSize;
  label?: JSX.Element;
  dataTestId?: string;
}

/**
 * @param containerSx - Additional styling of the checkbox's container
 * @param size - Size of the checkbox (small, medium)
 * @param label - The label to use next to the checkbox
 * @param dataTestId - Test id of the checkbox
 */
export const CfLoadingCheckbox: FC<CfLoadingCheckboxProps> = (props: CfLoadingCheckboxProps) => {
  const size = props.size === 'medium' ? '25px' : '16px';

  return (
    <Box sx={{ display: 'inline-flex', width: '100%', ...props.containerSx }}>
      <Box sx={{ mr: 1, display: 'flex', ...props.skeletonContainerSx }}>
        <Skeleton variant='rectangular' width={size} height={size}>
          <CfCheckbox
            dataTestId={props.dataTestId}
            size={props.size}
            item={{ label: '', checked: false }}
            onCheckedChanged={() => {
              /* no op*/
            }}
            variant='primary'
            icon='check'
            checkboxProps={{
              sx: {
                p: 0,
              },
            }}
          />
        </Skeleton>
      </Box>
      {props.label && (
        <Skeleton variant='text' height={size}>
          {props.label}
        </Skeleton>
      )}
    </Box>
  );
};
