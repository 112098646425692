import { ConfirmationStatusType } from '../../api/models/order-confirmation.models';
import { NotificationType } from '../../models/notifications.models';
import { generateStaticId } from './AppNotificationsCenter';

export class ConfirmationStatusLookup {
  public static ConfirmationStatus: { [key in ConfirmationStatusType]: ConfirmationNotificationReference } = {
    [ConfirmationStatusType.Confirmed]: {
      Id: generateStaticId('toastNotification', [ConfirmationStatusType.Confirmed.toString()]),
      Message: 'Your order is confirmed.',
      Icon: 'fa-check-circle',
      NotificationType: NotificationType.Success,
    },
    [ConfirmationStatusType.ConfirmedWithExceptions]: {
      Id: generateStaticId('toastNotification', [ConfirmationStatusType.ConfirmedWithExceptions.toString()]),
      Message: 'Your order is confirmed. Please review the exceptions below.',
      Icon: 'fa-exclamation-circle',
      NotificationType: NotificationType.Success,
    },
    [ConfirmationStatusType.ConfirmedWithInformation]: {
      Id: generateStaticId('toastNotification', [ConfirmationStatusType.ConfirmedWithInformation.toString()]),
      Message: 'Your order is confirmed. Please review the information below.',
      Icon: 'fa-exclamation-circle',
      NotificationType: NotificationType.Success,
    },
    [ConfirmationStatusType.AwaitingConfirmation]: {
      Id: generateStaticId('toastNotification', [ConfirmationStatusType.AwaitingConfirmation.toString()]),
      Message: 'Your order is being confirmed. This may take a few seconds.',
      Icon: undefined,
      NotificationType: NotificationType.Information,
    },
    [ConfirmationStatusType.Refused]: {
      Id: generateStaticId('toastNotification', [ConfirmationStatusType.Refused.toString()]),
      Message: 'Your order has been refused. Please make adjustments or contact your sales representative.',
      Icon: 'fa-exclamation-circle',
      NotificationType: NotificationType.Error,
    },
    [ConfirmationStatusType.Cancelled]: {
      Id: generateStaticId('toastNotification', [ConfirmationStatusType.Cancelled.toString()]),
      Message: 'Your order has been cancelled. If this is unexpected, contact your sales representative.',
      Icon: 'fa-exclamation-circle',
      NotificationType: NotificationType.Error,
    },
  };
}

export interface ConfirmationNotificationReference {
  Id: string;
  Message: string;
  Icon?: string;
  NotificationType: NotificationType;
}
