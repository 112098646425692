import { Icon, IconButton, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC } from 'react';

export type CfCloseButtonVariant = 'regular' | 'slim' | 'thick';

export interface CfCloseButtonProps {
  id?: string;
  size: 'small' | 'medium';
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: CfCloseButtonVariant;
  dataTestId?: string;
  sx?: SxProps<Theme>;
}

/**
 * @param id - The id of the button
 * @param size - The size of the button's font
 * @param disabled - Boolean indicating if the button is disabled
 * @param onClick - Method to call on click
 * @param variant - Variant of the button (regular, slim, thick)
 * @param dataTestId - Test id to be used for the
 * @param sx - Additional styling for the icon button
 */
export const CfCloseButton: FC<CfCloseButtonProps> = (props: CfCloseButtonProps) => {
  const baseClassName = props.variant === 'slim' ? 'fal' : props.variant === 'thick' ? 'fa' : 'far';
  return (
    <IconButton
      id={props.id}
      size='medium'
      color='primary'
      sx={props.sx}
      onClick={props.onClick}
      disabled={props.disabled}
      aria-label='close'
      data-testid={props.dataTestId}
    >
      <Icon baseClassName={baseClassName} className='fa-times' fontSize={props.size} />
    </IconButton>
  );
};
