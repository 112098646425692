import { OrderViewersRequest, OrderViewer } from '../models/order-viewer.models';
import { ServiceResult } from '../models/api-shared.models';
import ApiService from './api-service';

class OrderViewerService extends ApiService {
  private static classInstance: OrderViewerService;

  private constructor() {
    super('OrderViewer');
  }

  public getOrderViewers(request: OrderViewersRequest): ServiceResult<OrderViewer[]> {
    return this.get(`${this.baseUrl}/GetOrderViewers`, request);
  }

  public enterOrderView(request: OrderViewersRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/EnterOrderView`, request, 'queryParams');
  }

  public exitOrderView(request: OrderViewersRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/ExitOrderView`, request, 'queryParams');
  }

  public static getInstance(): OrderViewerService {
    if (!this.classInstance) {
      this.classInstance = new OrderViewerService();
    }
    return this.classInstance;
  }
}

export default OrderViewerService;
