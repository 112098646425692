import { Box, Icon, styled, Theme } from '@mui/material';
import { ProductDisplayMode } from 'common';

export const StyledResponsiveIcon = styled(Icon)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    ...theme.typography.iconMedium,
  },
  [theme.breakpoints.up('xl')]: {
    ...theme.typography.iconSmall,
  },
}));

export const PageContentContainer = styled(Box)<{ $displayMode?: ProductDisplayMode }>(({ theme, $displayMode }) => ({
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(2),
  backgroundColor: $displayMode === ProductDisplayMode.Grid ? 'transparent' : theme.palette.common.white,
  height: 'fit-content',
  padding: theme.spacing(2),
}));

export const StyledEllipsingTextContainer = styled(Box)<{
  $lines: number;
  $reserveHeight?: boolean;
  $variant?: 'bold' | 'small';
}>(({ $lines, $reserveHeight, $variant, theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: $lines,
  WebkitBoxOrient: 'vertical',
  ...($reserveHeight
    ? {
        [theme.breakpoints.up('xs')]: {
          ...($variant === 'bold'
            ? theme.typography.mobileParagraphBold
            : $variant === 'small'
            ? theme.typography.mobileParagraphSmall
            : theme.typography.mobileParagraph),
        },
        [theme.breakpoints.up('xs')]: {
          height:
            ($variant === 'bold'
              ? theme.custom.lineHeights.mobileParagraphBold
              : $variant === 'small'
              ? theme.custom.lineHeights.mobileParagraphSmall
              : theme.custom.lineHeights.mobileParagraph) * $lines,
        },
        [theme.breakpoints.up('xl')]: {
          height:
            ($variant === 'bold'
              ? theme.custom.lineHeights.paragraphBold
              : $variant === 'small'
              ? theme.custom.lineHeights.paragraphSmall
              : theme.custom.lineHeights.paragraph) * $lines,
        },
      }
    : {}),
}));

export const StyledEllipsingText = styled(Box)<{
  $lines: number;
  $variant?: 'bold' | 'small';
}>(({ $lines, $variant, theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: $lines,
  WebkitBoxOrient: 'vertical',
  wordBreak: 'break-word',

  [theme.breakpoints.up('xs')]: {
    ...($variant === 'bold'
      ? theme.typography.mobileParagraphBold
      : $variant === 'small'
      ? theme.typography.mobileParagraphSmall
      : theme.typography.mobileParagraph),
  },
  [theme.breakpoints.up('lg')]: {
    ...($variant === 'bold'
      ? theme.typography.mobileParagraphBold
      : $variant === 'small'
      ? theme.typography.mobileParagraphSmall
      : theme.typography.mobileParagraph),
  },
  [theme.breakpoints.up('xl')]: {
    ...($variant === 'bold'
      ? theme.typography.paragraphBold
      : $variant === 'small'
      ? theme.typography.paragraphSmall
      : theme.typography.paragraph),
  },
}));

export const StyledViewBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    marginBottom: theme.spacing(4),
  },
  [theme.breakpoints.up('xl')]: {
    marginBottom: theme.spacing(5),
  },
}));

/**
 * @summary Used to wrap page header contents situated below the site header and page breadcrumbs
 */
export const StyledPageHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  paddingBottom: theme.spacing(2),
}));

export const getPaneBorderStyle = (theme: Theme): string => `2px solid ${theme.palette.grey[100]}`;
