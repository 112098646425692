import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { CatalogProduct, GetProductDetailRequest, OrderEntryProduct } from '../../api';
import ProductDetailService from '../../api/services/product-detail.service';
import ProductFooterService from '../../api/services/product-footer.service';
import { AnalyticsContext, getAnalyticsItemData } from '../../helpers';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { AppDispatch, AppThunk, RootState } from '../store';
import { ProductComparisonSection, productComparisonSlice, selectComparisonProducts } from './product-compare.slice';

//hooks
const productDetailService = ProductDetailService.getInstance();
const productFooterService = ProductFooterService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Updates the selected customer to match the passed in customer
 *
 * @param isOpen - Value that the isOpen value in the slice will be set to
 * @param orderId - The order where the products to be compared come from
 * @param analyticsContext - The analytics context to be used
 * @returns NULL
 */
export const toggleProductComparison =
  (isOpen: boolean, orderId?: string | undefined, analyticsContext?: AnalyticsContext): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(productComparisonSlice.actions.setComparisonOpenState(isOpen));

    const comparisonProducts = selectComparisonProducts(getState().productComparison);
    const getProductDetailProductKeys = comparisonProducts.filter((cp) => !cp.DetailLoaded).map((cp) => cp.ProductKey);
    const getProductFooterProductKeys = comparisonProducts.filter((cp) => !cp.ProductFooter).map((cp) => cp.ProductKey);

    if (getProductDetailProductKeys.length)
      dispatch(getProductComparisonProductDetailList(getProductDetailProductKeys, orderId));

    if (getProductFooterProductKeys.length)
      dispatch(getProductComparisonProductFooterList(getProductFooterProductKeys));

    if (isOpen) {
      analyticsContext?.analytics?.viewItemList?.(
        selectComparisonProducts(getState().productComparison).flatMap(
          (p, index) =>
            p.Product?.UnitOfMeasureOrderQuantities?.map?.((uom) =>
              getAnalyticsItemData(
                p.ProductKey ?? '',
                uom.UnitOfMeasure.toString(),
                uom.Price ?? 0,
                uom.Quantity,
                index,
                uom,
                p.Product as CatalogProduct,
                analyticsContext.listId,
                analyticsContext.listName
              )
            ) ?? []
        ),
        analyticsContext.listId,
        analyticsContext.listName
      );
    }
  };

/**
 * If a product is selected, then the product will be added to compare product, otherwise it will be removed
 *
 * @param product - Product to either add or remove from compare products
 * @param selected - Indicates if the product should be added or removed
 * @returns NULL
 */
export const selectComparisonProduct =
  (product: OrderEntryProduct, selected: boolean): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch): Promise<void> => {
    if (selected) await dispatch(addComparisonProduct(product));
    else dispatch(removeComparisonProduct(product.ProductKey));
  };

/**
 * Removed the product from product compare
 *
 * @param productKey - The product key of the product to be removed
 * @returns NULL
 */
export const removeComparisonProduct =
  (productKey: string | undefined): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch): Promise<void> => {
    if (!productKey) return;
    dispatch(productComparisonSlice.actions.removeComparisonProduct(productKey));
  };

/**
 * Added the product to product compare
 *
 * @param product - The product to be added
 * @returns NULL
 */
export const addComparisonProduct =
  (product: OrderEntryProduct): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(
      productComparisonSlice.actions.addComparisonProduct({
        product: product,
      })
    );
  };

/**
 * Either clears the compare products or sets compare product to the first product
 *
 * @param mode - The mode to be used to evaluate if compare product is cleared or set to the first product
 * @returns NULL
 */
export const clearComparisonProducts =
  (mode?: 'add' | 'replace'): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const products = selectComparisonProducts(getState().productComparison);
    if (mode === 'replace') {
      if (products.length > 0) {
        dispatch(productComparisonSlice.actions.setComparisonProducts([products[0]]));
        return;
      }
    }
    if (products[0].Product?.IsDiscontinued) {
      dispatch(productComparisonSlice.actions.setComparisonProducts([products[0]]));
      return;
    }
    dispatch(productComparisonSlice.actions.setComparisonProducts([]));
  };

/**
 * Sets the comparisonProducts, comparisonProductsLoading and sectionHeights values in the product compare slice to their inital values
 *
 * @returns NULL
 */
export const resetProductComparisons = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(productComparisonSlice.actions.resetProductComparisons());
};

/**
 * Sets the sectionHeights value in the product compare slice to its inital values
 *
 * @returns NULL
 */
export const resetSectionHeights = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(productComparisonSlice.actions.resetSectionHeights());
};

/**
 * Sets the sectionHeights value in the product compare slice
 *
 * @param section - The section which will have its height updated
 * @param height - The height the section will have
 * @returns NULL
 */
export const upsertSectionHeight =
  (section: ProductComparisonSection, height: number | undefined): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(productComparisonSlice.actions.upsertSectionHeight({ section, height }));
  };

/**
 * Calls the getProductComparisonProductDetail for each product key provided
 *
 * @param productKeys - The product keys of the lists to get
 * @param orderId - The id of the order
 * @returns NULL
 */
const getProductComparisonProductDetailList =
  (productKeys: string[], orderId?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    productKeys.forEach((productKey) => {
      dispatch(getProductComparisonProductDetail(productKey, orderId));
    });
  };

/**
 * Calls and stores the GetProductDetail API call
 *
 * @param productKeys - The product keys of the list
 * @param orderId - The id of the order
 * @returns NULL
 */
const getProductComparisonProductDetail =
  (productKey: string, orderId?: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const customerId = getState().customer.selectedCustomer?.CustomerId;

      if (!customerId || !productKey) return;

      dispatch(productComparisonSlice.actions.setComparisonProductLoading({ productKey, isLoading: true }));

      const apiRequest: GetProductDetailRequest = {
        ProductKey: productKey,
        CustomerId: customerId,
        ...(orderId && { OrderEntryHeaderId: orderId }),
      };

      const { data } = await productDetailService.getProductDetail(apiRequest);

      if (data.IsSuccess)
        dispatch(
          productComparisonSlice.actions.addComparisonProduct({
            product: data.ResultObject,
            detailLoaded: true,
          })
        );
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(productComparisonSlice.actions.setComparisonProductLoading({ productKey, isLoading: false }));
    }
  };

/**
 * Calls and stores the GetProductFooterList API call
 *
 * @param productKeys - Array of the product keys of the lists to get the footers of
 * @returns NULL
 */
const getProductComparisonProductFooterList =
  (productKeys: string[]): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const selectedCustomer = getState().customer.selectedCustomer;
      if (!selectedCustomer || !selectedCustomer.OperationCompanyNumber) return;

      const { data } = await productFooterService.getProductFooterList({
        ProductKeyList: productKeys,
        CustomerId: selectedCustomer.CustomerId,
        OperationCompanyNumber: selectedCustomer.OperationCompanyNumber,
      });

      if (data.IsSuccess) {
        productKeys.forEach((productKey: string, index: number) => {
          dispatch(
            productComparisonSlice.actions.setProductFooter({
              productKey: productKey,
              productFooter: data.ResultObject[index],
            })
          );
        });
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };
