import { Box, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { getHoursBetween, toLocalDateTimeString } from 'common';
import TypographySkeleton from '../../../_shared/components/TypographySkeleton';

interface CutoffDateDisplayProps {
  cutoffDateTime: Date | string | undefined;
  title?: string;
  dateTimeSeparator?: string;
  loading?: boolean;
  titleSx?: SxProps<Theme>;
  dateSx?: SxProps<Theme>;
  labelTestId?: string;
  dataTestId?: string;
}

const CutoffDateDisplay = (props: CutoffDateDisplayProps): JSX.Element => {
  const { cutoffDateTime, title, dateTimeSeparator, loading, titleSx, dateSx, labelTestId, dataTestId } = props;

  if (!cutoffDateTime) return <TypographySkeleton charCount={15} />;

  const cutoffDateString = cutoffDateTime.toString();
  const hasTimeZoneIndicator = cutoffDateString.charAt(cutoffDateString.length - 1).toUpperCase() === 'Z';
  const hoursToCutoff = getHoursBetween(
    `${cutoffDateTime}${hasTimeZoneIndicator ? '' : 'Z'}`,
    new Date().toISOString()
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Typography data-testid={labelTestId} sx={{ typography: { xs: 'paragraph' }, ...titleSx }}>
        {title ?? 'Cutoff:'}&nbsp;
      </Typography>
      {loading ? (
        <TypographySkeleton charCount={15} />
      ) : (
        <Typography
          data-testid={dataTestId}
          sx={{
            typography: { xs: 'paragraphBold' },
            color: (theme: Theme) =>
              hoursToCutoff < 4
                ? theme.palette.error.main
                : hoursToCutoff < 24
                ? theme.palette.warning.contrastText
                : theme.palette.coolGrey[600],
            ...dateSx,
          }}
        >
          {toLocalDateTimeString(cutoffDateTime, true, dateTimeSeparator)}
        </Typography>
      )}
    </Box>
  );
};

export default CutoffDateDisplay;
