import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { History } from 'history';
import { toOrderEntryProductsFromCatalogProducts } from '../../api/mappers/order-mappers';
import { APIMessages } from '../../api/models/api-shared.models';
import { CustomerProductPriceResult } from '../../api/models/customer-product-price.models';
import {
  ProductSearchFilter,
  SearchProductCatalogRequest,
  TypeAheadRequest,
  TypeAheadResponse,
} from '../../api/models/product-catalog.models';
import ProductSearchService from '../../api/services/product-search.service';
import TypeaheadService from '../../api/services/typeahead.service';
import { AnalyticsContext } from '../../helpers';
import { getFilteredProductPriceRequests } from '../../helpers/general/product';
import { getAnalyticsItemData } from '../../helpers/utilities/google-analytics/google-analytics.mappers';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { AppRoute } from '../../models';
import { setErrorDialogContent } from '../common';
import { getCustomerProductPrices } from '../common/customer.thunks';
import { isAdvanceFilterUnset, ProductSearchHistoryState, updateAdvanceFilterObject } from '../product-search-shared';
import { AppDispatch, AppThunk, RootState } from '../store';
import { productComparisonSlice } from './product-compare.slice';
import { resetProductComparisons } from './product-compare.thunks';
import {
  productSearchSlice,
  selectAllCatalogProducts,
  selectProductSearchCatalogProductsByPage,
} from './product-search.slice';

import { LruObjectCache } from 're-reselect';
import CustomerProductPriceService from '../../api/services/customer-product-price.service';
import { ordersSlice } from '../orders/orders.slice';

// hooks
const productSearchService = ProductSearchService.getInstance();
const typeAheadService = TypeaheadService.getInstance();
const customerProductPriceService = CustomerProductPriceService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

const cache = new LruObjectCache({ cacheSize: 50 });

/**
 * Sets the products, productSearchResult, apiRequest and the productPriceLoading values in the orders slice to their initial values
 * and stops any calls to the SearchProductCatalog and GetCustomerProductPrice API calls
 *
 * @returns NULL
 */
export const resetProductSearchResults = (): AppThunk => (dispatch: AppDispatch) => {
  productSearchService.abortSearchProductCatalog();
  customerProductPriceService.abortGetCustomerProductPrice();
  dispatch(productSearchSlice.actions.resetProductSearchResults());
  dispatch(ordersSlice.actions.setProductPriceLoading(false));
};

/**
 * Sets the product and comparisonProducts price in the product-search slice
 *
 * @param priceResults - The products and price information
 * @returns NULL
 */
export const setProductSearchPrices =
  (priceResults: CustomerProductPriceResult[]): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(productSearchSlice.actions.setProductPrices(priceResults));
    dispatch(productComparisonSlice.actions.setProductComparisonPrices(priceResults));
  };

/**
 * Calls the getCustomerProductPrices method from customer.thunks.ts with all catalog products being passed in
 *
 * @returns NULL
 */
export const getProductSearchPrices = (): AppThunk => (dispatch: AppDispatch, getState: () => RootState) => {
  const catalogProducts = selectAllCatalogProducts(getState());
  dispatch(
    getCustomerProductPrices(
      getFilteredProductPriceRequests(toOrderEntryProductsFromCatalogProducts(catalogProducts)),
      (priceResults: CustomerProductPriceResult[]) => dispatch(setProductSearchPrices(priceResults))
    )
  );
};

/**
 * Calls the getCustomerProductPrices method from customer.thunks.ts with all catalog products on the page being passed in
 *
 * @param pageIndex - The page index to get the product from
 * @returns NULL
 */
export const getProductSearchProductPricesByPage =
  (pageIndex: number): AppThunk =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const products = selectProductSearchCatalogProductsByPage(getState(), pageIndex);
    dispatch(
      getCustomerProductPrices(
        getFilteredProductPriceRequests(products),
        (priceResults: CustomerProductPriceResult[]) => dispatch(setProductSearchPrices(priceResults))
      )
    );
  };

/**
 * Updates the search filters
 *
 * @param history - Used to fix the URL to match the updated filters
 * @param filters - The filter information to be set
 * @returns NULL
 */
export const updateProductSearchFilters =
  (history: History<ProductSearchHistoryState>, ...filters: ProductSearchFilter[]): AppThunk =>
  () => {
    const advanceFilter = updateAdvanceFilterObject(history.location.state?.advanceFilter, filters);
    history.replace(history.location.pathname + history.location.search, {
      ...history.location.state,
      advanceFilter,
    });
  };

/**
 * Clears the search filters
 *
 * @param history - Used to fix the URL to match the updated filters
 * @param parentRoute - The parent route used to fix the URL
 * @returns NULL
 */
export const clearProductSearchFilters =
  (history: History<ProductSearchHistoryState>, parentRoute?: AppRoute): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(productSearchSlice.actions.resetProductSearchResults());
    history.replace(history.location.pathname + history.location.search, {
      ...history.location.state,
      parentRoute: parentRoute ?? history.location.state.parentRoute,
      advanceFilter: undefined,
    });
  };

/**
 * Sets up, calls and stores the results of the SearchProductCatalog API call
 *
 * @param request - Has the query text, history and pageIndex to be used to make the API call
 * @param analyticsContext - The analytics context to be used
 * @returns NULL
 */
export const searchProductCatalog =
  (
    request: { queryText: string; history: History<ProductSearchHistoryState>; pageIndex?: number },
    analyticsContext?: AnalyticsContext
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const history = request.history;
      const historyState = history.location.state;

      const selectedCustomer = getState().customer.selectedCustomer;
      const activeOrderId = getState().orders.activeOrder?.OrderEntryHeaderId;
      const deliveryDate = getState().orders.activeOrder?.DeliveryDate;
      const advanceFilter = historyState?.advanceFilter ?? {
        IsCriticalItem: undefined,
        IsNewInStock: undefined,
        HasOrderedInLastNumberOfDays: undefined,
        HasPreviousPurchase: undefined,
        Badges: [],
        CategoryIds: [],
        Brand: undefined,
        Brands: [],
        PackSize: undefined,
        StorageTypes: [],
        DeliveryOptions: {},
        Nutritional: {},
      };

      const pageSize = 25;
      const pageIndex = request.pageIndex ?? 0;
      const skip = (request.pageIndex === undefined ? 0 : getState().productSearch.productSearchResult?.Skip) ?? 0;

      const searchValue = request.queryText;

      if (!selectedCustomer || !advanceFilter || !deliveryDate) return;
      const orderEntryHeaderId = activeOrderId || '00000000-0000-0000-0000-000000000000';
      const apiRequest: SearchProductCatalogRequest = {
        BusinessUnitKey: selectedCustomer.BusinessUnitKey,
        OperationCompanyNumber: selectedCustomer.OperationCompanyNumber,
        CustomerId: selectedCustomer.CustomerId,
        DeliveryDate: deliveryDate,
        CurrentPageNumber: pageIndex,
        PageSize: pageSize,
        QueryText: searchValue,
        Skip: skip,
        OrderEntryHeaderId: orderEntryHeaderId,
        LoadPricing: false,
        AdvanceFilter: advanceFilter,
      };

      if (!getState().productSearch.apiRequest) dispatch(resetProductComparisons());

      // Data in store is for search with different parameters (excluding page number and skip)
      if (
        JSON.stringify({ ...apiRequest, CurrentPageNumber: 0, Skip: 0 }) !==
        JSON.stringify({ ...getState().productSearch.apiRequest, CurrentPageNumber: 0, Skip: 0 })
      ) {
        dispatch(resetProductSearchResults());
      }

      const priorRequest = getState().productSearch.apiRequest;
      if (priorRequest && pageIndex <= priorRequest?.CurrentPageNumber && skip <= priorRequest?.Skip) {
        return;
      }

      dispatch(productSearchSlice.actions.setApiRequest(apiRequest));

      dispatch(
        productSearchSlice.actions.setPageLoading({
          pageIndex: pageIndex,
          pageSize: pageSize,
          isLoading: true,
        })
      );

      if (pageIndex > 0)
        analyticsContext?.analytics?.event?.(analyticsContext.listId, 'load_more', orderEntryHeaderId, pageIndex);

      const { data } = await productSearchService.searchProductCatalog(apiRequest);

      if (data.IsSuccess) {
        if (isAdvanceFilterUnset(history.location.state?.advanceFilter)) {
          history.replace(history.location.pathname + history.location.search, {
            ...history.location.state,
            cachedFacets: data.ResultObject.Facets,
          });
        }

        dispatch(
          productSearchSlice.actions.setProductSearchResults({ request: apiRequest, result: data.ResultObject })
        );

        analyticsContext?.analytics?.viewItemList?.(
          data.ResultObject.CatalogProducts.flatMap((p, index) =>
            p.UnitOfMeasureOrderQuantities.map((uom) =>
              getAnalyticsItemData(
                p.ProductKey ?? '',
                uom.UnitOfMeasure.toString(),
                uom.Price ?? 0,
                uom.Quantity,
                index,
                uom,
                p,
                analyticsContext.listId,
                analyticsContext.listName
              )
            )
          ),
          analyticsContext.listId,
          analyticsContext.listName
        );

        if (!data.ResultObject.HasLoadMore)
          analyticsContext?.analytics?.event?.(analyticsContext.listId, 'end_of_list', analyticsContext.listName);
      } else {
        dispatch(productSearchSlice.actions.setApiRequest(undefined));
        dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
        dispatch(
          productSearchSlice.actions.setPageLoaded({
            pageIndex: pageIndex,
            catalogProducts: [],
          })
        );
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      dispatch(productSearchSlice.actions.setApiRequest(undefined));
    }
  };

/**
 * Calls and stores the results of the GetTypeAhead API call
 *
 * @param searchValue - The value being searched for
 * @returns NULL
 */
export const getTypeAheadSearchResults =
  (searchValue: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    searchValue = (searchValue || '').toLowerCase().trim();
    dispatch(productSearchSlice.actions.setSearchValue(searchValue));

    const request: TypeAheadRequest = { SearchValue: searchValue };
    const selectedCustomer = getState().customer.selectedCustomer;

    const cacheHitResult = cacheGet(selectedCustomer?.CustomerId, searchValue);
    if (cacheHitResult) {
      dispatch(productSearchSlice.actions.setTypeAheadResults(cacheHitResult));
      return;
    }

    try {
      request.BusinessUnitKey = selectedCustomer?.BusinessUnitKey;
      request.CustomerId = selectedCustomer?.CustomerId;
      const { data: result } = await typeAheadService.getTypeAheadSearchResults(request);
      if (request.SearchValue !== getState().productSearch.searchValue) {
        if (result.IsSuccess) {
          cacheSet(selectedCustomer?.CustomerId, searchValue, result.ResultObject);
        }
        return;
      }
      const typeAheadAPIMessages: APIMessages = {
        IsError: false,
      };
      if (result.IsSuccess) {
        const typeAheadResult: TypeAheadResponse = result.ResultObject;
        if (result.InformationMessages && result.InformationMessages.length > 0) {
          typeAheadAPIMessages.InformationMessages = result.InformationMessages;
          dispatch(productSearchSlice.actions.setTypeAheadAPIMessages(typeAheadAPIMessages));
        }
        cacheSet(selectedCustomer?.CustomerId, searchValue, typeAheadResult);

        dispatch(productSearchSlice.actions.setTypeAheadResults(typeAheadResult));
      } else {
        if (result.ErrorMessages && result.ErrorMessages.length > 0) {
          typeAheadAPIMessages.ErrorMessages = result.ErrorMessages;
          typeAheadAPIMessages.IsError = true;
          dispatch(productSearchSlice.actions.setTypeAheadAPIMessages(typeAheadAPIMessages));
        }
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls and caches the results of the GetTypeAhead API call
 *
 * @param searchValue - The value being searched for
 * @returns NULL
 */
export const cacheTypeAheadSearchResults =
  (searchValue: string): AppThunk =>
  async (_dispatch: AppDispatch, getState) => {
    searchValue = (searchValue || '').toLowerCase().trim();
    const request: TypeAheadRequest = { SearchValue: searchValue };
    const selectedCustomer = getState().customer.selectedCustomer;

    const cacheResult = cacheGet(selectedCustomer?.CustomerId, searchValue);
    if (cacheResult) {
      // already cached
      return;
    }

    try {
      request.BusinessUnitKey = selectedCustomer?.BusinessUnitKey;
      request.CustomerId = selectedCustomer?.CustomerId;
      const { data: result } = await typeAheadService.getTypeAheadSearchResults(request);

      if (result.IsSuccess) {
        cacheSet(selectedCustomer?.CustomerId, searchValue, result.ResultObject);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls the DeleteSearchHistory API call
 *
 * @param searchValue - The value being searched for
 * @param userCustomerSearchHistoryId - The id of the user's search history
 * @returns NULL
 */
export const deleteSearchHistory =
  (searchValue: string, userCustomerSearchHistoryId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(clearTypeAheadResults());
      const { data: result } = await typeAheadService.deleteSearchHistory({
        UserCustomerSearchHistoryId: userCustomerSearchHistoryId,
      });
      if (result.IsSuccess) {
        cache.clear();
        dispatch(cacheTypeAheadSearchResults(searchValue));
        if (searchValue) {
          dispatch(cacheTypeAheadSearchResults(''));
        }
      }
    } catch (error) {
      console.error(error);
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sets the typeAheadResults value in the product-search slice to its initial values
 *
 * @returns NULL
 */
export const clearTypeAheadResults = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(
    productSearchSlice.actions.setTypeAheadResults({
      Suggestions: [],
      SearchHistory: [],
    })
  );
};

/**
 * Clears the type ahead search cache
 *
 * @returns NULL
 */
export const clearTypeAheadSearchCache = (): AppThunk => async () => cache.clear();

/**
 * Constructs and returns the cache key
 *
 * @param customerId - The customer's id
 * @param searchTerm - The value being searched
 * @returns The cache key
 */
const getCacheKey = (customerId: string | undefined, searchTerm: string | undefined) => {
  customerId = (customerId || '').toLowerCase().trim();
  searchTerm = (searchTerm || '').toLowerCase().trim();
  return `${customerId}_${searchTerm}`;
};

/**
 * Sets a value in the cache
 *
 * @param customerId - The customer's id
 * @param searchTerm - The value being searched
 * @param value - The value being stored
 * @returns NULL
 */
const cacheSet = (customerId: string | undefined, searchTerm: string, value: TypeAheadResponse): void => {
  if (customerId) {
    cache.set(getCacheKey(customerId, searchTerm), value);
  }
};

/**
 * Gets a value in the cache
 *
 * @param customerId - The customer's id
 * @param searchTerm - The value being searched
 * @returns NULL
 */
const cacheGet = (customerId: string | undefined, searchTerm: string): TypeAheadResponse | undefined =>
  cache.get(getCacheKey(customerId, searchTerm));
