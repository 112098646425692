import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { FileResult } from '../../api/models/api-shared.models';
import {
  CreateImportProductListRequest,
  CreateImportProductListResponse,
} from '../../api/models/product-list-header.models';
import ProductListHeaderService from '../../api/services/product-list-header.service';
import { getFormattedStringFromFile } from '../../helpers/general/files';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { Resolution } from '../../models';
import {
  UserActivityAction,
  UserActivityActionSummary,
  UserActivityPageName,
} from '../../models/user-activity-report.models';
import { setErrorDialogContent } from '../common/global.thunks';
import { logUserActivity } from '../common/user-activity.thunks';
import { AppDispatch, AppThunk } from '../store';

const service = ProductListHeaderService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Retrieves csv file data for a template to import a list of products with
 * @param customerId - passed to the logUserActivity thunk
 * @param resolution - passed to the logUserActivity thunk
 * @param onSuccess - callback method executed when the api response is successful
 * @param onError - callback method executed when the api response is unsuccessful
 * @returns
 */
export const getProductListImportTemplate =
  (customerId: string, resolution: Resolution, onSuccess: (file: FileResult) => void, onError: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await service.getProductListImportTemplate();
      if (data.IsSuccess) {
        await dispatch(
          logUserActivity({
            action: UserActivityAction.DownloadProductListImportTemplate,
            pageName: UserActivityPageName.ProductListImport,
            actionSummary: UserActivityActionSummary.DownloadProductListImportTemplate,
            resolution: resolution,
            customerId: customerId,
          })
        );
        onSuccess(data.ResultObject);
      } else {
        onError();
      }
    } catch (error) {
      onError();
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Dispatches the createProductListFromFileString thunk with a request generated from each file in the fileList
 * @param fileList - a collection of files to be imported as a product list
 * @param isPrivate - indicates whether the product list should be visible to other users
 * @param title - the title of the product list
 * @param customerId - the id that represents the customer associated to the list
 * @param ignoreErrors - a flag passed to the api to skip records in the file that do not relate to existing product data
 * @param resolution - passed to the logUserActivity thunk
 * @param onSuccess - the callback method executed when api repsonse is successful
 * @param onError- the callback method executed when api repsonse is unsuccessful
 * @returns
 */
export const createProductListFromImportedFileList =
  (
    fileList: FileList,
    isPrivate: boolean,
    title: string,
    customerId: string,
    ignoreErrors: boolean,
    resolution: Resolution,
    onSuccess: (result: CreateImportProductListResponse) => void,
    onError: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(
        logUserActivity<{ ProductListHeaderId: string }>({
          action: UserActivityAction.ImportProductList,
          pageName: UserActivityPageName.ListDetail,
          actionSummary: UserActivityActionSummary.ProductListImport,
          resolution: resolution,
        })
      );

      for (const file of Object.values(fileList)) {
        if (file.type !== 'text/csv' && file.type !== 'application/vnd.ms-excel') {
          dispatch(setErrorDialogContent('Error occurred', ['Invalid file type.']));
          onError();
          return;
        }

        const formattedString = await getFormattedStringFromFile(file);
        const request: CreateImportProductListRequest = {
          ImportFile: formattedString,
          IsPrivate: isPrivate,
          ProductListTitle: title,
          CustomerId: customerId,
          IgnoreErrors: ignoreErrors,
        };
        dispatch(createProductListFromFileString(request, onSuccess, onError));
      }
    } catch (error) {
      onError();
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sends a request to the api to import product list from a csv file
 * @param request - the request to send to the api
 * @param onSuccess - the callback method executed when api repsonse is successful
 * @param onError - the callback method executed when api repsonse is unsuccessful
 * @returns
 */
export const createProductListFromFileString =
  (
    request: CreateImportProductListRequest,
    onSuccess: (result: CreateImportProductListResponse) => void,
    onError: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await service.createProductListFromImportCSV(request);
      if (data.IsSuccess) {
        onSuccess(data.ResultObject);
      } else {
        onError();
      }
      if (data.ErrorMessages?.length > 0) {
        dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
      }
    } catch (error) {
      onError();
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };
