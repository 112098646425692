import { Box, Icon, IconButton, Skeleton } from '@mui/material';
import {
  OrderCartProductUnitOfMeasure,
  RootState,
  selectUserPermissionSetting,
  toCurrencyString,
  toQuantityString,
  useAppSelector,
} from 'common';
import { useBreakpoint } from '../../../../../../../../../../../hooks/useBreakpoint';
import { StyledGridItemRightBorder, StyledParagraphSmall, StyledParagraphSmallBold } from './ProductLineItems.styles';

export type ProductLineItemsProps =
  | {
      lineData: OrderCartProductUnitOfMeasure;
      lastButtonRef?: React.RefObject<HTMLButtonElement>;
      onLastButtonKeyDown?: React.KeyboardEventHandler<HTMLElement>;
      onClickDelete: (item: OrderCartProductUnitOfMeasure) => void;
      loadingPlaceholder?: never;
    }
  | {
      loadingPlaceholder: true;
    };

export const ProductLineItems = (props: ProductLineItemsProps): JSX.Element => {
  const { breakpoint } = useBreakpoint();
  const canViewPrice = useAppSelector((s: RootState) => selectUserPermissionSetting(s).CanViewPrices);

  if (props.loadingPlaceholder) {
    return (
      <Box sx={{ alignItems: 'center', display: 'flex', width: '100%', gap: 1 }}>
        <StyledGridItemRightBorder sx={{ width: '20%' }}>
          <Skeleton>
            <StyledParagraphSmall sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre' }}>
              test
            </StyledParagraphSmall>
          </Skeleton>
        </StyledGridItemRightBorder>
        {canViewPrice && (
          <StyledGridItemRightBorder sx={{ width: '25%', pr: 1 }}>
            <Skeleton>
              <StyledParagraphSmall sx={{ justifyContent: 'right' }}>$500/23</StyledParagraphSmall>
            </Skeleton>
          </StyledGridItemRightBorder>
        )}
        <Box sx={{ width: '26%' }}>
          <Skeleton>
            <StyledParagraphSmall>53 CS</StyledParagraphSmall>
          </Skeleton>
        </Box>
        {canViewPrice && (
          <Box display='flex' alignItems='center' justifyContent='right'>
            <Skeleton>
              <StyledParagraphSmallBold sx={{ width: '25%', justifyContent: 'right' }}>
                1269.20
              </StyledParagraphSmallBold>
            </Skeleton>
          </Box>
        )}
      </Box>
    );
  }

  const { lineData, onClickDelete } = props;
  const priceUOM = lineData.IsCatchWeight ? 'lb' : lineData.UnitOfMeasureDisplay;

  return (
    <Box sx={{ alignItems: 'center', display: 'flex', width: '100%', gap: 1 }}>
      <StyledGridItemRightBorder sx={{ width: '20%' }}>
        <StyledParagraphSmall sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre' }}>
          {lineData.ProductNumber}
        </StyledParagraphSmall>
      </StyledGridItemRightBorder>
      {canViewPrice &&
        (lineData.Price ? (
          <StyledGridItemRightBorder sx={{ width: '25%', pr: 1 }}>
            <StyledParagraphSmall sx={{ textAlign: 'right' }}>
              {toCurrencyString(lineData.Price)}/{priceUOM}
            </StyledParagraphSmall>
          </StyledGridItemRightBorder>
        ) : (
          <StyledGridItemRightBorder sx={{ width: '25%', pr: 1 }}>
            <StyledParagraphSmall sx={{ textAlign: 'center' }}>N/A</StyledParagraphSmall>
          </StyledGridItemRightBorder>
        ))}
      <Box sx={{ width: '26%' }}>
        <StyledParagraphSmall>
          {toQuantityString(lineData.Quantity)} {lineData.UnitOfMeasureDisplay}
        </StyledParagraphSmall>
      </Box>
      <Box sx={canViewPrice ? { width: '25%' } : { width: '50%' }}>
        {canViewPrice &&
          (lineData.ExtendedPrice ? (
            <StyledParagraphSmallBold sx={{ textAlign: 'right' }}>
              {toCurrencyString(lineData.ExtendedPrice, 2)}
            </StyledParagraphSmallBold>
          ) : (
            <StyledParagraphSmallBold sx={{ textAlign: 'right' }}>--</StyledParagraphSmallBold>
          ))}
      </Box>
      <IconButton
        aria-label='delete'
        color='primary'
        size={breakpoint === 'xl' ? 'small' : 'medium'}
        sx={{ width: '4%', pr: 0, maxWidth: 20, visibility: lineData.CanDeleteUom ? 'visible' : 'hidden' }}
        onKeyDown={props.onLastButtonKeyDown}
        onClick={() => onClickDelete(lineData)}
      >
        <Icon className='fa-trash' color='primary' fontSize={breakpoint === 'xl' ? 'extraSmall' : 'small'} />
      </IconButton>
    </Box>
  );
};
