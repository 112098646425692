import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { replace } from 'connected-react-router';
import { ServiceResult } from '../../api';
import { UserCustomer, UserSite } from '../../api/models/site.models';
import { UpdateUserRequest } from '../../api/models/user.models';
import { VerifyContactEmailAddressRequest, VerifyPendingUserRequest } from '../../api/models/verify-user.models';
import SiteService from '../../api/services/site.service';
import UserSettingService from '../../api/services/user-setting.service';
import UserService from '../../api/services/user.service';
import VerifyUserService from '../../api/services/verify-user.service';
import { CfGa, hasUserAcceptedEssentialTerms } from '../../helpers';
import { generateStaticId } from '../../helpers/lookups';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { AppRoutes, Resolution, RouteName } from '../../models';
import { NotificationDisplayType, NotificationKeys, NotificationType } from '../../models/notifications.models';
import {
  UserActivityAction,
  UserActivityActionSummary,
  UserActivityPageName,
} from '../../models/user-activity-report.models';
import { getOverviewViewedSlideGroups } from '../account/customer-support.thunks';
import { AppDispatch, AppThunk, RootState } from '../store';
import { updateSelectedCustomer } from './customer.thunks';
import { globalSlice } from './global.slice';
import { upsertAppNotification } from './global.thunks';
import { logUserActivity } from './user-activity.thunks';
import { userSlice } from './user.slice';

// hooks
const siteService = SiteService.getInstance();
const userService = UserService.getInstance();
const userSettingService = UserSettingService.getInstance();
const verifyUserService = VerifyUserService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

// thunks
/**
 * Updates the UserState's currentUserSite property to undefined and redirects users to the NoUserInformation page
 *
 * @param lorem - lorem
 * @returns void
 */
export const handleNoUserInformation = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(userSlice.actions.setCurrentUserSite(undefined));
  dispatch(replace(AppRoutes[RouteName.NoUserInformation].Path));
};

/**
 * Retrieves UserSite and UserSetting data from the api, updates the UserState properties for each,
 * and sets the selectedCustomer to the first UserCustomer returned in the UserSite data
 *
 * @param params.fetchSettings - boolean flag used to determine whether a request should be sent to retrieve the UserSetting data for the authorized user before retrieving UserSite data.
 * @returns Promise<UserSite|undefined> a promise of the api response's result of the UserSite data
 */
export const getCurrentUserSite =
  (params?: { fetchSettings?: boolean }): AppThunk<Promise<UserSite | undefined>> =>
  async (dispatch: AppDispatch): Promise<UserSite | undefined> => {
    try {
      const {
        data: { IsSuccess: isSuccess, ResultObject: userSiteResult },
      } = await siteService.getCurrentUserSite();

      if (isSuccess) {
        // If user has yet to accept all terms then return the result without loading settings or setting customer data
        if (!hasUserAcceptedEssentialTerms(userSiteResult)) {
          dispatch(userSlice.actions.setCurrentUserSite(userSiteResult));
          return Promise.resolve(userSiteResult);
        }

        // only fetch overview slide data when user has accepted all terms
        dispatch(getOverviewViewedSlideGroups());

        // only load settings if user has accepted all terms
        const userSettingResult = params?.fetchSettings ? userSettingService.getCurrentUserSetting() : null;

        // Fetch Settings (if necessary), then set UserSite in store
        // Ensure we have settings when App renders
        if (userSettingResult) {
          const { data } = await userSettingResult;
          if (data.IsSuccess) {
            dispatch(userSlice.actions.setUserSettingPreference(data.ResultObject));
          }
        }

        if (userSiteResult.UserCustomers.length > 0) {
          dispatch(updateSelectedCustomer(userSiteResult.UserCustomers[0]));
        }

        // Set the userSite state last to avoid triggering extra api calls e.g. getCustomer
        dispatch(userSlice.actions.setCurrentUserSite(userSiteResult));
        return Promise.resolve(userSiteResult);
      } else {
        return Promise.resolve(undefined);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      return Promise.resolve(undefined);
    }
  };

/**
 * Replaces the user customer in userSite to update par related values based in id. Intended for use in order entry's par dialog
 *
 * @param userCustomer - New customer value
 * @returns NULL
 */
export const setUserCustomerParSettings =
  (userCustomer: UserCustomer): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const userSite = getState().user.userSite;
      const userCustomers = getState().user.userSite?.UserCustomers;
      const index = userCustomers?.findIndex((customer) => customer.CustomerId === userCustomer.CustomerId);

      if (userSite && userCustomers && index !== -1) {
        let userCustomersAugmented: UserCustomer[] = [];
        userCustomers.forEach((customer, currentIndex) =>
          currentIndex === index ? userCustomersAugmented.push(userCustomer) : userCustomersAugmented.push(customer)
        );

        const userSiteAugmented: UserSite = {
          ...userSite,
          UserCustomers: userCustomersAugmented,
        };
        dispatch(userSlice.actions.setCurrentUserSite(userSiteAugmented));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Retrieves user data from the api's GetUsersByCustomerAccess endpoint and updates the usersByCustomerAccess property of the UserState
 *
 * @returns void
 */
export const getUsersByCustomerAccess = (): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    if (getState().user.usersByCustomerAccessLoading) {
      return;
    }

    dispatch(userSlice.actions.setUsersByCustomerAccessLoading(true));
    const { data } = await userService.getUsersByCustomerAccess();

    if (data.IsSuccess) {
      dispatch(userSlice.actions.setUsersByCustomerAccess(data.ResultObject));
    } else {
      dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
    }
  } catch (error) {
    appInsightsLogger.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
    console.error(error);
  } finally {
    dispatch(userSlice.actions.setUsersByCustomerAccessLoading(false));
  }
};

/**
 * Sends the following requests to the api - (1) update the Terms of Use Acceptance, (2) update the User Privacy Policy Acceptance, (3) get the UserSite data of the authorized user.
 *
 * @returns void
 */
export const updateTermsOfService = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    const requests = [userService.updateUserTermsOfUseAcceptance(), userService.updateUserPrivacyPolicyAcceptance()];
    await Promise.all(requests);
    dispatch(getCurrentUserSite());
  } catch (error) {
    appInsightsLogger.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
  }
};

/**
 * Sends the following requests to the api - (1) update the Cookies acceptance, (2) update the Analytics Cookies acceptance, (3) get the UserSite data of the authorized user.
 *
 * @returns void
 */
export const updateCookies =
  (
    hasPreviouslyAcceptedCookies: boolean,
    hasAcceptedAnalyticsCookies: boolean,
    analyticsCookiesAccepted: boolean,
    analytics?: CfGa
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const requests: ServiceResult<undefined>[] = [];

      if (!hasPreviouslyAcceptedCookies) {
        requests.push(userService.updateUserCookiesAcceptance());
      }
      if (hasAcceptedAnalyticsCookies !== analyticsCookiesAccepted) {
        analytics?.updateConsent(analyticsCookiesAccepted);
        requests.push(userService.updateUserAnalyticCookiesAcceptance(true));
      }
      await Promise.all(requests);
      dispatch(getCurrentUserSite());
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Retrieves data related to the authorized user from the api
 *
 * @returns void
 */
export const getCurrentUser = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    const { data } = await userService.getCurrentUser();

    if (data.IsSuccess) {
      dispatch(userSlice.actions.setUser(data.ResultObject));
    } else {
      dispatch(
        globalSlice.actions.setErrorDialogContent({
          title: 'An Error Ocurred',
          messages: data.ErrorMessages,
        })
      );
    }
  } catch (error: unknown) {
    appInsightsLogger.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
  }
};

/**
 * Sends a request to the api to update user data of the authorized user and updates the UserState accordingly
 *
 * @param email - the email address of the user
 * @param firstName - the first name of the user
 * @param lastName - the last name of the user
 * @param mobilePhone - the mobile phone numnber of the user
 * @param workPhone - the work phone numberof the user
 * @param resolution - the resolution of the viewport/device sending the request
 * @returns void
 */
export const updateUser =
  (
    email: string | undefined,
    firstName: string | undefined,
    lastName: string | undefined,
    mobilePhone: string | undefined,
    workPhone: string | undefined,
    resolution: Resolution
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      const request: UpdateUserRequest = {
        FirstName: firstName,
        LastName: lastName,
        MobilePhoneNumber: mobilePhone,
        WorkPhoneNumber: workPhone,
        ContactEmailAddress: email,
      };

      const { data } = await userService.updateUser(request);

      if (data.IsSuccess) {
        dispatch(
          logUserActivity({
            action: UserActivityAction.UpdateUserProfile,
            pageName: UserActivityPageName.UserProfile,
            actionSummary: UserActivityActionSummary.UserUpdatedUserProfile,
            resolution: resolution,
          })
        );
        const emailChanged: boolean = email?.toLowerCase() !== getState().user.user?.ContactEmailAddress?.toLowerCase();
        dispatch(
          upsertAppNotification(
            {
              Id: generateStaticId('toastNotification', ['user-profile-updated']),
              NotificationType: emailChanged ? NotificationType.Warning : NotificationType.Success,
              NotificationDisplayType: NotificationDisplayType.Toast,
              AutoDismiss: 0,
              CanUserDismiss: true,
              Key: NotificationKeys.Toast,
              Icon: emailChanged ? 'fa-exclamation-triangle' : 'fa-check-circle',
              Message: emailChanged
                ? 'New email address has been saved but not yet verified. A verification email was sent.'
                : 'Profile updated',
            },
            0
          )
        );
        dispatch(userSlice.actions.setUserFirstName(data.ResultObject.FirstName));
        dispatch(userSlice.actions.setUserLastName(data.ResultObject.LastName));
        dispatch(userSlice.actions.setUser(data.ResultObject));
      } else {
        dispatch(
          globalSlice.actions.setErrorDialogContent({
            title: 'An Error Ocurred',
            messages: data.ErrorMessages,
          })
        );
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sends a request to the api to resend an email that allows the user to verify their contact information email address
 *
 * @param resolution - the resolution of the viewport/device sending the request
 * @returns void
 */
export const resendVerifyContactEmail =
  (resolution: Resolution): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await userService.resendContactEmailVerification();

      if (data.IsSuccess) {
        dispatch(
          logUserActivity({
            action: UserActivityAction.SendContactEmailVerification,
            pageName: UserActivityPageName.UserProfile,
            actionSummary: UserActivityActionSummary.SentContactEmailVerification,
            resolution: resolution,
          })
        );
        dispatch(
          upsertAppNotification(
            {
              Id: generateStaticId('toastNotification', ['verify-email-sent']),
              NotificationType: NotificationType.Success,
              NotificationDisplayType: NotificationDisplayType.Toast,
              AutoDismiss: 0,
              CanUserDismiss: true,
              Key: NotificationKeys.Toast,
              Icon: 'fa-check-circle',
              Message: 'Contact verification email sent',
            },
            0
          )
        );
      } else {
        dispatch(
          globalSlice.actions.setErrorDialogContent({
            title: 'An Error Ocurred',
            messages: data.ErrorMessages,
          })
        );
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Updates the UserState's showReviewProfileDialog property
 *
 * @param display - the value to set the property to
 * @returns void
 */
export const toggleReviewProfileDialog =
  ({ display }: { display: boolean }): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setShowReviewProfileDialog(display));
  };

/**
 * Updates the UserState's showPriorityMessageDialog property
 *
 * @param display - the value to set the property to
 * @returns void
 */
export const togglePriorityMessagesDialog =
  ({ display }: { display: boolean }): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setShowPriorityMessagesDialog(display));
  };

/**
 * Updates the UserState's displayCustomerLocationToast property
 *
 * @param display - the value to set the property to
 * @returns void
 */
export const toggleCustomerLocationToast =
  ({ display }: { display: boolean }): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.toggleCustomerLocationToast({ display }));
  };

/**
 * Sends a request to the api to update the last profile review data and sets the UserState's showReviewProfileDialog property to false
 *
 * @param callback - executed after the request is sent (regardless of the response)
 * @returns void
 */
export const updateLastProfileReviewDate =
  (callBack: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      await userService.updateLastProfileReviewDate();
      dispatch(toggleReviewProfileDialog({ display: false }));
      callBack();
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sends a request to the api to validate the verification code and email address combination submitted by a user (used to validate the contact information email address of a user)
 *
 * @param vcode - the value of the verification code submitted by the user
 * @param email - the contact information email address submitted by the user
 * @returns void
 */
export const verifyContactEmailAddress =
  (vcode: string, email?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const request: VerifyContactEmailAddressRequest = {
        VerificationCode: vcode,
        EmailAddress: email,
      };

      const { data } = await verifyUserService.verifyContactEmailAddress(request);

      if (data.IsSuccess) {
        dispatch(
          upsertAppNotification(
            {
              Id: generateStaticId('toastNotification', ['success-contact-email-verification']),
              NotificationType: NotificationType.Success,
              NotificationDisplayType: NotificationDisplayType.Toast,
              AutoDismiss: 0,
              CanUserDismiss: true,
              Key: NotificationKeys.Toast,
              Message: 'New email address has been verified',
            },
            0
          )
        );
      } else {
        dispatch(
          upsertAppNotification(
            {
              Id: generateStaticId('toastNotification', ['failed-contact-email-verification']),
              NotificationType: NotificationType.Error,
              NotificationDisplayType: NotificationDisplayType.Toast,
              AutoDismiss: 0,
              CanUserDismiss: true,
              Key: NotificationKeys.Toast,
              Message: 'Unable to Verify Email Address',
              Icon: 'fa-exclamation-triangle',
            },
            0
          )
        );
        dispatch(userSlice.actions.setEmailVerificationSuccessful(false));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(userSlice.actions.setEmailVerificationComplete(true));
    }
  };

/**
 * Sends a request to the api to update the user's status from pending and updates the UserState's pendingUserVerification property with the response
 *
 * @param email - the email address of the user
 * @param ipAddress - the ip address of the device sending the request
 * @returns void
 */
export const verifyPendingUser =
  (email: string, ipAddress: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(userSlice.actions.setPendingUserVerificationLoading(true));
      const request: VerifyPendingUserRequest = {
        Email: email,
        RequestIpAddress: ipAddress,
      };

      const { data } = await verifyUserService.verifyPendingUser(request);

      if (data.IsSuccess) {
        dispatch(userSlice.actions.setPendingUserVerification(data.ResultObject));
      } else {
        dispatch(userSlice.actions.setPendingUserVerificationError(true));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(userSlice.actions.setPendingUserVerificationLoading(false));
    }
  };

/**
 * Resets the UserState's properties related to pending user verification to their initial values
 *
 * @returns void
 */
export const resetPendingUserState = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(userSlice.actions.resetPendingUserVerification());
};

/**
 * Resets the UserState's properties related to email verification to their initial values
 *
 * @returns void
 */
export const resetEmailVerification = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(userSlice.actions.resetEmailVerification());
};
