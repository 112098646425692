import { styled } from '@mui/material/styles';
import { Icon, Typography } from '@mui/material/';

export const StyledRemoveText = styled(Typography)(({ theme }) => ({
  right: 0,
  marginRight: '10px',
  position: 'absolute',
  color: theme.palette.coolGrey[600],
  lineHeight: '18px',
  cursor: 'pointer',
  '&: hover': {
    opacity: 1,
    fontWeight: 600,
    color: theme.palette.coolGrey[600],
  },
  [theme.breakpoints.up('xs')]: {
    ...theme.typography.mobileParagraphSmall,
  },
  [theme.breakpoints.up('xl')]: {
    ...theme.typography.paragraphSmall,
  },
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    ...theme.typography.mobileParagraph,
  },
  [theme.breakpoints.up('xl')]: {
    ...theme.typography.paragraph,
  },
}));

export const StyledIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.coolGrey[400],
  paddingRight: '6px',
  lineHeight: '18px !important',
}));
