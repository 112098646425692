import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Message } from '../../api/models/message.models';
import { RootState } from '../store';

interface MessageSelectedState {
  message: Message | undefined;
  requestedMessageId: string | undefined;
}

const initialState: MessageSelectedState = {
  message: undefined,
  requestedMessageId: undefined,
};

export const messageSelectedSlice = createSlice({
  name: 'message-selected',
  initialState: initialState,
  reducers: {
    resetMessage: () => initialState,
    setMessage: (state: MessageSelectedState, action: PayloadAction<Message>) => {
      state.message = action.payload;
    },
    setRequestedMessageId: (state: MessageSelectedState, action: PayloadAction<string | undefined>) => {
      state.requestedMessageId = action.payload;
    },
  },
});

export const selectIsSelectedMessageLoading = createSelector(
  (state: RootState) => state.messageSelected.requestedMessageId,
  (state: RootState) => state.messageSelected.message?.MessageId,
  (requestedMessageId: string | undefined, currentMessageId: string | undefined) =>
    requestedMessageId && requestedMessageId !== currentMessageId
);
