import { Stack, Typography } from '@mui/material';
import { RootState, selectUserPermissionSetting, toCurrencyString, useAppSelector } from 'common';
import { DeviceType, useBreakpoint } from '../../../../../hooks/useBreakpoint';

interface PriceDisclaimerProps {
  isPricingUnAvailable?: boolean;
}

export const PriceDisclaimer = (props: PriceDisclaimerProps): JSX.Element => {
  const { deviceType } = useBreakpoint();

  const orderEntryHeader = useAppSelector((x: RootState) => x.orders.order);
  const orderDetailsUpdating = useAppSelector((s: RootState) => s.orders.orderDetailsUpdating);
  const canViewPrice = useAppSelector((s: RootState) => selectUserPermissionSetting(s).CanViewPrices);
  if (!canViewPrice) {
    return <></>;
  }
  return (
    <Stack direction='column' sx={{ mt: 1, mb: { xs: 2, lg: 0 } }}>
      {!orderDetailsUpdating &&
        orderEntryHeader &&
        orderEntryHeader.MinimumOrderAmount > orderEntryHeader.TotalOrderPrice && (
          <Typography
            variant='paragraph'
            sx={{ color: (theme) => theme.palette.error.main }}
            data-id='summary-quantity-validation-message'
          >
            Add {toCurrencyString(orderEntryHeader.MinimumOrderAmount - orderEntryHeader.TotalOrderPrice, 2)} to meet
            your minimum!
          </Typography>
        )}
      {props.isPricingUnAvailable && (
        <Typography
          variant='paragraph'
          sx={{ color: (theme) => theme.palette.error.main }}
          data-id='summary-price-validation-message'
        >
          Pricing is temporarily unavailable.
        </Typography>
      )}

      <Typography
        variant='responsiveParagraphSmall'
        sx={{
          color: (theme) => theme.palette.grey[600],
          mt: (theme) => theme.spacing(0.5),
          textAlign: deviceType === DeviceType.Desktop ? 'center' : 'left',
        }}
        data-testid='price-disclaimer'
      >
        Disclaimer: All prices are subject to change.
      </Typography>
    </Stack>
  );
};
