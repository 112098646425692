import { Box, Grid, Icon, Typography } from '@mui/material';
import {
  AppRoutes,
  BusinessUnitLink,
  BusinessUnitLinkType,
  RootState,
  RouteName,
  getBusinessUnitLinks,
  getRouteNameByPathname,
  useAppDispatch,
  useAppSelector,
} from 'common';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DeviceType, useBreakpoint } from '../../../../hooks/useBreakpoint';
import { StyledFooterContainer, StyledFooterContent, StyledFooterSection, StyledLink } from './Footer.styles';

const Footer = (): JSX.Element => {
  const { deviceType } = useBreakpoint();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const businessUnitLinks = useAppSelector((s: RootState) => s.customer.businessUnitLinks);
  const userSite = useAppSelector((s: RootState) => s.user.userSite);

  const [businessLinks, setBusinessLinks] = useState<BusinessUnitLink[]>([]);
  const [socialMediaLinks, setSocialMediaLinks] = useState<BusinessUnitLink[]>([]);

  useEffect(() => {
    if (!businessUnitLinks.length) {
      dispatch(getBusinessUnitLinks());
    }

    setBusinessLinks(
      businessUnitLinks.filter(
        (l: BusinessUnitLink) =>
          l.LinkType === BusinessUnitLinkType.TermsOfUse || l.LinkType === BusinessUnitLinkType.PrivacyPolicy
      )
    );
    setSocialMediaLinks(
      businessUnitLinks.filter((l: BusinessUnitLink) => l.LinkType === BusinessUnitLinkType.SocialMedia)
    );
  }, [businessUnitLinks]);

  const mapBusinessUnitLinkToComponent = (link: BusinessUnitLink): JSX.Element => {
    if (link.LinkIconTagClass) {
      return (
        <StyledLink href={link.Url} target='_blank' rel='noopener' tabIndex={0}>
          <Icon
            baseClassName='fab'
            className={link.LinkIconTagClass}
            sx={{
              fontSize: '18px',
              paddingTop: '0.5px',
            }}
          />
        </StyledLink>
      );
    } else if (link.LinkIconUrl) {
      return <img width={21} height={21} src={link.LinkIconUrl} title={link.LinkTitle} alt={link.LinkTitle} />;
    } else {
      return (
        <StyledLink href={link.Url} target='_blank' rel='noopener' tabIndex={0}>
          {link.LinkTitle}
        </StyledLink>
      );
    }
  };

  const routeName = getRouteNameByPathname(location.pathname);
  if (
    RouteName.NoUserInformation === routeName ||
    RouteName.InsightsReport === routeName ||
    RouteName.CookieAcceptance === routeName ||
    RouteName.TermsOfService === routeName ||
    RouteName.GetStarted === routeName
  ) {
    return <></>;
  }

  const socialMediaSpacing = 12 - socialMediaLinks.length;
  return (
    <StyledFooterSection>
      <StyledFooterContainer>
        <StyledFooterContent>
          <Grid container justifyContent='space-between'>
            <Grid
              item
              xs={socialMediaSpacing}
              xl={8}
              sx={{
                display: { xs: 'flex', lg: 'block', xl: 'flex' },
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <Typography
                sx={{
                  display: 'inline',
                  typography: 'responsiveParagraphSmall',
                }}
                data-testid='footer-copyright'
              >
                &copy;{new Date().getFullYear()} {userSite?.OperatingAsDisplay} &nbsp;
              </Typography>
              <Box sx={{ display: 'flex' }}>
                {businessLinks.map((l: BusinessUnitLink, idx) => {
                  return (
                    <Box
                      key={l.BusinessUnitLinkId}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        lineHeight: deviceType === DeviceType.Tablet ? 0 : undefined,
                      }}
                    >
                      <Typography
                        sx={{
                          mx: 0.5,
                          display: { xs: idx < 1 ? 'none' : 'block', xl: 'block' },
                        }}
                        data-testid='business-unit-link'
                      >
                        |
                      </Typography>
                      {mapBusinessUnitLinkToComponent(l)}
                      {deviceType === DeviceType.Mobile && (
                        <Typography
                          sx={{
                            mx: 0.5,
                            display: { xs: idx < 1 ? 'none' : 'block', xl: 'block' },
                          }}
                          data-testid='business-unit-link'
                        >
                          |
                        </Typography>
                      )}
                    </Box>
                  );
                })}
                {deviceType !== DeviceType.Mobile && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      lineHeight: deviceType === DeviceType.Tablet ? 0 : undefined,
                    }}
                  >
                    <Typography
                      sx={{
                        mx: 0.5,
                      }}
                      data-testid='business-unit-link'
                    >
                      |
                    </Typography>
                    <StyledLink href={AppRoutes[RouteName.CustomerSupport].Path} tabIndex={0}>
                      Customer Support
                    </StyledLink>
                  </Box>
                )}
              </Box>
              {deviceType === DeviceType.Mobile && (
                <>
                  <br />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <StyledLink href={AppRoutes[RouteName.CustomerSupport].Path} tabIndex={0}>
                      Customer Support
                    </StyledLink>
                  </Box>
                </>
              )}
            </Grid>
            <Grid
              item
              xs={socialMediaLinks.length}
              xl={4}
              sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
            >
              {socialMediaLinks.map((l: BusinessUnitLink) => {
                return (
                  <Box
                    sx={{ display: 'flex', justifyContent: 'flex-end', height: 'fit-content', ml: 1.5 }}
                    key={l.BusinessUnitLinkId}
                  >
                    {mapBusinessUnitLinkToComponent(l)}
                  </Box>
                );
              })}
            </Grid>
          </Grid>
        </StyledFooterContent>
      </StyledFooterContainer>
    </StyledFooterSection>
  );
};

export default Footer;
