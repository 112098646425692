import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import {
  Delivery,
  DeliveryDetail,
  GetDeliveriesRequest,
  GetDeliveryDetailRequest,
} from '../../api/models/delivery.models';

// Adapters
const deliveryDetailsAdapter = createEntityAdapter<DeliveryDetail>({
  selectId: (delivery: DeliveryDetail) => delivery.DeliveryDetailProductKey,
});

// State
interface DeliveriesState {
  deliveryDetailRequest?: GetDeliveryDetailRequest;
  apiRequest?: GetDeliveriesRequest;
  deliveries: Delivery[];
  selectedDelivery: Delivery | undefined;
  deliveriesLoading: boolean;
  deliveryDetailsLoading: boolean;
  deliveryDetails: EntityState<DeliveryDetail>;
}

const initialState: DeliveriesState = {
  apiRequest: undefined,
  deliveryDetailRequest: undefined,
  deliveries: [],
  selectedDelivery: undefined,
  deliveriesLoading: false,
  deliveryDetailsLoading: true,
  deliveryDetails: deliveryDetailsAdapter.getInitialState(),
};

// Reducers
export const deliveriesSlice = createSlice({
  name: 'deliveries',
  initialState: initialState,
  reducers: {
    setDeliveries: (
      state: DeliveriesState,
      action: PayloadAction<{ request: GetDeliveriesRequest; result: Delivery[] }>
    ) => {
      state.apiRequest = action.payload.request;
      state.deliveries = action.payload.result;
    },
    setDelivery: (state: DeliveriesState, action: PayloadAction<Delivery | undefined>) => {
      state.selectedDelivery = action.payload;
    },
    setApiRequest: (state: DeliveriesState, action: PayloadAction<GetDeliveriesRequest | undefined>) => {
      state.apiRequest = action.payload;
      if (!action.payload) {
        state.deliveries = initialState.deliveries;
      }
    },
    setDeliveryDetailRequest: (state: DeliveriesState, action: PayloadAction<GetDeliveryDetailRequest | undefined>) => {
      state.deliveryDetailRequest = action.payload;
    },
    resetDeliveries: (state: DeliveriesState) => {
      state.deliveries = initialState.deliveries;
      state.apiRequest = initialState.apiRequest;
    },
    resetDeliveryDetails: (state: DeliveriesState) => {
      state.selectedDelivery = initialState.selectedDelivery;
      state.deliveryDetails = initialState.deliveryDetails;
      state.deliveryDetailRequest = initialState.deliveryDetailRequest;
      state.deliveryDetailsLoading = initialState.deliveryDetailsLoading;
    },
    setDeliveriesLoading: (state: DeliveriesState, action: PayloadAction<boolean>) => {
      state.deliveriesLoading = action.payload;
    },
    setDeliveryDetails: (state: DeliveriesState, action: PayloadAction<DeliveryDetail[]>) => {
      deliveryDetailsAdapter.setAll(state.deliveryDetails, action.payload);
    },
    setDeliveryDetailsLoading: (state: DeliveriesState, action: PayloadAction<boolean>) => {
      state.deliveryDetailsLoading = action.payload;
    },
  },
});

// Selectors
export const { selectAll: selectAllDeliveryDetails, selectById: selectDeliveryDetailById } =
  deliveryDetailsAdapter.getSelectors<DeliveriesState>((state: DeliveriesState) => state.deliveryDetails);

export const selectShortedDeliveryDetails = (state: DeliveriesState): DeliveryDetail[] =>
  selectAllDeliveryDetails(state).filter((d) => {
    return d.IsProductShorted;
  });

export const selectShippedDeliveryDetails = (state: DeliveriesState): DeliveryDetail[] =>
  selectAllDeliveryDetails(state).filter((d) => {
    return !d.IsShippedSeparately;
  });

export const selectShippedSeparatelyDeliveryDetails = (state: DeliveriesState): DeliveryDetail[] =>
  selectAllDeliveryDetails(state).filter((d) => {
    return d.IsShippedSeparately;
  });
