import { singletonHook } from 'react-singleton-hook';
import { useRef, useState } from 'react';

export type AccountSideBarActionsState =
  | {
      anchor: HTMLElement;
    }
  | undefined;

const initState = undefined;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let openActions = (_anchor: HTMLElement): void => {
  console.warn('use useAccountSideBarActions hook to access this method');
};

let closeActions = (): void => {
  console.warn('use useAccountSideBarActions hook to access this method');
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let toggleActions = (_anchor: HTMLElement): void => {
  console.warn('use useAccountSideBarActions hook to access this method');
};

export const useAccountSideBarActions = singletonHook<AccountSideBarActionsState>(initState, () => {
  const [state, setState] = useState<AccountSideBarActionsState>(initState);
  const stateRef = useRef<AccountSideBarActionsState>(initState);

  openActions = (anchor: HTMLElement) => {
    stateRef.current = { anchor };
    setState(stateRef.current);
  };
  closeActions = () => {
    stateRef.current = initState;
    setState(initState);
  };
  toggleActions = (anchor: HTMLElement) => {
    if (stateRef.current) {
      if (stateRef.current?.anchor === anchor) {
        closeActions();
      } else {
        openActions(anchor);
      }
    } else {
      openActions(anchor);
    }
  };

  return state;
});

export const openAccountSideBarActions = (anchor: HTMLElement): void => openActions(anchor);
export const closeAccountSideBarActions = (): void => closeActions();
export const toggleAccountSideBarActions = (anchor: HTMLElement): void => toggleActions(anchor);
