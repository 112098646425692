import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateCustomerGroupRequest,
  CustomerGroupBasic,
  CustomerGroupRecipient,
  CustomerGroupRecipientBasic,
  UpdateCustomerGroupRequest,
} from '../../api/models/customer-groups.models';
import { getSortComparer } from '../../helpers/general/array';
import { normalizeKey } from '../../helpers/general/string';
import { RootState } from '../store';

const customerGroupRecipientsAdapter = createEntityAdapter<CustomerGroupRecipient>({
  selectId: (recipient: CustomerGroupRecipient) => normalizeKey(recipient.CustomerGroupCustomerId),
  sortComparer: getSortComparer((recipient) => recipient.CustomerName),
});

interface ValidationMessages {
  customerGroupName: string[];
}

interface CustomerGroupSelectedState {
  customerGroup: CustomerGroupBasic | undefined;
  selectedCustomerGroupHeaderId: string | undefined;
  customerIds: EntityState<CustomerGroupRecipient>;
  customerIdsOnly: string[];
  customerIdsLoading: boolean;
  originalCustomerGroup:
    | {
        // CustomerGroupName: string;
        CustomerIds: CustomerGroupRecipientBasic[];
      }
    | undefined;
  validationMessages: ValidationMessages;
  saveCustomerGroupRequest: CreateCustomerGroupRequest | UpdateCustomerGroupRequest | undefined;
  deleteCustomerGroupHeaderId: string | undefined;
}

const initialState: CustomerGroupSelectedState = {
  customerGroup: undefined,
  selectedCustomerGroupHeaderId: undefined,
  customerIds: customerGroupRecipientsAdapter.getInitialState(),
  customerIdsOnly: [],
  customerIdsLoading: false,
  originalCustomerGroup: undefined,
  validationMessages: {
    customerGroupName: [],
  },
  saveCustomerGroupRequest: undefined,
  deleteCustomerGroupHeaderId: undefined,
};

export const customerGroupSelectedSlice = createSlice({
  name: 'customerGroupSelected',
  initialState,
  reducers: {
    resetState: () => initialState,
    setSelectedCustomerGroupHeaderId: (state: CustomerGroupSelectedState, action: PayloadAction<string>) => {
      state.selectedCustomerGroupHeaderId = action.payload;
    },
    setCustomerGroupBasic: (state: CustomerGroupSelectedState, action: PayloadAction<CustomerGroupBasic>) => {
      state.customerGroup = action.payload;
    },
    setCustomerGroupFull: (state: CustomerGroupSelectedState, action: PayloadAction<CustomerGroupRecipient[]>) => {
      if (
        state.customerGroup &&
        state.customerGroup.CustomerGroupHeaderId.toLowerCase() !==
          action.payload[0].CustomerGroupHeaderId.toLowerCase()
      )
        return;
      customerGroupRecipientsAdapter.setAll(
        state.customerIds,
        action.payload.map((r) => ({ ...r, CustomerGroupHeaderId: r.CustomerGroupHeaderId.toLowerCase() }))
      );
      state.customerIdsOnly = action.payload.map((r) => r.CustomerId.toLowerCase());
    },
    setCustomerGroupRecipients: (
      state: CustomerGroupSelectedState,
      action: PayloadAction<{
        recipients: CustomerGroupRecipient[];
      }>
    ) => {
      const { recipients } = action.payload;
      customerGroupRecipientsAdapter.setAll(state.customerIds, recipients);
    },
    removeCustomerGroupRecipientById: (state: CustomerGroupSelectedState, action: PayloadAction<string>) => {
      customerGroupRecipientsAdapter.removeOne(state.customerIds, action.payload);
    },
    resetCustomerGroupRecipients: (state: CustomerGroupSelectedState) => {
      state.customerIds = initialState.customerIds;
      state.customerIdsLoading = initialState.customerIdsLoading;
      state.originalCustomerGroup = initialState.originalCustomerGroup;
      state.customerIdsOnly = initialState.customerIdsOnly;
    },

    setCustomerIdsLoading: (state: CustomerGroupSelectedState, action: PayloadAction<boolean>) => {
      state.customerIdsLoading = action.payload;
    },
    setValidationMessages: (state: CustomerGroupSelectedState, action: PayloadAction<ValidationMessages>) => {
      state.validationMessages = action.payload;
    },
    setSaveCustomerGroupApiRequest: (
      state: CustomerGroupSelectedState,
      action: PayloadAction<CreateCustomerGroupRequest | UpdateCustomerGroupRequest | undefined>
    ) => {
      state.saveCustomerGroupRequest = action.payload;
    },
    setDeleteCustomerGroupHeaderId: (state: CustomerGroupSelectedState, action: PayloadAction<string>) => {
      state.deleteCustomerGroupHeaderId = action.payload;
    },
    resetDeleteCustomerGroupHeaderId: (state: CustomerGroupSelectedState) => {
      state.deleteCustomerGroupHeaderId = initialState.deleteCustomerGroupHeaderId;
    },
  },
});

export const {
  selectAll: selectAllCustomerGroupRecipients,
  selectById: selectCustomerGroupRecipientById,
  selectIds: selectAllCustomerGroupRecipientIds,
} = customerGroupRecipientsAdapter.getSelectors<EntityState<CustomerGroupRecipient>>(
  (recipients: EntityState<CustomerGroupRecipient>) => recipients
);

const getSelectRecipientsByCustomerRecipientTypeSelector = () =>
  createSelector(selectAllCustomerGroupRecipients, (recipients: CustomerGroupRecipient[]) => {
    return recipients;
  });

// const recipientsSelectors = {
//   [CustomerRecipientType.CustomerGroup]: getSelectRecipientsByCustomerRecipientTypeSelector(
//     CustomerRecipientType.CustomerGroup
//   ),
// };

export const getCustomerGroupRecipientSelector = () => {
  return getSelectRecipientsByCustomerRecipientTypeSelector();
};

const { selectAll: selectAllRecipientsLocal } = customerGroupRecipientsAdapter.getSelectors<CustomerGroupSelectedState>(
  (state: CustomerGroupSelectedState) => state.customerIds
);

export const selectAllRecipientsKeyedByObjectValues = createSelector(
  (state: RootState) => state.customerGroupSelected.customerIds,
  (recipients) =>
    Object.fromEntries(selectAllCustomerGroupRecipients(recipients).map((r) => [r.CustomerGroupHeaderId, r]))
);

export const selectIsValidCustomerGroup = createSelector(
  (state: RootState) => state.customerGroupSelected.validationMessages.customerGroupName,
  (state: RootState) => state.customerGroupSelected.customerIds.ids,
  (customerGroupName, recipientIds) => customerGroupName.length === 0 && recipientIds.length > 0
);

// export const selectDoesCustomereGroupHaveUnsavedChanges = createSelector(
//   (state: RootState) => state.customerGroupSelected.customerIdsLoading,
//   (state: RootState) => state.customerGroupSelected.customerGroup,
//   (state: RootState) => state.customerGroupSelected.originalCustomerGroup,
//   (state: RootState) => state.customerGroupSelected.customerIds,
//   (customerIdsLoading, customerGroup, originalCustomerGroup, customerGroupRecipients) =>
//     !(
//       customerIdsLoading ||
//       ((customerGroup?.CustomerGroupName ?? '') === (originalCustomerGroup?.CustomerGroupName ?? '') &&
//         arraysEqual(
//           selectAllCustomerGroupRecipients(customerGroupRecipients)
//             .map((r) => r.ObjectValue.toLowerCase())
//             .sort(getSortComparer((id) => id)),
//           originalCustomerGroup?.Recipients.map((r) => r.ObjectValue.toLowerCase()).sort(getSortComparer((id) => id)) ||
//             []
//         ))
//     )
// );
