import { Box, Stack, Theme } from '@mui/material';
import React from 'react';

interface CustomerSupportOverviewSlideIndicatorProps {
  indicators: number;
  currentIndex: number;
}

const CustomerSupportOverviewSlideIndicator: React.FC<CustomerSupportOverviewSlideIndicatorProps> = (
  props: CustomerSupportOverviewSlideIndicatorProps
) => {
  const slideWidth = 100 / props.indicators;

  return (
    <Box sx={{ height: (theme) => theme.spacing(1), width: '100%' }}>
      <Stack direction='row' sx={{ width: '100%' }} spacing={1}>
        {[...Array(props.indicators)].map((indicator, idx) => (
          <Box
            key={idx}
            sx={{
              height: (theme) => theme.spacing(1),
              width: `${slideWidth}%`,
              backgroundColor:
                idx <= props.currentIndex
                  ? (theme: Theme) => theme.palette.coolGrey[300]
                  : (theme: Theme) => theme.palette.common.white,
              border: idx > props.currentIndex ? (theme: Theme) => `1px solid ${theme.palette.coolGrey[300]}` : 'none',
              borderRadius: '2px',
            }}
          ></Box>
        ))}
      </Stack>
    </Box>
  );
};

export default CustomerSupportOverviewSlideIndicator;
