import { SeverityLevel } from '@microsoft/applicationinsights-web';
import {
  AccountReceivableDetailStatusType,
  GetAccountReceivableBalanceRequest,
  GetAccountReceivableDetailExportRequest,
  GetAccountReceivableDetailRequest,
  GetAccountReceivableStatementRequest,
} from '../../api/models/account-receivable.models';
import { FileResult } from '../../api/models/api-shared.models';
import AccountReceivableService from '../../api/services/account-receivable.service';
import { getTimeZone, toUTC } from '../../helpers/general/date';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { setErrorDialogContent, setInformationDialogContent } from '../common/global.thunks';
import { AppDispatch, AppThunk, RootState } from '../store';
import { accountReceivablesSlice } from './account-receivable.slice';

const accountReceivableService = AccountReceivableService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Resets the accounts-receivable slice to the inital values
 *
 * @returns NULL
 */
export const resetAccountReceivableState = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(accountReceivablesSlice.actions.resetState());
};

/**
 * Calls and stores the result of the GetAccountReceivableBalance API call
 *
 * @param request - Contains the customerIds of the customers who's AR balance is being retrieved
 * @param onFailure - Method to call on failure
 * @param isCalledFromHomeComponent - boolean value to indicate method was called from home page card
 * @returns NULL
 */
export const getAccountReceivableBalance =
  (request: { customerIds: string[] }, isCalledFromHomeComponent?: boolean, onFailure?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const customerIds = request.customerIds;
      if (customerIds.length === 0) return onFailure?.();

      const stateRequest = getState().accountReceivables.getAccountReceivableBalanceRequest;
      const apiRequest: GetAccountReceivableBalanceRequest = {
        Customers: customerIds,
      };

      dispatch(accountReceivablesSlice.actions.setAccountReceivableBalanceLoading(true));

      // Fetch new AR balance only when customers change
      if (JSON.stringify(apiRequest) !== JSON.stringify(stateRequest)) {
        dispatch(accountReceivablesSlice.actions.resetAccountReceivableBalance());
      } else {
        return;
      }

      const { data } = await accountReceivableService.getAccountReceivableBalance(apiRequest);
      if (data.IsSuccess) {
        dispatch(
          accountReceivablesSlice.actions.setAccountReceivableBalance({
            request: apiRequest,
            result: data.ResultObject,
          })
        );
      } else {
        if (data.ErrorMessages?.length > 0 && !isCalledFromHomeComponent) {
          dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
        }
        onFailure?.();
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(accountReceivablesSlice.actions.setAccountReceivableBalanceLoading(false));
    }
  };

/**
 * Calls and stores the result of the GetAccountReceivableDetail API call
 *
 * @param request - Contains customerIds, currentStatementsOnly, localStartDate, localEndDate, statusType
 * @param onFailure - Method to call on failure
 * @returns NULL
 */
export const getAccountReceivableDetail =
  (
    request: {
      customerIds: string[];
      currentStatementsOnly: boolean;
      localStartDate: Date | string;
      localEndDate: Date | string;
      statusType: AccountReceivableDetailStatusType[];
    },
    onFailure?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (request.customerIds.length === 0 || !request.localStartDate || !request.localEndDate) return onFailure?.();

      const apiRequest: GetAccountReceivableDetailRequest = {
        Customers: request.customerIds,
        CurrentStatementsOnly: request.currentStatementsOnly,
        StartDate: toUTC(request.localStartDate),
        EndDate: toUTC(request.localEndDate),
        AccountReceivableDetailStatusType: normalizeAccountReceivableDetailStatuses(request.statusType),
      };

      // Data in store is for search with different parameters. Data must be serialized (dates are not).
      const stateRequest = getState().accountReceivables.getAccountReceivableDetailRequest;
      const apiRequestToCompareAndSave = {
        ...apiRequest,
        StartDate: apiRequest.StartDate.toString(),
        EndDate: apiRequest.EndDate.toString(),
      };

      if (JSON.stringify(apiRequestToCompareAndSave) !== JSON.stringify(stateRequest)) {
        dispatch(accountReceivablesSlice.actions.resetAccountReceivableDetail());
      } else {
        return;
      }

      dispatch(accountReceivablesSlice.actions.setAccountReceivableDetailsLoading(true));

      const { data } = await accountReceivableService.getAccountReceivableDetail(apiRequest);
      if (data.IsSuccess) {
        dispatch(
          accountReceivablesSlice.actions.setAccountReceivableDetail({
            request: apiRequestToCompareAndSave,
            result: data.ResultObject,
          })
        );
        if (data.InformationMessages?.length > 0) {
          dispatch(setInformationDialogContent('Notice', data.InformationMessages));
        }
      } else {
        if (data.ErrorMessages?.length > 0) {
          dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
        }
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(accountReceivablesSlice.actions.setAccountReceivableDetailsLoading(false));
    }
  };

/**
 * Calls and stores the result of the GetAccountReceivableCustomers API call
 *
 * @returns NULL
 */
export const getAccountReceivableCustomers =
  (): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(accountReceivablesSlice.actions.setAccountReceivableCustomersLoading(true));

      const loadedCustomers = getState().accountReceivables.accountReceivableCustomers;
      if (loadedCustomers && loadedCustomers.length > 0) return;

      const { data } = await accountReceivableService.getAccountReceivableCustomers();
      if (data.IsSuccess) {
        dispatch(accountReceivablesSlice.actions.setAccountReceivableCustomers(data.ResultObject));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(accountReceivablesSlice.actions.setAccountReceivableCustomersLoading(false));
    }
  };

/**
 * Calls and stores the result of the GetAccountReceivableDetailExport API call
 *
 * @param request - Contains customerIds, currentStatementsOnly, localStartDate, localEndDate, statusType
 * @param onExportSuccess - Method to call on success
 * @param onFailure - Method to call on failure
 * @returns NULL
 */
export const getAccountReceivableDetailExport =
  (
    request: {
      customerIds: string[];
      currentStatementsOnly: boolean;
      localStartDate: Date | string;
      localEndDate: Date | string;
      statusType: AccountReceivableDetailStatusType[];
      filterText: string | undefined;
    },
    onExportSuccess: (file: FileResult) => void,
    onFailure?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(accountReceivablesSlice.actions.setAccountReceivableDetailExportLoading(true));

    const apiRequest: GetAccountReceivableDetailExportRequest = {
      Customers: request.customerIds,
      CurrentStatementsOnly: request.currentStatementsOnly,
      StartDate: toUTC(request.localStartDate),
      EndDate: toUTC(request.localEndDate),
      AccountReceivableDetailStatusType: normalizeAccountReceivableDetailStatuses(request.statusType),
      Filter: request.filterText,
      TimeZone: getTimeZone(),
    };

    try {
      const { data } = await accountReceivableService.getAccountReceivableDetailExport(apiRequest);
      if (data.IsSuccess) {
        onExportSuccess(data.ResultObject);
      } else {
        onFailure?.();
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(accountReceivablesSlice.actions.setAccountReceivableDetailExportLoading(false));
    }
  };

/**
 * Sets the value of accountReceivableDetailExportLoading in the account-receivable slice
 *
 * @param loading - Boolean value to set accountReceivableDetailExportLoading in the account-receivable slice to
 * @returns NULL
 */
export const setAccountReceivableDocumentDownloadLoading =
  (loading: boolean): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(accountReceivablesSlice.actions.setAccountRecieveableStatementDocumentDownloadLoading(loading));
  };

/**
 * Calls and stores the result of the GetAccountReceivableStatementDocument API call
 *
 * @param customerId - The id of the customer who's document is being retrieved
 * @param onStatementDownloadSuccess - Method to call on success
 * @param onStatementDownloadFailure - Method to call on failure
 * @returns NULL
 */
export const getAccountReceivableStatementDocument =
  (
    customerId: string,
    onStatementDownloadSuccess: (file: FileResult | undefined) => void,
    onStatementDownloadFailure?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(
      accountReceivablesSlice.actions.setAccountReceivableStatementDocumentLoadingIDs({ id: customerId, loading: true })
    );
    dispatch(setAccountReceivableDocumentDownloadLoading(true));
    const request: GetAccountReceivableStatementRequest = {
      CustomerId: customerId,
    };

    try {
      const { data } = await accountReceivableService.getAccountReceivableStatementDocument(request);
      if (data.IsSuccess) {
        onStatementDownloadSuccess(data.ResultObject);
      } else {
        onStatementDownloadFailure?.();
      }
    } catch (error) {
      onStatementDownloadFailure?.();
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(
        accountReceivablesSlice.actions.setAccountReceivableStatementDocumentLoadingIDs({
          id: customerId,
          loading: false,
        })
      );
    }
  };

/**
 * Ensures statuses has values and returns the normalized statuses
 *
 * @param statuses - The statuses to check
 * @returns AccountReceivableDetailStatusType[], Either returns the passed in stauses or, if there are no valus in statuses, Open, Closed and PastDue statuses
 */
const normalizeAccountReceivableDetailStatuses = (
  statuses: AccountReceivableDetailStatusType[]
): AccountReceivableDetailStatusType[] => {
  let accountStatusTypes = statuses;
  if (!accountStatusTypes || accountStatusTypes.length === 0) {
    accountStatusTypes = [
      AccountReceivableDetailStatusType.Open,
      AccountReceivableDetailStatusType.Closed,
      AccountReceivableDetailStatusType.PastDue,
    ];
  }
  return accountStatusTypes;
};
