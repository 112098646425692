import { BreadcrumbsProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { AppRoutes, isString, RouteName } from 'common';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { cfFreezeNames } from '../../theme/theme';
import {
  BreadcrumbBoldTypography,
  BreadcrumbTypography,
  StyledBreadcrumbs,
  StyledBreadcrumbSeparator,
  StyledLink,
} from './CfBreadcrumbs.styles';

export type CfBreadcrumbVariants = 'primary' | 'secondary';
export interface RequiredBreadcrumbProps {
  displayName: React.ReactNode;
}

/**
 * Used in the BreadcrumbItem type. There are two variations:
 *
 * @param routeName - Name of the route to be used
 * @param params - String array of the parameters to use in the breadcrumb
 * @param queryParams - Dictionary of the parameters to use in the query
 * @param navigationAction - Default method to call in handleLinkClick
 *
 * ------------------------------------------------------------------
 * or
 * @param customNavigation - Customized method to call in handleLinkClick
 */
type ConditionalBreadcrumbProps =
  | {
      routeName: RouteName;
      params?: string[];
      queryParams?: { [key: string]: string };
      navigationAction?: (() => void) | (() => Promise<void>);
      customNavigation?: never;
    }
  | {
      routeName?: never;
      params?: never;
      navigationAction?: never;
      queryParams?: never;
      customNavigation?: (() => void) | (() => Promise<void>);
    };

export type BreadcrumbItem = RequiredBreadcrumbProps & ConditionalBreadcrumbProps;

export interface CfBreadcrumbsProps extends BreadcrumbsProps {
  breadcrumbs: BreadcrumbItem[];
  variant?: CfBreadcrumbVariants;
  sx?: SxProps<Theme>;
}

/**
 * @param breadcrumbs - Array of breadcrumb items
 * @param variant - Styling to use for the breadcrumb(primary, secondary)
 * @param sx - Any additional breadcrumb styling
 */
export const CfBreadcrumbs: FC<CfBreadcrumbsProps> = (props: CfBreadcrumbsProps) => {
  const history = useHistory();

  const handleLinkClick = async (breadcrumb: BreadcrumbItem): Promise<void> => {
    if (breadcrumb.customNavigation) {
      await breadcrumb.customNavigation();
    } else {
      if (breadcrumb.navigationAction) {
        await breadcrumb.navigationAction();
      }
      let path = `${AppRoutes[breadcrumb.routeName as RouteName].Path}`;
      if (breadcrumb.params && breadcrumb.params.length > 0) path += `/${breadcrumb.params?.join?.('/')}`;
      let queryParams = '';
      if (breadcrumb.queryParams) {
        queryParams = '?' + new URLSearchParams(breadcrumb.queryParams).toString();
      }
      history.push(path + queryParams, history.location.state);
    }
  };

  const hideHomeLink = props.variant === 'secondary';

  return (
    <StyledBreadcrumbs
      separator={<StyledBreadcrumbSeparator className='fa-angle-right' fontSize='small' color='inherit' />}
      aria-label='breadcrumb'
      data-testid='styled-bread-crumbs-item'
      variant={props.variant}
      sx={props.sx}
      className={cfFreezeNames.breadcrumb}
    >
      {hideHomeLink ? undefined : props.breadcrumbs.length > 0 ? (
        <StyledLink
          data-testid={`bread-crumb-item-${AppRoutes[RouteName.Home].DisplayName}`}
          onClick={() => history.push(AppRoutes[RouteName.Home].Path)}
        >
          {AppRoutes[RouteName.Home].DisplayName}
        </StyledLink>
      ) : (
        <BreadcrumbTypography data-testid={`bread-crumb-item-${AppRoutes[RouteName.Home].DisplayName}`}>
          {AppRoutes[RouteName.Home].DisplayName}
        </BreadcrumbTypography>
      )}
      {props.breadcrumbs.map((breadcrumb: BreadcrumbItem, index: number) => {
        const isLast = index === props.breadcrumbs.length - 1;
        const key = isString(breadcrumb.displayName) ? (breadcrumb.displayName as string) : index;
        return isLast ? (
          <BreadcrumbBoldTypography key={key} data-testid={`bread-crumb-item-${key}`}>
            {breadcrumb.displayName}
          </BreadcrumbBoldTypography>
        ) : (
          <StyledLink
            key={key}
            onClick={async () => handleLinkClick(breadcrumb)}
            data-testid={`bread-crumb-item-${key}`}
          >
            {breadcrumb.displayName}
          </StyledLink>
        );
      })}
    </StyledBreadcrumbs>
  );
};
