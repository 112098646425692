import {
  AccountReceivableBalance,
  AccountReceivableDetail,
  GetAccountReceivableBalanceRequest,
  GetAccountReceivableDetailExportRequest,
  GetAccountReceivableDetailRequest,
  GetAccountReceivableStatementRequest,
} from '../models/account-receivable.models';
import { FileResult, ServiceResult } from '../models/api-shared.models';
import { Customer } from '../models/customer.models';
import ApiService from './api-service';

class AccountReceivableService extends ApiService {
  private static classInstance: AccountReceivableService;

  constructor() {
    super('AccountReceivable');
  }

  public getAccountReceivableCustomers(): ServiceResult<Customer[]> {
    return this.get(`${this.baseUrl}/GetAccountReceivableCustomers`);
  }

  public getAccountReceivableBalance(
    request: GetAccountReceivableBalanceRequest
  ): ServiceResult<AccountReceivableBalance> {
    return this.post(`${this.baseUrl}/GetAccountReceivableBalance`, request);
  }

  public getAccountReceivableDetail(
    request: GetAccountReceivableDetailRequest
  ): ServiceResult<AccountReceivableDetail[]> {
    return this.post(`${this.persistUrl}/GetAccountReceivableDetail`, request);
  }

  public getAccountReceivableStatementDocument(
    request: GetAccountReceivableStatementRequest
  ): ServiceResult<FileResult> {
    return this.get(`${this.persistUrl}/GetAccountReceivableStatementDocument`, request);
  }

  public getAccountReceivableDetailExport(request: GetAccountReceivableDetailExportRequest): ServiceResult<FileResult> {
    return this.post(`${this.persistUrl}/GetAccountReceivableDetailExport`, request);
  }

  public static getInstance(): AccountReceivableService {
    if (!this.classInstance) {
      this.classInstance = new AccountReceivableService();
    }
    return this.classInstance;
  }
}

export default AccountReceivableService;
