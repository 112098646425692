import { FileResult, ServiceResult } from '../models/api-shared.models';
import {
  AddUserToLocationRequest,
  CreatePendingUserRequest,
  GetManagedUserExistsRequest,
  GetManagedUsersByCustomerRequest,
  GetManagedUsersReportRequest,
  GetUserCustomerPermissionsRequest,
  ManageUser,
  ManageUserCustomerPermissions,
  RemoveUserFromLocationRequest,
  ResendInvitationRequest,
  SetUserCustomerPermissionsRequest,
  SetUserCustomersRequest,
} from '../models/manage-users.models';
import ApiService from './api-service';

class ManageUsersService extends ApiService {
  private static classInstance: ManageUsersService;

  private constructor() {
    super('UserManagement');
  }

  public getManagedUsersByCustomer(request: GetManagedUsersByCustomerRequest): ServiceResult<ManageUser[]> {
    return this.post(`${this.persistUrl}/GetManagedUsersByCustomer`, request);
  }

  public getUserCustomerPermissions(
    request: GetUserCustomerPermissionsRequest
  ): ServiceResult<ManageUserCustomerPermissions> {
    return this.post(`${this.persistUrl}/GetUserCustomerPermissions`, request);
  }

  public setUserCustomerPermissions(request: SetUserCustomerPermissionsRequest): ServiceResult<undefined> {
    return this.post(`${this.persistUrl}/SetUserCustomerPermissions`, request);
  }

  public getManagedUserExists(request: GetManagedUserExistsRequest): ServiceResult<ManageUser | undefined> {
    return this.post(`${this.persistUrl}/GetManagedUserExists`, request);
  }

  public createPendingUser(request: CreatePendingUserRequest): ServiceResult<ManageUser> {
    return this.post(`${this.persistUrl}/CreatePendingUser`, request);
  }

  public addUserToLocation(request: AddUserToLocationRequest): ServiceResult<undefined> {
    return this.post(`${this.persistUrl}/AddUserToLocation`, request);
  }

  public removeUsersFromLocation(request: RemoveUserFromLocationRequest): ServiceResult<undefined> {
    return this.post(`${this.persistUrl}/RemoveUsersFromLocation`, request);
  }

  public setUserCustomers(request: SetUserCustomersRequest): ServiceResult<undefined> {
    return this.post(`${this.persistUrl}/SetUserCustomers`, request);
  }

  public resendInvitation(request: ResendInvitationRequest): ServiceResult<undefined> {
    return this.post(`${this.persistUrl}/ResendInvitation`, request);
  }

  public getManagedUsersReport(request: GetManagedUsersReportRequest): ServiceResult<FileResult> {
    return this.post(`${this.persistUrl}/GetManagedUsersReport`, request);
  }

  public resetUserCheckupDate(): ServiceResult<undefined> {
    return this.post(`${this.persistUrl}/ResetUserCheckupDate`, undefined);
  }

  public static getInstance(): ManageUsersService {
    if (!this.classInstance) {
      this.classInstance = new ManageUsersService();
    }
    return this.classInstance;
  }
}

export default ManageUsersService;
