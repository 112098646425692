import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpdateCustomProductRequest } from '../../api';
import { APIMessages } from '../../api/models/api-shared.models';
import { SearchCacheEntry, TypeAheadResponse } from '../../api/models/product-catalog.models';
import { ProductListCategory } from '../../api/models/product-list-category.models';
import {
  ProductListCatalogProduct,
  ProductListSearchResult,
  SearchProductListCatalogRequest,
  SearchProductListCatalogResultDetail,
} from '../../api/models/product-list-search.models';
import { normalizeProductKey, updateProductCustomAttributesFromPayload } from '../../helpers/general/product';
import {
  instanceOfCatalogProduct,
  ProductListCatalogSearchMode,
  ReplaceDialogOptions,
} from '../../models/product-list.models';
import { createPagedEntityAdapter, EntityPageLoading, PagedEntityState } from '../paged-entity-adapter';
import { RootState } from '../store';

const productsAdapter = createPagedEntityAdapter<ProductListCatalogProduct>({
  selectId: (product: ProductListCatalogProduct) => normalizeProductKey(product.Product.ProductKey),
});

export interface ProductListSearchState {
  apiRequest?: SearchProductListCatalogRequest;
  productSearchResult?: ProductListSearchResult;
  products: PagedEntityState<ProductListCatalogProduct>;

  searchCache?: SearchCacheEntry[];
  typeAheadResults: TypeAheadResponse | undefined;
  typeAheadAPIMessages?: APIMessages;

  productListCategories: ProductListCategory[];
  selectedProductListCategoryId: string | undefined;
}

const initialState: ProductListSearchState = {
  productSearchResult: undefined,
  products: productsAdapter.getInitialState(true),
  typeAheadResults: undefined,
  typeAheadAPIMessages: undefined,
  searchCache: undefined,
  productListCategories: [],
  selectedProductListCategoryId: undefined,
};

export const productListSearchSlice = createSlice({
  name: 'productListSearch',
  initialState: initialState,
  reducers: {
    resetProductSearchState: (state: ProductListSearchState) => {
      // Resets the search state (leaves Typeahead state alone)
      state.apiRequest = initialState.apiRequest;
      state.productSearchResult = initialState.productSearchResult;
      state.products = initialState.products;
      state.productListCategories = initialState.productListCategories;
    },
    resetProductSearchResults: (state: ProductListSearchState) => {
      state.apiRequest = initialState.apiRequest;
      state.productSearchResult = initialState.productSearchResult;
      state.products = initialState.products;
    },
    setProductSearchResults: (
      state: ProductListSearchState,
      action: PayloadAction<{
        request: SearchProductListCatalogRequest;
        result: SearchProductListCatalogResultDetail;
        searchMode: ProductListCatalogSearchMode;
        replaceProductOptions?: ReplaceDialogOptions;
      }>
    ) => {
      const { request, result, searchMode, replaceProductOptions } = action.payload;
      const catalogProducts = result.CatalogProducts;
      if (
        searchMode === 'replace' &&
        replaceProductOptions?.fromProduct &&
        instanceOfCatalogProduct(replaceProductOptions.fromProduct)
      ) {
        catalogProducts.unshift({
          IsOnList: true,
          Product: replaceProductOptions.fromProduct,
        });
      }

      const productSearchResult: ProductListSearchResult = {
        Skip: result.Skip,
        PageSize: result.PageSize,
        QueryText: result.QueryText,
        TotalNumberOfItems: result.CatalogProducts.length,
        Facets: result.Facets,
        HasLoadMore: result.HasLoadMore,
        pageIndex: result.pageIndex,
      };

      state.apiRequest = request;
      state.productSearchResult = productSearchResult;

      productsAdapter.setLoadedPage(state.products, {
        pageIndex: result.pageIndex,
        entities: catalogProducts,
      });
    },
    setPageLoaded: (
      state: ProductListSearchState,
      action: PayloadAction<{ pageIndex: number; catalogProducts: ProductListCatalogProduct[] }>
    ) => {
      productsAdapter.setLoadedPage(state.products, {
        pageIndex: action.payload.pageIndex,
        entities: action.payload.catalogProducts,
      });
    },
    setPageLoading: (state: ProductListSearchState, action: PayloadAction<EntityPageLoading>) => {
      productsAdapter.setLoadingPage(state.products, action.payload);
    },
    setTypeAheadAPIMessages: (state: ProductListSearchState, action: PayloadAction<APIMessages>) => {
      state.typeAheadAPIMessages = action.payload;
    },
    setTypeAheadResults: (state: ProductListSearchState, action: PayloadAction<TypeAheadResponse>) => {
      state.typeAheadResults = action.payload;
    },
    setSearchCache: (state: ProductListSearchState, action: PayloadAction<SearchCacheEntry[]>) => {
      state.searchCache = action.payload;
    },
    setProductListCategories: (state: ProductListSearchState, action: PayloadAction<ProductListCategory[]>) => {
      state.productListCategories = action.payload;
    },
    setSelectedProductListCategoryId: (state: ProductListSearchState, action: PayloadAction<string>) => {
      state.selectedProductListCategoryId = action.payload;
    },
    setProductListCatalogProductIsOnList: (
      state: ProductListSearchState,
      action: PayloadAction<{ productKey: string | undefined; isOnList: boolean }>
    ) => {
      const productKey = normalizeProductKey(action.payload.productKey);
      const product = state.products.entities[productKey];
      if (product) product.IsOnList = action.payload.isOnList;
    },
    updateProductCustomAttributes: (
      state: ProductListSearchState,
      action: PayloadAction<UpdateCustomProductRequest>
    ) => {
      const product = state.products.entities[normalizeProductKey(action.payload.ProductKey)]?.Product;
      if (!product) return;

      updateProductCustomAttributesFromPayload(product, action.payload);
    },
  },
});

export const {
  selectAll: selectAllProductListSearchProducts,
  selectById: selectProductListSearchProduct,
  selectIsLoading: selectProductListSearchProductListIsLoading,
  selectByPage: selectProductListSearchProductsByPage,
  selectTotal: selectProductListSearchTotalProducts,
} = productsAdapter.getSelectors<RootState>((state) => state.productListSearch.products);
