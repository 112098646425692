import { Menus } from '../../hooks/useHeaderMenus';

export type NavigationProps = {
  variant: NavigationVariant;
};

export type NavigationVariant = 'bar' | 'drawer';

export enum NavigationOptionVariant {
  Button,
  Menu,
}

export type NavButtonOptions = {
  label: string;
  navigationAction?: () => void;
  navigationRoute?: string;
  testId?: string;
  isDisabled?: boolean;
  childOption?: boolean;
};

type ConditionalMenuConfig =
  | {
      variant: NavigationOptionVariant.Button;
      menuItems?: never;
      navigationAction: () => void;
    }
  | {
      variant: NavigationOptionVariant.Menu;
      menuItems: NavButtonOptions[];
      navigationAction?: never;
    };

export type NavMenuConfig = ConditionalMenuConfig & {
  id?: number;
  label: string;
  isDisabled?: boolean;
  testId: string;
  forcedMenuOpen?: boolean;
  menuHookValue?: Menus;
};
