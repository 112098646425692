import { UserCustomer } from 'common';
import { FC, useEffect, useRef } from 'react';
import { CfUncontrolledMenu } from '../../../../../../../../cf-ui/Menu/CfUncontrolledMenu';
import LocationSearchRadioList from '../../../../../common/LocationSearch/LocationSearchRadioList';
import { LocationBar } from '../../../common/LocationBar';
import TooltipContent from '../common/TooltipContent';

export interface LocationMenuProps {
  customer: UserCustomer | undefined;
  customerOptions: UserCustomer[];
  selectedCustomer: UserCustomer | undefined;
  onSelect?: (customer: UserCustomer | undefined) => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  isOpen?: boolean;
  icon: string;
  caretUpIcon: string;
  caretDownIcon: string;
}

const LocationMenu: FC<LocationMenuProps> = (props: LocationMenuProps) => {
  const { customer, customerOptions, selectedCustomer, onSelect, isOpen, onMenuOpen, onMenuClose } = {
    ...props,
  };

  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const buttonRef = useRef<HTMLSpanElement | null>(null);
  const buttonComponent = (
    <LocationBar
      dataTestId='location-menu-btn'
      setRef={(r) => (buttonRef.current = r)}
      iconClassName={props.icon}
      toolTipTitle={<TooltipContent customer={customer} />}
      isOpen={props.isOpen}
      onClick={() => {
        if (props.isOpen) onMenuClose?.();
        else onMenuOpen?.();
      }}
      caretUpIcon={props.caretUpIcon}
      caretDownIcon={props.caretDownIcon}
    />
  );

  const handleSearchKeyDown: React.KeyboardEventHandler<HTMLElement> = (event) => {
    if (event.code.toLowerCase() === 'tab' && event.shiftKey) {
      buttonRef.current?.focus();
      props.onMenuClose?.();
    }
  };

  const handleLastRadioKeyDown: React.KeyboardEventHandler<HTMLElement> = (event) => {
    if (event.code.toLowerCase() === 'tab' && !event.shiftKey) {
      buttonRef.current?.focus();
      props.onMenuClose?.();
    }
  };

  useEffect(() => {
    if (props.isOpen) {
      searchInputRef.current?.focus();
    }
  }, [props.isOpen]);

  return (
    <CfUncontrolledMenu
      dataTestId='location-menu'
      isOpen={isOpen}
      anchorElement={buttonRef.current}
      width={350}
      placement='bottom-start'
      onClose={onMenuClose}
      keepMounted
      mode='panel'
      variant='round'
      popperOffset={[0, 8]}
      popperSx={{
        boxShadow: '0px 2px 8px 0px #00000026',
        border: '1px solid #DDE1E6',
        position: 'fixed',
      }}
      containerSx={{ maxHeight: 'calc(65vh)', overflowY: 'auto' }}
      inputElement={buttonComponent}
    >
      <LocationSearchRadioList
        customerOptions={customerOptions}
        selectedCustomer={selectedCustomer}
        menuOpen={props.isOpen ?? false}
        onSelect={onSelect}
        radioSx={{ mt: 2.1, ml: 1.6 }}
        radioBoxSx={{
          overflowX: { xl: 'hidden' },
          px: 1,
          py: 0,
          height: (theme) => `calc(100vh - ${theme.spacing(37.5)})`,
          maxHeight: (theme) => theme.spacing(41),
        }}
        searchInputRef={searchInputRef}
        onSearchInputKeyDown={handleSearchKeyDown}
        onLastRadioKeyDown={handleLastRadioKeyDown}
        inputTestId='location-search-menu-search'
      />
    </CfUncontrolledMenu>
  );
};

export default LocationMenu;
