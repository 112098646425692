import ApiService from './api-service';
import { ServiceResult } from '../models/api-shared.models';
import {
  CreateProductListCategoryRequest,
  GetProductListCategoriesRequest,
  GetOrDeleteProductListCategoryRequest,
  ProductListCategory,
  SequenceProductListCategoryRequest,
  UpdateProductListCategoryRequest,
} from '../models/product-list-category.models';

class ProductListCategoryService extends ApiService {
  private static classInstance: ProductListCategoryService;

  private constructor() {
    super('ProductListCategory');
  }

  public sequenceProductListCategory(request: SequenceProductListCategoryRequest): ServiceResult<ProductListCategory> {
    return this.post<ProductListCategory>(`${this.baseUrl}/SequenceProductListCategory`, request);
  }

  public getProductListCategory(request: GetOrDeleteProductListCategoryRequest): ServiceResult<ProductListCategory> {
    return this.get<ProductListCategory>(`${this.baseUrl}/GetProductListCategory`, request);
  }

  public getProductListCategories(request: GetProductListCategoriesRequest): ServiceResult<ProductListCategory[]> {
    return this.get<ProductListCategory[]>(`${this.baseUrl}/GetProductListCategories`, request);
  }

  public createProductListCategory(request: CreateProductListCategoryRequest): ServiceResult<ProductListCategory> {
    return this.post<ProductListCategory>(`${this.baseUrl}/CreateProductListCategory`, request);
  }

  public updateProductListCategory(request: UpdateProductListCategoryRequest): ServiceResult<ProductListCategory> {
    return this.post<ProductListCategory>(`${this.baseUrl}/UpdateProductListCategory`, request);
  }

  public deleteProductListCategory(request: GetOrDeleteProductListCategoryRequest): ServiceResult<undefined> {
    return this.post<undefined>(`${this.baseUrl}/DeleteProductListCategory`, request);
  }

  public static getInstance(): ProductListCategoryService {
    if (!this.classInstance) {
      this.classInstance = new ProductListCategoryService();
    }
    return this.classInstance;
  }
}
export default ProductListCategoryService;
