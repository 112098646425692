import { Skeleton, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

interface LoadingSkeletonProps {
  lines: number;
  variant?: 'text' | 'rectangular' | 'circular';
  sx?: SxProps<Theme>;
  dataTestId?: string;
}

const LoadingSkeleton = (props: LoadingSkeletonProps): JSX.Element => {
  const numArray: number[] = Array.from(Array(props.lines).keys());
  const nsx: SxProps<Theme> = { width: '75%' };

  return (
    <>
      {numArray.map((idx) => {
        return (
          <Skeleton
            variant={props.variant}
            key={`skeleton - ${idx}`}
            sx={props.sx || nsx}
            data-testid={props.dataTestId}
          />
        );
      })}
    </>
  );
};

export default LoadingSkeleton;
