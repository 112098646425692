import { ServiceResult } from '../models/api-shared.models';
import {
  GetOrderEntrySearchRequest,
  GetOrderEntrySearchResultDetail,
  ProductListCategoryResult,
  ProductListOrderEntrySearchRequest,
} from '../models/order-entry-search.models';
import ApiService from './api-service';

class OrderEntrySearchService extends ApiService {
  private static classInstance: OrderEntrySearchService;
  private productListSearchController: AbortController;

  private constructor() {
    super('OrderEntrySearch');
    this.productListSearchController = new AbortController();
  }

  public getOrderEntrySearch(request: GetOrderEntrySearchRequest): ServiceResult<GetOrderEntrySearchResultDetail> {
    return this.post(`${this.baseUrl}/GetOrderEntrySearch`, request);
  }

  public abortGetProductListOrderEntrySearch = (): void => {
    this.productListSearchController.abort();
  };

  public getProductListOrderEntrySearch(
    request: ProductListOrderEntrySearchRequest
  ): ServiceResult<ProductListCategoryResult> {
    this.productListSearchController = new AbortController();
    return this.post(
      `${this.baseUrl}/ProductListOrderEntrySearch`,
      request,
      undefined,
      this.productListSearchController.signal
    );
  }

  public static getInstance(): OrderEntrySearchService {
    if (!this.classInstance) {
      this.classInstance = new OrderEntrySearchService();
    }
    return this.classInstance;
  }
}

export default OrderEntrySearchService;
