import { EntityState, PayloadAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { LruObjectCache, createCachedSelector } from 're-reselect';
import {
  GetParEntrySearchRequest,
  GetParEntrySearchResultDetail,
  ParListDetail,
  ParListHeader,
  ProductListHeaderCustom,
  ProductListProduct,
  ProductListSortOption,
  UpdateCustomProductRequest,
} from '../../api';
import { normalizeKey, normalizeProductKey, updateProductCustomAttributesFromPayload } from '../../helpers';
import { getNormalizedProductListGridData } from '../../helpers/general/product-list';
import {
  ParListGridData,
  ProductListCategoryCustom,
  ProductListCategoryProduct,
  ProductListGridData,
} from '../../models';
import { EntityPage, EntityPageLoading, PagedEntityState, createPagedEntityAdapter } from '../paged-entity-adapter';
import { RootState } from '../store';

//Adapter
const productsAdapter = createEntityAdapter<ProductListProduct>({
  selectId: (product: ProductListProduct) => normalizeProductKey(product.ProductKey),
});

const categoriesAdapter = createEntityAdapter<ProductListCategoryCustom>({
  selectId: (category: ProductListCategoryCustom) => category.Id,
});

const categoryProductsAdapter = createPagedEntityAdapter<ProductListCategoryProduct>({
  selectId: (categoryProduct: ProductListCategoryProduct) => categoryProduct.Id,
});

const parDetailsAdapter = createEntityAdapter<ParListDetail>({
  selectId: (parDetail: ParListDetail) => `${parDetail.ProductKey}-${parDetail.UnitOfMeasure}`,
});

const productListHeadersAdapter = createEntityAdapter<ProductListHeaderCustom>({
  selectId: (productListHeader) => productListHeader.ProductListHeaderId,
});

// States
interface ParMaintenanceState {
  isLoadingParListHeader: boolean;
  parListHeader?: ParListHeader;
  isProductListHeadersLoading: boolean;
  productListHeaders: EntityState<ProductListHeaderCustom>;
  isParDetailsLoading: boolean;
  parDetails: EntityState<ParListDetail>;
  products: EntityState<ProductListProduct>;
  isLoadingProductSearchResult: boolean;
  productSearchResult?: GetParEntrySearchResultDetail;
  apiRequest?: GetParEntrySearchRequest;
  categories: EntityState<ProductListCategoryCustom>;
  categoryProducts: PagedEntityState<ProductListCategoryProduct>;
  parDetailUpdateResult?: ParListDetail;
  isParDetailsUpdateLoading: boolean;
  isShowCompletedToggled?: boolean;
  sortByOptions: ProductListSortOption[];
  isLoadingSortByOptions: boolean;
}

const initialState: ParMaintenanceState = {
  isLoadingParListHeader: false,
  parListHeader: undefined,
  isProductListHeadersLoading: true,
  productListHeaders: productListHeadersAdapter.getInitialState(),
  isParDetailsLoading: true,
  parDetails: parDetailsAdapter.getInitialState(),
  products: productsAdapter.getInitialState(),
  isLoadingProductSearchResult: true,
  productSearchResult: undefined,
  apiRequest: undefined,
  categories: categoriesAdapter.getInitialState(),
  categoryProducts: categoryProductsAdapter.getInitialState(),
  parDetailUpdateResult: undefined,
  isParDetailsUpdateLoading: false,
  isShowCompletedToggled: false,
  sortByOptions: [],
  isLoadingSortByOptions: true,
};

// Reducers
export const parMaintenanceSlice = createSlice({
  name: 'parMaintenance',
  initialState: initialState,
  reducers: {
    setIsLoadingParListHeader: (state: ParMaintenanceState, action: PayloadAction<boolean>) => {
      state.isLoadingParListHeader = action.payload;
    },
    setParListHeader: (state: ParMaintenanceState, action: PayloadAction<ParListHeader>) => {
      state.parListHeader = action.payload;
    },
    resetParListHeader: (state: ParMaintenanceState) => {
      state.parListHeader = initialState.parListHeader;
    },
    setIsProductListHeadersLoading: (state: ParMaintenanceState, action: PayloadAction<boolean>) => {
      state.isProductListHeadersLoading = action.payload;
    },
    resetProductListHeadersLoading: (state: ParMaintenanceState) => {
      state.isProductListHeadersLoading = initialState.isProductListHeadersLoading;
    },
    setProductListHeaders: (state: ParMaintenanceState, action: PayloadAction<ProductListHeaderCustom[]>) => {
      productListHeadersAdapter.setAll(state.productListHeaders, action.payload);
    },
    resetProductListHeaders: (state: ParMaintenanceState) => {
      state.productListHeaders = initialState.productListHeaders;
    },
    setIsLoadingParDetails: (state: ParMaintenanceState, action: PayloadAction<boolean>) => {
      state.isParDetailsLoading = action.payload;
    },
    setParDetailUpdateResult: (state: ParMaintenanceState, action: PayloadAction<ParListDetail | undefined>) => {
      state.parDetailUpdateResult = action.payload;
      if (action.payload) {
        parDetailsAdapter.addOne(state.parDetails, action.payload);
        parDetailsAdapter.updateOne(state.parDetails, {
          id: `${action.payload.ProductKey}-${action.payload.UnitOfMeasure}` ?? '',
          changes: { Quantity: action.payload.Quantity },
        });
      }
    },
    resetParDetails: (state: ParMaintenanceState) => {
      state.parDetails = initialState.parDetails;
    },
    setIsParDetailUpdating: (state: ParMaintenanceState, action: PayloadAction<boolean>) => {
      state.isParDetailsUpdateLoading = action.payload;
    },
    resetSearchProductListParMaintenance: (state: ParMaintenanceState) => {
      state.products = productsAdapter.getInitialState();
      state.productSearchResult = initialState.productSearchResult;
      state.apiRequest = initialState.apiRequest;
      state.parDetails = initialState.parDetails;
    },
    setApiRequest: (state: ParMaintenanceState, action: PayloadAction<GetParEntrySearchRequest | undefined>) => {
      state.apiRequest = action.payload;
    },
    setIsLoadingProductSearchResult: (state: ParMaintenanceState, action: PayloadAction<boolean>) => {
      state.isLoadingProductSearchResult = action.payload;
    },
    searchProductListParMaintenance: (
      state: ParMaintenanceState,
      action: PayloadAction<{
        request: GetParEntrySearchRequest;
        result: GetParEntrySearchResultDetail;
        previousPage?: ProductListGridData[];
      }>
    ) => {
      const { request, result, previousPage } = action.payload;
      const data = getNormalizedProductListGridData(result.ProductListCategories, false, previousPage);

      categoriesAdapter.addMany(state.categories, data.categories);
      categoryProductsAdapter.setLoadedPage(state.categoryProducts, {
        pageIndex: result.pageIndex,
        entities: data.categoryProducts,
      });
      productsAdapter.addMany(state.products, data.products);
      state.apiRequest = request;
      state.productSearchResult = result;
      parDetailsAdapter.addMany(state.parDetails, result.ParListDetails);
    },
    setIsShowCompletedToggled: (state: ParMaintenanceState, action: PayloadAction<boolean>) => {
      state.isShowCompletedToggled = action.payload;
    },
    setSortByOptions: (state: ParMaintenanceState, action: PayloadAction<ProductListSortOption[]>) => {
      state.sortByOptions = action.payload;
    },
    setIsLoadingSortByOptions: (state: ParMaintenanceState, action: PayloadAction<boolean>) => {
      state.isLoadingSortByOptions = action.payload;
    },
    setPageLoading: (state: ParMaintenanceState, action: PayloadAction<EntityPageLoading>) => {
      categoryProductsAdapter.setLoadingPage(state.categoryProducts, action.payload);
    },
    updateCategoryIsCollapsed: (
      state: ParMaintenanceState,
      action: PayloadAction<{ isCollapsed: boolean; categoryId: string }>
    ) => {
      const category = state.categories.entities[action.payload.categoryId];
      if (category) category.IsCollapsed = action.payload.isCollapsed;
    },
    resetCategories: (state: ParMaintenanceState) => {
      state.categories = categoriesAdapter.getInitialState();
    },
    updateProductCustomAttributes: (state: ParMaintenanceState, action: PayloadAction<UpdateCustomProductRequest>) => {
      const product = state.products.entities[normalizeProductKey(action.payload.ProductKey)];
      if (!product) return;

      updateProductCustomAttributesFromPayload(product, action.payload);
    },
  },
});

// Selectors

const getState = (state: RootState): RootState => state;
const getIndex = (state: RootState, index: number): number => index;
const getId = (state: RootState, id: string): string => id;

export const { selectAll: selectAllParMaintenanceCategories, selectById: selectParMaintenanceCategoriesById } =
  categoriesAdapter.getSelectors<EntityState<ProductListCategoryCustom>>(
    (state: EntityState<ProductListCategoryCustom>) => state
  );

export const {
  selectAll: selectAllParMaintenanceCatalogProducts,
  selectById: selectParMaintenanceCatalogProductById,
  selectIsLoading: selectParMaintenanceProductListIsLoading,
  selectByPage: selectParMaintenanceCatalogProductsByPage,
} = categoryProductsAdapter.getSelectors<PagedEntityState<ProductListCategoryProduct>>(
  (state: PagedEntityState<ProductListCategoryProduct>) => state
);

export const { selectAll: selectAllParMaintenanceProducts, selectById: selectParMaintenanceProductById } =
  productsAdapter.getSelectors<EntityState<ProductListProduct>>((state: EntityState<ProductListProduct>) => state);

export const { selectAll: selectAllParDetails, selectById: selectParDetailById } = parDetailsAdapter.getSelectors<
  EntityState<ParListDetail>
>((state: EntityState<ParListDetail>) => state);

export const { selectAll: selectAllParProductListHeaders, selectById: selectParProductListHeadersById } =
  productListHeadersAdapter.getSelectors<EntityState<ProductListHeaderCustom>>(
    (state: EntityState<ProductListHeaderCustom>) => state
  );

// Custom Selectors
export const selectParMaintenanceGridDataById = createCachedSelector(
  (state: RootState) => state.parMaintenance.categories,
  (state: RootState) => state.parMaintenance.categoryProducts,
  (state: RootState) => state.parMaintenance.products,
  (state: RootState) => state.parMaintenance.parDetails,
  (_state: RootState, id: string) => id,
  (categories, categoryProducts, products, parDetails, id) => {
    const normalizedId = normalizeKey(id);

    const category = selectParMaintenanceCategoriesById(categories, normalizedId);
    if (category)
      return {
        category: category,
        categoryProduct: undefined,
        product: undefined,
        parDetails: undefined,
      } as ParListGridData;

    const categoryProduct = selectParMaintenanceCatalogProductById(categoryProducts, normalizedId);
    if (categoryProduct) {
      const category = selectParMaintenanceCategoriesById(categories, categoryProduct.CategoryId);
      const product = selectParMaintenanceProductById(products, normalizeProductKey(categoryProduct.ProductKey));

      if (product) {
        const allParDetail = selectAllParDetails(parDetails);
        const parDetail = allParDetail.filter((s) => s.ProductKey === product.ProductKey);
        return { category, categoryProduct, product, parDetail } as ParListGridData;
      }
    }

    return undefined;
  }
)({
  keySelector: (_state: RootState, categoryProductId: string) => categoryProductId,
  cacheObject: new LruObjectCache({ cacheSize: 100 }),
});

export const selectAllParMaintenanceIds = createSelector(
  (state: RootState) => state.parMaintenance.categories,
  (state: RootState) => state.parMaintenance.categoryProducts,
  (
    categories,
    categoryProducts
  ): {
    [pageIndex: number]: EntityPage;
  } => {
    const categorySet = new Set<string>();

    const pages: {
      [pageIndex: number]: EntityPage;
    } = {};

    for (let i = 0; i <= categoryProducts.totalPageCount; i++) {
      const page = categoryProducts.pages?.[i];
      if (!page) continue; // Loading pages are not included in the `totalPageCount`. Check for loading page

      if (page.isLoading) {
        pages[i] = { ...page };
      } else {
        const ids: string[] = [];
        page.ids.forEach((id) => {
          const categoryProduct = selectParMaintenanceCatalogProductById(categoryProducts, id);
          if (!categoryProduct) return;
          const category = selectParMaintenanceCategoriesById(categories, categoryProduct?.CategoryId);
          if (!category) return;

          if (!category.IsCollapsed) {
            ids.push(id);
          } else if (category.IsCollapsed && !categorySet.has(category.Id)) {
            ids.push(category.Id);
            categorySet.add(category.Id);
          }
        });
        pages[i] = { ...page, ids };
      }
    }
    return pages;
  }
);

export const selectParMaintenanceProductListGridDataByPage = createSelector([getState, getIndex], (state, index) => {
  const result: ParListGridData[] = [];
  const categoryProducts = selectParMaintenanceCatalogProductsByPage(state.parMaintenance.categoryProducts, index);
  categoryProducts.forEach((cp) => {
    if (cp) {
      const data = selectParMaintenanceGridDataById(state, cp.Id);
      if (data) result.push(data);
    }
  });
  return result;
});

export const selectParMaintenanceSelectedProductListHeader = createSelector([getState, getId], (state, id) => {
  return selectParProductListHeadersById(state.parMaintenance.productListHeaders, id);
});
