import { RouteName } from './routing.models';

export interface AppNotification {
  Id: string;
  Title?: string;
  Message?: string;
  Icon?: string;
  NotificationType: NotificationType;
  NotificationDisplayType: NotificationDisplayType;
  AutoDismiss: number;
  CanUserDismiss: boolean;
  Key: NotificationKeys;
  RouteNames?: RouteName[];
}

export enum NotificationType {
  Success,
  Error,
  Warning,
  Information,
  System,
  Neutral,
}

export enum NotificationDisplayType {
  Toast,
  Page,
  Component,
  Field,
  Growler,
}

export enum NotificationKeys {
  Default,
  GrowlerError,
  GrowlerSuccess,
  GrowlerWarning,
  GrowlerInformation,
  GrowlerSystem,
  ItemAddedSuccessfully,
  PageLevelError, // displays a bar on the top of the screen in the header
  PageLevelMessage, // displays a bar on the top of the screen in the header
  TextFieldNotification,
  CustomerAlertWarning,
  CustomerAlertInformation,
  CustomerAlertSystem,
  QuantityFieldNotification,
  PORequired,
  InvalidDeliveryDate,
  Toast,
  CustomErrorInformation,
}
