import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { MessageRecipientType } from '../../api';
import {
  CreateMessageGroupRequest,
  MessageGroup,
  MessageGroupBasic,
  MessageGroupRecipient,
  MessageGroupRecipientBasic,
  UpdateMessageGroupRequest,
} from '../../api/models/message-group.models';
import { arraysEqual, getSortComparer } from '../../helpers/general/array';
import { normalizeKey } from '../../helpers/general/string';
import { RootState } from '../store';

const messageGroupRecipientsAdapter = createEntityAdapter<MessageGroupRecipient>({
  selectId: (recipient: MessageGroupRecipient) => normalizeKey(recipient.MessageGroupRecipientId),
  sortComparer: getSortComparer(
    (recipient) => -recipient.MessageGroupRecipientType + 3 + recipient.MessageGroupRecipientNameDisplay
  ),
});

interface ValidationMessages {
  messageGroupName: string[];
}
interface MessageGroupSelectedState {
  messageGroup: MessageGroupBasic | undefined;
  recipients: EntityState<MessageGroupRecipient>;
  recipientsLoading: boolean;
  originalMessageGroup:
    | {
        MessageGroupName: string;
        Recipients: MessageGroupRecipientBasic[];
      }
    | undefined;
  validationMessages: ValidationMessages;
  saveMessageGroupRequest: CreateMessageGroupRequest | UpdateMessageGroupRequest | undefined;
}

const initialState: MessageGroupSelectedState = {
  messageGroup: undefined,
  recipients: messageGroupRecipientsAdapter.getInitialState(),
  recipientsLoading: false,
  originalMessageGroup: undefined,
  validationMessages: {
    messageGroupName: [],
  },
  saveMessageGroupRequest: undefined,
};

export const messageGroupSelectedSlice = createSlice({
  name: 'messageGroupSelected',
  initialState,
  reducers: {
    resetState: () => initialState,
    setMessageGroupBasic: (state: MessageGroupSelectedState, action: PayloadAction<MessageGroupBasic>) => {
      state.messageGroup = action.payload;
      state.recipientsLoading = false;
    },
    setMessageGroupFull: (state: MessageGroupSelectedState, action: PayloadAction<MessageGroup>) => {
      if (
        state.messageGroup &&
        state.messageGroup.MessageGroupId.toLowerCase() !== action.payload.MessageGroupId.toLowerCase()
      )
        return;

      state.messageGroup = {
        MessageGroupId: action.payload.MessageGroupId,
        MessageGroupName: action.payload.MessageGroupName,
        MessageGroupMemberCount: action.payload.Recipients.length,
      };
      messageGroupRecipientsAdapter.setAll(
        state.recipients,
        action.payload.Recipients.map((r) => ({ ...r, ObjectValue: r.ObjectValue.toLowerCase() }))
      );
      state.originalMessageGroup = {
        MessageGroupName: action.payload.MessageGroupName,
        Recipients: action.payload.Recipients.sort(
          getSortComparer((recipient) => recipient.MessageGroupRecipientType, true) // Sorts: CustomerGroup, Customer, User
        ).map((recipient) => ({
          MessageGroupRecipientType: recipient.MessageGroupRecipientType,
          ObjectValue: recipient.ObjectValue,
        })),
      };
      state.recipientsLoading = false;
      state.saveMessageGroupRequest = undefined;
    },
    setMessageGroupRecipients: (
      state: MessageGroupSelectedState,
      action: PayloadAction<{
        recipients: MessageGroupRecipient[];
        setOnlyRecipientsOfType?: MessageRecipientType;
      }>
    ) => {
      const { recipients, setOnlyRecipientsOfType } = action.payload;
      messageGroupRecipientsAdapter.setAll(
        state.recipients,
        recipients.concat(
          selectAllRecipientsLocal(state).filter(
            (r) => setOnlyRecipientsOfType !== undefined && r.MessageGroupRecipientType !== setOnlyRecipientsOfType
          )
        )
      );
    },
    removeMessageGroupRecipientById: (state: MessageGroupSelectedState, action: PayloadAction<string>) => {
      messageGroupRecipientsAdapter.removeOne(state.recipients, action.payload);
    },
    resetMessageGroupRecipients: (state: MessageGroupSelectedState) => {
      state.recipients = initialState.recipients;
      state.recipientsLoading = initialState.recipientsLoading;
      state.originalMessageGroup = initialState.originalMessageGroup;
    },

    setRecipientsLoading: (state: MessageGroupSelectedState, action: PayloadAction<boolean>) => {
      state.recipientsLoading = action.payload;
    },
    setValidationMessages: (state: MessageGroupSelectedState, action: PayloadAction<ValidationMessages>) => {
      state.validationMessages = action.payload;
    },
    setSaveMessageGroupApiRequest: (
      state: MessageGroupSelectedState,
      action: PayloadAction<CreateMessageGroupRequest | UpdateMessageGroupRequest | undefined>
    ) => {
      state.saveMessageGroupRequest = action.payload;
    },
  },
});

export const {
  selectAll: selectAllMessageGroupRecipients,
  selectById: selectMessageGroupRecipientById,
  selectIds: selectAllMessageGroupRecipientIds,
} = messageGroupRecipientsAdapter.getSelectors<EntityState<MessageGroupRecipient>>(
  (recipients: EntityState<MessageGroupRecipient>) => recipients
);

const getSelectRecipientsByMessageRecipientTypeSelector = (type: MessageRecipientType) =>
  createSelector(selectAllMessageGroupRecipients, (recipients: MessageGroupRecipient[]) => {
    return recipients.filter((r) => r.MessageGroupRecipientType === type);
  });

const recipientsSelectors = {
  [MessageRecipientType.User]: getSelectRecipientsByMessageRecipientTypeSelector(MessageRecipientType.User),
  [MessageRecipientType.Customer]: getSelectRecipientsByMessageRecipientTypeSelector(MessageRecipientType.Customer),
  [MessageRecipientType.CustomerGroup]: getSelectRecipientsByMessageRecipientTypeSelector(
    MessageRecipientType.Customer
  ),
  [MessageRecipientType.MessageGroup]: getSelectRecipientsByMessageRecipientTypeSelector(MessageRecipientType.Customer),
};

export const getMessageGroupRecipientSelector = (type: MessageRecipientType) => {
  return recipientsSelectors[type];
};

const { selectAll: selectAllRecipientsLocal } = messageGroupRecipientsAdapter.getSelectors<MessageGroupSelectedState>(
  (state: MessageGroupSelectedState) => state.recipients
);

export const selectAllRecipientsKeyedByObjectValue = createSelector(
  (state: RootState) => state.messageGroupSelected.recipients,
  (recipients) => Object.fromEntries(selectAllMessageGroupRecipients(recipients).map((r) => [r.ObjectValue, r]))
);

export const selectIsValidMessageGroup = createSelector(
  (state: RootState) => state.messageGroupSelected.validationMessages.messageGroupName,
  (state: RootState) => state.messageGroupSelected.recipients.ids,
  (messageGroupName, recipientIds) => messageGroupName.length === 0 && recipientIds.length > 0
);

export const selectDoesMessageGroupHaveUnsavedChanges = createSelector(
  (state: RootState) => state.messageGroupSelected.recipientsLoading,
  (state: RootState) => state.messageGroupSelected.messageGroup,
  (state: RootState) => state.messageGroupSelected.originalMessageGroup,
  (state: RootState) => state.messageGroupSelected.recipients,
  (recipientsLoading, messageGroup, originalMessageGroup, messageGroupRecipients) =>
    !(
      recipientsLoading ||
      ((messageGroup?.MessageGroupName ?? '') === (originalMessageGroup?.MessageGroupName ?? '') &&
        arraysEqual(
          selectAllMessageGroupRecipients(messageGroupRecipients)
            .map((r) => r.ObjectValue.toLowerCase())
            .sort(getSortComparer((id) => id)),
          originalMessageGroup?.Recipients.map((r) => r.ObjectValue.toLowerCase()).sort(getSortComparer((id) => id)) ||
            []
        ))
    )
);
