import { AppRoutes, convertTimeToUnit, getTimeBetween, RootState, RouteName, useAppSelector, useTimer } from 'common';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { matchPath, useHistory } from 'react-router-dom';
import { CfAutoDismissToast } from '../../../../cf-ui/Toast/CfAutoDismissToast';
import { usePageNotification } from '../../../../hooks/usePageNotification';

export interface ExpiringOrderToastProps {
  expirationDate: Date;
}

export const ExpiringOrderToast = (props: ExpiringOrderToastProps): JSX.Element => {
  const history = useHistory();
  const order = useAppSelector((s: RootState) => s.orders.order);
  const height = usePageNotification()?.height;
  const seconds = getTimeBetween(new Date(), props.expirationDate, 'second', false);
  const minutes = convertTimeToUnit(seconds, 'second', 'minute');
  const toastMessage =
    minutes < 1
      ? 'You have less than 1 minute remaining to edit your order'
      : minutes < 2
      ? `You have ${Math.floor(minutes)} minute remaining to edit your order`
      : `You have ${Math.floor(minutes)} minutes remaining to edit your order`;
  const timerThreshold = convertTimeToUnit(5, 'minute', 'second');
  const [isOpen, setIsOpen] = useState(false);
  const [isNotified, setIsNotified] = useState(false);

  const navigateToOrderEntry = () => {
    history.push(`${AppRoutes[RouteName.OrderEntry].Path}/${order?.OrderEntryHeaderId}`);
  };

  // Set isNotified flag to true when the toast closes (reset isNotified to false on path changes).
  const handleClose = () => {
    if (isOpen) {
      setIsOpen(false);
      setIsNotified(true);
    }
  };

  const matchesPath = (routeNameExceptions: RouteName[]) => {
    return routeNameExceptions
      .map((routeName) => matchPath(history.location.pathname, { path: AppRoutes[routeName].Path, exact: false }))
      .some((n) => !!n);
  };

  useEffect(() => {
    if (matchesPath([RouteName.OrderEntry, RouteName.OrderReview])) {
      setIsNotified(false);
    } else if (matchesPath([RouteName.OrderConfirmation])) {
      setIsNotified(true);
    } else {
      restart();
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (props.expirationDate && seconds > 0) {
      restart();
    } else {
      stop();
    }
  }, [props.expirationDate]);

  const { restart, stop } = useTimer(Math.max(seconds - timerThreshold, 0), () => {
    const show = !!(
      seconds >= 0 &&
      !isNotified &&
      !matchesPath([RouteName.OrderEntry, RouteName.OrderReview, RouteName.OrderConfirmation])
    );
    setIsOpen(show);
  });

  return (
    <CfAutoDismissToast
      action={() => navigateToOrderEntry()}
      actionLabel='Go to order'
      autoDimissSeconds={5}
      message={toastMessage}
      isOpen={isOpen}
      variant='info'
      sx={{ mt: height ? height / 8 + 2 : 0 }}
      onClose={handleClose}
    />
  );
};
