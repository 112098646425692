import { Box, styled, Theme } from '@mui/material';
import { NotificationType } from 'common';

export const NotificationContainer = styled(Box)`
  display: block;
  width: 100%;
  ${({ theme }: { theme: Theme }) => `
    color: ${theme.palette.common.white};
  `};
`;

export const NotificationHeaderXS = styled(Box, {
  shouldForwardProp: (name) => name !== '$notificationType',
})<{ $notificationType: NotificationType | undefined }>(({ theme, $notificationType }) => ({
  color: $notificationType === NotificationType.Warning ? theme.palette.grey[900] : undefined,
  backgroundColor:
    $notificationType == undefined
      ? theme.palette.background.default
      : $notificationType == NotificationType.Error
      ? theme.palette.error.main
      : $notificationType == NotificationType.Warning
      ? theme.palette.warning.main
      : $notificationType == NotificationType.Success
      ? theme.palette.success.main
      : $notificationType == NotificationType.Information
      ? theme.palette.info.main
      : theme.palette.background.default,
}));

export const NotificationHeader = styled(NotificationHeaderXS)<{
  $notificationType: NotificationType | undefined;
}>`
  white-space: nowrap;
  height: 24px;
  padding-left: 16px;
`;

export const NotificationContent = styled(Box)`
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 185px;
`;

export const NotificationBody = styled(Box)`
  padding: 6px 16px;
  ${({ theme }: { theme: Theme }) => `
    background-color: ${theme.palette.coolGrey[100]};
    color: ${theme.palette.common.black};
  `};
`;
