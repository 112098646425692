import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { OverviewSlideGroups, ViewStatus } from '../../api/models/customer-support.enums';
import { GetOverviewSlidesRequest, UpdateOverviewViewStatusRequest } from '../../api/models/customer-support.models';
import CustomerSupportService from '../../api/services/customer-support.service';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { globalSlice } from '../common/global.slice';
import { AppDispatch, AppThunk, RootState } from '../store';
import { customerSupportSlice } from './customer-support.slice';

const customerSupportService = CustomerSupportService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Calls and stores the result of the GetOverviewSlides API call
 *
 * @param group - The overview slide group being retrieved
 * @returns NULL
 */
export const getOverviewSlidesByGroup =
  (group: OverviewSlideGroups): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(customerSupportSlice.actions.setIsOverviewDialogLoading(true));
      dispatch(customerSupportSlice.actions.setCurrentSlideGroup(group));
      const apiRequest: GetOverviewSlidesRequest = {
        slideGroup: group,
      };
      const { data } = await customerSupportService.getOverviewSlides(apiRequest);
      if (data.IsSuccess) {
        dispatch(customerSupportSlice.actions.setCurrentSlides(data.ResultObject));
        dispatch(customerSupportSlice.actions.setShowOverviewDialog(true));
      } else {
        dispatch(customerSupportSlice.actions.resetSlideGroup());
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      dispatch(customerSupportSlice.actions.resetSlideGroup());
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(customerSupportSlice.actions.setIsOverviewDialogLoading(false));
    }
  };

/**
 * Calls and stores the result of the GetOverviewViewedSlideGroups API call
 *
 * @returns NULL
 */
export const getOverviewViewedSlideGroups =
  (): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const overviewState = getState().customerSupport.overviewViewStatus;
      const copiedObject = Object.assign({}, overviewState);
      const { data } = await customerSupportService.getOverviewViewedSlideGroups();
      if (data.IsSuccess) {
        const result = data.ResultObject;
        result.forEach((group: OverviewSlideGroups) => {
          copiedObject[group] = ViewStatus.Viewed;
        });
        dispatch(customerSupportSlice.actions.setOverviewViewedSlides(copiedObject));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls and stores the result of the UpdateOverviewViewStatus API call
 *
 * @param group - The overview slide group being retrieved
 * @returns NULL
 */
export const updateViewedSlideGroup =
  (group: number): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const apiRequest: UpdateOverviewViewStatusRequest = {
        slideGroup: group,
      };
      const overviewState = getState().customerSupport.overviewViewStatus;
      const copiedObject = Object.assign({}, overviewState);
      const { data } = await customerSupportService.updateOverviewViewStatus(apiRequest);
      if (data.IsSuccess) {
        const result = data.ResultObject;
        result.forEach((group: OverviewSlideGroups) => {
          copiedObject[group] = ViewStatus.Viewed;
        });
        dispatch(customerSupportSlice.actions.setOverviewViewedSlides(copiedObject));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        SeverityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls the getOverviewSlidesByGroup if the group is not viewed or if the group's viewStatus is undefined
 *
 * @param group - The overview slide group being retrieved
 * @returns NULL
 */
export const getOverviewViewStatus =
  (group: OverviewSlideGroups): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const viewStatus = getState().customerSupport.overviewViewStatus[group];
      if (viewStatus === ViewStatus.NotViewed || viewStatus === undefined) {
        dispatch(getOverviewSlidesByGroup(group));
      }
    } catch (error) {
      dispatch(globalSlice.actions.setErrorDialogContent({ messages: error as string[] }));
    }
  };
