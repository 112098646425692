import { ItemData, PromotionData, PurchaseData } from './google-analytics.models';

/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    gtag: any;
  }
}

export interface CfGaConfig {
  trackingId: string;
  consent: 'granted' | 'denied';
  debug?: boolean;
}

// Note: Support of custom metrics and dimensions may require the creation of a custom map: https://developers.google.com/analytics/devguides/collection/gtagjs/custom-dims-mets
export class CfGa {
  private _debug: boolean;

  constructor() {
    this._debug = false;
  }

  public initialize(config: CfGaConfig): void {
    this._debug = config.debug ?? false;

    if (!window.gtag) {
      this.warn('GoogleAnalytics is not loaded');
      return;
    }

    this._consent('default', { ad_storage: config.consent, analytics_storage: config.consent });
    this._config(config.trackingId, { anonymize_ip: true, send_page_view: false, debug_mode: this._debug });

    if (config.debug) this.log(`init: ${config.trackingId}, debug: ${config.debug}`);
  }

  /*
   * GA Functions
   */

  /**
   * event:
   * GA consent event
   * @param consent {boolean} required
   */
  public updateConsent(consent: boolean): void {
    // Send to GA
    this._consent('update', {
      ad_storage: consent,
      analytics_storage: consent,
    });
  }

  /**
   * event:
   * GA event tracking
   * @param category {String} required
   * @param action {String} required
   * @param label {String} optional
   * @param value {Int} optional
   * @param nonInteraction {boolean} optional
   * @param dimensions { [name: string]: string } optional where name is a key of the following format: `dimension#`
   * @param metrics { [name: string]: number } optional where name is a key of the following format: `metric#`
   */
  public event(
    category: string,
    action: string,
    label?: string,
    value?: number,
    nonInteraction?: boolean,
    dimensions?: { [name: string]: string },
    metrics?: { [name: string]: number }
  ): void {
    // Validation
    if (!category) {
      this.warn('category is required in .event()');
      return;
    }
    if (!action) {
      this.warn('category is required in .event()');
      return;
    }

    // Required Fields
    const fieldObject: any = {
      event_category: category,
    };

    // Optional Fields
    if (label) fieldObject.event_label = label;

    if (typeof value !== 'undefined' && typeof value !== 'number') {
      this.warn('`value` must be a number.');
    } else {
      fieldObject.event_value = value;
    }

    if (typeof nonInteraction !== 'undefined' && typeof nonInteraction !== 'boolean') {
      this.warn('`nonInteraction` must be a boolean.');
    } else {
      fieldObject.non_interaction = nonInteraction;
    }

    // Send to GA
    this._event(action, {
      ...fieldObject,
      ...this._normalizeDimensions(dimensions),
      ...this._normalizeMetrics(metrics),
    });
  }

  /**
   * pageview:
   * Basic GA pageview tracking
   * @param path {String}  required
   * @param title {String}  optional
   * @param location {String} optional
   */
  public pageview(path: string, title?: string, location?: string): void {
    const trimmedPath = path.trim();
    if (!path || trimmedPath === '') {
      this.warn('path is required in .pageview()');
      return;
    }

    // Required fields
    const fieldObject: any = {
      page_path: path,
    };

    // Optional fields
    if (title) fieldObject.page_title = title;
    if (location) fieldObject.page_location = location;

    this._event('page_view', fieldObject);
  }

  /**
   * timing:
   * GA timing
   * @param name {String} required
   * @param value  {Int}  required
   * @param eventCategory {String} optional
   * @param eventLabel  {String} optional
   */
  public timing(name: string, value: number, eventCategory: string, eventLabel: string): void {
    // Validation
    if (!name) {
      this.warn('name is required in .timing()');
      return;
    }
    if (!value) {
      this.warn('value is required in .timing()');
      return;
    }
    if (typeof value !== 'number') {
      this.warn('value has to be a number in .timing()');
      return;
    }

    // Required Fields
    const fieldObject: any = {
      name: name,
      value: value,
    };

    // Optional fields
    if (eventCategory) fieldObject.event_category = eventCategory;
    if (eventLabel) fieldObject.event_label = eventLabel;

    this._event('timing_complete', fieldObject);
  }

  /**
   * search:
   * Basic GA search
   * @param searchTerm {String}  required
   */
  public search(searchTerm: string): void {
    // Required fields
    const fieldObject: any = {
      search_term: searchTerm,
    };

    this._event('search', fieldObject);
  }

  /*
   * Enhanced Ecommerce Functions
   */

  /* Product views and Interactions ***************************/

  /**
   * viewItemList:
   * A user views a list of one or more products.
   * @param items {ItemData[]}  required. The items for the event.
   * @param itemListId {string} optional. The ID of the list in which the item was presented to the user. Ignored if set at the item-level.
   * @param itemListName {string} optional. The name of the list in which the item was presented to the user. Ignored if set at the item-level.
   */
  public viewItemList(items: ItemData[], itemListId?: string, itemListName?: string): void {
    this._normalizeEvent('view_item_list', items), { itemListId, itemListName };
  }

  /**
   * viewItem:
   * A user views details for a product.
   * @param item {ItemData} required. The item for the event.
   * @param currency {string} required. Currency of the items associated with the event, in 3-letter ISO 4217 format.
   * @param value {number} required. The monetary value of the event.
   */
  public viewItem(item: ItemData, currency: string, value: number): void {
    this._normalizeEvent('view_item', [item], { currency, value });
  }

  /**
   * selectItem:
   * A user clicks on a product or product link from a list
   * @param item {ItemData[]}  required. The items for the event.
   * @param itemListId {string} optional. The ID of the list in which the item was presented to the user. Ignored if set at the item-level.
   * @param itemListName {string} optional. The name of the list in which the item was presented to the user. Ignored if set at the item-level.
   */
  public selectItem(item: ItemData, itemListId?: string, itemListName?: string): void {
    this._normalizeEvent('select_item', [item], { itemListId, itemListName });
  }

  /**
   * selectContent:
   * @param contentType {string} optional.
   * @param itemId {string} optional.
   */
  public selectContent(contentType?: string, itemId?: string): void {
    this._normalizeEvent('select_content', [], { contentType, itemId });
  }

  /* Internal Promoitions ***************************/

  /**
   * viewPromotion:
   * A user views an internal promotion.
   * @param item {PromotionData} required. The item for the event.
   * @param creativeName {string} optional. The name of the promotional creative. Ignored if set at the item-level.
   * @param creativeSlot {string} optional. The name of the promotional creative slot associated with the event. Ignored if set at the item-level.
   * @param locationId {string} optional. The ID of the location. Ignored if set at the item-level.
   * @param promotionId {string} optional. The ID of the promotion associated with the event. Ignored if set at the item-level.
   * @param promotionNAme {string} optional. The name of the promotion associated with the event. Ignored if set at the item-level.
   */
  public viewPromotion(item: ItemData, promotion: PromotionData): void {
    this._normalizeEvent('view_promotion', [item], promotion);
  }

  /**
   * selectPromotion:
   * A user clicks on an internal promotion.
   * @param item {PromotionData} required. The item for the event.
   * @param creativeName {string} optional. The name of the promotional creative. Ignored if set at the item-level.
   * @param creativeSlot {string} optional. The name of the promotional creative slot associated with the event. Ignored if set at the item-level.
   * @param locationId {string} optional. The ID of the location. Ignored if set at the item-level.
   * @param promotionId {string} optional. The ID of the promotion associated with the event. Ignored if set at the item-level.
   * @param promotionNAme {string} optional. The name of the promotion associated with the event. Ignored if set at the item-level.
   */
  public selectPromotion(item: ItemData, promotion: PromotionData): void {
    this._normalizeEvent('select_promotion', [item], promotion);
  }

  /* Shopping Cart Interactions ***************************/
  /**
   * addToCart:
   * A user adds one or more products to a shopping cart.
   * @param items {ItemData[]}  required
   * @param currency {string} required. Currency of the items associated with the event, in 3-letter ISO 4217 format.
   * @param value {number} required. The monetary value of the event.
   */
  public addToCart(items: ItemData[], currency: string, value: number): void {
    this._normalizeEvent('add_to_cart', items, { currency, value });
  }

  /**
   * addToWishlist:
   * A user adds one or more products to their wishlist
   * @param items {ItemData[]}  required
   * @param currency {string} required. Currency of the items associated with the event, in 3-letter ISO 4217 format.
   * @param value {number} required. The monetary value of the event.
   */
  public addToWishlist(items: ItemData[], currency: string, value: number): void {
    this._normalizeEvent('add_to_wishlist', items, { currency, value });
  }

  /**
   * removeFromCart:
   * A user removes one or more products from a shopping cart.
   * @param items {ItemData[]}  required
   * @param currency {string} required. Currency of the items associated with the event, in 3-letter ISO 4217 format.
   * @param value {number} required. The monetary value of the event.
   */
  public removeFromCart(items: ItemData[], currency: string, value: number): void {
    this._normalizeEvent('remove_from_cart', items, { currency, value });
  }

  /**
   * viewCart:
   * A user removes one or more products from a shopping cart.
   * @param items {ItemData[]}  required
   * @param currency {string} required. Currency of the items associated with the event, in 3-letter ISO 4217 format.
   * @param value {number} required. The monetary value of the event.
   */
  public viewCart(items: ItemData[], currency: string, value: number): void {
    this._normalizeEvent('view_cart', items, { currency, value });
  }

  /* Checkout Funnel ***************************/

  /**
   * beginCheckout:
   * A user initiates the checkout process for one or more products.
   * @param items {ItemData[]}  required
   * @param currency {string} required. Currency of the items associated with the event, in 3-letter ISO 4217 format.
   * @param value {number} required. The monetary value of the event.
   * @param coupon {string} optional. The coupon name/code associated with the event. Event-level and item-level coupon parameters are independent.
   */
  public beginCheckout(items: ItemData[], currency: string, value: number, coupon?: string): void {
    this._normalizeEvent('begin_checkout', items, { currency, value, coupon });
  }

  /**
   * addPaymentInfo:
   * This event signifies a user has submitted their payment information.
   * @param items {ItemData[]}  required
   * @param currency {string} required. Currency of the items associated with the event, in 3-letter ISO 4217 format.
   * @param value {number} required. The monetary value of the event.
   * @param coupon {string} optional. The coupon name/code associated with the event. Event-level and item-level coupon parameters are independent.
   * @param paymentType {string} optional. The chosen method of payment.
   */
  public addPaymentInfo(
    items: ItemData[],
    currency: string,
    value: number,
    coupon?: string,
    paymentType?: string
  ): void {
    this._normalizeEvent('add_payment_info', items, { currency, value, coupon, paymentType });
  }

  /**
   * addShippingInfo:
   * This event signifies a user has submitted their shipping information.
   * @param items {ItemData[]}  required
   * @param currency {string} required. Currency of the items associated with the event, in 3-letter ISO 4217 format.
   * @param value {number} required. The monetary value of the event.
   * @param coupon {string} optional. The coupon name/code associated with the event. Event-level and item-level coupon parameters are independent.
   * @param shippingTier {string} optional. The shipping tier (e.g. Ground, Air, Next-day) selected for delivery of the purchased item.
   */
  public addShippingInfo(
    items: ItemData[],
    currency: string,
    value: number,
    coupon?: string,
    shippingTier?: string
  ): void {
    this._normalizeEvent('add_shipping_info', items, { currency, value, coupon, shippingTier });
  }

  /**
   * purchase:
   * A user completes a purchase.
   * @param items {ItemData[]}  required.
   * @param currencey {ItemData[]}  required. Currency of the items associated with the event, in 3-letter ISO 4217 format.
   * @param transactionId {ItemData[]}  required. The unique identifier of a transaction.
   * @param value {ItemData[]}  required. The monetary value of the event.
   * @param affiliation {ItemData[]}  optional. A product affiliation to designate a supplying company or brick and mortar store location.
   * @param coupon {ItemData[]}  optional. The coupon name/code associated with the event. Event-level and item-level coupon parameters are independent.
   * @param shipping {ItemData[]}  optional. Shipping cost associated with a transaction.
   */
  public purchase(items: ItemData[], purchase: PurchaseData): void {
    this._normalizeEvent('purchase', items, purchase);
  }

  /**
   * refund:
   * A user is issued a refund for one or more products.
   * To measure a full refund of a transaction, send a refund event with the transaction ID
   * To measure a partial refund, send a refund event with the transaction ID and the items to be refunded
   * @param items {ItemData[]}  required.
   * @param currencey {ItemData[]}  required. Currency of the items associated with the event, in 3-letter ISO 4217 format.
   * @param transactionId {ItemData[]}  required. The unique identifier of a transaction.
   * @param value {ItemData[]}  required. The monetary value of the event.
   * @param affiliation {ItemData[]}  optional. A product affiliation to designate a supplying company or brick and mortar store location.
   * @param coupon {ItemData[]}  optional. The coupon name/code associated with the event. Event-level and item-level coupon parameters are independent.
   * @param shipping {ItemData[]}  optional. Shipping cost associated with a transaction.
   * @param tax {ItemData[]}  optional. Tax cost associated with a transaction.
   */
  public refund(items: ItemData[], purchase: PurchaseData): void {
    this._normalizeEvent('refund', items, purchase);
  }

  /*
   * Private Functions
   */
  // Normalize JSON objects from camelCase to snake_case
  private _normalizeEvent(action: string, items: ItemData[], additionalData?: any): void {
    const fieldObject: any = {
      ...this._camelToUnderscore(additionalData),
      items: items.map((i) => this._camelToUnderscore(i)),
    };
    this._event(action, fieldObject);
  }

  private _config(trackingId: string, fieldObject: any): void {
    // Anticipates fieldObject with properties in the case of snake_case
    if (typeof window.gtag === 'function') window.gtag('config', trackingId, fieldObject);
    if (this._debug)
      this.log(`\n\ncalled ga('config', '${trackingId}', fieldObject);\n\n${JSON.stringify(fieldObject)}`);
  }

  private _consent(action: 'default' | 'update', fieldObject: any): void {
    // Anticipates fieldObject with properties in the case of snake_case
    if (typeof window.gtag === 'function') window.gtag('consent', action, fieldObject);
    if (this._debug) this.log(`\n\ncalled ga('consent', '${action}', fieldObject);\n\n${JSON.stringify(fieldObject)}`);
  }

  private _event(action: string, fieldObject: any): void {
    // Anticipates fieldObject with properties in the case of snake_case
    if (typeof window.gtag === 'function') window.gtag('event', action, fieldObject);
    if (this._debug) this.log(`\n\ncalled ga('event', '${action}', fieldObject);\n\n${JSON.stringify(fieldObject)}`);
  }

  private _normalizeDimensions(dimensions?: { [name: string]: string }): any {
    const result: any = {};
    if (dimensions) {
      Object.keys(dimensions)
        .filter((key) => key.substring(0, 'dimension'.length) === 'dimension')
        .forEach((key) => {
          result[key] = dimensions[key];
        });
    }
    return result;
  }

  private _normalizeMetrics(metrics?: { [name: string]: number }): any {
    const result: any = {};
    if (metrics) {
      Object.keys(metrics)
        .filter((key) => key.substring(0, 'metrics'.length) === 'metrics')
        .forEach((key) => {
          result[key] = metrics[key];
        });
    }
    return result;
  }

  private _camelToUnderscore(input: any): any {
    const result: any = {};

    const toUnderScore = (key: string): string => {
      return key.replace(/([A-Z])/g, '_$1').toLowerCase();
    };

    if (input) {
      for (const key in input) {
        result[toUnderScore(key)] = input[key];
      }
    }
    return result;
  }

  private warn(message: string): void {
    console.warn('[cf-ga]', message);
  }

  private log(message: string): void {
    console.info('[cf-ga]', message);
  }
}
