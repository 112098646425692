import { Box, Icon, Input, InputAdornment, TextField, Theme } from '@mui/material/';
import { styled } from '@mui/material/styles';

import { SxProps } from '@mui/system';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getResponsiveTextFieldStyles = (theme: Theme) => ({
  [theme.breakpoints.up('xs')]: {
    ['& .MuiInputBase-root']: { height: '40px !important', backgroundColor: 'red' },
    ['& .MuiButtonBase-root']: { height: 40, width: 40 },
    ['& .MuiInputBase-input']: { ...theme.typography.mobileParagraph },
  },
  [theme.breakpoints.up('xl')]: {
    ['& .MuiInputBase-root']: { height: '32px !important' },
    ['& .MuiButtonBase-root']: { height: 32, width: 32 },
    ['& .MuiInputBase-input']: { ...theme.typography.paragraph },
  },
});

export const StyledResponsiveTextField = styled(TextField)<{ sx?: SxProps<Theme> }>(({ theme, sx }) => ({
  ['&']: {
    ...getResponsiveTextFieldStyles(theme),
    ...sx,
  },
}));

export const StyledInput = styled(Input)(({ theme }) => ({
  '& input[type="date"]::-webkit-calendar-picker-indicator': {
    display: 'none',
    webkitAppearance: 'none',
  },
  '&.MuiInput-root': {
    outline: `1px solid ${theme.palette.coolGrey[300]}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.common.white,
    '&.Mui-focused': {
      outline: `2px solid ${theme.palette.primary.main}`,
    },
    '&.Mui-error': {
      outline: `2px solid ${theme.palette.error.main}`,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.coolGrey[100],
      border: `1px solid ${theme.palette.coolGrey[100]}`,
    },
    '& > .MuiInput-input': {
      paddingTop: 0,
      paddingBottom: 0,
      WebKitTextFillColor: 'currentcolor', // iOS/Safari issue fix
      opacity: 1,
    },
  },
}));

export const StyledInputAdornment = styled(InputAdornment)(({}) => ({
  textAlign: 'left',
  marginLeft: -8,
}));

export const StyledIcon = styled(Icon)(({ theme }) => ({
  fontSize: theme.typography.iconSmall.fontSize,
  lineHeight: theme.typography.iconSmall.fontSize,
}));

export const StyledLabelTypography = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    ...theme.typography.mobileParagraphSmall,
  },
  [theme.breakpoints.up('xl')]: {
    ...theme.typography.paragraphSmall,
  },
  color: theme.palette.grey[700],
}));

export const StyledLabelBox = styled(Box)(({ theme }) => ({
  textAlign: 'left',
  paddingBottom: theme.spacing(0.5),
  paddingTop: theme.spacing(0.5),
}));
