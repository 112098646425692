import { PaletteOptions } from '@mui/material/styles';

const paletteBase: PaletteOptions = {
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  grey: {
    50: '#E5E5E5',
    100: '#F4F4F4',
    200: '#E0E0E0',
    300: '#C6C6C6',
    400: '#A8A8A8',
    500: '#8D8D8D',
    600: '#6F6F6F',
    700: '#525252',
    800: '#393939',
    900: '#262626',
  },
  coolGrey: {
    100: '#F2F4F8',
    200: '#DDE1E6',
    300: '#C1C7CD',
    400: '#A2A9B0',
    500: '#878D96',
    600: '#4d5358',
    700: '#4D5358',
    800: '#343A3F',
    900: '#21272A',
  },
  secondary: {
    main: '#DDE1E6',
    light: '#58B7ED',
    dark: '#C1C7CD',
  },
  tertiary: {
    main: '#FFFFFF',
    dark: '#F2F4F8',
  },
  action: {
    disabled: '#C1C7CD',
    // focus: '#58B7ED',
    //focus: '#A2B2CA',
    focus: '#000000',
  },
  text: {
    primary: '#000000',
    secondary: '#FFFFFF',
  },
  success: {
    main: '#00C853',
    dark: '#1B8031',
  },
  error: {
    main: '#B5121B',
  },
  warning: {
    main: '#E27800',
    contrastText: '#C8510E',
  },
  info: {
    main: '#000000',
  },
  mode: 'light',
  background: {
    default: '#F4F4F4',
  },
  userAcceptanceBackground:
    'https://customerfirstimagedev.blob.core.windows.net/site/signin_generic_desktop.png?sp=r&st=2022-10-14T13:06:31Z&se=2070-10-14T21:06:31Z&spr=https&sv=2021-06-08&sr=b&sig=sYL3LHAbPhadNGjcMVYk7T8Fr58Yli6mSmiFc1phUF4%3D',
};

export function getPalettePFS(): PaletteOptions {
  return {
    ...paletteBase,
    primary: {
      main: '#60718A',
      light: '#58B7ED',
      dark: '#054569',
    },
    userAcceptanceBackground:
      'https://customerfirstimagedev.blob.core.windows.net/site/signin_pfs_desktop.png?sp=r&st=2022-10-14T13:06:49Z&se=2070-10-14T21:06:49Z&spr=https&sv=2021-06-08&sr=b&sig=r6dcPRiM%2F1UH%2F6FldiX0NFKJ1ZF%2BzmfiXapgjng2M34%3D',
  };
}

export function getPaletteVistar(): PaletteOptions {
  return {
    ...paletteBase,
    primary: {
      main: '#004934',
      light: '#1B8031',
      dark: '#00220E',
    },
    userAcceptanceBackground:
      'https://customerfirstimagedev.blob.core.windows.net/site/signin_vistar_desktop.png?sp=r&st=2022-10-14T13:07:02Z&se=2070-10-14T21:07:02Z&spr=https&sv=2021-06-08&sr=b&sig=Rpa4BIrFUeaz%2F3yTKrTz3DrHB3YId4ijV%2BObHzP%2F0fk%3D',
  };
}
