import { SharedProperties } from '../../helpers/general/types';
import { ProductData } from '../../models';
import { BusinessUnitKeyType, DeliveryDetail, OrderConfirmationProduct, SimilarCatalogProduct } from '../models';
import { CatalogProduct, UnitOfMeasureOrderQuantity } from '../models/api-shared.models';
import { OrderEntryProduct } from '../models/order-entry-search.models';
import { OrderCartProductUnitOfMeasure, OrderEntryDetail } from '../models/order.models';
import { SubmittedOrderDetail } from '../models/submitted-order.models';

export const toOrderEntryProductFromCatalogProduct = (catalogProduct: CatalogProduct): OrderEntryProduct => {
  const orderEntryProduct: OrderEntryProduct & SharedProperties<OrderEntryProduct, CatalogProduct> = {
    ...catalogProduct,
    ProductLastPurchaseUnitOfMeasure: catalogProduct.ProductLastPurchaseUnitOfMeasure,
    OrderEntryDetailChangeDate: undefined,
  };
  return orderEntryProduct;
};

export const toCatalogProductFromOrderEntryProduct = (orderEntryProduct: OrderEntryProduct): CatalogProduct => {
  const catalogProduct: CatalogProduct & SharedProperties<CatalogProduct, OrderEntryProduct> = {
    ...orderEntryProduct,
    ProductIsCatchWeight: orderEntryProduct.ProductIsCatchWeight || false,
    ProductAverageWeight: orderEntryProduct.ProductAverageWeight || 0,
    OrderEntryAdditionalDetailMessage: [],
    IsCriticalItem: false,
    IsOnManagedList: false,
  };
  return catalogProduct;
};

export const toOrderEntryProductsFromCatalogProducts = (catalogProducts: CatalogProduct[]): OrderEntryProduct[] => {
  return catalogProducts.map((cp: CatalogProduct) => toOrderEntryProductFromCatalogProduct(cp));
};

export const toOrderEntryProductsFromSimilarCatalogProducts = (
  catalogProducts: SimilarCatalogProduct[]
): OrderEntryProduct[] => {
  return catalogProducts.map((cp: SimilarCatalogProduct) => toOrderEntryProductFromCatalogProduct(cp.Product));
};

// This mapper is NOT a 1:1 and does make assumptions as denoted beside the properties.
export const toOrderEntryProductFromOrderDetail = (detail: OrderEntryDetail): OrderEntryProduct => {
  return {
    ...detail,
    UnitOfMeasureOrderQuantities: [
      {
        UnitOfMeasure: detail.UnitOfMeasureType,
        Quantity: detail.Quantity,
        Price: detail.Price,
        ExtendedPrice: detail.ExtendedPrice,
        ProductIsCatchWeight: detail.ProductIsCatchWeight,
        ProductAverageWeight: detail.ProductAverageWeight,
        ProductNumberDisplay: detail.ProductNumber,
        ShowOrderQuantityAlert: false,
        UOMOrderQuantityAlertThresholdMin: 0,
        UOMOrderQuantityAlertThresholdMax: 0,
        CanEditDetail: false,
        CanOrderUom: false,
      },
    ],
    IsDiscontinued: false,
    IsOutOfStock: false,
    IsExclusiveProduct: false,
    IsSpecialOrder: false,
    ProductImageUrls: [],
    ReplacementProduct: null,
    ProductPackSizes: [detail.ProductPackSize],
    CanEditProductDescription: false,
    CanEditProductNumber: false,
    CanOrder: true,
    HasMSDS: false,
    IsDirectDropShipItem: false,
    IsVirtualWarehouseItem: false,
    IsTemporarilyUnavailable: false,
    HasSubstituteProducts: false,
  };
};

export const toCatalogProductFromDeliveryDetailProduct = (deliveryDetail: DeliveryDetail): CatalogProduct => {
  return {
    ...deliveryDetail,
    DisplayProductDescription: deliveryDetail.ProductDescription,
    ProductKey: deliveryDetail.ProductKey,
    ProductDescription: deliveryDetail.ProductDescription,
    ProductBrand: deliveryDetail.ProductBrand,
    ProductImageUrlThumbnail: deliveryDetail.ProductImageURLThumbnail,
    IsCriticalItem: deliveryDetail.IsCriticalItem,
    IsOutOfStock: deliveryDetail.IsOutOfStock,
    IsDirectDropShipItem: deliveryDetail.IsDirectDropShipItem,
    IsVirtualWarehouseItem: deliveryDetail.IsVirtualWarehouseItem,
    IsTemporarilyUnavailable: deliveryDetail.IsTemporarilyUnavailable,
    IsExclusiveProduct: deliveryDetail.IsExclusiveProduct,

    BusinessUnitERPKey: deliveryDetail.DeliveryDetailBusinessUnitERPKey,
    UnitOfMeasureOrderQuantities: deliveryDetail.DeliveryDetailUnitOfMeasures.map((uom) => {
      return {
        UnitOfMeasure: uom.ProductUnitOfMeasureType,
        Quantity: uom.QuantityOrdered,
        Price: uom.UnitPrice,
        ExtendedPrice: uom.UnitPrice * uom.QuantityOrdered,
        ProductIsCatchWeight: uom.IsCatchWeight,
        ProductAverageWeight: 0,
        PackSize: uom.ProductPackSize,
        ProductNumberDisplay: uom.ProductNumber,
        ShowOrderQuantityAlert: false,
        UOMOrderQuantityAlertThresholdMin: 0,
        UOMOrderQuantityAlertThresholdMax: 0,
        CanEditDetail: false,
        CanOrderUom: false,
      };
    }),
    ShipLaterMaxEstimatedDays: 0,
    ProductIsCatchWeight: false,
    ProductAverageWeight: 0,
    OrderEntryAdditionalDetailMessage: [],
    IsDiscontinued: false,
    IsSpecialOrder: deliveryDetail.IsSpecialOrderItem,
    IsOnManagedList: false,
    ProductPackSizes: deliveryDetail.DeliveryDetailUnitOfMeasures.map((uom) => uom.ProductPackSize),
    ProductImageUrls: [deliveryDetail.ProductImageURLThumbnail],
    ReplacementProduct: null,
    CanEditProductDescription: false,
    CanEditProductNumber: false,
    HasMSDS: false,
    HasSubstituteProducts: false,
    CanOrder: true,
  };
};

export const toCatalogProductFromSubmittedOrderDetail = (d: SubmittedOrderDetail): CatalogProduct => {
  return toProductDataFromSubmittedOrderDetail(d) as CatalogProduct;
};

export const toProductDataFromSubmittedOrderDetail = (d: SubmittedOrderDetail): ProductData => {
  const unitOfMeasures: UnitOfMeasureOrderQuantity[] =
    d.UnitOfMeasures?.map((uom) => {
      const oo: UnitOfMeasureOrderQuantity = {
        UnitOfMeasure: uom.UnitOfMeasureType,
        Quantity: uom.Quantity,
        ProductIsCatchWeight: uom.IsCatchWeight,
        Price: uom.Price,
        ProductNumberDisplay: uom.ProductNumber,
        PackSize: uom.PackSize,
        ProductLastPurchaseAmount: uom.LastPurchaseQuantity,
        ProductLastPurchaseDate: uom.LastPurchaseDate,
        ExtendedPrice: uom.Price * uom.Quantity,

        ProductAverageWeight: 0,
        ShowOrderQuantityAlert: false,
        UOMOrderQuantityAlertThresholdMin: 0,
        UOMOrderQuantityAlertThresholdMax: 0,
        CanEditDetail: false,
        CanOrderUom: false,
      };
      return oo;
    }) ?? [];

  const lastPurchase = unitOfMeasures.find((u) => u.ProductLastPurchaseAmount && u.ProductLastPurchaseDate);
  const data: ProductData = {
    ProductKey: d.ProductKey,
    ProductNumber: d.UnitOfMeasures?.find((u) => u.ProductNumber)?.ProductNumber,
    ProductBrand: d.Brand,
    ProductDescription: d.Description,
    ProductImageUrlThumbnail: d.ProductImageUrlThumbnail,
    HasMSDS: d.HasMSDS,
    HasSubstituteProducts: d.HasSubstituteProducts,
    IsDirectDropShipItem: d.IsDirectDropShipItem,
    IsVirtualWarehouseItem: d.IsVirtualWarehouseItem,
    IsTemporarilyUnavailable: d.IsTemporarilyUnavailable,
    BusinessUnitERPKey: d.BusinessUnitERPKey,
    BusinessUnitKey: d.BusinessUnitKey ?? BusinessUnitKeyType.PerformanceFoodService,
    DisplayProductDescription: d.Description,
    UnitOfMeasureOrderQuantities: unitOfMeasures,
    ProductLastPurchaseUnitOfMeasure: lastPurchase?.UnitOfMeasure,
    ProductLastPurchaseAmount: lastPurchase?.ProductLastPurchaseAmount,
    ProductLastPurchaseDate: lastPurchase?.ProductLastPurchaseDate,
    ProductIsCatchWeight: unitOfMeasures.some((u) => u.ProductIsCatchWeight),
    ProductPackSizes: d.UnitOfMeasures?.map((uom) => uom.PackSize ?? '') ?? [],
    ProductImageUrls: d.ProductImageUrlThumbnail ? [d.ProductImageUrlThumbnail] : [],
    CanOrder: true,
    CanEditProductNumber: false,
    CanEditProductDescription: false,
    OrderEntryAdditionalDetailMessage: [],
    ProductAverageWeight: 0,
    ShipLaterMaxEstimatedDays: 0,
    IsDiscontinued: false,
    IsSpecialOrder: false,
    ReplacementProduct: null,
    IsOnManagedList: false,

    IsOutOfStock: false, //
    IsCriticalItem: false, //
    IsExclusiveProduct: false, //
  };

  return data;
};

export const toCatalogProductFromConfirmationOrderProduct = (d: OrderConfirmationProduct): CatalogProduct => {
  return toProductDataFromConfirmationOrderProduct(d) as CatalogProduct;
};

export const toProductDataFromConfirmationOrderProduct = (d: OrderConfirmationProduct): ProductData => {
  const unitOfMeasures: UnitOfMeasureOrderQuantity[] =
    d.UnitOfMeasures?.map((uom) => {
      const oo: UnitOfMeasureOrderQuantity = {
        UnitOfMeasure: uom.UnitOfMeasure,
        Quantity: uom.QuantitySubmitted,
        QuantityAccepted: uom.QuantityAccepted,
        ProductIsCatchWeight: uom.ProductIsCatchWeight,
        Price: uom.Price,
        ProductNumberDisplay: uom.ProductNumberDisplay,
        PackSize: uom.PackSize,
        ExtendedPrice: uom.ExtendedPrice,
        ProductAverageWeight: 0,
        ShowOrderQuantityAlert: false,
        UOMOrderQuantityAlertThresholdMin: 0,
        UOMOrderQuantityAlertThresholdMax: 0,
        CanEditDetail: false,
        EditOriginalQuantity: undefined,
        CanOrderUom: false,
      };
      return oo;
    }) ?? [];

  const data: ProductData = {
    ProductKey: d.ProductKey,
    ProductNumber: d.UnitOfMeasures?.find((u) => u.ProductNumberDisplay)?.ProductNumberDisplay,
    ProductBrand: d.ProductBrand,
    ProductDescription: d.DisplayProductDescription,
    ProductImageUrlThumbnail: d.ProductImageUrlThumbnail,
    HasMSDS: d.HasMSDS,
    HasSubstituteProducts: d.HasSubstituteProducts,
    IsDirectDropShipItem: d.IsDropShip,
    IsVirtualWarehouseItem: d.IsVirtualWarehouse,
    IsTemporarilyUnavailable: d.IsTemporarilyUnavaliable,
    BusinessUnitERPKey: d.BusinessUnitERPKey,
    DisplayProductDescription: d.DisplayProductDescription,
    UnitOfMeasureOrderQuantities: unitOfMeasures,
    ProductIsCatchWeight: unitOfMeasures.some((u) => u.ProductIsCatchWeight),
    ProductPackSizes: d.UnitOfMeasures?.map((uom) => uom.PackSize ?? '') ?? [],
    ProductImageUrls: [d.ProductImageUrlThumbnail ?? ''],
    CanOrder: d.CanOrder,
    IsSpecialOrder: d.IsSpecialOrder,

    BusinessUnitKey: BusinessUnitKeyType.PerformanceFoodService,

    CanEditProductNumber: false,
    CanEditProductDescription: false,
    OrderEntryAdditionalDetailMessage: [],
    ProductAverageWeight: 0,
    ShipLaterMaxEstimatedDays: d.ShipLaterMaxEstimatedDays,
    IsDiscontinued: false,
    ReplacementProduct: null,
    IsOnManagedList: false,
    IsOutOfStock: false, //
    IsCriticalItem: false, //
    IsExclusiveProduct: false, //

    PriceLoaded: true,

    InformationMessages: d.InformationMessages,
    ErrorMessages: d.ErrorMessages,
  };

  return data;
};

export const orderCartProductUnitOfMeasureToUnitOfMeasureQuantity = (
  uom: OrderCartProductUnitOfMeasure
): UnitOfMeasureOrderQuantity => {
  return {
    UnitOfMeasure: uom.UnitOfMeasureType,
    Quantity: uom.Quantity,
    Price: uom.Price,
    ExtendedPrice: uom.ExtendedPrice,
    ProductIsCatchWeight: uom.IsCatchWeight,
    ProductNumberDisplay: uom.ProductNumber,
  } as UnitOfMeasureOrderQuantity;
};
