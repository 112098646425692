import { ServiceResult } from '../models/api-shared.models';
import { EditUserSettingRequest, UserSetting, UserSettingPreference } from '../models/user-setting.models';
import ApiService from './api-service';

class UserSettingService extends ApiService {
  private static classInstance: UserSettingService;

  private constructor() {
    super('UserSetting');
  }

  public getCurrentUserSetting(): ServiceResult<UserSettingPreference> {
    return this.get(`${this.baseUrl}/GetCurrentUserSetting`);
  }

  public editUserSetting(request: EditUserSettingRequest): ServiceResult<UserSetting> {
    return this.post(`${this.baseUrl}/EditUserSetting`, request);
  }

  public static getInstance(): UserSettingService {
    if (!this.classInstance) {
      this.classInstance = new UserSettingService();
    }
    return this.classInstance;
  }
}

export default UserSettingService;
