import { useLayoutEffect, useState } from 'react';

type ReturnType = [boolean, (locked: boolean) => void];

export const useLockedBody = (): ReturnType => {
  const [locked, setLocked] = useState(false);

  // Clean up overflow when hook unmounts
  useLayoutEffect(() => {
    return () => {
      document.body.classList.toggle('cf-scroll-lock', false);
    };
  }, []);

  // Handle updates
  useLayoutEffect(() => {
    document.body.classList.toggle('cf-scroll-lock', locked);
  }, [locked]);

  return [locked, setLocked];
};
