import { convertServerUTCDateStrToLocalDate, convertToMilliseconds, RootState, useAppSelector } from 'common';
import { OrderEntryType } from 'common/src/models/order.enums';
import { CfCountdown } from '../../../../../../../../../../cf-ui/Timers/Countdown';

export const CartCountdown = (): JSX.Element => {
  const cart = useAppSelector((s: RootState) => s.orders.orderCart);
  const expirationDate = convertServerUTCDateStrToLocalDate(cart?.ExpirationDate);

  return (
    <>
      {cart && cart?.OrderType === OrderEntryType.Edit && expirationDate && (
        <CfCountdown
          date={expirationDate}
          minUnit='second'
          maxUnit='minute'
          padZeros
          suffix=' remaining to submit updates'
          sx={{
            mb: 1,
            typography: 'responsiveParagraphBold',
            color: (theme) => theme.palette.common.black,
          }}
          thresholds={[
            {
              milliseconds: convertToMilliseconds(1, 'minute'),
              sx: { color: (theme) => theme.palette.error.main },
            },
            {
              milliseconds: convertToMilliseconds(5, 'minute'),
              sx: { color: (theme) => theme.palette.warning.contrastText },
            },
          ]}
        />
      )}
    </>
  );
};
