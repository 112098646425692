import { Box, Divider, Grid } from '@mui/material';
import {
  deleteOrderEntryDetail,
  getOrderCart,
  OrderCartProduct,
  OrderCartProductUnitOfMeasure,
  orderCartProductUnitOfMeasureToUnitOfMeasureQuantity,
  UnitOfMeasureType,
  useAppDispatch,
} from 'common';
import { useState } from 'react';
import { CfSimpleDialog } from '../../../../../../../../../../cf-ui/dialogs/SimpleDialog/CfSimpleDialog';
import { useGa } from '../../../../../../../../../../hooks/useGa';
import { ProductHeader } from './components/ProductHeader';
import { ProductLineItems } from './components/ProductLineItems';

interface ItemToDelete {
  productKey: string;
  uomType: UnitOfMeasureType;
  product: OrderCartProduct;
  uom: OrderCartProductUnitOfMeasure;
  index: number;
}

export type CartProductsProps =
  | {
      items: OrderCartProduct[];
      onLastButtonKeyDown?: React.KeyboardEventHandler<HTMLElement>;
      onClickDelete?: () => void;
      loadingPlaceholder?: never;
    }
  | { loadingPlaceholder: true };

const CartProducts = (props: CartProductsProps): JSX.Element => {
  if (props.loadingPlaceholder) {
    return (
      <Grid container sx={{ mb: 3 }}>
        <Grid item sx={{ my: 1, width: '100%' }}>
          <ProductHeader loadingPlaceholder />
          <ProductLineItems loadingPlaceholder />
        </Grid>
        <Grid item sx={{ my: 1, width: '100%' }}>
          <ProductHeader loadingPlaceholder />
          <ProductLineItems loadingPlaceholder />
        </Grid>
      </Grid>
    );
  }

  const { items, onClickDelete } = props;

  const dispatch = useAppDispatch();
  const analytics = useGa();
  const analyticsContext = { analytics, listId: 'active_Cart', listName: 'Active Cart' };

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<ItemToDelete | undefined>(undefined);

  const handleClickDelete = (product: OrderCartProduct, uom: OrderCartProductUnitOfMeasure, index: number) => {
    onClickDelete?.();
    setDialogOpen(true);
    setItemToDelete({
      productKey: product.ProductKey || '',
      uomType: uom.UnitOfMeasureType,
      product,
      uom,
      index,
    });
  };

  const handleClickDialogConfirm = () => {
    setDialogOpen(false);
    if (itemToDelete) {
      dispatch(
        deleteOrderEntryDetail(
          itemToDelete.productKey,
          itemToDelete.uomType,
          orderCartProductUnitOfMeasureToUnitOfMeasureQuantity(itemToDelete.uom),
          itemToDelete.index,
          { enabled: true, includeUOM: true },
          analyticsContext,
          () => dispatch(getOrderCart())
        )
      );
    }
    handleClickDialogDismiss();
  };

  const handleClickDialogDismiss = () => {
    setDialogOpen(false);
    setItemToDelete(undefined);
  };

  const getCartContent = () => {
    const comps: JSX.Element[] = [];
    if (items) {
      let index = 1;
      items.forEach((product, key, arr) => {
        comps.push(
          <Box key={key}>
            <Grid container sx={{ my: 1 }}>
              <ProductHeader title={product.ProductDescription || ''} />
              {product.UnitOfMeasures.map((uom, uomIdx) => (
                <ProductLineItems
                  key={`cart-item-${product.ProductKey}-uom-${uomIdx}`}
                  lineData={uom}
                  {...(key === items.length - 1 &&
                    uomIdx === product.UnitOfMeasures.length - 1 && {
                      onLastButtonKeyDown: props.onLastButtonKeyDown,
                    })}
                  onClickDelete={() => handleClickDelete(product, uom, key)}
                />
              ))}
            </Grid>
            {index < arr.length && <Divider />}
          </Box>
        );
        index++;
      });
    }
    return comps;
  };

  return (
    <div>
      {getCartContent()}
      <CfSimpleDialog
        isOpen={dialogOpen}
        title='Remove product'
        icon='alert'
        primaryButtonProps={{ children: 'Remove', onClick: handleClickDialogConfirm }}
        secondaryButtonProps={{ children: 'Cancel', onClick: handleClickDialogDismiss }}
        onClose={handleClickDialogDismiss}
      >
        Are you sure you want to remove this product?
      </CfSimpleDialog>
    </div>
  );
};

export default CartProducts;
