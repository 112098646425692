export enum IsOrderEntryPurchaseOrderNumberRequiredType {
  No,
  Yes,
  YesNumbersOnly,
  OptionalNumbersOnly,
}

export enum OrderViewType {
  AllOrders = 0,
  Unsubmitted = 1,
  Submitted = 2,
  PreparingOrder = 3,
  OutForDelivery = 4,
  Delivered = 5,
  ReadyForPickup = 6,
  Cancelled = 7,
  FutureOrder = 8,
}
