import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { GetDeliveriesRequest } from '../../api/models/delivery.models';
import {
  GetConfirmationOrderEntryHeaderByCustomersRequest,
  GetCustomersOrderEntryHeadersRequest,
  GetFutureOrderEntryHeadersRequest,
} from '../../api/models/order-entry-header.models';
import { OrderViewType } from '../../api/models/order.enums';
import { GetSubmittedOrderHeadersRequest } from '../../api/models/submitted-order.models';
import { getSortComparer, sortByDesc } from '../../helpers/general/array';
import {
  filterOrderBasicOrders,
  filterOrderDeliveryOrders,
  filterOrderSubmittedOrders,
} from '../../helpers/general/order';
import { normalizeKey } from '../../helpers/general/string';
import { OrderEntryType, OrderHistoryMode } from '../../models/order.enums';
import { Order, OrderBasic, OrderDelivery, OrderSubmitted } from '../../models/order.models';
import { RootState } from '../store';

// Adapters
const unsubmittedOrdersAdapter = createEntityAdapter<OrderBasic>({
  selectId: (unsubmittedOrder: OrderBasic) => unsubmittedOrder.OrderEntryHeaderId,
  sortComparer: getSortComparer((order) => order.DeliveryDate, true),
});

const deliveriesAdapter = createEntityAdapter<OrderDelivery>({
  selectId: (delivery: OrderDelivery) => delivery.DeliveryKey,
  sortComparer: getSortComparer((order) => order.DeliveryDate, true),
});

const confirmationsAdapter = createEntityAdapter<OrderBasic>({
  selectId: (confirmedOrder: OrderBasic) => confirmedOrder.OrderEntryHeaderId,
  sortComparer: getSortComparer((order) => order.DeliveryDate, true),
});

const submittedOrdersAdapter = createEntityAdapter<OrderSubmitted>({
  selectId: (submittedOrder: OrderSubmitted) => normalizeKey(submittedOrder.OrderKey),
  sortComparer: getSortComparer((order) => order.DeliveryDate, true),
});

const futureOrdersAdapter = createEntityAdapter<OrderBasic>({
  selectId: (futureOrder: OrderBasic) => futureOrder.OrderEntryHeaderId,
  sortComparer: getSortComparer((order) => order.DeliveryDate, true),
});

// State
export interface OrderState {
  // Unsubmitted
  unsubmittedOrdersLoading: boolean;
  unsubmittedOrdersRequest: GetCustomersOrderEntryHeadersRequest | undefined;
  unsubmittedOrders: EntityState<OrderBasic>;

  // Deliveries
  deliveriesLoading: boolean;
  deliveriesRequest: GetDeliveriesRequest | undefined;
  deliveries: EntityState<OrderDelivery>;

  // Confirmations
  confirmationsLoading: boolean;
  confirmationsRequest: GetConfirmationOrderEntryHeaderByCustomersRequest | undefined;
  confirmations: EntityState<OrderBasic>;

  // Submitted
  submittedOrdersLoading: boolean;
  submittedOrdersRequest: GetSubmittedOrderHeadersRequest | undefined;
  submittedOrders: EntityState<OrderSubmitted>;

  // Future order
  futureOrdersLoading: boolean;
  futureOrdersRequest: GetFutureOrderEntryHeadersRequest | undefined;
  futureOrders: EntityState<OrderBasic>;

  // Delete
  deletingUnsubmittedOrder: boolean;

  // Settings
  customerIds: string[];
  showAll: boolean;
}

const initialState: OrderState = {
  // Unsubmitted
  unsubmittedOrdersLoading: false,
  unsubmittedOrdersRequest: undefined,
  unsubmittedOrders: unsubmittedOrdersAdapter.getInitialState(),

  // Deliveries
  deliveriesLoading: false,
  deliveriesRequest: undefined,
  deliveries: deliveriesAdapter.getInitialState(),

  // Confirmations
  confirmationsLoading: false,
  confirmationsRequest: undefined,
  confirmations: confirmationsAdapter.getInitialState(),

  // Submitted
  submittedOrdersLoading: false,
  submittedOrdersRequest: undefined,
  submittedOrders: submittedOrdersAdapter.getInitialState(),

  // Future order
  futureOrdersLoading: false,
  futureOrdersRequest: undefined,
  futureOrders: futureOrdersAdapter.getInitialState(),

  // Delete
  deletingUnsubmittedOrder: false,

  // Settings
  customerIds: [],
  showAll: false,
};

// Reducers
export const orderSlice = createSlice({
  name: 'order',
  initialState: initialState,
  reducers: {
    resetOrderState: () => {
      return initialState;
    },
    // Unsubmitted
    setUnsubmittedOrdersLoading: (state: OrderState, action: PayloadAction<boolean>) => {
      state.unsubmittedOrdersLoading = action.payload;
    },
    setUnsubmittedOrders: (state: OrderState, action: PayloadAction<OrderBasic[]>) => {
      unsubmittedOrdersAdapter.setAll(state.unsubmittedOrders, action.payload);
    },
    setUnsubmittedOrdersRequest: (
      state: OrderState,
      action: PayloadAction<GetCustomersOrderEntryHeadersRequest | undefined>
    ) => {
      state.unsubmittedOrdersRequest = action.payload;
    },
    // Deliveries
    setDeliveriesRequest: (state: OrderState, action: PayloadAction<GetDeliveriesRequest | undefined>) => {
      state.deliveriesRequest = action.payload;
    },
    setDeliveriesLoading: (state: OrderState, action: PayloadAction<boolean>) => {
      state.deliveriesLoading = action.payload;
    },
    setDeliveries: (state: OrderState, action: PayloadAction<OrderDelivery[]>) => {
      deliveriesAdapter.setAll(state.deliveries, action.payload);
    },
    // Confirmations
    setConfirmationsRequest: (
      state: OrderState,
      action: PayloadAction<GetConfirmationOrderEntryHeaderByCustomersRequest | undefined>
    ) => {
      state.confirmationsRequest = action.payload;
    },
    setConfirmationsLoading: (state: OrderState, action: PayloadAction<boolean>) => {
      state.confirmationsLoading = action.payload;
    },
    setConfirmations: (state: OrderState, action: PayloadAction<OrderBasic[]>) => {
      confirmationsAdapter.setAll(state.confirmations, action.payload);
    },
    // Submitted
    setSubmittedOrdersRequest: (
      state: OrderState,
      action: PayloadAction<GetSubmittedOrderHeadersRequest | undefined>
    ) => {
      state.submittedOrdersRequest = action.payload;
    },
    setSubmittedOrdersLoading: (state: OrderState, action: PayloadAction<boolean>) => {
      state.submittedOrdersLoading = action.payload;
    },
    setSubmittedOrders: (state: OrderState, action: PayloadAction<OrderSubmitted[]>) => {
      submittedOrdersAdapter.setAll(state.submittedOrders, action.payload);
    },
    //Future orders
    setFutureOrderRequest: (
      state: OrderState,
      action: PayloadAction<GetFutureOrderEntryHeadersRequest | undefined>
    ) => {
      state.futureOrdersRequest = action.payload;
    },
    setFutureOrderLoading: (state: OrderState, action: PayloadAction<boolean>) => {
      state.futureOrdersLoading = action.payload;
    },
    setFutureOrder: (state: OrderState, action: PayloadAction<OrderBasic[]>) => {
      futureOrdersAdapter.setAll(state.futureOrders, action.payload);
    },
    // Delete
    setDeletingUnsubmittedOrder: (state: OrderState, action: PayloadAction<boolean>) => {
      state.deletingUnsubmittedOrder = action.payload;
    },
    deleteUnsubmittedOrder: (state: OrderState, action: PayloadAction<string>) => {
      unsubmittedOrdersAdapter.removeOne(state.unsubmittedOrders, action.payload);
    },
    // Settings
    setCustomerIds: (state: OrderState, action: PayloadAction<string[]>) => {
      state.customerIds = action.payload;
    },
    setShowAll: (state: OrderState, action: PayloadAction<boolean>) => {
      state.showAll = action.payload;
    },

    clearAllOrders: (state: OrderState) => {
      unsubmittedOrdersAdapter.removeAll(state.unsubmittedOrders);
      submittedOrdersAdapter.removeAll(state.submittedOrders);
      confirmationsAdapter.removeAll(state.confirmations);
      deliveriesAdapter.removeAll(state.deliveries);

      state.unsubmittedOrdersRequest = undefined;
      state.submittedOrdersRequest = undefined;
      state.confirmationsRequest = undefined;
      state.deliveriesRequest = undefined;
    },
  },
});

// Adapter Selectors
const { selectAll: selectAllUnsubmittedOrders, selectById: selectUnsubmittedOrderById } =
  unsubmittedOrdersAdapter.getSelectors<OrderState>((state: OrderState) => state.unsubmittedOrders);
const { selectAll: selectAllDeliveries, selectById: selectDeliveryById } = deliveriesAdapter.getSelectors<OrderState>(
  (state: OrderState) => state.deliveries
);
const { selectAll: selectAllConfirmations, selectById: selectConfirmationById } =
  confirmationsAdapter.getSelectors<OrderState>((state: OrderState) => state.confirmations);
const { selectAll: selectAllSubmittedOrders, selectById: selectSubmittedOrderById } =
  submittedOrdersAdapter.getSelectors<OrderState>((state: OrderState) => state.submittedOrders);

const { selectAll: selectAllFutureOrders, selectById: selectFutureOrdersById } =
  futureOrdersAdapter.getSelectors<OrderState>((state: OrderState) => state.futureOrders);

// Custom Selectors
const selectFilteredOrders = (orders: Order[], view: OrderViewType): Order[] =>
  orders.filter(
    (o) =>
      view === OrderViewType.AllOrders ||
      o.View === view ||
      (view === OrderViewType.Submitted && o.View === OrderViewType.FutureOrder)
  );

export const selectHomeOrders = createSelector(
  [
    (s: RootState) => s.order,
    (_s: RootState, view: OrderViewType) => view,
    (s: RootState) => s.orders.activeOrder?.OrderEntryHeaderId,
  ],
  (state: OrderState, view: OrderViewType, activeOrderId: string | null | undefined) => {
    // No confirmations
    const unsubmitted: OrderBasic[] = selectAllUnsubmittedOrders(state);
    const deliveries: OrderDelivery[] = selectAllDeliveries(state);
    const submitted: OrderSubmitted[] = selectAllSubmittedOrders(state);
    const futureOrder: OrderBasic[] = selectAllFutureOrders(state);

    unsubmitted.sort(
      getSortComparer((o) =>
        o.OrderEntryHeaderId === activeOrderId ? new Date('0001-01-01') : new Date(o.CutoffDateTime)
      )
    );

    const otherOrders: Order[] = [...deliveries, ...submitted, ...futureOrder];
    otherOrders.sort(getSortComparer((o) => new Date(o.DeliveryDate), true));

    const orders: Order[] = [...unsubmitted, ...otherOrders];
    return selectFilteredOrders(orders, view);
  }
);

export const selectOrderHistoryOrders = createSelector(
  [
    (s: RootState) => s.order,
    (_s: RootState, view: OrderViewType) => view,
    (_s: RootState, _view: OrderViewType, filterText?: string) => filterText,
    (_s: RootState, _view: OrderViewType, _filterText?: string, orderMode?: OrderHistoryMode) => orderMode,
  ],
  (state: OrderState, view: OrderViewType, filterText?: string, orderMode?: OrderHistoryMode) => {
    // No unsubmitted orders
    const orders: Order[] = [];
    let deliveries = selectAllDeliveries(state);
    let confirmations = selectAllConfirmations(state);
    let submitted = selectAllSubmittedOrders(state);
    let futureOrder = selectAllFutureOrders(state);

    if (filterText) {
      deliveries = filterOrderDeliveryOrders(selectAllDeliveries(state), filterText);
      confirmations = filterOrderBasicOrders(selectAllConfirmations(state), filterText, true);
      submitted = filterOrderSubmittedOrders(selectAllSubmittedOrders(state), filterText);
      futureOrder = filterOrderBasicOrders(selectAllFutureOrders(state), filterText, true);
    }

    const filteredConfirmations = confirmations.filter((confirmation) => confirmation.View !== undefined);
    const filteredDeliveries = deliveries.filter((delivery) => delivery.View !== undefined);
    const filteredSubmitted = submitted.filter((submission) => submission.View !== undefined);
    const filteredFutureOrder = futureOrder.filter((futureOrder) => futureOrder.View !== undefined);

    orders.push(...filteredDeliveries);
    orders.push(...filteredConfirmations);
    orders.push(...filteredSubmitted);
    if (orderMode != OrderHistoryMode.RepeatOrder) {
      orders.push(...filteredFutureOrder);
    }

    return sortByDesc(selectFilteredOrders(orders, view), (o) => new Date(o.DeliveryDate).getTime());
  }
);

export const selectOrderById = (state: OrderState, id: string): Order | undefined => {
  return (
    selectUnsubmittedOrderById(state, id) ??
    selectDeliveryById(state, id) ??
    selectConfirmationById(state, id) ??
    selectSubmittedOrderById(state, id)
  );
};

export const selectAreAnyOrdersLoading = createSelector(
  (s: RootState) => s.order.confirmationsLoading,
  (s: RootState) => s.order.submittedOrdersLoading,
  (s: RootState) => s.order.unsubmittedOrdersLoading,
  (s: RootState) => s.order.deliveriesLoading,
  (...loadingBooleans: boolean[]) => loadingBooleans.find((val) => val) === true
);

export const selectAreAnyHomeOrdersLoading = createSelector(
  (s: RootState) => s.order.submittedOrdersLoading,
  (s: RootState) => s.order.unsubmittedOrdersLoading,
  (s: RootState) => s.order.deliveriesLoading,
  (...loadingBooleans: boolean[]) => loadingBooleans.find((val) => val) === true
);

export const selectOrderGridCustomerIds = createSelector(
  (s: RootState) => s.customer.selectedCustomer?.CustomerId,
  (s: RootState) => s.order.customerIds,
  (selectedCustomerId: string | undefined, customerIds: string[]) =>
    customerIds.length === 0 && selectedCustomerId ? [selectedCustomerId] : customerIds
);

export const selectIsEditingOrder = createSelector(
  (s: RootState) => s.orders.orderCart?.OrderType,
  (s: RootState) => s.orders.orderCart?.OrderEntryHeaderId,
  (s: RootState) => s.orders.activeOrder?.OrderEntryHeaderId,
  (cartOrderType, cartOrderEntryHeaderId, activeOrderId) => {
    return !!(activeOrderId && cartOrderEntryHeaderId && cartOrderType === OrderEntryType.Edit);
  }
);
