import { Skeleton, Theme, Typography, TypographyPropsVariantOverrides } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { SxProps } from '@mui/system';
import { OverridableStringUnion } from '@mui/types';

interface TypographySkeletonProps {
  sx?: SxProps<Theme>;
  charCount: number;
  variant?: OverridableStringUnion<Variant, TypographyPropsVariantOverrides>;
}

const TypographySkeleton = (props: TypographySkeletonProps): JSX.Element => {
  return (
    <Typography sx={{ width: `${props.charCount}ch`, ...props.sx }} variant={props.variant ?? 'paragraph'}>
      <Skeleton />
    </Typography>
  );
};

export default TypographySkeleton;
