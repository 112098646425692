import { Button, Typography } from '@mui/material';
import { FC, useEffect, useRef } from 'react';
import { CfNavMenu } from '../../../../../../../../cf-ui/Menu/CfNavMenu';
import { CfMenuItem } from '../../../../../../../../cf-ui/Menu/MenuItem/CfMenuItem';
import { Menus, setMenu, useHeaderMenus } from '../../../../hooks/useHeaderMenus';
import { NavButtonOptions, NavMenuConfig, NavigationOptionVariant } from '../../NavigationProps';
import { StyledNavigationLink } from '../NavigationBar/NavigationBar.styles';

export interface NavigationMenuProps {
  config: NavMenuConfig;
  onClickOption: ({ navigationAction, navigationRoute }: NavButtonOptions) => void;
}

export const NavigationMenu: FC<NavigationMenuProps> = ({ config, onClickOption }: NavigationMenuProps) => {
  const anchorRef = useRef<HTMLButtonElement>();

  const { openedMenu } = useHeaderMenus();
  const isMenu = config.variant === NavigationOptionVariant.Menu;
  const isOpen = isMenu && openedMenu === config.menuHookValue;

  useEffect(() => {
    anchorRef.current?.focus();
  }, [isOpen]);

  const toggleMenu = () => {
    if (isMenu && config.menuHookValue) {
      if (isOpen) setMenu(Menus.None);
      else setMenu(config.menuHookValue);
    } else config.navigationAction?.();
  };

  const handleAnchorClick: React.MouseEventHandler<HTMLElement> = () => toggleMenu();

  const handleAnchorKeyDown: React.KeyboardEventHandler<HTMLElement> = (event) => {
    if (event.code.toLowerCase() === 'tab') {
      if (event.shiftKey && isOpen) {
        toggleMenu();
      } else if (isOpen) {
        event.preventDefault();
        firstItemRef.current?.focus();
      }
    }
  };

  const firstItemRef = useRef<HTMLDivElement | null>(null);
  const lastItemRef = useRef<HTMLDivElement | null>(null);
  const firstInteractiveItemIndex: number = config.menuItems?.findIndex((i) => !i.isDisabled) ?? -1;
  const lastInteractiveItemIndex: number = config.menuItems
    ? config.menuItems.length - 1 - [...config.menuItems].reverse().findIndex((i) => !i.isDisabled)
    : -1;

  const menuItems = config.menuItems?.map?.((option: NavButtonOptions, index: number) => (
    <CfMenuItem
      key={index}
      isDisabled={option.isDisabled}
      {...(firstInteractiveItemIndex === index && { rootRef: firstItemRef })}
      {...(lastInteractiveItemIndex === index && { rootRef: lastItemRef })}
      onSelect={() => onClickOption(option)}
      dataTestId={`navigation-menu-item-${option.testId}`}
    >
      {option.isDisabled ? (
        <Typography
          variant={option.childOption ? 'paragraph' : 'paragraphBold'}
          sx={{ color: (theme) => theme.palette.coolGrey[300], whiteSpace: 'nowrap' }}
        >
          {option.label}
        </Typography>
      ) : (
        <StyledNavigationLink
          sx={{ ...(option.childOption && { pl: 2 }) }}
          variant={option.childOption ? 'paragraph' : 'paragraphBold'}
          data-testid={option.testId}
        >
          {option.label}
        </StyledNavigationLink>
      )}
    </CfMenuItem>
  ));

  const anchorComponent = (
    <Button
      variant='text'
      disableRipple
      disableElevation
      size='extraLarge'
      color='primary'
      disabled={config.isDisabled}
      sx={{
        p: 0,
        '&.MuiButton-text:focus': { color: (theme) => theme.palette.primary.dark },
      }}
      onClick={(e) => {
        e.stopPropagation();
        handleAnchorClick(e);
        e.currentTarget.blur();
      }}
      onKeyDown={handleAnchorKeyDown}
      ref={(_ref) => _ref && (anchorRef.current = _ref)}
    >
      <Typography variant='label' data-testid={`nav-item-${config.label}`}>
        {config.label}
      </Typography>
    </Button>
  );

  if (!isMenu) return anchorComponent;
  return (
    <CfNavMenu
      anchorElement={anchorRef.current}
      isOpen={isOpen}
      onClose={() => setMenu(Menus.None)}
      minWidth={155}
      maxWidth={178}
      placement='bottom-start'
      anchorComponent={anchorComponent}
      closeOnBlur
    >
      {menuItems}
    </CfNavMenu>
  );
};
