import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Library has issues with lazy-loading. Hence added it to app styles until solved.

export const StyledPageContainer = styled(Grid, { shouldForwardProp: (prop) => prop !== '$userAcceptanceFlow' })<{
  $userAcceptanceFlow?: boolean;
}>(({ theme, $userAcceptanceFlow }) => ({
  width: '100vw',
  maxWidth: '100%',
  paddingLeft: 'calc(100vw - 100%)',
  ...($userAcceptanceFlow && {
    background: `url(${theme.palette.userAcceptanceBackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  }),
}));

export const StyledPageContent = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    width: 'calc(100% - 24px)',
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: theme.custom.pageWidths['xl'],
    width: theme.custom.contentWidths['xl'],
  },
}));
