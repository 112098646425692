import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { InvoiceDetail, InvoiceDetailUnitOfMeasure, InvoiceHeader } from '../../api/models/invoice.models';
import { filterObjects, UOMLookup } from '../../helpers';
import { AppRoute, GridSettings, InvoiceDetailsSelectOption } from '../../models';
import { RootState } from '../store';

// Adapters
const invoiceDetailsAdapater = createEntityAdapter<InvoiceDetail>({
  selectId: (detail: InvoiceDetail) => detail.InvoiceDetailLineNumber.toString(),
});

// State
interface InvoiceDetailsState {
  invoiceDetails: EntityState<InvoiceDetail>;
  invoiceDetailsHeader: InvoiceHeader | undefined;
  invoiceDetailsLoading: boolean;
  invoiceDetailsGridSettings: GridSettings;
  routes: AppRoute[];
}

const initialState: InvoiceDetailsState = {
  invoiceDetails: invoiceDetailsAdapater.getInitialState(),
  invoiceDetailsHeader: undefined,
  invoiceDetailsLoading: false,
  invoiceDetailsGridSettings: {
    PageIndex: 0,
    PageSize: 25,
    FilterText: '',
  },
  routes: [],
};

// Reducers
export const invoiceDetailsSlice = createSlice({
  name: 'invoice-details',
  initialState: initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    setInvoiceDetails: (state: InvoiceDetailsState, action: PayloadAction<InvoiceDetail[]>) => {
      invoiceDetailsAdapater.setAll(state.invoiceDetails, action.payload);
    },
    setInvoiceDetailsHeader: (state: InvoiceDetailsState, action: PayloadAction<InvoiceHeader>) => {
      state.invoiceDetailsHeader = action.payload;
    },
    setInvoiceDetailsLoading: (state: InvoiceDetailsState, action: PayloadAction<boolean>) => {
      state.invoiceDetailsLoading = action.payload;
    },
    setInvoiceDetailsGridSettings: (state: InvoiceDetailsState, action: PayloadAction<GridSettings>) => {
      state.invoiceDetailsGridSettings = action.payload;
    },
    resetInvoiceDetailsGridSettings: (state: InvoiceDetailsState) => {
      state.invoiceDetailsGridSettings = initialState.invoiceDetailsGridSettings;
    },
    setInvoiceDetailBreadCrumb: (state: InvoiceDetailsState, action: PayloadAction<AppRoute[]>) => {
      state.routes = action.payload;
    },
  },
});

// Adapter Selectors
export const { selectAll: selectAllInvoiceDetails, selectById: selectInvoiceDetailByKey } =
  invoiceDetailsAdapater.getSelectors<EntityState<InvoiceDetail>>(
    (invoiceDetails: EntityState<InvoiceDetail>) => invoiceDetails
  );

// Custom selectors
export const selectFilteredInvoiceDetailKeys = createSelector(
  (state: RootState) => state.invoiceDetails.invoiceDetails,
  (state: RootState) => state.invoiceDetails.invoiceDetailsGridSettings.FilterText,
  (_state: RootState, sortOption: InvoiceDetailsSelectOption) => sortOption,
  (invoiceDetails, filterText, sortOption) => {
    const items = selectAllInvoiceDetails(invoiceDetails);
    let filteredItems = items;
    if (filterText) {
      filteredItems = filterObjects(
        items,
        filterText,
        ['InvoiceDetailLineNumber', 'ProductDescription', 'ProductBrand', 'UnitOfMeasures'],
        [
          {
            field: 'UnitOfMeasures',
            type: 'custom',
            filterFunction: (val: string, queryString: string, obj: InvoiceDetail) =>
              filterUnitOfMeasures(obj.UnitOfMeasures, queryString).length > 0,
          },
        ]
      );
    }

    let orderedItems = [];
    switch (sortOption) {
      case InvoiceDetailsSelectOption.LineNumber:
        orderedItems = filteredItems.sort(
          (a: InvoiceDetail, b: InvoiceDetail) => a.InvoiceDetailLineNumber - b.InvoiceDetailLineNumber
        );
        break;
      case InvoiceDetailsSelectOption.ProductNumber:
        orderedItems = filteredItems.sort((a: InvoiceDetail, b: InvoiceDetail) =>
          (a.UnitOfMeasures[0]?.ProductNumber || '').localeCompare(b.UnitOfMeasures[0]?.ProductNumber || '')
        );
        break;
      case InvoiceDetailsSelectOption.ShippedQuantity:
        orderedItems = filteredItems.sort(
          (a: InvoiceDetail, b: InvoiceDetail) =>
            (b.UnitOfMeasures[0]?.QuantityShipped || 0) - (a.UnitOfMeasures[0]?.QuantityShipped || 0)
        );
        break;
      case InvoiceDetailsSelectOption.ExtendedPrice:
        orderedItems = filteredItems.sort(
          (a: InvoiceDetail, b: InvoiceDetail) =>
            (b.UnitOfMeasures[0]?.ExtendedPrice || 0) - (a.UnitOfMeasures[0]?.ExtendedPrice || 0)
        );
        break;
      default:
        orderedItems = filteredItems;
        break;
    }

    return orderedItems.map((i) => i.InvoiceDetailLineNumber.toString()) as string[];
  }
);

const filterUnitOfMeasures = (items: InvoiceDetailUnitOfMeasure[], queryString: string) =>
  filterObjects(
    items,
    queryString,
    [
      'ProductNumber',
      'ProductPackSize',
      'CatchWeightDisplay',
      'UnitPrice',
      'ExtendedPrice',
      'ProductUnitOfMeasureType',
    ],
    [
      { field: 'UnitPrice', type: 'currency' },
      { field: 'ExtendedPrice', type: 'currency' },
      {
        field: 'ProductUnitOfMeasureType',
        type: 'custom',
        filterFunction: (val: string, queryString: string, obj: InvoiceDetailUnitOfMeasure) => {
          const uom = UOMLookup.UOMTypes[+val].shortDescription;
          const orderedContains = `${obj.QuantityOrdered} ${uom}`.toLowerCase().includes(queryString.toLowerCase());
          const shippedContains = `${obj.QuantityShipped} ${uom}`.toLowerCase().includes(queryString.toLowerCase());
          return orderedContains || shippedContains;
        },
      },
    ]
  );
