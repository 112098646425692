import { Icon, Typography } from '@mui/material';
import { NotificationType } from 'common';
import { CfTooltip } from '../../../../../../cf-ui/Tooltip/CfTooltip';
import {
  NotificationContent,
  NotificationHeader,
} from '../../../../../site/components/notifications/ComponentNotifications/ComponentNotification.styles';

interface OrderSummaryValidationProps {
  message?: string;
  title?: string;
  notificationType?: NotificationType;
}

export const OrderSummaryValidation = ({
  message,
  title,
  notificationType,
}: OrderSummaryValidationProps): JSX.Element => {
  return (
    <NotificationHeader $notificationType={notificationType}>
      <NotificationContent>
        <Typography variant='paragraphSmallBold'>{title}</Typography>
      </NotificationContent>
      &nbsp;
      <NotificationContent data-testid={`order-summary-validation-banner-${title}`}>
        <CfTooltip
          title={<Typography variant='paragraphSmall'>{message as string}</Typography>}
          placement='top'
          enterDelay={200}
          toolTipStyle={{
            maxWidth: '170px',
            margin: '12px',
          }}
        >
          <Icon
            className='fa-exclamation-circle'
            color='inherit'
            sx={{ textAlign: 'center', fontWeight: '900 !important', fontSize: 12, padding: 0 }}
          />
        </CfTooltip>
      </NotificationContent>
    </NotificationHeader>
  );
};
