import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { AppNotificationsCenter } from '../../helpers/lookups/AppNotificationsCenter';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { AppNotification, NotificationKeys, NotificationType } from '../../models/notifications.models';
import { AppDispatch, AppThunk } from '../store';
import { globalSlice } from './global.slice';

// hooks
const appInsightsLogger = useAppInsightsLogger();

/**
 * Calls on the appInsightsLogger to track an exception
 *
 * @param error - The error being tracked
 * @returns NULL
 */
export const trackException =
  (error: Error): AppThunk =>
  async () => {
    appInsightsLogger.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
  };

/**
 * Calls on the appInsightsLogger to track a network error
 *
 * @param isNetworkError - boolean indicating that the error is a network error
 * @param error - The error being tracked
 * @returns NULL
 */
export const setNetworkError =
  (isNetworkError: boolean, error?: Error): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (isNetworkError && error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
    dispatch(globalSlice.actions.setNetworkError(isNetworkError));
  };

/**
 * Adds an app notification to the notifications value in the global slice
 *
 * @param key - Notification's key
 * @param title - Title to be used when displaying the notification
 * @param message - Message to be used when displaying the notification
 * @param id - Notification's id
 * @returns NULL
 */
export const addAppNotification =
  (key: NotificationKeys, title: string, message?: string, id?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(
      globalSlice.actions.addAppNotification(AppNotificationsCenter.getNotificationByKey(key, message, title, id))
    );
  };

/**
 * Inserts an app notification where it is the frst element of it's key type into notifications value in the global slice
 *
 * @param appNotification - Information about the notification
 * @param autoDismissTime - Time in seconds till the notification will dismiss on it's own
 * @returns NULL
 */
export const upsertAppNotification =
  (appNotification: AppNotification, autoDismissTime?: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    appNotification.AutoDismiss =
      autoDismissTime || autoDismissTime === 0 ? autoDismissTime : AppNotificationsCenter.DefaultAutoDismissTime;
    dispatch(globalSlice.actions.upsertAppNotification(appNotification));
  };

/**
 * Inserts an app notification where it is the first element of the corresponding key type into notifications value in the global slice
 *
 * @param key - Notification's key
 * @param title - Title to be used when displaying the notification
 * @param message - Message to be used when displaying the notification
 * @returns NULL
 */
export const upsertAppNotificationByKey =
  (key: NotificationKeys, title: string, message?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const appNotification = AppNotificationsCenter.getNotificationByKey(key, message, title);
    dispatch(globalSlice.actions.upsertAppNotificationByKey(appNotification));
  };

/**
 * Inserts an app notification where it is the first element of the corresponding key type and id into notifications value in the global slice
 *
 * @param key - Notification's key
 * @param id = Notification's id
 * @param message - Message to be used when displaying the notification
 * @param title - Title to be used when displaying the notification
 * @param icon - The icon the notification will display
 * @param type - Notification's type
 * @returns NULL
 */
export const upsertAppNotificationByKeyAndId =
  (
    key: NotificationKeys,
    id: string,
    message?: string,
    title?: string,
    icon?: string,
    type?: NotificationType
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const appNotification = AppNotificationsCenter.getNotificationByKeyAndId(key, {
      id: id,
      message: message,
      title: title,
      icon: icon,
      type: type,
    });
    dispatch(globalSlice.actions.upsertAppNotificationByKeyAndId(appNotification));
  };

/**
 * Removes any notification that matches the passed in AppNotification
 *
 * @param appNotification - Information about the notification
 * @returns NULL
 */
export const removeAppNotification =
  (appNotification: AppNotification): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(globalSlice.actions.removeAppNotification(appNotification));
  };

/**
 * Removes any notification that matches the key
 *
 * @param key - Notification's key
 * @returns NULL
 */
export const removeAppNotificationByKey =
  (key: NotificationKeys): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(globalSlice.actions.removeAppNotificationByKey(key));
  };

/**
 * Removes any notification that matches the id
 *
 * @param id - Notification's id
 * @returns NULL
 */
export const removeAppNotificationById =
  (id: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(globalSlice.actions.removeAppNotificationById(id));
  };

/**
 * Sets the title and message of the error dialog
 *
 * @param title - Title to be used when displaying the notification
 * @param message - Message to be used when displaying the notification
 * @returns NULL
 */
export const setErrorDialogContent =
  (title: string | undefined, messages: string[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(globalSlice.actions.setErrorDialogContent({ title: title, messages: messages }));
  };

/**
 * Sets the title and message of the information dialog
 *
 * @param title - Title to be used when displaying the notification
 * @param message - Message to be used when displaying the notification
 * @returns NULL
 */
export const setInformationDialogContent =
  (title: string, messages: string[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(globalSlice.actions.setInformationDialogContent({ title: title, messages: messages }));
  };
