import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledGridItemRightBorder = styled(Box)(({}) => ({
  borderStyle: 'solid',
  borderColor: 'rgba(0, 0, 0, 0.12)',
  borderWidth: '0 1px 0 0',
}));

export const StyledParagraphSmall = styled(Typography)(({ theme }) => ({
  color: theme.palette.coolGrey[600],
  [theme.breakpoints.up('xs')]: {
    ...theme.typography.mobileParagraphSmall,
  },
  [theme.breakpoints.up('xl')]: {
    ...theme.typography.paragraphSmall,
  },
}));

export const StyledParagraphSmallBold = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    ...theme.typography.mobileParagraphSmallBold,
  },
  [theme.breakpoints.up('xl')]: {
    ...theme.typography.paragraphSmallBold,
  },
}));
