import { Box, Button, Drawer, Typography } from '@mui/material/';
import { styled, Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

export const StyledList = styled('ul')(({}) => ({
  padding: 0,
  overflow: 'auto',
  listStyleType: 'none',
  '&:focus': { outline: 'none' },
}));

export const StyledPanelBox = styled(Box)(({}) => ({
  overflow: 'auto',
  '&:focus': { outline: 'none' },
}));

interface DrawerProps {
  displayAboveHeader?: boolean;
  drawerSx?: SxProps<Theme>;
}

export const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'displayAboveHeader' && prop !== 'drawerSx',
})<DrawerProps>(({ theme, displayAboveHeader, drawerSx }) => ({
  '& .cf-menu-item': {
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  },
  '& .cf-menu-item-prefix': {
    height: 21,
    width: 21,
    marginRight: theme.spacing(1),
  },

  ...(!displayAboveHeader && { zIndex: 100 }),

  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    borderRadius: 0,
    ...(!displayAboveHeader && {
      [theme.breakpoints.up('xs')]: {
        top: theme.custom.headerHeights['xs'],
        maxHeight: `calc(100% - ${theme.custom.headerHeights['xs']}px)`,
      },
      [theme.breakpoints.up('lg')]: {
        top: theme.custom.headerHeights['lg'],
        maxHeight: `calc(100% - ${theme.custom.headerHeights['lg']}px)`,
      },
    }),
    ...drawerSx,
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  ['&.MuiButton-sizeMedium']: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export const StyledMenuLabel = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    ...theme.typography.mobileParagraph,
  },
  [theme.breakpoints.up('xl')]: {
    ...theme.typography.paragraph,
  },
}));

export const StyledContent = styled(Typography)<{ $selected?: boolean }>(({ $selected, theme }) => ({
  ...($selected
    ? {
        '&': { ...theme.typography.mobileParagraphBold },
        color: theme.palette.primary.main,
      }
    : {
        '&': { ...theme.typography.mobileParagraph },
        color: theme.palette.grey[900],
      }),
}));
