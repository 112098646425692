/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

export type SwitchFromEditedOrderState =
  | {
      isOpen: boolean;
      isEditing: boolean;
      onSwitchDialogConfirm: (() => unknown) | undefined;
    }
  | undefined;

const initState: SwitchFromEditedOrderState = { isOpen: false, isEditing: false, onSwitchDialogConfirm: undefined };

let close: (() => void) | undefined,
  open: (() => void) | undefined,
  evaluate: ((callback: () => unknown) => boolean) | undefined,
  setIsEditing: ((isEditing: boolean) => void) | undefined;

export const useSwitchFromEditedOrder = singletonHook<SwitchFromEditedOrderState>(initState, () => {
  const [state, setState] = useState<SwitchFromEditedOrderState>(initState);
  open = () =>
    setState({
      isOpen: true,
      isEditing: state?.isEditing ?? false,
      onSwitchDialogConfirm: state?.onSwitchDialogConfirm ?? undefined,
    });
  close = () =>
    setState({
      isOpen: false,
      isEditing: state?.isEditing ?? false,
      onSwitchDialogConfirm: state?.onSwitchDialogConfirm ?? undefined,
    });
  setIsEditing = (isEditing: boolean) =>
    setState({
      isEditing: isEditing,
      isOpen: state?.isOpen ?? false,
      onSwitchDialogConfirm: state?.onSwitchDialogConfirm ?? undefined,
    });
  evaluate = (callback: () => unknown): boolean => {
    if (state?.isEditing) {
      setState({ isEditing: true, isOpen: true, onSwitchDialogConfirm: callback });
    } else {
      setState(undefined);
      callback();
    }

    return !state?.isEditing;
  };

  return state;
});

export const evaluateSwitchFromEditedOrder = (callback: () => unknown): boolean => evaluate?.(callback) ?? true;
export const closeSwitchFromEditedOrderDialog = (): void => close?.();
export const openSwitchFromEditedOrderDialog = (): void => open?.();
export const setSwitchFromEditedOrderDialog = (isEditing: boolean): void => setIsEditing?.(isEditing);
