import { FC, useEffect, useState } from 'react';
import CfEmailInput from '../../../../../../cf-ui/TextField/CfEmailInput';
import CommunicationsDialogBase, { CommunicationsDialogBaseProps } from './CommunicationsDialogBase';

export interface CommunicationsEmailDialogProps extends CommunicationsDialogBaseProps {
  label?: string;
  defaultValue?: string;
}

const CommunicationsEmailDialog: FC<CommunicationsEmailDialogProps> = (props: CommunicationsEmailDialogProps) => {
  const {
    defaultValue,
    isLoading,
    isOpen,
    label,
    primaryButtonProps,
    secondaryButtonProps,
    selectedCustomer,
    title,
    applyButtonTestId,
    checkboxTestId,
    closeButtonTestId,
    dialogTitleTestId,
    searchButtonTestId,
    selectAllCheckboxTestId,
    onClose,
    onSubmit,
  } = props;
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [value, setValue] = useState<string>(defaultValue ?? '');

  const handleSubmit = () => {
    if (value && value != defaultValue) {
      onSubmit?.(value);
      handleClose();
    }
  };

  const handleClose = (): void => {
    setValue('');
    setIsDisabled(true);
    onClose?.();
  };

  const handleChange = (value: string, hasError: boolean) => {
    if (value == defaultValue) {
      setIsDisabled(true);
      setValue(value);
    } else if (!hasError) {
      setIsDisabled(false);
      setValue(value);
    } else {
      setIsDisabled(true);
      setValue('');
    }
  };

  useEffect(() => {
    if (isOpen && defaultValue != value) {
      setValue(defaultValue ?? '');
    }
  }, [defaultValue, isOpen]);

  return (
    <CommunicationsDialogBase
      isOpen={isOpen}
      isLoading={isLoading}
      selectedCustomer={selectedCustomer}
      secondaryButtonProps={secondaryButtonProps}
      primaryButtonProps={{
        ...primaryButtonProps,
        children: 'Save',
        disabled: isDisabled,
      }}
      title={title}
      applyButtonTestId={applyButtonTestId}
      checkboxTestId={checkboxTestId}
      closeButtonTestId={closeButtonTestId}
      dialogTitleTestId={dialogTitleTestId}
      searchButtonTestId={searchButtonTestId}
      selectAllCheckboxTestId={selectAllCheckboxTestId}
      onSubmit={handleSubmit}
      onClose={handleClose}
    >
      <CfEmailInput
        dataTestId='comms-add-email-text-field'
        defaultValue={value}
        inputLabel={label}
        sx={{ width: { xl: 233 } }}
        onChange={handleChange}
        onSubmit={handleSubmit}
      />
    </CommunicationsDialogBase>
  );
};

export default CommunicationsEmailDialog;
