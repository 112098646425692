import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { CreateSubmittedEditOrderData, CreateSubmittedEditOrderRequest } from '../../api/models/edit-order.models';
import {
  GetSubmittedOrderHeadersRequest,
  GetSubmittedOrderRequest,
  OpenOrderHeadersResult,
  SubmittedOrderDetail,
} from '../../api/models/submitted-order.models';
import SubmittedOrderService from '../../api/services/submitted-order.service';
import { AppNotificationsCenter } from '../../helpers/lookups/AppNotificationsCenter';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { NotificationKeys } from '../../models';
import { upsertAppNotification } from '../common';
import { globalSlice } from '../common/global.slice';
import { AppDispatch, AppThunk, RootState } from '../store';
import { ordersSlice } from './orders.slice';

// Hooks
const service = SubmittedOrderService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

// Thunks
/**
 * Updates the OrdersState selectedSubmittedOrderHeader property
 *
 * @param selectedHeader - order header data related to the submitted order to be stored in the OrdersState
 * @returns void
 */
export const setSelectedSubmittedOrderHeader =
  (selectedHeader: OpenOrderHeadersResult): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(ordersSlice.actions.setSelectedSubmittedOrderHeader(selectedHeader));
  };

/**
 * Resets the OrdersState selectedSubmittedOrderHeader property to be undefined
 *
 * @returns void
 */
export const resetSelectedSubmittedOrderHeader = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(ordersSlice.actions.resetSelectedSubmittedOrderHeader());
};

/**
 * Updates the OrderState selectedSubmittedOrderDetails property
 *
 * @param orderDetails - detailed order data related to the selected submitted order to be stored in the OrdersState
 * @returns void
 */
export const setSelectedSubmittedOrderDetails =
  (orderDetails: SubmittedOrderDetail[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(ordersSlice.actions.setSelectedSubmittedOrderDetails(orderDetails));
  };

/**
 * Retrieves submitted order header data from the api;
 * updates the OrderState loadingSubmittedOrderHeaders, submittedOrdersApiRequest, and submittedOrderHeaders properties;
 * and aborts previous requests to the endpoint.
 *
 * @param cusomterIds - passed to the api request to filter which submitted orders to return
 * @returns void
 */
export const getSubmittedOrderHeaders =
  (customerIds: string[]): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const request: GetSubmittedOrderHeadersRequest = {
        CustomerIds: customerIds,
      };

      if (JSON.stringify(getState().orders.submittedOrdersApiRequest) === JSON.stringify(request)) {
        if (getState().orders.loadingSubmittedOrderHeaders) {
          dispatch(ordersSlice.actions.setLoadingSubmittedOrderHeaders(false));
        }
        return;
      }

      dispatch(ordersSlice.actions.setSubmittedOrdersApiRequest(request));

      if (!getState().orders.loadingSubmittedOrderHeaders) {
        dispatch(ordersSlice.actions.setLoadingSubmittedOrderHeaders(true));
      }

      const { data } = await service.getSubmittedOrderHeaders(request);
      if (data.IsSuccess) {
        dispatch(ordersSlice.actions.setSubmittedOrderHeaders(data.ResultObject));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        dispatch(resetSubmittedOrderHeaders());
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      dispatch(ordersSlice.actions.setSubmittedOrdersApiRequest(undefined));
    } finally {
      dispatch(ordersSlice.actions.setLoadingSubmittedOrderHeaders(false));
    }
  };

/**
 * Retrieves a submitted order's header data from the api and
 * updates the OrderState loadingSubmittedOrderHeaders and selectedSubmittedOrderHeader properties with the response
 *
 * @param cusomterId - passed to the api request
 * @param orderBusinessUnitERPKey - passed to the api request
 * @param orderOperationCompanyNumber - passed to the api request
 * @param orderKey - the guid related to the order data to retrieve from the api
 * @returns void
 */
export const getSubmittedOrderHeader =
  (
    customerId: string,
    orderBusinessUnitERPKey: number,
    orderOperationCompanyNumber?: string,
    orderKey?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().orders.loadingSelectedSubmittedOrderHeader) {
        dispatch(ordersSlice.actions.setSelectedSubmittedOrderHeaderLoading(true));
      }

      const request: GetSubmittedOrderRequest = {
        OrderBusinessUnitERPKey: orderBusinessUnitERPKey,
        OrderOperationCompanyNumber: orderOperationCompanyNumber,
        CustomerId: customerId,
        OrderKey: orderKey,
      };

      const { data } = await service.getSubmittedOrderHeader(request);
      if (data.IsSuccess) {
        dispatch(ordersSlice.actions.setSelectedSubmittedOrderHeader(data.ResultObject));
      } else {
        dispatch(
          upsertAppNotification(
            AppNotificationsCenter.getNotificationByKey(
              NotificationKeys.CustomErrorInformation,
              data.ErrorMessages.at(0)
            ),
            10
          )
        );
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(ordersSlice.actions.setSelectedSubmittedOrderHeaderLoading(false));
    }
  };

/**
 * Retrieves a submitted order's detail data from the api and
 * updates the OrderState loadingSubmittedOrderDetails and submittedOrderDetails properties with the response
 *
 * @param cusomterId - passed to the api request
 * @param orderBusinessUnitERPKey - passed to the api request
 * @param orderOperationCompanyNumber - passed to the api request
 * @param orderKey - the guid related to the order data to retrieve from the api
 * @returns void
 */
export const getSubmittedOrderDetails =
  (
    customerId: string,
    orderBusinessUnitERPKey: number,
    orderOperationCompanyNumber?: string,
    orderKey?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const request: GetSubmittedOrderRequest = {
        OrderBusinessUnitERPKey: orderBusinessUnitERPKey,
        OrderOperationCompanyNumber: orderOperationCompanyNumber,
        CustomerId: customerId,
        OrderKey: orderKey,
      };

      if (JSON.stringify({ ...request }) === JSON.stringify({ ...getState().orders.submittedOrdersApiRequest })) {
        return;
      }

      dispatch(ordersSlice.actions.resetSubmittedOrderDetails());

      if (!getState().orders.loadingSelectedSubmittedOrderDetails) {
        dispatch(ordersSlice.actions.setSelectedSubmittedOrderDetailsLoading(true));
      }
      dispatch(ordersSlice.actions.setSubmittedOrdersDetailApiRequest(request));

      const { data } = await service.getSubmittedOrderDetails(request);

      if (data.IsSuccess) {
        dispatch(ordersSlice.actions.setSelectedSubmittedOrderDetails(data.ResultObject));
      } else {
        dispatch(
          upsertAppNotification(
            AppNotificationsCenter.getNotificationByKey(
              NotificationKeys.CustomErrorInformation,
              data.ErrorMessages.at(0)
            ),
            10
          )
        );
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(ordersSlice.actions.setSelectedSubmittedOrderDetailsLoading(false));
    }
  };

/**
 * Sends a request to the api to create an edited order from a previous order submission
 *
 * @param orderKey - the guid representing the order to be edited
 * @param onSuccess - callback method executed when the api response is successful
 * @param onFailure - callback method executed when the api response is unsuccessful
 * @returns void
 */
export const createSubmittedEditOrder =
  (
    orderKey: string,
    onSuccess: (result: CreateSubmittedEditOrderData) => unknown,
    onFailure: (errorMessages: string[]) => unknown
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const customer = getState().customer.selectedCustomer;
      if (!customer) {
        onFailure?.(['Invalid customer']);
        return;
      }

      const request: CreateSubmittedEditOrderRequest = {
        SubmittedOrderBusinessUnitERPKey: customer.BusinessUnitERPKey,
        SubmittedOrderOperationCompanyNumber: customer.OperationCompanyNumber,
        CustomerId: customer.CustomerId,
        SubmittedOrderKey: orderKey,
      };

      const { data } = await service.createSubmittedEditOrder(request);

      if (data.IsSuccess) {
        onSuccess(data.ResultObject);
      } else {
        onFailure(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Resets properties pertaining to submitted orders in the OrderState to their initial values
 * @returns void
 */
export const resetSubmittedOrderHeaders = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(ordersSlice.actions.resetSubmittedOrderHeaders());
};
