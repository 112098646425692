import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { openFileInCurrentWindow } from 'web/src/utilities/download-file';
import { FileResult } from '../../api';
import { GetProductListDiaryRequest } from '../../api/models/product-list-diary.models';
import ProductListDiaryService from '../../api/services/product-list-diary.service';
import { addDays, getTimeZone, truncateTime } from '../../helpers';
import { useAppInsightsLogger } from '../../logging';
import { globalSlice } from '../common';
import { AppDispatch, AppThunk } from '../store';
import { productListDiarySlice } from './product-list-diary.slice';

const productListDiaryService = ProductListDiaryService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

export const resetProductListDiary = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(productListDiarySlice.actions.resetState());
};

export const getProductListDiaryGridData =
  (productListHeaderId?: string, startDate?: string | Date, endDate?: string | Date): AppThunk<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    try {
      if (!productListHeaderId) return; // no-op

      //Last7Days:
      if (!endDate) {
        endDate = truncateTime(new Date());
        endDate = addDays(endDate, 1);
      }
      if (!startDate) {
        startDate = truncateTime(new Date());
        startDate = truncateTime(addDays(startDate, -7));
      }
      const apiRequest: GetProductListDiaryRequest = {
        StartDate: startDate,
        EndDate: endDate,
        ProductListHeaderId: productListHeaderId,
      };

      if (JSON.stringify(apiRequest) !== JSON.stringify(getState().productListDiary.apiRequest)) {
        dispatch(resetProductListDiary());
      } else {
        return; // no-op, duplicate request
      }

      if (!getState().productListDiary.isLoadingGridData) {
        dispatch(productListDiarySlice.actions.setIsLoadingGridData(true));
      }

      const { data } = await productListDiaryService.getProductListDiary(apiRequest);

      if (data.IsSuccess) {
        dispatch(productListDiarySlice.actions.setGridData(data.ResultObject));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(productListDiarySlice.actions.setIsLoadingGridData(false));
    }
  };

export const exportProductListDiaryReport =
  (
    customerId?: string,
    productListHeaderId?: string,
    startDate?: string | Date,
    endDate?: string | Date,
    filter?: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      if (!customerId || !productListHeaderId) return;
      //Last7Days:
      if (!endDate) {
        endDate = truncateTime(new Date());
        endDate = addDays(endDate, 1);
      }
      if (!startDate) {
        startDate = truncateTime(new Date());
        startDate = truncateTime(addDays(startDate, -7));
      }
      const { data } = await productListDiaryService.getProductListDiaryReport({
        ProductListHeaderId: productListHeaderId,
        StartDate: startDate,
        EndDate: endDate,
        Filter: filter ?? '',
        CustomerId: customerId,
        TimeZone: getTimeZone(),
      });
      if (data.IsSuccess) {
        openFileInCurrentWindow(data.ResultObject as FileResult);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };
