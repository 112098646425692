import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ProductListSortByType } from '../../api/models/api-shared.enums';
import { ProductListOrderEntrySearchRequest } from '../../api/models/order-entry-search.models';
import {
  ProductListProduct,
  ProductListSearchFieldType,
  SearchProductListCatalogFieldRequest,
} from '../../api/models/product-list-search.models';
import OrderEntrySearchService from '../../api/services/order-entry-search.service';
import ProductListSearchService from '../../api/services/product-list-search.service';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { AppDispatch, AppThunk, RootState } from '../store';
import { itemOrderEntrySlice } from './item-order-entry.slice';
import { orderEntrySlice } from './order-entry.slice';
import { getActiveOrder } from './orders.thunks';

const orderEntrySearchService = OrderEntrySearchService.getInstance();
const productListSearchService = ProductListSearchService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Sets the ItemOrderEntryState's noSelectableProducts property to false and updates the prevQuery and searchProductListCatalogFieldRequest properties with the request
 * @param request.CustomerId - the id representing the customer associated to the specified order
 * @param request.OrderEntryHeaderId - the id representing the order entry header data to add a product to
 * @param request.ProductListSearchFieldType - specifies which field to search by (e.g. product id, custom product id, manufacturer id)
 * @param request.Query - the id value related to the product to be added;
 * @returns void
 */
export const setSearchProductListCatalogFieldRequest =
  (request: SearchProductListCatalogFieldRequest): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    if (getState().itemOrderEntry.noSelectableProducts) {
      dispatch(itemOrderEntrySlice.actions.setNoSelectableProducts(false));
    }
    dispatch(itemOrderEntrySlice.actions.setSearchProductListCatalogFieldRequest(request));
  };

/**
 * Sends a request to the api to retrieve a list of products added to an order in Item Entry mode
 * and updates the ItemOrderEntryState's products property with the successful response and dispatches the
 * getActiveOrder thunk
 * @param request.orderEntryHeaderId - the id representing the order header to retrieve data for
 * @returns void
 */
export const getProductListItemOrderEntrySearch =
  (request: { orderEntryHeaderId: string }): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const orderEntryHeaderId = request.orderEntryHeaderId;
      const sortByType = ProductListSortByType.OrderEntry;

      if (!orderEntryHeaderId) return;

      const apiRequest: ProductListOrderEntrySearchRequest = {
        OrderEntryHeaderId: orderEntryHeaderId,
        SortByType: sortByType.valueOf(),
        Skip: 0,
        ItemsWithQuantityOnly: true,
      };

      // Data in store is for search with different parameters (excluding skip)
      if (
        JSON.stringify({ ...apiRequest, Skip: 0 }) === JSON.stringify({ ...getState().orderEntry.apiRequest, Skip: 0 })
      ) {
        return;
      }

      dispatch(resetItemOrderEntryState());

      dispatch(orderEntrySlice.actions.setApiRequest(apiRequest));

      if (!getState().itemOrderEntry.pageIsLoading) {
        dispatch(itemOrderEntrySlice.actions.setPageIsLoading(true));
      }

      const { data } = await orderEntrySearchService.getProductListOrderEntrySearch(apiRequest);
      if (data.IsSuccess) {
        const productListCategoryProductData = data.ResultObject.ProductListCategories.flatMap((plc) => plc.Products);
        const products = productListCategoryProductData.map((plcp) => plcp.Product);
        dispatch(itemOrderEntrySlice.actions.setProducts(products));
        dispatch(getActiveOrder());
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      dispatch(orderEntrySlice.actions.setApiRequest(undefined));
    } finally {
      dispatch(itemOrderEntrySlice.actions.setPageIsLoading(false));
    }
  };

/**
 * Retrieves the default search type from the api and then dispatches the setSearchProductListCatalogFieldRequest thunk with the successful response
 * @returns void
 */
export const getSearchProductListCatalogFieldDefault = (): AppThunk => async (dispatch: AppDispatch, getState) => {
  try {
    if (!getState().itemOrderEntry.loadingDefaultSearchType) {
      dispatch(itemOrderEntrySlice.actions.setLoadingDefaultSearchType(true));
    }
    const { data } = await productListSearchService.getSearchProductListCatalogFieldDefault();
    if (data.IsSuccess) {
      const request = getState().itemOrderEntry.searchProductListCatalogFieldRequest;
      const newRequest: SearchProductListCatalogFieldRequest = {
        ...request,
        ProductListSearchFieldType: data.ResultObject as ProductListSearchFieldType,
      };
      dispatch(setSearchProductListCatalogFieldRequest(newRequest));
    }
  } catch (error: unknown) {
    appInsightsLogger.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
  } finally {
    dispatch(itemOrderEntrySlice.actions.setLoadingDefaultSearchType(false));
  }
};

/**
 * Updates the ItemOrderEntryState's properties to their initial values
 * @returns void
 */
export const resetItemOrderEntryState = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(itemOrderEntrySlice.actions.resetState());
};

/**
 * Updates the ItemOrderEntryState's selectableProducts property to an empty array
 * @returns void
 */
export const resetSelectableProducts = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(itemOrderEntrySlice.actions.setSelectableProducts([]));
};

/**
 * Adds product data to the ItemOrderEntryState's products array and sets the ItemOrderEntryState's focus property to that product
 * @param product - product data to be added to the ItemOrderEntryState's products property
 * @returns void
 */
export const addSelectedProduct =
  (product: ProductListProduct): AppThunk =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(itemOrderEntrySlice.actions.addProduct(product));
    dispatch(
      itemOrderEntrySlice.actions.setFocus({
        productKey: product.ProductKey,
        customProductNumber: getState().itemOrderEntry.prevQuery,
      })
    );
  };

/**
 * Dispatches thunks (1) resetItemOrderEntryState, (2) getProductListItemOrderEntrySearch, and (3) getSearchProductListCatalogFieldDefault
 * @param request.orderEntryHeaderId - the id representing the order header data to load
 * @returns void
 */
export const getInitialItemOrderEntryState =
  (request: { orderEntryHeaderId: string }): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(
      getProductListItemOrderEntrySearch({
        orderEntryHeaderId: request.orderEntryHeaderId,
      })
    );
    dispatch(getSearchProductListCatalogFieldDefault());
  };

/**
 * Sends a request to the api to search a product list and retrieve product data based on
 * the selected search type (e.g. product id, custom product id, manufacturer id) to add to the ItemOrderEntryState's products array.
 * Responses with multiple product results will set the ItemOrderEntryState's selectableProducts to be selected from a user via the ItemOrderEntryDialog component.
 * @param request.CustomerId - the id representing the customer associated to the specified order
 * @param request.OrderEntryHeaderId - the id representing the order entry header data to add a product to
 * @param request.ProductListSearchFieldType - specifies which field to search by (e.g. product id, custom product id, manufacturer id)
 * @param request.Query - the id value related to the product to be added;
 * @returns void
 */
export const searchProductListCatalogField =
  (request: SearchProductListCatalogFieldRequest): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(itemOrderEntrySlice.actions.setNoSelectableProducts(false));
      if (!getState().itemOrderEntry.searchingProductList) {
        dispatch(itemOrderEntrySlice.actions.setSearchingProductList(true));
      }

      const { data } = await productListSearchService.searchProductListCatalogField(request);
      if (data.IsSuccess) {
        if (data.ResultObject.length === 0) {
          dispatch(itemOrderEntrySlice.actions.setNoSelectableProducts(true));
        } else if (data.ResultObject.length === 1) {
          dispatch(itemOrderEntrySlice.actions.addProduct(data.ResultObject[0]));
          dispatch(
            itemOrderEntrySlice.actions.setFocus({
              productKey: data.ResultObject[0].ProductKey,
              productNumber:
                request.ProductListSearchFieldType === ProductListSearchFieldType.Product ? request.Query : undefined,
              customProductNumber:
                request.ProductListSearchFieldType === ProductListSearchFieldType.CustomProduct
                  ? request.Query
                  : undefined,
            })
          );
        } else {
          dispatch(itemOrderEntrySlice.actions.setSelectableProducts(data.ResultObject));
        }
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(itemOrderEntrySlice.actions.clearRequestQuery());
      dispatch(itemOrderEntrySlice.actions.setSearchingProductList(false));
    }
  };
