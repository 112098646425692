import { ServiceResult } from '../models/api-shared.models';
import { SubmitOrderEntryDetailResultDetail, UpdateOrderEntryDetailRequest } from '../models/order-entry-detail.models';
import ApiService from './api-service';

class OrderEntryDetailService extends ApiService {
  private static classInstance: OrderEntryDetailService;

  private constructor() {
    super('OrderEntryDetail');
  }

  public updateOrderEntryDetail(
    request: UpdateOrderEntryDetailRequest
  ): ServiceResult<SubmitOrderEntryDetailResultDetail> {
    return this.post(`${this.baseUrl}/UpdateOrderEntryDetail`, request);
  }

  public static getInstance(): OrderEntryDetailService {
    if (!this.classInstance) {
      this.classInstance = new OrderEntryDetailService();
    }
    return this.classInstance;
  }
}

export default OrderEntryDetailService;
