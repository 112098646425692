import { FileResult } from 'common';

export const openFileInNewWindow = (file: FileResult): boolean => {
  if (!file.FileData) return false;

  const byteString = window.atob(file.FileData);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const blob = new File([int8Array], file.FileName, { type: file.FileContentType });
  const fileURL = URL.createObjectURL(blob);

  if (file.FileContentType === 'application/pdf') {
    window.open(fileURL, '_blank');
  } else {
    const anchorElement = document.createElement('a');
    anchorElement.href = fileURL;
    anchorElement.download = file.FileName || 'download';
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
  }

  return true;
};

export const openFileInCurrentWindow = (file: FileResult): boolean => {
  if (!file.FileData) return false;

  const byteString = window.atob(file.FileData);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const blob = new File([int8Array], file.FileName, { type: file.FileContentType });
  const fileURL = URL.createObjectURL(blob);

  const anchorElement = document.createElement('a');
  anchorElement.href = fileURL;
  anchorElement.download = file.FileName || 'download';
  document.body.appendChild(anchorElement);
  anchorElement.click();
  document.body.removeChild(anchorElement);

  return true;
};

export const downloadFile = (file: {
  FileURL: string | undefined;
  FileName: string | undefined;
  FileMimeType: string | undefined;
}): boolean => {
  if (!file.FileURL) return false;
  if (file.FileMimeType === 'application/pdf') {
    window.open(file.FileURL, '_blank');
  } else {
    const anchorElement = document.createElement('a');
    anchorElement.href = file.FileURL;
    anchorElement.download = file.FileName || 'download';
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
  }
  return true;
};
