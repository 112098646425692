import { StringDict } from '@azure/msal-common';
import { AppRoutes, BusinessUnitKeyType, getQueryStringByKey, RouteName } from 'common';
import { LocalStorageService } from './local-storage-service';

type LogoutRequest = {
  postLogoutRedirectUri: string;
};

export interface MsalRedirectInstance {
  logoutRedirect: (logoutRequest: LogoutRequest) => Promise<void>;
}

export const logout = async (instance: MsalRedirectInstance, businessUnit?: BusinessUnitKeyType): Promise<void> => {
  const localStorageService = new LocalStorageService();

  localStorageService.clear();

  const appendQueryString = businessUnit ? `?bu=${getBusinessUnitQueryParam(businessUnit)}` : '';
  const logoutRequest: LogoutRequest = { postLogoutRedirectUri: AppRoutes[RouteName.Home].Path + appendQueryString };
  return await instance.logoutRedirect(logoutRequest);
};

export const getBusinessUnitQueryParam = (businessUnit?: BusinessUnitKeyType): string => {
  switch (businessUnit) {
    case BusinessUnitKeyType.PerformanceFoodService:
    case BusinessUnitKeyType.ReinhartFoodService:
      return 'performance';
    case BusinessUnitKeyType.Vistar:
      return 'vistar';
    default:
      return '';
  }
};

export const applyExtraQueryParameters = (extraQueryParameters: StringDict | undefined): StringDict | undefined => {
  const buQueryString = getQueryStringByKey('bu');
  if (buQueryString)
    extraQueryParameters = {
      bu: buQueryString,
    };

  return extraQueryParameters;
};
