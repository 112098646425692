import { Customer, ReorderInvoiceRequest, ReorderResult } from '../models';
import { ServiceResult } from '../models/api-shared.models';
import {
  GetInvoiceDetailsRequest,
  GetInvoiceDocumentRequest,
  GetInvoiceDocumentResult,
  GetInvoiceExportOptionsResponse,
  GetInvoiceExportRequest,
  GetInvoiceExportResult,
  GetInvoiceHeaderRequest,
  GetInvoiceHeadersRequest,
  GetInvoiceProofOfDeliveryDocumentRequest,
  GetInvoiceProofOfDeliveryDocumentResult,
  InvoiceDetail,
  InvoiceHeader,
} from '../models/invoice.models';
import ApiService from './api-service';

class InvoiceService extends ApiService {
  private static classInstance: InvoiceService;

  private constructor() {
    super('Invoice');
  }

  public getInvoiceCustomers(): ServiceResult<Customer[]> {
    return this.get(`${this.baseUrl}/GetInvoiceCustomers`);
  }

  public getInvoiceHeader(request: GetInvoiceHeaderRequest): ServiceResult<InvoiceHeader> {
    return this.post(`${this.baseUrl}/GetInvoiceHeader`, request);
  }

  public getInvoiceHeaders(request: GetInvoiceHeadersRequest): ServiceResult<InvoiceHeader[]> {
    return this.post(`${this.persistUrl}/GetInvoiceHeaders`, request);
  }

  public getInvoiceDetails(request: GetInvoiceDetailsRequest): ServiceResult<InvoiceDetail[]> {
    return this.post(`${this.baseUrl}/GetInvoiceDetails`, request);
  }

  public getInvoiceDocument(request: GetInvoiceDocumentRequest): ServiceResult<GetInvoiceDocumentResult> {
    return this.post(`${this.persistUrl}/GetInvoiceDocument`, request);
  }

  public getInvoiceProofOfDeliveryDocument(
    request: GetInvoiceProofOfDeliveryDocumentRequest
  ): ServiceResult<GetInvoiceProofOfDeliveryDocumentResult> {
    return this.post(`${this.persistUrl}/GetInvoiceProofOfDeliveryDocument`, request);
  }

  public getInvoiceExport(request: GetInvoiceExportRequest): ServiceResult<GetInvoiceExportResult> {
    return this.post(`${this.persistUrl}/GetInvoiceExport`, request);
  }

  public getInvoiceExportOptions(): ServiceResult<GetInvoiceExportOptionsResponse> {
    return this.get(`${this.baseUrl}/GetInvoiceExportOptions`);
  }

  public reorderInvoice(request: ReorderInvoiceRequest): ServiceResult<ReorderResult> {
    return this.post(`${this.persistUrl}/ReorderInvoice`, request);
  }

  public static getInstance(): InvoiceService {
    if (!this.classInstance) {
      this.classInstance = new InvoiceService();
    }
    return this.classInstance;
  }
}

export default InvoiceService;
