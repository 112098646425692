import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ProductListSortByType } from '../../api/models/api-shared.enums';
import {
  CustomerProductPriceResult,
  OrderReviewCustomerProductPriceResult,
} from '../../api/models/customer-product-price.models';
import { GetDidYouForgetCustomerProductsRequest } from '../../api/models/customer-product.models';
import {
  GetOrderEntrySearchRequest,
  ProductListOrderEntrySearchRequest,
} from '../../api/models/order-entry-search.models';
import { ProductListProduct } from '../../api/models/product-list-search.models';
import CustomerProductService from '../../api/services/customer-product.service';
import OrderEntrySearchService from '../../api/services/order-entry-search.service';
import { AnalyticsContext, getAnalyticsItemData } from '../../helpers';
import { getFilteredProductPriceRequests } from '../../helpers/general/product';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { getCustomerProductPrices, getOrderReviewCustomerProductPrices } from '../common/customer.thunks';
import { globalSlice } from '../common/global.slice';
import { AppDispatch, AppThunk, RootState } from '../store';
import {
  orderReviewSlice,
  selectAllDidYouForgetCustomerProducts,
  selectAllOrderReviewProducts,
} from './order-review.slice';
import { ordersSlice } from './orders.slice';
import { getActiveOrder, getMostRecentOrder, setActiveCartById } from './orders.thunks';

// Hooks
const orderEntrySearchService = OrderEntrySearchService.getInstance();
const customerProductService = CustomerProductService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

// Thunks

export const getOrderReviewData =
  (orderId: string, analyticsContext?: AnalyticsContext): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(getMostRecentOrder());

    const activeOrderId = getState().orders.activeOrder?.OrderEntryHeaderId;

    if (activeOrderId === orderId) {
      dispatch(getProductListOrderReviewSearch(analyticsContext));
    } else {
      dispatch(setActiveCartById(orderId, () => dispatch(getProductListOrderReviewSearch(analyticsContext))));
    }
  };

/**
 * Calls and stores the result of the ProductListOrderEntrySearch API call
 *
 * @param analyticsContext - The context to use for analytics
 * @returns NULL
 */
export const getProductListOrderReviewSearch =
  (analyticsContext?: AnalyticsContext): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().orderReview.productListLoading) {
        dispatch(orderReviewSlice.actions.setProductListLoading(true));
      }
      const selectedCustomer = getState().customer.selectedCustomer;
      const activeOrderId = getState().orders.activeOrder?.OrderEntryHeaderId;
      const deliveryDate = getState().orders.activeOrder?.DeliveryDate;

      if (!selectedCustomer || !activeOrderId || !deliveryDate) return;
      if (activeOrderId === getState().orderReview.apiRequest?.OrderEntryHeaderId) return;

      const apiRequest: ProductListOrderEntrySearchRequest = {
        OrderEntryHeaderId: activeOrderId,
        ProductListHeaderId: undefined,
        QueryText: undefined,
        SortByType: ProductListSortByType.OrderEntry,
        Skip: 0,
        PageSize: undefined,
        ItemsWithQuantityOnly: true,
      };

      dispatch(orderReviewSlice.actions.setApiRequest(apiRequest));

      const { data } = await orderEntrySearchService.getProductListOrderEntrySearch(apiRequest);
      if (data.IsSuccess) {
        dispatch(orderReviewSlice.actions.searchProductListOrderReview(data.ResultObject));
        dispatch(getOrderReviewCustomerProductPrices());
        dispatch(getActiveOrder());
        dispatch(getDidYouForgetCustomerProducts());

        let extendedPrice = 0;
        analyticsContext?.analytics?.beginCheckout?.(
          data.ResultObject.ProductListCategories.flatMap((c) =>
            c.Products.flatMap((p, index) =>
              p.Product.UnitOfMeasureOrderQuantities.map((uom) => {
                extendedPrice += uom.Price ?? 0;
                return getAnalyticsItemData(
                  p.Product.ProductKey ?? '',
                  uom.UnitOfMeasure.toString(),
                  uom.Price ?? 0,
                  uom.Quantity,
                  index,
                  uom,
                  p.Product,
                  analyticsContext.listId,
                  analyticsContext.listName
                );
              })
            )
          ),
          'USD',
          extendedPrice
        );
      } else {
        dispatch(
          globalSlice.actions.setErrorDialogContent({
            messages: data.ErrorMessages,
          })
        );
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(orderReviewSlice.actions.setProductListLoading(false));
    }
  };

/**
 * @deprecated - only used for unit tests
 * Calls and stores the result of the GetOrderEntrySearch API call (Only used for unit tests)
 *
 * @returns NULL
 */
export const getOrderReviewSearch = (): AppThunk => async (dispatch: AppDispatch, getState) => {
  try {
    const selectedCustomer = getState().customer.selectedCustomer;
    const activeOrderId = getState().orders.activeOrder?.OrderEntryHeaderId;
    const deliveryDate = getState().orders.activeOrder?.DeliveryDate;

    if (!selectedCustomer || !activeOrderId || !deliveryDate) return;

    const request: GetOrderEntrySearchRequest = {
      BusinessUnitKey: selectedCustomer.BusinessUnitKey,
      BusinessUnitERPKey: selectedCustomer.BusinessUnitERPKey,
      OperationCompanyNumber: selectedCustomer.OperationCompanyNumber,
      CustomerId: selectedCustomer.CustomerId,
      DeliveryDate: deliveryDate,
      SortByKey: 3,
      OrderEntryHeaderId: activeOrderId,
      ItemsWithQuantityOnly: true,
      LoadPricing: true,
    };

    dispatch(orderReviewSlice.actions.setProductListLoading(true));

    const { data } = await orderEntrySearchService.getOrderEntrySearch(request);
    if (data.IsSuccess) {
      dispatch(orderReviewSlice.actions.setProducts(data.ResultObject));
      dispatch(getOrderReviewCustomerProductPrices());
      dispatch(getActiveOrder());
    } else {
      dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
    }
    dispatch(orderReviewSlice.actions.setProductListLoading(false));
  } catch (error) {
    appInsightsLogger.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
    dispatch(orderReviewSlice.actions.setProductListLoading(false));
    // [TODO]: Handle state if an error occurs here
  }
};

/**
 * Set the value of the products value's price in the order-review slice
 *
 * @param priceResults - The value to set the price to
 * @returns NULL
 */
export const setOrderReviewProductPrices =
  (priceResults: OrderReviewCustomerProductPriceResult[]): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(orderReviewSlice.actions.setProductPrices(priceResults));
  };

/**
 * Calls the GetCustomerProductPrice API call and calls the setOrderReviewProductPrices to store the prices
 *
 * @returns NULL
 */
export const getOrderReviewProductPrices = (): AppThunk => (dispatch: AppDispatch, getState: () => RootState) => {
  const products = selectAllOrderReviewProducts(getState());
  dispatch(
    getCustomerProductPrices(getFilteredProductPriceRequests(products), (priceResults: CustomerProductPriceResult[]) =>
      dispatch(setOrderReviewProductPrices(priceResults as OrderReviewCustomerProductPriceResult[]))
    )
  );
};

/**
 * Removed the specified product from the products value in the order-review slice
 *
 * @param productKey - The key of the product being removed
 * @returns NULL
 */
export const removeOrderReviewProduct =
  (productKey?: string): AppThunk =>
  (dispatch: AppDispatch) => {
    if (productKey) dispatch(orderReviewSlice.actions.removeOrderReviewProduct(productKey));
  };
/**
 * Resets the values in the order-review slice to thier inital values
 *
 * @returns NULL
 */
export const resetOrderReviewState = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(orderReviewSlice.actions.resetOrderReviewState());
  dispatch(ordersSlice.actions.resetProductPriceLoaded());
  dispatch(orderReviewSlice.actions.resetDidYouForgetProductPriceLoaded());
};

/**
 * Calls and stores the result of the GetDidYouForgetCustomerProducts API call
 *
 * @returns NULL
 */
export const getDidYouForgetCustomerProducts = (): AppThunk => async (dispatch: AppDispatch, getState) => {
  try {
    if (!getState().orderReview.didYouForgetCustomerProductsLoading) {
      dispatch(orderReviewSlice.actions.didYouForgetCustomerProductsLoading(true));
      dispatch(orderReviewSlice.actions.resetDidYouForgetCustomerProducts());
    }
    const selectedCustomer = getState().customer.selectedCustomer;
    const activeOrderId = getState().orders.activeOrder?.OrderEntryHeaderId;

    if (!selectedCustomer || !activeOrderId) return;

    const request: GetDidYouForgetCustomerProductsRequest = {
      CustomerId: selectedCustomer.CustomerId,
      OrderEntryHeaderId: activeOrderId,
    };

    dispatch(orderReviewSlice.actions.setDidYouForgetApiRequest(request));

    const { data } = await customerProductService.getDidYouForgetCustomerProducts(request);
    if (data.IsSuccess) {
      dispatch(orderReviewSlice.actions.setDidYouForgetCustomerProducts(data.ResultObject));
      dispatch(getDidYouForgetCustomerPrices());
    } else {
      dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
    }
  } catch (error) {
    appInsightsLogger.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
    // [TODO]: Handle state if an error occurs here
  } finally {
    dispatch(orderReviewSlice.actions.didYouForgetCustomerProductsLoading(false));
  }
};

export const setDidYouForgetCustomerPrices =
  (priceResults: OrderReviewCustomerProductPriceResult[]): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(orderReviewSlice.actions.setDidYouForgetCustomerProductPrices(priceResults));
  };

/**
 * Calls the GetCustomerProductPrice API call and calls the setDidYouForgetCustomerPrices to store the prices
 *
 * @returns NULL
 */
export const getDidYouForgetCustomerPrices = (): AppThunk => (dispatch: AppDispatch, getState: () => RootState) => {
  const products = selectAllDidYouForgetCustomerProducts(getState().orderReview);
  dispatch(
    getCustomerProductPrices(
      getFilteredProductPriceRequests(products),
      (priceResults: CustomerProductPriceResult[]) => {
        dispatch(setDidYouForgetCustomerPrices(priceResults as OrderReviewCustomerProductPriceResult[]));
      }
    )
  );
};

export const addDidYouForgetCustomerProductToOrderReviewList =
  (product: ProductListProduct): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(orderReviewSlice.actions.addOrderReviewProduct(product));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };
