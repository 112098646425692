import { Box, Fade, Icon, useScrollTrigger } from '@mui/material';
import { CfContainedButton } from '../../../../cf-ui/buttons/CfContainedButton';

export interface ScrollToTopButtonProps {
  scrollTarget?: Element | Window | null;
  scrollThreshold?: number;
  isScrollThresholdMet: boolean;
  onClick?: () => unknown;
}

export const ScrollToTopButton = (props: ScrollToTopButtonProps): JSX.Element => {
  const scrollTarget = props.scrollTarget ?? window;
  const scrollTrigger = useScrollTrigger({
    target: scrollTarget,
    disableHysteresis: false,
    threshold: props.scrollThreshold,
  });

  const handleClick = props.onClick ?? (() => scrollTarget.scroll({ top: 0, left: 0, behavior: 'smooth' }));

  return (
    // Reverse scrollTrigger because the 'scroll to top' button should only appear
    // when users scroll up the page. Utilize custom threshold property
    // because reversing the scrollTrigger negates its own threshold evaluation.
    <Fade in={props.isScrollThresholdMet && !scrollTrigger}>
      <Box
        sx={{
          position: 'fixed',
          zIndex: 99,
          bottom: (theme) => ({
            xs: theme.spacing(9.25),
            xl: theme.spacing(6),
          }),
          right: (theme) => ({
            xs: theme.spacing(3),
            lg: theme.spacing(3),
            xl: `max(${theme.spacing(3)}, calc((100% - ${theme.custom.contentWidths['xl']}px) / 2 - ${theme.spacing(
              18
            )}))`,
          }),
        }}
      >
        <CfContainedButton
          color='secondary'
          startIcon={<Icon className='fa-chevron-up' fontSize='small' />}
          onClick={handleClick}
        >
          Scroll to top
        </CfContainedButton>
      </Box>
    </Fade>
  );
};

export default ScrollToTopButton;
