import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  PublicClientApplication,
  SilentRequest,
} from '@azure/msal-browser';
import axios, { AxiosError, AxiosHeaders, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import * as rax from 'retry-axios';
import { LocalStorageKeys, LocalStorageService } from '../../../../../apps/web/src/utilities/local-storage-service';
import { getSHA1HashOfString } from '../../helpers/general/hash';
import { AppRoutes, RouteName } from '../../models/routing.models';
import { msalConfig, tokenRequest } from '../auth/auth-config';
import { ApiResponse, ServiceResult } from '../models/api-shared.models';

let axiosInstance: AxiosInstance;
export const msalInstance = new PublicClientApplication(msalConfig);
let sessionId = '';

export const getSessionId = (): string => {
  if (!sessionId) {
    const accounts = msalInstance.getAllAccounts();
    const homeAccountId = accounts.length > 0 ? accounts[0]?.homeAccountId : '';
    const accountInfo = msalInstance.getAccountByHomeId(homeAccountId);
    if (accountInfo !== undefined && accountInfo !== null) {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      const idTokenClaims: any = accountInfo.idTokenClaims;
      if (idTokenClaims !== undefined) {
        sessionId = idTokenClaims.iat;
      }
    }
  }

  return sessionId;
};

export const getAxiosInstance = (displayErrors = true): AxiosInstance => {
  if (!axiosInstance) {
    axiosInstance = axios.create();
    axios.defaults.raxConfig = {
      retry: 3,
      noResponseRetries: 3,
      httpMethodsToRetry: ['HEAD', 'OPTIONS', 'GET', 'POST', 'PUT', 'DELETE'],
      statusCodesToRetry: [[408], [429], [500], [502, 504]],
      backoffType: 'exponential',
      instance: axiosInstance,
      onRetryAttempt: (axiosError: AxiosError<unknown>) => {
        const retryConfig = rax.getConfig(axiosError);
        if (retryConfig?.currentRetryAttempt === retryConfig?.retry) {
        }
      },
    };

    // Only use Auth Interceptor & Re-Try Configuration in Non-Test Environments
    const isTestEnvironment = process.env.NODE_ENV === 'test';
    if (!isTestEnvironment) {
      axiosInstance.interceptors.request.use(bearerTokenInterceptor);
      rax.attach(axiosInstance);
    }

    if (displayErrors) axiosInstance.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);
    else axiosInstance.interceptors.response.use();
  }
  return axiosInstance;
};

const initiateSignOutIfFlagged = async (email: string, isFromForgotPasswordFlow = false) => {
  /* 
    isForgotPassword is an output claim that's written into the ID Token generated during the password reset flow.
    This is used as a flag to infer if the user was authenticated and reach this point in the application via the Password Reset flow 
    or from the Sign-In flow.
    ifForgotPassword === true => User was authenticated via the Password Reset Flow
    ifForgotPassword === false => User was authenticated via the Sign-In Flow
    The connecting link can be found in the B2C_1A_TrustFrameworkExtensions as an <OutputClaim></OutputClaim>
  */
  if (isFromForgotPasswordFlow) {
    // log the user out regardless as the user has been authenticated and given an ID token via the password reset flow
    await msalInstance.logoutRedirect({ postLogoutRedirectUri: AppRoutes[RouteName.Home].Path });
    return;
  }
  /*
    check email ID. if already exists and is a different one, this means that the user just signed up and tried signing in. 
    therefore, set a LogoutRequired flag to signout if any other email address than the current one was encountered.
  */
  const currentAuthenticatedUserEmailHash = getSHA1HashOfString(email);
  const localStorageService = new LocalStorageService();
  const invalidEmailIDHashCodeHash = localStorageService.getItem(LocalStorageKeys.KEY_INVALID_USER_EMAIL_ID);
  const validEmailIDHash = localStorageService.getItem(LocalStorageKeys.KEY_VALID_USER_EMAIL_ID);
  if (
    currentAuthenticatedUserEmailHash === invalidEmailIDHashCodeHash &&
    currentAuthenticatedUserEmailHash !== validEmailIDHash
  ) {
    localStorageService.removeItem(LocalStorageKeys.KEY_INVALID_USER_EMAIL_ID); // no need to clear all entries completely. storage spans browser tabs. Could remove the entries that the active and valid tab relies on if cleared completely
    await msalInstance.logoutRedirect({ postLogoutRedirectUri: AppRoutes[RouteName.Home].Path });
  }
};

const responseSuccessInterceptor = async (response: AxiosResponse<ApiResponse<unknown>>): ServiceResult<unknown> => {
  if (!response.data.IsSuccess) {
    // store.dispatch(setErrorDialogContent('Error Occurred', response.data.ErrorMessages));
  }
  return response;
};

const responseErrorInterceptor = async (error: AxiosError<ApiResponse<unknown>>): ServiceResult<AxiosError> => {
  if (error.code === 'ERR_CANCELED') {
    // no-op
  } else if (error.response && error.response?.data.ErrorMessages.length > 0) {
    // store.dispatch(setErrorDialogContent(undefined, error.response.data.ErrorMessages));
  } else {
    // store.dispatch(setErrorDialogContent(undefined, [error.message]));
  }
  return Promise.reject(error);
};

const bearerTokenInterceptor = async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const { accessToken } = await getAccessToken(tokenRequest);

  const axiosHeaders = request.headers as AxiosHeaders;
  axiosHeaders.set('Accept', 'application/json');
  axiosHeaders.set('Content-Type', 'application/json');
  if (accessToken) axiosHeaders.set('Authorization', `Bearer ${accessToken}`);

  return request;
};

const getAccessToken = async (request: SilentRequest): Promise<AuthenticationResult> => {
  const accounts = msalInstance.getAllAccounts();
  const homeAccountId = accounts.length > 0 ? accounts[0]?.homeAccountId : '';
  const accountInfo = msalInstance.getAccountByHomeId(homeAccountId);
  request.account = accountInfo || undefined;

  if (request.account !== undefined) {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const idTokenClaims: any = request.account.idTokenClaims;
    if (idTokenClaims !== undefined) {
      await initiateSignOutIfFlagged(idTokenClaims.sub, idTokenClaims.isForgotPassword);
    }
  }

  return msalInstance
    .acquireTokenSilent(request)
    .then((response: AuthenticationResult) => {
      // Set the Session ID (iat claim) when we get a new token from STS
      if (!response.fromCache && !sessionId) {
        sessionId = getSessionId();
      }
      // In case the response from B2C server has an empty accessToken field
      // throw an error to initiate token acquisition
      if (!response.accessToken || response.accessToken === '') {
        throw new InteractionRequiredAuthError();
      }
      return response;
    })
    .catch((error) => {
      console.log('Silent token acquisition failed. Redirecting to login page. \n', error);
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance
          .loginRedirect()
          .then((response) => {
            console.log(response);
            return response;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        console.log(error);
        return error;
      }
    });
};
