import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import {
  BaseGroupByRequest,
  CriteriaGroupBy,
  GenericCriteriaFilterOption,
  InvoiceReportSearchDirectory,
  InvoiceReportSearchHeader,
  ResequenceSearchGroupBysRequest,
  SaveSearchCriteriaRequest,
  SaveSearchCriteriaResultData,
  SearchCriteriaCustomersResultCustomer,
  SearchCriteriaData,
  SearchGroupBy,
  TemplateSelectableOptions,
  UpdateSearchDetailOptionsBatchRequest,
  UpdateSearchDetailOptionsRequest,
} from '../../api/models/invoice-report.models';
import { getDefaultSaveSearchCriteriaRequest, getNonTemplateSelectableOptions } from '../../helpers/general/insights';

type InvoiceReportSearchHeaderEntry = {
  directoryId: string;
  headers: InvoiceReportSearchHeader[];
  loaded: boolean;
};

// Adapters
const searchDirectoriesAdapter = createEntityAdapter<InvoiceReportSearchDirectory>({
  selectId: (directory: InvoiceReportSearchDirectory) => directory.InvoiceReportSearchDirectoryId,
});

const searchHeadersAdapater = createEntityAdapter<InvoiceReportSearchHeaderEntry>({
  selectId: (entry: InvoiceReportSearchHeaderEntry) => entry.directoryId,
});

const createHeadersEntry = (
  directoryId: string,
  headers: InvoiceReportSearchHeader[],
  loaded: boolean
): InvoiceReportSearchHeaderEntry => ({
  directoryId: directoryId,
  headers: headers,
  loaded: loaded,
});

// State
interface InsightsState {
  searchDirectories: EntityState<InvoiceReportSearchDirectory>;
  directoriesLoading: boolean;
  searchHeaders: EntityState<InvoiceReportSearchHeaderEntry>;
  headersLoading: boolean;
  searchCriteria?: SearchCriteriaData;
  searchCriteriaLoading: boolean;
  searchCriteriaCustomers: SearchCriteriaCustomersResultCustomer[];
  searchCriteriaCustomersLoading: boolean;
  saveSearchCriteriaResult?: SaveSearchCriteriaResultData;
  saveSearchCriteriaLoading: boolean;
  availableGroupBys: SearchGroupBy[];
  availableGroupBysLoading: boolean;
  searchCriteriaGroupBys: CriteriaGroupBy[];
  searchCriteriaGroupBysLoading: boolean;
  criteriaFilterOptions: GenericCriteriaFilterOption[];
  criteriaFilterOptionsLoading: boolean;
  saveSearchCriteriaRequest: SaveSearchCriteriaRequest;
  updateSearchDetailOptionsBatchRequest?: UpdateSearchDetailOptionsBatchRequest;
  updateSearchDetailOptionsRequest?: UpdateSearchDetailOptionsRequest;
  templateSelectableOptions: TemplateSelectableOptions;
  resequenceSearchGroupBysRequest?: ResequenceSearchGroupBysRequest;
  removeSearchCriteriaGroupByRequests: BaseGroupByRequest[];
  customerFilterDisplay?: string;
}

const initialState: InsightsState = {
  searchDirectories: searchDirectoriesAdapter.getInitialState(),
  directoriesLoading: true,
  searchHeaders: searchHeadersAdapater.getInitialState(),
  headersLoading: true,
  searchCriteria: undefined,
  searchCriteriaLoading: false,
  searchCriteriaCustomers: [],
  searchCriteriaCustomersLoading: true,
  saveSearchCriteriaResult: undefined,
  saveSearchCriteriaLoading: false,
  availableGroupBys: [],
  availableGroupBysLoading: false,
  searchCriteriaGroupBys: [],
  searchCriteriaGroupBysLoading: false,
  criteriaFilterOptions: [],
  criteriaFilterOptionsLoading: false,
  saveSearchCriteriaRequest: getDefaultSaveSearchCriteriaRequest(),
  updateSearchDetailOptionsBatchRequest: undefined,
  updateSearchDetailOptionsRequest: undefined,
  templateSelectableOptions: getNonTemplateSelectableOptions(),
  resequenceSearchGroupBysRequest: undefined,
  removeSearchCriteriaGroupByRequests: [],
  customerFilterDisplay: undefined,
};

// Reducers
export const insightsSlice = createSlice({
  name: 'insights',
  initialState: initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    setInvoiceSearchDirectories: (state: InsightsState, action: PayloadAction<InvoiceReportSearchDirectory[]>) => {
      searchDirectoriesAdapter.setAll(state.searchDirectories, action.payload);
    },
    setInvoiceSearchDirectoriesLoading: (state: InsightsState, action: PayloadAction<boolean>) => {
      state.directoriesLoading = action.payload;
    },
    addInvoiceSearchDirectory: (state: InsightsState, action: PayloadAction<InvoiceReportSearchDirectory>) => {
      searchDirectoriesAdapter.addOne(state.searchDirectories, action.payload);
    },
    updateInvoiceSearchDirectory: (state: InsightsState, action: PayloadAction<InvoiceReportSearchDirectory>) => {
      searchDirectoriesAdapter.updateOne(state.searchDirectories, {
        id: action.payload.InvoiceReportSearchDirectoryId,
        changes: { FolderName: action.payload.FolderName },
      });
    },
    deleteInvoiceSearchDirectory: (state: InsightsState, action: PayloadAction<string>) => {
      searchDirectoriesAdapter.removeOne(state.searchDirectories, action.payload);
      searchHeadersAdapater.removeOne(state.searchHeaders, action.payload);
    },
    setInvoiceSearchHeaders: (
      state: InsightsState,
      action: PayloadAction<{ directoryId: string; headers: InvoiceReportSearchHeader[] }>
    ) => {
      const { directoryId, headers } = action.payload;
      const entry = createHeadersEntry(directoryId, headers, true);
      searchHeadersAdapater.upsertOne(state.searchHeaders, entry);
    },
    deleteInvoiceSearchHeader: (
      state: InsightsState,
      action: PayloadAction<{ directoryId: string; invoiceReportSearchHeaderId: string }>
    ) => {
      const { directoryId, invoiceReportSearchHeaderId } = action.payload;

      const existingEntry = state.searchHeaders.entities[directoryId];
      if (!existingEntry) return;

      const filteredHeaders = existingEntry.headers.filter(
        (h) => h.InvoiceReportSearchHeaderId !== invoiceReportSearchHeaderId
      );
      const entry = createHeadersEntry(directoryId, filteredHeaders, true);
      searchHeadersAdapater.upsertOne(state.searchHeaders, entry);
    },
    setInvoiceSearchHeadersLoading: (state: InsightsState, action: PayloadAction<boolean>) => {
      state.headersLoading = action.payload;
    },
    resetInsightsReportConfigurationData: (state: InsightsState) => {
      const newState: InsightsState = {
        ...state,
        searchCriteria: undefined,
        searchCriteriaLoading: false,
        searchCriteriaCustomers: [],
        searchCriteriaCustomersLoading: true,
        saveSearchCriteriaResult: undefined,
        saveSearchCriteriaLoading: false,
        availableGroupBys: [],
        availableGroupBysLoading: false,
        searchCriteriaGroupBys: [],
        searchCriteriaGroupBysLoading: false,
        criteriaFilterOptions: [],
        criteriaFilterOptionsLoading: false,
        saveSearchCriteriaRequest: getDefaultSaveSearchCriteriaRequest(),
        updateSearchDetailOptionsBatchRequest: undefined,
        updateSearchDetailOptionsRequest: undefined,
        templateSelectableOptions: getNonTemplateSelectableOptions(),
        resequenceSearchGroupBysRequest: undefined,
        removeSearchCriteriaGroupByRequests: [],
      };

      state = newState;
      return state;
    },
    setSearchCriteria: (state: InsightsState, action: PayloadAction<SearchCriteriaData | undefined>) => {
      state.searchCriteria = action.payload;
    },
    setSearchAsFavorite: (
      state: InsightsState,
      action: PayloadAction<{ SearchName: string; SearchDirectoryId: string }>
    ) => {
      if (state.searchCriteria) {
        state.searchCriteria.SearchName = action.payload.SearchName;
        state.searchCriteria.SearchDescription = action.payload.SearchDirectoryId;
        //state.searchCriteria.IsFavorite = action.payload.IsFavorite;
      }
    },
    setSearchCriteriaLoading: (state: InsightsState, action: PayloadAction<boolean>) => {
      state.searchCriteriaLoading = action.payload;
    },
    setSearchCriteriaCustomers: (
      state: InsightsState,
      action: PayloadAction<SearchCriteriaCustomersResultCustomer[]>
    ) => {
      state.searchCriteriaCustomers = action.payload;
    },
    setSearchCriteriaCustomersLoading: (state: InsightsState, action: PayloadAction<boolean>) => {
      state.searchCriteriaCustomersLoading = action.payload;
    },
    setSaveSearchCriteriaResult: (
      state: InsightsState,
      action: PayloadAction<SaveSearchCriteriaResultData | undefined>
    ) => {
      state.saveSearchCriteriaResult = action.payload;
    },
    setSaveSearchCriteriaLoading: (state: InsightsState, action: PayloadAction<boolean>) => {
      state.saveSearchCriteriaLoading = action.payload;
    },
    setAvailableGroupBys: (state: InsightsState, action: PayloadAction<SearchGroupBy[]>) => {
      state.availableGroupBys = action.payload;
    },
    setAvailableGroupBysLoading: (state: InsightsState, action: PayloadAction<boolean>) => {
      state.availableGroupBysLoading = action.payload;
    },
    setSearchCriteriaGroupBys: (state: InsightsState, action: PayloadAction<CriteriaGroupBy[]>) => {
      state.searchCriteriaGroupBys = action.payload;
    },
    setCustomerFilterDisplay: (state: InsightsState, action: PayloadAction<string | undefined>) => {
      state.customerFilterDisplay = action.payload;
    },
    setSearchCriteriaGroupBysLoading: (state: InsightsState, action: PayloadAction<boolean>) => {
      state.searchCriteriaGroupBysLoading = action.payload;
    },
    setCriteriaFilterOptions: (state: InsightsState, action: PayloadAction<GenericCriteriaFilterOption[]>) => {
      state.criteriaFilterOptions = action.payload;
    },
    setCriteriaFilterOptionsLoading: (state: InsightsState, action: PayloadAction<boolean>) => {
      state.criteriaFilterOptionsLoading = action.payload;
    },
    setSaveSearchCriteriaRequest: (state: InsightsState, action: PayloadAction<SaveSearchCriteriaRequest>) => {
      state.saveSearchCriteriaRequest = action.payload;
    },
    setUpdateSearchDetailOptionsBatchRequest: (
      state: InsightsState,
      action: PayloadAction<UpdateSearchDetailOptionsBatchRequest | undefined>
    ) => {
      state.updateSearchDetailOptionsBatchRequest = action.payload;
    },
    setUpdateSearchDetailOptionsRequest: (
      state: InsightsState,
      action: PayloadAction<UpdateSearchDetailOptionsRequest | undefined>
    ) => {
      state.updateSearchDetailOptionsRequest = action.payload;
    },
    setTemplateSelectableOptions: (state: InsightsState, action: PayloadAction<TemplateSelectableOptions>) => {
      state.templateSelectableOptions = action.payload;
    },
    setResequenceSearchGroupBysRequest: (
      state: InsightsState,
      action: PayloadAction<ResequenceSearchGroupBysRequest>
    ) => {
      state.resequenceSearchGroupBysRequest = action.payload;
    },
    addRemoveSearchCriteriaGroupByRequest: (state: InsightsState, action: PayloadAction<BaseGroupByRequest>) => {
      const newItems = [...state.removeSearchCriteriaGroupByRequests];
      newItems.push(action.payload);
      state.removeSearchCriteriaGroupByRequests = newItems;
    },
    setRemoveSearchCriteriaGroupByRequests: (state: InsightsState, action: PayloadAction<BaseGroupByRequest[]>) => {
      state.removeSearchCriteriaGroupByRequests = action.payload;
    },
  },
});

// Adapter Selectors
export const { selectAll: selectInvoiceSearchDirectories, selectById: selectInvoiceSearchDirectoriesByKey } =
  searchDirectoriesAdapter.getSelectors<InsightsState>((state: InsightsState) => state.searchDirectories);

export const { selectAll: selectInvoiceSearchHeaders, selectById: selectInvoiceSearchHeadersByKey } =
  searchHeadersAdapater.getSelectors<InsightsState>((state: InsightsState) => state.searchHeaders);

export const selectInvoiceSearchHeaderById = createSelector(
  [(state: InsightsState) => state, (state: InsightsState, id: string) => id],
  (state, id) => {
    const r: InvoiceReportSearchHeader | undefined = selectInvoiceSearchHeaders(state)
      .flatMap((sh) => sh.headers.filter((h) => h.InvoiceReportSearchHeaderId == id))
      .find((h) => h.InvoiceReportSearchHeaderId == id);

    return r;
  }
);

export const selectInvoiceSearchDirectoriesExceptTemplate = createSelector(
  [(state: InsightsState) => state],
  (state) => {
    return selectInvoiceSearchDirectories(state).filter((i) => !i.IsTemplate);
  }
);
