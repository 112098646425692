import { FC, PropsWithChildren, useRef } from 'react';
import { CfNavMenu } from '../../../../../../../cf-ui/Menu/CfNavMenu';
import { AccountButton } from '../AccountButton/AccountButton';

export interface AccountMenuProps {
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  firstItemRef?: React.RefObject<HTMLDivElement | undefined>;
}

export const AccountMenu: FC<PropsWithChildren<AccountMenuProps>> = (props: PropsWithChildren<AccountMenuProps>) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const anchorComponent = (
    <AccountButton
      setRef={(_ref) => (anchorRef.current = _ref)}
      onClick={() => {
        if (props.isOpen) props.onClose?.();
        else props.onOpen?.();
      }}
      onKeyDown={(e) => {
        if (props.isOpen && e.code.toLowerCase() === 'tab') {
          if (e.shiftKey) {
            props.onClose?.();
          } else {
            e.preventDefault();
            props.firstItemRef?.current?.focus();
          }
        }
      }}
    />
  );
  return (
    <CfNavMenu
      anchorElement={anchorRef.current as HTMLElement}
      isOpen={props.isOpen}
      dataTestId='profile-menu'
      width={159}
      onClose={props.onClose}
      placement='bottom'
      anchorComponent={anchorComponent}
      closeOnBlur
    >
      {props.children}
    </CfNavMenu>
  );
};
