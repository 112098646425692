import { Button, ButtonPropsColorOverrides, ButtonPropsSizeOverrides, Skeleton, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { OverridableStringUnion } from '@mui/types';
import React, { FC } from 'react';
import { DeviceType, useBreakpoint } from '../../hooks/useBreakpoint';

export interface CfContainedButtonProps {
  color?: OverridableStringUnion<
    'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    ButtonPropsColorOverrides
  >;
  size?: OverridableStringUnion<'small' | 'medium' | 'large', ButtonPropsSizeOverrides>;
  isAutoFocused?: boolean;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isLoading?: boolean;
  href?: string;
  dataTestId?: string;
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  sx?: SxProps<Theme>;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLButtonElement>) => void;
}
/**
 *
 * @param props.isAutoFocused - Boolean indicating if the button should be automatically focused on
 * @param props.color - Color scheme of the button (inherit, primary, secondary, success, error, info, warning)
 * @param props.isDisabled - Boolean indicating if the button is disabled
 * @param props.endIcon - The icon to be shown on the right side of the button
 * @param props.isfullWidth - Boolean indicating if the width of the button should be set to the width of it's container
 * @param props.href - URL link to use on button click
 * @param props.size - responsive (small or medium) if undefined
 * @param props.startIcon - The icon to be shown on the left side of the button
 * @param props.sx - Allows for additional styling to be passed to the button
 * @param props.onClick - merged functionality of onClick and onKeyDown ('enter')
 * @param props.isLoading - Boolean indicating if the loading placeholder should be shown
 * @param props.dataTestId - Test id of the button
 */
export const CfContainedButton: FC<CfContainedButtonProps> = (props: CfContainedButtonProps) => {
  const { onClick, isLoading, dataTestId, isDisabled, isAutoFocused, isFullWidth, ...rest } = props;

  const deviceType = useBreakpoint().deviceType;
  let size = props.size;
  if (size == undefined) {
    size = deviceType === DeviceType.Desktop ? 'small' : 'medium';
  }
  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onClick?.(e);
  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (['Enter', 'NumpadEnter'].includes(e.key)) onClick?.(e);
  };
  if (isLoading)
    return (
      <Skeleton variant='rectangular'>
        <Button
          data-testid={dataTestId}
          variant='contained'
          onClick={handleOnClick}
          onKeyDown={handleOnKeyDown}
          {...rest}
          size={size}
        />
      </Skeleton>
    );
  return (
    <Button
      data-testid={dataTestId}
      disabled={isDisabled}
      autoFocus={isAutoFocused}
      fullWidth={isFullWidth}
      variant='contained'
      onClick={handleOnClick}
      onKeyDown={handleOnKeyDown}
      {...rest}
      size={size}
    />
  );
};
