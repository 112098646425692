import { ReorderOrderConfirmationRequest, ReorderResult } from '../models';
import { ServiceResult } from '../models/api-shared.models';
import {
  GetOrderConfirmationReportRequest,
  GetOrderConfirmationRequest,
  OrderConfirmationReport,
  OrderConfirmationResult,
} from '../models/order-confirmation.models';
import ApiService from './api-service';

class OrderConfirmationService extends ApiService {
  private static classInstance: OrderConfirmationService;

  private constructor() {
    super('OrderConfirmation');
  }

  public getOrderConfirmation(request: GetOrderConfirmationRequest): ServiceResult<OrderConfirmationResult> {
    return this.get(`${this.baseUrl}/GetOrderConfirmation`, request);
  }

  public getOrderConfirmationReport(
    request: GetOrderConfirmationReportRequest
  ): ServiceResult<OrderConfirmationReport> {
    return this.get(`${this.persistUrl}/GetOrderConfirmationReport`, request);
  }

  public reorderOrderConfirmation(request: ReorderOrderConfirmationRequest): ServiceResult<ReorderResult> {
    return this.post(`${this.persistUrl}/ReorderOrderConfirmation`, request);
  }

  public static getInstance(): OrderConfirmationService {
    if (!this.classInstance) {
      this.classInstance = new OrderConfirmationService();
    }
    return this.classInstance;
  }
}

export default OrderConfirmationService;
