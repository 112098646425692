import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { CheckboxItem, CheckboxTypedItem } from '../../../../web/src/cf-ui/CheckboxList/components/Checkbox/CfCheckbox';
import { openFileInCurrentWindow } from '../../../../web/src/utilities/download-file';
import { FileResult } from '../../api/models/api-shared.models';
import {
  GetExportOptionDefaultsResultData,
  GetProductListReportRequest,
  GetProductListReportSortOptionResponse,
  ProductListReportSortOptionType,
  ReportSpecificationProps,
} from '../../api/models/product-list-header.models';
import ProductListHeaderService from '../../api/services/product-list-header.service';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { globalSlice } from '../common';
import { setErrorDialogContent } from '../common/global.thunks';
import { AppDispatch, AppThunk } from '../store';
import { productListReportSlice } from './product-list-report.slice';
const service = ProductListHeaderService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Calls GetExportOptionDefaults API call
 *
 * @param productListHeaderId - Id of the product list header whos Export option defaults are being retrieved
 * @returns Promise of GetExportOptionDefaultsResultData type
 */
export const getProductListExportOptionDefaults =
  (productListHeaderId: string): AppThunk<Promise<GetExportOptionDefaultsResultData | undefined>> =>
  async (): Promise<GetExportOptionDefaultsResultData | undefined> => {
    try {
      const { data } = await service.getExportOptionDefaults(productListHeaderId);
      if (data.IsSuccess) {
        return Promise.resolve(data.ResultObject);
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }

    return Promise.resolve(undefined);
  };

/**
 * Calls GetProductListReportSortOptions API call
 *
 * @param productListHeaderId - Id of the product list header whos report sort options are being retrieved
 * @returns Promise of GetProductListReportSortOptionResponse type
 */
export const getProductListReportSortOptions =
  (productListHeaderId: string): AppThunk<Promise<GetProductListReportSortOptionResponse[]>> =>
  async (dispatch: AppDispatch): Promise<GetProductListReportSortOptionResponse[]> => {
    try {
      const { data } = await service.getProductListReportSortOptions(productListHeaderId);
      if (data.IsSuccess) {
        return Promise.resolve(data.ResultObject);
      }
      if (data.ErrorMessages?.length) {
        dispatch(setErrorDialogContent('An Error occurred When Retrieving Report Sort Options', data.ErrorMessages));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }

    return Promise.resolve([]);
  };

/**
 * Calls GetProductListReportCategories API call
 *
 * @param productListHeaderId - Id of the product list header whos report sort options are being retrieved
 * @param customerId - Id of the customer whos report category options are being retrieved
 * @param productListReportSortOptionType - Sort option type used in the request to the API call
 * @returns NULL
 */
export const getProductListReportCategoryOptions =
  (
    productListHeaderId: string,
    customerId: string,
    productListReportSortOptionType: ProductListReportSortOptionType
  ): AppThunk<Promise<string[]>> =>
  async (dispatch: AppDispatch): Promise<string[]> => {
    try {
      const { data } = await service.getProductListReportCategoryOptions({
        ProductListHeaderId: productListHeaderId,
        CustomerId: customerId,
        ProductListReportSortOptionType: productListReportSortOptionType,
      });
      if (data.IsSuccess) {
        return Promise.resolve(data.ResultObject);
      }
      if (data.ErrorMessages?.length) {
        dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }

    return Promise.resolve([]);
  };

/**
 * Sets the eportIsLoading value in the product-list-report slice
 *
 * @param loading - The value to set the reportIsLoading value in the product-list-report slice to
 * @returns NULL
 */
export const setReportIsLoading =
  (loading: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(productListReportSlice.actions.setReportIsLoading(loading));
  };

/**
 * Calls and sets the result of the GetProductListReport API call
 *
 * @param request - The request information for the API call
 * @returns NULL
 */
export const getProductListReport =
  (request: GetProductListReportRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(setReportIsLoading(true));
      const { data } = await service.getProductListReport(request);
      if (data.IsSuccess) {
        dispatch(productListReportSlice.actions.setProductListReport(data.ResultObject as FileResult));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(setReportIsLoading(false));
    }
  };

/**
 * Calls and displays the result of the GetProductListReport API call
 *
 * @param request - The request information for the API call
 * @returns NULL
 */
export const exportProductListReport =
  (request: GetProductListReportRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await service.getProductListReport(request);
      if (data.IsSuccess) {
        openFileInCurrentWindow(data.ResultObject as FileResult);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

export const SetPreviousSelections =
  (
    reportType: number,
    pageOrientation: number,
    sort: number,
    specificationOptions: CheckboxTypedItem<ReportSpecificationProps>[],
    parListId: string,
    categories: CheckboxItem[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const request = {
        ReportType: reportType,
        PageOrientation: pageOrientation,
        Sort: sort,
        IncludeAlternateRowShading: specificationOptions.find((s) => s.additionalValue === 'IncludeAlternateRowShading')
          ?.checked,
        IncludeDoubleSpacing: specificationOptions.find((s) => s.additionalValue === 'IncludeDoubleSpacing')?.checked,
        IncludeCategoryBreaks: specificationOptions.find((s) => s.additionalValue === 'IncludeCategoryBreaks')?.checked,
        IncludePrices: specificationOptions.find((s) => s.additionalValue === 'IncludePrices')?.checked,
        IncludeCustomProductNumbersAndDescriptions: specificationOptions.find(
          (s) => s.additionalValue === 'IncludeCustomProductNumbersAndDescriptions'
        )?.checked,
        IncludeLastPurchase: specificationOptions.find((s) => s.additionalValue === 'IncludeLastPurchase')?.checked,
        IncludeBadgesAndAdditonalInfo: specificationOptions.find(
          (s) => s.additionalValue === 'IncludeBadgesAndAdditonalInfo'
        )?.checked,
        IncludeParValues: specificationOptions.find((s) => s.additionalValue === 'IncludeParValues')?.checked,
        ParListId: parListId,
        Categories: categories.filter((c) => c.checked).map((c) => c.additionalValue as string),
      } as GetExportOptionDefaultsResultData;

      dispatch(productListReportSlice.actions.setPreviousSelections(request));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

export const ResetPreviousSelections = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(productListReportSlice.actions.resetPreviousSelections());
  } catch (error) {
    appInsightsLogger.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
  }
};
