import { css, GlobalStyles } from '@mui/material';
import { useTheme } from '@mui/styles';

// Old scroll bar styles
// *::-webkit-scrollbar {
//   width: 14px;
// }
// *::-webkit-scrollbar-thumb {
//   background-color: ${theme.palette.grey[300]};
//   border-radius: 10px;
//   border: 4px solid rgba(0,0,0,0);
//   background-clip: padding-box;
// }
// *::-webkit-scrollbar-track {
//   background-color: transparent;
// }

export const CustomGlobalStyles = (): JSX.Element => {
  const theme = useTheme();
  return (
    <GlobalStyles
      styles={css`
        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }

        html {
          scroll-padding-top: ${theme.custom.headerHeights['xs']}px;
        }

        body {
          font-family: ${theme.typography.fontFamily};
          background-color: ${theme.palette.background.default};
          position: relative;
          min-height: 100vh;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          overflow-y: overlay;
          padding: 0 !important;
        }

        body.cf-scroll-lock {
          overflow: hidden;
        }

        input[type='number'] {
          -moz-appearance: textfield;
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        .fas {
          line-height: inherit;
        }

        ul,
        ol {
          padding-left: 1em;
        }
      `}
    />
  );
};
