import { SeverityLevel } from '@microsoft/applicationinsights-web';
import {
  CopyParListRequest,
  CreateParListHeaderRequest,
  ParListDetailsByProductsRequest,
  ParListHeader,
  ParListProductRequest,
  UpdateParListHeaderRequest,
} from '../../api';
import ParListDetailService from '../../api/services/par-list-detail.service';
import ParListHeaderService from '../../api/services/par-list-header.service';
import { useAppInsightsLogger } from '../../logging/index';
import { Resolution, UserActivityAction, UserActivityActionSummary, UserActivityPageName } from '../../models';
import { globalSlice, logUserActivity } from '../common/index';
import { AppDispatch, AppThunk, RootState } from '../store';
import { parManagementSlice } from './par-management.slice';

const appInsightsLogger = useAppInsightsLogger();
const parListHeaderService = ParListHeaderService.getInstance();
const parListDetailService = ParListDetailService.getInstance();

export const getParListHeaders =
  (customerId: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().parManagement.isLoadingParListHeaders)
        dispatch(parManagementSlice.actions.setIsLoadingParListHeaders(true));

      const { data } = await parListHeaderService.getParListHeaders(customerId);

      if (data.IsSuccess) {
        dispatch(parManagementSlice.actions.setParListHeaders(data.ResultObject));
      } else {
        dispatch(parManagementSlice.actions.resetParListHeaders());
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(parManagementSlice.actions.setIsLoadingParListHeaders(false));
    }
  };

export const createParList =
  (
    parGuideName: string,
    customerId: string,
    resolution: Resolution,
    successCallback?: (parListHeader: ParListHeader) => void | Promise<void>
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().parManagement.isLoadingCreateCopyList) {
        dispatch(parManagementSlice.actions.setIsLoadingCreateCopyList(true));
      }

      dispatch(
        logUserActivity({
          action: UserActivityAction.CreateParGuide,
          pageName: UserActivityPageName.ParManagement,
          actionSummary: UserActivityActionSummary.CreatedParGuide,
          resolution: resolution,
        })
      );

      const request: CreateParListHeaderRequest = {
        ParListTitle: parGuideName,
        CustomerId: customerId,
      };
      const { data } = await parListHeaderService.createParListHeader(request);
      if (data.IsSuccess) {
        dispatch(parManagementSlice.actions.addParListHeader(data.ResultObject));
        successCallback?.(data.ResultObject);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(parManagementSlice.actions.setIsLoadingCreateCopyList(false));
    }
  };

export const copyParList =
  (
    parListHeaderId: string,
    parGuideName: string,
    customerId: string,
    resolution: Resolution,
    successCallback?: (parListHeader: ParListHeader) => void | Promise<void>
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().parManagement.isLoadingCreateCopyList) {
        dispatch(parManagementSlice.actions.setIsLoadingCreateCopyList(true));
      }

      dispatch(
        logUserActivity<{ ProductListHeaderId: string }>({
          action: UserActivityAction.CopyParGuide,
          pageName: UserActivityPageName.CopyParGuide,
          actionSummary: UserActivityActionSummary.CopyParGuide,
          resolution: resolution,
          additionalData: { ProductListHeaderId: parListHeaderId },
        })
      );

      const request: CopyParListRequest = {
        ParListTitle: parGuideName,
        ParListHeaderId: parListHeaderId,
        CustomerId: customerId,
      };

      const { data } = await parListHeaderService.copyParList(request);

      if (data.IsSuccess) {
        dispatch(parManagementSlice.actions.addParListHeader(data.ResultObject));
        successCallback?.(data.ResultObject);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(parManagementSlice.actions.setIsLoadingCreateCopyList(false));
    }
  };

export const updateParListHeader =
  (
    parListHeaderId: string,
    parGuideName: string,
    customerId: string,
    successCallback?: (parListHeader: ParListHeader) => void | Promise<void>,
    errorCallback?: () => void | Promise<void>
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().parManagement.isLoadingUpdateParListHeader) {
        dispatch(parManagementSlice.actions.setIsLoadingUpdateParListHeader(true));
      }

      const request: UpdateParListHeaderRequest = {
        ParListTitle: parGuideName,
        ParListHeaderId: parListHeaderId,
        CustomerId: customerId,
      };

      const { data } = await parListHeaderService.updateParListHeader(request);

      if (data.IsSuccess) {
        dispatch(parManagementSlice.actions.updateParList(data.ResultObject));
        successCallback?.(data.ResultObject);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        errorCallback?.();
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(parManagementSlice.actions.setIsLoadingUpdateParListHeader(false));
    }
  };

export const deleteParListHeader =
  (
    productListHeaderId: string,
    customerId: string,
    resolution: Resolution,
    closeCallback?: () => void | Promise<void>
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(
        logUserActivity<{ ProductListHeaderId: string }>({
          action: UserActivityAction.DeleteParGuide,
          pageName: UserActivityPageName.DeleteParGuide,
          actionSummary: UserActivityActionSummary.DeletedParGuide,
          resolution: resolution,
          additionalData: { ProductListHeaderId: productListHeaderId },
        })
      );
      const { data } = await parListHeaderService.deleteParListHeader({
        ParListHeaderId: productListHeaderId,
        CustomerId: customerId,
      });

      if (data.IsSuccess) {
        dispatch(parManagementSlice.actions.deleteParListHeader(productListHeaderId));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      closeCallback?.();
      dispatch(parManagementSlice.actions.setIsLoadingDeleteList(false));
    }
  };

export const getParListDetailsByProduct =
  (parListHeaderId: string, parListProductRequests: ParListProductRequest[]): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().parManagement.isLoadingParDetailsByProduct)
        dispatch(parManagementSlice.actions.setIsLoadingParListDetailsByProduct(true));

      const request: ParListDetailsByProductsRequest = {
        ParListHeaderId: parListHeaderId,
        ParListProductRequests: parListProductRequests,
      };

      const { data } = await parListDetailService.getParListDetailsByProducts(request);

      if (data.IsSuccess) {
        dispatch(parManagementSlice.actions.setParListDetailsByProduct(data.ResultObject.ParListDetails));
      } else {
        dispatch(parManagementSlice.actions.resetParListDetailsByProduct());
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(parManagementSlice.actions.setIsLoadingParListDetailsByProduct(false));
    }
  };

export const resetParListDetailsByProduct = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(parManagementSlice.actions.resetParListDetailsByProduct());
  } catch (error) {
    appInsightsLogger.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
  }
};

export const resetOrderEntryParListHeader = (): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(parManagementSlice.actions.resetParListHeader());
    dispatch(parManagementSlice.actions.setIsLoadingParListHeaders(true));
  } catch (error) {
    appInsightsLogger.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
  }
};
