import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { UnitOfMeasureOrderQuantity } from '../../api/models/api-shared.models';
import {
  CustomerProductPriceResult,
  OrderReviewCustomerProductPriceResult,
} from '../../api/models/customer-product-price.models';
import {
  DidYouForgetCustomerProducts,
  GetDidYouForgetCustomerProductsRequest,
  GetDidYouForgetCustomerProductsResult,
  UpdateCustomProductRequest,
} from '../../api/models/customer-product.models';
import {
  GetOrderEntrySearchResultDetail,
  OrderEntryProduct,
  ProductListCategoryResult,
  ProductListOrderEntrySearchRequest,
} from '../../api/models/order-entry-search.models';
import { ProductDetailTransfer } from '../../api/models/product-detail.models';
import { ProductListProduct } from '../../api/models/product-list-search.models';
import {
  canOrderProductUnitOfMeasures,
  getAllProducts,
  normalizeProductKey,
  updateProductCustomAttributesFromPayload,
} from '../../helpers/general/product';
import { getNormalizedProductListGridData } from '../../helpers/general/product-list';
import { RootState } from '../store';

const isNotificationProduct = (p: OrderEntryProduct) =>
  !p.CanOrder ||
  p.IsDiscontinued ||
  p.UnitOfMeasureOrderQuantities.some((uom: UnitOfMeasureOrderQuantity) => uom.ShowOrderQuantityAlert);

// Adapters
const productsAdapter = createEntityAdapter<ProductListProduct>({
  selectId: (product: ProductListProduct) => normalizeProductKey(product.ProductKey),
});

const notificationProductsAdapter = createEntityAdapter<ProductListProduct>({
  selectId: (product: ProductListProduct) => normalizeProductKey(product.ProductKey),
  sortComparer: (a: ProductListProduct, b: ProductListProduct) => {
    return (
      +!b.CanOrder + +b.IsDiscontinued - (+!a.CanOrder + +a.IsDiscontinued) ||
      +b.UnitOfMeasureOrderQuantities.some((uom: UnitOfMeasureOrderQuantity) => uom.ShowOrderQuantityAlert) -
        +a.UnitOfMeasureOrderQuantities.some((uom: UnitOfMeasureOrderQuantity) => uom.ShowOrderQuantityAlert)
    );
  },
});

const didYouForgetCustomerProductsAdapter = createEntityAdapter<DidYouForgetCustomerProducts>({
  selectId: (product: DidYouForgetCustomerProducts) => normalizeProductKey(product.ProductKey),
});

// State
export interface OrderReviewState {
  apiRequest: ProductListOrderEntrySearchRequest | undefined;
  products: EntityState<ProductListProduct>;
  notificationProducts: EntityState<ProductListProduct>;
  productListLoading: boolean;
  didYouForgetCustomerProducts: EntityState<DidYouForgetCustomerProducts>;
  didYouForgetCustomerProductsLoading: boolean;
  didYouForgetCustomerProductApiRequest: GetDidYouForgetCustomerProductsRequest | undefined;
  didYouForgetProductPricesLoaded?: boolean;
}

const initialState: OrderReviewState = {
  apiRequest: undefined,
  products: productsAdapter.getInitialState(),
  notificationProducts: notificationProductsAdapter.getInitialState(),
  productListLoading: false,
  didYouForgetCustomerProducts: didYouForgetCustomerProductsAdapter.getInitialState(),
  didYouForgetCustomerProductsLoading: false,
  didYouForgetCustomerProductApiRequest: undefined,
  didYouForgetProductPricesLoaded: undefined,
};

// Reducers
export const orderReviewSlice = createSlice({
  name: 'orderReview',
  initialState: initialState,
  reducers: {
    resetOrderReviewState: () => {
      return initialState;
    },
    setApiRequest: (state: OrderReviewState, action: PayloadAction<ProductListOrderEntrySearchRequest | undefined>) => {
      state.apiRequest = action.payload;
    },
    searchProductListOrderReview: (state: OrderReviewState, action: PayloadAction<ProductListCategoryResult>) => {
      const data = getNormalizedProductListGridData(action.payload.ProductListCategories);
      productsAdapter.setAll(state.products, data.products);
      const notificationProducts = data.products.filter((p: OrderEntryProduct) => {
        return (
          !p.CanOrder ||
          p.IsDiscontinued ||
          // !canOrderProductUnitOfMeasures(p) ||
          p.UnitOfMeasureOrderQuantities.some((uom: UnitOfMeasureOrderQuantity) => uom.ShowOrderQuantityAlert)
        );
      });
      notificationProductsAdapter.setAll(state.notificationProducts, notificationProducts);
    },
    setProducts: (state: OrderReviewState, action: PayloadAction<GetOrderEntrySearchResultDetail>) => {
      // only used by unit tests
      const products = getAllProducts(action.payload);
      productsAdapter.setAll(state.products, products as ProductListProduct[]);
      const notificationProducts = products.filter((p: OrderEntryProduct) => {
        return (
          !p.CanOrder ||
          p.IsDiscontinued ||
          !canOrderProductUnitOfMeasures(p) ||
          p.UnitOfMeasureOrderQuantities.some((uom: UnitOfMeasureOrderQuantity) => uom.ShowOrderQuantityAlert)
        );
      });
      notificationProductsAdapter.setAll(state.notificationProducts, notificationProducts as ProductListProduct[]);
    },
    setDidYouForgetCustomerProducts: (
      state: OrderReviewState,
      action: PayloadAction<GetDidYouForgetCustomerProductsResult>
    ) => {
      const didYouForgetCustomerProducts = action.payload.Products;
      didYouForgetCustomerProductsAdapter.setAll(
        state.didYouForgetCustomerProducts,
        didYouForgetCustomerProducts as DidYouForgetCustomerProducts[]
      );
    },
    resetDidYouForgetCustomerProducts: (state: OrderReviewState) => {
      didYouForgetCustomerProductsAdapter.removeAll(state.didYouForgetCustomerProducts);
    },
    setProductListLoading: (state: OrderReviewState, action: PayloadAction<boolean>) => {
      state.productListLoading = action.payload;
    },
    didYouForgetCustomerProductsLoading: (state: OrderReviewState, action: PayloadAction<boolean>) => {
      state.didYouForgetCustomerProductsLoading = action.payload;
    },
    updateProduct: (state: OrderReviewState, action: PayloadAction<ProductDetailTransfer>) => {
      const product = state.products.entities[normalizeProductKey(action.payload.productKey)];
      if (product) {
        product.UnitOfMeasureOrderQuantities.forEach((uom: UnitOfMeasureOrderQuantity) => {
          if (uom.UnitOfMeasure === action.payload.uom) {
            uom.ExtendedPrice = action.payload.extendedPrice;
            uom.Quantity = action.payload.quantity;
            uom.ShowOrderQuantityAlert = action.payload.showOrderQuantityAlert;
          }
        });

        const notificationProduct = state.notificationProducts.entities[normalizeProductKey(action.payload.productKey)];
        if (isNotificationProduct(product)) {
          if (notificationProduct) {
            notificationProduct.UnitOfMeasureOrderQuantities.forEach((uom: UnitOfMeasureOrderQuantity) => {
              if (uom.UnitOfMeasure === action.payload.uom) {
                uom.ExtendedPrice = action.payload.extendedPrice;
                uom.Quantity = action.payload.quantity;
                uom.ShowOrderQuantityAlert = action.payload.showOrderQuantityAlert;
              }
            });
          } else {
            notificationProductsAdapter.addOne(state.notificationProducts, product);
          }
        } else {
          if (notificationProduct) {
            notificationProductsAdapter.removeOne(
              state.notificationProducts,
              normalizeProductKey(action.payload.productKey)
            );
          }
        }
      }
      const didYouForgetCustomerProduct =
        state.didYouForgetCustomerProducts.entities[normalizeProductKey(action.payload.productKey)];
      if (didYouForgetCustomerProduct) {
        didYouForgetCustomerProduct.UnitOfMeasureOrderQuantities.forEach((uom: UnitOfMeasureOrderQuantity) => {
          if (uom.UnitOfMeasure === action.payload.uom) {
            uom.ExtendedPrice = action.payload.extendedPrice;
            uom.Quantity = action.payload.quantity;
            uom.ShowOrderQuantityAlert = action.payload.showOrderQuantityAlert;
          }
        });
        productsAdapter.addOne(state.products, didYouForgetCustomerProduct);
        const notificationProduct = state.notificationProducts.entities[normalizeProductKey(action.payload.productKey)];
        if (isNotificationProduct(didYouForgetCustomerProduct)) {
          if (notificationProduct) {
            notificationProduct.UnitOfMeasureOrderQuantities.forEach((uom: UnitOfMeasureOrderQuantity) => {
              if (uom.UnitOfMeasure === action.payload.uom) {
                uom.ExtendedPrice = action.payload.extendedPrice;
                uom.Quantity = action.payload.quantity;
                uom.ShowOrderQuantityAlert = action.payload.showOrderQuantityAlert;
              }
            });
          } else {
            notificationProductsAdapter.addOne(state.notificationProducts, didYouForgetCustomerProduct);
          }
        } else {
          if (notificationProduct) {
            notificationProductsAdapter.removeOne(
              state.notificationProducts,
              normalizeProductKey(action.payload.productKey)
            );
          }
        }
      }
    },
    updateProductCustomAttributes: (state: OrderReviewState, action: PayloadAction<UpdateCustomProductRequest>) => {
      const product = state.products.entities[normalizeProductKey(action.payload.ProductKey)];
      if (!product) return;

      updateProductCustomAttributesFromPayload(product, action.payload);

      const notificationProduct = state.notificationProducts.entities[normalizeProductKey(action.payload.ProductKey)];
      if (!notificationProduct) return;

      updateProductCustomAttributesFromPayload(notificationProduct, action.payload);
    },
    removeOrderReviewProduct: (state: OrderReviewState, action: PayloadAction<string>) => {
      productsAdapter.removeOne(state.products, normalizeProductKey(action.payload));
      notificationProductsAdapter.removeOne(state.notificationProducts, normalizeProductKey(action.payload));
    },
    addOrderReviewProduct: (state: OrderReviewState, action: PayloadAction<ProductListProduct>) => {
      productsAdapter.addOne(state.products, action.payload);
    },
    setProductPrices: (state: OrderReviewState, action: PayloadAction<CustomerProductPriceResult[]>) => {
      action.payload.forEach((priceResult: CustomerProductPriceResult) => {
        if (priceResult.ProductKey) {
          const product = state.products.entities[normalizeProductKey(priceResult.ProductKey)];
          if (product) {
            product.PriceLoaded = true;
            product.UnitOfMeasureOrderQuantities.forEach((uom: UnitOfMeasureOrderQuantity) => {
              if (uom.UnitOfMeasure === priceResult.UnitOfMeasureType) {
                uom.Price = priceResult.Price;
                if (priceResult.ProductAverageWeight > 0) {
                  uom.ProductAverageWeight = priceResult.ProductAverageWeight;
                }
              }
            });
          }
        }
      });
    },
    setDidYouForgetCustomerProductPrices: (
      state: OrderReviewState,
      action: PayloadAction<OrderReviewCustomerProductPriceResult[]>
    ) => {
      action.payload.forEach((priceResult: OrderReviewCustomerProductPriceResult) => {
        if (priceResult.ProductKey) {
          const product = state.didYouForgetCustomerProducts.entities[normalizeProductKey(priceResult.ProductKey)];
          if (product) {
            product.PriceLoaded = true;
            product.UnitOfMeasureOrderQuantities.forEach((uom: UnitOfMeasureOrderQuantity) => {
              if (uom.UnitOfMeasure === priceResult.UnitOfMeasureType) {
                uom.Price = priceResult.Price;
                if (priceResult.ProductAverageWeight > 0) {
                  uom.ProductAverageWeight = priceResult.ProductAverageWeight;
                }
              }
            });
          }
        }
      });
    },
    setDidYouForgetApiRequest: (
      state: OrderReviewState,
      action: PayloadAction<GetDidYouForgetCustomerProductsRequest | undefined>
    ) => {
      state.didYouForgetCustomerProductApiRequest = action.payload;
    },
    setDidYouForgetProductPriceLoaded: (state: OrderReviewState, action: PayloadAction<boolean>) => {
      state.didYouForgetProductPricesLoaded = action.payload;
    },
    resetDidYouForgetProductPriceLoaded: (state: OrderReviewState) => {
      state.didYouForgetProductPricesLoaded = initialState.didYouForgetProductPricesLoaded;
    },
  },
});

// Selectors
export const { selectAll: selectAllOrderReviewProducts, selectById: selectOrderReviewProductById } =
  productsAdapter.getSelectors<RootState>((state: RootState) => state.orderReview.products);

export const {
  selectAll: selectAllOrderReviewNotificationProducts,
  selectById: selectOrderReviewNotificationProductById,
} = notificationProductsAdapter.getSelectors<RootState>((state: RootState) => state.orderReview.notificationProducts);

export const selectOrderReviewActionNeededProducts = createSelector(
  selectAllOrderReviewNotificationProducts,
  (products) =>
    products.filter(
      (p) => !p.CanOrder || p.IsDiscontinued
      // || !canOrderProductUnitOfMeasures(p)
    )
);

export const selectOrderReviewActionRequestedProducts = createSelector(
  selectAllOrderReviewNotificationProducts,
  (products) =>
    products.filter(
      (p) =>
        !(
          (!p.CanOrder || p.IsDiscontinued)
          // || !canOrderProductUnitOfMeasures(p)
        )
    )
);

export const selectOrderReviewEditedProducts = createSelector(selectAllOrderReviewProducts, (products) =>
  products.filter((p) => p.UnitOfMeasureOrderQuantities.some((u) => (u.EditOriginalQuantity ?? 0) != u.Quantity))
);

export const selectHasOrderReviewActionNeededProducts = createSelector(
  selectOrderReviewActionNeededProducts,
  (products) => products.length > 0
);

export const selectHasOrderReviewActionRequestedProducts = createSelector(
  selectOrderReviewActionRequestedProducts,
  (products) => products.length > 0
);

export const selectHasOrderReviewEditedProducts = createSelector(
  selectOrderReviewEditedProducts,
  (products) => products.length > 0
);

export const { selectAll: selectAllDidYouForgetCustomerProducts } =
  didYouForgetCustomerProductsAdapter.getSelectors<OrderReviewState>(
    (state: OrderReviewState) => state.didYouForgetCustomerProducts
  );

export const isOrderReviewProductsPagePricesLoaded = createSelector(
  selectAllOrderReviewProducts,
  (products) =>
    products.flatMap((p) =>
      p.UnitOfMeasureOrderQuantities.map((uom) => ({ product: p, uom })).filter(
        (data) => data.uom.Price || p.PriceLoaded
      )
    ).length > 0
);

export const didYouForgetProductsPagePricesLoaded = createSelector(
  (state: RootState) => selectAllDidYouForgetCustomerProducts(state.orderReview),
  (products) => products.filter((p) => p.PriceLoaded).length > 0
);

export const isReviewProductsPagePricesLoaded = createSelector(
  [isOrderReviewProductsPagePricesLoaded, didYouForgetProductsPagePricesLoaded],
  (orderReviewHasPrice, didYouForgetHasPrice): boolean => {
    return orderReviewHasPrice || didYouForgetHasPrice;
  }
);
