export function tryUntil(
  handler: (repeated: number, totalTry: number) => boolean,
  tryTime: number,
  interval = 100,
  callback?: (isSuccessful: boolean) => unknown
): void {
  const tryEach = (index: number, tryTime: number) => {
    const isDone = handler(index, tryTime);
    if (isDone) {
      callback?.(true);
    } else if (index + 1 === tryTime) {
      callback?.(false);
    } else {
      setTimeout(() => tryEach(index + 1, tryTime), interval);
    }
  };

  // Invoke recursive function
  tryEach(0, tryTime);
}
