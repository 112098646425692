import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ParListProductRequest, ProductListSortOption } from '../../api';
import { ProductListSortByType, UnitOfMeasureType } from '../../api/models/api-shared.enums';
import { CatalogProduct, UnitOfMeasureOrderQuantity } from '../../api/models/api-shared.models';
import { CustomerProductPriceResult } from '../../api/models/customer-product-price.models';
import {
  SubmitOrderEntryDetailResultDetail,
  UpdateOrderEntryDetailRequest,
} from '../../api/models/order-entry-detail.models';
import { SubmitOrderEntryHeaderRequest } from '../../api/models/order-entry-header.models';
import { OrderEntryProduct, ProductListOrderEntrySearchRequest } from '../../api/models/order-entry-search.models';
import { OrderViewersRequest } from '../../api/models/order-viewer.models';
import { ProductDetailTransfer } from '../../api/models/product-detail.models';
import CustomerProductPriceService from '../../api/services/customer-product-price.service';
import OrderEntryDetailService from '../../api/services/order-entry-detail.service';
import OrderEntryHeaderService from '../../api/services/order-entry-header.service';
import OrderEntrySearchService from '../../api/services/order-entry-search.service';
import OrderViewerService from '../../api/services/order-viewer.service';
import ProductListHeaderService from '../../api/services/product-list-header.service';
import ProductListSearchService from '../../api/services/product-list-search.service';
import { getFilteredProductPriceRequests } from '../../helpers/general/product';
import {
  AnalyticsContext,
  PurchaseData,
  getAnalyticsItemData,
  getTimeZone,
  logOrderDetailQuantityAnalytics,
} from '../../helpers/index';
import { AppNotificationsCenter, generateStaticId } from '../../helpers/lookups/AppNotificationsCenter';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { AppRoutes, ProductData, Resolution, RouteName } from '../../models';
import { NotificationKeys } from '../../models/notifications.models';
import { OrderEntryType } from '../../models/order.enums';
import {
  UserActivityAction,
  UserActivityActionSummary,
  UserActivityPageName,
} from '../../models/user-activity-report.models';
import { getCustomerProductPrices } from '../common/customer.thunks';
import { globalSlice } from '../common/global.slice';
import {
  removeAppNotificationByKey,
  setErrorDialogContent,
  upsertAppNotification,
  upsertAppNotificationByKeyAndId,
} from '../common/global.thunks';
import { logUserActivity } from '../common/user-activity.thunks';
import { getParListDetailsByProduct } from '../par-management';
import { productSearchSlice, similarProductSearchSlice } from '../search';
import { productComparisonSlice } from '../search/product-compare.slice';
import { AppDispatch, AppThunk, RootState } from '../store';
import { itemOrderEntrySlice } from './item-order-entry.slice';
import { orderConfirmationSlice } from './order-confirmation.slice';
import {
  orderEntrySlice,
  selectOrderEntryProductsMissingExtendedPrice as selectOrderEntryAnyProductsMissingExtendedPrice,
  selectOrderEntryProductListGridDataByPage,
  selectOrderEntryProductsByPage,
} from './order-entry.slice';
import { orderReviewSlice, selectAllOrderReviewProducts } from './order-review.slice';
import { ordersSlice } from './orders.slice';
import { getActiveOrder } from './orders.thunks';
import { productDetailSlice } from './product-detail.slice';

const orderEntryDetailService = OrderEntryDetailService.getInstance();
const orderEntryHeaderService = OrderEntryHeaderService.getInstance();
const orderEntrySearchService = OrderEntrySearchService.getInstance();
const orderViewerService = OrderViewerService.getInstance();
const productListSearchService = ProductListSearchService.getInstance();
const productListHeaderService = ProductListHeaderService.getInstance();
const customerProductPriceService = CustomerProductPriceService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Resets the values in order-entry slice and clears the selectOrderEntryGridDataById cache
 *
 * @param callBack - Method to call at the end of the method
 * @returns NULL
 */
export const resetOrderEntryState =
  (callBack?: () => void | Promise<void>): AppThunk =>
  async (dispatch: AppDispatch) => {
    productListHeaderService.abortGetProductListHeaders();
    productListSearchService.abortGetProductListOrderEntrySort();
    orderEntrySearchService.abortGetProductListOrderEntrySearch();
    customerProductPriceService.abortGetCustomerProductPrice();
    dispatch(orderEntrySlice.actions.resetState());
    dispatch(ordersSlice.actions.setProductPriceLoading(false));
    dispatch(ordersSlice.actions.resetProductPriceLoaded());

    if (callBack) {
      await callBack();
    }
  };

/**
 * Resets the categories, categoryProducts, products, productSearchResult and apiRequest values in the slice
 *
 * @returns NULL
 */
export const resetSearchProductListOrderEntry = (): AppThunk => async (dispatch: AppDispatch) => {
  customerProductPriceService.abortGetCustomerProductPrice();
  orderEntrySearchService.abortGetProductListOrderEntrySearch();
  dispatch(orderEntrySlice.actions.resetSearchProductListOrderEntry());
  dispatch(ordersSlice.actions.setProductPriceLoading(false));
  dispatch(ordersSlice.actions.resetProductPriceLoaded());
};

/**
 * Calls and stores the result of the ProductListOrderEntrySearch API call
 *
 * @param request - The request to the api. Contains orderEntryHeaderId, productListHeaderId, sortByType, filterText, pageIndex and itemsWithQuantityOnly values
 * @param analyticsContext - The analytics context to use
 * @returns NULL
 */
export const getProductListOrderEntrySearch =
  (
    request: {
      orderEntryHeaderId: string;
      productListHeaderId?: string;
      sortByType?: ProductListSortByType;
      filterText?: string;
      pageIndex?: number;
      itemsWithQuantityOnly?: boolean;
    },
    analyticsContext?: AnalyticsContext
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const currentOrderId = getState().orders.activeOrder?.OrderEntryHeaderId;
      if (request.orderEntryHeaderId !== currentOrderId) return; // no-op, must match the active order

      const orderEntryHeaderId = request.orderEntryHeaderId;
      const productListHeaderId =
        request.productListHeaderId ?? getState().orderEntry.productListHeaderOptions?.[0]?.ProductListHeaderId;
      const sortByType = request?.sortByType ?? getState().orderEntry.sortByOptions?.[0]?.Value;

      const pageSize = 75;
      const pageIndex = request.pageIndex ?? 0;
      const skip = (request.pageIndex === undefined ? 0 : getState().orderEntry.productSearchResult?.Skip) ?? 0;
      const filterText = request.filterText;
      const itemsWithQuantityOnly = request.itemsWithQuantityOnly ?? false;

      if (!orderEntryHeaderId && !productListHeaderId) {
        return;
      }

      const apiRequest: ProductListOrderEntrySearchRequest = {
        OrderEntryHeaderId: orderEntryHeaderId,
        ProductListHeaderId: productListHeaderId,
        QueryText: filterText ?? '',
        SortByType: sortByType.valueOf(),
        Skip: skip,
        PageSize: pageSize,
        ItemsWithQuantityOnly: itemsWithQuantityOnly,
      };

      // Data in store is for search with different parameters (excluding skip)
      if (
        JSON.stringify({ ...apiRequest, Skip: 0 }) !== JSON.stringify({ ...getState().orderEntry.apiRequest, Skip: 0 })
      ) {
        dispatch(resetSearchProductListOrderEntry());
      }

      const priorRequest = getState().orderEntry.apiRequest;
      if (priorRequest && skip <= priorRequest?.Skip) {
        return;
      }

      dispatch(orderEntrySlice.actions.setApiRequest(apiRequest));

      dispatch(
        orderEntrySlice.actions.setPageLoading({
          pageIndex: pageIndex,
          pageSize: pageSize,
          isLoading: true,
        })
      );

      if (pageIndex > 0) {
        analyticsContext?.analytics?.event?.(analyticsContext.listId, 'load_more', orderEntryHeaderId, pageIndex);
      }

      const { data } = await orderEntrySearchService.getProductListOrderEntrySearch(apiRequest);

      if (data.IsSuccess) {
        data.ResultObject.pageSize = pageSize;
        data.ResultObject.pageIndex = pageIndex;
        const previousIndex = pageIndex - 1;
        const previousPage =
          previousIndex >= 0 ? selectOrderEntryProductListGridDataByPage(getState(), previousIndex) : undefined;

        dispatch(
          orderEntrySlice.actions.searchProductListOrderEntry({
            request: apiRequest,
            result: data.ResultObject,
            previousPage,
          })
        );
        dispatch(getActiveOrder());

        analyticsContext?.analytics?.viewItemList?.(
          data.ResultObject.ProductListCategories.flatMap((c, index) =>
            c.Products.flatMap((p) =>
              p.Product.UnitOfMeasureOrderQuantities.map((uom) =>
                getAnalyticsItemData(
                  p.Product.ProductKey ?? '',
                  uom.UnitOfMeasure.toString(),
                  uom.Price ?? 0,
                  uom.Quantity,
                  index,
                  uom,
                  p.Product,
                  analyticsContext.listId,
                  analyticsContext.listName
                )
              )
            )
          ),
          analyticsContext.listId,
          analyticsContext.listName
        );

        if (!data.ResultObject.HasLoadMore)
          analyticsContext?.analytics?.event?.(analyticsContext.listId, 'end_of_list', analyticsContext.listName);
      } else {
        dispatch(orderEntrySlice.actions.setApiRequest(undefined));
        dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
        dispatch(
          orderEntrySlice.actions.setPageLoaded({
            pageIndex: pageIndex,
            categoryProducts: [],
          })
        );
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      dispatch(orderEntrySlice.actions.setApiRequest(undefined));
    } finally {
    }
  };

/**
 * Resets the getProductListHeadersRequest and productListHeaderOptions values in the slice
 *
 * @returns NULL
 */
export const resetProductListHeaderOptions = (): AppThunk => async (dispatch: AppDispatch) => {
  productListHeaderService.abortGetProductListHeaders();
  dispatch(orderEntrySlice.actions.resetProductListHeaderOptions());
};

/**
 * Sets the loading product list
 *
 * @returns NULL
 */
export const setIsLoadingProductList = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(
    orderEntrySlice.actions.setPageLoading({
      pageIndex: 0,
      pageSize: 75,
      isLoading: true,
    })
  );
};

/**
 * Calls and stores the result of the GetProductListHeaders API call
 *
 * @param orderId - The id of the order so that it can be compared to the active order
 * @param callback - Method to call at the end of the method
 * @returns
 */
export const getProductListHeaderOptions =
  (orderId: string, callback?: (productListHeaderIds: string[]) => void): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (!getState().orderEntry.isLoadingProductListHeaderOptions)
      dispatch(orderEntrySlice.actions.setIsLoadingProductListHeaderOptions(true));
    try {
      const currentOrderId = getState().orders.activeOrder?.OrderEntryHeaderId;
      if (orderId !== currentOrderId) return; // no-op, must match the active order

      const customerId = getState().customer.selectedCustomer?.CustomerId;
      if (!customerId) return;

      const apiRequest = {
        excludeReadOnlyLists: false,
        isForOrderEntry: true,
        customerId: customerId,
      };

      if (
        JSON.stringify(apiRequest) === JSON.stringify(getState().orderEntry.getProductListHeadersRequest) &&
        getState().orderEntry.productListHeaderOptions.length
      ) {
        callback?.(getState().orderEntry.productListHeaderOptions.map((header) => header.ProductListHeaderId));
        return;
      }

      dispatch(resetProductListHeaderOptions());
      dispatch(
        orderEntrySlice.actions.setPageLoading({
          pageIndex: 0,
          pageSize: 75,
          isLoading: true,
        })
      );

      const { data } = await productListHeaderService.getProductListHeaders(apiRequest);

      dispatch(orderEntrySlice.actions.setProductListHeaderOptions({ request: apiRequest, result: data.ResultObject }));

      callback?.(data.ResultObject.map((header) => header.ProductListHeaderId));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(orderEntrySlice.actions.setIsLoadingProductListHeaderOptions(false));
    }
  };

/**
 * Resets the getSortByOptionsProductListHeaderId and sortByOptions values in the slice
 *
 * @returns NULL
 */
export const resetProductListOrderEntrySort = (): AppThunk => async (dispatch: AppDispatch) => {
  productListSearchService.abortGetProductListOrderEntrySort();
  dispatch(orderEntrySlice.actions.resetSortByOptions());
};

/**
 * Calls and stores the results of the GetProductListOrderEntrySort API call
 *
 * @param productListHeaderId - The id of the product list header
 * @param callback - Method to call at the end of this method
 * @returns NULL
 */
export const getProductListOrderEntrySort =
  (productListHeaderId?: string, callback?: (productListSortOptions: ProductListSortOption[]) => void): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!productListHeaderId) return;

      if (
        productListHeaderId === getState().orderEntry.getSortByOptionsProductListHeaderId &&
        getState().orderEntry.sortByOptions.length
      ) {
        callback?.(getState().orderEntry.sortByOptions);
        return;
      }

      dispatch(orderEntrySlice.actions.setIsLoadingSortByOptions(true));
      dispatch(resetProductListOrderEntrySort());
      dispatch(
        orderEntrySlice.actions.setPageLoading({
          pageIndex: 0,
          pageSize: 75,
          isLoading: true,
        })
      );
      const { data } = await productListSearchService.getProductListOrderEntrySort(productListHeaderId);
      if (data.IsSuccess) {
        dispatch(orderEntrySlice.actions.setSortByOptions({ productListHeaderId, sortByOptions: data.ResultObject }));
        callback?.(data.ResultObject);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(orderEntrySlice.actions.setIsLoadingSortByOptions(false));
    }
  };

/**
 * Calls and stores the result of the UpdateOrderEntryDetail API call
 *
 * @param uom - Unit of measure quantity
 * @param quantity - The quantity to set the entry detail to
 * @param product - The product being updated
 * @param index - The index for logging
 * @param fieldLevelNotificationProps - Used to generate the notification
 * @param analyticsContext - The analytics context for logging
 * @param callBack - Method to call once this method completes
 * @returns NULL
 */
export const updateOrderEntryDetailQuantity =
  (
    uom: UnitOfMeasureOrderQuantity,
    quantity: number,
    product: CatalogProduct | OrderEntryProduct,
    index: number,
    fieldLevelNotificationProps = { enabled: false, includeUOM: true },
    analyticsContext?: AnalyticsContext,
    callBack?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      if (!getState().orders.orderDetailsUpdating) {
        dispatch(ordersSlice.actions.setOrderDetailsUpdating(true));
      }

      const activeOrderId = getState().orders.activeOrder?.OrderEntryHeaderId;
      const selectedCustomer = getState().customer.selectedCustomer;

      if (!selectedCustomer) return;

      const updateOrderEntryDetailRequest: UpdateOrderEntryDetailRequest = {
        OrderEntryHeaderId: activeOrderId || '00000000-0000-0000-0000-000000000000',
        BusinessUnitKey: product.BusinessUnitKey,
        BusinessUnitERPKey: product.BusinessUnitERPKey,
        CustomerId: selectedCustomer.CustomerId,
        ProductKey: product.ProductKey,
        UnitOfMeasureType: uom.UnitOfMeasure,
        Quantity: quantity,
        Price: uom.Price || 0,
        ProductNumber: product.ProductNumber,
        ProductDescription: product.ProductDescription,
        ProductBrand: product.ProductBrand,
        ProductPackSize: uom.PackSize,
        ProductIsCatchWeight: uom.ProductIsCatchWeight,
        ProductAverageWeight: uom.ProductAverageWeight,
        ShipLaterMaxEstimatedDays: product.ShipLaterMaxEstimatedDays,
        CutoffDateTime: product.CutoffDateTime,
        UOMOrderQuantityAlertThresholdMin: uom.UOMOrderQuantityAlertThresholdMin,
        UOMOrderQuantityAlertThresholdMax: uom.UOMOrderQuantityAlertThresholdMax,
      };

      const backupProductDetailTransfer: ProductDetailTransfer = {
        productKey: product.ProductKey ?? '',
        uom: uom.UnitOfMeasure,
        extendedPrice: uom?.ExtendedPrice ?? 0,
        quantity: product.UnitOfMeasureOrderQuantities.find((x) => x.UnitOfMeasure == uom.UnitOfMeasure)?.Quantity ?? 0,
        showOrderQuantityAlert: uom.ShowOrderQuantityAlert,
      };

      const intialProductDetailTransfer: ProductDetailTransfer = {
        productKey: product.ProductKey ?? '',
        uom: uom.UnitOfMeasure,
        extendedPrice: uom?.ExtendedPrice ?? 0,
        quantity: quantity,
        showOrderQuantityAlert: uom.ShowOrderQuantityAlert,
      };
      dispatch(handleOrderDetailChanges(intialProductDetailTransfer));

      const {
        data: { IsSuccess: isSuccess, ResultObject: result, ErrorMessages: ErrorMessages },
      } = await orderEntryDetailService.updateOrderEntryDetail(updateOrderEntryDetailRequest);
      if (isSuccess) {
        dispatch(
          handleSuccessOrderDetailChange(
            product.ProductKey || '',
            uom.UnitOfMeasure,
            fieldLevelNotificationProps,
            result,
            () => {
              callBack?.();
              logOrderDetailQuantityAnalytics(analyticsContext, result, uom, index, product);
            }
          )
        );
      } else {
        dispatch(setErrorDialogContent('Error occurred', ErrorMessages));
        dispatch(handleOrderDetailChanges(backupProductDetailTransfer));
        dispatch(ordersSlice.actions.setOrderDetailsUpdating(false));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });

      const backupProductDetailTransfer: ProductDetailTransfer = {
        productKey: product.ProductKey ?? '',
        uom: uom.UnitOfMeasure,
        extendedPrice: uom?.ExtendedPrice ?? 0,
        quantity: product.UnitOfMeasureOrderQuantities.find((x) => x.UnitOfMeasure == uom.UnitOfMeasure)?.Quantity ?? 0,
        showOrderQuantityAlert: uom.ShowOrderQuantityAlert,
      };
      dispatch(handleOrderDetailChanges(backupProductDetailTransfer));
      dispatch(ordersSlice.actions.setOrderDetailsUpdating(false));
    }
  };

export const setOrderEntryProductPrices =
  (priceResults: CustomerProductPriceResult[]): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(orderEntrySlice.actions.setProductPrices(priceResults));
  };

export const getOrderEntryProductPricesByPage =
  (pageIndex: number): AppThunk =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const products = selectOrderEntryProductsByPage(getState(), pageIndex);
    dispatch(
      getCustomerProductPrices(
        getFilteredProductPriceRequests(products),
        (priceResults: CustomerProductPriceResult[]) => {
          dispatch(setOrderEntryProductPrices(priceResults));
          selectOrderEntryAnyProductsMissingExtendedPrice(getState()).forEach((data) =>
            dispatch(updateOrderEntryDetailQuantity(data.uom, data.uom.Quantity, data.product, -1))
          );
        }
      )
    );
  };

export const getOrderEntryParListDetailsByProductByPage =
  (parListHeaderId: string, pageIndex: number): AppThunk =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const products = selectOrderEntryProductsByPage(getState(), pageIndex);

    const requests: ParListProductRequest[] = products
      .map((product: OrderEntryProduct | CatalogProduct) => {
        return product.UnitOfMeasureOrderQuantities.map(
          (uom) => ({ ProductKey: product.ProductKey, UnitOfMeasureType: uom.UnitOfMeasure } as ParListProductRequest)
        );
      })
      .flat();

    dispatch(getParListDetailsByProduct(parListHeaderId, requests));
  };

export const setIsOrderEntryHeaderSubmitted =
  (submitted: boolean): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(orderEntrySlice.actions.setIsOrderEntryHeaderSubmitted(submitted));
  };

export const submitOrderEntryHeader =
  (
    resolution: Resolution,
    analyticsContext?: AnalyticsContext,
    orderEntryType?: OrderEntryType,
    successCallback?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    let isRedirecting = false;
    try {
      if (!getState().orderEntry.orderEntryHeaderSubmitting) {
        dispatch(orderEntrySlice.actions.setOrderEntryHeaderSubmitting(true));
      }
      const activeOrderId = getState().orders.activeOrder?.OrderEntryHeaderId;
      const customerId = getState().customer.selectedCustomer?.CustomerId;

      if (!activeOrderId || !customerId) return;

      const submitOrderEntryRequest: SubmitOrderEntryHeaderRequest = {
        OrderEntryHeaderId: activeOrderId,
        TimeZone: getTimeZone(),
      };

      const { data } = await orderEntryHeaderService.submitOrderEntryHeader(submitOrderEntryRequest);

      if (data.IsSuccess) {
        if (data.ResultObject.RedirectUrl) {
          isRedirecting = true;
          setTimeout(() => window.open(data.ResultObject.RedirectUrl, '_self'), 10);
          return;
        }
        if (data.ResultObject.AcceptOrder) {
          if (orderEntryType && orderEntryType === OrderEntryType.Edit) {
            dispatch(
              logUserActivity({
                action: UserActivityAction.SubmitEditOrder,
                pageName: UserActivityPageName.OrderEntry,
                actionSummary: UserActivityActionSummary.SubmittdEditOrder,
                resolution: resolution,
              })
            );
          } else {
            dispatch(
              logUserActivity({
                action: UserActivityAction.SubmitOrder,
                pageName: UserActivityPageName.OrderReview,
                actionSummary: UserActivityActionSummary.SubmittedOrder,
                resolution: resolution,
              })
            );
          }

          dispatch(
            orderConfirmationSlice.actions.setSubmittedOrderData({ CustomerId: customerId, OrderId: activeOrderId })
          );
          dispatch(getActiveOrder(customerId));
          dispatch(orderEntrySlice.actions.setIsOrderEntryHeaderSubmitted(true));

          let extendedPrice = 0;
          const orderProducts = selectAllOrderReviewProducts(getState());
          analyticsContext?.analytics?.purchase?.(
            orderProducts.flatMap((p, index) =>
              p.UnitOfMeasureOrderQuantities.map((uom) => {
                extendedPrice += uom.ExtendedPrice ?? 0;
                return getAnalyticsItemData(
                  p.ProductKey ?? '',
                  uom.UnitOfMeasure.toString(),
                  uom.Price ?? 0,
                  uom.Quantity,
                  index,
                  uom,
                  p,
                  analyticsContext.listId,
                  analyticsContext.listName
                );
              })
            ),
            {
              transactionId: activeOrderId,
              currency: 'USD',
              value: extendedPrice,
            } as PurchaseData
          );
        } else {
          dispatch(
            globalSlice.actions.setErrorDialogContent({
              title: 'Your order could not be submitted',
              messages: data.ErrorMessages,
            })
          );
        }
        if (successCallback) successCallback();
      } else {
        dispatch(
          globalSlice.actions.setErrorDialogContent({
            title: 'Your order could not be submitted',
            messages: data.ErrorMessages,
          })
        );
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      if (!isRedirecting) {
        dispatch(orderEntrySlice.actions.setOrderEntryHeaderSubmitting(false));
      }
    }
  };

/**
 * Calls the GetOrderViewers API call and upserts an app notification
 *
 * @returns NULL
 */
export const getOrderEntryViewers = (): AppThunk => async (dispatch: AppDispatch, getState) => {
  try {
    const activeOrderId = getState().orders.activeOrder?.OrderEntryHeaderId;
    if (!activeOrderId) return;

    const request: OrderViewersRequest = {
      orderEntryHeaderId: activeOrderId,
    };

    const { data } = await orderViewerService.getOrderViewers(request);

    if (data.IsSuccess) {
      const result = data.ResultObject.map((viewer) => viewer.UserDisplayName);
      if (result.length) {
        const message = result.length == 1 ? `${result[0]}` : `${result.join(', ')}`;
        dispatch(
          upsertAppNotification(
            AppNotificationsCenter.getNotificationByKey(NotificationKeys.PageLevelMessage, message),
            120
          )
        );
      } else {
        dispatch(removeAppNotificationByKey(NotificationKeys.PageLevelMessage));
      }
    }
  } catch (error: unknown) {
    appInsightsLogger.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
  }
};
/**
 * Calls the EnterOrderView API call
 *
 * @returns NULL
 */
export const enterOrderEntry = (): AppThunk => async (dispatch: AppDispatch, getState) => {
  try {
    const activeOrderId = getState().orders.activeOrder?.OrderEntryHeaderId;
    if (!activeOrderId) return;

    const request: OrderViewersRequest = {
      orderEntryHeaderId: activeOrderId,
    };

    await orderViewerService.enterOrderView(request);
  } catch (error: unknown) {
    appInsightsLogger.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
  }
};

/**
 * Calls the ExitOrderView API call
 *
 * @param allowExitingFlag - Boolean indicating if the exitingOrderView value should change
 * @returns NULL
 */
export const exitOrderEntry =
  (allowExitingFlag = true): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      const activeOrderId = getState().orders.activeOrder?.OrderEntryHeaderId;
      if (!activeOrderId) return;

      if (allowExitingFlag && !getState().orderEntry.exitingOrderView) {
        dispatch(orderEntrySlice.actions.setExitingOrderView(true));
      }

      const request: OrderViewersRequest = {
        orderEntryHeaderId: activeOrderId,
      };

      await orderViewerService.exitOrderView(request);
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(removeAppNotificationByKey(NotificationKeys.PageLevelMessage));
      dispatch(removeAppNotificationByKey(NotificationKeys.PageLevelError));
      if (allowExitingFlag) {
        dispatch(orderEntrySlice.actions.setExitingOrderView(false));
      }
    }
  };

/**
 * Calls the UpdateOrderEntryDetail API call
 *
 * @param productKey - The product key of the product being updated
 * @param unitOfMeasureType - The UOM type
 * @param uom - Unit of measure quantity
 * @param index - The index for logging
 * @param fieldLevelNotificationProps - Used to generate the notification
 * @param analyticsContext - The analytics context for logging
 * @param callBack - Method to call once this method completes
 * @returns NULL
 */
export const deleteOrderEntryDetail =
  (
    productKey: string,
    unitOfMeasureType: UnitOfMeasureType,
    uom: UnitOfMeasureOrderQuantity,
    index: number,
    fieldLevelNotificationProps = { enabled: false, includeUOM: true },
    analyticsContext?: AnalyticsContext,
    callBack?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      if (!getState().orders.orderDetailsUpdating) {
        dispatch(ordersSlice.actions.setOrderDetailsUpdating(true));
      }
      if (!getState().orders.cartLoading) {
        dispatch(ordersSlice.actions.setCartLoading(true));
      }
      const activeOrderId = getState().orders.activeOrder?.OrderEntryHeaderId;
      const selectedCustomer = getState().customer.selectedCustomer;

      if (!selectedCustomer) return;

      const updateOrderEntryDetailRequest: UpdateOrderEntryDetailRequest = {
        OrderEntryHeaderId: activeOrderId || '00000000-0000-0000-0000-000000000000',
        BusinessUnitKey: selectedCustomer.BusinessUnitKey,
        BusinessUnitERPKey: selectedCustomer.BusinessUnitERPKey,
        CustomerId: selectedCustomer.CustomerId,
        ProductKey: productKey,
        ProductDescription: '',
        ProductNumber: '',
        UnitOfMeasureType: unitOfMeasureType,
        Quantity: 0,
        Price: 0,
        ProductIsCatchWeight: false,
        ProductAverageWeight: 0,
        ShipLaterMaxEstimatedDays: 0,
        UOMOrderQuantityAlertThresholdMin: 0,
        UOMOrderQuantityAlertThresholdMax: 0,
      };

      const {
        data: { IsSuccess: isSuccess, ResultObject: result },
      } = await orderEntryDetailService.updateOrderEntryDetail(updateOrderEntryDetailRequest);

      if (isSuccess) {
        dispatch(
          handleSuccessOrderDetailChange(productKey, unitOfMeasureType, fieldLevelNotificationProps, result, () => {
            callBack?.();
            logOrderDetailQuantityAnalytics(analyticsContext, result, uom, index);
          })
        );
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(ordersSlice.actions.setCartLoading(false));
    }
  };

/**
 * Adds or removes a product from the replacementProducts value in the similarProductsSearch slice
 *
 * @param result - Contains the Product data
 * @param uom - The unit of measure
 * @param updatedQuantity - The updated quantity
 * @param callBack - Method to call once this method is done
 * @returns NULL
 */
export const handleOrderDetailChange =
  (result: ProductData, uom: UnitOfMeasureOrderQuantity, updatedQuantity: number, callBack?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    if (result && result.ProductKey) {
      const productDetailTransfer: ProductDetailTransfer = {
        productKey: result.ProductKey,
        uom: uom.UnitOfMeasure,
        extendedPrice: uom.ExtendedPrice ?? 0,
        quantity: updatedQuantity,
        showOrderQuantityAlert: false,
      };

      if (getState().router.location.pathname.includes(AppRoutes[RouteName.SimilarProductSearch].Path))
        dispatch(similarProductSearchSlice.actions.updateReplacementProduct(productDetailTransfer));

      if (callBack) callBack();
    }
  };

/**
 * Updates the products in the order entry slice, item order entry slice, order review slice, product search slice, similar product search slice.
 * Updates the product details header in the product details slice.
 * Updates the replacement products in similar products search slice.
 * Updates the comparison products in the products comparison slice.
 *
 * @param productKey - The product key of the product being updated
 * @param uomType - The UOM type
 * @param result - Contains the Product data
 * @param fieldLevelNotificationProps - Used to generate the notification
 * @param callBack - Method to call once this method completes
 * @returns NULL
 */
const handleSuccessOrderDetailChange =
  (
    productKey: string,
    uomType: UnitOfMeasureType,
    fieldLevelNotificationProps = { enabled: false, includeUOM: true },
    result: SubmitOrderEntryDetailResultDetail,
    callBack?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (fieldLevelNotificationProps.enabled) {
      const fieldId = fieldLevelNotificationProps.includeUOM ? 'quantityField' : 'quantityFieldProductLevel';
      const args = fieldLevelNotificationProps.includeUOM ? [productKey ?? '', uomType.toString()] : [productKey || ''];
      dispatch(
        upsertAppNotificationByKeyAndId(
          NotificationKeys.TextFieldNotification,
          generateStaticId(fieldId, args),
          'Quantity updated'
        )
      );
    }

    if (result && result.ProductKey) {
      const productDetailTransfer: ProductDetailTransfer = {
        productKey: result.ProductKey,
        uom: result.UnitOfMeasureType,
        extendedPrice: result.ExtendedPrice,
        quantity: result.Quantity,
        showOrderQuantityAlert: result.ShowOrderQuantityAlert,
      };
      dispatch(handleOrderDetailChanges(productDetailTransfer));

      if (callBack) callBack();
    }
    dispatch(getActiveOrder());
  };

export const handleOrderDetailChanges =
  (productDetailTransfer: ProductDetailTransfer): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(orderEntrySlice.actions.updateProduct(productDetailTransfer));
    dispatch(itemOrderEntrySlice.actions.updateProduct(productDetailTransfer));
    dispatch(orderReviewSlice.actions.updateProduct(productDetailTransfer));
    dispatch(productDetailSlice.actions.updateProductDetailExtendedPrice(productDetailTransfer));
    dispatch(productComparisonSlice.actions.updateComparisonProduct(productDetailTransfer));
    dispatch(productSearchSlice.actions.updateProduct(productDetailTransfer));
    dispatch(similarProductSearchSlice.actions.updateProduct(productDetailTransfer));
    dispatch(similarProductSearchSlice.actions.updateReplacementProduct(productDetailTransfer));
  };
