import { ClickAwayListener } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { RouteName, getRouteNameByPathname } from 'common';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLockedBody } from '../../hooks/useLockedBody';
import { CfSearchInputSize, CfSearchInputTypeahead } from '../SearchInput/CfSearchInputTypeahead';
import { CfSuggestionList } from '../SuggestionList/CfSuggestionList';
import { SuggestionItem } from '../SuggestionList/Suggestion/CfSuggestion';

/**
 * the properties which describe how the CfTypeAheadSearchInput component should present and behave
 */
export interface CfTypeAheadSearchInputProps {
  placeholder?: string;
  searchTextValue?: string;
  onSubmit?: (value: string | undefined) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  items: SuggestionItem[];
  onItemClick?: (item: SuggestionItem) => void;
  onItemRemove?: (item: SuggestionItem, value: string | undefined) => void;
  onListClose?: () => void;
  onListOpen?: () => void;
  onFocus?: (value: string) => void;
  size?: CfSearchInputSize;
  variant?: 'primary' | 'secondary';
  inputTestId?: string;
  suggestionListSx?: SxProps<Theme>;
}

/**
 * @param placeholder passed to the CfSearchInput component's placeholder property
 * @param searchTextValue passed as the default value of the CfSearchInput component;
 * @param onSubmit executed when the CfSearchInput component's onSubmit event is triggered or when the CfSuggestionList component's onItemClick event is triggered
 * @param onChange executed when the CfSearchInput component's onChange event is triggered
 * @param onClear executed when the CfSearchInput component's onClear event is triggered
 * @param items: passed to the CfSuggestionList component's items property
 * @param onItemClick executed when the CfSuggestionList component's onItemClick event is triggered
 * @param onItemRemove executed when the CfSuggestionList component's onItemRemove event is triggered
 * @param onListClose executed when the CfSuggestionList component's onListClose event is triggered
 * @param onListOpen executed when the CfSuggestionList component's isOpen property is set to true
 * @param onFocus executed when the CfSearchInput component's onFocus event is triggered
 * @param variant passed to the CfSearchInput component's variant property
 * @param suggestionListSx passed to the CfSuggestList component's suggestionListSx property
 * @param size passed to the CfSearchInput component
 * @param inputTestId passed to the CfSearchInput component's data-testId property
 */
export const CfTypeAheadSearchInput: FC<CfTypeAheadSearchInputProps> = (props: CfTypeAheadSearchInputProps) => {
  const location = useLocation();
  const routeName = getRouteNameByPathname(location.pathname);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [text, setText] = useState<string>(props.searchTextValue ?? '');
  const textRef = useRef<string>(props.searchTextValue ?? '');
  const [focusIndex, setFocusIndex] = useState<number | null>(null); // null for no focus, -1 for input focus, [0, N] for list
  const [focusElement, setFocusElement] = useState<'item' | 'action' | undefined>(undefined);

  const [, setLocked] = useLockedBody();

  const rootRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const customSetText = (value: string | undefined | null) => {
    const newValue = value || '';
    textRef.current = newValue;
    setText(newValue);
  };
  useEffect(() => {
    customSetText(props.searchTextValue);
  }, [props.searchTextValue]);

  useEffect(() => {
    if (props.items.length == 0 && focusIndex != null) setIsOpen(false); // Avoids triggering props.onClose, preventing overlay flicker
    if (props.items.length == 0 && focusIndex == null) handleOnClose();
    if (focusIndex != null) {
      handleOnOpen();
      if (focusIndex > props.items.length) setFocusIndex(-1);
    }
  }, [props.items]);

  const handleItemClick = (item: SuggestionItem) => {
    if (item) {
      customSetText(item.text);
      //handleOnClose();
      props.onItemClick?.(item);
      handleSubmit(item.text);
    }
  };

  const handleItemRemove = (item: SuggestionItem) => {
    setFocusIndex(null); // Input and list loses focus
    props.onItemRemove?.(item, text);
  };

  const handleSubmit = (text: string) => {
    if (props.onSubmit) props.onSubmit(text);
    handleOnClose();
    if (routeName === RouteName.ProductSearch || routeName === RouteName.ProductDetails) {
      handleInputFocus();
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event) customSetText(event.target.value);
    props.onChange?.(event);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    const key = event.key;

    if (key === 'ArrowDown') {
      if (focusIndex == null || (focusIndex == -1 && props.items.length > 0)) {
        setFocusIndex(0);
        handleOnOpen();
      }
    }

    if (isOpen) {
      if (key === 'ArrowDown') {
        if (focusIndex != null) {
          focusIndex < props.items.length - 1 ? setFocusIndex(focusIndex + 1) : setFocusIndex(-1);
        }
        event.preventDefault();
      } else if (key === 'ArrowUp') {
        if (focusIndex == 0) {
          setFocusIndex(null);
        } else if (focusIndex != null) {
          setFocusIndex(focusIndex - 1);
        }
        event.preventDefault();
      } else if (key === 'Enter' && focusIndex != null && focusIndex != -1 && focusIndex < props.items.length) {
        const item = props.items[focusIndex];
        focusElement === 'action' ? handleItemRemove(item) : handleItemClick(item);
      } else if (key === 'Escape') handleOnClose();
      else if (key === 'Tab') handleTab(event);
    }
  };

  const handleTab = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    event.preventDefault();
    if (focusIndex !== null) {
      if (event.shiftKey) {
        if (focusIndex === 0 && focusElement !== 'action') handleOnClose();
        else if (focusIndex === -1) focusElement === 'item' ? setFocusIndex(props.items.length - 1) : handleOnClose();
        else {
          switch (focusElement) {
            case 'action':
              setFocusElement('item');
              break;
            default:
              setFocusElement('action');
              setFocusIndex(focusIndex - 1);
          }
        }
      } else {
        if (focusIndex <= props.items.length - 1) {
          if (!props.items[focusIndex]?.isRemovable) {
            setFocusElement('item');
            setFocusIndex(focusIndex + 1);
            return;
          }
          switch (focusElement) {
            case 'item':
              setFocusElement('action');
              break;
            default:
              setFocusElement('item');
              setFocusIndex(focusIndex + 1);
          }
        } else handleOnClose();
      }
    }
  };

  const handleOnClear = (): void => {
    customSetText('');
    setFocusIndex(-1);
    props.onClear?.();
  };

  const handleOnClose = (): void => {
    setLocked(false);
    setIsOpen(false);
    setFocusIndex(null);
    props.onListClose?.();
  };

  const handleOnOpen = (): void => {
    if (props.items.length > 0) {
      setLocked(true);
      setIsOpen(true);
      props.onListOpen?.();
    }
  };

  const handleInputClick = (): void => {
    inputRef.current?.select();
  };

  const handleInputFocus = (): void => {
    setFocusIndex(-1);
    handleOnOpen();
    props.onFocus?.(textRef.current);
    inputRef.current?.select();
  };

  return (
    <ClickAwayListener onClickAway={() => handleOnClose()}>
      <div>
        <CfSearchInputTypeahead
          initialValue={text}
          placeholder={props.placeholder}
          onClick={handleInputClick}
          rootRef={rootRef}
          inputRef={inputRef}
          onClear={() => handleOnClear()}
          onKeyDown={handleKeyDown}
          onSubmit={handleSubmit}
          onChange={handleOnChange}
          onFocus={handleInputFocus}
          variant={props.variant}
          size={props.size}
          testId={props.inputTestId}
        />
        <CfSuggestionList
          anchorEl={rootRef.current}
          isOpen={isOpen}
          searchVal={text}
          items={props.items}
          currentItem={focusIndex}
          focusElement={focusElement}
          onItemClick={handleItemClick}
          onItemRemove={handleItemRemove}
          suggestionListSx={props.suggestionListSx}
        />
      </div>
    </ClickAwayListener>
  );
};
