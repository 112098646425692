import {
  InvoiceReportTrendType,
  SearchCriteriaData,
  SaveSearchCriteriaRequest,
  SearchGroupBy,
  CriteriaGroupBy,
  TemplateSelectableOptions,
} from '../../api/models/invoice-report.models';

export const getDefaultSaveSearchCriteriaRequest = (): SaveSearchCriteriaRequest => {
  return {
    SearchHeaderId: undefined,
    CustomerIds: [],
    StartDate: '',
    EndDate: '',
    TrendType: InvoiceReportTrendType.Summary,
    CompareStartDate: undefined,
    CompareEndDate: undefined,
    ShowCatchWeight: false,
    ShowDollars: false,
    ShowNetWeight: false,
    ShowQuantity: true,
    Name: '',
    SearchName: '',
    SearchDescription: '',
    InvoiceReportSearchDirectoryId: undefined,
  };
};

export const mapSearchCriteriaToSaveSearchCriteriaRequest = (
  criteria: SearchCriteriaData
): SaveSearchCriteriaRequest => {
  const request: SaveSearchCriteriaRequest = {
    ...getDefaultSaveSearchCriteriaRequest(),
    SearchHeaderId: criteria.InvoiceReportSearchHeaderId,
    StartDate: criteria.StartDate,
    EndDate: criteria.EndDate,
    TrendType: criteria.TrendType,
    CompareStartDate: criteria.CompareStartDate,
    CompareEndDate: criteria.CompareEndDate,
    ShowCatchWeight: criteria.ShowCatchWeight,
    ShowDollars: criteria.ShowDollars,
    ShowNetWeight: criteria.ShowNetWeight,
    ShowQuantity: criteria.ShowQuantity,
    SearchName: criteria.SearchName ?? '',
    SearchDescription: criteria.SearchDescription,
    InvoiceReportSearchDirectoryId: criteria.SearchDirectoryId,
    IsFavorite: criteria.IsFavorite,
  };

  return request;
};

export const mapSearchGroupByToCriteriaGroupBy = (
  searchGroupBy: SearchGroupBy,
  customerFilterDisplay?: string
): CriteriaGroupBy => {
  const item: CriteriaGroupBy = {
    ...searchGroupBy,
    CanUpdateCriteria: true,
    FilterDisplay: undefined,
  };

  if (searchGroupBy.GroupByType === 0 && customerFilterDisplay) {
    item.FilterDisplay = customerFilterDisplay;
  }

  return item;
};

export class TemplateSelectableOptionsLookup {
  public static TemplateSelectableOptions: {
    [key: number]: TemplateSelectableOptions;
  } = {
    0: {
      dateSelectType: false,
      dates: true,
      trendTypes: false,
      customers: true,
      quantity: false,
      dollars: false,
      weight: false,
      canSortFilter: false,
      canModifyGroups: false,
      canRemoveGroups: false,
      canFilter: true,
    },
    1: {
      dateSelectType: false,
      dates: true,
      trendTypes: false,
      customers: true,
      quantity: false,
      dollars: false,
      weight: false,
      canSortFilter: false,
      canModifyGroups: false,
      canRemoveGroups: false,
      canFilter: true,
    },
    2: {
      dateSelectType: false,
      dates: true,
      trendTypes: false,
      customers: true,
      quantity: false,
      dollars: false,
      weight: false,
      canSortFilter: false,
      canModifyGroups: false,
      canRemoveGroups: false,
      canFilter: true,
    },
    3: {
      dateSelectType: false,
      dates: true,
      trendTypes: false,
      customers: true,
      quantity: false,
      dollars: false,
      weight: false,
      canSortFilter: false,
      canModifyGroups: false,
      canRemoveGroups: false,
      canFilter: true,
    },
  };
}

export const getNonTemplateSelectableOptions = (): TemplateSelectableOptions => {
  return {
    dateSelectType: true,
    dates: true,
    trendTypes: true,
    customers: true,
    quantity: true,
    dollars: true,
    weight: true,
    canSortFilter: true,
    canModifyGroups: true,
    canRemoveGroups: true,
    canFilter: true,
  };
};
