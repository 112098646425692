import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { BusinessUnitKeyType, RootState, useAppSelector } from 'common';
import { LocationDescriptor } from 'history';
import { Link, useHistory } from 'react-router-dom';
import { DeviceType, useBreakpoint } from '../../../../../hooks/useBreakpoint';

export type LogoSize = 'small' | 'medium';

export type LogoProps = {
  dataTestId?: string;
  routeTo?: LocationDescriptor<unknown>;
  sx?: SxProps<Theme>;
  logoAlternative?: string;
};

const Logo = (props: LogoProps): JSX.Element => {
  const { deviceType } = useBreakpoint();
  const businessUnitKey = useAppSelector((s: RootState) => s.user.userSite?.BusinessUnitKey);
  const logoUrl = useAppSelector((s: RootState) => s.user.userSite?.LogoURL);
  const history = useHistory();
  if (!logoUrl && !props.logoAlternative) return <></>;

  const selectSrc = () => {
    if (history.location.pathname.toLowerCase().includes('/getstarted') || !logoUrl) return props.logoAlternative;
    return logoUrl;
  };

  const logoHeight =
    businessUnitKey === BusinessUnitKeyType.Vistar
      ? deviceType === DeviceType.Tablet
        ? 40
        : deviceType === DeviceType.Mobile
        ? 39
        : '100%'
      : '100%';

  const imageComponent = (
    <Box
      component='img'
      src={selectSrc()}
      alt='Business Unit Logo'
      data-testid={props.dataTestId}
      sx={{
        width: '100%',
        height: logoHeight,
        objectFit: 'contain',
        ...props.sx,
      }}
    />
  );

  if (props.routeTo)
    return (
      <Link to={props.routeTo} data-testid={props.dataTestId} style={{ height: 'inherit' }}>
        {imageComponent}
      </Link>
    );

  return <>{imageComponent}</>;
};

export default Logo;
