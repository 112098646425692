import { AccountInfo, EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider, Theme } from '@mui/material/styles';
import '@mui/styles';
import StylesProvider from '@mui/styles/StylesProvider';
import {
  AppNotificationsCenter,
  AppRoute,
  AppRoutes,
  BusinessUnitKeyType,
  RootState,
  RouteName,
  UserActivityAction,
  UserActivityActionSummary,
  UserActivityPageName,
  UserSite,
  getBuildInfo,
  getCurrentUserSite,
  getSHA1HashOfString,
  getTimestampDisplay,
  hasUserAcceptedEssentialTerms,
  logUserActivity,
  toggleCustomerLocationToast,
  toggleReviewProfileDialog,
  useAppDispatch,
  useAppSelector,
} from 'common';
import { Location } from 'history';
import { Suspense, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppContent from './AppContent';
import { AppErrorBoundary } from './AppErrorBoundary';
import { CustomGlobalStyles } from './CustomGlobalStyles';
import { CfPageLoader } from './cf-ui/Loader/PageLoader/CfPageLoader';
import { IdleTimeoutDialog } from './features/site/components/dialogs/IdleTimeoutDialog';
import GrowlerNotification from './features/site/components/notifications/GrowlerNotification/GrowlerNotification';
import { getTheme } from './theme/theme';
import { LocalStorageKeys, LocalStorageService } from './utilities/local-storage-service';
import { getResolution } from './utilities/resolution';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function App(): JSX.Element {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const localStorageService = new LocalStorageService();

  const userSite = useAppSelector((s: RootState) => s.user.userSite);
  const [theme, setTheme] = useState<Theme | undefined>(undefined);
  const firstLoadRef = useRef(true);
  const firstLoginRef = useRef(false);

  const { instance } = useMsal();

  const { buildTimestamp, gitSha, gitBranch } = getBuildInfo();

  useEffect(() => {
    console.info(
      `
      ${'-'.repeat(50)}
      Built on ${getTimestampDisplay(buildTimestamp)}
      Commit ${gitSha}${gitBranch ? ' (' + gitBranch + ')' : ''}
      ${'-'.repeat(50)}`
        .replace(/  +/g, '')
        .trim()
    );
  }, [buildTimestamp, gitSha, buildTimestamp]);

  useEffect(() => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const callbackId = instance.addEventCallback((message: any) => {
      if (message.eventType === EventType.HANDLE_REDIRECT_END) {
        firstLoginRef.current = true;
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  useEffect(() => {
    if (userSite && firstLoadRef.current) {
      firstLoadRef.current = false;
      if (userSite.IsPastProfileReviewDate) {
        dispatch(toggleReviewProfileDialog({ display: true }));
      }
      // If more than 1 Customer, show Customer Toast
      if (userSite.UserCustomers.length > 1) {
        dispatch(toggleCustomerLocationToast({ display: true }));
      }
    }
  }, [userSite]);

  useEffect(() => {
    (async () => {
      const userSiteResponse: UserSite | undefined = await dispatch(getCurrentUserSite({ fetchSettings: true }));

      if (userSiteResponse) {
        if (firstLoginRef.current) {
          firstLoginRef.current = false;
          dispatch(
            logUserActivity({
              action: UserActivityAction.Login,
              pageName: UserActivityPageName.Login,
              actionSummary: UserActivityActionSummary.SuccessfullyLoggedIn,
              resolution: getResolution(),
            })
          );
        }
      } else {
        setTheme(getTheme(BusinessUnitKeyType.PerformanceFoodService));
        invalidUserSite();
      }
    })();

    AppNotificationsCenter.init();

    history.listen((location: Location<unknown>) => {
      //This line is needed to prevent double execution of loguseractivity on push and replace.
      if (history.action == 'REPLACE') return;

      const path = history.location.pathname.toLocaleLowerCase();

      const appRoutes = Object.values(AppRoutes);
      const routeFromList = appRoutes
        .filter((appRoute: AppRoute) => path.includes(appRoute.Path.toLocaleLowerCase()))
        .sort((a, b) => b.Path.length - a.Path.length);

      const routeFrom = routeFromList.length > 0 ? routeFromList[0] : undefined;

      if (!routeFrom?.UserActivityPageName) return;

      const pageName = routeFrom.UserActivityPageName;

      if (pageName === UserActivityPageName.Home) return;

      let summary: UserActivityActionSummary | undefined = undefined;
      switch (pageName) {
        case UserActivityPageName.Invoices:
          summary = UserActivityActionSummary.VisitedInvoices;
          break;
        case UserActivityPageName.OrderEntry:
          if (location.search.includes('itemOrderEntry=true')) {
            summary = UserActivityActionSummary.VisitedItemOrder;
          } else {
            summary = UserActivityActionSummary.VisitedOrderEntry;
          }
          break;
        case UserActivityPageName.OrderReview:
          summary = UserActivityActionSummary.VisitedOrderReview;
          break;
        case UserActivityPageName.ProductDetails:
          summary = UserActivityActionSummary.VisitedProductDetails;
          break;
        case UserActivityPageName.OrderHistory:
          summary = UserActivityActionSummary.VisitedOrderHistory;
          break;
        case UserActivityPageName.RepeatOrder:
          summary = UserActivityActionSummary.VisitedRepeatOrder;
          break;
        case UserActivityPageName.Search:
          summary = UserActivityActionSummary.VisitedSearch;
          break;
        case UserActivityPageName.OrderConfirmation:
          summary = UserActivityActionSummary.VisitedOrderConfirmation;
          break;
        case UserActivityPageName.UserProfile:
          summary = UserActivityActionSummary.VisitedUserProfile;
          break;
        case UserActivityPageName.AccountsReceivableStatements:
          summary = UserActivityActionSummary.VisitedAccountsReceivableStatements;
          break;
        case UserActivityPageName.OrderImport:
          summary = UserActivityActionSummary.VisitedOrderImport;
          break;
        case UserActivityPageName.ListNotification:
          summary = UserActivityActionSummary.VisitedListNotification;
          break;
        case UserActivityPageName.FindSimilarSearch:
          summary = UserActivityActionSummary.VisitedFindSimilarSearch;
          break;
        case UserActivityPageName.DraftMessages:
          summary = UserActivityActionSummary.VisitedMessageDraft;
          break;
        case UserActivityPageName.SentMessages:
          summary = UserActivityActionSummary.VisitedMessageSent;
          break;
        case UserActivityPageName.InboxMessages:
          summary = UserActivityActionSummary.VisitedMessageInbox;
          break;
        case UserActivityPageName.MessageGroups:
          summary = UserActivityActionSummary.VisitedMessageGroups;
          break;
        case UserActivityPageName.CustomerSupportAccount:
          summary = UserActivityActionSummary.VisitedCustomerSupportAccount;
          break;
        case UserActivityPageName.CustomerSupportInvoices:
          summary = UserActivityActionSummary.VisitedCustomerSupportInvoices;
          break;
        case UserActivityPageName.CustomerSupportOrdering:
          summary = UserActivityActionSummary.VisitedCustomerSupportOrdering;
          break;
        case UserActivityPageName.CustomerSupportListManagement:
          summary = UserActivityActionSummary.VisitedCustomerSupportListManagement;
          break;
        case UserActivityPageName.CustomerSupportParManagement:
          summary = UserActivityActionSummary.VisitedCustomerSupportParManagement;
          break;
        case UserActivityPageName.ListDetailSearch:
          summary = UserActivityActionSummary.VisitedListDetailSearch;
          break;
        case UserActivityPageName.CustomerGroups:
          summary = UserActivityActionSummary.VisitedCustomerGroups;
          break;
        case UserActivityPageName.ViewCustomerGroup:
          summary = UserActivityActionSummary.VisitedCustomerGroups;
          break;
        default:
          break;
      }

      if (!summary) return;

      dispatch(
        logUserActivity({
          action: UserActivityAction.Visit,
          pageName: pageName,
          actionSummary: summary,
          resolution: getResolution(),
        })
      );
    });
  }, []);

  useEffect(() => {
    const emailID = getCurrentAuthenticatedUserEmailID();
    const emailIDHash = getSHA1HashOfString(emailID);
    const validUserEmailIDHash = localStorageService.getItem(LocalStorageKeys.KEY_VALID_USER_EMAIL_ID);
    if (validUserEmailIDHash !== null && validUserEmailIDHash !== undefined) {
      // check if different from the current logged in user
      if (emailID !== '' && emailIDHash !== validUserEmailIDHash) {
        // set a flag to trigger sign out if any other email address found in the user tokens
        localStorageService.setItem(LocalStorageKeys.KEY_INVALID_USER_EMAIL_ID, validUserEmailIDHash); // invalidate the currently valid one
        localStorageService.setItem(LocalStorageKeys.KEY_VALID_USER_EMAIL_ID, getSHA1HashOfString(emailID)); // mark the new one as the valid email
      }
    } else if (emailID !== '') {
      localStorageService.setItem(LocalStorageKeys.KEY_VALID_USER_EMAIL_ID, getSHA1HashOfString(emailID));
    }

    if (userSite) {
      // Set Theme & Business Unit Links based upon the Business Unit Key
      setTheme(getTheme(userSite.BusinessUnitKey));

      // Sanity check, prevent TOS redirection when no customers
      if (!userSite.IsActive || !userSite.UserCustomers.length) {
        invalidUserSite();
        return;
      }

      // Redirect user based on accepted terms
      if (hasUserAcceptedEssentialTerms(userSite)) {
        const path = history.location.pathname;
        if (path === AppRoutes[RouteName.TermsOfService].Path) {
          history.push(AppRoutes[RouteName.Home].Path);
        }
      } else if (!userSite.HasAcceptedPrivacyPolicy || !userSite.HasAcceptedTermsOfUse) {
        history.replace(AppRoutes[RouteName.TermsOfService].Path);
      } else if (!userSite.HasAcceptedCookies) {
        history.replace(AppRoutes[RouteName.CookieAcceptance].Path);
      }
    }
  }, [
    userSite?.BusinessUnitKey,
    userSite?.UserCustomers,
    userSite?.HasAcceptedPrivacyPolicy,
    userSite?.HasAcceptedTermsOfUse,
  ]);

  const invalidUserSite = () => history.replace(AppRoutes[RouteName.NoUserInformation].Path);

  const getCurrentAuthenticatedUserEmailID = (): string => {
    let emailID = '';
    const accounts: AccountInfo[] = instance.getAllAccounts();
    const homeAccountId = accounts.length > 0 ? accounts[0]?.homeAccountId : '';
    const accountInfo = instance.getAccountByHomeId(homeAccountId);
    if (accountInfo !== undefined && accountInfo !== null) {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      const idTokenClaims: any = accountInfo.idTokenClaims;
      if (idTokenClaims !== undefined) {
        emailID = idTokenClaims.sub;
      }
    }
    return emailID;
  };

  return (
    <StylesProvider injectFirst>
      {theme && (
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <CustomGlobalStyles />
            <IdleTimeoutDialog />
            <GrowlerNotification />
            <Suspense fallback={<CfPageLoader isLoading />}>
              <AppErrorBoundary>
                <AppContent />
              </AppErrorBoundary>
            </Suspense>
          </MuiThemeProvider>
        </StyledEngineProvider>
      )}
    </StylesProvider>
  );
}

export default App;
