import { Badge, Box, Icon, Slide, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';

export interface CartIconProps {
  badgeContent: number;
}

export const CartIcon: FC<CartIconProps> = (props: CartIconProps) => {
  const [addFromEmpty, setAddFromEmpty] = useState(false);
  const [showValue, setShowValue] = useState(false);
  const [currentCount, setCurrentCount] = useState(0);

  useEffect(() => {
    setAddFromEmpty(false);
    setShowValue(false);
    if (currentCount === 0 && props.badgeContent > 0) {
      setAddFromEmpty(true);
      setCurrentCount(props.badgeContent);
    }
  }, [props.badgeContent]);

  const badgeContentSlide = (
    <>
      {addFromEmpty || showValue ? (
        <Typography variant='paragraphSmallBold'>{props.badgeContent >= 100 ? '99+' : props.badgeContent}</Typography>
      ) : (
        <Slide
          direction='down'
          in={props.badgeContent > 0 && props.badgeContent !== currentCount}
          appear
          timeout={500}
          easing={{ enter: 'cubic-bezier(.02, 1.94, .76, .76)' }}
          onEntered={() => {
            setCurrentCount(props.badgeContent);
          }}
          onExiting={() => {
            setShowValue(true);
          }}
          mountOnEnter
          unmountOnExit
        >
          <Typography variant='paragraphSmallBold'>{props.badgeContent >= 100 ? '99+' : props.badgeContent}</Typography>
        </Slide>
      )}
    </>
  );
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Icon className='fa-shopping-cart' fontSize='medium' />
      <Slide direction='down' in={props.badgeContent > 0} onExiting={() => setCurrentCount(props.badgeContent)}>
        <Badge badgeContent={badgeContentSlide} color='error'>
          <Box sx={{ height: (theme) => theme.spacing(2.625) }} />
        </Badge>
      </Slide>
    </Box>
  );
};
