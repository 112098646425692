import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { History } from 'history';
import { FileResult } from '../../api';
import {
  BaseSearchRequest,
  GetInvoiceInsightsReportExcelRequest,
  GetInvoiceInsightsReportPDFRequest,
  GetSearchResultsRequest,
  SearchDrilldownRequest,
  SetSearchAsFavoriteRequest,
} from '../../api/models/invoice-report.models';
import InvoiceInsightsReportService from '../../api/services/invoice-insights-report.service';
import InvoiceReportSearchService from '../../api/services/invoice-report-search.service';
import { getQueryParamsString } from '../../helpers';
import { getInsightsReportURL } from '../../helpers/general/routing';
import { validateStringEmptyOrMaxLength } from '../../helpers/validation/general';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { InsightsReportQueryParams } from '../../models/finances-routing.models';
import { globalSlice } from '../common/global.slice';
import { AppDispatch, AppThunk, RootState } from '../store';
import { insightsReportSlice } from './insights-report.slice';
import { insightsSlice } from './insights.slice';

const invoiceReportSearchService = InvoiceReportSearchService.getInstance();
const invoiceInsightsReportService = InvoiceInsightsReportService.getInstance();

const appInsightsLogger = useAppInsightsLogger();

/**
 * Resets the values in the insights-report slice to their initial values
 *
 * @returns NULL
 */
export const resetInsightsReportState = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(insightsReportSlice.actions.resetState());
};

/**
 * Calls and stores the results of the SearchDrilldown API call
 *
 * @param request - The request being sent to the API call
 * @param history - Used to take the user to the insights report page
 * @returns NULL
 */
export const getInvoiceReportSearchDrilldown =
  (request: SearchDrilldownRequest, history: History): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(insightsReportSlice.actions.resetSearchResults());
      dispatch(insightsReportSlice.actions.setSearchResultsIsLoading(true));

      const { data } = await invoiceReportSearchService.searchDrilldown(request);
      if (data.IsSuccess) {
        dispatch(insightsReportSlice.actions.setSearchResults(data.ResultObject));
        history.push(getInsightsReportURL(data.ResultObject.SearchHeaderId, data.ResultObject.SortByKpiType));
      } else {
        dispatch(
          globalSlice.actions.setErrorDialogContent({
            messages: data.ErrorMessages,
          })
        );
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(insightsReportSlice.actions.setSearchResultsIsLoading(false));
    }
  };

/**
 * Calls and stores the results of the GetSearchResults API call
 *
 * @param request - The request being sent to the API call
 * @param history - Used to take the user to the insights report page
 * @returns NULL
 */
export const getInvoiceReportSearchResults =
  (request: GetSearchResultsRequest, history: History): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (
        request.SearchHeaderId === getState().insightsReport.searchResults?.SearchHeaderId &&
        request.SortByKPIType === getState().insightsReport.searchResults?.SortByKpiType &&
        request.SortByOrderType === getState().insightsReport.searchResults?.SortByOrderType
      )
        return;

      dispatch(getSearchCriteriaGroupByCount({ searchHeaderId: request.SearchHeaderId }));

      dispatch(insightsReportSlice.actions.resetSearchResults());
      dispatch(insightsReportSlice.actions.setSearchResultsIsLoading(true));

      const { data } = await invoiceReportSearchService.getSearchResults(request);
      if (data.IsSuccess) {
        dispatch(insightsReportSlice.actions.setSearchResults(data.ResultObject));

        const newSort = data.ResultObject.SortByKpiType;
        if (request.SortByKPIType === undefined) {
          const newQueryParams: InsightsReportQueryParams = {
            sort: newSort.toString(),
          };
          history.replace(history.location.pathname + getQueryParamsString(newQueryParams));
        }
      } else {
        dispatch(
          globalSlice.actions.setErrorDialogContent({
            messages: data.ErrorMessages,
          })
        );
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(insightsReportSlice.actions.setSearchResultsIsLoading(false));
    }
  };

/**
 * Sets the isCollapsed properties of the search result rows
 *
 * @param id - The id of the search row
 * @param isCollapsed - The vlaue to set the isCollapsed property to
 * @returns NULL
 */
export const expandCollapseInvoiceReportResultRow =
  (id: string, isCollapsed: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(insightsReportSlice.actions.expandCollapseInvoiceReportResultRow({ id, isCollapsed }));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sets all the isCollapsed properties of the search result rows to true
 *
 * @param isCollapsed - The vlaue to set the isCollapsed property to
 * @returns NULL
 */
export const expandCollapseAllInvoiceReportResultRows =
  (isCollapsed: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(insightsReportSlice.actions.expandCollapseAllInvoiceReportResultRows(isCollapsed));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

export interface SetSearchAsFavoriteValidationResult {
  searchNameErrors: string[];
  newFolderNameErrors: string[];
}

/**
 * Validates search names and the new folder namnes
 *
 * @param request - The request containing the information to validate
 * @returns The search name errors and new folder name errors
 */
export const validateSetSearchAsFavorite = (
  request: SetSearchAsFavoriteRequest
): SetSearchAsFavoriteValidationResult => {
  return {
    searchNameErrors: validateStringEmptyOrMaxLength('Name', request.SearchName, 100),
    newFolderNameErrors: request.AddNewDirectory
      ? validateStringEmptyOrMaxLength('Folder name', request.NewDirectoryName, 100)
      : [],
  };
};

/**
 * Calls the SetSearchAsFavorite API call
 *
 * @param request - The request containing the information to validate
 * @param successCallback - Method to call on success
 * @param errorCallback - Method to call on error
 * @returns NULL
 */
export const setSearchAsFavorite =
  (
    request: SetSearchAsFavoriteRequest,
    successCallback?: () => void | Promise<void>,
    errorCallback?: (errors: SetSearchAsFavoriteValidationResult) => void | Promise<void>
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
      const validationResult = validateSetSearchAsFavorite(request);
      if (validationResult.searchNameErrors.length > 0 || validationResult.newFolderNameErrors.length > 0) {
        errorCallback?.(validationResult);
        return;
      }

      const { data } = await invoiceReportSearchService.setSearchAsFavorite(request);
      const name = request.EditOriginalSearch
        ? request.SearchName ?? ''
        : getState().insightsReport.searchResults?.SearchName ?? '';

      if (data.IsSuccess) {
        dispatch(
          insightsSlice.actions.setSearchAsFavorite({
            SearchName: name,
            SearchDirectoryId: request.SearchDirectoryId ?? '',
          })
        );
        dispatch(
          insightsReportSlice.actions.setSearchAsFavorite({
            SearchName: name,
            SearchDirectoryId: request.SearchDirectoryId ?? '',
          })
        );

        successCallback?.();
      } else {
        // Errors will be shown in a separate dialog, and no clean way to detect which input each error is for anyway
        errorCallback?.({ searchNameErrors: [], newFolderNameErrors: [] });
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls and and stores result data of GetSearchCriteriaGroupBys API call
 *
 * @param request - The request containing the information to validate
 * @returns NULL
 */
export const getSearchCriteriaGroupByCount =
  (request: BaseSearchRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await invoiceReportSearchService.getSearchCriteriaGroupBys(request);
      if (data.IsSuccess)
        dispatch(insightsReportSlice.actions.setSearchCriteriaGroupByCount(data.ResultObject.GroupBys.length));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls and stores the result of the GetInvoiceInsightsReportExcel API call
 *
 * @param request - The request containing the information to validate
 * @param successCallback - Method to call on success
 * @param errorCallback - Method to call on error
 * @returns NULL
 */
export const getInvoiceInsightsReportExcel =
  (
    request: GetInvoiceInsightsReportExcelRequest,
    onSuccess?: (result: FileResult) => void,
    onFailure?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(insightsReportSlice.actions.setReportFileIsLoading(true));
      const { data } = await invoiceInsightsReportService.getInvoiceInsightsReportExcel(request);
      if (data.IsSuccess) onSuccess?.(data.ResultObject);
      else onFailure?.();
    } catch (error) {
      onFailure?.();
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(insightsReportSlice.actions.setReportFileIsLoading(false));
    }
  };

/**
 * Calls the GetInvoiceInsightsPDFReport API calls
 *
 * @param request - The request containing the information to validate
 * @param successCallback - Method to call on success
 * @param errorCallback - Method to call on error
 * @returns NULL
 */
export const getInvoiceInsightsReportPDF =
  (
    request: GetInvoiceInsightsReportPDFRequest,
    onSuccess?: (result: FileResult | undefined) => void,
    onFailure?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(insightsReportSlice.actions.setReportFileIsLoading(true));
      const { data } = await invoiceInsightsReportService.getInvoiceInsightsReportPDF(request);
      if (data.IsSuccess) onSuccess?.(data.ResultObject);
      else onFailure?.();
    } catch (error) {
      onFailure?.();
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(insightsReportSlice.actions.setReportFileIsLoading(false));
    }
  };
