import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';
import { MessageRecipientType } from '../../api/models/message.enums';
import {
  CreateDraftMessageRequest,
  Message,
  MessageAttachment,
  MessageRecipient,
  SendMessageRequest,
  UpdateDraftMessageRequest,
} from '../../api/models/message.models';
import { arraysEqual, getSortComparer } from '../../helpers/general/array';
import { normalizeKey } from '../../helpers/general/string';
import { RootState } from '../store';

const messageRecipientsAdapter = createEntityAdapter<MessageRecipient>({
  selectId: (recipient: MessageRecipient) => normalizeKey(recipient.MessageRecipientId).toLowerCase(),
  sortComparer: getSortComparer(
    (recipient) => -recipient.MessageRecipientType + 3 + recipient.MessageRecipientNameDisplay
  ),
});

interface OriginalMessage {
  Recipients: MessageRecipient[];
  Subject: string;
  IsHighPriority: boolean;
  Body: string;
  Attachments: MessageAttachment[];
}

interface ValidationMessages {
  recipients: string[];
  subject: string[];
  body: string[];
}
interface MessageCreateState {
  messageId: string | undefined;
  messageLoading: boolean;
  recipients: EntityState<MessageRecipient>;
  subject: string;
  isHighPriority: boolean;
  body: string;
  attachments: MessageAttachment[];
  originalMessage: OriginalMessage;
  validationMessages: ValidationMessages;
  showAllRecipients: boolean;
  sendMessageApiRequest: SendMessageRequest | undefined;
  saveMessageDraftApiRequest: CreateDraftMessageRequest | UpdateDraftMessageRequest | undefined;
}

const initialState: MessageCreateState = {
  messageId: undefined,
  messageLoading: false,
  recipients: messageRecipientsAdapter.getInitialState(),
  subject: '',
  isHighPriority: false,
  body: '',
  attachments: [],
  originalMessage: {
    Recipients: [],
    Subject: '',
    IsHighPriority: false,
    Body: '',
    Attachments: [],
  },
  validationMessages: {
    recipients: [],
    subject: [],
    body: [],
  },
  showAllRecipients: false,
  sendMessageApiRequest: undefined,
  saveMessageDraftApiRequest: undefined,
};

export const messageCreateSlice = createSlice({
  name: 'messageCreate',
  initialState,
  reducers: {
    resetState: () => {
      selectMessageCreateRecipientsByMessageRecipientType.clearCache();
      return initialState;
    },
    setMessageSubject: (state: MessageCreateState, action: PayloadAction<string>) => {
      state.subject = action.payload;
    },
    setMessageIsHighPriority: (state: MessageCreateState, action: PayloadAction<boolean>) => {
      state.isHighPriority = action.payload;
    },
    setMessageBody: (state: MessageCreateState, action: PayloadAction<string>) => {
      state.body = action.payload;
    },
    setOriginalMessage: (state: MessageCreateState, action: PayloadAction<OriginalMessage>) => {
      state.originalMessage = action.payload;
    },
    setOriginalMessageBody: (state: MessageCreateState, action: PayloadAction<string>) => {
      state.originalMessage.Body = action.payload;
    },
    setMessageRecipients: (
      state: MessageCreateState,
      action: PayloadAction<{
        recipients: MessageRecipient[];
        setOnlyRecipientsOfType?: MessageRecipientType;
      }>
    ) => {
      const { recipients, setOnlyRecipientsOfType } = action.payload;
      messageRecipientsAdapter.setAll(
        state.recipients,
        recipients
          .concat(
            selectAllRecipientsLocal(state).filter(
              (r) => setOnlyRecipientsOfType !== undefined && r.MessageRecipientType !== setOnlyRecipientsOfType
            )
          )
          .map((r) => ({ ...r, ObjectValue: r.ObjectValue.toLowerCase() }))
      );
    },
    removeMessageRecipientById: (state: MessageCreateState, action: PayloadAction<string>) => {
      messageRecipientsAdapter.removeOne(state.recipients, action.payload);
    },
    setValidationMessages: (state: MessageCreateState, action: PayloadAction<ValidationMessages>) => {
      state.validationMessages = action.payload;
    },
    setShowAllRecipients: (state: MessageCreateState, action: PayloadAction<boolean>) => {
      state.showAllRecipients = action.payload;
    },
    setSendMessageApiRequest: (state: MessageCreateState, action: PayloadAction<SendMessageRequest | undefined>) => {
      state.sendMessageApiRequest = action.payload;
    },
    setSaveMessageDraftApiRequest: (
      state: MessageCreateState,
      action: PayloadAction<CreateDraftMessageRequest | UpdateDraftMessageRequest | undefined>
    ) => {
      state.saveMessageDraftApiRequest = action.payload;
    },
    setMessageLoading: (state: MessageCreateState, action: PayloadAction<boolean>) => {
      state.messageLoading = action.payload;
    },
    setMessage: (state: MessageCreateState, action: PayloadAction<Message>) => {
      const message = action.payload;
      state.originalMessage = message;

      state.messageId = message.MessageId;
      state.messageLoading = false;

      messageRecipientsAdapter.setAll(
        state.recipients,
        message.Recipients.map((r) => ({ ...r, ObjectValue: r.ObjectValue.toLowerCase() }))
      );
      state.subject = message.Subject;
      state.isHighPriority = message.IsHighPriority;
      state.body = message.Body;
      state.attachments = message.Attachments;
      state.validationMessages = {
        recipients: [],
        subject: [],
        body: [],
      };
      state.showAllRecipients = false;
      state.sendMessageApiRequest = undefined;
      state.saveMessageDraftApiRequest = undefined;
    },
    addAttachment: (state: MessageCreateState, action: PayloadAction<MessageAttachment>) => {
      state.attachments.push(action.payload);
    },
    removeAttachment: (state: MessageCreateState, action: PayloadAction<string>) => {
      state.attachments = state.attachments.filter(
        (a) => a.MessageAttachmentId?.toLowerCase() !== action.payload.toLowerCase()
      );
    },
  },
});

export const {
  selectAll: selectAllMessageRecipients,
  selectById: selectMessageRecipientById,
  selectIds: selectAllMessageRecipientIds,
} = messageRecipientsAdapter.getSelectors<EntityState<MessageRecipient>>(
  (recipients: EntityState<MessageRecipient>) => recipients
);

export const selectMessageCreateRecipientsByMessageRecipientType = createCachedSelector(
  (state: RootState) => state.messageCreate.recipients,
  (_state: RootState, type: MessageRecipientType) => type,
  (recipients: EntityState<MessageRecipient>, type: MessageRecipientType) => {
    return selectAllMessageRecipients(recipients).filter((r) => r.MessageRecipientType === type);
  }
)({
  keySelector: (_state: RootState, type: MessageRecipientType) => type,
});

const { selectAll: selectAllRecipientsLocal } = messageRecipientsAdapter.getSelectors<MessageCreateState>(
  (state: MessageCreateState) => state.recipients
);

export const selectAllMessageCreateRecipientsKeyedByObjectValue = createSelector(
  (state: RootState) => state.messageCreate.recipients,
  (recipients) => Object.fromEntries(selectAllMessageRecipients(recipients).map((r) => [r.ObjectValue, r]))
);

export const selectCanSaveMessageDraft = createSelector(
  (state: RootState) => state.messageCreate,
  (state) =>
    selectDoesMessageHaveUnsavedChangesInternal(state) &&
    state.validationMessages.body.length === 0 &&
    state.validationMessages.subject.length === 0
);

export const selectMessageAttachmentIdsToRemove = createSelector(
  (state: RootState) => state.messageCreate,
  (messageCreate) => {
    const attachmentIds = new Set(
      messageCreate.attachments.filter((a) => a.MessageAttachmentId).map((a) => a.MessageAttachmentId)
    );

    return messageCreate.originalMessage.Attachments.filter(
      (a) => a.MessageAttachmentId && !attachmentIds.has(a.MessageAttachmentId)
    ).map((a) => a.MessageAttachmentId!);
  }
);

export const selectCanSendMessage = createSelector(
  (state: RootState) => state.messageCreate.validationMessages,
  (validationMessages) =>
    validationMessages.body.length === 0 &&
    validationMessages.recipients.length === 0 &&
    validationMessages.subject.length === 0
);

const selectDoesMessageHaveUnsavedChangesInternal = (state: MessageCreateState) => {
  const unchanged =
    state.messageLoading ||
    (state.subject === (state.originalMessage?.Subject ?? '') && // subject
      (state.body ?? '') === (state.originalMessage?.Body ?? '') && // body
      arraysEqual(
        // recipients
        selectAllMessageRecipients(state.recipients)
          .map((r) => r.ObjectValue.toLowerCase())
          .sort(),
        state.originalMessage?.Recipients.map((r) => r.ObjectValue.toLowerCase()).sort() || []
      ) &&
      arraysEqual(
        // attachments
        state.attachments.map((a) => a.MessageAttachmentId).sort(),
        state.originalMessage?.Attachments.map((a) => a.MessageAttachmentId).sort() || []
      ));

  return !unchanged;
};
export const selectDoesMessageHaveUnsavedChanges = createSelector(
  (state: RootState) => state.messageCreate,
  selectDoesMessageHaveUnsavedChangesInternal
);
