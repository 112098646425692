import { GetActiveOrderResult, OrderCart } from 'common';
import { FC } from 'react';
import { CfUncontrolledDrawer } from '../../../../../../../cf-ui/Drawer/CfUncontrolledDrawer';
import { CartButton } from '../CartButton/CartButton';
import { CartContent } from '../common/CartContent/CartContent';

export interface CartDrawerProps {
  isOpen?: boolean;
  activeOrder: GetActiveOrderResult | undefined;
  cartData: OrderCart | undefined;
  isLoading: boolean;
  onClose: () => void;
  onDrawerOpen?: () => void;
  onDrawerClose?: () => void;
}

export const CartDrawer: FC<CartDrawerProps> = (props: CartDrawerProps) => {
  const { isOpen, activeOrder, cartData, isLoading, onDrawerOpen, onDrawerClose } = {
    ...props,
  };

  const handleDrawerButtonClick = () => {
    if (isOpen) onDrawerClose?.();
    else onDrawerOpen?.();
  };

  return (
    <>
      <CartButton onClick={handleDrawerButtonClick} totalQuantity={activeOrder?.TotalQuantity} />
      <CfUncontrolledDrawer
        mode='panel'
        placement='right'
        open={Boolean(isOpen)}
        containerSx={{ p: 3, width: 375 }}
        onClose={onDrawerClose}
      >
        <CartContent
          activeOrder={activeOrder}
          cartData={cartData}
          isLoading={isLoading}
          closeShoppingCart={props.onClose}
        />
      </CfUncontrolledDrawer>
    </>
  );
};
