import { SeverityLevel } from '@microsoft/applicationinsights-web';
import {
  BaseGroupByRequest,
  BaseSearchRequest,
  CreateInvoiceReportSearchDirectoryRequest,
  DeleteInvoiceReportSearchDirectoryRequest,
  DeleteSearchHeaderRequest,
  GetCriteriaFilterOptionsRequest,
  InvoiceReportSearchDirectory,
  ResequenceSearchGroupBysRequest,
  SaveSearchCriteriaRequest,
  SearchCriteriaCustomersResultCustomer,
  UpdateInvoiceReportSearchDirectoryRequest,
  UpdateSearchDetailOptionsBatchRequest,
  UpdateSearchDetailOptionsRequest,
} from '../../api/models/invoice-report.models';
import InvoiceReportSearchDirectoryService from '../../api/services/invoice-report-search-directory.service';
import InvoiceReportSearchService from '../../api/services/invoice-report-search.service';
import {
  mapSearchCriteriaToSaveSearchCriteriaRequest,
  TemplateSelectableOptionsLookup,
} from '../../helpers/general/insights';
import { validateStringEmptyOrMaxLength } from '../../helpers/validation/general';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { globalSlice } from '../common/global.slice';
import { setErrorDialogContent } from '../common/global.thunks';
import { AppDispatch, AppThunk, RootState } from '../store';
import { insightsSlice } from './insights.slice';

const invoiceReportSearchService = InvoiceReportSearchService.getInstance();
const invoiceReportSearchDirectoryService = InvoiceReportSearchDirectoryService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Resets the insights slice to it's intial values
 *
 * @returns NULL
 */
export const resetInsightsState = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(insightsSlice.actions.resetState());
};

/**
 * Calls and stored the result of the GetSearchDirectories API call
 *
 * @param successCallback - Method to call on success
 * @returns BULL
 */
export const getInvoiceReportSearchDirectories =
  (successCallback?: (directories: InvoiceReportSearchDirectory[]) => void | Promise<void>): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(insightsSlice.actions.setInvoiceSearchDirectoriesLoading(true));

      const { data } = await invoiceReportSearchDirectoryService.getInvoiceReportSearchDirectories();

      if (data.IsSuccess) {
        dispatch(insightsSlice.actions.setInvoiceSearchDirectories(data.ResultObject));
        successCallback?.(data.ResultObject);
      } else {
        dispatch(
          globalSlice.actions.setErrorDialogContent({
            messages: data.ErrorMessages,
          })
        );
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(insightsSlice.actions.setInvoiceSearchDirectoriesLoading(false));
    }
  };

/**
 * Calls and stores the result of the GetSearchHeaders API call
 *
 * @param invoiceReportSearchDirectoryId -The id of the search directory who's header is being retrieved
 * @param refresh - Boolean indicating if the API call should be made (If true, then the method will return)
 * @returns NULL
 */
export const getInvoiceReportSearchHeaders =
  (invoiceReportSearchDirectoryId: string, refresh?: boolean): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const headersLoaded = getState().insights.searchHeaders.entities[invoiceReportSearchDirectoryId]?.loaded;
      if (!refresh && headersLoaded) return;

      dispatch(insightsSlice.actions.setInvoiceSearchHeadersLoading(true));

      const { data } = await invoiceReportSearchService.getInvoiceReportSearchHeaders({
        invoiceReportSearchDirectoryId,
      });

      if (data.IsSuccess) {
        dispatch(
          insightsSlice.actions.setInvoiceSearchHeaders({
            directoryId: invoiceReportSearchDirectoryId,
            headers: data.ResultObject,
          })
        );
      } else {
        dispatch(
          globalSlice.actions.setErrorDialogContent({
            messages: data.ErrorMessages,
          })
        );
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(insightsSlice.actions.setInvoiceSearchHeadersLoading(false));
    }
  };

/**
 * Calls validateStringEmptyOrMaxLength in general.ts
 *
 * @param request - Used to get the folder name for validation
 * @returns string[], Contains the validation messages
 */
export const validateCreateInvoiceReportSearchDirectory = (
  request: CreateInvoiceReportSearchDirectoryRequest
): string[] => validateStringEmptyOrMaxLength('Folder name', request.FolderName, 100);

/**
 * Calls and stores the result of the CreateSearchDirectory API call
 *
 * @param request - The request to make to the API
 * @param successCallback - Method to call on success
 * @param errorCallback - Method to call on error
 * @returns NULL
 */
export const createInvoiceReportSearchDirectory =
  (
    request: CreateInvoiceReportSearchDirectoryRequest,
    successCallback?: (productListCategory: InvoiceReportSearchDirectory) => void | Promise<void>,
    errorCallback?: (errors: string[]) => void | Promise<void>
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const validationMessages = validateCreateInvoiceReportSearchDirectory(request);
      if (validationMessages.length > 0) {
        errorCallback?.(validationMessages);
        return;
      }

      const { data } = await invoiceReportSearchDirectoryService.createInvoiceReportSearchDirectory(request);

      if (data.IsSuccess) {
        dispatch(insightsSlice.actions.addInvoiceSearchDirectory(data.ResultObject));
        successCallback?.(data.ResultObject);
      } else {
        dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
        errorCallback?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls validateStringEmptyOrMaxLength in general.ts
 *
 * @param request - Used to get the folder name for validation
 * @returns string[], Contains the validation messages
 */
export const validateEditInvoiceReportSearchDirectory = (
  request: UpdateInvoiceReportSearchDirectoryRequest
): string[] => validateStringEmptyOrMaxLength('Folder name', request.FolderName, 100);

/**
 * Calls and stores the UpdateSearchDirectory API call
 *
 * @param request - The request to make to the API
 * @param successCallback - Method to call on success
 * @param errorCallback - Method to call on error
 * @returns NULL
 */
export const editInvoiceReportSearchDirectory =
  (
    request: UpdateInvoiceReportSearchDirectoryRequest,
    successCallback?: (productListCategory: InvoiceReportSearchDirectory) => void | Promise<void>,
    errorCallback?: (errors: string[]) => void | Promise<void>
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const validationMessages = validateEditInvoiceReportSearchDirectory(request);
      if (validationMessages.length > 0) {
        errorCallback?.(validationMessages);
        return;
      }

      const { data } = await invoiceReportSearchDirectoryService.updateInvoiceReportSearchDirectory(request);

      if (data.IsSuccess) {
        dispatch(insightsSlice.actions.updateInvoiceSearchDirectory(data.ResultObject));
        successCallback?.(data.ResultObject);
      } else {
        dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
        errorCallback?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls the DeleteSearchDirectory API call
 *
 * @param request - The request to make to the API
 * @param successCallback - Method to call on success
 * @param errorCallback - Method to call on error
 * @returns NULL
 */
export const deleteInvoiceReportSearchDirectory =
  (
    request: DeleteInvoiceReportSearchDirectoryRequest,
    successCallback?: () => void | Promise<void>,
    errorCallback?: (errors: string[]) => void | Promise<void>
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const { data } = await invoiceReportSearchDirectoryService.deleteInvoiceReportSearchDirectory(request);

      if (data.IsSuccess) {
        dispatch(insightsSlice.actions.deleteInvoiceSearchDirectory(request.InvoiceReportSearchDirectoryId));
        successCallback?.();
      } else {
        dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
        errorCallback?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls getSearchCriteria and getSearchCriteriaCustomers methods
 *
 * @param request - The request to make to the API
 * @returns NULL
 */
export const getSearchCriteriaInitialState =
  (request: BaseSearchRequest): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      await dispatch(getSearchCriteria(request));
      await dispatch(getSearchCriteriaCustomers(request));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls and stores the result of the GetSearchCriteria API call
 *
 * @param request - The request to make to the API
 * @returns NULL
 */
export const getSearchCriteria =
  (request: BaseSearchRequest): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      if (!request.searchHeaderId) return;

      dispatch(insightsSlice.actions.setSearchCriteriaLoading(true));

      const { data } = await invoiceReportSearchService.getSearchCriteria(request);
      if (data.IsSuccess) {
        dispatch(insightsSlice.actions.setSearchCriteria(data.ResultObject));
        if (
          data.ResultObject.TemplateType !== undefined &&
          data.ResultObject.TemplateType !== null &&
          (data.ResultObject.IsTemplate || !data.ResultObject.IsFavorite)
        ) {
          const options = TemplateSelectableOptionsLookup.TemplateSelectableOptions[data.ResultObject.TemplateType];
          dispatch(insightsSlice.actions.setTemplateSelectableOptions(options));
        }
        // Premptively save request to avoid UI flickering
        dispatch(setSaveSearchCriteriaRequest(mapSearchCriteriaToSaveSearchCriteriaRequest(data.ResultObject)));
      } else dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(insightsSlice.actions.setSearchCriteriaLoading(false));
    }
  };

/**
 * Calls and stores the result of the GetSearchCriteriaCustomers API call
 *
 * @param request - The request to make to the API
 * @returns NULL
 */
export const getSearchCriteriaCustomers =
  (request: BaseSearchRequest): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
      if (!getState().insights.searchCriteriaCustomersLoading) {
        dispatch(insightsSlice.actions.setSearchCriteriaCustomersLoading(true));
      }

      const templateOptions = getState().insights.templateSelectableOptions;
      const selectedCustomer = getState().customer.selectedCustomer;
      const saveSearchCriteriaRequest = getState().insights.saveSearchCriteriaRequest;

      const customerIds = [];
      if (templateOptions.customers) {
        const { data } = await invoiceReportSearchService.getSearchCriteriaCustomers(request);
        if (data.IsSuccess) {
          const searchCriteriaCustomers = data.ResultObject;
          const selectedCriteriaCustomerIds = searchCriteriaCustomers
            .filter((c) => c.IsSelected)
            .map((c) => c.Customer.CustomerId);
          customerIds.push(...selectedCriteriaCustomerIds);

          // Default to selected customer, if non selected
          if (selectedCustomer && customerIds.length <= 0) {
            customerIds.push(selectedCustomer.CustomerId);
            const idx = searchCriteriaCustomers.map((c) => c.Customer.CustomerId).indexOf(selectedCustomer.CustomerId);
            if (idx >= 0) searchCriteriaCustomers[idx] = { ...searchCriteriaCustomers[idx], IsSelected: true };
          }

          dispatch(insightsSlice.actions.setSearchCriteriaCustomers(searchCriteriaCustomers));
        }
      }

      // Premptively save request to avoid UI flickering
      dispatch(setSaveSearchCriteriaRequest({ ...saveSearchCriteriaRequest, CustomerIds: customerIds }));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(insightsSlice.actions.setSearchCriteriaCustomersLoading(false));
    }
  };

/**
 * Sets the searchCriteriaCustomers value in the insights slice
 *
 * @param customers  - Customer value to set searchCriteriaCustomers in the slice to
 * @returns NULL
 */
export const setSearchCriteriaCustomers =
  (customers: SearchCriteriaCustomersResultCustomer[]): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(insightsSlice.actions.setSearchCriteriaCustomers(customers));
  };

/**
 * Sets the saveSearchCriteriaRequest value in the insights slice
 *
 * @param request  - Request value to set saveSearchCriteriaRequest in the slice to
 * @returns NULL
 */
export const setSaveSearchCriteriaRequest =
  (request: SaveSearchCriteriaRequest): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(insightsSlice.actions.setSaveSearchCriteriaRequest(request));
  };

/**
 * Sets the updateSearchDetailOptionsBatchRequest value in the insights slice
 *
 * @param request  - Request value to set updateSearchDetailOptionsBatchRequest in the slice to
 * @returns NULL
 */
export const setUpdateSearchDetailOptionsBatchRequest =
  (request?: UpdateSearchDetailOptionsBatchRequest): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(insightsSlice.actions.setUpdateSearchDetailOptionsBatchRequest(request));
  };

/**
 * Calls and stores the result of the SaveSearchCriteria API call
 *
 * @param request  - Request to make to the API call
 * @param successCallback - Method to call on success
 * @param failureCallback - Method to call on failure
 * @returns NULL
 */
export const saveSearchCriteria =
  (
    request: SaveSearchCriteriaRequest,
    successCallback?: () => void,
    failureCallback?: (errors: string[]) => void
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().insights.saveSearchCriteriaLoading) {
        dispatch(insightsSlice.actions.setSaveSearchCriteriaLoading(true));
      }

      const { data } = await invoiceReportSearchService.saveSearchCriteria(request);
      if (data.IsSuccess) {
        dispatch(insightsSlice.actions.setSaveSearchCriteriaResult(data.ResultObject));
        successCallback?.();
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        failureCallback?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(insightsSlice.actions.setSaveSearchCriteriaLoading(false));
    }
  };

/**
 * Calls and stores the result of the GetAvailableGroupBys API call
 *
 * @returns NULL
 */
export const getAvailableGroupBys = (): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    if (!getState().insights.availableGroupBysLoading) {
      dispatch(insightsSlice.actions.setAvailableGroupBysLoading(true));
    }

    const { data } = await invoiceReportSearchService.getAvailableGroupBys();
    if (data.IsSuccess) {
      dispatch(insightsSlice.actions.setAvailableGroupBys(data.ResultObject));
    }
  } catch (error) {
    appInsightsLogger.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
  } finally {
    dispatch(insightsSlice.actions.setAvailableGroupBysLoading(false));
  }
};

/**
 * Calls and stores the results of the GetSearchCriteriaGroupBys API call
 *
 * @param request  - Request to make to the API call
 * @param ignoreLoading - Boolean indicating if the searchCriteriaGroupBysLoading should be set to true or skipped
 * @returns NULL
 */
export const getSearchCriteriaGroupBys =
  (request: BaseSearchRequest, ignoreLoading?: boolean): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!ignoreLoading && !getState().insights.searchCriteriaGroupBysLoading) {
        dispatch(insightsSlice.actions.setSearchCriteriaGroupBysLoading(true));
      }

      const { data } = await invoiceReportSearchService.getSearchCriteriaGroupBys(request);
      if (data.IsSuccess) {
        dispatch(insightsSlice.actions.setSearchCriteriaGroupBys(data.ResultObject.GroupBys));
        dispatch(insightsSlice.actions.setCustomerFilterDisplay(data.ResultObject.CustomerFilterDisplay));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(insightsSlice.actions.setSearchCriteriaGroupBysLoading(false));
    }
  };

/**
 * Calls the RemoveSearchCriteriaGroupBy API call
 *
 * @param request  - Request to make to the API call
 * @returns NULL
 */
export const removeSearchCriteriaGroupBy =
  (request: BaseGroupByRequest): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().insights.searchCriteriaGroupBysLoading) {
        dispatch(insightsSlice.actions.setSearchCriteriaGroupBysLoading(true));
      }

      const { data } = await invoiceReportSearchService.removeSearchCriteriaGroupBy(request);
      if (data.IsSuccess) {
        const searchCriteriaGroupBys = getState().insights.searchCriteriaGroupBys;
        const newCriteriaGroupBys = searchCriteriaGroupBys.filter((x) => x.GroupByType !== request.GroupByType);
        dispatch(insightsSlice.actions.setSearchCriteriaGroupBys(newCriteriaGroupBys));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(insightsSlice.actions.setSearchCriteriaGroupBysLoading(false));
    }
  };

/**
 * Resets the criteriaFilterOptions and criteriaFilterOptionsLoading values to their inital values
 *
 * @returns NULL
 */
export const resetCriteriaFilterOptions = (): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(insightsSlice.actions.setCriteriaFilterOptions([]));
  dispatch(insightsSlice.actions.setCriteriaFilterOptionsLoading(false));
};

/**
 * Calls and stores the results of the GetCriteriaFilterOptions API call
 *
 * @param request  - Request to make to the API call
 * @returns NULL
 */
export const getCriteriaFilterOptions =
  (request: GetCriteriaFilterOptionsRequest): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().insights.criteriaFilterOptionsLoading) {
        dispatch(insightsSlice.actions.setCriteriaFilterOptionsLoading(true));
      }

      const { data } = await invoiceReportSearchService.getCriteriaFilterOptions(request);
      if (data.IsSuccess) {
        dispatch(insightsSlice.actions.setCriteriaFilterOptions(data.ResultObject));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(insightsSlice.actions.setCriteriaFilterOptionsLoading(false));
    }
  };

/**
 * Calls the UpdateSearchDetailOptionsBatch API call
 *
 * @param request  - Request to make to the API call
 * @param successCallback - Method to call on success
 * @returns NULL
 */
export const updateSearchDetailOptionsBatch =
  (request: UpdateSearchDetailOptionsBatchRequest, successCallback?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await invoiceReportSearchService.updateSearchDetailOptionsBatch(request);
      if (data.IsSuccess) {
        dispatch(insightsSlice.actions.setUpdateSearchDetailOptionsBatchRequest(undefined));
        successCallback?.();
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls the UpdateSearchDetailOptions API call
 *
 * @param request  - Request to make to the API call
 * @param successCallback - Method to call on success
 * @returns NULL
 */
export const updateSearchDetailOptions =
  (request: UpdateSearchDetailOptionsRequest, successCallback?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await invoiceReportSearchService.updateSearchDetailOptions(request);
      if (data.IsSuccess) {
        dispatch(insightsSlice.actions.setUpdateSearchDetailOptionsRequest(undefined));
        successCallback?.();
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls the DeleteSearchHeader API call
 *
 * @param searchDirectoryId - The id for the search directory being deleted
 * @param request  - Request to make to the API call
 * @param successCallback - Method to call on success
 * @param failureCallback - Method to call on failure
 * @returns NULL
 */
export const deleteSearchHeader =
  (
    searchDirectoryId: string,
    request: DeleteSearchHeaderRequest,
    successCallback?: () => void,
    failureCallback?: (errors: string[]) => void
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().insights.saveSearchCriteriaLoading) {
        dispatch(insightsSlice.actions.setSaveSearchCriteriaLoading(true));
      }

      const { data } = await invoiceReportSearchService.deleteSearchHeader(request);
      if (data.IsSuccess) {
        dispatch(
          insightsSlice.actions.deleteInvoiceSearchHeader({
            directoryId: searchDirectoryId,
            invoiceReportSearchHeaderId: request.InvoiceReportSearchHeaderId ?? '',
          })
        );

        successCallback?.();
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        failureCallback?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(insightsSlice.actions.setSaveSearchCriteriaLoading(false));
    }
  };

/**
 * Calls the ResequenceSearchGroupBys API call
 *
 * @param request  - Request to make to the API call
 * @param successCallback - Method to call on success
 * @returns
 */
export const resequenceSearchGroupBys =
  (request: ResequenceSearchGroupBysRequest, successCallback?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().insights.searchCriteriaGroupBysLoading) {
        dispatch(insightsSlice.actions.setSearchCriteriaGroupBysLoading(true));
      }

      const { data } = await invoiceReportSearchService.resequenceSearchGroupBys(request);
      if (data.IsSuccess) {
        successCallback?.();
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(insightsSlice.actions.setSearchCriteriaGroupBysLoading(false));
    }
  };

/**
 * Resets the updateSearchDetailOptionsBatchRequest value in the slice
 *
 * @returns NULL
 */
export const resetUpdateSearchDetailOptionsBatchRequest = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(insightsSlice.actions.setUpdateSearchDetailOptionsBatchRequest(undefined));
};

/**
 * Resets the updateSearchDetailOptionsRequest value in the slice
 *
 * @returns NULL
 */
export const resetUpdateSearchDetailOptionsRequest = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(insightsSlice.actions.setUpdateSearchDetailOptionsRequest(undefined));
};

/**
 * Resets the searchCriteria, searchCriteriaLoading, searchCriteriaCustomers, searchCriteriaCustomersLoading, saveSearchCriteriaResult, saveSearchCriteriaLoading, availableGroupBys,
 * availableGroupBysLoading, searchCriteriaGroupBys, searchCriteriaGroupBysLoading, criteriaFilterOptions, criteriaFilterOptionsLoading, saveSearchCriteriaRequest, updateSearchDetailOptionsBatchRequest
 * updateSearchDetailOptionsRequest, templateSelectableOptions, resequenceSearchGroupBysRequest and removeSearchCriteriaGroupByRequests values in the slice
 *
 * @returns NULL
 */
export const resetInsightsReportConfigurationData = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(insightsSlice.actions.resetInsightsReportConfigurationData());
};

/**
 * Sets the resequenceSearchGroupBysRequest value in the slice
 *
 * @returns NULL
 */
export const setResequenceSearchGroupBysRequest =
  (request: ResequenceSearchGroupBysRequest): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(insightsSlice.actions.setResequenceSearchGroupBysRequest(request));
  };

/**
 * Adds request to removeSearchCriteriaGroupByRequests value in the insights slice
 *
 * @param request - The request to add to removeSearchCriteriaGroupByRequests in the slice
 * @returns NULL
 */
export const addRemoveSearchCriteriaGroupByRequest =
  (request: BaseGroupByRequest): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(insightsSlice.actions.addRemoveSearchCriteriaGroupByRequest(request));
  };

/**
 * Sets the removeSearchCriteriaGroupByRequests value in the insights slice
 *
 * @param request - The request to add to removeSearchCriteriaGroupByRequests in the slice
 * @returns NULL
 */
export const setRemoveSearchCriteriaGroupByRequests =
  (requests: BaseGroupByRequest[]): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(insightsSlice.actions.setRemoveSearchCriteriaGroupByRequests(requests));
  };
