import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { ProductListCategoryProduct } from '../../models';

// Adapters
const categoryProductsAdapter = createEntityAdapter<ProductListCategoryProduct>({
  selectId: (categoryProduct: ProductListCategoryProduct) => categoryProduct.Id,
});

// State
export interface DeleteProductListDetailsState {
  isDialogOpen: boolean;
  isLoading: boolean;
  categoryProducts: EntityState<ProductListCategoryProduct>;
}

const initialState: DeleteProductListDetailsState = {
  isDialogOpen: false,
  isLoading: false,
  categoryProducts: categoryProductsAdapter.getInitialState(),
};

// Reducers
export const deleteProductListDetailsSlice = createSlice({
  name: 'delete-product-list-details',
  initialState: initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    setIsLoading: (state: DeleteProductListDetailsState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    openDialog: (state: DeleteProductListDetailsState, action: PayloadAction<ProductListCategoryProduct[]>) => {
      categoryProductsAdapter.addMany(state.categoryProducts, action.payload);
      state.isDialogOpen = true;
    },
  },
});

// Adapter Selectors
export const { selectAll: selectAllCategoryProductsToDelete, selectById: selectCategoryProductToDelete } =
  categoryProductsAdapter.getSelectors<DeleteProductListDetailsState>(
    (state: DeleteProductListDetailsState) => state.categoryProducts
  );
