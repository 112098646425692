import { Box, Button, ClickAwayListener, Icon } from '@mui/material';
import {
  AppRoutes,
  RouteName,
  getRouteNameByPathname,
  getUnreadMessagesCount,
  togglePriorityMessagesDialog,
  useAppDispatch,
  useAppSelector,
} from 'common';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DeviceType, useBreakpoint } from '../../../../hooks/useBreakpoint';
import { cfFreezeNames } from '../../../../theme/theme';
import Logo from '../common/Logo/Logo';
import { PageNotification } from '../notifications/PageNotification/PageNotification';
import ToastNotification from '../notifications/ToastNotification/ToastNotification';
import { StyledAppBar, StyledHeaderContainer, StyledHeaderSpacer, StyledSubHeaderContainer } from './Header.styles';
import { Account } from './components/Account/Account';
import Cart from './components/Cart/Cart';
import Location from './components/Location/Location';
import { Navigation } from './components/Navigation/Navigation';
import TypeAheadSearchInput from './components/TypeAheadSearchInput/TypeAheadSearchInput';

const Header: FC = () => {
  const { deviceType } = useBreakpoint();
  const location = useLocation();
  const [mobileSearchOpen, setMobileSearchOpen] = useState<boolean>(false);

  const mobileSearchMode =
    (mobileSearchOpen && deviceType === DeviceType.Mobile) ||
    (location.pathname.includes(AppRoutes[RouteName.ProductSearch].Path) && deviceType === DeviceType.Mobile);
  const mobileSearchModeClosed = !mobileSearchMode && deviceType === DeviceType.Mobile;

  const dispatch = useAppDispatch();

  const userSite = useAppSelector((x) => x.user.userSite);

  useEffect(() => {
    if (userSite?.IsActive === true || userSite?.UserCustomers.length) {
      dispatch(
        getUnreadMessagesCount((hasPriorityMessages) => {
          if (hasPriorityMessages) dispatch(togglePriorityMessagesDialog({ display: true }));
        })
      );
    }
  }, [dispatch, userSite?.UserCustomers.length]);

  const routeName = getRouteNameByPathname(location.pathname);
  if (
    RouteName.NoUserInformation === routeName ||
    RouteName.CookieAcceptance === routeName ||
    RouteName.TermsOfService === routeName ||
    RouteName.GetStarted === routeName
  ) {
    return <></>;
  }

  return (
    <>
      <StyledHeaderContainer className={cfFreezeNames.header} sx={{ flexDirection: 'column' }}>
        <Location variant={deviceType === DeviceType.Desktop ? 'menu' : 'drawer'} />
      </StyledHeaderContainer>
      <StyledSubHeaderContainer className={cfFreezeNames.subheader}>
        <StyledAppBar sx={{ gap: { xs: 1.5, lg: 3.5, xl: 3 }, height: 'inherit' }}>
          <Box sx={{ height: 'inherit', display: 'flex', alignItems: 'center', gap: 3 }}>
            {deviceType !== DeviceType.Desktop && (
              <Box>
                <Navigation variant='drawer' />
              </Box>
            )}
            {!mobileSearchMode && (
              <Box sx={{ py: 1, maxWidth: { xs: 166, xl: 214 }, height: 'inherit' }}>
                <Logo dataTestId='logo-home' routeTo={AppRoutes[RouteName.Home].Path} />
              </Box>
            )}
            {deviceType === DeviceType.Desktop && (
              <Box sx={{ pb: 1 }}>
                <Navigation variant='bar' />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              gap: 1,
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mr: { xs: 2, xl: 0 },
                gap: { xs: 1, xl: 0.5 },
                justifyContent: 'end',
                width: { xs: '100%', xl: 'unset' },
              }}
            >
              {deviceType !== DeviceType.Mobile && (
                <Box
                  sx={{
                    alignSelf: 'end',
                    width: { xs: 345, xl: 320 },
                    mr: 1,
                  }}
                >
                  <TypeAheadSearchInput
                    size={deviceType !== DeviceType.Desktop ? 'medium' : 'small'}
                    inputTestId='search-field-input'
                  />
                </Box>
              )}
              {mobileSearchMode && (
                <ClickAwayListener onClickAway={() => setMobileSearchOpen(false)}>
                  <Box sx={{ width: '100%' }}>
                    <TypeAheadSearchInput size='medium' inputTestId='search-field-input' />
                  </Box>
                </ClickAwayListener>
              )}
              {mobileSearchModeClosed && (
                <Button
                  variant='text'
                  disableRipple
                  disableElevation
                  sx={{ color: 'black', minWidth: 35 }}
                  onClick={() => setMobileSearchOpen(true)}
                >
                  <Icon className='fa-search' fontSize='medium' />
                </Button>
              )}

              {deviceType !== DeviceType.Mobile && (
                <Box sx={{ minWidth: '30px', minHeight: '30px' }}>
                  <Account variant={deviceType === DeviceType.Desktop ? 'menu' : 'drawer'} />
                </Box>
              )}

              <Box sx={{ minWidth: '30px', minHeight: '30px' }}>
                <Cart variant={deviceType === DeviceType.Desktop ? 'menu' : 'drawer'} />
              </Box>
            </Box>
          </Box>
          <ToastNotification />
        </StyledAppBar>
      </StyledSubHeaderContainer>
      <PageNotification />
      {/* Reserve vertical space at the top of the page for header*/}
      <StyledHeaderSpacer />
    </>
  );
};

export default Header;
