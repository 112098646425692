import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import {
  CustomerProductPriceResult,
  ProductDetailTransfer,
  ProductListProduct,
  UpdateCustomProductRequest,
} from '../../api';
import { APIMessages, CatalogProduct, UnitOfMeasureOrderQuantity } from '../../api/models/api-shared.models';
import {
  ProductSearchResult,
  SearchProductCatalogRequest,
  SearchProductCatalogResultDetail,
  TypeAheadResponse,
} from '../../api/models/product-catalog.models';
import { normalizeProductKey, updateProductCustomAttributesFromPayload } from '../../helpers';
import { createPagedEntityAdapter, EntityPageLoading, PagedEntityState } from '../paged-entity-adapter';

const productsAdapter = createPagedEntityAdapter<CatalogProduct>({
  selectId: (product: CatalogProduct) => normalizeProductKey(product.ProductKey),
});

export interface ProductSearchState {
  apiRequest?: SearchProductCatalogRequest;
  productSearchResult?: ProductSearchResult;
  products: PagedEntityState<CatalogProduct>;

  searchValue: string | undefined;
  typeAheadResults: TypeAheadResponse | undefined;
  typeAheadAPIMessages?: APIMessages;
}

export const initialState: ProductSearchState = {
  apiRequest: undefined,
  productSearchResult: undefined,
  products: productsAdapter.getInitialState(true),
  searchValue: undefined,
  typeAheadResults: undefined,
  typeAheadAPIMessages: undefined,
};

// Reducers
export const productSearchSlice = createSlice({
  name: 'productSearch',
  initialState: initialState,
  reducers: {
    resetProductSearchResults: (state: ProductSearchState) => {
      state.products = initialState.products;
      state.productSearchResult = initialState.productSearchResult;
      state.apiRequest = initialState.apiRequest;
    },
    setSearchValue: (state: ProductSearchState, action: PayloadAction<string | undefined>) => {
      state.searchValue = action.payload;
    },
    setApiRequest: (state: ProductSearchState, action: PayloadAction<SearchProductCatalogRequest | undefined>) => {
      state.apiRequest = action.payload;
    },
    setProductSearchResults: (
      state: ProductSearchState,
      action: PayloadAction<{ request: SearchProductCatalogRequest; result: SearchProductCatalogResultDetail }>
    ) => {
      const { request, result } = action.payload;
      const productSearchResult: ProductSearchResult = {
        CurrentPageNumber: result.CurrentPageNumber,
        NumberOfPages: result.NumberOfPages,
        PageSize: result.PageSize,
        QueryText: result.QueryText,
        TotalNumberOfItems: result.TotalNumberOfItems,
        Skip: result.Skip,
        Facets: result.Facets,
        HasLoadMore: result.HasLoadMore,
      };

      state.apiRequest = request;
      state.productSearchResult = productSearchResult;

      productsAdapter.setLoadedPage(state.products, {
        pageIndex: result.CurrentPageNumber,
        entities: result.CatalogProducts,
      });
    },
    setPageLoaded: (
      state: ProductSearchState,
      action: PayloadAction<{ pageIndex: number; catalogProducts: CatalogProduct[] }>
    ) => {
      productsAdapter.setLoadedPage(state.products, {
        pageIndex: action.payload.pageIndex,
        entities: action.payload.catalogProducts,
      });
    },
    setPageLoading: (state: ProductSearchState, action: PayloadAction<EntityPageLoading>) => {
      productsAdapter.setLoadingPage(state.products, action.payload);
    },
    setTypeAheadAPIMessages: (state: ProductSearchState, action: PayloadAction<APIMessages>) => {
      state.typeAheadAPIMessages = action.payload;
    },
    setTypeAheadResults: (state: ProductSearchState, action: PayloadAction<TypeAheadResponse>) => {
      state.typeAheadResults = action.payload;
    },
    setProductPrices: (state: ProductSearchState, action: PayloadAction<CustomerProductPriceResult[]>) => {
      action.payload.forEach((priceResult: CustomerProductPriceResult) => {
        if (priceResult.ProductKey) {
          const product = state.products.entities[normalizeProductKey(priceResult.ProductKey)];
          if (product) {
            (product as ProductListProduct).PriceLoaded = true;
            product.UnitOfMeasureOrderQuantities.forEach((uom: UnitOfMeasureOrderQuantity) => {
              if (uom.UnitOfMeasure === priceResult.UnitOfMeasureType) {
                uom.Price = priceResult.Price;
                if (priceResult.ProductAverageWeight > 0) {
                  uom.ProductAverageWeight = priceResult.ProductAverageWeight;
                }
              }
            });
          }
        }
      });
    },
    updateProduct: (state: ProductSearchState, action: PayloadAction<ProductDetailTransfer>) => {
      const product = state.products.entities[normalizeProductKey(action.payload.productKey)];
      if (product) {
        product.UnitOfMeasureOrderQuantities.forEach((uom: UnitOfMeasureOrderQuantity) => {
          if (uom.UnitOfMeasure === action.payload.uom) {
            uom.ExtendedPrice = action.payload.extendedPrice;
            uom.Quantity = action.payload.quantity;
            uom.ShowOrderQuantityAlert = action.payload.showOrderQuantityAlert;
          }
        });
      }
    },
    updateProductCustomAttributes: (state: ProductSearchState, action: PayloadAction<UpdateCustomProductRequest>) => {
      const product = state.products.entities[normalizeProductKey(action.payload.ProductKey)];
      if (!product) return;

      updateProductCustomAttributesFromPayload(product, action.payload);
    },
  },
});

export const {
  selectAll: selectAllCatalogProducts,
  selectById: selectCatalogProductById,
  selectIsLoading: selectProductSearchProductListIsLoading,
  selectByPage: selectProductSearchCatalogProductsByPage,
  selectTotal: selectProductSearchTotalCatalogProducts,
} = productsAdapter.getSelectors<RootState>((state) => state.productSearch.products);
