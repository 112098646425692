import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { MessageGroupBasic } from '../../api/models/message-group.models';
import { getSortComparer } from '../../helpers';
import { normalizeKey } from '../../helpers/general/string';
import { RootState } from '../store';

const messageGroupAdapter = createEntityAdapter<MessageGroupBasic>({
  selectId: (messageGroup: MessageGroupBasic) => normalizeKey(messageGroup.MessageGroupId),
  sortComparer: getSortComparer((messageGroup) => messageGroup.MessageGroupName),
});

interface MessageGroupState {
  messageGroups: EntityState<MessageGroupBasic>;
  messageGroupsLoading: boolean;
  messageGroupsLoaded: boolean;
}

const initialState: MessageGroupState = {
  messageGroups: messageGroupAdapter.getInitialState(),
  messageGroupsLoading: false,
  messageGroupsLoaded: false,
};

export const messageGroupSlice = createSlice({
  name: 'messageGroup',
  initialState: initialState,
  reducers: {
    resetState: () => initialState,
    setMessageGroups: (state: MessageGroupState, action: PayloadAction<MessageGroupBasic[]>) => {
      messageGroupAdapter.setAll(state.messageGroups, action.payload);
      state.messageGroupsLoading = false;
    },
    resetMessageGroups: (state: MessageGroupState) => {
      state.messageGroups = initialState.messageGroups;
      state.messageGroupsLoading = false;
      state.messageGroupsLoaded = false;
    },
    removeMessageGroup: (state: MessageGroupState, action: PayloadAction<string>) => {
      messageGroupAdapter.removeOne(state.messageGroups, action.payload);
    },
    setMessageGroupsLoading: (state: MessageGroupState, action: PayloadAction<boolean>) => {
      state.messageGroupsLoading = action.payload;
    },
    setMessageGroupsLoaded: (state: MessageGroupState, action: PayloadAction<boolean>) => {
      state.messageGroupsLoaded = action.payload;
    },
  },
});

export const {
  selectAll: selectAllMessageGroups,
  selectById: selectMessageGroupById,
  selectIds: selectAllMessageGroupIds,
} = messageGroupAdapter.getSelectors<RootState>((state: RootState) => state.messageGroup.messageGroups);
