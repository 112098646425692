import { SeverityLevel } from '@microsoft/applicationinsights-web';
import {
  ApiResponse,
  CreateMessageGroupRequest,
  CreateMessageGroupResult,
  GetMessageGroupRequest,
  MessageRecipientType,
  UpdateMessageGroupRequest,
  UpdateMessageGroupResult,
} from '../../api';
import MessageGroupService from '../../api/services/message-group.service';
import { generateId, normalizeKey } from '../../helpers';
import { validateStringEmptyOrMaxLength } from '../../helpers/validation/general';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { globalSlice } from '../common/global.slice';
import { AppDispatch, AppThunk, RootState } from '../store';
import {
  messageGroupSelectedSlice,
  selectAllMessageGroupRecipients,
  selectAllRecipientsKeyedByObjectValue,
} from './message-group-selected.slice';
import { selectAllMessageGroups } from './message-group.slice';
import { getMessageGroups, resetMessageGroups } from './message-group.thunks';
import { CustomerRecipient, UserRecipient } from './recipients.models';

const messageGroupService = MessageGroupService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Sets the messageGroup to the specified message group
 *
 * @param messageGroupId - The id of the message group the messageGroup value is being set to
 * @returns NULL
 */
export const setMessageGroupBasic =
  (messageGroupId: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const messageGroup = getState().messageGroup.messageGroups.entities[normalizeKey(messageGroupId)];
    if (messageGroup) {
      dispatch(messageGroupSelectedSlice.actions.setMessageGroupBasic(messageGroup));
      dispatch(messageGroupSelectedSlice.actions.setValidationMessages({ messageGroupName: [] }));
    }
  };

/**
 * Sets the messageGroup's name to the specified name
 *
 * @param messageGroupName - The name of the message group the messageGroup's name attribute is being set to
 * @returns NULL
 */
export const setMessageGroupName =
  (messageGroupName: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const messageGroup = getState().messageGroupSelected.messageGroup;
    if (messageGroupName !== messageGroup?.MessageGroupName)
      dispatch(
        messageGroupSelectedSlice.actions.setMessageGroupBasic({
          MessageGroupId: messageGroup?.MessageGroupId || '',
          MessageGroupName: messageGroupName,
          MessageGroupMemberCount: getState().messageGroupSelected.recipients.ids.length,
        })
      );
    dispatch(validateMessageGroup());
  };

/**
 * Validates and sets the messageGroup's customer recipients
 *
 * @param customerRecipients - Array of the customer recipients that are being assigned to the messageGroup
 * @returns NULL
 */
export const setMessageGroupCustomerRecipients =
  (customerRecipients: CustomerRecipient[]): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const recipientType = MessageRecipientType.Customer;
    const recipientsKeyedByObjectValue = selectAllRecipientsKeyedByObjectValue(getState());
    customerRecipients.forEach((c) => {
      const key = normalizeKey(c.customerId);
      if (recipientsKeyedByObjectValue[key] && !c.selected) {
        delete recipientsKeyedByObjectValue[key];
      } else if (!recipientsKeyedByObjectValue[key] && c.selected) {
        recipientsKeyedByObjectValue[key] = {
          MessageGroupId: '',
          MessageGroupRecipientId: generateId(),
          MessageGroupRecipientNameDisplay: c.customerName || '',
          MessageGroupAdditionalDisplayOne: c.customerAddress || '',
          MessageGroupAdditionalDisplayTwo: `${c.customerLocation} ${c.customerZip}`,
          MessageGroupAdditionalDisplayThree: `${c.operationCompanyName} - ${c.customerNumber}`,
          MessageGroupRecipientType: recipientType,
          ObjectValue: key,
        };
      }
    });

    const recipients = Object.values(recipientsKeyedByObjectValue);

    dispatch(
      messageGroupSelectedSlice.actions.setMessageGroupRecipients({
        recipients,
        setOnlyRecipientsOfType: recipientType,
      })
    );
  };

/**
 * Validates and sets the messageGroup's user recipients
 *
 * @param userRecipients - Array of the user recipients that are being assigned to the messageGroup
 * @returns NULL
 */
export const setMessageGroupUserRecipients =
  (userRecipients: UserRecipient[]): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const recipientType = MessageRecipientType.User;
    const recipientsKeyedByObjectValue = selectAllRecipientsKeyedByObjectValue(getState());
    userRecipients.forEach((u) => {
      const key = normalizeKey(u.userId);
      if (recipientsKeyedByObjectValue[key] && !u.selected) {
        delete recipientsKeyedByObjectValue[key];
      } else if (!recipientsKeyedByObjectValue[key] && u.selected) {
        recipientsKeyedByObjectValue[key] = {
          MessageGroupId: '',
          MessageGroupRecipientId: generateId(),
          MessageGroupRecipientNameDisplay: u.userDisplayName,
          MessageGroupAdditionalDisplayOne: u.userName,
          MessageGroupAdditionalDisplayTwo: '',
          MessageGroupAdditionalDisplayThree: '',
          MessageGroupRecipientType: recipientType,
          ObjectValue: key,
        };
      }
    });

    const recipients = Object.values(recipientsKeyedByObjectValue);

    dispatch(
      messageGroupSelectedSlice.actions.setMessageGroupRecipients({
        recipients,
        setOnlyRecipientsOfType: recipientType,
      })
    );
  };

/**
 * Calls and stores the result of the GetMessageGroup API call
 *
 * @param request - The information needed to make a GetMessageGroup API call
 * @returns NULL
 */
export const getMessageGroupFull =
  (request: GetMessageGroupRequest): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (
        getState().messageGroupSelected.messageGroup?.MessageGroupId?.toLowerCase() ===
          request.messageGroupId.toLowerCase() &&
        getState().messageGroupSelected.recipientsLoading
      ) {
        return;
      }

      if (!getState().messageGroupSelected.recipientsLoading) {
        dispatch(messageGroupSelectedSlice.actions.setRecipientsLoading(true));
      }
      const { data } = await messageGroupService.getMessageGroup(request);
      if (data.IsSuccess) {
        dispatch(messageGroupSelectedSlice.actions.setMessageGroupFull(data.ResultObject));
      } else {
        dispatch(messageGroupSelectedSlice.actions.resetMessageGroupRecipients());
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      dispatch(messageGroupSelectedSlice.actions.resetMessageGroupRecipients());
      console.error(error);
    } finally {
      dispatch(messageGroupSelectedSlice.actions.setRecipientsLoading(false));
    }
  };

/**
 * Removes the specified recipient from the messageGroup's recipients
 *
 * @param recipientId - The id of the recipient to be removed
 * @returns NULL
 */
export const removeMessageGroupRecipientById =
  (recipientId: string): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(messageGroupSelectedSlice.actions.removeMessageGroupRecipientById(recipientId));
  };

/**
 * Sets all values in the message-group.selected slice to their initial values
 *
 * @returns NULL
 */
export const resetMessageGroupSelected = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(messageGroupSelectedSlice.actions.resetState());
};

/**
 * Validates the message group's name
 *
 * @param messageGroupName - The name to be validated
 * @returns Returns the messages gotten from the validation
 */
export const validateMessageGroupName =
  (messageGroupName: string | undefined): AppThunk<Promise<string[]>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<string[]> => {
    const messages = validateStringEmptyOrMaxLength('Name', messageGroupName, 100);

    if (messages.length === 0) {
      await dispatch(getMessageGroups());
      const currentMessageGroupId = getState().messageGroupSelected.messageGroup?.MessageGroupId;
      if (
        selectAllMessageGroups(getState()).filter(
          (mg) => currentMessageGroupId !== mg.MessageGroupId && mg.MessageGroupName.trim() === messageGroupName?.trim()
        ).length > 0
      ) {
        messages.push('Name unavailable');
      }
    }
    return messages;
  };

/**
 * Sets validationMessages to the result of the validateMessageGroupName method
 *
 * @returns Boolean indicating if the length of the validation message is 0
 */
export const validateMessageGroup =
  (): AppThunk<Promise<boolean>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<boolean> => {
    const validationMessages = await dispatch(
      validateMessageGroupName(getState().messageGroupSelected.messageGroup?.MessageGroupName)
    );
    dispatch(
      messageGroupSelectedSlice.actions.setValidationMessages({
        messageGroupName: validationMessages,
      })
    );

    return validationMessages.length === 0;
  };

/**
 * Creates or updates a message group depending on if the selected message group has a group id
 *
 * @param successCallback - Method to call on success
 * @param errorCallback - Method to call on error
 * @returns NULL
 */
export const createOrUpdateMessageGroup =
  (
    successCallback?: (messageGroupId: string) => void,
    errorCallback?: (errors: string[]) => void
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
      if (!(await dispatch(validateMessageGroup()))) {
        return;
      }
      const request: CreateMessageGroupRequest | UpdateMessageGroupRequest = {
        MessageGroupId: getState().messageGroupSelected.messageGroup?.MessageGroupId || '',
        MessageGroupName: getState().messageGroupSelected.messageGroup?.MessageGroupName || '',
        Recipients: selectAllMessageGroupRecipients(getState().messageGroupSelected.recipients).map((r) => ({
          MessageGroupRecipientType: r.MessageGroupRecipientType,
          ObjectValue: r.ObjectValue,
        })),
      };
      const priorRequest = getState().messageGroupSelected.saveMessageGroupRequest;

      if (JSON.stringify(priorRequest) === JSON.stringify(request)) {
        return;
      }
      dispatch(messageGroupSelectedSlice.actions.setSaveMessageGroupApiRequest(request));

      let data: ApiResponse<CreateMessageGroupResult | UpdateMessageGroupResult>;
      if (request.MessageGroupId) {
        data = (await messageGroupService.updateMessageGroup(request)).data;
      } else {
        data = (await messageGroupService.createMessageGroup(request)).data;
      }

      if (data.IsSuccess) {
        dispatch(resetMessageGroups());
        dispatch(
          messageGroupSelectedSlice.actions.setMessageGroupFull({
            MessageGroupId: getState().messageGroupSelected.messageGroup?.MessageGroupId || '',
            MessageGroupName: getState().messageGroupSelected.messageGroup?.MessageGroupName || '',
            Recipients: selectAllMessageGroupRecipients(getState().messageGroupSelected.recipients),
          })
        );
        successCallback?.(data.ResultObject.MessageGroupId);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        errorCallback?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(messageGroupSelectedSlice.actions.setSaveMessageGroupApiRequest(undefined));
    }
  };
