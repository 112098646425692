import { useEffect } from 'react';
import { CfGa, useAppSelector } from 'common';
import { useGaState } from './hooks/useGa';

// Used to initialize GA due to constraints with singleton hooks that access store and unit tests
const Analytics = (): JSX.Element => {
  const trackingId = process.env.REACT_APP_GA_MEASUREMENT_ID;

  const ga = useGaState();
  const analytics = ga?.[0];
  const setAnalytics = ga?.[1];

  const userSite = useAppSelector((s) => s.user.userSite);

  useEffect(() => {
    if (!userSite || !trackingId || analytics) return;

    const _analytics = new CfGa();
    _analytics.initialize({
      debug: process.env.NODE_ENV === 'development',
      trackingId,
      consent: userSite.HasAcceptedAnalyticsCookies ? 'granted' : 'denied',
    });

    setAnalytics?.(_analytics);
  }, [userSite]);

  return <></>;
};

export default Analytics;
