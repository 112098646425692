import { Box, styled } from '@mui/material';

export const StyledMenuItemBase = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  [theme.breakpoints.up('xs')]: {
    ...theme.typography.mobileParagraph,
  },
  [theme.breakpoints.up('xl')]: {
    ...theme.typography.paragraph,
  },
}));

export const StyledMenuItem = styled(StyledMenuItemBase)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.grey[900],
  '&:hover': {
    backgroundColor: theme.palette.coolGrey[100],
  },
  '&:focus': {
    backgroundColor: theme.palette.coolGrey[100],
    outline: 'none',
  },
}));

export const StyledDisabledMenuItem = styled(StyledMenuItemBase)(({ theme }) => ({
  cursor: 'default',
  color: `${theme.palette.grey[400]} !important`,
}));

export const StyledSelectedMenuItem = styled(StyledMenuItemBase)(({ theme }) => ({
  cursor: 'default',
  color: `${theme.palette.primary.main} !important`,
  backgroundColor: theme.palette.grey[100],

  [theme.breakpoints.up('xs')]: {
    ...theme.typography.mobileParagraphBold,
  },
  [theme.breakpoints.up('xl')]: {
    ...theme.typography.paragraphBold,
  },
}));

export const StyledHeaderMenuItem = styled(StyledMenuItemBase)(() => ({
  cursor: 'default',
}));
