import { Box, Theme, TooltipProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, PropsWithChildren } from 'react';
import { CfTooltip } from '../../Tooltip/CfTooltip';
import {
  StyledDisabledMenuItem,
  StyledHeaderMenuItem,
  StyledMenuItem,
  StyledSelectedMenuItem,
} from './CfMenuItem.styles';
import { CfMenuItemPrefix } from './CfMenuItemPrefix';

export interface CfMenuItemProps extends CfMenuItemCommonProps {
  rootRef?: React.RefObject<HTMLDivElement | undefined>;
  tabIndex?: number;
  value?: unknown;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLElement>;
  onSelect?: (value: unknown | undefined) => void;
}

export interface CfMenuItemCommonProps {
  isHeader?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
  disabledTooltipTitle?: string;
  tooltipProps?: TooltipProps;
  iconClassName?: string | undefined;
  iconSx?: SxProps<Theme>;
  sx?: SxProps<Theme>;
  dataTestId?: string;
}

/**
 *
 * @param isHeader adjusts styling and interactivity to represent a label within the menu
 * @param isSelected adjusts styling and interactivity to represent a selected item
 * @param isDisabled adjusts styling and interactivity to represent a disabled item
 * @param disabledTooltipTitle passed to the Tooltip subcomponent rendered when isDisabled is set to true
 * @param tooltipProps passed to the Tooltip subcomponent
 * @param iconClassName passed to the CfMenuItemPrefix subcomponent
 * @param sx passed to the StyledMenuItem subcomponent
 * @param dataTestId passed to the StyledMenuItem subcomponent
 *  @returns
 */
export const CfMenuItem: FC<PropsWithChildren<CfMenuItemProps>> = (props: PropsWithChildren<CfMenuItemProps>) => {
  const {
    children,
    dataTestId,
    isDisabled,
    disabledTooltipTitle,
    isHeader,
    iconClassName,
    iconSx,
    rootRef,
    tabIndex,
    isSelected,
    tooltipProps,
    onClick,
    onKeyDown,
    onSelect,
    ...rest
  } = { ...props };

  const className = 'cf-menu-item';

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    const key = event.key;
    if (key === 'Enter') {
      onSelect?.(props.value);
      event.preventDefault();
    }
  };

  const handleClick = () => {
    onSelect?.(props.value);
  };

  const childrenAugmented = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {iconClassName && <CfMenuItemPrefix iconClassName={iconClassName} iconSx={iconSx} />}
      {children}
    </Box>
  );

  if (isDisabled && disabledTooltipTitle)
    return (
      <CfTooltip title={disabledTooltipTitle} placement='left' {...tooltipProps}>
        <StyledDisabledMenuItem {...rest} className={className}>
          {childrenAugmented}
        </StyledDisabledMenuItem>
      </CfTooltip>
    );
  if (isDisabled)
    return (
      <StyledDisabledMenuItem {...rest} className={className} data-testid={dataTestId}>
        {childrenAugmented}
      </StyledDisabledMenuItem>
    );
  if (isSelected)
    return (
      <StyledSelectedMenuItem {...rest} className={className} data-testid={dataTestId}>
        {childrenAugmented}
      </StyledSelectedMenuItem>
    );
  if (isHeader)
    return (
      <StyledHeaderMenuItem
        {...rest}
        className={className}
        onKeyDown={onKeyDown ?? handleKeyDown}
        data-testid={dataTestId}
      >
        {childrenAugmented}
      </StyledHeaderMenuItem>
    );
  return (
    <StyledMenuItem
      {...rest}
      ref={rootRef}
      onClick={onClick ?? handleClick}
      onKeyDown={onKeyDown ?? handleKeyDown}
      tabIndex={tabIndex ?? 0}
      className={className}
      data-testid={dataTestId}
    >
      {childrenAugmented}
    </StyledMenuItem>
  );
};
