import { Icon, Typography } from '@mui/material';
import { AppNotification, removeAppNotification, useAppDispatch, useTimer } from 'common';
import { useState } from 'react';
import { StyledPaperNotification } from './GrowlerNotificationItem.styles';

interface GrowlerNotificationProps {
  notification: AppNotification;
}

const GrowlerNotification = ({ notification }: GrowlerNotificationProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [elevation, setElevation] = useState(2);

  const timer = useTimer(notification.AutoDismiss, removeNotification);

  function removeNotification() {
    dispatch(removeAppNotification(notification));
  }

  const handleNotificationClick = () => {
    if (notification.CanUserDismiss) removeNotification();
  };

  const handleMouseEnter = () => {
    setElevation(10);
    timer.pause();
  };

  const handleMouseLeave = () => {
    setElevation(2);
    timer.resume();
  };

  return (
    <StyledPaperNotification
      data-testid={`notification-${notification.Id}`}
      elevation={elevation}
      onClick={handleNotificationClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      role='alert'
    >
      {notification.Icon && (
        <Icon fontSize='medium' className={notification.Icon} color='primary' sx={{ marginRight: 0.9 }} />
      )}
      {notification.Title && (
        <Typography data-testid={`notification-title-${notification.Id}`} variant='h4'>
          {notification.Title}
        </Typography>
      )}
      {notification.Message && (
        <Typography data-testid={`notification-message-${notification.Id}`} variant='paragraph' component='span'>
          {notification.Message}
        </Typography>
      )}
    </StyledPaperNotification>
  );
};

export default GrowlerNotification;
