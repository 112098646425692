import { Box, Fade, Icon, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SxProps, Theme } from '@mui/system';
import { FC, PropsWithChildren } from 'react';
import { CfCloseButton } from '../buttons/CfCloseButton';
import { StyledBackgroundBox, StyledContentBox } from './CfAlert.styles';

export type CfAlertVariant = 'error' | 'warning' | 'info' | 'success' | 'system';

export interface CfAlertProps {
  variant: CfAlertVariant;
  title?: string;
  message?: string;
  isOpen: boolean;
  onClose?: () => void;
  anchorEl?: HTMLElement;
  hasCloseButton?: boolean;
  sx?: SxProps<Theme>;
  wrapperDataTestId?: string;
  messageDataTestId?: string;
  messageSx?: SxProps<Theme>;
}

/**
 * @param variant - Determines the alert's colors and any icons to be used (error, warning, info, success, system)
 * @param title - The alert's title
 * @param message - The alert's message
 * @param isOpen - Boolean indicating if the alert is open or closed
 * @param onClose - Method to call on close
 * @param anchorEl - (Not used)
 * @param hasCloseButton - Boolean indicating if the alert should show a close button
 * @param sx - Any additional SxProps
 * @param wrapperDataTestId - The test id for the StyledContentBox that wraps the alert
 * @param messageDataTestId - The test id for the Box which contains the message
 * @param messageSx - Any additional SxProps for the message box
 */

export const CfAlert: FC<PropsWithChildren<CfAlertProps>> = (props: PropsWithChildren<CfAlertProps>) => {
  const theme = useTheme();

  let backgroundColor = undefined;
  let color = theme.palette.common.white;
  let iconClassName = undefined;
  switch (props.variant) {
    case 'error':
      backgroundColor = theme.palette?.error?.main;
      iconClassName = 'fa-exclamation-triangle';
      break;
    case 'warning':
      backgroundColor = theme.palette?.warning?.main;
      color = theme.palette.grey[900];
      break;
    case 'info':
      backgroundColor = theme.palette?.primary?.main;
      break;
    case 'success':
      backgroundColor = theme.palette?.success?.main;
      break;
    case 'system':
      backgroundColor = theme.palette?.common?.black;
      break;
    default:
      break;
  }
  return (
    <>
      {props.isOpen && (
        <Fade in={props.isOpen}>
          <StyledBackgroundBox
            sx={{
              visibility: props.isOpen ? 'visible' : 'hidden',
              backgroundColor: backgroundColor,
              color: color,
            }}
          >
            <StyledContentBox sx={props.sx} data-testid={props.wrapperDataTestId}>
              {iconClassName && (
                <Icon
                  className={iconClassName}
                  fontSize='small'
                  sx={{
                    paddingRight: 1,
                  }}
                />
              )}
              {props.title && (
                <Box>
                  <Typography variant='paragraphBold'>{props.title}:&nbsp;</Typography>
                </Box>
              )}
              {props.message && (
                <Box
                  data-testid={props.messageDataTestId}
                  sx={{
                    flexGrow: 1,
                    ...props.messageSx,
                  }}
                >
                  <Typography variant='paragraph' sx={{ whiteSpace: 'pre-line' }}>
                    {props.message}
                  </Typography>
                </Box>
              )}
              {props.children}
              {props.hasCloseButton && (
                <CfCloseButton
                  size='small'
                  variant='thick'
                  sx={{
                    color: color,
                    padding: 0.5,
                    '& > .MuiIcon-root': {
                      width: 18,
                    },
                  }}
                  onClick={() => props.onClose?.()}
                />
              )}
            </StyledContentBox>
          </StyledBackgroundBox>
        </Fade>
      )}
    </>
  );
};
