import { ServiceResult } from '../models/api-shared.models';
import {
  ProductListNotificationGroup,
  ProductListNotificationTotal,
  UpdateProductListNotificationRequest,
} from '../models/product-list-notification.models';
import ApiService from './api-service';

class ProductListNotificationService extends ApiService {
  private static classInstance: ProductListNotificationService;

  private constructor() {
    super('ProductListNotification');
  }

  public getProductListNotifications(customerId: string): ServiceResult<ProductListNotificationGroup[]> {
    return this.get(`${this.baseUrl}/GetProductListNotifications`, { customerId: customerId });
  }

  public getProductListNotificationTotal(customerId: string): ServiceResult<ProductListNotificationTotal> {
    return this.get(`${this.baseUrl}/GetProductListNotificationTotal`, { customerId: customerId });
  }

  public updateProductListNotification(request: UpdateProductListNotificationRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/UpdateProductListNotification`, request);
  }

  public dismissProductListNotification(
    productListNotificationId: string,
    customerId: string | undefined
  ): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/DismissProductListNotification`, {
      ProductListNotificationId: productListNotificationId,
      CustomerId: customerId,
    });
  }
  public dismissActionRequestedProductListNotifications(customerId: string | undefined): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/DismissActionRequestedProductListNotifications`, {
      CustomerId: customerId,
    });
  }

  public static getInstance(): ProductListNotificationService {
    if (!this.classInstance) {
      this.classInstance = new ProductListNotificationService();
    }
    return this.classInstance;
  }
}

export default ProductListNotificationService;
