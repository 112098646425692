import { useMsal } from '@azure/msal-react';
import { Box, Button, Icon, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import {
  UserActivityAction,
  UserActivityActionSummary,
  UserActivityPageName,
  logUserActivity,
  useAppDispatch,
  useAppSelector,
} from 'common';
import { FC, Fragment, useEffect } from 'react';
import { CfUncontrolledDrawer } from '../../../../../../../../cf-ui/Drawer/CfUncontrolledDrawer';
import { CfDrawerFooter } from '../../../../../../../../cf-ui/Drawer/components/CfDrawerFooter';
import { CfDropdown } from '../../../../../../../../cf-ui/Dropdown/CfDropdown';
import { CfMenuItem } from '../../../../../../../../cf-ui/Menu/MenuItem/CfMenuItem';
import { logout } from '../../../../../../../../utilities/auth';
import { getResolution } from '../../../../../../../../utilities/resolution';
import { Menus, setMenu, useHeaderMenus } from '../../../../hooks/useHeaderMenus';
import { NavButtonOptions, NavMenuConfig, NavigationOptionVariant } from '../../NavigationProps';

interface NavigationDrawerProps {
  navigationConfig: NavMenuConfig[];
  onClickOption: ({ navigationAction, navigationRoute }: NavButtonOptions) => void;
}

export const NavigationDrawer: FC<NavigationDrawerProps> = (props: NavigationDrawerProps) => {
  const dispatch = useAppDispatch();
  const BusinessUnitKey = useAppSelector((x) => x.user.userSite?.BusinessUnitKey);
  const { instance } = useMsal();

  const { openedMenu } = useHeaderMenus();

  useEffect(() => {
    setMenu(Menus.None);
  }, []);

  const isOpen = openedMenu === Menus.NavigationDrawer;

  const handleClick = (option: NavButtonOptions) => {
    if (option.isDisabled) return;
    setMenu(Menus.None);
    props?.onClickOption(option);
  };

  const handleLogout = () => {
    dispatch(
      logUserActivity({
        action: UserActivityAction.Logout,
        pageName: UserActivityPageName.Logout,
        actionSummary: UserActivityActionSummary.SuccessfullyLoggedOut,
        resolution: getResolution(),
        callBack: async () => logout(instance, BusinessUnitKey),
      })
    );
  };

  const handleButtonVariantClick = (navAction: () => void) => () => {
    setMenu(Menus.None);
    navAction();
  };

  const handleDrawerButtonClick = () => {
    if (isOpen) setMenu(Menus.None);
    else setMenu(Menus.NavigationDrawer);
  };

  const navItemSx: SxProps<Theme> = {
    '&:hover': {
      backgroundColor: (theme) => theme.palette.coolGrey[100],
    },
    '&:focus': {
      backgroundColor: (theme) => theme.palette.coolGrey[100],
      outline: 'none',
    },
  };

  const navItems = props.navigationConfig.map((config, index) => (
    <Fragment key={index}>
      {config.variant === NavigationOptionVariant.Menu ? (
        <CfDropdown
          variant='header'
          hideCaret={(config.menuItems?.length ?? 0) <= 0}
          disabled={config.isDisabled}
          caretSx={{ pr: 2 }}
          headerBoxSx={{
            px: 2,
            py: 1.5,
            mb: 0,
            ...navItemSx,
          }}
          title={
            <Typography
              key={index}
              variant='mobileLabel'
              sx={{
                color: (theme) => (config.isDisabled ? theme.palette.coolGrey[300] : theme.palette.primary.main),
              }}
            >
              {config.label}
            </Typography>
          }
        >
          <Box sx={{ width: '100%' }}>
            {config.menuItems?.map?.((option: NavButtonOptions, index: number) => (
              <CfMenuItem
                key={index}
                onSelect={() => handleClick(option)}
                isDisabled={option.isDisabled}
                sx={{ pl: 6 }}
                dataTestId={`navigation-drawer-menu-item-${option.testId}`}
              >
                <Typography
                  variant='mobileLabel'
                  sx={
                    option.isDisabled
                      ? {
                          color: (theme: Theme) => theme.palette.coolGrey[300],
                          pl: (theme: Theme) => theme.spacing(4),
                          ...(option.childOption && {
                            pl: (theme: Theme) => theme.spacing(2),
                            fontWeight: 400,
                          }),
                        }
                      : {
                          color: (theme) => theme.palette.primary.main,
                          pl: (theme: Theme) => theme.spacing(4),
                          ...(option.childOption && {
                            pl: (theme: Theme) => theme.spacing(6),
                            fontWeight: 400,
                          }),
                        }
                  }
                  data-testid={option.testId}
                >
                  {option.label}
                </Typography>
              </CfMenuItem>
            ))}
          </Box>
        </CfDropdown>
      ) : (
        <Box sx={{ width: '100%', ...navItemSx }}>
          <Button
            sx={{ pl: 2, py: 1.5, justifyContent: 'flex-start' }}
            onClick={handleButtonVariantClick(config.navigationAction)}
            disabled={config.isDisabled}
            fullWidth
          >
            <Typography
              variant='mobileLabel'
              data-testid={config.testId}
              sx={{
                color: (theme: Theme) => (config.isDisabled ? theme.palette.coolGrey[300] : theme.palette.primary.main),
              }}
            >
              {config.label}
            </Typography>
          </Button>
        </Box>
      )}
    </Fragment>
  ));

  const navSuffix = (
    <CfDrawerFooter
      sx={{
        justifyContent: 'space-between',
      }}
      onClick={handleLogout}
    >
      <Typography variant='h4' color='primary' data-testid='sign-out-drawer-btn'>
        Sign out
      </Typography>
      <Icon className='fa-sign-out' fontSize='medium' color='primary' sx={{ mr: 1 }} />
    </CfDrawerFooter>
  );

  return (
    <>
      <Button
        variant='text'
        disableRipple={true}
        disableElevation={true}
        sx={{ color: 'black', minWidth: { xs: 23, lg: 30 } }}
        onClick={handleDrawerButtonClick}
      >
        <Icon className='fa-bars' fontSize='medium' />
      </Button>
      <CfUncontrolledDrawer
        mode='panel'
        placement='left'
        onClose={() => setMenu(Menus.None)}
        open={isOpen}
        containerSx={{ py: 1, width: 350 }}
        suffix={navSuffix}
      >
        {navItems}
      </CfUncontrolledDrawer>
    </>
  );
};
