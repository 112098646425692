import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material/';

export const StyledGrid = styled(Grid)(({ theme }) => ({
  position: 'fixed',
  width: 'auto',
  height: 'auto',
  bottom: 'auto',
  left: 'auto',
  right: 0,
  minHeight: 50,
  zIndex: 2000,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  marginX: theme.spacing(2),

  [theme.breakpoints.up('xs')]: {
    right: 5,
    top: theme.custom.headerHeights['lg'] - 15,
  },
  [theme.breakpoints.up('sm')]: {
    right: 5,
    top: theme.custom.headerHeights['lg'] - 15,
  },
  [theme.breakpoints.up('md')]: {
    right: 5,
    top: theme.custom.headerHeights['lg'] - 15,
  },
  [theme.breakpoints.up('lg')]: {
    right: 5,
    top: theme.custom.headerHeights['lg'] - 15,
  },
  [theme.breakpoints.up('xl')]: {
    right: `calc((100% - ${theme.custom.contentWidths['xl']}px) / 2)`,
    top: theme.custom.headerHeights['xl'] - 15,
  },
}));
