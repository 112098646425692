import { BusinessUnitKeyType } from './api-shared.enums';

export interface BusinessUnit {
  BusinessUnitId: string;
  BusinessUnitKey: BusinessUnitKeyType;
  BusinessUnitName?: string;
  SettingsData: BusinessUnitSetting;
}

export interface BusinessUnits {
  ResultObject: BusinessUnit[];
}

export interface BusinessUnitSetting {
  CanViewOrders: boolean;
  CanViewInventory: boolean;
  CanViewRecipes: boolean;
  CanViewPromotionsAndRewards: boolean;
  CanViewInvoices: boolean;
  CanEditOpenOrders: boolean;
  CanViewListManagement: boolean;
  MaxFutureOrderDaysAllowed: number;
  MaxFutureOrderEntryDaysAllowed: number;
  UseCustomerFirstARStatement: boolean;
  SourceApiGroupName?: string;
  ReportIncorrectProductInformationEmail?: string;
  RequestProductNutritionalInformationEmail?: string;
  BusinessUnitLogoURL?: string;
  OperatingAsDisplay?: string;
}

export interface CreateBusinessUnitRequest {
  BusinessUnitKey: BusinessUnitKeyType;
  BusinessUnitName?: string;
  SettingsData: BusinessUnitSetting;
}

export type CreateBusinessUnitResultData = BusinessUnit;

export interface GetBusinessUnitRequest {
  businessUnitKey: BusinessUnitKeyType;
}

export interface UpdateBusinessUnitRequest {
  BusinessUnitKey: BusinessUnitKeyType;
  BusinessUnitName?: string;
  SettingsData: BusinessUnitSetting;
}

export enum BusinessUnitLinkType {
  TermsOfUse = 1,
  PrivacyPolicy = 2,
  SocialMedia = 3,
}

export interface BusinessUnitLink {
  BusinessUnitLinkId: string;
  BusinessUnitKey: BusinessUnitKeyType;
  LinkType: BusinessUnitLinkType;
  Sequence: number;
  LinkTitle?: string;
  LinkIconUrl?: string;
  LinkIconTagClass?: string;
  Url?: string;
}

export interface GetBusinessUnitLinksRequest {
  BusinessUnitKey?: BusinessUnitKeyType;
}
