import { Theme, Typography } from '@mui/material';
import { FC, Fragment } from 'react';
import { CfDialog } from '../Dialog/CfDialog';

export interface CfErrorDialogProps {
  title: string;
  messages: string[];
  open: boolean;
  onClose?: () => void;
}

/**
 * A wrapper for the CfDialog component to be used to display error messages
 *
 * @param title - Title to display
 * @param message - Array of messages to display
 * @param open - Boolean indicating if the dialog is open
 * @param onClose - Method to call on close
 */
export const CfErrorDialog: FC<CfErrorDialogProps> = (props: CfErrorDialogProps) => {
  const { title, messages, open, onClose } = props;
  return (
    <CfDialog
      isOpen={open}
      title={title}
      primaryButtonProps={{ children: 'Close', onClick: onClose }}
      iconProps={{ className: 'fa-exclamation-triangle', sx: { color: (theme: Theme) => theme.palette.error.main } }}
      onClose={onClose}
    >
      {messages.map((msg, idx) => (
        <Fragment key={idx}>
          {idx !== 0 && <br />}
          <Typography key={`err-msg-${idx}`} variant='paragraph' data-testid={`alert-dialog-error-${idx}`}>
            {msg}
          </Typography>
        </Fragment>
      ))}
    </CfDialog>
  );
};
