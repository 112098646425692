import { FormControl, IconButton, InputAdornment, InputProps, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { FC, useEffect, useRef } from 'react';
import { DeviceType, useBreakpoint } from '../../hooks/useBreakpoint';
import { CfCloseButton } from '../buttons/CfCloseButton';
import {
  StyledIcon,
  StyledInput,
  StyledInputAdornment,
  StyledLabelBox,
  StyledLabelTypography,
} from './CfTextField.styles';

export type CfTextFieldPropSizes = 'extra-small' | 'small' | 'medium';

/**
 * the properties which describe how the CfTextField component should present and behave
 */
export interface CfTextFieldProps extends InputProps {
  inlineLabel?: React.ReactNode;
  inputLabel?: React.ReactNode;
  helpLabel?: React.ReactNode;
  iconClassName?: string;
  showIcon?: boolean;
  showErrorIcon?: boolean;
  showClearButton?: boolean;
  onClear?: () => void;
  onFormControlKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  onIconKeyDown?: React.KeyboardEventHandler<HTMLElement>;
  selectMode?: boolean;
  inputSizeOverride?: CfTextFieldPropSizes;
  muiInputRef?: React.RefObject<HTMLDivElement>;
  formControlTabIndex?: number;
  inputTestId?: string;
  helpLabelTestId?: string;
  selectButtonTestId?: string;
  dropdownTestId?: string;
  errorIconTestId?: string;
  spacing?: number;
  fullWidth?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  showHelpLabelDefault?: boolean;
}

/**
 *
 * @param inlineLabel augmented and passed to the Input component's startAdornment property
 * @param inputLabel rendered within the FormControl component before the Input component
 * @param helpLabel rendered after the Input component (replaced by error messages when applicable)
 * @param iconClassName passed to the IconButton component's className property
 * @param showIcon determines whether to show the (main) IconButton component with the assigned iconClassName property
 * @param showErrorIcon determines whether to pass an (error) IconButton component to the Input component's endAdornment property to indicate an error is present
 * @param showClearButton determines whether to pass an (clear) Iconbutton component to the Input component's endAdornment property that allows users to clear the value of the Input component
 * @param onClear executed when the CfCloseButton component's onClick event is triggered
 * @param onFormControlKeyDown passed to the FormControl component's onKeyDown property
 * @param onIconKeyDown passed to the main IconButton component's onKeyDown property
 * @param selectMode determines whether to disable the Input component
 * @param inputSizeOverride ('extra-small' | 'small' | 'medium') determines the height of the Input component
 * @param muiInputRef passed to the Input component's ref property
 * @param formControlTabIndex passed to the Input component's tabIndex property
 * @param inputTestId passed to the Input component's inputProps property
 * @param helpLabelTestId assigned to the helpLabel's container's data-testid property
 * @param selectButtonTestId passed to the main IconButton component's data-testid property
 * @param dropdownTestId passsed to the Input component's data-testid property
 * @param errorIconTestId passed to the error IconButton component's data-testid property
 * @param spacing determines the Input component's css padding-left and padding-right properties
 * @param fullWidth passed to the FormControl component's fullWidth property
 * @param inputRef passed to the Input component's inputRef property
 * @param showHelpLabelDefault passed to display the help label always
 */
export const CfTextField: FC<CfTextFieldProps> = (props: CfTextFieldProps) => {
  const {
    inlineLabel,
    inputLabel,
    helpLabel,
    iconClassName,
    showIcon,
    showErrorIcon,
    showClearButton,
    onClear,
    onFormControlKeyDown,
    onIconKeyDown,
    selectMode,
    inputSizeOverride,
    muiInputRef,
    formControlTabIndex,
    inputTestId,
    helpLabelTestId,
    selectButtonTestId,
    dropdownTestId,
    errorIconTestId,
    showHelpLabelDefault,
    ...rest
  } = {
    ...props,
  };
  const deviceType = useBreakpoint().deviceType;
  const inputRef = props.inputRef ?? useRef<HTMLInputElement>(null);

  const handleClear = (): void => {
    inputRef.current?.focus();
    onClear?.();
  };

  useEffect(() => {
    // disable text input while still allowing users to click
    if (selectMode && inputRef && inputRef.current) inputRef.current.readOnly = true;
  }, []);

  const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') rest.onSubmit?.(event);
  };

  let height;
  let themedHeight = (theme: Theme) => theme.spacing(3);
  let _inputSize = inputSizeOverride;
  if (!_inputSize) _inputSize = deviceType === DeviceType.Desktop ? 'small' : 'medium';
  switch (_inputSize) {
    case 'extra-small':
      themedHeight = (theme: Theme) => theme.spacing(3);
      break;
    case 'small':
      themedHeight = (theme: Theme) => theme.spacing(4);
      break;
    case 'medium':
      themedHeight = (theme: Theme) => theme.spacing(5);
  }
  if (rest.multiline) height = 'unset';
  else height = themedHeight;

  const paddingX = inlineLabel ? 1 : 2;

  const inputSX: SxProps<Theme> = {
    '&.MuiInput-root': {
      height: height,
    },
    '&.Mui-focused:not(.Mui-error) > .MuiInput-input, &.Mui-error > .MuiInput-input': {
      paddingX: (theme: Theme) => `${theme.custom.spacing * 2 - 1}px`,
    },
    '& > .MuiInput-input': {
      paddingX: paddingX,
      typography: inlineLabel
        ? { xs: 'mobileParagraphBold', xl: 'paragraphBold' }
        : { xs: 'mobileParagraph', xl: 'paragraph' },
    },
  };
  const augmentedInputSX = {
    ...inputSX,
    ...rest.sx,
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') rest.onSubmit?.(event);
  };

  const handleFormControlFocus = (event: React.FocusEvent) => {
    if (event.relatedTarget !== inputRef.current) {
      inputRef.current?.focus();
    }
  };

  return (
    <FormControl
      tabIndex={formControlTabIndex}
      fullWidth={props.fullWidth}
      onKeyPress={handleKeyPress}
      onKeyDown={onFormControlKeyDown}
      onFocus={handleFormControlFocus}
    >
      {inputLabel && (
        <StyledLabelBox>
          {typeof inputLabel === 'string' && <StyledLabelTypography>{inputLabel}</StyledLabelTypography>}
          {typeof inputLabel !== 'string' && inputLabel}
        </StyledLabelBox>
      )}
      <StyledInput
        {...rest}
        ref={muiInputRef}
        inputRef={inputRef}
        disableUnderline
        tabIndex={0}
        sx={augmentedInputSX}
        onKeyDown={rest.onKeyDown ?? handleKeydown}
        startAdornment={
          inlineLabel && (
            <InputAdornment position='start' sx={{ paddingLeft: 2, marginRight: -0.5 }}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.common.black,
                  typography: { xs: 'mobileParagraph', xl: 'paragraph' },
                }}
              >
                {inlineLabel}
              </Typography>
            </InputAdornment>
          )
        }
        endAdornment={
          props.endAdornment ?? (
            <>
              {rest.error && showErrorIcon && (
                <StyledInputAdornment position='end'>
                  <IconButton disableRipple disableFocusRipple disableTouchRipple>
                    <StyledIcon color='error' className='fa-exclamation-triangle' data-testid={errorIconTestId} />
                  </IconButton>
                </StyledInputAdornment>
              )}
              {showIcon && iconClassName && (
                <StyledInputAdornment position='end' sx={{ mr: 1 }}>
                  <IconButton tabIndex={-1} data-testid={selectButtonTestId} onKeyDown={(e) => onIconKeyDown?.(e)}>
                    <StyledIcon sx={{ color: (theme) => theme.palette.common.black }} className={iconClassName} />
                  </IconButton>
                </StyledInputAdornment>
              )}
              {!rest.error && showClearButton && ((rest.value as string) ?? '').length > 0 && (
                <StyledInputAdornment position='end'>
                  <CfCloseButton onClick={handleClear} size='small' />
                </StyledInputAdornment>
              )}
            </>
          )
        }
        inputProps={{
          'data-testid': inputTestId,
        }}
        data-testid={dropdownTestId}
      />
      {((helpLabel && rest.error) || showHelpLabelDefault) && (
        <StyledLabelBox>
          <StyledLabelTypography
            sx={{ color: (theme) => (rest.error ? theme.palette.error.main : theme.palette.common.black) }}
            data-testid={helpLabelTestId}
            className='label-error'
          >
            {helpLabel}
          </StyledLabelTypography>
        </StyledLabelBox>
      )}
    </FormControl>
  );
};

CfTextField.defaultProps = {
  fullWidth: true,
  showHelpLabelDefault: false,
};
