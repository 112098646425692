import { Box, Button, Icon, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { RootState, useAppSelector } from 'common';
import { FC } from 'react';
import { CfTooltip } from '../../../../../../cf-ui/Tooltip/CfTooltip';

export interface LocationBarProps {
  onClick?: () => void;
  iconClassName: string;
  dataTestId?: string;
  setRef?: (instance: HTMLSpanElement | null) => void;
  toolTipTitle?: React.ReactNode;
  isOpen?: boolean;
  caretUpIcon?: string;
  caretDownIcon?: string;
  sx?: SxProps<Theme>;
}

export const LocationBar: FC<LocationBarProps> = (props: LocationBarProps) => {
  const selectedCustomer = useAppSelector((s: RootState) => s.customer.selectedCustomer);
  const icon = (
    <Icon
      ref={(r) => props.setRef?.(r)}
      className={props.iconClassName}
      fontSize='small'
      sx={{ color: (theme) => theme.palette.common.black }}
    />
  );
  const caretUpIcon = (
    <Icon className={props.caretUpIcon} fontSize='small' sx={{ color: (theme) => theme.palette.common.black }} />
  );
  const caretDownIcon = (
    <Icon className={props.caretDownIcon} fontSize='small' sx={{ color: (theme) => theme.palette.common.black }} />
  );
  return (
    <Button
      variant='text'
      disableElevation
      disableRipple
      sx={{
        ...props.sx,
        color: (theme) => theme.palette.common.black,
        width: '100%',
        height: 34,
        '&.MuiButton-root': { boxShadow: '0px 1px 0px #E5E5E5' },
        minWidth: { xs: 23, lg: 30 },
        borderRadius: 0,
        '&.MuiButton-text:hover': {
          backgroundColor: (theme) => theme.palette.coolGrey[100],
        },
      }}
      onClick={() => props.onClick?.()}
      data-testid={props.dataTestId}
    >
      {props.toolTipTitle ? (
        <Box
          sx={{
            width: (theme) => theme.custom.contentWidths['xl'],
            maxWidth: (theme) => theme.custom.pageWidths['xl'],
            justifyContent: 'flex-start',
            flexDirection: 'row',
            boxShadow: 'none',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            marginRight: 'auto',
            marginLeft: 'auto',
            paddingRight: '0px !important',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          <CfTooltip title={props.toolTipTitle} placement='left' enterDelay={200}>
            <>
              {icon}
              <Typography
                component='span'
                display='inline'
                sx={{
                  typography: { xs: 'mobileParagraphBold', xl: 'paragraphBold' },
                  pr: 1,
                  pl: 1,
                }}
              >
                {`${selectedCustomer?.CustomerName}`}
              </Typography>
              <Typography
                component='span'
                display='inline'
                sx={{ typography: { xs: 'mobileParagraph', xl: 'paragraphSmall' }, pr: 1 }}
              >
                |
              </Typography>
              <Typography
                component='span'
                display='inline'
                sx={{ typography: { xs: 'mobileParagraph', xl: 'paragraphSmall' } }}
              >{`${selectedCustomer?.CustomerAddress}, ${selectedCustomer?.CustomerLocation} ${selectedCustomer?.CustomerZip}`}</Typography>
              <Typography
                component='span'
                display='inline'
                sx={{ typography: { xs: 'mobileParagraph', xl: 'paragraphSmall' }, pr: 1, pl: 1 }}
              >
                |
              </Typography>
              <Typography
                component='span'
                display='inline'
                sx={{
                  typography: { xs: 'mobileParagraph', xl: 'paragraphSmall' },
                  pr: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >{`${selectedCustomer?.OperationCompanyName} - ${selectedCustomer?.CustomerNumber}`}</Typography>
              {props.isOpen ? caretUpIcon : caretDownIcon}
            </>
          </CfTooltip>
        </Box>
      ) : (
        <Box
          sx={{
            width: (theme) => theme.custom.contentWidths['xl'],
            maxWidth: (theme) => theme.custom.pageWidths['xl'],
            justifyContent: 'flex-start',
            flexDirection: 'row',
            boxShadow: 'none',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            marginRight: 'auto',
            marginLeft: 'auto',
            paddingRight: '0px !important',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          <>
            {icon}
            <Typography
              component='span'
              display='inline'
              sx={{
                typography: { xs: 'mobileParagraphBold', xl: 'paragraphBold' },
                pr: 1,
                pl: 1,
              }}
            >
              {`${selectedCustomer?.CustomerName}`}
            </Typography>
            <Typography
              component='span'
              display='inline'
              sx={{ typography: { xs: 'mobileParagraph', xl: 'paragraphSmall' }, pr: 1 }}
            >
              |
            </Typography>
            <Typography
              component='span'
              display='inline'
              sx={{ typography: { xs: 'mobileParagraph', xl: 'paragraphSmall' } }}
            >{`${selectedCustomer?.CustomerAddress}, ${selectedCustomer?.CustomerLocation} ${selectedCustomer?.CustomerZip}`}</Typography>
            <Typography
              component='span'
              display='inline'
              sx={{ typography: { xs: 'mobileParagraph', xl: 'paragraphSmall' }, pr: 1, pl: 1 }}
            >
              |
            </Typography>
            <Typography
              component='span'
              display='inline'
              sx={{
                typography: { xs: 'mobileParagraph', xl: 'paragraphSmall' },
                pr: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >{`${selectedCustomer?.OperationCompanyName} - ${selectedCustomer?.CustomerNumber}`}</Typography>
            {props.isOpen ? caretUpIcon : caretDownIcon}
          </>
        </Box>
      )}
    </Button>
  );
};
