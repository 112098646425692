import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { FC, PropsWithChildren } from 'react';

export type CfDrawerFooterProps = {
  onClick?: () => void;
  sx?: SxProps<Theme>;
};

/**
 * The footer component for drawers
 *
 * @param onClick - Method to call on clicking the footer box
 * @param sx - Additional styling for the footer box
 * @param children - Items to show in the footer
 */
export const CfDrawerFooter: FC<PropsWithChildren<CfDrawerFooterProps>> = (
  props: PropsWithChildren<CfDrawerFooterProps>
) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        minHeight: 72,
        p: 2,
        mt: 'auto',
        flexShrink: 0,
        boxShadow: '0px -1px 0px #E6E6E6',
        ...props.sx,
      }}
      onClick={props.onClick}
    >
      {props.children}
    </Box>
  );
};
