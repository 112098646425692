import { AppNotification, groupBy, NotificationDisplayType, RootState, useAppSelector } from 'common';
import GrowlerNotificationItem from './components/GrowlerNotificationItem';
import { StyledGrid } from './GrowlerNotification.styles';

const GrowlerNotification = (): JSX.Element => {
  const notifications = useAppSelector((x: RootState) =>
    x.global.notifications.filter(
      (notification: AppNotification) => notification.NotificationDisplayType === NotificationDisplayType.Growler
    )
  );
  if (!(notifications && notifications.length > 0)) return <></>;

  const groupedNotifications = groupBy(notifications, (m) => m.Id);
  const uniqueNotifications = Array.from(groupedNotifications, ([, value]) => value[0]);
  return (
    <StyledGrid data-testid='notification-grid'>
      {uniqueNotifications.map((notification: AppNotification) => {
        return <GrowlerNotificationItem key={notification.Id} notification={notification} />;
      })}
    </StyledGrid>
  );
};

export default GrowlerNotification;
