import { Box } from '@mui/material/';
import { styled } from '@mui/material/styles';

export const StyledBackgroundBox = styled(Box)({
  display: 'flex',
  width: '100%',
  height: '100%',
  zIndex: 500,
});

export const StyledContentBox = styled(Box)({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  margin: '0 auto',
});

export const StyledTextBox = styled(Box)({
  display: 'block',
});
