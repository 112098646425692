import { FC, PropsWithChildren } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CfMonthPicker } from '../common/CfMonthPicker';
import { StyledCalendarBox, StyledDatePickerBox } from '../common/Shared.styles';

/**
 * describes how the CfDatePicker component should present and behave
 */
export interface CfDatePickerProps extends ReactDatePickerProps {
  calendarId?: string;
  calendarRef?: React.LegacyRef<DatePicker<never>> | undefined;
  onCalendarContainerBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
  testId?: string;
}

/**
 * Allows users to select a date from a gregorian calendar utilizing the react-datepicker module's DatePicker component and properties
 * @param calendarId - assigned as the id attribute of the calendar container element
 * @param calendarRef - passed to the DatePicker subcomponent
 * @param onCalendarContainerBlur - assigned as the onBlur handler of the calendar container
 * @param testId - assigned as the test id of the calendar container element and DatePicker subcomponent - and augmented/assigned as the CfMonthPicker subcomponent's testId property
 * @param ... - ReactDatePickerProps
 */
export const CfDatePicker: FC<PropsWithChildren<CfDatePickerProps>> = (props: PropsWithChildren<CfDatePickerProps>) => {
  const handleChange = (calendarDate: Date | null) => {
    if (!calendarDate) return;
    calendarDate.setHours(0);
    calendarDate.setMinutes(0);
    calendarDate.setSeconds(0);
    props.onChange(calendarDate, undefined);
  };

  return (
    <StyledDatePickerBox>
      <DatePicker
        autoComplete='off'
        id='cf-date-picker'
        showPopperArrow={false}
        fixedHeight
        {...props}
        ref={props.calendarRef}
        onChange={handleChange}
        enableTabLoop={false}
        calendarContainer={(calProps) => (
          <StyledCalendarBox
            id={props.calendarId}
            onBlur={props.onCalendarContainerBlur}
            sx={{ userSelect: 'none' }}
            data-testid={`${props.testId}-box`}
          >
            {calProps.children}
          </StyledCalendarBox>
        )}
        renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => (
          <CfMonthPicker
            monthDate={monthDate}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            testId={`${props.testId}-month-picker`}
          />
        )}
        data-testid={props.testId}
      />
    </StyledDatePickerBox>
  );
};
