import { SxProps, Theme } from '@mui/system';
import React, { useRef, useState } from 'react';
import { ValidEmailRequest } from '../../features/site/components/dialogs/GetStartedDialog/GetStartedDialog';
import { CfTextField, CfTextFieldPropSizes } from './CfTextField';
import { validateRequestValues } from './validation/EmailValidation';

interface CfEmailInputProps {
  defaultValue?: string;
  onChange?: (value: string, hasError: boolean) => void;
  onSubmit?: (value: string) => void;
  inputLabel?: string;
  sx?: SxProps<Theme>;
  dataTestId: string;
  inputSize?: CfTextFieldPropSizes;
  extraValidator?: (value: string) => { isValid: boolean; message?: string };
}

const defaultHelpLabel = 'A valid email address is required';

const CfEmailInput: React.FC<CfEmailInputProps> = (props: CfEmailInputProps) => {
  const defaultValues: ValidEmailRequest = {
    value: props.defaultValue ?? '',
    error: false,
    required: true,
    regex: validatePendingEmail,
  };

  const [requestInformation, setRequestInformation] = useState<ValidEmailRequest>(defaultValues);
  const helpLabelRef = useRef(defaultHelpLabel);

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const cleanVal = event.target.value.trim();
    let isError = validateRequestValues({ ...requestInformation, value: cleanVal });
    if (props.extraValidator) {
      const result = props.extraValidator(cleanVal);
      if (!result.isValid) {
        isError = true;
        helpLabelRef.current = result.message || '';
      } else {
        helpLabelRef.current = defaultHelpLabel;
      }
    }
    setRequestInformation({
      ...defaultValues,
      value: cleanVal,
      error: isError,
    });
    props.onChange?.(cleanVal, isError);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (requestInformation.error) return;
      props.onSubmit?.(requestInformation.value);
    }
  };

  return (
    <CfTextField
      name='emailAddress'
      inputLabel={props.inputLabel}
      defaultValue={props.defaultValue}
      error={requestInformation.error}
      helpLabel={helpLabelRef.current}
      inputTestId={props.dataTestId}
      onChange={handleOnChange}
      onKeyDown={handleKeyDown}
      sx={props.sx}
      inputSizeOverride={props.inputSize}
    />
  );
};

export const validatePendingEmail = new RegExp("\\w+([-+.']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*");
export default CfEmailInput;
