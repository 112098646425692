import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledEstimatedCostLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.coolGrey[600],
  [theme.breakpoints.up('xs')]: {
    ...theme.typography.mobileParagraphBold,
  },
  [theme.breakpoints.up('xl')]: {
    ...theme.typography.paragraphBold,
  },
}));

export const StyledExtendedItemPrice = styled(Typography)(({ theme }) => ({
  color: theme.palette.coolGrey[800],
  textAlign: 'right',
  [theme.breakpoints.up('xs')]: {
    ...theme.typography.mobileParagraph,
  },
  [theme.breakpoints.up('xl')]: {
    ...theme.typography.paragraph,
  },
}));

export const StyledEmptyCartMessage = styled(Typography)(({ theme }) => ({
  py: theme.spacing(2),
  color: theme.palette.coolGrey[600],
  [theme.breakpoints.up('xs')]: {
    ...theme.typography.h4,
  },
}));
