import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { GetOrderRequest } from '../../api/models/order.models';
import { getSessionId } from '../../api/services/axios-instance';
import OrderEntryHeaderService from '../../api/services/order-entry-header.service';
import UserActivityReportService from '../../api/services/user-activity-report.service';
import { getBuildInfo } from '../../helpers';
import getBrowser from '../../helpers/utilities/browser/getBrowser';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { Resolution } from '../../models/common.models';
import {
  LogUserActivityRequest,
  UserActivityAction,
  UserActivityActionSummary,
  UserActivityPageName,
  UserActivityParameter,
} from '../../models/user-activity-report.models';
import { AppDispatch, AppThunk } from '../store';

// hooks
const userActivityReportService = UserActivityReportService.getInstance();
const orderEntryHeaderService = OrderEntryHeaderService.getInstance();
const appInsightsLogger = useAppInsightsLogger();
const browserInfo = getBrowser();

interface LogUserActivityParams<T = undefined> {
  action: UserActivityAction;
  pageName: UserActivityPageName;
  actionSummary: UserActivityActionSummary;
  resolution: Resolution;
  customerId?: string;
  customerNumber?: string;
  orderEntryHeaderId?: string;
  orderName?: string;
  deliveryDate?: string | Date;
  additionalData?: T;
  userId?: string;
  pendingUserId?: string;
  callBack?: () => void;
}

/**
 * Sends a request to the api to add an activity record to the user activity log
 *
 * @param params.action - UserActivityAction enum that represents a user interaction
 * @param params.pageName - UserActivityPageName enum that represents which page the user was viewing during the interaction
 * @param params.actionSummary - UserActivityActionSummary enum that describes a user interaction
 * @param params.resolution - Resolution - represents the size of the viewport/device the user was interacting with
 * @param params.customerId? - represents a specified customer (defaults to CustomerState's selectedCustomer)
 * @param params.customerNumber? - represents a specified customer (defaults to CustomerState's customerBillto)
 * @param params.orderEntryHeaderId? - represents a specified order (defaults to OrderState's activeOrder) and used to retrieve customerId, orderName, and deliveryDate from the api when unspecified
 * @param params.orderName? - represents an order (defaults to the orderName property of the api response of getOrderEntryHeader of the specified orderEntryHeaderId or activeOrder's orderEntryHeaderId)
 * @param params.deliveryDate? - the delivery date for an order (defaults to the deliveryDate property of the api response of getOrderEntryHeader of the specified orderEntryHeaderId or activeOrder's orderEntryHeaderId)
 * @param params.additionalData? - used to provide additional data unrelated to order, customer, and page information (e.g. ProductListHeaderId)
 * @param params.userId? - represents a specified user that exists (defaults to CustomerState's selectedCustomer's user id)
 * @param params.pendingUserId? - represents a specified user that is pending
 * @param params.callBack? - executed after the api request is sent regardless of the response
 * @returns void
 */
export const logUserActivity =
  <T = undefined>(params: LogUserActivityParams<T>): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      const { action, pageName, actionSummary, resolution, additionalData, callBack } = params;
      let { customerId, customerNumber, orderEntryHeaderId, orderName, deliveryDate, userId, pendingUserId } = params;

      const sessionID = getSessionId();
      const orderEntryHeader = getState().orders.order;
      const activeOrderId = getState().orders.activeOrder?.OrderEntryHeaderId || '';
      const productNumber = getState().productDetail.productDetailHeader?.ProductNumber || '';
      orderEntryHeaderId = orderEntryHeaderId ? orderEntryHeaderId : activeOrderId;
      customerId = customerId ? customerId : getState().customer.selectedCustomer?.CustomerId || '';
      deliveryDate = deliveryDate ? deliveryDate : getState().orders.order?.DeliveryDate || '';
      userId = userId ? userId : getState().customer.selectedCustomer?.UserId || '';
      pendingUserId = pendingUserId ? pendingUserId : '';

      if (orderEntryHeaderId === orderEntryHeader?.OrderEntryHeaderId) {
        orderName = orderName ?? (orderEntryHeader.OrderEntryHeaderName || '');
        deliveryDate = deliveryDate ?? (orderEntryHeader.DeliveryDate || '');
      } else if (
        orderEntryHeaderId &&
        ((actionSummary.includes(UserActivityParameter.OrderName) && !orderName) ||
          (actionSummary.includes(UserActivityParameter.DeliverDate) && !deliveryDate) ||
          !customerId)
      ) {
        const getOrderRequest: GetOrderRequest = { orderEntryHeaderId: orderEntryHeaderId };
        const getOrderResult = await orderEntryHeaderService.getOrderEntryHeader(getOrderRequest);
        const orderResultData = getOrderResult.data;
        if (orderResultData.IsSuccess) {
          customerId = orderResultData.ResultObject.CustomerId || '';
          orderName = orderResultData.ResultObject.OrderEntryHeaderName || '';
          deliveryDate = orderResultData.ResultObject.DeliveryDate || '';
        }
      }

      customerNumber = customerNumber ?? '';
      orderName = orderName ?? '';

      const activityActionSummary = actionSummary
        .replace(UserActivityParameter.CustomerNumber, customerNumber)
        .replace(UserActivityParameter.OrderName, orderName)
        .replace(UserActivityParameter.ProductNumber, productNumber)
        .replace(UserActivityParameter.DeliverDate, deliveryDate as string)
        .replace(UserActivityParameter.OrderEntryHeaderId, orderEntryHeaderId)
        .replace(UserActivityParameter.FromPage, pageName)
        .replace(UserActivityParameter.CustomerId, customerId)
        .replace(UserActivityParameter.UserId, userId)
        .replace(UserActivityParameter.PendingUserId, pendingUserId);

      const request: LogUserActivityRequest<T> = {
        Action: action,
        PageName: pageName,
        ActionSummary: activityActionSummary,
        SessionId: sessionID.toString(),
        BrowserName: browserInfo.name,
        BrowserVersion: browserInfo.version,
        Resolution: resolution.width.toString() + '/' + resolution.height.toString(),
        CustomerId: customerId || '',
        // getBuildInfo errors out when run by Jest
        AdditionalData: process.env.JEST_WORKER_ID ? additionalData : { ...getBuildInfo(), ...additionalData },
      };

      await userActivityReportService.logUserActivity(request);

      if (callBack) await callBack();
    } catch (error: unknown) {
      console.error(error);
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };
