import { Icon, IconButton } from '@mui/material';
import { CfTooltip } from '../../../../../../../cf-ui/Tooltip/CfTooltip';
import { DeviceType, useBreakpoint } from '../../../../../../../hooks/useBreakpoint';

interface EmailActionsProps {
  notificationId: string;
  onClickEditIcon: () => void;
  onClickDeleteIcon: () => void;
}

export const EmailActions = (props: EmailActionsProps): JSX.Element => {
  const { deviceType } = useBreakpoint();

  return (
    <>
      <CfTooltip
        title='Edit'
        placement='top'
        key={`communications-action-edit-${props.notificationId}`}
        data-testid={`communications-action-edit-${props.notificationId}`}
      >
        <IconButton onClick={props.onClickEditIcon}>
          <Icon
            className='fa-pen'
            fontSize={deviceType === DeviceType.Desktop ? 'small' : 'medium'}
            color='primary'
            data-testid='communications-email-list-notification-edit-icon'
          />
        </IconButton>
      </CfTooltip>
      <CfTooltip
        title='Delete'
        placement='top'
        key={`communications-action-delete-${props.notificationId}`}
        data-testid={`communications-action-delete-${props.notificationId}`}
      >
        <IconButton onClick={props.onClickDeleteIcon}>
          <Icon
            className='fa-trash'
            fontSize={deviceType === DeviceType.Desktop ? 'small' : 'medium'}
            color='primary'
            data-testid='communications-email-list-notification-delete-icon'
          />
        </IconButton>
      </CfTooltip>
    </>
  );
};
