import { Divider } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { StyledDrawer, StyledList, StyledPanelBox } from './CfDrawer.styles';

export type CfUncontrolledDrawerProps = {
  mode: 'menu' | 'panel';

  open: boolean;
  onClose?: () => void;

  displayAboveHeader?: boolean;
  placement?: 'left' | 'top' | 'right' | 'bottom';
  variant?: 'permanent' | 'persistent' | 'temporary';

  stopPropagation?: boolean;
  disablePortal?: boolean;
  keepMounted?: boolean;
  hideDividers?: boolean;
  keepOpenOnChange?: boolean;

  dataTestId?: string;

  drawerSx?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
  zIndex?: number;

  prefix?: ReactNode;
  suffix?: ReactNode;
};

/**
 * Styled wrapper of the MUI Drawer component
 *
 * @param mode - Value indicating if the drawer should be based off of a list or a panel component
 * @param open - Boolean indicating if the drawer is open
 * @param onClose - Method to call on close
 * @param displayAboveHeader - Boolean indicating if the drawer should be above the header navigation bar
 * @param placement - Value indicating how the anchor should place the drawer (left, top, right, bottom)
 * @param variant - The variant of drawer to use (permanent, persistent, temporary) Defaults to 'temporary'
 * @param stopPropagation - (Not used)
 * @param disablePortal - (Not used)
 * @param keepMounted - (Not used)
 * @param hideDivider - Boolan indicating if the divider should be hidden
 * @param keepOpenOnChange - Boolean indicating if the drawer should be kept open after selecting something
 * @param dataTestId - (Not used)
 * @param drawerSx - Styling for the drawer to use
 * @param containerSx - Styling for the list or panel (depending on the mode) to use
 * @param zIndex - The Z index for the drawer to use
 * @param prefix - The component to put at the top of the drawer
 * @param suffic - the component to put at the end of the drawer
 * @param children - Components to show in the list or panel (depending on the mode)
 */
export const CfUncontrolledDrawer: FC<PropsWithChildren<CfUncontrolledDrawerProps>> = (
  props: PropsWithChildren<CfUncontrolledDrawerProps>
) => {
  // Hooks
  // Constants
  const numChildren = React.Children.count(props.children);

  // Handlers
  const handleClose = (event?: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event?.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    props.onClose?.();
  };

  const handleItemClick = () => {
    !props.keepOpenOnChange && handleClose();
  };

  return (
    <StyledDrawer
      {...(props.zIndex ? { sx: { zIndex: props.zIndex } } : {})}
      drawerSx={props.drawerSx}
      displayAboveHeader={props.displayAboveHeader}
      open={props.open}
      onClose={(event: React.KeyboardEvent | React.MouseEvent) => handleClose(event)}
      variant={props.variant}
      anchor={props.placement}
    >
      {props.prefix}
      {props.mode === 'menu' ? (
        <StyledList tabIndex={0} sx={props.containerSx}>
          {React.Children.map(props.children, (child, index) => {
            return (
              <>
                <li onClick={handleItemClick}>{child}</li>
                {index < numChildren - 1 && !props.hideDividers && <Divider variant='fullWidth' />}
              </>
            );
          })}
        </StyledList>
      ) : (
        <StyledPanelBox sx={props.containerSx}>{props.children}</StyledPanelBox>
      )}
      {props.suffix}
    </StyledDrawer>
  );
};
