import { FileResult, ServiceResult } from '../models/api-shared.models';
import {
  CreateDraftMessageRequest,
  CreateDraftMessageResult,
  DeleteDraftMessageRequest,
  DeleteMessagesRequest,
  GetMessageExportPDFReportRequest,
  GetMessageResultData,
  GetMessagesRequest,
  GetMessagesResultData,
  GetUnreadMessagesCountResultData,
  SendMessageRequest,
  SendMessageResult,
  UpdateDraftMessageRequest,
  UpdateDraftMessageResult,
  UpdateMessageReadStatusRequest,
} from '../models/message.models';
import ApiService from './api-service';

class MessageService extends ApiService {
  private static classInstance: MessageService;

  private constructor() {
    super('Message');
  }

  public getMessage(messageId: string): ServiceResult<GetMessageResultData> {
    return this.get(`${this.baseUrl}/GetMessage`, { messageId });
  }

  public getMessages(request: GetMessagesRequest): ServiceResult<GetMessagesResultData> {
    return this.get(`${this.baseUrl}/GetMessages`, request);
  }

  public getUnreadMessagesCount(): ServiceResult<GetUnreadMessagesCountResultData> {
    return this.get(`${this.baseUrl}/GetUnreadMessagesCount`);
  }

  public sendMessage(request: SendMessageRequest): ServiceResult<SendMessageResult> {
    return this.post(`${this.baseUrl}/SendMessage`, request);
  }

  public createDraftMessage(request: CreateDraftMessageRequest): ServiceResult<CreateDraftMessageResult> {
    return this.post(`${this.baseUrl}/CreateDraftMessage`, request);
  }

  public updateDraftMessage(request: UpdateDraftMessageRequest): ServiceResult<UpdateDraftMessageResult> {
    return this.post(`${this.baseUrl}/UpdateDraftMessage`, request);
  }

  public deleteDraftMessage(request: DeleteDraftMessageRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/DeleteDraftMessage`, request);
  }

  public deleteMessages(request: DeleteMessagesRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/DeleteMessages`, request);
  }

  public getMessageExportPDFReport(request: GetMessageExportPDFReportRequest): ServiceResult<FileResult> {
    return this.post(`${this.baseUrl}/GetMessageExportPDFReport`, request);
  }

  public updateMessageReadStatus(request: UpdateMessageReadStatusRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/UpdateMessageReadStatus`, request);
  }

  public static getInstance(): MessageService {
    if (!this.classInstance) {
      this.classInstance = new MessageService();
    }
    return this.classInstance;
  }
}

export default MessageService;
