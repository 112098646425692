import { ServiceResult } from '../models';
import {
  GetMarketingCampaignsRequest,
  MarketingZone,
  UpdateMarketingCampaignStatisticsCountRequest,
  UpdateMarketingCampaignStatisticsViewRequest,
} from '../models/marketing-zones.models';
import ApiService from './api-service';

class MarketingZonesService extends ApiService {
  private static classInstance: MarketingZonesService;

  private constructor() {
    super('MarketingCampaign');
  }

  public getMarketingCampaigns(request: GetMarketingCampaignsRequest): ServiceResult<MarketingZone[]> {
    return this.post(`${this.baseUrl}/GetMarketingCampaigns`, request);
  }

  public updateMarketingCampaignStatisticsCount(request: UpdateMarketingCampaignStatisticsCountRequest) {
    return this.post(`${this.baseUrl}/UpdateMarketingCampaignStatisticsCount`, request);
  }

  public updateMarketingCampaignStatisticsViews(request: UpdateMarketingCampaignStatisticsViewRequest) {
    return this.post(`${this.baseUrl}/UpdateMarketingCampaignStatisticsView`, request);
  }

  public static getInstance(): MarketingZonesService {
    if (!this.classInstance) {
      this.classInstance = new MarketingZonesService();
    }
    return this.classInstance;
  }
}

export default MarketingZonesService;
