import { ServiceResult } from '../models/api-shared.models';
import { User, UpdateUserRequest, UpdateUser, GetUsersByCustomerAccessResultData } from '../models/user.models';
import ApiService from './api-service';

class UserService extends ApiService {
  private static classInstance: UserService;

  private constructor() {
    super('User');
  }

  public getCurrentUser(): ServiceResult<User> {
    return this.get(`${this.baseUrl}/GetCurrentUser`);
  }

  public getUsersByCustomerAccess(): ServiceResult<GetUsersByCustomerAccessResultData[]> {
    return this.get(`${this.baseUrl}/GetUsersByCustomerAccess`);
  }

  public updateUser(request: UpdateUserRequest): ServiceResult<UpdateUser> {
    return this.post(`${this.baseUrl}/UpdateUser`, request);
  }

  public resendContactEmailVerification(): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/ResendContactEmailVerification`, undefined);
  }

  public updateUserPrivacyPolicyAcceptance(): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/UpdateUserPrivacyPolicyAcceptance`, undefined);
  }

  public updateUserTermsOfUseAcceptance(): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/UpdateUserTermsOfUseAcceptance`, undefined);
  }

  public updateUserCookiesAcceptance(): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/UpdateUserCookiesAcceptance`, undefined);
  }

  public updateUserAnalyticCookiesAcceptance(isAccepted: boolean): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/UpdateUserAnalyticCookiesAcceptance`, { IsAccepted: isAccepted });
  }

  public updateLastProfileReviewDate(): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/UpdateLastProfileReviewDate`, undefined);
  }

  public static getInstance(): UserService {
    if (!this.classInstance) {
      this.classInstance = new UserService();
    }
    return this.classInstance;
  }
}

export default UserService;
