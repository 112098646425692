import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { History } from 'history';
import { selectCategoryProductsByProductKey } from '..';
import { CatalogProduct } from '../../api';
import { ProductListSortByType } from '../../api/models/api-shared.enums';
import {
  UpdateCustomProductRequest,
  UpdateCustomProductUOMRequest,
  UpdateIsCriticalItemRequest,
} from '../../api/models/customer-product.models';
import { ProductListCategory } from '../../api/models/product-list-category.models';
import {
  MoveToCategoryProductListDetailRequest,
  MovedToCategoryProductListDetail,
  ProductListDetail,
  SequenceProductListDetailRequest,
  UpdateHideProductRequest,
  UpdateProductHistoryRetentionRequest,
} from '../../api/models/product-list-detail.models';
import {
  GetProductListHeaderRequest,
  ProductListHeader,
  ProductListHeaderCustom,
  UpdateProductListHeaderRequest,
} from '../../api/models/product-list-header.models';
import { ProductListProduct, SearchProductListRequest } from '../../api/models/product-list-search.models';
import CustomerProductService from '../../api/services/customer-product.service';
import ProductListDetailService from '../../api/services/product-list-detail.service';
import ProductListHeaderService from '../../api/services/product-list-header.service';
import ProductListSearchService from '../../api/services/product-list-search.service';
import { generateId, generateStaticId, getProductListURL } from '../../helpers';
import { normalizeProductKey } from '../../helpers/general/product';
import QueueHelper from '../../helpers/general/queue';
import { validateStringEmptyOrMaxLength } from '../../helpers/validation/general';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { NotificationDisplayType, NotificationKeys, NotificationType } from '../../models';
import {
  ProductListCategoryCustom,
  ProductListCategoryProduct,
  ProductListHistoryState,
} from '../../models/product-list.models';
import { updateSelectedCustomerByCustomerId } from '../common/customer.thunks';
import { globalSlice } from '../common/global.slice';
import { setErrorDialogContent } from '../common/global.thunks';
import { AppDispatch, AppThunk, RootState } from '../store';
import { getProductListCategories } from './product-list-category.thunks';
import {
  productListSlice,
  selectAllCategories,
  selectAllCheckedCategoryProducts,
  selectAllCustomAttributesProductUpdates,
  selectCategoryById,
  selectCategoryByProductListCategoryId,
  selectCustomAttributesProductUpdate,
  selectProductListGridDataById,
  selectProductListProductById,
  selectProductListProductsWithCustomAttributes,
} from './product-list.slice';

const customerProductService = CustomerProductService.getInstance();
const productListHeaderService = ProductListHeaderService.getInstance();
const productListDetailService = ProductListDetailService.getInstance();
const productListSearchService = ProductListSearchService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

const queueHelper = QueueHelper.getInstance();

/**
 * Clears product list category id cache, product list grid data cache, and sets the product list state to its initial value
 *
 * @returns void
 */
export const resetProductListState = (): AppThunk => async (dispatch: AppDispatch) =>
  dispatch(productListSlice.actions.resetState());

/**
 * Sets the ProductListState's categories and categoryProducts to their corresponding backup property's value (e.g. categoriesBackup)
 *
 * @returns void
 */
export const restoreProductListSearch = (): AppThunk => async (dispatch: AppDispatch) =>
  dispatch(productListSlice.actions.restoreProductListSearch());

// Product List Header
/**
 * Checks the current ProductListState and dispatches the getProductListHeader thunk if the producstListHeaderId parameter doesn't currently match the state
 *
 * @param productListHeaderId - the id of the product list header data to retrieve from the api
 * @param onSuccess - callback function executed when a successful response is received from the api OR when the product list header data associated to the productListHeaderId parameter value matches what is stored in the ProductListState.
 * @param onFailure - callback function executed when an unsuccessful response is received from the api
 * @returns void
 */
export const getActiveProductListHeader =
  (productListHeaderId: string, onSuccess?: () => void, onFailure?: () => void): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const productListHeader = getState().productList.productListHeader;
    if (productListHeaderId) {
      if (
        !productListHeader ||
        productListHeaderId.toLocaleLowerCase() !== productListHeader.ProductListHeaderId.toLocaleLowerCase()
      ) {
        await dispatch(
          getProductListHeader(
            productListHeaderId,
            (data) => {
              dispatch(productListSlice.actions.setProductListHeader(data));
            },
            (messages) => {
              dispatch(setErrorDialogContent('Error occurred', messages));
              onFailure?.();
            }
          )
        );
      } else if (
        productListHeader &&
        productListHeaderId.toLocaleLowerCase() === productListHeader.ProductListHeaderId.toLocaleLowerCase()
      ) {
        onSuccess?.();
      }
    }
  };

/**
 * Dispatches the searchProductList thunk with a constructed request derived from the ProductListState and CustomerState
 *
 * @returns void
 */
export const refreshProductListSearchResults =
  (): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const productListHeaderId = getState().productList.productListHeader?.ProductListHeaderId;
    const customerId = getState().customer.selectedCustomer?.CustomerId;
    const apiRequest = getState().productList.apiRequest;
    if (productListHeaderId) {
      dispatch(
        searchProductList(
          {
            queryText: apiRequest?.QueryText ?? '',
            productListHeaderId: productListHeaderId,
            customerId: customerId,
            sortByType: apiRequest?.SortByType,
          },
          undefined,
          true
        )
      );
    }
  };

/**
 * Retrieves product list header data from the api and executes the onSuccess or onFailure callback method based on the status of the api response.
 *
 * @param productListHeaderId - the id of the product list header data to retrieve from the api
 * @param onSuccess - callback function executed when a successful response is received from the api
 * @param onFailure - callback function executed when an unsuccessful response is received from the api
 * @returns void
 */
export const getProductListHeader =
  (
    productListHeaderId: string,
    onSuccess?: (data: ProductListHeaderCustom) => void,
    onFailure?: (messages: string[]) => void
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
      const selectedCustomerId = getState().customer.selectedCustomer?.CustomerId;
      if (!selectedCustomerId) return;

      const request: GetProductListHeaderRequest = {
        ProductListHeaderId: productListHeaderId,
        customerId: selectedCustomerId,
      };

      const { data } = await productListHeaderService.getProductListHeader(request);
      if (data.IsSuccess) {
        onSuccess?.(data.ResultObject);
      } else {
        onFailure?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

// Manage categories - Grid
/**
 * Dispatches the getProductListCategories thunk and sets the ProductListState's categories data
 *
 * @param productListHeaderId - the productListHeaderId passed to the api to retrieve its affiliated categories data
 * @returns void
 */
export const getManageProductListCategories =
  (productListHeaderId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      if (!productListHeaderId) return;

      dispatch(productListSlice.actions.setProductListLoading(true));
      await dispatch(
        getProductListCategories(
          productListHeaderId,
          false,
          (data) => {
            dispatch(productListSlice.actions.manageProductListCategories(data));
          },
          (errorMessages) => {
            dispatch(setErrorDialogContent('Error occurred', errorMessages));
          }
        )
      );
    } finally {
      dispatch(productListSlice.actions.setProductListLoading(false));
    }
  };

// Product List - Grid
/**
 * Retrieves product data related to the specified product list from the api, stores the results in the History state, and updates the CustomerState's selectedCustomer based on the request parameter's customerId property.
 *
 * @param request.queryText - filters the product results
 * @param request.productListHeaderId - determines which product list to search
 * @param request.customerId - updates the CustomerState's selectedCustomer
 * @param request.sortByType - determines which property to sort the results by
 * @param request.history - used to persist the latest request parameters
 * @param onFailure - callback method that is executed when the customerId or productListHeaderId are undefined, OR the api response returns zero results, OR the api response is unsuccessful
 * @param allowDuplicateRequests - determines whether to send the same request that was previously sent to the api
 * @returns void
 */
export const searchProductList =
  (
    request: {
      queryText: string;
      productListHeaderId?: string;
      customerId?: string;
      sortByType?: ProductListSortByType;
      history?: History<ProductListHistoryState>;
    },
    onFailure?: () => void,
    allowDuplicateRequests?: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!request.customerId || !request.productListHeaderId) return onFailure?.();

      dispatch(updateSelectedCustomerByCustomerId(request.customerId));

      // Handle history state
      const history = request.history;
      const historyState = history?.location?.state;
      const oldHistoryKey = history?.location.key;

      // Handle new header and sort options
      if (getState().productList.productListHeader?.ProductListHeaderId !== request.productListHeaderId) {
        // Fetch from history state
        if (
          historyState?.productListHeader &&
          request.productListHeaderId === historyState.productListHeader.ProductListHeaderId
        ) {
          dispatch(productListSlice.actions.setProductListHeader(historyState.productListHeader));
          if (getState().productList.sortByOptions.length === 0) {
            await dispatch(getProductListMaintenanceSort(undefined, onFailure));
          }
          // Fetceh from api
        } else {
          await dispatch(getActiveProductListHeader(request.productListHeaderId, undefined, onFailure));
          await dispatch(getProductListMaintenanceSort(undefined, onFailure));
        }
      }

      dispatch(clearCustomAttributesProductUpdates());

      // TODO: remove this once manage cateogires has been moved to a new slice, avoids duplicate calls when refreshing page
      if (historyState?.manageCategoriesMode) return;

      // Search
      const selectedCustomer = getState().customer.selectedCustomer;
      const productListHeaderId =
        getState().productList.productListHeader?.ProductListHeaderId ?? request?.productListHeaderId;

      const searchValue = request.queryText;
      const sortByType = request.sortByType ?? getState().productList.sortByOptions?.[0]?.Value;

      if (!selectedCustomer || !productListHeaderId || sortByType === undefined) return onFailure?.();

      const apiRequest: SearchProductListRequest = {
        CustomerId: selectedCustomer.CustomerId,
        ProductListHeaderId: productListHeaderId,
        QueryText: searchValue,
        SortByType: Number(sortByType),
      };

      const stateRequest = getState().productList.apiRequest;

      // Data in store is for search with different parameters
      if (allowDuplicateRequests || JSON.stringify(apiRequest) !== JSON.stringify(stateRequest)) {
        productListSearchService.abortSearchProductList();
        dispatch(productListSlice.actions.resetProductSearchResults());
      } else {
        return;
      }

      dispatch(productListSlice.actions.setProductListLoading(true));

      const { data } = await productListSearchService.searchProductList(apiRequest);

      if (data.IsSuccess) {
        dispatch(
          productListSlice.actions.setProductSearchResults({
            request: apiRequest,
            result: data.ResultObject,
          })
        );

        // Update sort by type
        if (request.sortByType !== sortByType) {
          const newHistoryKey = history?.location.key;
          // User may have navigated away while the API request was in progress
          if (oldHistoryKey === newHistoryKey) {
            history?.replace?.(
              getProductListURL(request.customerId, request.productListHeaderId, sortByType?.toString(), searchValue),
              history.location.state
            );
          }
        }
      } else {
        dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
      }

      dispatch(productListSlice.actions.setProductListLoading(false));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

// Product List - Parameters
/**
 * Toggles the boolean flag representing whether the user is editing the custom attributes of products within the active product list (i.e. ProducstListState.productListEditAllCustomAttributesMode)
 *
 * @param callBack - callback method executed after the flag is toggled.
 * @returns void
 */
export const changeProductListEditAllCustomAttributesMode =
  (callBack?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(
      productListSlice.actions.setProductListEditAllCustomAttributesMode(
        !getState().productList.productListEditAllCustomAttributesMode
      )
    );
    if (callBack) callBack();
  };

// Selects
/**
 * Retrieves the sort options for product lists from the api and sets the ProductListState.sortByOptions when the response is successful. Unsuccessful responses do not affect the state.
 *
 * @param onSuccess - the callback method executed when the api response is successful
 * @param onFailure - the callback method executed when the api response is unsuccessful
 * @returns void
 */
export const getProductListMaintenanceSort =
  (onSuccess?: () => void, onFailure?: () => void): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
      const productListHeaderId = getState().productList.productListHeader?.ProductListHeaderId;
      if (!productListHeaderId) return;

      const { data } = await productListSearchService.getProductListMaintenanceSort(productListHeaderId);
      if (data.IsSuccess) {
        dispatch(productListSlice.actions.setSortByOptions(data.ResultObject));
        onSuccess?.();
      } else {
        onFailure?.();
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Dispatches the getProductListCategories thunk and sets the categorySelectOptions property of the ProductListState
 *
 * @param productListHeaderId - passed to the getProductListCategories thunk to indicate which product list's category options to retrieve
 * @returns void
 */
export const getProductListCategorySelectOptions =
  (productListHeaderId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(
      getProductListCategories(
        productListHeaderId,
        true,
        (data) => {
          dispatch(productListSlice.actions.setCategorySelectOptions(data));
        },
        (errorMessages) => dispatch(setErrorDialogContent('Error occurred', errorMessages))
      )
    );
  };

// Products - updates
/**
 * Sends a request to the api to update the isCriticalItem property of the specified product and updates the ProductListState accordingly
 *
 * @param isCriticalItem - the value to set the product's isCriticalItem property to
 * @param productKey - indicates which product to update
 * @returns void
 */
export const updateProductIsCriticalItem =
  (isCriticalItem: boolean, productKey?: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const customerId = getState().customer.selectedCustomer?.CustomerId;

      if (!productKey || !customerId) return;

      const request: UpdateIsCriticalItemRequest = {
        CustomerId: customerId,
        ProductKey: productKey,
        IsCriticalItem: isCriticalItem,
      };

      const { data } = await customerProductService.updateIsCriticalItem(request);
      if (data.IsSuccess) {
        dispatch(productListSlice.actions.updateProductIsCriticalItem(data.ResultObject));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sends a request to the api to update the isHidden property of the specified product and updates the ProductListState accordingly
 *
 * @param isHidden - the value to set the product's isHidden property to
 * @param productKey - indicates which product to update
 * @returns void
 */
export const updateHideProductResult =
  (isHidden: boolean, productKey?: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const customerId = getState().customer.selectedCustomer?.CustomerId;

      if (!productKey || !customerId) return;

      const request: UpdateHideProductRequest = {
        CustomerId: customerId,
        ProductKey: productKey,
        IsHidden: isHidden,
      };

      const { data } = await productListDetailService.updateHideProductResult(request);
      if (data.IsSuccess) {
        dispatch(productListSlice.actions.updateProductIsHidden({ isHidden: isHidden, productKey: productKey }));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sends a request to the api to update the isRetained property of the specified product and updates the ProductListState accordingly
 *
 * @param isRetained - the value to set the product's isRetained property to
 * @param productKey - indicates which product to update
 * @returns void
 */
export const updateProductHistoryRetention =
  (isRetained: boolean, productKey?: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const customerId = getState().customer.selectedCustomer?.CustomerId;

      if (!productKey || !customerId) return;

      const request: UpdateProductHistoryRetentionRequest = {
        CustomerId: customerId,
        ProductKey: productKey,
        IsRetained: isRetained,
      };

      const { data } = await productListDetailService.updateProductHistoryRetention(request);
      if (data.IsSuccess) {
        dispatch(
          productListSlice.actions.updateCategoryProductIsRetained({ isRetained: isRetained, productKey: productKey })
        );
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Updates the isChecked property of the specified product in the ProductListState
 *
 * @param isChecked - the value to set the product's isChecked property to
 * @param productKey - indicates which product to update
 * @returns void
 */
export const updateProductChecked =
  (isChecked: boolean, productKey?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (!productKey) return;
    dispatch(productListSlice.actions.updateProductIsChecked({ isChecked: isChecked, productKey: productKey }));
  };

/**
 * Updates the isChecked property of all products in the ProductListState
 *
 * @param isChecked - the value to set the isChecked property of all products in ProductListState to
 * @returns void
 */
export const updateAllProductsChecked =
  (isChecked: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(productListSlice.actions.updateProductAllChecked({ isChecked: isChecked }));
  };

// Category Products
/**
 * Sends a request to the api to move selected products of the active product list to the specified category
 *
 * @param productListCategoryId - indicates which category to move the selected products to
 * @param repositionProducts - determines whether to dispatch sequenceCategoryProducts
 * @returns void
 */
export const moveCheckedCategoryProducts =
  (productListCategoryId: string, repositionProducts: boolean): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const productListHeaderId = getState().productList.productListHeader?.ProductListHeaderId;
      const customerId = getState().customer.selectedCustomer?.CustomerId;
      const products = selectAllCheckedCategoryProducts(getState());
      if (!productListHeaderId || !customerId || products.length <= 0) return;

      //get old product category ids
      let oldCategoryDict = new Map<string, string>();
      products.forEach((p) => {
        const category = selectCategoryById(getState().productList.categories, p.CategoryId);
        if (!category || !category.ProductListCategoryId) return;
        oldCategoryDict.set(p.Id, category.ProductListCategoryId);
      });

      // Call API, handles failure
      dispatch(
        moveToCategoryProductListDetails({
          ProductListHeaderId: productListHeaderId,
          ProductListCategoryId: productListCategoryId,
          CustomerId: customerId,
          Products: products.map(
            (p) =>
              ({
                ProductKey: p.ProductKey,
                ProductListDetailId: p.ProductListDetailId,
                OldCategoryId: oldCategoryDict.get(p.Id),
              } as MovedToCategoryProductListDetail)
          ),
        } as MoveToCategoryProductListDetailRequest)
      );

      // Update Position, don't call api, use bulk call (moveToCategoryProductListDetail)
      if (repositionProducts) {
        dispatch(sequenceCategoryProducts(customerId, productListCategoryId, products, false));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Dispatches the sequenceCategoryProduct thunk for each product provided within the specific category
 *
 * @param customerId - passed to the sequenceCategoryProduct thunk
 * @param productListCategoryId - used to select the category from the ProductListState's product list
 * @param products - the list of products to sequence
 * @param callApi - passed to the sequenceCategoryProduct thunk
 * @returns void
 */
export const sequenceCategoryProducts =
  (
    customerId: string,
    productListCategoryId: string,
    products: ProductListCategoryProduct[],
    callApi = true
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const category = selectCategoryByProductListCategoryId(getState().productList, productListCategoryId);
      if (!category) return;
      const categoryId = category.Id;
      products.forEach((p) => {
        const category = selectCategoryById(getState().productList.categories, categoryId);
        if (category) {
          const finalCategoryProductId = category.CategoryProductIds[category.CategoryProductIds.length - 1];
          if (finalCategoryProductId) {
            dispatch(
              sequenceCategoryProduct(
                {
                  customerId,
                  currentItemId: finalCategoryProductId,
                  sourceItemId: p.Id,
                  positionOffset: 1,
                },
                callApi
              )
            );
          } else {
            dispatch(
              sequenceCategoryProduct(
                {
                  customerId,
                  currentItemId: categoryId,
                  sourceItemId: p.Id,
                  positionOffset: 0,
                },
                callApi
              )
            );
          }
        }
      });
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Updates the ProductListState to reposition a product within a product list and sends a request to the api when specified
 *
 * @param request.customerId - passed to the squenceProductListDetail thunk
 * @param request.currentItemId - the id of the item that user has swapped the source item with
 * @param request.sourceItemId - the id of the item that a user is actively moving
 * @param request.positionOffset - adjusts the new position of the source item based on its position within/outside of a category
 * @param request.callApi - determines whether to send a request to the api
 * @returns void
 */
export const sequenceCategoryProduct =
  (
    request: {
      customerId?: string;
      currentItemId: string;
      sourceItemId: string;
      positionOffset: number;
      isBelowProduct?: boolean;
      isAboveCategoryLastItem?: boolean;
    },
    callApi = true
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    // Inserts the sourceItemId (category product) at currentItemId's position within a category
    // sourceItem's sequence becomes that of the current items, if a category product
    // offset position to handle dropping above, below and swapping
    try {
      const customerId = request.customerId ?? getState().customer.selectedCustomer?.CustomerId;

      if (!customerId) return;

      const currentItem = selectProductListGridDataById(getState(), request.currentItemId);
      const item = selectProductListGridDataById(getState(), request.sourceItemId);
      let sequenceOffset = 0;
      if (request.isAboveCategoryLastItem) {
        sequenceOffset = 1;
      } else if (request.isBelowProduct) {
        sequenceOffset = currentItem?.categoryProduct?.CategoryId === item?.categoryProduct?.CategoryId ? 0 : 1;
      }

      if (currentItem?.category && item?.categoryProduct) {
        let index = request.positionOffset ?? 0;
        let sequence = sequenceOffset;
        if (currentItem.categoryProduct?.Sequence !== undefined) {
          // currentItem is categoryProduct
          index = currentItem.category.CategoryProductIds.indexOf(request.currentItemId) + request.positionOffset;
          sequence = currentItem.categoryProduct.Sequence + sequenceOffset;
        } else {
          // currentItem is category
          sequence = 0;
        }
        dispatch(productListSlice.actions.setProductListSearchBackup());
        const sequenceRequest = {
          categoryProductId: item.categoryProduct.Id,
          categoryId: currentItem.category?.Id,
          index: index,
          sequence: sequence,
        };
        dispatch(productListSlice.actions.sequenceCategoryProduct(sequenceRequest));

        if (callApi) {
          // enqueing the SequenceProductListDetail call
          queueHelper.enqueue(
            sequenceProductListDetail({
              CustomerId: request.customerId ?? customerId,
              ProductListDetailId: item.categoryProduct.ProductListDetailId,
              ProductListCategoryId: currentItem.category.ProductListCategoryId,
              Sequence: sequence,
            })
          );

          // The queue processing will take care of sequential processing of API calls
          await queueHelper.process(dispatch);
        }
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sends a request to the api to update the sequence of an item within a product list category (e.g. subcategory, product)
 *
 * @param request.CustomerId - the customer id of the selected product list
 * @param request.ProductListDetailId - the id of the item within the product list category (e.g. subcategory, product)
 * @param request.ProductListCategoryId - the id of the category within the product list
 * @param request.Sequence - the position of the item within its category
 * @returns void
 */
export const sequenceProductListDetail =
  (request: SequenceProductListDetailRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await productListDetailService.sequenceProductListDetail(request);
      if (!data.IsSuccess) {
        queueHelper.empty();
        dispatch(refreshProductListSearchResults());
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      queueHelper.empty();
      dispatch(refreshProductListSearchResults());
      dispatch(globalSlice.actions.setErrorDialogContent({ messages: ['Something went wrong. Try again'] }));
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sends a request to the api to move multiple products within a product list to a specified category.
 * A successful response will dispatch a toast notification while an unsuccessful response will restore the ProductListState's categories and category products to their respective backups.
 *
 * @param request.ProductListHeaderId - the id of the product list
 * @param request.ProductListCategoryId - the id of the category of the product list
 * @param request.CustomerId - the id of the customer of the product list
 * @param request.Products - a list of products to move to the specified category
 * @returns void
 */
export const moveToCategoryProductListDetails =
  (request: MoveToCategoryProductListDetailRequest): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { data } = await productListDetailService.moveToCategoryProductListDetail(request);
      if (!data.IsSuccess) {
        dispatch(restoreProductListSearch());
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      } else {
        const message =
          'Item successfully moved to ' +
          selectCategoryByProductListCategoryId(getState().productList, request.ProductListCategoryId).CategoryTitle;
        dispatch(
          globalSlice.actions.upsertAppNotification({
            Id: generateStaticId('toastNotification', ['confirm-move-product']),
            NotificationType: NotificationType.Success,
            NotificationDisplayType: NotificationDisplayType.Toast,
            AutoDismiss: 10,
            CanUserDismiss: true,
            Key: NotificationKeys.Toast,
            Message: message,
          })
        );
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Removes a specified product from all categories of the active product list
 *
 * @param productListProduct - the product to be removed
 * @returns void
 */
export const removeProductListProduct =
  (productListProduct: ProductListProduct): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const categoryProducts = selectCategoryProductsByProductKey(
      getState().productList,
      normalizeProductKey(productListProduct.ProductKey)
    );
    categoryProducts.forEach((cp) => {
      dispatch(
        productListSlice.actions.removeCategoryProduct({
          categoryId: cp.CategoryId,
          productId: cp.Id,
          productDetailId: cp.ProductListDetailId,
        })
      );
      dispatch(
        productListSlice.actions.resequenceCategoryProducts({
          categoryId: cp.CategoryId,
        })
      );
    });
  };

/**
 * Removes a specified product from a category of the active product list in the ProductListState
 *
 * @param productListDetail - the data to be removed from the category of the product list
 * @returns void
 */
export const removeCategoryProduct =
  (productListDetail: ProductListCategoryProduct): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(
      productListSlice.actions.removeCategoryProduct({
        categoryId: productListDetail.CategoryId,
        productId: productListDetail.Id,
        productDetailId: productListDetail.ProductListDetailId,
      })
    );
    dispatch(
      productListSlice.actions.resequenceCategoryProducts({
        categoryId: productListDetail.CategoryId,
      })
    );
  };

/**
 * Sends a request to the api to remove a product from a category of the active product list and dispatches removeCategoryProduct if a sucessful response is received
 *
 * @param productListDetail - the data to be removed from the category of the product list
 * @returns void
 */
export const deleteProductListDetail =
  (productListDetail: ProductListCategoryProduct): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await productListDetailService.deleteProductListDetail({
        productListDetailId: productListDetail.ProductListDetailId,
      });
      if (data.IsSuccess) {
        dispatch(removeCategoryProduct(productListDetail));
        dispatch(
          productListSlice.actions.resequenceCategoryProducts({
            categoryId: productListDetail.CategoryId,
          })
        );
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

// Categories TODO, move to categories thunk
/**
 * Updates the ProductListState state to set a category to be edited by the EditCategoryDialog component
 *
 * @param category - the category data to be edited
 * @returns void
 */
export const setCategoryToEdit =
  (category: ProductListCategoryCustom | undefined): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(productListSlice.actions.setCategoryToEdit(category));
  };

/**
 * Updates the ProductListState to set a category to be deleted by the DeleteCategoryDialog component
 *
 * @param category - the category data to be deleted
 * @returns void
 */
export const setCategoryToDelete =
  (category: ProductListCategoryCustom | undefined): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(productListSlice.actions.setCategoryToDelete(category));
  };

/**
 * Adds a category to the ProductListState's categories and categorySelectOptions properties
 *
 * @param category - the data of the category to be added
 * @returns void
 */
export const addCategory =
  (category: ProductListCategory): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const id = generateId();

    // Find the parent's id
    let parentCategory = undefined;
    if (category.ParentProductListCategoryId) {
      const categories = selectAllCategories(getState().productList.categories);
      for (let i = 0; i < categories.length; i++) {
        const existingCategory = categories[i];
        if (existingCategory.ProductListCategoryId === category.ParentProductListCategoryId) {
          parentCategory = existingCategory;
          break;
        }
      }
    }

    dispatch(productListSlice.actions.addCategorySelectOption(category));
    dispatch(productListSlice.actions.addCategory({ newCategory: category, id, parentId: parentCategory?.Id }));
  };

/**
 * Removes a category from the ProductListState
 *
 * @param categoryId - the id representing the category to be removed
 * @returns void
 */
export const removeCategory =
  (categoryId: string): AppThunk =>
  async (dispatch: AppDispatch) =>
    dispatch(productListSlice.actions.removeCategory({ categoryId }));

// Custom Attributes - Editing
/**
 * Sets the Editing property of all products in the ProductListState to true
 *
 * @returns void
 */
export const setAllCustomAttributeProductEditing = (): AppThunk => async (dispatch: AppDispatch) =>
  dispatch(productListSlice.actions.updateProductAllEditing({ isEditing: true }));

/**
 * Sets the Editing property of a specified product in the ProductListState to true
 *
 * @param productKey - the guid representing the product to be edited
 * @returns void
 */
export const setCustomAttributeProductEditing =
  (productKey?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    productKey = normalizeProductKey(productKey);
    dispatch(productListSlice.actions.updateProductIsEditing({ isEditing: true, productKey: productKey }));
  };

/**
 * Sets the Editing property of a specified product in the ProductListState to false and removes any pending changes associated to that product's custom attributes
 *
 * @param productKey - the guid representing the product
 * @returns void
 */
export const removeCustomAttributeProductEditing =
  (productKey?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    productKey = normalizeProductKey(productKey);
    dispatch(productListSlice.actions.updateProductIsEditing({ isEditing: false, productKey: productKey }));
    dispatch(productListSlice.actions.removeCustomAttributesProductUpdate(productKey));
  };

// Custom Attribute - Updates
/**
 * Adds a pending custom attribute change to the ProductListState
 *
 * @param productUpdate - the data to update a product's custom attributes with
 * @returns void
 */
export const addEditingCustomAttributesProductUpdate =
  (productUpdate: UpdateCustomProductRequest): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch): Promise<void> => {
    if (!productUpdate.ProductKey) return;
    dispatch(productListSlice.actions.upsertCustomAttributesProductUpdate(productUpdate));
  };

/**
 * Sets the Editing property of a specified product in the ProductListState to false and removes any pending changes associated to all products' custom attributes
 *
 * @returns void
 */
export const clearCustomAttributesProductUpdates =
  (): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(productListSlice.actions.setProductListEditAllCustomAttributesMode(false));
    dispatch(productListSlice.actions.clearCustomAttributesProductUpdates());
    dispatch(productListSlice.actions.updateProductAllEditing({ isEditing: false }));
  };

/**
 * Removes a product's pending custom attribute change from the ProductListState
 *
 * @param productKey - the guid representing the product with pending custom attribute changes
 * @returns void
 */
export const removeEditingCustomAttributesProductUpdate =
  (productKey?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    productKey = normalizeProductKey(productKey);
    dispatch(productListSlice.actions.removeCustomAttributesProductUpdate(productKey));
  };

// Custom Attributes - Reset
/**
 * Dispatches the resetProductCustomAttributes thunk for every product with custom attributes and
 * dispatches the clearCustomAttributesProductUpdates thunk to remove any pending changes to product
 * custom attributes.
 *
 * @param lorem - lorem
 * @returns void
 */
export const resetAllProductsCustomAttributes =
  (): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const productsWithCustAttr = selectProductListProductsWithCustomAttributes(getState().productList);
    const customerId = getState().customer.selectedCustomer?.CustomerId;

    await dispatch(clearCustomAttributesProductUpdates());
    if (!productsWithCustAttr || productsWithCustAttr.length < 1 || !customerId) return;

    await Promise.all(productsWithCustAttr.map((p) => dispatch(resetProductCustomAttributes(p.ProductKey))));
  };

/**
 * Generates an update request for the specified product to set custom attributes to white-space
 * (the api is currently setup to convert white-space values to the product's original values)
 * and dispatches the addEditingCustomAttributesProductUpdate and saveEditingCustomAttributesProductUpdate thunks
 *
 * @param productKey - the guid represetng the product to reset
 * @returns void
 */
export const resetProductCustomAttributes =
  (productKey?: string): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    productKey = normalizeProductKey(productKey);
    const customerId = getState().customer.selectedCustomer?.CustomerId;
    const product = selectProductListProductById(getState().productList.products, productKey);
    if (!customerId || !product) return;
    const canEditDescription = product.CanEditProductDescription;
    const canEditProductNumber = product.CanEditProductNumber;

    // We reset custom description and item number by setting
    // to a non-empty string. Using an empty string e.g. '' will
    // not take effect.

    const request: UpdateCustomProductRequest = {
      ProductKey: productKey,
      CustomProductDescription: canEditDescription ? ' ' : undefined,
      CustomerId: customerId,
      UnitOfMeasures: canEditProductNumber
        ? product.UnitOfMeasureOrderQuantities.map((oo) => {
            const uom: UpdateCustomProductUOMRequest = {
              CustomItemNumber: ' ',
              UnitOfMeasure: oo.UnitOfMeasure,
            };
            return uom;
          })
        : undefined,
    };
    await dispatch(addEditingCustomAttributesProductUpdate(request));
    await dispatch(saveEditingCustomAttributesProductUpdate(productKey));
  };

// Custom Attributes - post and save
/**
 * Loops through all product custom attribute updates stored in the ProductListState and dispatches the saveEditingCustomAttributesProductUpdate thunk
 * and then clears all custom attribute updates stored in the ProductListState
 *
 * @param lorem - lorem
 * @returns void
 */
export const saveAllEditingCustomAttributesProductUpdates =
  (): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
    const updates = selectAllCustomAttributesProductUpdates(getState().productList);
    if (updates && updates.length > 0) {
      updates.forEach((u) => u?.ProductKey && dispatch(saveEditingCustomAttributesProductUpdate(u.ProductKey)));
    }
    setTimeout(() => dispatch(clearCustomAttributesProductUpdates()), 0);
  };

/**
 * Sends a request to the api to update a specified product's custom attributes and preemptively updates the ProductListState.
 * If the api response is successful then the ProductListState's product data is updated with the response.
 * Else if the api repsonse is unsuccessful then the ProductListState's product data is restored.
 *
 * @param productKey - the guid representing the product to be updated
 * @returns void
 */
export const saveEditingCustomAttributesProductUpdate =
  (productKey?: string): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const _productKey = normalizeProductKey(productKey);
    const update = selectCustomAttributesProductUpdate(getState().productList, _productKey);
    const product = selectProductListProductById(getState().productList.products, _productKey);
    const customerId = getState().customer.selectedCustomer?.CustomerId;
    const isEditingAllCustomAttributes = getState().productList.productListEditAllCustomAttributesMode;

    if (!productKey || !customerId) return;
    if (!update) {
      dispatch(removeCustomAttributeProductEditing(productKey));
      return;
    }

    if (!getState().productList.productListLoading) {
      dispatch(productListSlice.actions.setProductListLoading(true));
    }

    try {
      const backupRequest: UpdateCustomProductRequest = {
        CustomerId: customerId,
        ProductKey: productKey,
        CustomProductDescription: product?.CustomProductDescription,
        UnitOfMeasures: product?.UnitOfMeasureOrderQuantities?.map((uom) => {
          return {
            UnitOfMeasure: uom.UnitOfMeasure,
            CustomItemNumber: uom.ProductNumberDisplay,
          } as UpdateCustomProductUOMRequest;
        }),
      };
      const request: UpdateCustomProductRequest = {
        CustomerId: customerId,
        ProductKey: productKey,
        CustomProductDescription: update.CustomProductDescription,
        UnitOfMeasures: update.UnitOfMeasures,
      };

      if (!isEditingAllCustomAttributes) dispatch(removeCustomAttributeProductEditing(productKey));

      // Pre-emptively save updates
      dispatch(productListSlice.actions.saveEditingCustomAttributesProductUpdate(request));

      const { data } = await customerProductService.updateCustomProduct(request);
      if (data.IsSuccess) {
        dispatch(productListSlice.actions.saveEditingCustomAttributesProductUpdate(data.ResultObject));
      } else {
        dispatch(productListSlice.actions.saveEditingCustomAttributesProductUpdate(backupRequest));
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(productListSlice.actions.setProductListLoading(false));
    }
  };

// Product List
/**
 * Sends a request to the api to update Product List data (e.g. title, isPrivate)
 *
 * @param productListHeaderId - the id representing the product list to affect
 * @param productListTitle - the title of the product list
 * @param isPrivate - a boolean flag indicating whether the product list is private
 * @param successCallback - a callback method executed when the api response is successful
 * @param errorCallback - a callback metehod executed when the api response is unsuccessful
 * @returns void
 */
export const updateProductList =
  (
    productListHeaderId: string,
    productListTitle: string,
    isPrivate: boolean,
    successCallback?: (productListHeader: ProductListHeader) => void | Promise<void>,
    errorCallback?: (errors: string[]) => void | Promise<void>
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(productListSlice.actions.setProductListDialogLoading(true));
      const validationMessages = validateStringEmptyOrMaxLength('Product list title', productListTitle, 50);
      if (validationMessages.length > 0) {
        errorCallback?.(validationMessages);
        return;
      }

      const customerId = getState().customer.selectedCustomer?.CustomerId;
      if (!customerId) return;

      const request: UpdateProductListHeaderRequest = {
        ProductListHeaderId: productListHeaderId,
        ProductListTitle: productListTitle,
        CustomerId: customerId,
        IsPrivate: isPrivate,
      };

      const { data } = await productListHeaderService.updateProductListHeader(request);

      if (data.IsSuccess) {
        dispatch(productListSlice.actions.updateProductList(data.ResultObject));
        successCallback?.(data.ResultObject);
      } else {
        errorCallback?.(data.ErrorMessages);
        dispatch(setErrorDialogContent('Error occured', data.ErrorMessages));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(productListSlice.actions.setProductListDialogLoading(false));
    }
  };

/**
 * Retrieves recently purchased product data associated to the product list and customer from the api
 *
 * @param productListHeaderId - id representing the product list
 * @returns void
 */
export const getProductListRecentPurchases =
  (productListHeaderId: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const customerId = getState().customer.selectedCustomer?.CustomerId;
      if (!customerId) return;

      const { data } = await productListHeaderService.getProductListRecentPurchase(productListHeaderId, customerId);

      if (data.IsSuccess) {
        dispatch(productListSlice.actions.updateProductListRecentPurchases(data.ResultObject));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Updates the ProductListState by adding a product to the categories, categoryProducts, and products properties and removing it from the recentlyPurchasedProducts property.
 *
 * @param productListDetail - represents the relationship between product and category and added to the categoryProducts property
 * @param product - data representing the product
 * @param sortByType - currently unused
 * @returns void
 */
export const addRecentPurchasedProductToList =
  (productListDetail: ProductListDetail, product: CatalogProduct, sortByType: ProductListSortByType): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(
        productListSlice.actions.addRecentPurchasedProductToList({
          productListDetail,
          product,
          sortByType,
        })
      );
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Updates the ProdcutListState's showAddToListDialog property to bused by the AddToListDialog component
 *
 * @param open - a boolean flag representing whether the dialog should be open and shown or closed and hidden
 * @returns void
 */
export const toggleAddToListDialog =
  (open: boolean): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(productListSlice.actions.setShowAddToListDialog(open));
  };

/**
 * Updates the ProductListState's showAddNewListDialog property to be used by the AddToListCreateNewDialog component
 *
 * @param open - a boolean flag representing whether the dialog should be open and shown or closed and hidden
 * @returns void
 */
export const toggleShowNewListDialog =
  (open: boolean): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(productListSlice.actions.setShowNewListDialog(open));
  };
