// Adapters
import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import {
  AccountReceivableBalance,
  AccountReceivableDetail,
  AccountReceivableDetailStatus,
  AccountReceivableDetailStatusType,
  AccountReceivableDetailType,
  GetAccountReceivableBalanceRequest,
  GetAccountReceivableDetailRequest,
} from '../../api/models/account-receivable.models';
import { Customer } from '../../api/models/customer.models';
import { filterObjects, normalizeKey, sortBy } from '../../helpers';
import { toLocalDateString, toLocalFullDateString } from '../../helpers/general/date';
import { RootState } from '../store';

const accountReceivableDetailAdapter = createEntityAdapter<AccountReceivableDetail>({
  selectId: (detail: AccountReceivableDetail) => detail.AccountReceivableDetailKey.toString(),
});

// State
interface AccountReceivableStatementState {
  getAccountReceivableBalanceRequest: GetAccountReceivableBalanceRequest | undefined;
  accountReceivableBalance: AccountReceivableBalance | undefined;
  accountReceivableBalanceLoading: boolean;

  getAccountReceivableDetailRequest: GetAccountReceivableDetailRequest | undefined;
  accountReceivableDetails: EntityState<AccountReceivableDetail>;
  accountReceivableDetailsLoading: boolean;

  accountReceivableStatementDocumentLoadingIds: string[];
  accountReceivableStatementDocumentDownloading: boolean;
  accountReceivableDetailExportLoading: boolean;

  accountReceivableCustomers: Customer[];
  accountReceivableCustomersLoading: boolean;
}

const initialState: AccountReceivableStatementState = {
  getAccountReceivableBalanceRequest: undefined,
  accountReceivableBalance: {
    PastDue: 0.0,
    TotalDue: 0.0,
    CurrentDue: 0.0,
    MakePaymentURL: '',
    ShowAlertMessage: true,
  },
  accountReceivableBalanceLoading: true,

  getAccountReceivableDetailRequest: undefined,
  accountReceivableDetails: accountReceivableDetailAdapter.getInitialState(),
  accountReceivableDetailsLoading: true,

  accountReceivableStatementDocumentLoadingIds: [],
  accountReceivableStatementDocumentDownloading: false,
  accountReceivableDetailExportLoading: false,

  accountReceivableCustomers: [],
  accountReceivableCustomersLoading: false,
};

// Reducers
export const accountReceivablesSlice = createSlice({
  name: 'account-receivables',
  initialState: initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    // Balance
    resetAccountReceivableBalance: (state: AccountReceivableStatementState) => {
      state.getAccountReceivableBalanceRequest = initialState.getAccountReceivableBalanceRequest;
      state.accountReceivableBalance = initialState.accountReceivableBalance;
      state.accountReceivableBalanceLoading = initialState.accountReceivableBalanceLoading;
    },
    setAccountReceivableBalance: (
      state: AccountReceivableStatementState,
      action: PayloadAction<{
        request: GetAccountReceivableBalanceRequest;
        result: AccountReceivableBalance;
      }>
    ) => {
      state.getAccountReceivableBalanceRequest = action.payload.request;
      state.accountReceivableBalance = action.payload.result;
    },
    setAccountReceivableBalanceLoading: (state: AccountReceivableStatementState, action: PayloadAction<boolean>) => {
      state.accountReceivableBalanceLoading = action.payload;
    },
    // Detail
    resetAccountReceivableDetail: (state: AccountReceivableStatementState) => {
      state.getAccountReceivableDetailRequest = initialState.getAccountReceivableDetailRequest;
      state.accountReceivableDetails = accountReceivableDetailAdapter.getInitialState();
      state.accountReceivableDetailsLoading = initialState.accountReceivableDetailsLoading;
    },
    setAccountReceivableDetail: (
      state: AccountReceivableStatementState,
      action: PayloadAction<{
        request: GetAccountReceivableDetailRequest;
        result: AccountReceivableDetail[];
      }>
    ) => {
      const { request, result } = action.payload;
      state.getAccountReceivableDetailRequest = request;
      accountReceivableDetailAdapter.setAll(state.accountReceivableDetails, result);
    },
    setAccountReceivableDetailsLoading: (state: AccountReceivableStatementState, action: PayloadAction<boolean>) => {
      state.accountReceivableDetailsLoading = action.payload;
    },
    // Export
    setAccountReceivableStatementDocumentLoadingIDs: (
      state: AccountReceivableStatementState,
      action: PayloadAction<{ id: string; loading: boolean }>
    ) => {
      const { id, loading } = action.payload;
      if (loading) {
        state.accountReceivableStatementDocumentLoadingIds.push(id);
      } else {
        state.accountReceivableStatementDocumentLoadingIds = state.accountReceivableStatementDocumentLoadingIds.filter(
          (documentId: string) => documentId !== id
        );
      }
    },
    setAccountRecieveableStatementDocumentDownloadLoading: (
      state: AccountReceivableStatementState,
      action: PayloadAction<boolean>
    ) => {
      state.accountReceivableStatementDocumentDownloading = action.payload;
    },
    setAccountReceivableDetailExportLoading: (
      state: AccountReceivableStatementState,
      action: PayloadAction<boolean>
    ) => {
      state.accountReceivableDetailExportLoading = action.payload;
    },
    // Customers
    setAccountReceivableCustomers: (state: AccountReceivableStatementState, action: PayloadAction<Customer[]>) => {
      state.accountReceivableCustomers = action.payload;
      state.accountReceivableCustomersLoading = false;
    },
    setAccountReceivableCustomersLoading: (state: AccountReceivableStatementState, action: PayloadAction<boolean>) => {
      state.accountReceivableCustomersLoading = action.payload;
    },
  },
});

// Adapter Selectors
export const {
  selectAll: selectAllAccountReceivableDetails,
  selectById: selectAccountReceivableDetailByProductKey,
  selectIds: selectAccountReceivableDetailIds,
} = accountReceivableDetailAdapter.getSelectors<EntityState<AccountReceivableDetail>>(
  (details: EntityState<AccountReceivableDetail>) => details
);

// Custom Selectors

const filterFields: (keyof AccountReceivableDetail)[] = [
  'CustomerName',
  'CustomerCity',
  'CustomerState',
  'CustomerZip',
  'OperationCompanyName',
  'CustomerNumber',
  'DocumentNumber',
  'PurchaseOrderNumber',
  'AccountReceivableDetailTypeOtherDisplay',
  'CheckNumber',
  'ReferenceNumber',
  'Amount',
  'AccountReceivableDetailStatusType',
  'AccountReceivableDetailType',
  'PaidDate',
  'DueDate',
  'TransactionDate',
];

export const selectFilteredAccountReceivableDetailKeys = createSelector(
  (state: RootState) => state.accountReceivables.accountReceivableDetails,
  (_state: RootState, filterText: string | undefined) => filterText,
  (accountReceivableDetails, filterText) => {
    if (!filterText) return selectAccountReceivableDetailIds(accountReceivableDetails) as string[];

    const items = selectAllAccountReceivableDetails(accountReceivableDetails);
    const filteredItems = filterObjects(items, filterText, filterFields, [
      {
        field: 'AccountReceivableDetailType',
        type: 'custom',
        filterFunction: (val: string, queryString: string) =>
          AccountReceivableDetailType[+val]
            .toString()
            .toLocaleLowerCase()
            .includes(queryString.toString().toLocaleLowerCase()),
      },
      {
        field: 'AccountReceivableDetailStatusType',
        type: 'custom',
        filterFunction: (val: string, queryString: string) =>
          AccountReceivableDetailStatus[+val as AccountReceivableDetailStatusType]
            .toString()
            .toLocaleLowerCase()
            .includes(queryString),
      },
      {
        field: 'PaidDate',
        type: 'custom',
        filterFunction: (val: string, queryString: string, obj: AccountReceivableDetail) => {
          if (val && obj.AccountReceivableDetailStatusType === AccountReceivableDetailStatusType.Closed)
            return (
              toLocalDateString(val).includes(queryString.trim().toLocaleLowerCase()) ||
              toLocalFullDateString(val).includes(queryString.trim().toLocaleLowerCase())
            );

          return false;
        },
      },
      {
        field: 'DueDate',
        type: 'custom',
        filterFunction: (val: string, queryString: string, obj: AccountReceivableDetail) => {
          if (val && obj.AccountReceivableDetailStatusType !== AccountReceivableDetailStatusType.Closed)
            return (
              toLocalDateString(val).includes(queryString.trim().toLocaleLowerCase()) ||
              toLocalFullDateString(val).includes(queryString.trim().toLocaleLowerCase())
            );

          return false;
        },
      },
      {
        field: 'TransactionDate',
        type: 'date',
      },
      {
        field: 'Amount',
        type: 'currency',
      },
    ]);

    const sortedItems = sortBy(filteredItems, (i) => i.TransactionDate);
    return sortedItems.map((i) => normalizeKey(i.AccountReceivableDetailKey) as string) as string[];
  }
);
