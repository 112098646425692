import { Box } from '@mui/material';
import { RootState, useAppSelector } from 'common';
import { Slide } from 'common/src/api/models/customer-support.models';
import React, { useEffect, useState } from 'react';
import { CfContentLoader } from '../../../../../../../cf-ui/Loader/ContentLoader/CfContentLoader';
import { DeviceType, useBreakpoint } from '../../../../../../../hooks/useBreakpoint';

interface CustomerSupportOverviewSlidesProps {
  slides: Slide[];
  currentIndex: number;
  setLoading?: (value: boolean) => void;
}

const CustomerSupportOverviewSlides: React.FC<CustomerSupportOverviewSlidesProps> = (
  props: CustomerSupportOverviewSlidesProps
) => {
  const deviceType = useBreakpoint().deviceType;
  const isOverviewDialogLoading = useAppSelector((s: RootState) => s.customerSupport.isOverviewDialogLoading);
  const [imageLoading, setImageLoading] = useState(true);
  const imgSrc =
    deviceType === DeviceType.Desktop
      ? props.slides[props.currentIndex]?.DesktopImageURL
      : deviceType === DeviceType.Tablet
      ? props.slides[props.currentIndex]?.TabletImageURL
      : props.slides[props.currentIndex]?.MobileImageURL;

  const imgSx = { maxWidth: '100%', maxHeight: '100%' };

  useEffect(() => {
    setImageLoading(true);
  }, [props.slides]);

  const imageLoaded = (): void => {
    setImageLoading(false);
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
        width: '100%',
        height: '100%',
        maxHeight: (theme) => theme.spacing(47.5),
        position: 'relative',
      }}
    >
      <CfContentLoader
        isLoading={!isOverviewDialogLoading && imageLoading}
        sx={{ width: '100%', height: '100%', maxHeight: '380px', position: 'absolute' }}
      />
      {!isOverviewDialogLoading && imageLoading && <Box sx={{ width: '100%', height: (theme) => theme.spacing(47) }} />}
      {!isOverviewDialogLoading && (
        <Box
          hidden={imageLoading}
          component='img'
          alt={props.slides[props.currentIndex]?.Title}
          src={imgSrc}
          sx={imgSx}
          onLoad={imageLoaded}
        />
      )}
    </Box>
  );
};

export default CustomerSupportOverviewSlides;
