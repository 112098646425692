import { Box, ButtonProps, Icon, IconProps, Theme, Typography, TypographyProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { debounce } from 'lodash';
import React, { FC, PropsWithChildren, useCallback } from 'react';
import { CfContentLoader } from '../../Loader/ContentLoader/CfContentLoader';
import { CfCloseButton } from '../../buttons/CfCloseButton';
import { CfContainedButton } from '../../buttons/CfContainedButton';
import {
  StyledDialog,
  StyledDialogContainer,
  StyledDialogContent,
  StyledDialogFooter,
  StyledDialogHeader,
  StyledDialogHeaderTypography,
} from '../common/Shared.styles';

export interface CfDialogProps {
  isOpen: boolean;
  onClose?: () => void;

  iconProps?: IconProps;
  title?: string;
  subTitle?: string;

  hasPrimaryButton?: boolean;
  primaryButtonProps?: ButtonProps;
  hasSecondaryButton?: boolean;
  secondaryButtonProps?: ButtonProps;
  hasCloseButton?: boolean;

  paperSx?: SxProps<Theme>;
  headerSx?: SxProps<Theme>;
  contentContainerSx?: SxProps<Theme>;
  dialogContainerSx?: SxProps<Theme>;
  titleProps?: TypographyProps;
  dialogFooterSx?: SxProps<Theme>;
  dialogCloseButtonSx?: SxProps<Theme>;

  dataTestId?: string;
  closeButtonDataTestId?: string;
  primaryButtonDataTestId?: string;
  secondaryButtonDataTestId?: string;
  titleDataTestId?: string;

  isLoading?: boolean;
}
/**
 * @param isOpen - Boolean indicating if the dialog is open
 * @param onClose - Method to call on close
 * @param iconProps - Props for the icon next to the title
 * @param title - The title to display
 * @param subTitle - The optional subtitle to display
 * @param hasPrimaryButton - Boolean indicating if there is a primary button
 * @param primaryButtonProps - Props for the primary button to use
 * @param hasSecondaryButton - Boolean indicating if there is a secondary button
 * @param secondaryButtonProps - Props for the secondary button to use
 * @param hasCloseButton - Boolan indicating if the top-right close button should be hidden
 * @param paperSx - Styling for the dialog paper wrapper
 * @param headerSx - Styling for the dialog's header
 * @param contentContainerSx - Styling for the box wrapping the component's children
 * @param dialogContainerSx - Styling for the dialog's container
 * @param dataTestId - Test id for the dialog
 * @param closeButtonDataTestId - Test id for the header close button
 * @param primaryButtonDataTestId - Test id for the primary button
 * @param secondaryButtonDataTestId - Test id for the secondary button
 * @param titleDataTestId - Test id for the title
 * @param isLoading - Boolean indicating if the dialog is loading
 */
export const CfDialog: FC<PropsWithChildren<CfDialogProps>> = (props: PropsWithChildren<CfDialogProps>) => {
  const {
    children,
    iconProps,
    title,
    subTitle,
    isOpen,
    onClose,
    hasPrimaryButton,
    primaryButtonProps,
    hasSecondaryButton,
    secondaryButtonProps,
    hasCloseButton,
    paperSx,
    headerSx,
    contentContainerSx,
    dialogContainerSx,
    titleProps,
    dataTestId,
    closeButtonDataTestId,
    primaryButtonDataTestId,
    secondaryButtonDataTestId,
    titleDataTestId: titleTestId,
    isLoading: isLoading,
    dialogCloseButtonSx,
  } = props;

  const handlePrimaryClick = (event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
    const mouseEvent = event as React.MouseEvent<HTMLButtonElement>;
    const keyboardEvent = event as React.KeyboardEvent<HTMLButtonElement>;
    if (mouseEvent) {
      primaryButtonProps?.onClick?.(mouseEvent);
    } else {
      primaryButtonProps?.onKeyDown?.(keyboardEvent);
    }
  };
  const handleSecondaryClick = (
    event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>
  ) => {
    const mouseEvent = event as React.MouseEvent<HTMLButtonElement>;
    const keyboardEvent = event as React.KeyboardEvent<HTMLButtonElement>;
    if (mouseEvent) {
      secondaryButtonProps?.onClick?.(mouseEvent);
    } else {
      secondaryButtonProps?.onKeyDown?.(keyboardEvent);
    }
  };
  const handleDialogClose = (_event: unknown, reason: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return false;
    onClose?.();
  };

  const iconSx = { mr: 1.5, ...(iconProps?.sx ?? {}) };

  const closeButtonSx = {
    color: (theme: Theme) => theme.palette.coolGrey?.[800] ?? '#00000',
    ...dialogCloseButtonSx,
  };

  const debouncedPrimaryClick = useCallback(debounce(handlePrimaryClick, 300), [primaryButtonProps?.onClick]);
  const debouncedSecondaryClick = useCallback(debounce(handleSecondaryClick, 300), [secondaryButtonProps?.onClick]);
  return (
    <StyledDialog
      open={isOpen}
      onClose={handleDialogClose}
      aria-labelledby='dialog'
      PaperProps={{ sx: { width: 424, ...paperSx } }}
      data-testid={dataTestId}
    >
      <CfContentLoader
        isLoading={isLoading ?? false}
        sx={{ borderRadius: (theme) => `${theme.shape.borderRadius}px` }}
      />
      <StyledDialogContainer p={2} sx={dialogContainerSx}>
        <StyledDialogHeader sx={{ pb: 2, ...headerSx }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {iconProps && <Icon fontSize='medium' {...iconProps} sx={iconSx} />}
            {title && (
              <Box>
                <StyledDialogHeaderTypography data-testid={titleTestId} {...titleProps}>
                  {title}
                </StyledDialogHeaderTypography>
              </Box>
            )}
          </Box>
          {!hasCloseButton && (
            <Box>
              <CfCloseButton
                onClick={onClose}
                variant='slim'
                size='medium'
                dataTestId={closeButtonDataTestId}
                sx={closeButtonSx}
              />
            </Box>
          )}
        </StyledDialogHeader>
        {subTitle && (
          <Box sx={{ pb: 2 }}>
            <StyledDialogContent>{subTitle}</StyledDialogContent>
          </Box>
        )}
        <Box sx={contentContainerSx}>{children}</Box>
        {(primaryButtonProps || secondaryButtonProps) && (
          <StyledDialogFooter sx={{ padding: 0, pt: 2, ...props.dialogFooterSx }}>
            {(secondaryButtonProps || hasSecondaryButton) && (
              <CfContainedButton
                variant='contained'
                color='secondary'
                {...secondaryButtonProps}
                onClick={debouncedSecondaryClick}
                data-testid={secondaryButtonDataTestId}
              >
                <Typography variant='responsiveParagraphBold'>{secondaryButtonProps?.children ?? 'No'}</Typography>
              </CfContainedButton>
            )}
            {(primaryButtonProps || hasPrimaryButton) && (
              <>
                <CfContainedButton
                  variant='contained'
                  color='primary'
                  {...primaryButtonProps}
                  onClick={debouncedPrimaryClick}
                  data-testid={primaryButtonDataTestId}
                >
                  <Typography variant='responsiveParagraphBold'>{primaryButtonProps?.children ?? 'Yes'}</Typography>
                </CfContainedButton>
              </>
            )}
          </StyledDialogFooter>
        )}
      </StyledDialogContainer>
    </StyledDialog>
  );
};
