import { ServiceResult } from '../models/api-shared.models';
import {
  CustomerProductPriceResult,
  GetCustomerProductPriceRequest,
  GetOrderReviewCustomerProductPriceRequest,
  OrderReviewCustomerProductPriceResult,
} from '../models/customer-product-price.models';
import ApiService from './api-service';

class CustomerProductPriceService extends ApiService {
  private static classInstance: CustomerProductPriceService;
  private customerProductPriceController: AbortController;

  private constructor() {
    super('CustomerProductPrice');
    this.customerProductPriceController = new AbortController();
  }

  public abortGetCustomerProductPrice = (): void => {
    this.customerProductPriceController.abort();
  };
  public getCustomerProductPrice(request: GetCustomerProductPriceRequest): ServiceResult<CustomerProductPriceResult[]> {
    this.customerProductPriceController = new AbortController();
    return this.post(
      `${this.baseUrl}/GetCustomerProductPrice`,
      request,
      undefined,
      this.customerProductPriceController.signal
    );
  }

  public getOrderReviewCustomerProductPrice(
    request: GetOrderReviewCustomerProductPriceRequest
  ): ServiceResult<OrderReviewCustomerProductPriceResult[]> {
    return this.get(`${this.baseUrl}/GetOrderReviewCustomerProductPrice`, request);
  }

  public static getInstance(): CustomerProductPriceService {
    if (!this.classInstance) {
      this.classInstance = new CustomerProductPriceService();
    }
    return this.classInstance;
  }
}

export default CustomerProductPriceService;
