import { Box, ClickAwayListener, Divider, Fade, PopperPlacementType, PopperProps, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { FC, PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react';
import { useLockedBody } from '../../hooks/useLockedBody';
import { StyledContainerMenu, StyledContainerPanel, StyledPopper, StyledPopperArrow } from './CfMenu.styles';

type CfUncontrolledMenuPropsBase = {
  isOpen?: boolean;
  anchorElement: HTMLElement | undefined | null;
  closeOnScroll?: boolean;
  closeOnBlur?: boolean;
  dataTestId?: string;
  displayArrow?: boolean;
  inputElement?: React.ReactNode;
  disablePortal?: boolean;
  firstInteractiveItemIndex?: number;
  lastInteractiveItemIndex?: number;
  keepMounted?: boolean;
  placement?: PopperPlacementType;
  prefix?: ReactNode;
  stopPropagation?: boolean;
  minWidth?: number;
  maxWidth?: number;
  width?: number;
  variant?: 'square' | 'round';
  popperOffset?: number[];
  popperProps?: PopperProps;
  popperSx?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
  sx?: SxProps<Theme>;
  onClose?: () => void;
  keepOpenOnChange?: boolean;
  disabled?: boolean;
};

export type CfUncontrolledMenuProps =
  | ({
      mode: 'menu' | 'select';
      divider?: 'full' | 'flush';
    } & CfUncontrolledMenuPropsBase)
  | ({
      mode: 'panel';
      divider?: never;
    } & CfUncontrolledMenuPropsBase);

export const handleItemTabEvent = (
  anchor: HTMLElement | undefined | null,
  event: React.KeyboardEvent<HTMLElement>,
  isFirstItem: boolean,
  isLastItem: boolean,
  closeOnBlur?: boolean,
  stopPropagation?: boolean,
  closeHandler?: () => unknown
): void => {
  if (stopPropagation) event.stopPropagation();
  if (event.code.toLowerCase() === 'tab') {
    if ((isLastItem && !event.shiftKey) || (isFirstItem && event.shiftKey)) {
      anchor?.focus();
      if (closeOnBlur) {
        closeHandler?.();
      }
    }
  }
};

/**
 * @param children rendered as menu items within an \<li\> element
 * @param isOpen passed to the Popper component's open property
 * @param anchorElement passed to the Popper component's anchorEl property
 * @param closeOnScroll determines whether to add an event listener to the window's "wheel" event that closes the menu
 * @param closeOnBlur determines whether pressing the "tab" key while the last interactive menu item is in focus should also close the menu (also applies to shift+tab on the first interactive item)
 * @param dataTestId passed to the Popper component's data-testid property
 * @param displayArrow passed to the Popper component's modifiers property
 * @param inputElement rendered before the Popper component
 * @param disablePortal passed to the Popper component's disablePortal property
 * @param firstInteractiveItemIndex determines which menu item is considered the first interactive item
 * @param lastInteractiveItemIndex determines which menu item considered the last interactive item
 * @param keepMounted passed to the Popper component's keepMounted property
 * @param placement passed to the Popper component's placement property
 * @param prefix rendered before the children
 * @param stopPropagation determines whether event handlers should propagate the event to the target element's parent
 * @param minWidth sets the minimum width of the Popper component
 * @param maxWidth sets the maximum width of the Popper component
 * @param width determines the width of the Popper component
 * @param variant determines the css styles of the Popper component
 * @param popperOffset passed to the Popper component's modifiers property
 * @param popperProps passed to the Popper component
 * @param popperSx augmented and passed to the Popper component's sx property
 * @param containerSx augmented and passed to the ul element containing the children
 * @param sx passed to the Box component containing the inputElement and Popper component
 * @param onClose executed when the user clicks a menu item, "tabs away", or "clicks away" from the menu
 * @param keepOpenOnChange determines whether to close the menu when a user clicks a menu item
 * @param mode: 'menu' | 'select' | 'panel';
 * @param divider?: 'full' | 'flush';
 * @param disabled: disable menu functionality, preventing the underlying Popper component from rendering;

 * @returns
 */
export const CfUncontrolledMenu: FC<PropsWithChildren<CfUncontrolledMenuProps>> = (
  props: PropsWithChildren<CfUncontrolledMenuProps>
) => {
  // Hooks
  const [, setLocked] = useLockedBody();
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const containerRef = useRef<HTMLUListElement>(null);

  const isOpen = props.isOpen ?? Boolean(props.anchorElement);

  useEffect(() => {
    if (isOpen && props.anchorElement && document.body.contains(props.anchorElement)) {
      setLocked(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (props.closeOnScroll && isOpen) window.addEventListener('wheel', handleClose, { passive: true });
    return () => {
      if (props.closeOnScroll) window.removeEventListener('wheel', handleClose);
    };
  }, [isOpen, props.closeOnScroll]);

  // Constants
  const numChildren = React.Children.count(props.children);
  const firstInteractiveItemIndex = props.firstInteractiveItemIndex ?? 0;
  const lastInteractiveItemIndex = props.lastInteractiveItemIndex ?? numChildren - 1;
  const menuWidth = props.width ?? props.minWidth ?? 0;
  const anchorWidth = props.anchorElement?.clientWidth ?? 0;

  let arrowSX = {};
  switch (props.placement) {
    case 'bottom':
      arrowSX = { left: menuWidth / 2 - 10 };
      break;
    case 'bottom-start':
      arrowSX = { left: anchorWidth / 2 };
      break;
    case 'bottom-end':
      arrowSX = { left: menuWidth - anchorWidth / 2 - 10 };
      break;
  }

  // Handlers
  const handleClose = () => {
    setLocked(false);
    props.onClose?.();
  };

  const handleClickAway = () => {
    if (isOpen) {
      handleClose();
    } else {
      setLocked(false);
    }
  };

  const handleItemClick = (event: React.MouseEvent<HTMLLIElement> | undefined) => {
    if (props.stopPropagation && event) event.stopPropagation();
    !props.keepOpenOnChange && handleClose();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLUListElement>): void => {
    if (props.stopPropagation) event.stopPropagation();
    if (event.key.toLowerCase() == 'enter' && !props.keepOpenOnChange && ['menu', 'select'].includes(props.mode))
      handleClose();
    if (event.key.toLowerCase() == 'escape') handleClose();
  };

  const popperSxAugmented: SxProps<Theme> =
    props.variant === 'square'
      ? {
          borderRadius: 0,
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
          border: 'none',
          ...(props.width && { width: props.width }),
          ...(props.minWidth && { minWidth: props.minWidth }),
          ...(props.maxWidth && { maxWidth: props.maxWidth }),
          ...props.popperSx,
        }
      : {
          width: props.width ?? '',
          ...props.popperSx,
        };

  return (
    <ClickAwayListener mouseEvent='onMouseDown' touchEvent='onTouchStart' onClickAway={handleClickAway}>
      <Box sx={props.sx}>
        {props.inputElement}
        {numChildren > 0 && props.disabled !== true && (
          <StyledPopper
            {...props.popperProps}
            anchorEl={props.anchorElement}
            placement={props.placement}
            keepMounted={props.keepMounted}
            disablePortal={props.disablePortal}
            data-testid={props.dataTestId}
            open={isOpen}
            modifiers={[
              {
                name: 'arrow',
                enabled: Boolean(props.displayArrow),
                options: {
                  element: arrowRef,
                },
              },
              { name: 'offset', enabled: Boolean(props.popperOffset), options: { offset: props.popperOffset } },
              {
                name: 'preventOverflow',
                enabled: true,
                options: {
                  rootBoundary: 'window',
                },
              },
            ]}
            transition
            sx={popperSxAugmented}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={{ enter: 250, exit: 0 }}>
                <div>
                  {props.displayArrow && (
                    <StyledPopperArrow
                      ref={setArrowRef}
                      sx={{
                        '::before': {
                          ...arrowSX,
                        },
                      }}
                    />
                  )}

                  {['menu', 'select'].includes(props.mode) ? (
                    <StyledContainerMenu
                      ref={containerRef}
                      tabIndex={0}
                      sx={props.containerSx}
                      onKeyDown={handleKeyDown}
                    >
                      {props.prefix}
                      {React.Children.map(props.children, (child, index) => {
                        return (
                          <>
                            <li
                              onClick={handleItemClick}
                              onKeyDown={(e) =>
                                handleItemTabEvent(
                                  props.anchorElement,
                                  e,
                                  index === firstInteractiveItemIndex,
                                  index === lastInteractiveItemIndex,
                                  props.closeOnBlur,
                                  props.stopPropagation,
                                  handleClose
                                )
                              }
                            >
                              {child}
                            </li>
                            {props.divider && index < numChildren - 1 && (
                              <Box sx={{ padding: props.divider === 'flush' ? '' : '0px 16px' }}>
                                <Divider variant='fullWidth' />
                              </Box>
                            )}
                          </>
                        );
                      })}
                    </StyledContainerMenu>
                  ) : (
                    <>
                      <StyledContainerPanel ref={containerRef} tabIndex={0} sx={props.containerSx}>
                        {props.prefix}
                        {props.children}
                      </StyledContainerPanel>
                      <Box
                        tabIndex={0}
                        onFocus={() => {
                          props.anchorElement?.focus();
                          handleClose();
                        }}
                      ></Box>
                    </>
                  )}
                </div>
              </Fade>
            )}
          </StyledPopper>
        )}
      </Box>
    </ClickAwayListener>
  );
};
