import { Switch, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.coolGrey[700],
  marginBottom: theme.spacing(0.25),
}));

export const StyledSwitch = styled(Switch)(({ checked }) => ({
  '&.MuiSwitch-root.MuiSwitch-sizeMedium .MuiSwitch-input': {
    left: checked ? '-50%' : '0',
    width: '145%',
  },
}));
