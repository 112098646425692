import { LogLevel, RedirectRequest } from '@azure/msal-browser';
import { AppRoutes, RouteName } from '../../models/routing.models';

const authorities = process.env.REACT_APP_B2C_KNOWN_AUTHORITIES?.split(' '); // space delimited values on the .env file
const b2cAPIScopes = process.env.REACT_APP_B2C_API_SCOPES?.split(' ');

const apiConfig = {
  // b2cScopes: process.env.REACT_APP_B2C_API_SCOPES, will be using environment variables once we pbtain B2C API scopes
  b2cScopes: b2cAPIScopes || [],
  forceRefresh: false,
};

export const tokenRequest = {
  scopes: apiConfig.b2cScopes ? [...apiConfig.b2cScopes] : [],
  forceRefresh: false,
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID ?? '',
    authority: process.env.REACT_APP_B2C_SIGNIN_AUTHORITY ?? '',
    // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
    redirectUri: process.env.REACT_APP_B2C_REDIRECT_URI ?? '',
    knownAuthorities: authorities,
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          // case LogLevel.Info:
          //   console.info(message);
          //   return;
          // case LogLevel.Verbose:
          //   console.debug(message);
          //   return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest: RedirectRequest = {
  scopes: ['openid', 'profile'],
  redirectStartPage: AppRoutes[RouteName.Home].Path,
};

export const getWelcomeLinkBaseUrl = (): string => {
  return process.env.REACT_APP_B2C_REDIRECT_URI ?? '';
};
