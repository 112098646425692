import { debounce, Icon, InputAdornment, TextField, TextFieldProps, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { Cancelable } from '@mui/utils/debounce';
import React, { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DeviceType, useBreakpoint } from '../../hooks/useBreakpoint';
import { CfCloseButton } from '../buttons/CfCloseButton';
import { getResponsiveTextFieldStyles } from '../TextField/CfTextField.styles';
import { StyledSearchButton } from './CfSearchInput.styles';

export type CfSearchInputSize = 'small' | 'medium' | 'responsive';
export type CfSearchInputVariant = 'primary' | 'secondary' | 'tertiary';

/**
 * the properties which describe how the CfSearchInput component should present and behave
 */
export interface CfSearchInputProps {
  rootRef?: React.RefObject<HTMLDivElement>;
  inputRef?: React.RefObject<HTMLInputElement>;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onSubmit?: (text: string) => void;
  onClear?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onInputKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  initialValue?: string;
  hideClearButton?: boolean;
  hideSearchButton?: boolean;
  sx?: SxProps<Theme>;
  testId?: string;
  searchButtonTestId?: string;
  size?: CfSearchInputSize;
  variant?: CfSearchInputVariant;
  autoFocus?: boolean;
  isResponsive?: boolean;
}

/* This is a copy of the CfSearchInput component.
 *  This was done because of the debouncedSubmit declaration below which is stored out side of the context of a single
 *  component instance. In its current implementation as of 08/17/2023 this is used to resolve an issue surrounding
 *  incorrect searches in the header's typeahead search input. However, this implementation is also causing problems
 *  with how this component interacts with other instances of the same component since the debounced callback is stored
 *  at the file level instead of the component level. Based on a discussion with Mikayla and Austin, we made a decision
 *  that due to regression testing fears we would resolve this issue by abstracting the fixes out into separate components
 *  until such time that we can refactor the entire typeahead and search input functionality. */
let debouncedSubmit: (((value: string) => void) & Cancelable) | null = null;

/**
 * Renders a text field input with specialized buttons to submit and clear the input value
 * @param rootRef passed as to the TextField component's rootRef property
 * @param inputRef passed as the TextField component's inputRef property
 * @param onClick executed when the TextField component's onClick event is triggered
 * @param onSubmit executed when the TextField component's onSubmit event is triggered
 * @param onClear executed when the CfCloseButton component's onClick event is triggered
 * @param onKeyDown executed when the TextField component's onClick event is triggered
 * @param onInputKeyDown executed when the input element of the TextField component's onKeyDown event is triggered
 * @param onChange executed when the TextField component's onChange event is triggered
 * @param onFocus executed when the TextField component's onFocus event is triggered
 * @param onBlur passed to the TextField component's onBlur property
 * @param placeholder passed to the TextField component's placeholder property
 * @param initialValue passed to the TextField component's value property
 * @param hideClearButton determines whether to render the CfCloseButton component
 * @param hideSearchButton determines whether to render the (search) IconButton component
 * @param sx augmented and passed to the TextField component's sx property
 * @param testId assigned to the input element's data-testid property via the inputRef
 * @param searchButtonTestId passed to the (search) IconButton component's data-testid property
 * @param size determines the css properties of the Textfield component and (search) IconButton component,
 * @param variant determines the css properties of the Textfield component and (search) IconButton component
 * @param autoFocus passed to the TextField component's autoFocus property
 * @param isResponsive determines the css properties of the Textfield component and (search) IconButton component
 */
export const CfSearchInputTypeahead: FC<CfSearchInputProps> = (props: CfSearchInputProps) => {
  const theme = useTheme(); // Using this due to issues with MUI's styled(TextField) forcing the property: variant="Filled"
  const [text, setText] = useState<string>(props.initialValue ?? '');

  const rootRef = props.rootRef ?? useRef<HTMLDivElement>(null);
  const inputRef = props.inputRef ?? useRef<HTMLInputElement>(null);
  const deviceType = useBreakpoint().deviceType;
  const isDesktop = deviceType === DeviceType.Desktop;
  useLayoutEffect(() => {
    if (inputRef.current && props.testId) {
      inputRef.current.setAttribute('data-testid', props.testId);
    }
  }, [inputRef]);

  useEffect(() => {
    setText(props.initialValue ?? '');
  }, [props.initialValue]);

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    props.onClick?.(event);
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event) setText(event.target.value);
    props.onChange?.(event);
  };

  const handleOnClear = () => {
    setText('');
    props.onClear?.();
    inputRef.current?.focus();
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      const targetElement = event.target as Element;
      const targetElementType = targetElement.getAttribute('type');
      const targetElementId = targetElement.getAttribute('id');
      if (targetElementType === 'text' || targetElementId === 'submit-button') handleOnSubmit();
      else if (targetElementId === 'clear-button') handleOnClear();
    } else if (event.key === 'Backspace') {
      //const endIndex = text.length === 0 ? 0 : text.length - 1;
      //setText(text.substring(0, endIndex));
    } else {
      // if (event && !event.altKey && !event.ctrlKey && isValidKey(event.key)) setText(text + event.key);
    }

    props.onKeyDown?.(event);
    //event.preventDefault();
  };

  const handleOnButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleOnSubmit();
    event.stopPropagation();
  };

  const handleOnSubmit = () => {
    debouncedSubmit?.(text);
  };

  const _handleOnSubmit = (value: string) => {
    props.onSubmit?.(value);
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    props.onFocus?.(event);
    event.target.select();
  };

  const propSize = props.size ?? 'responsive';
  const size = propSize === 'responsive' ? (deviceType === DeviceType.Desktop ? 'small' : 'medium') : propSize;

  const newDebouncedSubmit = useCallback(debounce(_handleOnSubmit, 200), [props.onSubmit]);
  if (debouncedSubmit && newDebouncedSubmit !== debouncedSubmit) {
    debouncedSubmit.clear();
  }
  debouncedSubmit = newDebouncedSubmit;

  const height = size === 'medium' ? theme.spacing(5) : theme.spacing(4);
  const buttonWidth = size === 'medium' ? theme.spacing(5.5) : theme.spacing(3.75);
  const mobileFont = theme.typography.mobileParagraph;
  const h4Font = theme.typography.h4;
  const inputRootProps =
    size === 'medium'
      ? {
          fontSize: mobileFont.fontSize,
          fontWeight: mobileFont.fontWeight,
          lineHeight: mobileFont.lineHeight,
          letterSpacing: mobileFont.letterSpacing,
        }
      : props.variant === 'secondary'
      ? {
          fontSize: h4Font.fontSize,
          fontWeight: h4Font.fontWeight,
          lineHeight: h4Font.lineHeight,
          letterSpacing: h4Font.letterSpacing,
        }
      : {};
  const textFieldBaseProps: TextFieldProps = {
    ref: rootRef,
    inputRef: inputRef,
    fullWidth: true,
    onClick: handleOnClick,
    onChange: handleOnChange,
    onKeyDown: handleOnKeyDown,
    onSubmit: handleOnSubmit,
    onFocus: handleFocus,
    onBlur: props.onBlur,
    placeholder: props.placeholder,
    value: text,
    autoFocus: props.autoFocus,
  };

  const textFieldVariantProps: TextFieldProps =
    props.variant === 'secondary'
      ? {
          variant: 'outlined',
          sx: {
            '& .MuiInputBase-root': {
              color: (theme: Theme) => theme.palette.coolGrey[700],
              backgroundColor: theme.palette.common.white,
              borderRadius: 0,
              borderBottom: 1,
              pl: 0,
              borderColor: (theme: Theme) => theme.palette.coolGrey[200],
              ...inputRootProps,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
              '& .Mui-focused': {
                color: '#ffffff',
              },
            },
            '& .MuiOutlinedInput-input': {
              '&::placeholder': {
                color: (theme: Theme) => theme.palette.coolGrey[700],
                opacity: 1,
                ...inputRootProps,
              },
            },
            ...props.sx,
          },
          InputProps: {
            sx:
              props.variant === 'secondary'
                ? {
                    '& .MuiOutlinedInput-input': {
                      '&::placeholder': {
                        typography: { xs: 'mobileLabel', xl: 'h4' },
                      },
                      paddingRight: 0,
                    },
                  }
                : { paddingRight: 0 },
            onKeyDown: props.onInputKeyDown,
            startAdornment: <Icon className='fa-plus' sx={{ fontSize: '16px', mr: 1 }} />,
          },
        }
      : {
          sx: {
            '& .MuiInputBase-root': {
              height: props.isResponsive ? 'auto' : height + '!important',
              backgroundColor: props.variant === 'tertiary' ? theme.palette.common.white : theme.palette.grey[100],
              ...inputRootProps,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: props.variant === 'tertiary' ? `1px solid ${theme.palette.grey[300]}` : 0,
            },
            '& .MuiOutlinedInput-input': {
              py: 0,
              lineHeight: (theme) => ({
                xs: `${theme.custom.lineHeights.mobileParagraph}px`,
                xl: `${theme.custom.lineHeights.paragraph}px`,
              }),
              color: (theme) => theme.palette.grey[900],

              '&::placeholder': {
                color: theme.palette.text.primary,
                opacity: 1,
                ...inputRootProps,
              },
            },
            ...props.sx,
          },
          InputProps: {
            sx: { paddingRight: 0 },
            onKeyDown: props.onInputKeyDown,
            endAdornment: (
              <InputAdornment position='end'>
                {props.hideClearButton != true && (
                  <CfCloseButton
                    id='clear-button'
                    onClick={handleOnClear}
                    variant='regular'
                    size='small'
                    sx={{
                      display: text.length <= 0 ? 'none' : '',
                      maxWidth: (theme) => ({ lg: theme.spacing(4.75), xl: theme.spacing(4.25) }),
                    }} //explicitly set width to fix text placeholder overlap
                    disabled={text.length <= 0}
                  />
                )}
                {props.hideSearchButton != true && (
                  <StyledSearchButton
                    id='submit-button'
                    data-testid={props.searchButtonTestId}
                    onClick={handleOnButtonClick}
                    size={isDesktop ? 'small' : 'medium'}
                    sx={{ height: height, width: buttonWidth }}
                  >
                    <Icon
                      className='fa-search'
                      fontSize={!isDesktop ? 'medium' : 'small'}
                      data-testid='submit-search'
                    />
                  </StyledSearchButton>
                )}
              </InputAdornment>
            ),
          },
        };

  return (
    <TextField
      {...textFieldBaseProps}
      {...textFieldVariantProps}
      {...(props.isResponsive && {
        sx: {
          ...textFieldVariantProps.sx,
          ...getResponsiveTextFieldStyles(theme),
        },
      })}
    />
  );
};
