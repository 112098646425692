import { FileResult, ServiceResult } from '../models/api-shared.models';
import {
  GetProductListDiaryReportRequest,
  GetProductListDiaryRequest,
  ProductListDiaryEntry,
} from '../models/product-list-diary.models';
import ApiService from './api-service';

class ProductListDiaryService extends ApiService {
  private static classInstance: ProductListDiaryService;

  private constructor() {
    super('ProductListDiary');
  }

  public getProductListDiary(request: GetProductListDiaryRequest): ServiceResult<ProductListDiaryEntry[]> {
    return this.post(`${this.baseUrl}/GetProductListDiary`, request);
  }
  public getProductListDiaryReport(request: GetProductListDiaryReportRequest): ServiceResult<FileResult> {
    return this.post(`${this.baseUrl}/GetProductListDiaryReport`, request);
  }

  public static getInstance(): ProductListDiaryService {
    if (!this.classInstance) {
      this.classInstance = new ProductListDiaryService();
    }
    return this.classInstance;
  }
}

export default ProductListDiaryService;
