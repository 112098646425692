import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'common';

interface CommunicationsCustomerInfoCardProps {
  customerId: string;
  customerAddressTestId?: string;
  customerZipTestId?: string;
  customerNumberTestId?: string;
}

const CommunicationsCustomerInfoCard = (props: CommunicationsCustomerInfoCardProps): JSX.Element => {
  const { customerId } = props;
  const userCustomer = useAppSelector((state) =>
    state.user.userSite?.UserCustomers.find((uc) => uc.CustomerId === customerId)
  );

  if (!userCustomer) {
    return <Box>No customer found</Box>;
  }

  return (
    <>
      {userCustomer.CustomerName && (
        <Typography component='p' typography='responsiveParagraphBold' data-testid='finances-customer-name'>
          {userCustomer.CustomerName}
        </Typography>
      )}
      {userCustomer.CustomerAddress && (
        <Typography data-testid={props.customerAddressTestId} component='p' typography='responsiveParagraphSmall'>
          {userCustomer.CustomerAddress}
        </Typography>
      )}
      {userCustomer.CustomerLocation && (
        <Typography data-testid={props.customerZipTestId} component='p' typography='responsiveParagraphSmall'>
          {userCustomer.CustomerLocation} {userCustomer.CustomerZip}
        </Typography>
      )}
      {userCustomer.OperationCompanyName && (
        <Typography data-testid={props.customerNumberTestId} component='p' typography='responsiveParagraphSmall'>
          {userCustomer.OperationCompanyName} - {userCustomer.CustomerNumber}
        </Typography>
      )}
    </>
  );
};

export default CommunicationsCustomerInfoCard;
