import { Box, Button, Snackbar } from '@mui/material/';
import { styled } from '@mui/material/styles';

export const StyledToastBox = styled(Box)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.coolGrey[600],
  borderRadius: '4px',
  padding: theme.spacing(2),
  display: 'flex',
}));

export const StyledDivider = styled('span')(({ theme }) => ({
  height: '100%',
  width: theme.spacing(0.125),
  lineHeight: '24px',
  display: 'inline-block',
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
}));

export const StyledActionButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    ...theme.typography.mobileParagraphBold,
  },
  [theme.breakpoints.up('xl')]: {
    ...theme.typography.paragraphBold,
  },
  maxHeight: theme.spacing(6),
  minWidth: 'auto',
  maxWidth: theme.spacing(18),
  whiteSpace: 'nowrap',
  borderRadius: '8px',
  backgroundColor: 'unset',
  '&.MuiButtonBase-root.MuiButton-root': {
    border: '1px solid rgba(255, 255, 255, 0.5)',
    paddingTop: '4px',
    paddingBottom: '4px',
    [theme.breakpoints.down('xl')]: {
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    '&: focus': {
      backgroundColor: 'initial',
      borderColor: theme.palette.grey[100],
      boxShadow: 'none',
    },
    '&: hover': {
      backgroundColor: 'initial',
      borderColor: theme.palette.grey[100],
      boxShadow: 'none',
    },
  },
}));

export const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    top: theme.custom.headerHeights['xs'],
    width: 'auto',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'max-content',
    maxWidth: `calc(100% - ${theme.spacing(3)})`,
    top: theme.custom.headerHeights['sm'],
  },
  [theme.breakpoints.up('md')]: {
    top: theme.custom.headerHeights['md'],
  },
  [theme.breakpoints.up('lg')]: {
    top: theme.custom.headerHeights['lg'],
  },
  [theme.breakpoints.up('xl')]: {
    top: theme.custom.headerHeights['xl'],
  },
}));
