import { useRef, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

export type PageNotificationState =
  | {
      height: number;
    }
  | undefined;

const initState = undefined;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let setHeight = (height: number): void => {
  console.warn('use usePageNotification hook to access this method');
};

export const usePageNotification = singletonHook<PageNotificationState>(initState, () => {
  const [state, setState] = useState<PageNotificationState>(initState);
  const stateRef = useRef<PageNotificationState>(initState);

  setHeight = (height: number) => {
    stateRef.current = { height };
    setState(stateRef.current);
  };

  return state;
});

export const setAlertHeight = (height: number): void => setHeight(height);
