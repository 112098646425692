import { CatalogProduct, UnitOfMeasureOrderQuantity } from '../../../api';
import { ItemData } from './google-analytics.models';

export const getAnalyticsItemData = (
  productKey: string,
  uomType: string,
  price: number,
  quantity: number,
  index?: number,
  uom?: UnitOfMeasureOrderQuantity,
  product?: CatalogProduct,
  itemListId?: string,
  itemListName?: string
): ItemData => ({
  itemId: productKey,
  itemName: uom?.ProductNumberDisplay ?? '',
  affiliation: undefined,
  coupon: undefined,
  currency: undefined,
  discount: undefined,
  index: index,
  itemBrand: product?.ProductBrand,
  itemCategory: product?.ProductCategory,
  itemCategory2: undefined,
  itemCategory3: undefined,
  itemCategory4: undefined,
  itemCategory5: undefined,
  itemListId: itemListId,
  itemListName: itemListName,
  itemVariant: uomType,
  locationId: undefined,
  price: price,
  quantity: quantity,
});
