import { Paper, styled } from '@mui/material';

export const StyledPaperNotification = styled(Paper)(({ theme }) => ({
  border: theme.palette.primary.main,
  position: 'relative',
  width: 'auto',
  height: 'auto',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  marginBottom: 10,
  textAlign: 'center',
  overflowWrap: 'break-word',
  boxSizing: 'border-box',
  display: 'flex',

  [theme.breakpoints.up('xs')]: {
    flexDirection: 'row',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}));
