import { ServiceResult } from '../models/api-shared.models';
import {
  CopyParListRequest,
  CreateParListHeaderRequest,
  DeleteParListHeaderRequest,
  ParListHeader,
  ResetParListDetailsRequest,
  UpdateParListHeaderRequest,
} from '../models/par-list-header.models';
import ApiService from './api-service';

class ParListHeaderService extends ApiService {
  private static classInstance: ParListHeaderService;

  private constructor() {
    super('ParListHeader');
  }

  public getParListHeader(parListHeaderId: string): ServiceResult<ParListHeader> {
    return this.get(`${this.baseUrl}/GetParListHeader`, { parListHeaderId: parListHeaderId });
  }

  public getParListHeaders(customerId: string): ServiceResult<ParListHeader[]> {
    return this.get(`${this.baseUrl}/GetParListHeaders`, { customerId: customerId });
  }

  public updateParListHeader(request: UpdateParListHeaderRequest): ServiceResult<ParListHeader> {
    return this.post(`${this.baseUrl}/UpdateParListHeader`, request);
  }

  public createParListHeader(request: CreateParListHeaderRequest): ServiceResult<ParListHeader> {
    return this.post(`${this.baseUrl}/CreateParListHeader`, request);
  }

  public copyParList(request: CopyParListRequest): ServiceResult<ParListHeader> {
    return this.post(`${this.baseUrl}/CopyParList`, request);
  }

  public resetParListDetails(request: ResetParListDetailsRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/ResetParListDetails`, request);
  }

  public deleteParListHeader(request: DeleteParListHeaderRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/DeleteParListHeader`, request);
  }

  public static getInstance(): ParListHeaderService {
    if (!this.classInstance) {
      this.classInstance = new ParListHeaderService();
    }
    return this.classInstance;
  }
}

export default ParListHeaderService;
