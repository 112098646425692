import { LogUserActivityRequest } from '../../models/user-activity-report.models';
import { ServiceResult } from '../models/api-shared.models';
import ApiService from './api-service';

class UserActivityReportService extends ApiService {
  private static classInstance: UserActivityReportService;

  private constructor() {
    const displayErrors = process.env.REACT_APP_ENV_NAME === 'dev' || process.env.REACT_APP_ENV_NAME === 'local';
    super('UserActivityReport', displayErrors);
  }

  public logUserActivity<T>(request: LogUserActivityRequest<T>): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/LogUserActivity`, request);
  }

  public static getInstance(): UserActivityReportService {
    if (!this.classInstance) {
      this.classInstance = new UserActivityReportService();
    }
    return this.classInstance;
  }
}

export default UserActivityReportService;
