import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MenuHeader, MenuOption } from './AccountSideBar';

export const StyledMenuHeaderWrapper = styled(Box)<{ header: MenuHeader; isSelected: boolean }>(
  ({ theme, header, isSelected }) => ({
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),

    borderRadius: theme.shape.borderRadius,
    color: header.disabled ? theme.palette.action.disabled : theme.palette.coolGrey[800],
    backgroundColor: isSelected ? theme.palette.coolGrey[100] : 'unset',
    cursor: header.disabled || isSelected ? 'default' : 'pointer',
  })
);

export const StyledUserProfileMenuItemWrapper = styled(Box)<{ option: MenuOption; isSelected: boolean }>(
  ({ theme, option, isSelected }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      height: 36,
    },
    [theme.breakpoints.up('xl')]: {
      height: 28,
    },
    borderRadius: theme.shape.borderRadius,
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },

    color: option.disabled ? theme.palette.action.disabled : theme.palette.coolGrey[800],
    backgroundColor: isSelected ? theme.palette.coolGrey[100] : 'unset',
    cursor: option.disabled || isSelected ? 'default' : 'pointer',
    ...(!option.disabled && {
      '&:hover': {
        backgroundColor: theme.palette.coolGrey[100],
      },
    }),
  })
);
