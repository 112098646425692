import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { InvoiceType } from '../../api';
import { Customer } from '../../api/models/customer.models';
import {
  GetInvoiceHeadersRequest,
  InvoiceExportCheckBox,
  InvoiceExportOptions,
  InvoiceHeader,
  InvoiceHeaderReference,
} from '../../api/models/invoice.models';
import { filterObjects } from '../../helpers';
import { normalizeKey } from '../../helpers/general/string';
import { RootState } from '../store';

// Adapters
const invoiceHeaderAdapter = createEntityAdapter<InvoiceHeader>({
  selectId: (inv: InvoiceHeader) => normalizeKey(inv.InvoiceHeaderKey),
  sortComparer: (a: InvoiceHeader, b: InvoiceHeader) => {
    return new Date(b.InvoiceDate).valueOf() - new Date(a.InvoiceDate).valueOf();
  },
});

// State
interface InvoiceState {
  getInvoiceHeadersRequest: GetInvoiceHeadersRequest | undefined;
  invoiceHeaders: EntityState<InvoiceHeader>;
  invoiceHeadersLoading: boolean;

  invoiceCustomers: Customer[];
  invoiceCustomersLoading: boolean;

  selectedInvoiceCustomers: string[];
  selectedInvoiceHeaderKeys: string[];
  invoiceDocumentLoading: boolean;
  invoiceExportOptions?: InvoiceExportOptions;
  InvoiceHeader?: InvoiceHeaderReference[];
  invoiceLabel?: string[];
  resetInvoiceExport: boolean;
  invoiceExportOrderedList?: InvoiceExportCheckBox[];
}

const initialState: InvoiceState = {
  getInvoiceHeadersRequest: undefined,
  invoiceHeaders: invoiceHeaderAdapter.getInitialState(),
  invoiceHeadersLoading: true,

  invoiceCustomers: [],
  invoiceCustomersLoading: true,

  selectedInvoiceCustomers: [],

  selectedInvoiceHeaderKeys: [],
  invoiceDocumentLoading: false,
  invoiceExportOptions: undefined,
  InvoiceHeader: undefined,
  invoiceLabel: undefined,
  resetInvoiceExport: false,
  invoiceExportOrderedList: undefined,
};

// Reducers
export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    // Invoice Headers
    resetInvoiceHeaders: (state: InvoiceState) => {
      state.getInvoiceHeadersRequest = initialState.getInvoiceHeadersRequest;
      state.invoiceHeaders = invoiceHeaderAdapter.getInitialState();
      state.invoiceHeadersLoading = initialState.invoiceHeadersLoading;
    },
    setInvoiceHeaders: (
      state: InvoiceState,
      action: PayloadAction<{ request: GetInvoiceHeadersRequest; result: InvoiceHeader[] }>
    ) => {
      const { request, result } = action.payload;
      state.getInvoiceHeadersRequest = request;
      invoiceHeaderAdapter.setAll(state.invoiceHeaders, result);
      state.invoiceHeadersLoading = false;
    },
    setInvoiceHeadersLoading: (state: InvoiceState, action: PayloadAction<boolean>) => {
      state.invoiceHeadersLoading = action.payload;
    },
    setSelectedInvoiceHeaderKeys: (state: InvoiceState, action: PayloadAction<string[]>) => {
      state.selectedInvoiceHeaderKeys = action.payload;
    },
    setAllSelectedInvoiceHeaderKeys: (state: InvoiceState) => {
      state.selectedInvoiceHeaderKeys = [...state.invoiceHeaders.ids] as string[];
    },
    // Invoice Customers
    setInvoiceCustomers: (state: InvoiceState, action: PayloadAction<Customer[]>) => {
      state.invoiceCustomers = action.payload;
      state.invoiceCustomersLoading = initialState.invoiceHeadersLoading;
    },
    setInvoiceCustomersLoading: (state: InvoiceState, action: PayloadAction<boolean>) => {
      state.invoiceCustomersLoading = action.payload;
    },
    setSelectedInvoiceCustomers: (state: InvoiceState, action: PayloadAction<string[]>) => {
      state.selectedInvoiceCustomers = action.payload;
    },
    setInvoiceDocumentLoading: (state: InvoiceState, action: PayloadAction<boolean>) => {
      state.invoiceDocumentLoading = action.payload;
    },
    setInvoiceExportOptions: (state: InvoiceState, action: PayloadAction<InvoiceExportOptions>) => {
      state.invoiceExportOptions = action.payload;
    },
    resetInvoiceExportOptions: (state: InvoiceState) => {
      state.invoiceExportOptions = initialState.invoiceExportOptions;
    },
    setInvoiceLabel: (state: InvoiceState, action: PayloadAction<string[]>) => {
      state.invoiceLabel = action.payload;
    },
    resetInvoiceExport: (state: InvoiceState, action: PayloadAction<boolean>) => {
      state.resetInvoiceExport = action.payload;
    },
    setInvoiceExportOrderedList: (state: InvoiceState, action: PayloadAction<InvoiceExportCheckBox[]>) => {
      state.invoiceExportOrderedList = action.payload;
    },
  },
});

// Adapter Selectors
export const {
  selectAll: selectAllInvoiceHeaders,
  selectById: selectInvoiceHeaderByKey,
  selectIds: selectAllInvoiceHeaderKeys,
} = invoiceHeaderAdapter.getSelectors<EntityState<InvoiceHeader>>(
  (invoiceHeaders: EntityState<InvoiceHeader>) => invoiceHeaders
);

// Custom selectors
function getFilterFields(filterOnCustomer: boolean): (keyof InvoiceHeader)[] {
  const fields: (keyof InvoiceHeader)[] = [];
  if (filterOnCustomer) {
    fields.push('CustomerName', 'CustomerAddress', 'CustomerLocation', 'CustomerNumber', 'OperationCompanyName');
  }
  fields.push(
    'InvoiceNumber',
    'InvoiceType',
    'InvoiceDate',
    'OrderNumber',
    'PurchaseOrderNumber',
    'InvoiceTotalQuantity',
    'InvoiceTotalDollars'
  );
  return fields;
}

type FilterRequest = {
  filterText: string | undefined;
  filterOnCustomer: boolean;
};

export const selectFilteredInvoiceHeaderKeys = createSelector(
  (state: RootState) => state.invoice.invoiceHeaders,
  (_state: RootState, filterRequest: FilterRequest) => filterRequest.filterText,
  (_state: RootState, filterRequest: FilterRequest) => filterRequest.filterOnCustomer,
  (invoiceHeaders, filterText, filterOnCustomer) => {
    if (!filterText) return selectAllInvoiceHeaderKeys(invoiceHeaders) as string[];

    const items = selectAllInvoiceHeaders(invoiceHeaders);
    const filteredItems = filterObjects(items, filterText, getFilterFields(filterOnCustomer), [
      {
        field: 'InvoiceType',
        type: 'custom',
        filterFunction: (val: string, queryString: string) =>
          InvoiceType[+val as InvoiceType].toString().toLocaleLowerCase().includes(queryString),
      },
      {
        field: 'InvoiceDate',
        type: 'date',
      },
      {
        field: 'InvoiceTotalDollars',
        type: 'currency',
      },
    ]);

    return filteredItems.map((i) => normalizeKey(i.InvoiceHeaderKey) as string) as string[];
  }
);
