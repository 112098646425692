import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { UnitOfMeasureOrderQuantity } from '../../api/models/api-shared.models';
import { CustomerProductPriceResult } from '../../api/models/customer-product-price.models';
import { OrderEntryProduct } from '../../api/models/order-entry-search.models';
import { ProductDetailTransfer } from '../../api/models/product-detail.models';
import { GetFooterResultDetail, Inventory } from '../../api/models/product-footer.models';
import { normalizeProductKey } from '../../helpers/general/product';
import { RootState } from '../store';

const comparisonProductAdapter = createEntityAdapter<ComparisonProduct>({
  selectId: (product: ComparisonProduct) => normalizeProductKey(product.ProductKey),
});

const comparisonProductsLoadingAdapter = createEntityAdapter<string>({
  selectId: (productKey: string | undefined) => normalizeProductKey(productKey),
});

export type ComparisonProduct = {
  ProductKey: string;
  Product: OrderEntryProduct;
  ProductFooter?: GetFooterResultDetail;
  DetailLoaded: boolean;
};

export enum ProductComparisonSection {
  History,
  ImageCarousel,
  ProductBadges,
  ProductDescription,
  ProductPackSizes,
  ProductBrand,
  ProductQuantity1,
  ProductQuantity2,
  ProductQuantity3,
  ServingSize,
  ShipsInDays,
  Inventory,
}

export type ProductComparisonQuantityState = {
  initialQuantity: number;
  updatedQuantity?: number;
  isChanged: boolean;
};

interface ProductComparisonState {
  readonly maxProducts: number;
  isOpen: boolean;
  comparisonProducts: EntityState<ComparisonProduct>;
  comparisonProductsLoading: EntityState<string>;
  sectionHeights: Record<ProductComparisonSection, number | undefined>;
}

const initialState: ProductComparisonState = {
  maxProducts: 5,
  isOpen: false,
  comparisonProducts: comparisonProductAdapter.getInitialState(),
  comparisonProductsLoading: comparisonProductsLoadingAdapter.getInitialState(),
  sectionHeights: {} as Record<ProductComparisonSection, number | undefined>,
};

// Reducers
export const productComparisonSlice = createSlice({
  name: 'productComparison',
  initialState: initialState,
  reducers: {
    setComparisonOpenState: (state: ProductComparisonState, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },

    setComparisonProductLoading: (
      state: ProductComparisonState,
      action: PayloadAction<{ productKey: string | undefined; isLoading: boolean }>
    ) => {
      const productKey = normalizeProductKey(action.payload.productKey);
      if (action.payload.isLoading)
        comparisonProductsLoadingAdapter.upsertOne(state.comparisonProductsLoading, productKey);
      else comparisonProductsLoadingAdapter.removeOne(state.comparisonProductsLoading, productKey);
    },
    addComparisonProduct: (
      state: ProductComparisonState,
      action: PayloadAction<{ product: OrderEntryProduct; detailLoaded?: boolean }>
    ) => {
      const productKey = normalizeProductKey(action.payload.product.ProductKey);
      const product = action.payload.product;
      const productDetailLoaded = action.payload.detailLoaded ?? false;
      const comparisonProduct: ComparisonProduct = {
        ProductKey: productKey,
        Product: product,
        DetailLoaded: productDetailLoaded,
      };
      const existingProduct = state.comparisonProducts.entities[normalizeProductKey(comparisonProduct.ProductKey)];
      comparisonProductAdapter.upsertOne(state.comparisonProducts, { ...existingProduct, ...comparisonProduct });
    },
    setComparisonProducts: (state: ProductComparisonState, action: PayloadAction<ComparisonProduct[]>) => {
      comparisonProductAdapter.setAll(state.comparisonProducts, action.payload);
    },
    removeComparisonProduct: (state: ProductComparisonState, action: PayloadAction<string | undefined>) => {
      comparisonProductAdapter.removeOne(state.comparisonProducts, normalizeProductKey(action.payload));
    },
    updateComparisonProduct: (state: ProductComparisonState, action: PayloadAction<ProductDetailTransfer>) => {
      const comparisonProduct = state.comparisonProducts.entities[normalizeProductKey(action.payload.productKey)];
      if (comparisonProduct) {
        comparisonProduct.Product?.UnitOfMeasureOrderQuantities?.forEach?.((uom: UnitOfMeasureOrderQuantity) => {
          if (uom.UnitOfMeasure === action.payload.uom) {
            uom.ExtendedPrice = action.payload.extendedPrice;
            uom.Quantity = action.payload.quantity;
            uom.ShowOrderQuantityAlert = action.payload.showOrderQuantityAlert;
          }
        });
      }
    },
    resetProductComparisons: (state: ProductComparisonState) => {
      state.comparisonProducts = initialState.comparisonProducts;
      state.comparisonProductsLoading = initialState.comparisonProductsLoading;
      state.sectionHeights = initialState.sectionHeights;
    },
    setProductFooter: (
      state: ProductComparisonState,
      action: PayloadAction<{ productKey: string; productFooter: GetFooterResultDetail }>
    ) => {
      const comparisonProduct = state.comparisonProducts.entities[normalizeProductKey(action.payload.productKey)];
      if (comparisonProduct) comparisonProduct.ProductFooter = action.payload.productFooter;
    },
    upsertSectionHeight: (
      state: ProductComparisonState,
      action: PayloadAction<{ section: ProductComparisonSection; height: number | undefined }>
    ) => {
      state.sectionHeights[action.payload.section] = action.payload.height;
    },
    resetSectionHeights: (state: ProductComparisonState) => {
      state.sectionHeights = initialState.sectionHeights;
    },
    setProductComparisonPrices: (
      state: ProductComparisonState,
      action: PayloadAction<CustomerProductPriceResult[]>
    ) => {
      action.payload.forEach((priceResult: CustomerProductPriceResult) => {
        if (priceResult.ProductKey) {
          const comparisonProduct = state.comparisonProducts.entities[normalizeProductKey(priceResult.ProductKey)];
          if (comparisonProduct) {
            comparisonProduct.Product?.UnitOfMeasureOrderQuantities?.forEach?.((uom: UnitOfMeasureOrderQuantity) => {
              if (uom.UnitOfMeasure === priceResult.UnitOfMeasureType) {
                uom.Price = priceResult.Price;
                if (priceResult.ProductAverageWeight > 0) {
                  uom.ProductAverageWeight = priceResult.ProductAverageWeight;
                }
              }
            });
          }
        }
      });
    },
  },
});

// Selectors
export const { selectAll: selectComparisonProducts, selectById: selectComparisonProductById } =
  comparisonProductAdapter.getSelectors<ProductComparisonState>(
    (state: ProductComparisonState) => state.comparisonProducts
  );

export const selectProductCompareDisplayShippingSection = createSelector(
  (s: ProductComparisonState) => s,
  (productComparisonState) =>
    selectComparisonProducts(productComparisonState).some(
      (cp: ComparisonProduct) => cp.Product && cp.Product.ShipLaterMaxEstimatedDays > 0
    )
);

export const selectProductCompareDisplayNextPOSection = createSelector(
  (state: ProductComparisonState) => state,
  (productComparisonState) =>
    selectComparisonProducts(productComparisonState).some(
      (cp: ComparisonProduct) =>
        cp.ProductFooter && cp.ProductFooter.Inventories.find((item: Inventory) => item.EstimatedDeliveryDate !== null)
    )
);

export const selectComparisonProductIsLoading = createSelector(
  [
    (s: RootState) => s.productComparison.comparisonProductsLoading.ids,
    (_s: RootState, productKey: string | undefined) => productKey,
  ],
  (ids, productKey) => ids.indexOf(normalizeProductKey(productKey)) >= 0
);

export const selectComparisonProductCount = createSelector(
  [(s: RootState) => s.productComparison.comparisonProducts.ids.length],
  (comparisonProductCount) => comparisonProductCount
);

export const selectHasMaxComparisonProducts = createSelector(
  (s: RootState) => selectComparisonProductCount(s),
  (s: RootState) => s.productComparison.maxProducts,
  (productCount, maxProducts) => productCount >= maxProducts
);
