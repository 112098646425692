import { ServiceResult } from '../models/api-shared.models';
import { VerifyPendingUser } from '../models/verify-user.enums';
import { VerifyContactEmailAddressRequest, VerifyPendingUserRequest } from '../models/verify-user.models';
import ApiService from './api-service';

class VerifyUserService extends ApiService {
  private static classInstance: VerifyUserService;

  private constructor() {
    super('VerifyUser');
  }

  public verifyContactEmailAddress(request: VerifyContactEmailAddressRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/VerifyContactEmailAddress`, request);
  }

  public verifyPendingUser(request: VerifyPendingUserRequest): ServiceResult<VerifyPendingUser> {
    return this.post(`${this.baseUrl}/VerifyPendingUser`, request);
  }

  public static getInstance(): VerifyUserService {
    if (!this.classInstance) {
      this.classInstance = new VerifyUserService();
    }
    return this.classInstance;
  }
}

export default VerifyUserService;
