import { Button, Icon } from '@mui/material';
import { FC } from 'react';
import { UnreadMessagesBadge } from '../../../../../../account/components/message-center/UnreadMessagesBadge';

export interface AccountButtonProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLElement>;
  setRef?: (instance: HTMLButtonElement | null) => void;
}

export const AccountButton: FC<AccountButtonProps> = (props: AccountButtonProps) => {
  return (
    <Button
      variant='text'
      disableElevation
      sx={{ color: 'black', minWidth: { xs: 23, lg: 30 } }}
      onClick={props.onClick}
      onKeyDown={props.onKeyDown}
      ref={(_ref) => props.setRef?.(_ref)}
      data-testid='profile-menu-btn'
    >
      <UnreadMessagesBadge>
        <Icon className='fa-user' fontSize='medium' />
      </UnreadMessagesBadge>
    </Button>
  );
};
