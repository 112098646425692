export enum CustomerAlertType {
  WarningMessage,
  InformationalMessage,
  SystemMessage,
}

export interface CustomerAlert {
  CustomerAlertMessage: string;
  CustomerAlertType: CustomerAlertType;
}

export interface CustomerAlertRequest {
  CustomerId: string;
}
