import { ServiceResult } from '../models/api-shared.models';
import {
  GetFooterResultDetail,
  GetProductFooterRequest,
  GetProductFooterListRequest,
} from '../models/product-footer.models';
import ApiService from './api-service';

class ProductFooterService extends ApiService {
  private static classInstance: ProductFooterService;

  private constructor() {
    super('ProductFooter');
  }

  public getProductFooter(request: GetProductFooterRequest): ServiceResult<GetFooterResultDetail> {
    return this.get(`${this.baseUrl}/GetProductFooter`, request);
  }

  public getProductFooterList(request: GetProductFooterListRequest): ServiceResult<GetFooterResultDetail[]> {
    return this.post(`${this.baseUrl}/GetProductFooterList`, request);
  }

  public static getInstance(): ProductFooterService {
    if (!this.classInstance) {
      this.classInstance = new ProductFooterService();
    }
    return this.classInstance;
  }
}

export default ProductFooterService;
