import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { FileResult } from '../../api/models/api-shared.models';
import {
  DownloadOrderImportSpreadsheetTemplateRequest,
  ImportOrderDelimitedRequest,
  ImportOrderDelimitedResultData,
  ImportOrderSpreadsheetRequest,
  ImportOrderSpreadsheetResultData,
  ValidateImportOrderDelimitedRequest,
  ValidateImportOrderDelimitedResultData,
  ValidateImportOrderSpreadsheetRequest,
  ValidateImportOrderSpreadsheetResultData,
} from '../../api/models/order-import.models';
import OrderEntryHeaderService from '../../api/services/order-entry-header.service';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { Resolution, UserActivityAction, UserActivityActionSummary, UserActivityPageName } from '../../models';
import { globalSlice, logUserActivity } from '../common';
import { setErrorDialogContent } from '../common/global.thunks';
import { AppDispatch, AppThunk } from '../store';

const service = OrderEntryHeaderService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Retrieves file data of a csv template containing records for each product in the specified product list
 * @param request - the request sent to the api
 * @param onSuccess - the callback method executed when api repsonse is successful
 * @param onError - the callback method executed when api repsonse is unsuccessful
 * @returns
 */
export const getOrderImportSpreadsheetTemplate =
  (
    request: DownloadOrderImportSpreadsheetTemplateRequest,
    onSuccess: (file: FileResult) => void,
    onError: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await service.getOrderImportSpreadsheetTemplate(request);
      if (data.IsSuccess) {
        onSuccess(data.ResultObject);
      } else {
        onError();
        dispatch(
          globalSlice.actions.setErrorDialogContent({
            title: 'Failed to download template',
            messages: data.ErrorMessages,
          })
        );
      }
    } catch (error) {
      onError();
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sends a request to the api to evaluate the validity of records contained in the selected file of the request
 * @param request - the request sent to the api
 * @param onSuccess - the callback method executed when api repsonse is successful
 * @param onError - the callback method executed when api repsonse is unsuccessful
 * @returns
 */
export const validateImportOrderSpreadsheet =
  (
    request: ValidateImportOrderSpreadsheetRequest,
    onSuccess: (result: ValidateImportOrderSpreadsheetResultData) => void,
    onError: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      // Call the API
      const { data } = await service.validateImportOrderSpreadsheet(request);

      // Handle results
      if (data.IsSuccess) {
        onSuccess(data.ResultObject);
      } else {
        onError();
        dispatch(setErrorDialogContent('Error Validating Spreadsheet', data.ErrorMessages));
      }
    } catch (error) {
      onError();
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sends a request to the api to evaluate the validity of records contained in the selected file of the request
 * @param request - the request sent to the api
 * @param onSuccess - the callback method executed when api repsonse is successful
 * @param onError - the callback method executed when api repsonse is unsuccessful
 * @returns
 */
export const validateImportOrderDelimited =
  (
    request: ValidateImportOrderDelimitedRequest,
    onSuccess: (result: ValidateImportOrderDelimitedResultData) => void,
    onError: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      // Call the API
      const { data } = await service.validateImportOrderDelimited(request);

      // Handle results
      if (data.IsSuccess) {
        onSuccess(data.ResultObject);
      } else {
        onError();
        dispatch(setErrorDialogContent('Error Validating Spreadsheet', data.ErrorMessages));
      }
    } catch (error) {
      onError();
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sends a request to the api to create a new product list from the data contained in a csv file
 * @param request - the request sent to the api
 * @param resolution - passed to the logUserActivity thunk
 * @param onSuccess - the callback method executed when api repsonse is successful
 * @param onError - the callback method executed when api repsonse is unsuccessful
 * @returns
 */
export const importOrderSpreadsheet =
  (
    request: ImportOrderSpreadsheetRequest,
    resolution: Resolution,
    onSuccess: (result: ImportOrderSpreadsheetResultData) => void,
    onError: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      // Call the API
      const { data } = await service.importOrderSpreadsheet(request);

      // Handle results
      if (data.IsSuccess) {
        onSuccess(data.ResultObject);
        dispatch(
          logUserActivity({
            action: UserActivityAction.CreateOrder,
            pageName: UserActivityPageName.OrderImport,
            actionSummary: UserActivityActionSummary.CreateOrderFromImport,
            resolution: resolution,
          })
        );
      } else {
        onError();
        dispatch(setErrorDialogContent('Error Validating Spreadsheet', data.ErrorMessages));
      }
    } catch (error) {
      onError();
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sends a request to the api to create a new product list from the delimited data contained in the selected file
 * @param request - the request sent to the api
 * @param onSuccess - the callback method executed when api repsonse is successful
 * @param onError - the callback method executed when api repsonse is unsuccessful
 * @returns
 */
export const importOrderDelimited =
  (
    request: ImportOrderDelimitedRequest,
    onSuccess: (result: ImportOrderDelimitedResultData) => void,
    onError: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      // Call the API
      const { data } = await service.importOrderDelimited(request);

      // Handle results
      if (data.IsSuccess) {
        onSuccess(data.ResultObject);
      } else {
        onError();
        dispatch(setErrorDialogContent('Error Validating Spreadsheet', data.ErrorMessages));
      }
    } catch (error) {
      onError();
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };
