import { Box, Icon, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, PropsWithChildren } from 'react';
import { DeviceType, useBreakpoint } from '../../../hooks/useBreakpoint';

export interface CfMenuItemPrefixProps {
  iconClassName?: string;
  iconSx?: SxProps<Theme>;
}

export const CfMenuItemPrefix: FC<CfMenuItemPrefixProps> = (props: PropsWithChildren<CfMenuItemPrefixProps>) => {
  const { iconClassName, iconSx } = props;
  const deviceType = useBreakpoint().deviceType;
  return (
    <Box
      className='cf-menu-item-prefix'
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mr: iconClassName ? 1 : 0 }}
    >
      {props.iconClassName && (
        <Icon
          baseClassName='fa'
          className={iconClassName}
          fontSize={deviceType === DeviceType.Desktop ? 'extraSmall' : 'small'}
          sx={{ color: (theme) => theme.palette.grey[400], ...iconSx }}
        />
      )}
    </Box>
  );
};
