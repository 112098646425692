import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { AreaFeaturePermissionSetting, UserSite } from '../../api/models/site.models';
import { UserSettingPreference } from '../../api/models/user-setting.models';
import { GetUsersByCustomerAccessResultData, User } from '../../api/models/user.models';
import { VerifyPendingUser } from '../../api/models/verify-user.enums';
import { getSortComparer, hasUserAcceptedEssentialTerms } from '../../helpers';
import { normalizeKey } from '../../helpers/general/string';
import { RootState } from '../store';

const defaultAreaFeaturePermissionSetting: AreaFeaturePermissionSetting = {
  AreaSetting: {
    IsParManagementEnabled: false,
    IsOrderEntryEnabled: false,
    IsListManagementEnabled: false,
    IsInvoicesEnabled: false,
    IsARStatementEnabled: false,
    IsMessageCenterEnabled: false,
    IsMultiAccountEnabled: false,
  },
  FeatureSetting: {
    CanViewStopShipMessages: false,
    CanViewInventoryLevelsOnHand: false,
    CanEditOpenOrders: false,
    CanViewInvoiceInsights: false,
    CanBillPay: false,
    CanViewIndividualInvoiceDocuments: false,
    CanRequestMissingNutitionalInformation: false,
    IsDeliveryDayMissingNotificationEnabled: false,
    CanViewOrderCutOffs: false,
    CanViewListNotifications: false,
  },
  PermissionSetting: {
    CanAccessParManagement: false,
    CanManageUserAccounts: false,
    CanCreateOrder: false,
    CanSubmitOrder: false,
    CanViewPrices: false,
    CanModifyCustomAttributes: false,
    CanAccessListMangement: false,
    CanManageList: false,
    CanAccessInvoiceArea: false,
    CanAccessARStatementArea: false,
    CanSendMessages: false,
    CanChangePrices: false,
    CanOnlyShowLowestUOM: false,
    CanOnlyAccessManagedListProducts: false,
    CanViewInvoiceInsightsArea: false,
  },
};

const usersByCustomerAccessAdapter = createEntityAdapter<GetUsersByCustomerAccessResultData>({
  selectId: (user: GetUsersByCustomerAccessResultData) => normalizeKey(user.UserId),
});

// State
interface UserState {
  user?: User;
  userSite?: UserSite;
  userSettingPreference?: UserSettingPreference;
  showReviewProfileDialog: boolean;
  displayCustomerLocationToast: boolean;
  emailVerificationComplete: boolean;
  emailVerificationSuccessful: boolean;
  pendingUserVeificationLoading: boolean;
  pendingUserVerification: VerifyPendingUser | undefined;
  pendingUserVerificationError: boolean | undefined;
  usersByCustomerAccess: EntityState<GetUsersByCustomerAccessResultData>;
  usersByCustomerAccessLoading: boolean;
  showPriorityMessagesDialog: boolean;
}

const initialState: UserState = {
  user: undefined,
  userSite: undefined,
  userSettingPreference: undefined,
  showReviewProfileDialog: false,
  displayCustomerLocationToast: false,
  emailVerificationComplete: false,
  emailVerificationSuccessful: true,
  pendingUserVeificationLoading: false,
  pendingUserVerification: undefined,
  pendingUserVerificationError: undefined,
  usersByCustomerAccess: usersByCustomerAccessAdapter.getInitialState(),
  usersByCustomerAccessLoading: false,
  showPriorityMessagesDialog: false,
};

// Reducers
export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUserFirstName: (state: UserState, action: PayloadAction<string | undefined>) => {
      if (state.user) state.user.FirstName = action.payload;
      if (state.userSite) state.userSite.FirstName = action.payload;
    },
    setUserLastName: (state: UserState, action: PayloadAction<string | undefined>) => {
      if (state.user) state.user.LastName = action.payload;
      if (state.userSite) state.userSite.LastName = action.payload;
    },
    setUser: (state: UserState, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setCurrentUserSite: (state: UserState, action: PayloadAction<UserSite | undefined>) => {
      if (action.payload) {
        const userCustomers = action.payload.UserCustomers ?? [];
        state.userSite = {
          ...action.payload,
          UserCustomers: [...userCustomers].sort(getSortComparer((uc) => uc.CustomerName)),
        };
      } else {
        state.userSite = initialState.userSite;
      }
    },
    setIsUserPastCheckupDate: (state: UserState, action: PayloadAction<boolean>) => {
      if (state.userSite) state.userSite.IsPastManagedUsersCheckupReviewDate = action.payload;
    },
    setUserSettingPreference: (state: UserState, action: PayloadAction<UserSettingPreference>) => {
      state.userSettingPreference = action.payload;
    },
    setShowReviewProfileDialog: (state: UserState, action: PayloadAction<boolean>) => {
      state.showReviewProfileDialog = action.payload;
    },
    setShowPriorityMessagesDialog: (state: UserState, action: PayloadAction<boolean>) => {
      state.showPriorityMessagesDialog = action.payload;
    },
    toggleCustomerLocationToast: (state: UserState, action: PayloadAction<{ display: boolean }>) => {
      state.displayCustomerLocationToast = action.payload.display;
    },
    setEmailVerificationSuccessful: (state: UserState, action: PayloadAction<boolean>) => {
      state.emailVerificationSuccessful = action.payload;
    },
    setEmailVerificationComplete: (state: UserState, action: PayloadAction<boolean>) => {
      state.emailVerificationComplete = action.payload;
    },
    resetEmailVerification: (state: UserState) => {
      state.emailVerificationSuccessful = initialState.emailVerificationSuccessful;
      state.emailVerificationComplete = initialState.emailVerificationComplete;
    },
    setAreaFeaturePermissionSetting: (state: UserState, action: PayloadAction<AreaFeaturePermissionSetting>) => {
      if (state.userSite) state.userSite.AreaFeaturePermissionSetting = action.payload;
      //if (state.userSite) state.userSite.AreaFeaturePermissionSetting = defaultAreaFeaturePermissionSetting;
    },
    setPendingUserVerificationLoading: (state: UserState, action: PayloadAction<boolean>) => {
      state.pendingUserVeificationLoading = action.payload;
    },
    setPendingUserVerification: (state: UserState, action: PayloadAction<VerifyPendingUser>) => {
      state.pendingUserVerification = action.payload;
    },
    setPendingUserVerificationError: (state: UserState, action: PayloadAction<boolean>) => {
      state.pendingUserVerificationError = action.payload;
    },
    resetPendingUserVerification: (state: UserState) => {
      state.pendingUserVerification = initialState.pendingUserVerification;
      state.pendingUserVerificationError = initialState.pendingUserVerificationError;
    },
    setUsersByCustomerAccess: (state: UserState, action: PayloadAction<GetUsersByCustomerAccessResultData[]>) => {
      usersByCustomerAccessAdapter.setAll(state.usersByCustomerAccess, action.payload);
    },
    setUsersByCustomerAccessLoading: (state: UserState, action: PayloadAction<boolean>) => {
      state.usersByCustomerAccessLoading = action.payload;
    },
  },
});

const getUserSite = (state: RootState): UserSite | undefined => state.user.userSite;
const getUserCustomerLength = (state: RootState): number | undefined => state.user.userSite?.UserCustomers?.length;
export const selectUserAreaSetting = createSelector(
  getUserSite,
  (s) => s?.AreaFeaturePermissionSetting?.AreaSetting ?? defaultAreaFeaturePermissionSetting.AreaSetting
);

export const selectUserFeatureSetting = createSelector(
  getUserSite,
  (s) => s?.AreaFeaturePermissionSetting?.FeatureSetting ?? defaultAreaFeaturePermissionSetting.FeatureSetting
);

export const selectUserPermissionSetting = createSelector(
  getUserSite,
  (s) => s?.AreaFeaturePermissionSetting?.PermissionSetting ?? defaultAreaFeaturePermissionSetting.PermissionSetting
);

export const selectUserIsMultiUnit = createSelector(getUserCustomerLength, (length) => (length ?? 0) > 1);

export const selectIsPunchoutUser = createSelector(getUserSite, (s) => s?.PunchoutDomain != null);

export const {
  selectAll: selectAllUsers,
  selectById: selectUserById,
  selectIds: selectAllUserIds,
} = usersByCustomerAccessAdapter.getSelectors<RootState>((state: RootState) => state.user.usersByCustomerAccess);

export const selectUserHasAcceptedEssentialTerms = createSelector(getUserSite, (s) => hasUserAcceptedEssentialTerms(s));
