import { FormControlLabel, Radio, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { isString } from 'common/src/helpers/general/string';
import React, { FC, useEffect, useRef } from 'react';
import { StyledBox, StyledDot, StyledPrimaryCircle, StyledSecondaryCircle } from './CfRadio.styles';

export type CfRadioSize = 'small' | 'medium';

/**
 * @param label - The label being displayed with the radio item
 * @param value - The value of the radio item
 * @param disabled - Boolean indicating if the radio item should be disabled
 * @param sx - Styling of the radio button container
 * @param radioSx - Used to provide additional styling to the radio button
 */
export interface RadioItem {
  label?: string | JSX.Element;
  value: unknown;
  isDisabled?: boolean;
  sx?: SxProps<Theme>;
  radioSx?: SxProps<Theme>;
}

const icon1 = (disabled: boolean, variant: CfRadioVariant, size: CfRadioSize) => {
  return (
    <StyledBox size={size}>
      <StyledPrimaryCircle isDisabled={disabled} size={size} />
    </StyledBox>
  );
};

const checkedIcon1 = (disabled: boolean, size: CfRadioSize) => {
  return (
    <StyledBox size={size}>
      <StyledPrimaryCircle isDisabled={disabled} size={size}>
        <StyledDot isDisabled={disabled} size={size} />
      </StyledPrimaryCircle>
    </StyledBox>
  );
};

const icon2 = (disabled: boolean, size: CfRadioSize) => {
  return (
    <StyledBox size={size}>
      <StyledSecondaryCircle isDisabled={disabled} />
    </StyledBox>
  );
};

const checkedIcon2 = (disabled: boolean, size: CfRadioSize) => {
  return (
    <StyledBox size={size}>
      <StyledSecondaryCircle isDisabled={disabled}>
        <StyledDot isDisabled={disabled} />
      </StyledSecondaryCircle>
    </StyledBox>
  );
};

export type CfRadioVariant = 'primary' | 'secondary';

export interface CfRadioProps {
  item: RadioItem;
  variant: CfRadioVariant;
  size?: CfRadioSize;
  isChecked?: boolean;
  isDisabled?: boolean;
  sx?: SxProps<Theme>;
  dataTestId?: string;
  isAutoFocusChecked?: boolean;
  isFocusAppliedToInput?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  onChange?: (item: RadioItem) => void;
  onMouseDown?: (item: RadioItem, event?: React.MouseEvent<HTMLLabelElement>) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLElement>;
}

/**
 * Wraps the MUI Radio component
 *
 * @param item - RadioItem holding information about the radio button
 * @param variant - Styling variant (primary, secondary)
 * @param size - The size that the radio button will be (small, medium)
 * @param isChecked - Boolean value representing if the radio button is currently selected
 * @param isDisabled - Boolean indicating if the radio button is disabled
 * @param sx - Additional styling of the radio button
 * @param dataTestId - Test id for the radio button
 * @param isAutoFocusChecked - Boolean indicating if the checked radio button should be focused on
 * @param isFocusAppliedToInput - Boolean indicating if the focus should switch to the input element
 * @param inputRef - The reference to the current radio button
 * @param onChanged - Method to call on change
 * @param onKeyDown - Method to call on key down event
 */
export const CfRadio: FC<CfRadioProps> = (props: CfRadioProps) => {
  const inputRef = props.inputRef ?? useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.isAutoFocusChecked && props.isChecked) {
      setTimeout(() => inputRef.current?.focus(), 0);
    }
  }, []);

  const label = isString(props.item.label) ? (
    <Typography variant='paragraphSmall'>{props.item.label}</Typography>
  ) : (
    props.item.label ?? <></>
  );

  const isDisabled = props.isDisabled ?? props.item.isDisabled ?? false;
  const size = props.size ?? 'small';

  const augmentedSx = {
    height: 0,
    padding: 0,
    margin: 1,
    ...props.sx,
  };

  const handleFormControlFocus = (event: React.FocusEvent) => {
    // By default, transfer focus from the form control to the input element.
    const isFocusAppliedToInput = props.isFocusAppliedToInput ?? true;

    if (isFocusAppliedToInput && event.relatedTarget !== inputRef.current) {
      inputRef.current?.focus();
    }
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLElement> = (event) => {
    if (event.code.toLowerCase() === 'enter') {
      props.onChange?.(props.item);
    }
    props.onKeyDown?.(event);
  };

  return (
    <FormControlLabel
      control={
        <Radio
          size='small'
          inputRef={inputRef}
          color='primary'
          disabled={isDisabled}
          checkedIcon={props.variant === 'primary' ? checkedIcon1(isDisabled, size) : checkedIcon2(isDisabled, size)}
          icon={props.variant === 'primary' ? icon1(isDisabled, props.variant, size) : icon2(isDisabled, size)}
          sx={{ '& .MuiTouchRipple-child': { color: (theme) => theme.palette.primary.dark }, ...augmentedSx }}
          data-testid={props.dataTestId}
        />
      }
      label={label}
      onFocus={handleFormControlFocus}
      onKeyDown={handleKeyDown}
      checked={props.isChecked}
      value={props.item.value}
      onMouseDown={(event) => props.onMouseDown?.(props.item, event)}
      onChange={() => props.onChange?.(props.item)}
      sx={props.item.sx}
    />
  );
};
