import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetExportOptionDefaultsResultData } from '../../api';
import { FileResult } from '../../api/models/api-shared.models';

export interface ProductListReportState {
  reportIsLoading: boolean;
  productListReport: FileResult | undefined;
  previousSelections: GetExportOptionDefaultsResultData | undefined;
}

const initialState: ProductListReportState = {
  reportIsLoading: false,
  productListReport: undefined,
  previousSelections: undefined,
};

export const productListReportSlice = createSlice({
  name: 'product-list-report',
  initialState: initialState,
  reducers: {
    setReportIsLoading: (state: ProductListReportState, action: PayloadAction<boolean>) => {
      state.reportIsLoading = action.payload;
    },
    setProductListReport: (state: ProductListReportState, action: PayloadAction<FileResult>) => {
      state.productListReport = action.payload;
    },
    setPreviousSelections: (
      state: ProductListReportState,
      action: PayloadAction<GetExportOptionDefaultsResultData>
    ) => {
      state.previousSelections = action.payload;
    },
    resetPreviousSelections: (state: ProductListReportState) => {
      state.previousSelections = initialState.previousSelections;
    },
  },
});
