import { Checkbox, FormControlLabel, Icon } from '@mui/material/';
import { styled } from '@mui/material/styles';

interface CheckboxSizeProps {
  checkboxSize: number;
}

export const StyledFormControlLabel = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {},
  margin: 0,
});

export const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== 'checkboxSize',
})<CheckboxSizeProps>(({ theme, checkboxSize }) => ({
  marginBottom: 'auto',
  padding: 0,
  marginRight: theme.spacing(1),
  backgroundColor: theme.palette.common.white,

  '& input + .MuiIcon-root': {
    height: checkboxSize,
    width: checkboxSize,
    boxSizing: 'border-box',
  },

  '& input:disabled + .MuiIcon-root': {
    borderColor: theme.palette.coolGrey[300],
    backgroundColor: theme.palette.coolGrey[100],
  },
  '&[aria-checked="true"] .MuiIcon-root': {
    borderColor: theme.palette.primary.main,
  },
  '&[aria-checked="true"] [color === "primary"]  .MuiIcon-root': {
    backgroundColor: theme.palette.primary.main,
  },

  '&[aria-checked="true"] input:focus + .MuiIcon-root': {
    backgroundColor: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    borderWidth: 2,
    '&::before': {
      top: 0.5,
    },
  },
  '&[aria-checked="false"] input:focus + .MuiIcon-root': {
    borderColor: theme.palette.primary.dark,
    borderWidth: 2,
    '&::before': {
      top: 0.5,
    },
  },
}));

export const StyledIcon = styled(Icon, {
  shouldForwardProp: (prop) => prop !== 'checkboxSize',
})<CheckboxSizeProps>(({ theme, checkboxSize }) => ({
  '&.far.fa-square': {
    color: 'transparent',
  },
  '&.MuiIcon-root': {
    borderRadius: `${theme.shape.borderRadius}px`,
    borderWidth: theme.custom.outlinedButtonBorderWidth,
    borderColor: theme.palette.coolGrey[300],
    borderStyle: 'solid',
    height: checkboxSize - 2 * theme.custom.outlinedButtonBorderWidth,
    width: checkboxSize - 2 * theme.custom.outlinedButtonBorderWidth,
    padding: 0,
    position: 'relative',
    '&.MuiIcon-fontSizeExtraSmall': {
      lineHeight: checkboxSize - 2 * theme.custom.outlinedButtonBorderWidth + 'px',
    },
    '&::before': {
      width: '100%',
      textAlign: 'center',
      position: 'relative',
      top: 1,
    },
  },
}));

export const StyledLabel = styled('span')(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    ...theme.typography.mobileParagraph,
    lineHeight: '18px',
  },
  [theme.breakpoints.up('xl')]: {
    ...theme.typography.paragraph,
    lineHeight: '18px',
  },
}));
