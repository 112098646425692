import { Box, Link, Stack, Typography } from '@mui/material';
import { AppRoutes, RootState, RouteName, UserCustomer, selectUserAreaSetting, useAppSelector } from 'common';
import { useState } from 'react';
import { CfTooltip } from '../../../../../cf-ui/Tooltip/CfTooltip';
import { DeviceType, useBreakpoint } from '../../../../../hooks/useBreakpoint';
import TypographySkeleton from '../../../../_shared/components/TypographySkeleton';
import { StyledSwitch } from '../../../../orders/components/Orders/components/AllLocationsSwitch.styles';
import CommunicationsNotificationsManagement from './CommunicationsNotificationsManagement';

interface CommunicationsOrderConfirmationsProps {
  selectedCustomer: UserCustomer;
}

const CommunicationsOrderConfirmations: React.FC<CommunicationsOrderConfirmationsProps> = (
  props: CommunicationsOrderConfirmationsProps
): JSX.Element => {
  const { deviceType } = useBreakpoint();

  const [checkedState, setCheckedState] = useState<boolean>(true);
  const isOrderEntryEnabled = useAppSelector((s: RootState) => selectUserAreaSetting(s).IsOrderEntryEnabled);
  const user = useAppSelector((s: RootState) => s.user.user);

  const handleChange = (checked: boolean) => {
    setCheckedState(checked);
  };

  if (!isOrderEntryEnabled) {
    return (
      <>
        <Stack sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', gap: 3 }}>
          <Typography variant={deviceType === DeviceType.Desktop ? 'h3' : 'h2'}>Preferences</Typography>

          <Typography>
            You do not have the permissions to update preferences. Please contact your sales representative with any
            questions
          </Typography>
        </Stack>
      </>
    );
  }

  return (
    <>
      <Stack
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          gap: 3,
        }}
      >
        <Typography variant={deviceType === DeviceType.Desktop ? 'h3' : 'h2'}>Preferences</Typography>

        <Box>
          <Typography
            variant='h5'
            sx={{ mt: { lg: 0.5, xl: 0 } }}
            data-testid='communications-order-confirmations-title'
          >
            ORDER CONFIRMATIONS
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            maxWidth: { xs: 295, lg: 344, xl: 242 },
          }}
        >
          <Typography variant='responsiveParagraph' data-testid='communications-order-confirmations-email-title'>
            Email
          </Typography>
          <CfTooltip
            title='You will receive order confirmations for all orders you place, so this cannot be updated'
            placement={deviceType === DeviceType.Mobile ? 'bottom-end' : 'right'}
            enterDelay={200}
            data-testid='communications-order-confirmations-tooltip'
          >
            <span>
              <StyledSwitch
                color='primary'
                size='medium'
                onChange={(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleChange(checked)}
                checked={checkedState}
                onKeyDown={(event: React.KeyboardEvent<HTMLButtonElement>) =>
                  event.key === 'Enter' && handleChange(!checkedState)
                }
                disabled={true}
                data-testid='communications-order-confirmations-switch'
              />
            </span>
          </CfTooltip>
        </Box>
      </Stack>

      {user ? (
        <Stack
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: 2,
          }}
        >
          {user.ContactEmailAddress && user.ContactEmailAddressVerifiedDate ? (
            <CommunicationsNotificationsManagement
              selectedCustomer={props.selectedCustomer}
              contactEmail={user.ContactEmailAddress}
            />
          ) : user.ContactEmailAddress ? (
            <Typography variant='responsiveParagraphSmall' sx={{ color: (theme) => theme.palette.error.main, mt: 1 }}>
              Please verify your email address in&nbsp;
              <Link href={AppRoutes[RouteName.UserProfile].Path}>Account Profile</Link>
            </Typography>
          ) : (
            <Typography variant='responsiveParagraphSmall' sx={{ color: (theme) => theme.palette.error.main, mt: 1 }}>
              Please add an email address to your&nbsp;
              <Link href={AppRoutes[RouteName.UserProfile].Path}>Account Profile</Link>
            </Typography>
          )}
        </Stack>
      ) : (
        <TypographySkeleton charCount={37} variant='responsiveParagraphSmall' sx={{ display: 'block' }} />
      )}
    </>
  );
};

export default CommunicationsOrderConfirmations;
