import { AppRoutes, getTimeBetween, resetActiveOrder, RouteName, useAppDispatch, useTimer } from 'common';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CfSimpleDialog } from '../../../../cf-ui/dialogs/SimpleDialog/CfSimpleDialog';

export interface EditOrderDialogProps {
  expirationDate: Date;
}

export const ExpiredOrderDialog = (props: EditOrderDialogProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const seconds = getTimeBetween(new Date(), props.expirationDate, 'second', false);
  const [isOpen, setIsOpen] = useState(false);

  const { restart, stop } = useTimer(seconds, () => {
    setIsOpen(true);
  });

  useEffect(() => {
    if (isOpen) {
      dispatch(resetActiveOrder());
      history.replace(AppRoutes[RouteName.Home].Path);
    }
  }, [isOpen]);

  useEffect(() => {
    if (props.expirationDate && seconds > 0) {
      restart();
    } else {
      stop();
    }
  }, [props.expirationDate]);

  const handleDialogConfirm = () => {
    setIsOpen(false);
  };

  return (
    <CfSimpleDialog
      isOpen={isOpen}
      title='Order not updated'
      onClose={handleDialogConfirm}
      primaryButtonProps={{ children: 'Close', onClick: handleDialogConfirm }}
    >
      Your order was not updated. The change window has expired.
    </CfSimpleDialog>
  );
};
