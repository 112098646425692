import { SubmitOrderEntryDetailResultDetail, UnitOfMeasureOrderQuantity, CatalogProduct } from '../../../api';
import { ProductData } from '../../../models';
import { getAnalyticsItemData } from './google-analytics.mappers';
import { AnalyticsContext } from './google-analytics.models';

export const logOrderDetailQuantityAnalytics = (
  analyticsContext: AnalyticsContext | undefined,
  result: SubmitOrderEntryDetailResultDetail,
  uom: UnitOfMeasureOrderQuantity,
  index: number,
  product?: ProductData
) => {
  const analytics = analyticsContext?.analytics;
  if (!analyticsContext || !analytics) return;

  let quantity = result.Quantity - (uom?.Quantity ?? 0);
  const added = quantity > 0;
  quantity = Math.abs(quantity);

  const item = getAnalyticsItemData(
    result.ProductKey ?? '',
    result.UnitOfMeasureType.toString(),
    uom?.Price ?? 0,
    quantity,
    index,
    uom,
    product as CatalogProduct,
    analyticsContext.listId,
    analyticsContext.listName
  );

  if (added) analytics.addToCart([item], 'USD', result.ExtendedPrice);
  else analytics.removeFromCart([item], 'USD', uom?.ExtendedPrice ?? 0);
};
