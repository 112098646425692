import { Box } from '@mui/material';
import { FC } from 'react';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { StyledIcon, StyledRemoveText, StyledText } from './CfSuggestion.styles';

/**
 * the properties which relate to each item of a CfSuggestionList component
 */
export interface SuggestionItem {
  text: string;
  value: string;
  type?: string;
  iconClassName?: string;
  isRemovable?: boolean;
}

/**
 * the properties which describe how the CfSuggestion component should present and behave
 */
export interface CfSuggestionProps {
  item: SuggestionItem;
  isActionInFocus?: boolean;
  onRemoveClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, item: SuggestionItem) => void;
}

/**
 * Displays an icon, label, and interactive "Remove" typography for each item of a CfSuggestionList component
 * @param item.text displayed as stylized text
 * @param item.value set and untilized by handlers
 * @param item.type set and utilized by handlers
 * @param item.iconClassName passed to the Icon component's className property
 * @param item.isRemovable determines whether to render a Typography component that calls the event handler assigned to the onRemoveClick property
 * @param focusAction affects the style of the "remove" Typography component
 * @param onRemoveClick passed to the "remove" Typography component's onClick property
 */
export const CfSuggestion: FC<CfSuggestionProps> = (props: CfSuggestionProps) => {
  const { breakpoint } = useBreakpoint();
  return (
    <>
      {props.item.iconClassName && (
        <StyledIcon className={props.item.iconClassName} fontSize={breakpoint === 'xl' ? 'extraSmall' : 'small'} />
      )}
      <Box sx={{ display: 'flex', mr: 5 }}>
        <StyledText>{props.item.text}</StyledText>
      </Box>
      {props.item.isRemovable && (
        <StyledRemoveText
          onClick={(event) => props.onRemoveClick?.(event, props.item)}
          sx={props.isActionInFocus ? { backgroundColor: (theme) => theme.palette.coolGrey[200], py: 0.75 } : {}}
        >
          Remove
        </StyledRemoveText>
      )}
    </>
  );
};
