import { Box, Icon, Slide, Stack, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { FC, PropsWithChildren, useRef } from 'react';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { CfCloseButton } from '../buttons/CfCloseButton';
import { StyledActionButton, StyledDivider, StyledSnackbar, StyledToastBox } from './CfToast.styles';

/**
 * the properties which describe how the CfToast component should present and behave
 */
export interface CfToastProps {
  isOpen: boolean;
  message: React.ReactNode;
  onClose?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  actionLabel?: string;
  action?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  variant?: 'info' | 'success' | 'warning' | 'error' | 'primary';
  icon?: string;
  sx?: SxProps<Theme>;
  closeButtonDataTestId?: string;
  actionButtonDataTestId?: string;
  toastDataTestId?: string;
}

/**
 * @param children passed to the Snackbar component's message property
 * @param open passed to the Snackbar component's open property
 * @param message rendered within a Typography component, after the Icon component, before the actionLabel's Button component
 * @param onClose passed to the Snackbar component's onClose property
 * @param onMouseEnter passed to the onMouseEnter property of the color container
 * @param onMouseLeave passed to the onMouseLeavel property of the color container
 * @param actionLabel rendered within a Button component after the message property's Typography component, before the CfCloseButton component
 * @param action passed to the actionLabel's Button component's onClick property
 * @param variant determines the colors of the toast
 * @param icon passed to the Icon component's className property
 * @param sx passed to the Snackbar component's sx property
 * @param closeBtnTestId passed to the CfCloseButton's dataTestId property
 * @param actionBtnTestId passed to the actionLabel's Button component's data-testid property
 * @param toastDataTestId passed to the color container's data-testid property
 * @returns
 */
export const CfToast: FC<PropsWithChildren<CfToastProps>> = (props: PropsWithChildren<CfToastProps>) => {
  const theme = useTheme();
  const { breakpoint } = useBreakpoint();
  const currentRef = useRef(null);

  let backgroundColor = undefined;
  let color = theme.palette.common.white;
  let lineColor = 'rgba(255, 255, 255, 0.5)';
  switch (props.variant) {
    case 'error':
      backgroundColor = theme.palette?.error?.main;
      break;
    case 'warning':
      backgroundColor = theme.palette?.warning?.main;
      color = theme.palette.grey[900];
      lineColor = 'rgba(0, 0, 0, 0.5)';
      break;
    case 'success':
      backgroundColor = theme.palette?.success?.dark;
      break;
    case 'info':
      backgroundColor = theme.palette?.coolGrey[600];
      break;
    case 'primary':
      backgroundColor = theme.palette?.primary.main;
      break;
    default:
      break;
  }

  return (
    <StyledSnackbar
      open={props.isOpen}
      onClose={() => props.onClose?.()}
      message={props.children}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      ClickAwayListenerProps={{ onClickAway: () => undefined }}
      sx={props.sx}
    >
      <Box overflow='hidden' ref={currentRef}>
        <Slide
          in={props.isOpen}
          container={currentRef.current}
          timeout={200}
          easing={{ enter: 'cubic-bezier(0,1.33,.9,.91)', exit: 'cubic-bezier(.14,.35,0,-0.79)' }}
        >
          <StyledToastBox
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            data-testid={props.toastDataTestId}
            sx={{ backgroundColor: backgroundColor, color: color }}
          >
            <Stack direction='row' alignItems='center' justifyContent='center' sx={{ width: '100%' }}>
              {props.icon && <Icon className={props.icon} fontSize='medium' sx={{ mr: 1 }} />}
              <Stack
                direction={breakpoint === 'xs' ? 'column' : 'row'}
                alignItems={breakpoint !== 'xs' ? 'center' : undefined}
                justifyContent='center'
                spacing={2}
              >
                <Typography variant='paragraphBold'>{props.message}</Typography>
                {props.actionLabel && (
                  <StyledActionButton
                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => props.action?.(event)}
                    variant='outlined'
                    data-testid={props.actionButtonDataTestId}
                    size='extraSmall'
                    sx={{
                      borderColor: lineColor,
                      color: color,
                    }}
                  >
                    {props.actionLabel}
                  </StyledActionButton>
                )}
              </Stack>
              <StyledDivider sx={{ background: lineColor }} />
              <CfCloseButton
                dataTestId={props.closeButtonDataTestId}
                size='small'
                variant='thick'
                onClick={() => props.onClose?.()}
                sx={{ p: 0, color: color }}
              />
            </Stack>
          </StyledToastBox>
        </Slide>
      </Box>
    </StyledSnackbar>
  );
};
