import { FC } from 'react';
import { CfUncontrolledDrawer } from '../../../../cf-ui/Drawer/CfUncontrolledDrawer';
import {
  closeAccountSideBarActions,
  useAccountSideBarActions,
} from '../../../../hooks/account/message-center/useAccountSideBarActions';
import { useRouteChangeCleanup } from '../../../../hooks/useRouteChangeCleanup';
import AccountSideBar from '../AccountSideBar/AccountSideBar';

const AccountSideBarDrawer: FC = (): JSX.Element => {
  const actionState = useAccountSideBarActions();
  const anchor = actionState?.anchor;
  useRouteChangeCleanup(() => closeAccountSideBarActions(), []);

  return (
    <CfUncontrolledDrawer
      open={Boolean(anchor)}
      onClose={() => closeAccountSideBarActions()}
      mode='panel'
      placement='right'
      containerSx={{ p: 3, pt: 2, width: 350 }}
      data-testid='account-sidebar-drawer'
    >
      <AccountSideBar />
    </CfUncontrolledDrawer>
  );
};

export default AccountSideBarDrawer;
