import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { GetInvoiceDetailsRequest } from '../../api/models/invoice.models';
import InvoiceService from '../../api/services/invoice.service';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { GridSettings } from '../../models/grid.models';
import { AppRoute } from '../../models/routing.models';
import { AppDispatch, AppThunk } from '../store';
import { invoiceDetailsSlice } from './invoice-details.slice';

const invoiceService = InvoiceService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Calls and stores the results of the GetInvoiceDetails API call
 *
 * @param request - The request that will be sent to the API
 * @returns NULL
 */
export const getInvoiceDetails =
  (request: GetInvoiceDetailsRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(invoiceDetailsSlice.actions.setInvoiceDetailsLoading(true));

      const { data } = await invoiceService.getInvoiceDetails(request);
      if (data.IsSuccess) {
        dispatch(invoiceDetailsSlice.actions.setInvoiceDetails(data.ResultObject));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(invoiceDetailsSlice.actions.setInvoiceDetailsLoading(false));
    }
  };

/**
 * Sets the invoiceDetailsGridSettings value in the invoice details slice
 *
 * @param gridSettings - The gridSettings the invoiceDetailsGridSettings value is to be set to
 * @returns NULL
 */
export const setInvoiceDetailsGridSettings =
  (gridSettings: GridSettings): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(invoiceDetailsSlice.actions.setInvoiceDetailsGridSettings(gridSettings));
  };

/**
 * Resets the invoice details slice to its initial values
 *
 * @returns NULL
 */
export const resetInvoiceDetailsState = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(invoiceDetailsSlice.actions.resetState());
};

/**
 * Sets the route value in the invoice details slice which is used for the invoice details breadcrumb
 *
 * @param routes - The value to set the route in the slice to
 * @returns NULL
 */
export const setInvoiceDetailBreadCrumb =
  (routes: AppRoute[]): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(invoiceDetailsSlice.actions.setInvoiceDetailBreadCrumb(routes));
  };
