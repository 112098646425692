import { FC } from 'react';
import { CfUncontrolledDrawer } from '../../../../../../../cf-ui/Drawer/CfUncontrolledDrawer';
import AccountSideBar from '../../../../../../account/components/AccountSideBar/AccountSideBar';
import { AccountButton } from '../AccountButton/AccountButton';

export interface AccountDrawerProps {
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

export const AccountDrawer: FC<AccountDrawerProps> = (props: AccountDrawerProps) => {
  const handleDrawerButtonClick = () => {
    if (props.isOpen) props.onClose?.();
    else props.onOpen?.();
  };

  return (
    <>
      <AccountButton onClick={handleDrawerButtonClick} />
      <CfUncontrolledDrawer
        open={Boolean(props.isOpen)}
        onClose={props.onClose}
        mode='panel'
        placement='right'
        containerSx={{ p: 3, pt: 2, width: 350 }}
      >
        <AccountSideBar />
      </CfUncontrolledDrawer>
    </>
  );
};
