import { ServiceResult } from '../models/api-shared.models';
import { CustomerAlert, CustomerAlertRequest } from '../models/customer-alert.models';
import ApiService from './api-service';

class CustomerAlertService extends ApiService {
  private static classInstance: CustomerAlertService;

  private constructor() {
    super('CustomerAlert');
  }

  public getCustomerAlert(request: CustomerAlertRequest): ServiceResult<CustomerAlert[]> {
    return this.get(`${this.baseUrl}/GetCustomerAlert`, request);
  }

  public static getInstance(): CustomerAlertService {
    if (!this.classInstance) {
      this.classInstance = new CustomerAlertService();
    }
    return this.classInstance;
  }
}

export default CustomerAlertService;
