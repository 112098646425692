import { Box, ButtonPropsSizeOverrides, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { OverridableStringUnion } from '@mui/types';
import { AnalyticsContext } from 'common';
import { OrderSummaryContinueButton } from './OrderSummaryContinueButton';
import { OrderSummaryReviewButton } from './OrderSummaryReviewButton';
import { OrderSummarySubmitButton } from './OrderSummarySubmitButton';

interface OrderSummaryActionButtonsProps {
  mode: 'entry' | 'review';
  size?: OverridableStringUnion<'small' | 'medium' | 'large', ButtonPropsSizeOverrides>;
  showContinueShoppingButton?: boolean;
  showPrice?: boolean;
  analyticsContext?: AnalyticsContext;
  onNavigateToOrderEntry?: () => void;
  onNavigateToOrderReview?: () => void;
  onNavigateToOrderConfirmation?: () => void;
  sx?: SxProps<Theme>;
}

export const OrderSummaryActionButtons = (props: OrderSummaryActionButtonsProps): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        ...props.sx,
      }}
    >
      {props.showContinueShoppingButton && (
        <OrderSummaryContinueButton size={props.size} onNavigateToOrderEntry={props.onNavigateToOrderEntry} />
      )}
      {props.mode === 'entry' && (
        <OrderSummaryReviewButton
          size={props.size}
          showPrice={props.showPrice}
          onNavigateToOrderReview={props.onNavigateToOrderReview}
        />
      )}
      {props.mode === 'review' && (
        <OrderSummarySubmitButton
          size={props.size}
          onNavigateToOrderConfirmation={props.onNavigateToOrderConfirmation}
          analyticsContext={props.analyticsContext}
        />
      )}
    </Box>
  );
};
