import {
  AppRoutes,
  RootState,
  RouteName,
  toggleCustomerLocationToast,
  updateSelectedCustomer,
  useAppDispatch,
  useAppSelector,
  UserCustomer,
} from 'common';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DeviceType, useBreakpoint } from '../../../../../../hooks/useBreakpoint';
import { LocalStorageKeys, LocalStorageService } from '../../../../../../utilities/local-storage-service';
import { Menus, setMenu, useHeaderMenus } from '../../hooks/useHeaderMenus';
import { LocationBar } from '../common/LocationBar';
import { LocationBarMobile } from '../common/LocationBarMobile';
import LocationDrawer from './components/LocationDrawer/LocationDrawer';
import LocationMenu from './components/LocationMenu/LocationMenu';

export type LocationVariant = 'menu' | 'drawer';

export interface LocationProps {
  variant: LocationVariant;
}

const Location: FC<LocationProps> = (props: LocationProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { openedMenu } = useHeaderMenus();
  const { deviceType } = useBreakpoint();

  // State
  const [customer, setCustomer] = useState<UserCustomer | undefined>(undefined);
  const [customerOptions, setCustomerOptions] = useState<UserCustomer[]>([]);

  // Selectors
  const userSite = useAppSelector((s: RootState) => s.user.userSite);
  const selectedCustomer = useAppSelector((s: RootState) => s.customer.selectedCustomer);

  const localStorageService = new LocalStorageService();

  const handleCustomerChange = (customer: UserCustomer | undefined) => {
    if (customer) {
      setCustomer(customer);
      setSortedCustomerList(customer);
      dispatch(updateSelectedCustomer(customer, { updateLocationAllSetting: true, skipOrderDataAndAlerts: true }));
      history.push(AppRoutes[RouteName.Home].Path);
      setMenu(Menus.None);
      dispatch(toggleCustomerLocationToast({ display: true }));
    }
  };

  useEffect(() => {
    return () => {
      setMenu(Menus.None); //Cleanup on dismount
    };
  }, []);

  useEffect(() => {
    setCustomer(selectedCustomer);
    if (selectedCustomer) {
      setSortedCustomerList(selectedCustomer);
    }
  }, [userSite, selectedCustomer]);

  const setSortedCustomerList = (newCustomer: UserCustomer) => {
    if (userSite) {
      localStorageService.setItem(LocalStorageKeys.KEY_SELECTED_CUSTOMER_ID, newCustomer.CustomerId);
      const filteredCustomers = userSite.UserCustomers.filter(
        (c: UserCustomer) => c.CustomerId !== newCustomer.CustomerId
      );
      filteredCustomers.unshift({ ...newCustomer });
      setCustomerOptions(filteredCustomers);
    }
  };

  if ((userSite?.UserCustomers?.length ?? 0) <= 1)
    return (
      <>
        {deviceType === DeviceType.Desktop ? (
          <LocationBar
            dataTestId='location-menu-btn'
            iconClassName={locationMenuIcon}
            sx={{ pointerEvents: 'none', cursor: 'default' }}
          />
        ) : (
          <LocationBarMobile iconClassName={locationMenuIcon} />
        )}
      </>
    );
  if (props.variant === 'menu') {
    return (
      <LocationMenu
        customer={customer}
        customerOptions={customerOptions}
        selectedCustomer={selectedCustomer}
        onSelect={handleCustomerChange}
        icon={locationMenuIcon}
        isOpen={openedMenu === Menus.LocationMenu}
        onMenuClose={() => setMenu(Menus.None)}
        onMenuOpen={() => setMenu(Menus.LocationMenu)}
        caretUpIcon={caretUpIcon}
        caretDownIcon={caretDownIcon}
      />
    );
  } else {
    return (
      <LocationDrawer
        customer={customer}
        customerOptions={customerOptions}
        selectedCustomer={selectedCustomer}
        onSelect={handleCustomerChange}
        forcedToggleState={openedMenu === Menus.LocationMenu}
        onDrawerOpen={() => setMenu(Menus.LocationMenu)}
        onDrawerClose={() => setMenu(Menus.None)}
        icon={locationMenuIcon}
        caretUpIcon={caretUpIcon}
        caretDownIcon={caretDownIcon}
      />
    );
  }
};

export default Location;

const locationMenuIcon = 'fa-map-marker-alt';
const caretUpIcon = 'fas fa-caret-up';
const caretDownIcon = 'fas fa-caret-down';
