import { Box, styled } from '@mui/material';

export const StyledDiv = styled(Box)({
  maxHeight: '350px',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  minWidth: 'auto',
  textOverflow: 'clip',
});

export const StyledCustomerNameListItemDiv = styled(StyledDiv)({
  WebkitLineClamp: 2,
});
