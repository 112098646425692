import { useTimer } from 'common';
import { useEffect, useState } from 'react';
import { CfToast, CfToastProps } from './CfToast';

export interface CfAutoDismissToastProps extends CfToastProps {
  autoDimissSeconds: number;
}

export const CfAutoDismissToast = (props: CfAutoDismissToastProps): JSX.Element => {
  const { autoDimissSeconds, ...toastProps } = props;
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const timer = useTimer(autoDimissSeconds, () => handleClose());
  const animationTimer = useTimer(autoDimissSeconds - 0.2, () => setIsOpen(false));

  const handleClose = () => {
    props.onClose?.();
  };

  useEffect(() => {
    if (props.isOpen) {
      setIsOpen(true);
      timer.restart(autoDimissSeconds);
      animationTimer.restart(autoDimissSeconds - 0.2);
    } else {
      setIsOpen(false);
      timer.stop();
      animationTimer.stop();
    }
  }, [props.isOpen]);

  return (
    <CfToast
      {...toastProps}
      onMouseEnter={() => {
        timer.pause();
        animationTimer.pause();
      }}
      onMouseLeave={() => {
        timer.restart(autoDimissSeconds);
        animationTimer.restart(autoDimissSeconds - 0.2);
      }}
      isOpen={isOpen}
    />
  );
};
