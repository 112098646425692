import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material/';

export const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const StyledSearchButton = styled(IconButton)(({ theme }) => ({
  zIndex: 1,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.background.default,
  borderRadius: '0px 4px 4px 0px',
  maxHeight: 'unset',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));
