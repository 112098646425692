import { Box, Stack, Typography } from '@mui/material';
import {
  AppNotification,
  NotificationKeys,
  RootState,
  getRouteName,
  selectUserFeatureSetting,
  toLocalDateString,
  updateOrderDeliveryDate,
  useAppDispatch,
  useAppSelector,
} from 'common';
import { OrderEntryType } from 'common/src/models/order.enums';
import { DeviceType, useBreakpoint } from '../../../../../hooks/useBreakpoint';
import TypographySkeleton from '../../../../_shared/components/TypographySkeleton';
import CutoffDateDisplay from '../../common/CutoffDateDisplay';
import { OrderDeliveryDateCalendar } from '../../common/order/OrderDeliveryDateCalendar';
import { StyledLabelTypography } from '../OrderSummary.styles';
import { EditableHeaderName } from './EditableHeaderName';
import { EditablePurchaseOrderNumber } from './EditablePurchaseOrderNumber';

interface OrderSummaryDetailsProps {
  dataTestId?: string;
  labelTestId?: string;
}

export const OrderSummaryDetails = (props: OrderSummaryDetailsProps): JSX.Element => {
  // hooks
  const { deviceType } = useBreakpoint();
  const dispatch = useAppDispatch();

  // selectors
  //const businessUnitKey = useAppSelector((x: RootState) => x.customer.selectedCustomer?.BusinessUnitKey);
  const canViewOrderCutOffFeature = useAppSelector((s: RootState) => selectUserFeatureSetting(s).CanViewOrderCutOffs);
  const orderEntryHeader = useAppSelector((x: RootState) => x.orders.order);
  const hasInvalidDeliveryDate = useAppSelector((s: RootState) =>
    s.global.notifications.some((x: AppNotification) => x.Key === NotificationKeys.InvalidDeliveryDate)
  );

  const handleCalendarDateChange = (calendarDate: Date) => {
    const deliveryDate = calendarDate.toISOString();
    if (deliveryDate) {
      dispatch(updateOrderDeliveryDate(deliveryDate, getRouteName()));
    }
  };

  const deliveryCalendar = (
    <OrderDeliveryDateCalendar
      testId='order-summary-details-delivery-calendar'
      date={orderEntryHeader?.DeliveryDate}
      onChange={handleCalendarDateChange}
    />
  );

  return (
    <Stack sx={{ mb: 2, mt: deviceType !== DeviceType.Desktop ? 2 : 0 }}>
      {deviceType !== DeviceType.Desktop && orderEntryHeader?.OrderType !== OrderEntryType.Edit && (
        <EditableHeaderName
          direction={deviceType === DeviceType.Tablet ? 'row' : 'column'}
          size='medium'
          variant='responsiveParagraph'
          label='Order Name: '
          containerSx={deviceType === DeviceType.Tablet ? { width: 332 } : {}}
          buttonContainerSx={{ mr: { xs: '', lg: 'auto' } }}
          labelVariant='responsiveParagraph'
          inputAltTestId={props.dataTestId}
        />
      )}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <StyledLabelTypography variant='responsiveParagraph' data-testid='summary-delivery-date-label'>
          Delivery date:
        </StyledLabelTypography>
        {orderEntryHeader ? (
          <>
            <Typography
              variant={!hasInvalidDeliveryDate ? 'responsiveParagraph' : 'responsiveParagraphBold'}
              sx={{ color: hasInvalidDeliveryDate ? (theme) => theme.palette.error.main : undefined }}
              data-testid='summary-delivery-date'
            >
              {toLocalDateString(orderEntryHeader?.DeliveryDate)}
            </Typography>
            {orderEntryHeader.OrderType !== OrderEntryType.Edit && deliveryCalendar}
          </>
        ) : (
          <TypographySkeleton charCount={10} />
        )}
      </Box>
      <EditablePurchaseOrderNumber
        deviceType={deviceType}
        variant='responsiveParagraph'
        labelVariant='responsiveParagraph'
        buttonContainerSx={{ mr: { xs: 0, lg: 'auto', xl: 0 } }}
        testId='order-summary-details-po'
      />
      {canViewOrderCutOffFeature && orderEntryHeader?.CutoffDateTime && (
        <CutoffDateDisplay
          cutoffDateTime={orderEntryHeader?.CutoffDateTime}
          title='Cut off time:'
          dateTimeSeparator=' at '
          loading={!orderEntryHeader}
          titleSx={{ typography: 'responsiveParagraph' }}
          dateSx={{ typography: 'responsiveParagraphBold' }}
          dataTestId={props.dataTestId}
          labelTestId={props.labelTestId}
        />
      )}
    </Stack>
  );
};
