import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { reduxHistory, store } from 'common';
import { msalInstance } from 'common/src/api/services/axios-instance';
import { ConnectedRouter } from 'connected-react-router';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Analytics from './Analytics';
import reportWebVitals from './reportWebVitals';
import { UnauthenticatedAppRouting } from './routing/AppRouting';

function render() {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const App = require('./App').default;
  ReactDOM.render(
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <AuthenticatedTemplate>
          <ConnectedRouter history={reduxHistory}>
            <Analytics />
            <App />
          </ConnectedRouter>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <ConnectedRouter history={reduxHistory}>
            <UnauthenticatedAppRouting />
          </ConnectedRouter>
        </UnauthenticatedTemplate>
      </Provider>
    </MsalProvider>,
    document.getElementById('root')
  );
}

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
