import { AppRoute, AppRoutes } from '../models/routing.models';

const getBreadCrumbsRouteName = (previousRoute?: AppRoute | undefined): AppRoute | undefined => {
  const appRoutes = Object.values(AppRoutes);
  const cleanPath = location.pathname.substring(1).split('/');

  const queryVal = previousRoute ? previousRoute.Path : `/${cleanPath[0].toLocaleLowerCase()}`;
  const routeFrom = appRoutes.find((appRoute: AppRoute) => queryVal == appRoute.Path.toLocaleLowerCase());

  if (routeFrom) {
    let manipulatedRouteFrom = routeFrom;

    if (cleanPath.length > 1) {
      manipulatedRouteFrom = JSON.parse(JSON.stringify(routeFrom));
      cleanPath.slice(1).forEach((param: string, idx: number) => {
        if (manipulatedRouteFrom.Params) {
          manipulatedRouteFrom.Params[idx] = param;
        }
      });
    }

    // removes unassigned parameters before returning data, this is safe because a URL should never have a colon
    const sanitizedAppRoute: AppRoute = {
      ...manipulatedRouteFrom,
      ...(manipulatedRouteFrom.Params && {
        Params: manipulatedRouteFrom.Params.filter((param) => !param.includes(':')),
      }),
    };
    return sanitizedAppRoute;
  }
  return undefined;
};

export default getBreadCrumbsRouteName;
