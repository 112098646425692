import { ServiceResult } from '../models/api-shared.models';
import { Customer, GetCustomerDeliveryDatesRequest, GetCustomerRequest } from '../models/customer.models';
import ApiService from './api-service';

class CustomerService extends ApiService {
  private static classInstance: CustomerService;

  private constructor() {
    super('Customer');
  }

  public getCustomerDeliveryDates(request: GetCustomerDeliveryDatesRequest): ServiceResult<string[]> {
    return this.get(`${this.baseUrl}/GetCustomerDeliveryDates`, request);
  }

  public getCustomer(request: GetCustomerRequest): ServiceResult<Customer> {
    return this.get(`${this.baseUrl}/GetCustomer`, request);
  }

  public static getInstance(): CustomerService {
    if (!this.classInstance) {
      this.classInstance = new CustomerService();
    }
    return this.classInstance;
  }
}

export default CustomerService;
