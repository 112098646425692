import { Box, Stack, Typography } from '@mui/material';
import { useAppDispatch } from 'common';
import { routerActions } from 'connected-react-router';
import { FC } from 'react';
import { CfContainedButton } from './cf-ui/buttons/CfContainedButton';
import { CfErrorFallbackProps } from './cf-ui/ErrorBoundary/CfErrorBoundaryProps';

export const AppErrorBoundaryFallback: FC<CfErrorFallbackProps> = () => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(routerActions.go(0));
  };

  return (
    <Box sx={{ width: '100vw', height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Stack role='alert' sx={{ gap: 8, alignItems: 'center', m: 2 }}>
        <Stack sx={{ gap: 2, alignItems: 'start' }}>
          <Typography variant='h1'>Oops...</Typography>
          <Typography variant='responsiveParagraph'>Something went wrong while displaying this webpage.</Typography>
        </Stack>
        <CfContainedButton onClick={handleClick} sx={{ alignSelf: 'end' }}>
          Reload
        </CfContainedButton>
      </Stack>
    </Box>
  );
};
