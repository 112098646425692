import { ServiceResult } from '../models/api-shared.models';
import {
  DebugSearchProductCatalogRequest,
  DebugSearchProductCatalogResultDetail,
  SearchProductCatalogRequest,
  SearchProductCatalogResultDetail,
} from '../models/product-catalog.models';
import ApiService from './api-service';

class ProductSearchService extends ApiService {
  private static classInstance: ProductSearchService;
  private productSearchController: AbortController;

  private constructor() {
    super('ProductCatalog');
    this.productSearchController = new AbortController();
  }

  public debugSearchProductCatalog(
    request: DebugSearchProductCatalogRequest
  ): ServiceResult<DebugSearchProductCatalogResultDetail> {
    return this.post(`${this.baseUrl}/DebugSearchProductCatalog`, request);
  }

  public abortSearchProductCatalog = (): void => {
    this.productSearchController.abort();
  };

  public searchProductCatalog(request: SearchProductCatalogRequest): ServiceResult<SearchProductCatalogResultDetail> {
    this.productSearchController = new AbortController();
    return this.post(`${this.baseUrl}/SearchProductCatalog`, request, 'body', this.productSearchController.signal);
  }

  public static getInstance(): ProductSearchService {
    if (!this.classInstance) {
      this.classInstance = new ProductSearchService();
    }
    return this.classInstance;
  }
}

export default ProductSearchService;
