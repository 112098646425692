import { ButtonPropsSizeOverrides, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { OverridableStringUnion } from '@mui/types';
import {
  AnalyticsContext,
  IsOrderEntryPurchaseOrderNumberRequiredType,
  NotificationKeys,
  RootState,
  isProductInvalid,
  resetActiveOrder,
  resetOrderCart,
  selectAllOrderReviewProducts,
  selectUserPermissionSetting,
  submitOrderEntryHeader,
  useAppDispatch,
  useAppSelector,
} from 'common';
import { OrderEntryType } from 'common/src/models/order.enums';
import { useRef } from 'react';
import { CfContainedButton } from '../../../../../cf-ui/buttons/CfContainedButton';
import { DeviceType, useBreakpoint } from '../../../../../hooks/useBreakpoint';
import { getResolution } from '../../../../../utilities/resolution';

interface OrderSummarySubmitButtonProps {
  onNavigateToOrderConfirmation?: () => void;
  size?: OverridableStringUnion<'small' | 'medium' | 'large', ButtonPropsSizeOverrides>;
  sx?: SxProps<Theme>;
  analyticsContext?: AnalyticsContext;
}

export const OrderSummarySubmitButton = (props: OrderSummarySubmitButtonProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { deviceType } = useBreakpoint();

  const orderEntryHeader = useAppSelector((x: RootState) => x.orders.order);
  const activeOrder = useAppSelector((s: RootState) => s.orders.activeOrder);
  const productsInCart = useAppSelector((s: RootState) => selectAllOrderReviewProducts(s));
  const hasInvalidDeliveryDate = useAppSelector((s: RootState) =>
    s.global.notifications.some((x) => x.Key === NotificationKeys.InvalidDeliveryDate)
  );
  const orderReviewLoading = useAppSelector((x: RootState) => x.orderReview.productListLoading);
  const canSubmitOrder = useAppSelector((s: RootState) => selectUserPermissionSetting(s).CanSubmitOrder);
  const orderDetailsUpdating = useAppSelector((s: RootState) => s.orders.orderDetailsUpdating);
  const isFutureSubmission = useAppSelector((s) => s.orders.activeOrderIsFutureSubmission);
  const punchoutDomain = useAppSelector((s) => s.user.userSite?.PunchoutDomain);

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const canOrderBeSubmittedRef = useRef(false);
  canOrderBeSubmittedRef.current =
    isFutureSubmission ||
    !(
      orderReviewLoading ||
      !activeOrder ||
      !orderEntryHeader?.OrderEntryHeaderName ||
      hasInvalidDeliveryDate ||
      ((orderEntryHeader?.IsPurchaseOrderNumberRequired === IsOrderEntryPurchaseOrderNumberRequiredType.Yes ||
        orderEntryHeader?.IsPurchaseOrderNumberRequired ===
          IsOrderEntryPurchaseOrderNumberRequiredType.YesNumbersOnly) &&
        !orderEntryHeader?.PurchaseOrderNumber?.trim()) ||
      (orderEntryHeader?.OrderType !== OrderEntryType.Edit && activeOrder?.TotalQuantity === 0) ||
      productsInCart.some(isProductInvalid)
    );

  const handleClickProceedToConfirmation = () => {
    const action = () => {
      dispatch(submitOrderEntryHeader(getResolution(), props.analyticsContext, orderEntryHeader?.OrderType));

      if (orderEntryHeader?.OrderType === OrderEntryType.Edit) {
        dispatch(resetActiveOrder());
        dispatch(resetOrderCart());
      }

      props.onNavigateToOrderConfirmation?.();
    };

    if (orderDetailsUpdating && orderEntryHeader && orderEntryHeader.TotalLines < 2) {
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = setTimeout(() => {
        if (canOrderBeSubmittedRef.current) {
          action();
        }
      }, 1000);
    } else {
      action();
    }
  };

  if (!canSubmitOrder) return <></>;
  return (
    <CfContainedButton
      onClick={handleClickProceedToConfirmation}
      color='primary'
      isFullWidth={deviceType === DeviceType.Tablet ? false : true}
      data-testid='summary-submit-order-button'
      isDisabled={!canOrderBeSubmittedRef.current}
      size={props.size}
      sx={{ ...props.sx, flex: 1 }}
    >
      {orderEntryHeader?.OrderType === OrderEntryType.Edit ? 'Submit updated order' : 'Submit order'}
      {punchoutDomain ? ` to ${punchoutDomain.PunchoutDomainDisplay}` : ''}
    </CfContainedButton>
  );
};
