import { BuildInfo } from '../helpers';

export enum UserActivityParameter {
  CustomerNumber = '[[CustomerNumber]]',
  OrderName = '[[OrderName]]',
  ProductNumber = '[[ProductNumber]]',
  DeliverDate = '[[DeliveryDate]]',
  OrderEntryHeaderId = '[[OrderEntryHeaderId]]',
  FromPage = '[[FromPage]]',
  UserId = '[[UserId]]',
  PendingUserId = '[[PendingUserId]]',
  CustomerId = '[[CustomerId]]',
}

export enum UserActivityAction {
  BeginEditOrder = 'Begin Order Edit',
  CancelEditOrder = 'Cancel Order Edit',
  CreateOrder = 'Create Order',
  CreateItemOrder = 'Create Item Order',
  DeleteOrder = 'Delete Order',
  Login = 'Login',
  Logout = 'Logout',
  SubmitOrder = 'Submit Order',
  UpdateUserProfile = 'Update User Profile',
  Visit = 'Visit',
  ExportARData = 'Export AR Data',
  SendContactEmailVerification = 'Send Contact Email Verification',
  DownloadInvoiceDocument = 'Download',
  DownloadInvoiceProofOfDeliveryDocument = 'Download Invoice Proof Of Delivery',
  ViewAccountReceivableStatement = 'View AR Statement',
  DownloadProductListImportTemplate = 'Download Product List Import Template',
  CreateProductList = 'Create Product List',
  CopyProductList = 'Copy Product List',
  ImportProductList = 'Import Product List',
  DeleteProductList = 'Delete Product List',
  ShareProductList = 'Share Product List',
  ExportProductList = 'Export Product List',
  PrintProductList = 'Print Product List',
  RepeatOrder = 'Repeat Order',
  RunReport = 'Run Report',
  RunTemplateReport = 'Run Template Report',
  SaveInsight = 'Save Insight',
  SubmitEditOrder = 'Submit Order Edit',
  CreateParGuide = 'Create Par Guide',
  CopyParGuide = 'Copy Par Guide',
  DeleteParGuide = 'Delete Par Guide',
  ResetParLevels = 'Reset Par Levels',
  ResendInvite = 'Resend Invite',
  InviteNewUser = 'Invite New Users',
  AddUserToLocation = 'Add User To Location',
  RemoveUserFromLocation = 'Remove User From Location',
  UpdatePermissions = 'Update Permissions',
}

export enum UserActivityPageName {
  Home = 'Home',
  Login = 'Login',
  Logout = 'Logout',
  NoUserInformation = 'No User Information',
  ListManagement = 'List Management',
  ListDetail = 'List Detail',
  ListNotification = 'List Notification',
  CopyProductList = 'Copy Product List',
  ProductListDelete = 'Delete Product List',
  ProductListShare = 'Share Product List',
  ProductListImport = 'Product List Import',
  ProductListExport = 'Product List Export',
  OrderConfirmation = 'Order Confirmation',
  OrderDetails = 'Order Details',
  OrderEntry = 'Order Entry',
  ItemOrder = 'Item Order',
  OrderImport = 'Order Import',
  OrderReview = 'Order Review',
  OrderHistory = 'Order History',
  RepeatOrder = 'Repeat Order',
  ParManagement = 'Par Management',
  ParDetail = 'Par Detail',
  DeleteParGuide = 'Delete Par Guide',
  CopyParGuide = 'Copy Par Guide',
  PasswordReset = 'Password Reset',
  ProductCompare = 'Product Compare',
  ProductDetails = 'Product Details',
  DeliveryDetails = 'Delivery Detail',
  AccountsReceivableStatements = 'AR Statements',
  AccountsMakePayment = 'AR Statements Make Payment',
  HomepageMakePayment = 'Homepage Make Payment',
  HomepageARStatements = 'Homepage AR Statement',
  Insights = 'Insights',
  InsightsDashboard = 'Insights Dashboard',
  InsightsReport = 'Insights Report',
  Invoices = 'Invoices',
  InvoiceDetail = 'Invoice Detail',
  InvoiceExport = 'Invoice Export',
  SearchAnalysis = 'Search Analysis',
  UI = 'UI',
  UserProfile = 'User Profile',
  CustomerSupport = 'Customer Support',
  CreateMessage = 'Create Message',
  MessageGroups = 'Message Groups',
  ViewMessageGroup = 'View Message Group',
  CreateMessageGroup = 'Create Message Group',
  UpdateMessageGroup = 'Update Message Group',
  InboxMessages = 'Message Inbox',
  ViewInboxMessage = 'View Inbox Message',

  DraftMessages = 'Message Draft',
  ViewDraftMessage = 'View Draft Message',

  SentMessages = 'Message Sent',
  ViewSentMessage = 'View Sent Message',

  Search = 'Search',
  ListDetailSearch = 'List Detail Search',
  FindSimilarSearch = 'Find Similar Search',
  CustomerSupportAccount = 'Customer Support Account',
  CustomerSupportOrdering = 'Customer Support Ordering',
  CustomerSupportInvoices = 'Customer Support Invoices',
  CustomerSupportListManagement = 'Customer Support List Management',
  CustomerSupportParManagement = 'Customer Support Par Management',
  CommunicationPreferences = 'Communication Preferences',
  ManageUsers = 'Manage Users',
  ManageUsersExport = 'Manage Users Export',

  CustomerGroups = 'Customer Groups',
  ViewCustomerGroup = 'View Customer Group',
  CreateCustomerGroup = 'Create Customer Group',
}

export enum UserActivityActionSummary {
  CancelledEditingOrder = 'User cancelled editing a submitted order',
  CreateProductList = 'User Created Product List From List Management',
  CopyProductList = 'User Copied Product List',
  ProductListImport = 'User Imported Product List',
  ProductListDelete = 'User delete custom list',
  ProductListShare = 'User Shared Product List',
  ProductListExport = 'User Exported Product List',
  ProductListPrint = 'User Printed Product List',
  UserExportedARData = 'User Exported AR Data',
  CreateOrderFromHeader = 'Created Order from Header Menu, Customer:[[CustomerNumber]]',
  CreateItemOrderFromHeader = 'Created Item Order from Header Menu, Customer:[[CustomerNumber]]',
  CreateOrderFromHome = 'Created Order from Home New Order Button, Customer:[[CustomerNumber]]',
  CreateOrderFromRecentOrders = 'Created Order from Order History New Order Button, Customer:[[CustomerNumber]]',
  CreateOrderFromImport = 'Created Order from Import, Customer:[[CustomerNumber]]',
  CreateRepeatOrder = 'User Created a Repeat Order from [[FromPage]]',
  DeletedOrder = 'User Deleted Order, Customer:[[CustomerNumber]], Order Name:[[OrderName]]',
  DownloadInvoiceDocument = 'User downloaded invoice export',
  DownloadInvoiceProofOfDeliveryDocument = 'User Download Invoice Proof Of Delivery',
  DownloadAccountReceivableStatement = 'User Downloaded AR Statement',
  DownloadProductListImportTemplate = 'User Downloaded Product List Import Template',
  SentContactEmailVerification = 'User Sent Contact Email Verification',
  SubmittedOrder = 'User Submitted an Order, Customer:[[CustomerNumber]], Order Name:[[OrderName]]',
  StartedEditingOrder = 'User started editing a submitted order',
  SubmittdEditOrder = 'User submitted an edit on a submitted order',
  SuccessfullyLoggedIn = 'User Successfully Logged in',
  SuccessfullyLoggedOut = 'User Successfully Logged out from Header Menu',
  UserUpdatedPassword = 'User Reset their Password',
  UserUpdatedUserProfile = 'User Updated User Profile',
  VisitedAccountsReceivableStatements = 'User Visited AR Statements',
  VisitedAccountsMakePayment = 'User Clicked AR Statements Make Payment',
  VisitedHomepageMakePayment = 'User Clicked Homepage Make Payment',
  VisitedInvoiceDetail = 'User Visited Invoice Detail',
  VisitedHomePage = 'User Visited Home Page, Customer:[[CustomerNumber]]',
  VisitedInvoices = 'User Visited Invoices',
  VisitedInvoicesExport = 'User Visited Invoices Export',
  VisitedListDetail = 'User Visited List Detail',
  VisitedParDetail = 'User Visited Par Detail',
  VisitedListManagement = 'User Visited List Management',
  VisitedListNotification = 'User Visited List Notifications',
  VisitedOrderDetails = 'User Visited Order Detail',
  VisitedOrderConfirmation = 'User Visited Order Confirmation Page, Customer:[[CustomerNumber]], Order Delivery Date:[[DeliveryDate]]',
  VisitedItemOrder = 'User Visited Item Order [[OrderEntryHeaderId]]',
  VisitedOrderEntry = 'User Visited Order Entry [[OrderEntryHeaderId]]',
  VisitedOrderReview = 'User Visited Order Review [[OrderEntryHeaderId]]',
  VisitedOrderImport = 'User Visited Order Import, Customer:[[CustomerNumber]]',
  VisitedParManagement = 'User Visited Par Management',
  VisitedPasswordReset = 'User Visited Password Reset',
  VisitedProductCompare = 'User Visited Product Compare Page, Customer:[[CustomerNumber]]',
  VisitedProductDetails = 'User Visited Product Details Page, Customer:[[CustomerNumber]], Product:[[ProductNumber]]',
  VisitedProductListExport = 'User visited List Export Page',
  VisitedOrderHistory = 'User Visited Order History Page, Customer:[[CustomerNumber]]',
  VisitedRepeatOrder = 'User Visited Repeat Order Page, Customer:[[CustomerNumber]]',
  VisitedDeliveryDetails = 'User Visited Delivery Detail',
  VisitedInsightsDashboard = 'User Visited Insights Dashboard',
  VisitedFindSimilarSearch = 'User Visited Find Similar Search for product: #[[ProductNumber]]',
  VisitedSearch = 'User Visited Search',
  VisitedListDetailSearch = 'User Visited List Detail Search',
  VisitedMessageInbox = 'User Visited Message Inbox',
  VisitedMessageDraft = 'User Visited Message Draft',
  VisitedMessageSent = 'User Visited Message Sent',
  VisitedMessageGroups = 'User Visited Message Groups',
  VisitedCustomerGroups = 'User Visited Customer Groups',
  VisitedCustomerSupportAccount = 'User Visited Customer Support Account',
  VisitedCustomerSupportOrdering = 'User Visited Customer Support Ordering',
  VisitedCustomerSupportInvoices = 'User Visited Customer Support Invoices',
  VisitedCustomerSupportListManagement = 'User Visited Customer Support List Management',
  VisitedCustomerSupportParManagement = 'User Visited Customer Support Par Management',
  VisitedCommunicationPreferences = 'User Visited Communication Preferences',
  RanReport = 'User Ran Insight Report',
  RanTemplateReport = 'User Ran Insight Template Report',
  SavedInsights = 'User Saved Insights Report',
  VisitedUserProfile = 'User Visited Profile',
  CreatedParGuide = 'User Created Par Guide From Par Management',
  CopyParGuide = 'User Copied Par Guide',
  DeletedParGuide = 'User Deleted Par Guide',
  ResetParLevels = 'User Reset Par Levels',
  ResendInvite = 'Site User Resent Invitation to: [[PendingUserId]]',
  InviteNewUser = 'Site User Invited a New User: [[PendingUserId]]',
  AddUserToLocation = 'Site User Added User: [[UserId]] to Location: [[CustomerId]]',
  RemoveUserFromLocation = 'Site User Removed User: [[UserId]] from Location: [[CustomerId]]',
  UpdateExistingUserPermissions = 'Site User Updated User: [[UserId]] User/Customer Permissions for Location: [[CustomerId]]',
  UpdateNewUserPermissions = 'Site User Set Pending User: [[PendingUserId]] User Permissions',
}

export interface LogUserActivityRequest<T = undefined> {
  Action: UserActivityAction;
  PageName: UserActivityPageName;
  ActionSummary: string;
  SessionId: string;
  BrowserName: string;
  BrowserVersion: string;
  Resolution: string;
  CustomerId: string;
  AdditionalData?: T | BuildInfo;
}
