import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { AppDispatch, AppThunk, removeCategoryProduct, RootState } from '..';
import { BusinessUnitERPKeyType, DeleteProductListDetailByProductKeyRequest, ProductListSortByType } from '../../api';
import ProductListDetailService from '../../api/services/product-list-detail.service';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { NotificationKeys, ProductListCategoryProduct } from '../../models';
import { addAppNotification, setErrorDialogContent } from '../common';
import { deleteProductListDetailsSlice, selectAllCategoryProductsToDelete } from './delete-product-list-details.slice';

const productListDetailService = ProductListDetailService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

const errorTitle = 'An Error Occurred';

// View Thunks

/**
 * Resets the deleteProdictListDetail slices to the initial state
 *
 * @returns NULL
 */
export const closeDeleteCategoryProductsDialog =
  (): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(deleteProductListDetailsSlice.actions.resetState());
  };

/**
 * Opens the delete category products dialog and sets the categoryProducts value
 *
 * @param categoryProducts - The products that categoryProducts in the slice will be set to
 * @returns NULL
 */
export const openDeleteCategoryProductsDialog =
  (categoryProducts: ProductListCategoryProduct[]): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(deleteProductListDetailsSlice.actions.openDialog(categoryProducts));
  };

/**
 * Deletes category products
 *
 * @param sortByType - Used to check which delete product list details API call should be made
 * @param filterText - Used to check which delete product list details API call should be made
 * @returns NULL
 */
export const deleteCategoryProducts =
  (sortByType: ProductListSortByType, filterText: string | undefined): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
      const businessUnitERPKey = getState().customer.selectedCustomer?.BusinessUnitERPKey;
      const productListHeaderId = getState().productList.productListHeader?.ProductListHeaderId;
      const categoryProducts = selectAllCategoryProductsToDelete(getState().deleteProductListDetails);

      if (!productListHeaderId || businessUnitERPKey === undefined) return;

      dispatch(deleteProductListDetailsSlice.actions.setIsLoading(true));
      const onSuccess = () => categoryProducts.forEach((p) => dispatch(removeCategoryProduct(p)));

      if (sortByType === ProductListSortByType.ListCategory && !filterText)
        dispatch(
          deleteProductListDetails(
            categoryProducts.map((p) => p.ProductListDetailId),
            onSuccess
          )
        );
      else
        dispatch(
          deleteProductListDetailByProductKeys(
            businessUnitERPKey,
            categoryProducts.map((p) => p.ProductKey),
            [productListHeaderId],
            onSuccess
          )
        );
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(deleteProductListDetailsSlice.actions.resetState());
    }
  };

// API Thunks

/**
 * Deletes product list details
 *
 * @param productListDetailIds - Array of ids to be deleted
 * @param successCallback - Method to call on success
 * @param errorCallback - Method to call on error
 * @returns NULL
 */
export const deleteProductListDetails =
  (
    productListDetailIds: string[],
    successCallback?: () => void | Promise<void>,
    errorCallback?: (errors: string[]) => void | Promise<void>
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await productListDetailService.deleteProductListDetails(productListDetailIds);

      if (data.IsSuccess) {
        successCallback?.();
      } else {
        errorCallback?.(data.ErrorMessages);
        dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Deletes product list details by their product key
 *
 * @param businessUnitERPKey - BU ERP key to use use to create the request
 * @param productKeys - Array of product keys to be deleted
 * @param producListHeaderIds - Array of product list header ids to delete product details from
 * @param successCallback - Method to call on success
 * @param errorCallback - Method to call on error
 * @returns NULL
 */
export const deleteProductListDetailByProductKeys =
  (
    businessUnitERPKey: BusinessUnitERPKeyType,
    productKeys: string[],
    producListHeaderIds: string[],
    successCallback?: () => void | Promise<void>,
    errorCallback?: (errors: string[]) => void | Promise<void>
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const requests = productKeys.map(
        (key) =>
          ({
            ProductListHeaderIds: producListHeaderIds,
            ProductBusinessUnitERPKey: businessUnitERPKey,
            ProductKey: key,
          } as DeleteProductListDetailByProductKeyRequest)
      );
      const { data } = await productListDetailService.deleteProductListDetailByProductKeys(requests);

      if (data.IsSuccess) {
        successCallback?.();
      } else {
        errorCallback?.(data.ErrorMessages);
        dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Deletes product list detail by its product key
 *
 * @param businessUnitERPKey - BU ERP key to use use to create the request
 * @param productKeys - Product keys to be deleted
 * @param successCallback - Method to call on success
 * @param errorCallback - Method to call on error
 * @param producListHeaderIds - Array of product list header ids to delete product details from
 * @returns NULL
 */
export const deleteProductListDetailByProductKey =
  (
    productBusinessUnitERPKey: BusinessUnitERPKeyType,
    productKey: string | undefined,
    successCallback?: () => void | Promise<void>,
    errorCallback?: (errors: string[]) => void | Promise<void>,
    producListHeaderIds?: string[] | undefined
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
      const productListHeaderId = getState().productList.productListHeader?.ProductListHeaderId ?? '';

      if (!productKey) return;

      const { data } = await productListDetailService.deleteProductListDetailByProductKey({
        ProductListHeaderIds: producListHeaderIds ?? [productListHeaderId],
        ProductBusinessUnitERPKey: productBusinessUnitERPKey,
        ProductKey: productKey,
      });

      if (data.IsSuccess) {
        successCallback?.();
        dispatch(addAppNotification(NotificationKeys.GrowlerSuccess, '', 'Item removed from list'));
      } else {
        errorCallback?.(data.ErrorMessages);
        dispatch(setErrorDialogContent(errorTitle, data.ErrorMessages));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };
