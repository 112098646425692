import { Card, CardProps, Link, Stack, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { Fragment, useState } from 'react';
import { CfDropdown } from '../../../../../cf-ui/Dropdown/CfDropdown';
import { DeviceType, useBreakpoint } from '../../../../../hooks/useBreakpoint';

interface SideBarCardProps extends CardProps {
  title: string;
  titleSx?: SxProps<Theme>;
  children: React.ReactNode;
  actionPlacement?: SideBarActionPlacement;
  actionLabel?: string;
  actionSx?: SxProps<Theme>;
  onActionClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  actionDisabled?: boolean;
  canHide?: boolean;
  contentWhenHidden?: string | React.ReactNode;
  containerWidth?: boolean;
  titleTestId?: string;
  actionLabelTestId?: string;
  collapsedByDefault?: boolean;
}

export enum SideBarActionPlacement {
  unset = 0,
  content = 1,
  header = 2,
}

const CommunicationsEmailCard = (props: SideBarCardProps): JSX.Element => {
  const { deviceType } = useBreakpoint();
  const {
    title,
    titleSx,
    children,
    actionLabel,
    onActionClick,
    actionDisabled,
    canHide,
    contentWhenHidden,
    containerWidth,
    titleTestId,
    actionLabelTestId,
    collapsedByDefault,
  } = props;

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(!collapsedByDefault);

  const handleActionClick = (e?: React.MouseEvent<HTMLElement>) => {
    if (onActionClick && !actionDisabled) {
      onActionClick(e);
    }
  };

  const isActPlaceHeader = props.actionPlacement === SideBarActionPlacement.header;

  const additionalAction = (
    <Link
      sx={{
        width: 'fit-content',
        color: (theme: Theme) => (actionDisabled ? theme.palette.grey[600] : theme.palette.primary.main),
        alignSelf: 'self-end',
        mr: isActPlaceHeader ? 1 : 0,
        typography: { xs: 'mobileParagraphSmall', xl: 'paragraphSmallLink' },
      }}
      onClick={handleActionClick}
      onKeyDown={(event) => {
        if (additionalAction) event.stopPropagation();
        if (event.key === 'Enter') handleActionClick();
      }}
      tabIndex={0}
      data-testid={actionLabelTestId}
    >
      {actionLabel}
    </Link>
  );

  return (
    <Card
      sx={{
        borderRadius: 2,
        pl: 2,
        mb: { xl: 2 },
        ...props.sx,
      }}
    >
      {canHide && (
        <CfDropdown
          contentWhenHidden={contentWhenHidden}
          {...(isActPlaceHeader && {
            additionalAction: additionalAction,
            additionalActionSx: props.actionSx,
          })}
          caretClass='chevron'
          caretSize={deviceType === DeviceType.Desktop ? 'small' : 'medium'}
          caretSx={{ color: (theme) => ({ xs: theme.palette.coolGrey[600] }) }}
          headerBoxSx={{
            mb: isDropdownOpen ? 1 : 0,
            maxWidth: { xs: 295, lg: 344, xl: 242 },
            overflow: 'hidden',
          }}
          headerTestId={titleTestId}
          isCollapsedByDefault={props.collapsedByDefault}
          title={title}
          titleSx={{
            textTransform: 'none',
            typography: 'responsiveParagraphSmallBold',
            color: (theme: Theme) => theme.palette.coolGrey[600],
            ...titleSx,
          }}
          onOpen={() => setIsDropdownOpen(true)}
          onClose={() => setIsDropdownOpen(false)}
        >
          <Stack direction='column' width='100%'>
            {actionLabel && !isActPlaceHeader && (
              <Link
                data-testid={actionLabelTestId}
                variant='paragraphSmallLink'
                sx={titleSx}
                onClick={handleActionClick}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') handleActionClick();
                }}
                tabIndex={0}
              >
                {actionLabel}
              </Link>
            )}
            {children}
          </Stack>
        </CfDropdown>
      )}
      {!canHide && (
        <>
          <Stack
            direction='row'
            justifyContent={containerWidth ? 'flex-start' : 'space-between'}
            alignItems='center'
            sx={{ mb: 1 }}
          >
            <Typography data-testid={titleTestId} sx={titleSx}>
              {title}
            </Typography>
            <Link
              sx={{
                ml: 1,
                typography: { xs: 'mobileParagraphSmall', xl: 'paragraphSmallLink' },
                color: (theme: Theme) => theme.palette.primary.main,
              }}
              onClick={handleActionClick}
              onKeyDown={(event) => {
                if (event.key === 'Enter') handleActionClick();
              }}
              tabIndex={0}
              data-testid={actionLabelTestId}
            >
              {actionLabel}
            </Link>
          </Stack>
          <Fragment>{children}</Fragment>
        </>
      )}
    </Card>
  );
};

export default CommunicationsEmailCard;
