import { SxProps, Theme } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import {
  StyledHeaderText,
  StyledList,
  StyledListItem,
  StyledListItemActive,
  StyledListItemHeader,
  StyledPopper,
} from './CfSuggestionList.styles';
import { CfSuggestion, SuggestionItem } from './Suggestion/CfSuggestion';

export type CfSearchListSize = 'small' | 'medium';

/**
 * the properties which describe how the CfSuggestionList component should present and behave
 */
export interface CfSuggestionListProps {
  anchorEl: (EventTarget & HTMLDivElement) | null;
  searchVal: string;
  isOpen: boolean;
  items: SuggestionItem[];
  currentItem: number | null;
  focusElement?: 'item' | 'action';
  onItemClick: (item: SuggestionItem) => void;
  onItemRemove: (item: SuggestionItem) => void;
  suggestionListSx?: SxProps<Theme>;
}

/**
 *
 * @param anchorEl passed to the Popper component's anchorEl property
 * @param searchVal triggers the component to re-evaluate the label value displayed before the items
 * @param isOpen passed to the Popper component's open property
 * @param items each value is rendered as a CfSuggestion component contained within an \<li\> element
 * @param currentItem determines how to style a container of the item's CfSuggestion component
 * @param focusElement determines how to style a container of the item's CfSuggestion component
 * @param onItemClick passed to an item's CfSuggestion component's onClick property
 * @param onItemRemove executed when an item's CfSuggestion component's onRemoveClick event is triggered
 * @param suggestionListSx augmented and passed to the Popper component's sx property
 */
export const CfSuggestionList: FC<CfSuggestionListProps> = (props: CfSuggestionListProps) => {
  const [label, setLabel] = useState<string | undefined>(undefined);
  const handleRemoveClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, item: SuggestionItem) => {
    event?.stopPropagation();
    props.onItemRemove(item);
  };

  useEffect(() => {
    if (props.items.some((i) => i.type === 'history')) {
      setLabel('Recent');
    } else setLabel(undefined);
  }, [props.items, props.searchVal]);

  return (
    <div>
      <StyledPopper
        open={props.isOpen}
        anchorEl={props.anchorEl}
        placement='bottom-start'
        sx={{ width: props.anchorEl?.clientWidth || 0, ...props.suggestionListSx }}
        modifiers={[
          {
            name: 'flip',
            enabled: false,
            options: {
              altBoundary: false,
              rootBoundary: 'document',
            },
          },
        ]}
      >
        <StyledList>
          {label && (
            <StyledListItemHeader key={-1}>
              <StyledHeaderText>{label}</StyledHeaderText>
            </StyledListItemHeader>
          )}
          {props.items.map((item, index) => {
            if (index == props.currentItem) {
              if (props.focusElement === 'action') {
                return (
                  <StyledListItem key={index} onClick={() => props.onItemClick(item)}>
                    <CfSuggestion item={item} onRemoveClick={handleRemoveClick} isActionInFocus />
                  </StyledListItem>
                );
              }
              return (
                <StyledListItemActive key={index} onClick={() => props.onItemClick(item)}>
                  <CfSuggestion item={item} onRemoveClick={handleRemoveClick} />
                </StyledListItemActive>
              );
            } else {
              return (
                <StyledListItem key={index} onClick={() => props.onItemClick(item)}>
                  <CfSuggestion item={item} onRemoveClick={handleRemoveClick} />
                </StyledListItem>
              );
            }
          })}
        </StyledList>
      </StyledPopper>
    </div>
  );
};
