import { Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledNavigationLink = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    color: theme.palette.common.black,
    whiteSpace: 'nowrap',
    '&: hover': {
      backgroundColor: theme.palette.coolGrey[100],
      boxRadius: 8,
    },
  },
}));

export const StyledNavigationButton = styled(Button)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    '&:focus': {
      color: theme.palette.common.black,
    },
  },
}));
