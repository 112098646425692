import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { CustomerRecipientType } from '../../api/models/customer-groups.enums';
import {
  CreateCustomerGroupRequest,
  CustomerGroupBasic,
  CustomerGroupRecipient,
  CustomerGroupRecipientBasic,
  UpdateCustomerGroupRequest,
} from '../../api/models/customer-groups.models';
import { arraysEqual, getSortComparer } from '../../helpers/general/array';
import { normalizeKey } from '../../helpers/general/string';
import { RootState } from '../store';

const customerGroupCreateRecipientsAdapter = createEntityAdapter<CustomerGroupRecipient>({
  selectId: (recipient: CustomerGroupRecipient) => normalizeKey(recipient.CustomerGroupCustomerId),
  sortComparer: getSortComparer((recipient) => recipient.CustomerName),
});

interface ValidationMessages {
  customerGroupName: string[];
  disableButton: boolean;
}

interface CustomerGroupCreateState {
  customerGroup: CustomerGroupBasic | undefined;
  customerIds: EntityState<CustomerGroupRecipient>;
  customerGroupsUnassignedLocations: string[];
  customerIdsLoading: boolean;
  originalCustomerGroup:
    | {
        CustomerGroupName: string;
        CustomerIds: CustomerGroupRecipientBasic[];
      }
    | undefined;
  validationMessages: ValidationMessages;
  saveCustomerGroupRequest: CreateCustomerGroupRequest | UpdateCustomerGroupRequest | undefined;
  showNotification: boolean;
}

const initialState: CustomerGroupCreateState = {
  customerGroup: undefined,
  customerIds: customerGroupCreateRecipientsAdapter.getInitialState(),
  customerIdsLoading: false,
  customerGroupsUnassignedLocations: [],
  originalCustomerGroup: undefined,
  validationMessages: {
    customerGroupName: [],
    disableButton: true,
  },
  saveCustomerGroupRequest: undefined,
  showNotification: false,
};

export const customerGroupCreateSlice = createSlice({
  name: 'customerGroupCreate',
  initialState,
  reducers: {
    resetState: () => initialState,
    setCustomerGroupBasic: (state: CustomerGroupCreateState, action: PayloadAction<CustomerGroupBasic>) => {
      state.customerGroup = action.payload;
    },
    setCustomerGroupFull: (state: CustomerGroupCreateState, action: PayloadAction<CustomerGroupRecipient[]>) => {
      if (
        state.customerGroup &&
        state.customerGroup.CustomerGroupHeaderId.toLowerCase() !==
          action.payload[0].CustomerGroupHeaderId.toLowerCase()
      )
        return;
      customerGroupCreateRecipientsAdapter.setAll(
        state.customerIds,
        action.payload.map((r) => ({ ...r, CustomerGroupHeaderId: r.CustomerGroupHeaderId.toLowerCase() }))
      );
    },
    setCustomerGroupRecipients: (
      state: CustomerGroupCreateState,
      action: PayloadAction<{
        recipients: CustomerGroupRecipient[];
        setOnlyRecipientsOfType?: CustomerRecipientType;
      }>
    ) => {
      const { recipients } = action.payload;
      customerGroupCreateRecipientsAdapter.setAll(
        state.customerIds,
        selectAllRecipientsLocal(state).concat(recipients)
      );
    },
    removeCustomerGroupRecipientById: (state: CustomerGroupCreateState, action: PayloadAction<string>) => {
      customerGroupCreateRecipientsAdapter.removeOne(state.customerIds, action.payload);
    },
    resetCustomerGroupRecipients: (state: CustomerGroupCreateState) => {
      state.customerIds = initialState.customerIds;
      state.customerIdsLoading = initialState.customerIdsLoading;
      state.originalCustomerGroup = initialState.originalCustomerGroup;
    },
    setustomerIdsLoading: (state: CustomerGroupCreateState, action: PayloadAction<boolean>) => {
      state.customerIdsLoading = action.payload;
    },
    setValidationMessages: (state: CustomerGroupCreateState, action: PayloadAction<ValidationMessages>) => {
      state.validationMessages = action.payload;
    },
    resetValidtionMessages: (state: CustomerGroupCreateState) => {
      state.validationMessages = initialState.validationMessages;
    },
    setEnableButtonValidation: (state: CustomerGroupCreateState, action: PayloadAction<ValidationMessages>) => {
      state.validationMessages = action.payload;
    },
    setSaveCustomerGroupApiRequest: (
      state: CustomerGroupCreateState,
      action: PayloadAction<CreateCustomerGroupRequest | UpdateCustomerGroupRequest | undefined>
    ) => {
      state.saveCustomerGroupRequest = action.payload;
    },
    setShowNofication: (state: CustomerGroupCreateState, action: PayloadAction<boolean>) => {
      state.showNotification = action.payload;
    },
  },
});

export const {
  selectAll: selectAllCustomerGroupCreateRecipients,
  selectById: selectCustomerGroupCreateRecipientById,
  selectIds: selectAllCustomerGroupCreateRecipientIds,
} = customerGroupCreateRecipientsAdapter.getSelectors<EntityState<CustomerGroupRecipient>>(
  (recipients: EntityState<CustomerGroupRecipient>) => recipients
);

const getSelectRecipientsByCustomerRecipientTypeSelector = () =>
  createSelector(selectAllCustomerGroupCreateRecipients, (recipients: CustomerGroupRecipient[]) => {
    return recipients;
  });

export const getCustomerGroupCreateRecipientSelector = () => {
  return getSelectRecipientsByCustomerRecipientTypeSelector();
};

const { selectAll: selectAllRecipientsLocal } =
  customerGroupCreateRecipientsAdapter.getSelectors<CustomerGroupCreateState>(
    (state: CustomerGroupCreateState) => state.customerIds
  );

export const selectAllCreateRecipientsKeyedByObjectValues = createSelector(
  (state: RootState) => state.customerGroupCreate.customerIds,
  (recipients) =>
    Object.fromEntries(selectAllCustomerGroupCreateRecipients(recipients).map((r) => [r.CustomerGroupHeaderId, r]))
);

export const selectIsValidCustomerGroupCreate = createSelector(
  (state: RootState) => state.customerGroupCreate.validationMessages.customerGroupName,
  (state: RootState) => state.customerGroupCreate.customerIds.ids,
  (customerGroupName, recipientIds) => customerGroupName.length === 0 && recipientIds.length > 0
);

export const selectDoesCustomereGroupCreateHaveUnsavedChanges = createSelector(
  (state: RootState) => state.customerGroupCreate.customerIdsLoading,
  (state: RootState) => state.customerGroupCreate.customerGroup,
  (state: RootState) => state.customerGroupCreate.originalCustomerGroup,
  (state: RootState) => state.customerGroupCreate.customerIds,
  (customerIdsLoading, customerGroup, originalCustomerGroup, customerGroupRecipients) =>
    !(
      customerIdsLoading ||
      ((customerGroup?.CustomerGroupName ?? '') === (originalCustomerGroup?.CustomerGroupName ?? '') &&
        arraysEqual(
          selectAllCustomerGroupCreateRecipients(customerGroupRecipients)
            .map((r) => r.CustomerGroupHeaderId.toLowerCase())
            .sort(getSortComparer((id) => id)),
          originalCustomerGroup?.CustomerIds.map((r) => r.ObjectValue.toLowerCase()).sort(
            getSortComparer((id) => id)
          ) || []
        ))
    )
);
