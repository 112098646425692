import { Breadcrumbs, Icon, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CfBreadcrumbVariants } from './CfBreadcrumbs';

const breadcrumbFontStyles = {
  fontSize: '11px',
  lineHeight: '16px',
};

export const BreadcrumbTextStyles = {
  ...breadcrumbFontStyles,
  height: '16px',
};

export const StyledBreadcrumbSeparator = styled(Icon)(({ theme }) => ({
  alignSelf: 'center',
  [theme.breakpoints.up('xl')]: { ...BreadcrumbTextStyles },
}));

export const StyledBreadcrumbs = styled(Breadcrumbs)<{
  variant?: CfBreadcrumbVariants;
}>(({ theme, variant }) => ({
  ...(variant === 'secondary'
    ? {
        '& li': {
          minHeight: theme.spacing(3),
          marginBottom: theme.spacing(0.5),
          '& :first-of-type': {
            lineHeight: '24px',
          },
        },
        '& .MuiBreadcrumbs-li': {
          borderRadius: `${theme.shape.borderRadius}px`,
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          backgroundColor: theme.palette.coolGrey[200],
          '&:last-of-type': {
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: theme.palette.common.white,
          },
        },
      }
    : {
        backgroundColor: theme.palette.background.default,
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        [theme.breakpoints.up('xl')]: { ...breadcrumbFontStyles },
      }),
}));

export const BreadcrumbTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.responsiveParagraph,
  [theme.breakpoints.up('xl')]: { ...BreadcrumbTextStyles },
}));

export const BreadcrumbBoldTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.responsiveParagraphBold,
  [theme.breakpoints.up('xl')]: { ...BreadcrumbTextStyles },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  ...theme.typography.responsiveParagraph,
  color: theme.palette.common.black,
  cursor: 'pointer',
  textDecoration: 'none',

  [theme.breakpoints.up('xl')]: { ...BreadcrumbTextStyles },

  ':hover': {
    textDecoration: 'underline',
    textDecorationColor: theme.palette.primary.main,
  },
}));
