import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import {
  NotificationDisplayType,
  NotificationType,
  removeAppNotification,
  removeAppNotificationById,
  RootState,
  useAppDispatch,
  useAppSelector,
  useTimer,
} from 'common';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CfToast } from '../../../../../cf-ui/Toast/CfToast';
import { usePageNotification } from '../../../../../hooks/usePageNotification';

interface ToastNotificationProps {
  sx?: SxProps<Theme>;
}

const ToastNotification = ({ sx }: ToastNotificationProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const height = usePageNotification()?.height;
  const notification = useAppSelector((x: RootState) =>
    x.global.notifications.find((y) => y.NotificationDisplayType === NotificationDisplayType.Toast)
  );
  const [isOpen, setIsOpen] = useState(true);

  const getTimerTime = (): number => {
    if (notification?.AutoDismiss && notification?.AutoDismiss != 0) return notification.AutoDismiss;
    else if (
      notification?.NotificationType === NotificationType.Success ||
      notification?.NotificationType === NotificationType.Neutral ||
      notification?.NotificationType === NotificationType.Information
    )
      return 5;
    else return 0;
  };

  const animationTimer = useTimer(getTimerTime() - 0.2, () => {
    if (getTimerTime() !== 0) setIsOpen(false);
  });

  const timer = useTimer(getTimerTime(), () => {
    if (notification && getTimerTime() !== 0) dispatch(removeAppNotificationById(notification?.Id || '0'));
  });

  function removeNotification() {
    if (notification) dispatch(removeAppNotification(notification));
  }

  useEffect(() => {
    setIsOpen(true);
    animationTimer.restart(getTimerTime() - 0.2);
    timer.restart(getTimerTime());
  }, [notification]);

  useEffect(() => {
    removeNotification();
  }, [history.location.pathname]);

  return (
    <>
      {notification && (
        <CfToast
          message={notification.Message ? notification.Message : ''}
          onClose={removeNotification}
          isOpen={isOpen}
          variant={CfToastVariantLookup.NotificationTypes[notification.NotificationType].variant}
          icon={notification.Icon}
          sx={{ mt: height ? height / 8 + 2 : 0, ...sx }}
        />
      )}
    </>
  );
};

class CfToastVariantLookup {
  public static NotificationTypes: {
    [key: number]: { variant: 'info' | 'success' | 'warning' | 'error' | 'primary' };
  } = {
    [NotificationType.Success]: {
      variant: 'success',
    },
    [NotificationType.Information]: {
      variant: 'info',
    },
    [NotificationType.Warning]: {
      variant: 'warning',
    },
    [NotificationType.Error]: {
      variant: 'error',
    },
    [NotificationType.Neutral]: {
      variant: 'primary',
    },
  };
}

export default ToastNotification;
