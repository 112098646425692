import { SeverityLevel } from '@microsoft/applicationinsights-web';
import {
  Delivery,
  GetDeliveriesRequest,
  GetDeliveryDetailRequest,
  GetDeliveryRequest,
} from '../../api/models/delivery.models';
import DeliveryService from '../../api/services/delivery.service';
import { AppNotificationsCenter } from '../../helpers/lookups/AppNotificationsCenter';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { NotificationKeys } from '../../models/notifications.models';
import { updateSelectedCustomerByCustomerId, upsertAppNotification } from '../common';
import { globalSlice } from '../common/global.slice';
import { AppDispatch, AppThunk, RootState } from '../store';
import { deliveriesSlice } from './deliveries.slice';

const deliveryService = DeliveryService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Get and stores the result of the GetDeliveries API call
 *
 * @param request - The request to send the API
 * @returns NULL
 */
export const getDeliveries =
  (request: GetDeliveriesRequest): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (JSON.stringify(getState().deliveries.apiRequest) === JSON.stringify(request)) {
        if (getState().deliveries.deliveriesLoading) {
          dispatch(deliveriesSlice.actions.setDeliveriesLoading(false));
        }
        return;
      }
      dispatch(deliveriesSlice.actions.setApiRequest(request));

      if (!getState().deliveries.deliveriesLoading) {
        dispatch(deliveriesSlice.actions.setDeliveriesLoading(true));
      }
      const { data } = await deliveryService.getDeliveries(request);

      if (data.IsSuccess) {
        dispatch(deliveriesSlice.actions.setDeliveries({ request, result: data.ResultObject }));
      } else {
        dispatch(deliveriesSlice.actions.resetDeliveries());
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      dispatch(deliveriesSlice.actions.setApiRequest(undefined));
    } finally {
      dispatch(deliveriesSlice.actions.setDeliveriesLoading(false));
    }
  };

/**
 * Resets the deliveries and apiRequest values in the deliveries slice
 *
 * @returns NULL
 */
export const resetDeliveries = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(deliveriesSlice.actions.resetDeliveries());
};

/**
 * Calls the GetDelivery API call
 *
 * @param request - The request to send to the API
 * @param onSuccess - Method to call on success
 * @param onError - Method to call on error
 * @returns NULL
 */
export const getDelivery =
  (
    request: GetDeliveryRequest,
    onSuccess?: (delivery: Delivery) => void,
    onError?: (errors: string[]) => void
  ): AppThunk =>
  async () => {
    try {
      const { data } = await deliveryService.getDelivery(request);
      if (data.IsSuccess) {
        onSuccess?.(data.ResultObject);
      } else {
        onError?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls and stores the result of the GetDeliveryDetail API call
 *
 * @param request - The request to send to the API
 * @returns NULL
 */
export const getDeliveryDetail =
  (request: GetDeliveryDetailRequest): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (JSON.stringify({ ...request }) === JSON.stringify({ ...getState().deliveries.deliveryDetailRequest })) {
        return;
      }

      dispatch(deliveriesSlice.actions.resetDeliveryDetails());
      dispatch(deliveriesSlice.actions.setDeliveryDetailRequest(request));
      if (!getState().deliveries.deliveryDetailsLoading)
        dispatch(deliveriesSlice.actions.setDeliveryDetailsLoading(true));

      if (!getState().deliveries.selectedDelivery) {
        dispatch(
          getDelivery(
            {
              DeliveryBusinessUnitERPKey: request.DeliveryBusinessUnitERPKey,
              DeliveryKey: request.DeliveryKey,
              DeliveryOperationCompanyNumber: request.DeliveryOperationCompanyNumber,
            },
            (delivery: Delivery) => dispatch(setDelivery(delivery)),
            (errors: string[]) =>
              dispatch(
                upsertAppNotification(
                  AppNotificationsCenter.getNotificationByKey(NotificationKeys.CustomErrorInformation, errors.at(0)),
                  10
                )
              )
          )
        );
      }

      const { data } = await deliveryService.getDeliveryDetail(request);
      if (data.IsSuccess) {
        dispatch(deliveriesSlice.actions.setDeliveryDetails(data.ResultObject));
      } else {
        dispatch(
          upsertAppNotification(
            AppNotificationsCenter.getNotificationByKey(
              NotificationKeys.CustomErrorInformation,
              data.ErrorMessages.at(0)
            ),
            10
          )
        );
      }
      dispatch(deliveriesSlice.actions.setDeliveryDetailsLoading(false));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      dispatch(deliveriesSlice.actions.setDeliveryDetailsLoading(false));
    }
  };

/**
 * Calls the updateSelectedCustomerByCustomerId method from customer.thunks.ts and sets the selectedDelivery value in the deliveries slice
 *
 * @param delivery - Delivery to set selectedDelivery in the slice to
 * @param callBack - Method to call once this method completes
 * @returns NULL
 */
export const setDelivery =
  (delivery: Delivery, callBack?: () => void): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(updateSelectedCustomerByCustomerId(delivery.CustomerId));
    dispatch(deliveriesSlice.actions.setDelivery(delivery));
    callBack?.();
  };
