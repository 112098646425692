import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { History } from 'history';
import { FileResult } from '../../api';
import { MessageStatusType } from '../../api/models/message.enums';
import { GetMessagesRequest, MessagePreview } from '../../api/models/message.models';
import MessageService from '../../api/services/message.service';
import { getTimeZone } from '../../helpers';
import { getRouteNameByPathname } from '../../helpers/getRouteName';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { AppRoutes, RouteName } from '../../models';
import { globalSlice } from '../common';
import { AppDispatch, AppThunk, RootState } from '../store';
import { messageSlice, selectAllCheckedMessages, selectAllMessages } from './message.slice';

const messageService = MessageService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Resets the getMessagesRequest, messages and checkedMessageIds in the messages slice
 *
 * @returns NULL
 */
export const resetMessages = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(messageSlice.actions.resetMessages());
};

/**
 * Calls and stores the result from the GetMessages API call
 *
 * @param request - Stores message status type, query and page index for the API call
 * @returns NULL
 */
export const getMessages =
  (request: { statusType: MessageStatusType; query: string; pageIndex?: number }): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      // Data in store is for search with different parameters (excluding page number and skip)
      const prevRequest = getState().message.getMessagesRequest;

      let pageIndex = request.pageIndex ?? 0;
      if (prevRequest?.MessageStatusType !== request.statusType) pageIndex = 0;
      if (prevRequest && prevRequest.Query !== request.query) pageIndex = 0;

      const apiRequest: GetMessagesRequest = {
        MessageStatusType: request.statusType,
        Query: request.query,
        PageSize: 50,
        CurrentPage: pageIndex,
        TimeZone: getTimeZone(),
      };

      if (JSON.stringify({ ...apiRequest, CurrentPage: 0 }) !== JSON.stringify({ ...prevRequest, CurrentPage: 0 })) {
        dispatch(messageSlice.actions.resetMessages());
      }

      const priorRequest = getState().message.getMessagesRequest;
      if (priorRequest && pageIndex <= priorRequest?.CurrentPage) return;

      dispatch(
        messageSlice.actions.setPageLoading({
          pageIndex: pageIndex,
          pageSize: 3,
          isLoading: true,
        })
      );

      const { data } = await messageService.getMessages(apiRequest);
      if (data.IsSuccess) {
        dispatch(messageSlice.actions.setMessages({ data: data.ResultObject, request: apiRequest }));
      } else {
        dispatch(
          messageSlice.actions.setPageLoaded({
            pageIndex: pageIndex,
          })
        );
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      dispatch(messageSlice.actions.resetMessages());
    }
  };

/**
 * Sets the checkedMessageIds value in the message slice
 *
 * @param message - The message being checked/unchecked
 * @param isChecked - The boolean value indicating if the value should be added to or removed from checkedMessageIds
 * @returns NULL
 */
export const checkMessage =
  (message: MessagePreview, isChecked: boolean): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch) => {
    dispatch(messageSlice.actions.setCheckedMessages({ messages: [message], isChecked }));
  };

/**
 * Sets checkedMessageIds to either contain all messages or none of them
 *
 * @param isChecked - The boolean value indicating if the value should be added to or removed from checkedMessageIds
 * @returns NULL
 */
export const checkAllMessages =
  (isChecked: boolean): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(messageSlice.actions.setCheckedMessages({ messages: selectAllMessages(getState()), isChecked }));
  };

/**
 * Deletes the message(s) which are in checkedMessageIds
 *
 * @param history - Used so the user can be redirected to the proper page after deleting the message(s)
 * @param successCallback - Method to call on success
 * @returns NULL
 */
export const deleteCheckedMessages =
  (history: History, successCallback?: () => void): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const messages = selectAllCheckedMessages(getState()).map((m) => m.MessageId);
    await dispatch(deleteMessages(history, messages, successCallback));
  };

/**
 * Deletes the specified message(s)
 *
 * @param history - Used so the user can be redirected to the proper page after deleting the message(s)
 * @param messageIds - array of the ids of the messages being deleted
 * @param successCallback - Method to call on success
 * @returns NULL
 */
export const deleteMessages =
  (history: History, messageIds: string[], successCallback?: () => void): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(messageSlice.actions.setDeletingMessages(true));

      const { data } = await messageService.deleteMessages({ MessageIds: messageIds });
      if (data.IsSuccess) {
        dispatch(messageSlice.actions.removeMessages(messageIds));

        // Handle URL navigation if deleting an item in view message
        const message = getState().messageSelected.message;
        if (message && messageIds.find((id) => id === message.MessageId)) {
          const routeName = getRouteNameByPathname(history.location.pathname);
          switch (routeName) {
            case RouteName.InboxMessages:
              history.push(AppRoutes[RouteName.InboxMessages].Path);
              break;
            case RouteName.DraftMessages:
              history.push(AppRoutes[RouteName.DraftMessages].Path);
              break;
            case RouteName.SentMessages:
              history.push(AppRoutes[RouteName.SentMessages].Path);
              break;
          }
        }

        dispatch(getUnreadMessagesCount());
        successCallback?.();
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(messageSlice.actions.setDeletingMessages(false));
    }
  };

/**
 * Takes messages in checkedMessageIds and calls the toggleMessagesRead method
 *
 * @param successCallback - Method to call on success
 * @returns NULL
 */
export const toggleCheckedMessagesRead =
  (successCallback?: () => void): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const messages = selectAllCheckedMessages(getState());
    dispatch(toggleMessagesRead(messages, successCallback));
  };

/**
 * Flips the isRead value of the given messages
 *
 * @param messages - The messages which will have their isRead value flipped
 * @param successCallback - Method to call on success
 * @returns NULL
 */
export const toggleMessagesRead =
  (messages: MessagePreview[], successCallback?: () => void): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch) => {
    try {
      const messageIds = messages.map((m) => m.MessageId);
      const { data } = await messageService.updateMessageReadStatus({
        MessageIds: messageIds,
      });
      if (data.IsSuccess) {
        const wereAllRead = messages.filter((m) => m.IsRead).length === messageIds.length;
        dispatch(messageSlice.actions.setMessagePreviewsRead({ messageIds, isRead: !wereAllRead }));
        dispatch(getUnreadMessagesCount());
        successCallback?.();
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      console.error(error);
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Takes messages in checkedMessageIds and calls the printMessages method
 *
 * @param successCallback - Method to call on success
 * @param failureCallback - Method to call on failure
 * @returns NULL
 */
export const printCheckedMessages =
  (successCallback?: (data: FileResult | undefined) => void, failureCallback?: () => void): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const messageIds = selectAllCheckedMessages(getState()).map((m) => m.MessageId);
    dispatch(printMessages(messageIds, successCallback, failureCallback));
  };

/**
 * Prints the messages in checkedMessageIds
 *
 * @param messageIds - Ids of the messages to print
 * @param successCallback - Method to call on success
 * @param failureCallback - Method to call on failure
 * @returns NULL
 */
export const printMessages =
  (
    messageIds: string[],
    successCallback?: (data: FileResult | undefined) => void,
    failureCallback?: () => void
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(messageSlice.actions.setExportingMessages(true));

      const { data } = await messageService.getMessageExportPDFReport({
        MessageIds: messageIds,
        TimeZone: getTimeZone(),
      });
      if (data.IsSuccess) {
        successCallback?.(data.ResultObject);
      } else {
        failureCallback?.();
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      failureCallback?.();
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(messageSlice.actions.setExportingMessages(false));
    }
  };

/**
 * Calls and stores the result of the GetUnreadMessagesCount API call
 *
 * @param successCallback - Method to call on success
 * @returns NULL
 */
export const getUnreadMessagesCount =
  (successCallback?: (isAnyHighPriority: boolean) => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await messageService.getUnreadMessagesCount();
      if (data.IsSuccess) {
        dispatch(messageSlice.actions.setUnreadMessages(data.ResultObject));
        successCallback?.(data.ResultObject.IsAnyHighPriority);
      } else {
        dispatch(messageSlice.actions.setUnreadMessages(undefined));
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      dispatch(messageSlice.actions.setUnreadMessages(undefined));
    }
  };
