import { ServiceResult } from '../models/api-shared.models';
import { GetParEntrySearchRequest, GetParEntrySearchResultDetail } from '../models/par-entry-search.models';
import ApiService from './api-service';

class ParEntrySearchService extends ApiService {
  private static classInstance: ParEntrySearchService;

  private constructor() {
    super('ParEntrySearch');
  }

  public getParEntrySearch(request: GetParEntrySearchRequest): ServiceResult<GetParEntrySearchResultDetail> {
    return this.post(`${this.baseUrl}/ProductListParEntrySearch`, request);
  }

  public static getInstance(): ParEntrySearchService {
    if (!this.classInstance) {
      this.classInstance = new ParEntrySearchService();
    }
    return this.classInstance;
  }
}

export default ParEntrySearchService;
