import { Grid } from '@mui/material';
import { FC } from 'react';
import { NavMenuConfig, NavButtonOptions } from '../../NavigationProps';
import { NavigationMenu } from '../NavigationMenu/NavigationMenu';

interface NavigationBarProps {
  navigationConfig: NavMenuConfig[];
  onClickOption: ({ navigationAction, navigationRoute }: NavButtonOptions) => void;
}

export const NavigationBar: FC<NavigationBarProps> = (props: NavigationBarProps) => {
  return (
    <Grid container wrap='nowrap' pt={0.5}>
      {props.navigationConfig.map((config: NavMenuConfig, index: number) => (
        <Grid key={index} item sx={{ mr: 2 }}>
          <NavigationMenu config={config} onClickOption={props.onClickOption} />
        </Grid>
      ))}
    </Grid>
  );
};
