import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { History } from 'history';
import { toOrderEntryProductsFromSimilarCatalogProducts } from '../../api/mappers/order-mappers';
import { APIMessages, CatalogProduct } from '../../api/models/api-shared.models';
import { CustomerProductPriceResult } from '../../api/models/customer-product-price.models';
import {
  ProductSearchFilter,
  SearchCacheEntry,
  SearchSimilarProductsRequest,
  TypeAheadRequest,
  TypeAheadResponse,
} from '../../api/models/product-catalog.models';
import SimilarProductSearchService from '../../api/services/similar-product-search.service';
import TypeaheadService from '../../api/services/typeahead.service';
import { AnalyticsContext } from '../../helpers';
import { getFilteredProductPriceRequests, showRemoveProductQuantityButton } from '../../helpers/general/product';
import { getAnalyticsItemData } from '../../helpers/utilities/google-analytics/google-analytics.mappers';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { AppRoute, ProductData } from '../../models';
import { setErrorDialogContent } from '../common';
import { getCustomerProductPrices } from '../common/customer.thunks';
import { removeProductFromOrder } from '../orders/orders.thunks';
import { getProductListHeadersByProductKey } from '../product-list-management/product-list-management.thunks';
import {
  FindSimilarMode,
  isAdvanceFilterUnset,
  ProductSearchHistoryState,
  updateAdvanceFilterObject,
} from '../product-search-shared';
import { AppDispatch, AppThunk, RootState } from '../store';
import { productComparisonSlice } from './product-compare.slice';
import { resetProductComparisons } from './product-compare.thunks';
import {
  selectAllSimilarCatalogProducts,
  selectSimilarProductSearchCatalogProductsByPage,
  similarProductSearchSlice,
} from './similar-products-search.slice';

// hooks
const similarProductSearchSliceService = SimilarProductSearchService.getInstance();
const typeAheadService = TypeaheadService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Resets the products, productSearchResult and apiRequest similar-products-search slice to their initial values
 *
 * @returns NULL
 */
export const resetSimilarProductSearchResults = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(similarProductSearchSlice.actions.resetProductSearchResults());
};

/**
 * Sets the product prices for the products value in the similar product search slice
 *
 * @param priceResults - The prices that the product prices in the slice be set to
 * @returns NULL
 */
export const setSimilarProductSearchPrices =
  (priceResults: CustomerProductPriceResult[]): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(similarProductSearchSlice.actions.setProductPrices(priceResults));
    dispatch(productComparisonSlice.actions.setProductComparisonPrices(priceResults));
  };

/**
 * Calls and stores the results of the GetCustomerProductPrice API call
 *
 * @returns NULL
 */
export const getSimilarProductSearchPrices = (): AppThunk => (dispatch: AppDispatch, getState: () => RootState) => {
  const catalogProducts = selectAllSimilarCatalogProducts(getState().similarProductSearch);
  dispatch(
    getCustomerProductPrices(
      getFilteredProductPriceRequests(toOrderEntryProductsFromSimilarCatalogProducts(catalogProducts)),
      (priceResults: CustomerProductPriceResult[]) => dispatch(setSimilarProductSearchPrices(priceResults))
    )
  );
};

/**
 * Calls and stores the results of the GetCustomerProductPrice API call, but only for the products that are on the specified page
 *
 * @param pageIndex - The page who's products will have their price gotten from the API call
 * @returns NULL
 */
export const getSimilarProductSearchProductPricesByPage =
  (pageIndex: number): AppThunk =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const products = selectSimilarProductSearchCatalogProductsByPage(getState().similarProductSearch, pageIndex);
    dispatch(
      getCustomerProductPrices(
        getFilteredProductPriceRequests(toOrderEntryProductsFromSimilarCatalogProducts(products)),
        (priceResults: CustomerProductPriceResult[]) => dispatch(setSimilarProductSearchPrices(priceResults))
      )
    );
  };

/**
 * Updates the search filters with the provided filter information
 *
 * @param history - Fixes the URL to match the filter update
 * @param filters - The information to update the filters with
 * @returns NULL
 */
export const updateSimilarProductSearchFilters =
  (history: History<ProductSearchHistoryState>, ...filters: ProductSearchFilter[]): AppThunk =>
  () => {
    const advanceFilter = updateAdvanceFilterObject(history.location.state?.advanceFilter, filters);
    history.replace(history.location.pathname + history.location.search, {
      ...history.location.state,
      advanceFilter,
    });
  };

/**
 * Resets the products, productSearchResult, and apiRequest values in the slice to their inital values
 *
 * @param history - Fixes the URL to reflect the reset
 * @param parentRoute - The parent route to use in the URL reset
 * @returns NULL
 */
export const clearSimilarProductSearchFilters =
  (history: History<ProductSearchHistoryState>, parentRoute?: AppRoute): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(similarProductSearchSlice.actions.resetProductSearchResults());
    history.replace(history.location.pathname + history.location.search, {
      ...history.location.state,
      parentRoute: parentRoute ?? history.location.state.parentRoute,
      advanceFilter: undefined,
    });
  };

/**
 * Calls and stores the result of the SearchSimilarProducts API call
 *
 * @param request - Information needed to make a call to the API (prodcutKey, queryText, history, pageIndex and skip)
 * @param analyticsContext - The analytics context to use
 * @returns NULL
 */
export const searchSimilarProductCatalog =
  (
    request: {
      productKey?: string;
      queryText?: string;
      history?: History<ProductSearchHistoryState>;
      pageIndex?: number;
      skip?: number;
    },
    analyticsContext?: AnalyticsContext
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const history = request.history;
      const historyState = history?.location.state;

      const selectedCustomer = getState().customer.selectedCustomer;
      const activeOrderId = getState().orders.activeOrder?.OrderEntryHeaderId;
      const deliveryDate = getState().orders.activeOrder?.DeliveryDate;
      const advanceFilter = historyState?.advanceFilter ?? {
        IsCriticalItem: undefined,
        IsNewInStock: undefined,
        HasOrderedInLastNumberOfDays: undefined,
        HasPreviousPurchase: undefined,
        Badges: [],
        CategoryIds: [],
        Brand: undefined,
        Brands: [],
        PackSize: undefined,
        StorageTypes: [],
        DeliveryOptions: {},
        Nutritional: {},
      };

      const pageSize = 25;
      const pageIndex = request.pageIndex ?? 0;
      const skip =
        (request.pageIndex === undefined ? 0 : getState().similarProductSearch.productSearchResult?.Skip) ?? 0;

      const searchValue = request.productKey;
      const queryText = request.queryText;
      if (!selectedCustomer || !advanceFilter || !deliveryDate) return;
      const orderEntryHeaderId = activeOrderId || '00000000-0000-0000-0000-000000000000';
      const apiRequest: SearchSimilarProductsRequest = {
        BusinessUnitKey: selectedCustomer.BusinessUnitKey,
        OperationCompanyNumber: selectedCustomer.OperationCompanyNumber,
        CustomerId: selectedCustomer.CustomerId,
        DeliveryDate: deliveryDate,
        ProductKey: searchValue,
        QueryText: queryText,
        //CurrentPageNumber: pageIndex,
        PageSize: pageSize,
        Skip: skip,
        OrderEntryHeaderId: orderEntryHeaderId,
        AdvanceFilter: advanceFilter,
      };

      if (!getState().similarProductSearch.apiRequest) dispatch(resetProductComparisons());

      // Data in store is for search with different parameters (excluding page number and skip)
      if (
        JSON.stringify({ ...apiRequest, CurrentPageNumber: 0, Skip: 0 }) !==
        JSON.stringify({ ...getState().similarProductSearch.apiRequest, CurrentPageNumber: 0, Skip: 0 })
      ) {
        dispatch(resetProductComparisons());
        dispatch(similarProductSearchSlice.actions.resetProductSearchResults());
      }

      const priorRequest = getState().similarProductSearch.apiRequest;
      if (priorRequest && skip <= priorRequest?.Skip) return;

      dispatch(similarProductSearchSlice.actions.setApiRequest(apiRequest));

      dispatch(
        similarProductSearchSlice.actions.setPageLoading({
          pageIndex: pageIndex,
          pageSize: pageSize,
          isLoading: true,
        })
      );

      if (pageIndex > 0)
        analyticsContext?.analytics?.event?.(analyticsContext.listId, 'load_more', orderEntryHeaderId, pageIndex);

      const { data } = await similarProductSearchSliceService.getSimilarProducts(apiRequest);

      // The following was added because the similar search API comes back with
      // current page no set to 0 unlike the other search API responses
      // this should probably be changed on the API side.
      // The API is also coming back with 'HasLoadMore' always set to false.
      // If you set it to true on the front end and make another request with correct
      // skip and productKet parameters, it always comes back with the same product
      // no matter what.
      let pageno = getState().similarProductSearch.productSearchResult?.CurrentPageNumber;
      pageno === undefined ? (pageno = 0) : (pageno = pageno + 1);
      data.ResultObject.CurrentPageNumber = pageno;

      if (data.IsSuccess) {
        const resultData = data.ResultObject;

        // Handle active product appearing at the top of the grid (regardless of searching)

        const activeProductIndex = historyState?.activeProductIndex;
        if (historyState?.findSimilarProducts && activeProductIndex !== undefined) {
          const activeProduct = historyState?.findSimilarProducts?.[activeProductIndex];
          const apiActiveProduct = resultData.CatalogProducts.find(
            (p) => p.Product.ProductKey === activeProduct.product.ProductKey
          );
          const product = apiActiveProduct?.Product ?? activeProduct.product;

          resultData.CatalogProducts = [
            {
              IsOriginal: true,
              IsRecommended: false,
              Product: product as CatalogProduct,
            },
            ...resultData.CatalogProducts,
          ];

          if (!apiActiveProduct) {
            resultData.TotalCount++;
          } else {
            // Ensure that the history state is updated with the most recent product data from the api (quantity)
            const mutableFindSimilarProducts = [...historyState.findSimilarProducts];
            mutableFindSimilarProducts[activeProductIndex] = { ...activeProduct, product };
            history?.replace(history.location.pathname + history.location.search, {
              ...history.location.state,
              findSimilarProducts: mutableFindSimilarProducts,
            });
          }
        }

        if (isAdvanceFilterUnset(history?.location.state?.advanceFilter)) {
          history?.replace(history.location.pathname + history.location.search, {
            ...history.location.state,
            cachedFacets: data.ResultObject.Facets,
          });
        }
        dispatch(
          similarProductSearchSlice.actions.setProductSearchResults({
            request: apiRequest,
            result: resultData,
          })
        );

        analyticsContext?.analytics?.viewItemList?.(
          data.ResultObject.CatalogProducts.flatMap((p, index) =>
            p.Product.UnitOfMeasureOrderQuantities.map((uom) =>
              getAnalyticsItemData(
                p.Product.ProductKey ?? '',
                uom.UnitOfMeasure.toString(),
                uom.Price ?? 0,
                uom.Quantity,
                index,
                uom,
                p.Product,
                analyticsContext.listId,
                analyticsContext.listName
              )
            )
          ),
          analyticsContext.listId,
          analyticsContext.listName
        );

        if (!data.ResultObject.HasLoadMore)
          analyticsContext?.analytics?.event?.(analyticsContext.listId, 'end_of_list', analyticsContext.listName);
      } else {
        dispatch(similarProductSearchSlice.actions.setApiRequest(undefined));
        dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
        dispatch(
          similarProductSearchSlice.actions.setPageLoaded({
            pageIndex: pageIndex,
            catalogProducts: [],
          })
        );
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      dispatch(similarProductSearchSlice.actions.setApiRequest(undefined));
    }
  };

/**
 * Calls and stores the result of the GetTypeAhead API call
 *
 * @param searchValue - The value being searched for
 * @param isCalledFromDeleteFlow - Boolean indicating if the user is deleting from the input
 * @returns NULL
 */
export const getSimilarTypeAheadSearchResults =
  (searchValue: string, isCalledFromDeleteFlow = false): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const request: TypeAheadRequest = { SearchValue: searchValue };
    // check the cache to see if there is a matching entry
    const cache = getState().similarProductSearch.searchCache;
    let isCacheHit = false;
    if (!isCalledFromDeleteFlow && cache) {
      const cacheHitResult = cache.find((c) => c.key.toLowerCase() === request.SearchValue.toLowerCase());
      if (cacheHitResult) {
        isCacheHit = true;
        dispatch(similarProductSearchSlice.actions.setTypeAheadResults(cacheHitResult.value));
      }
    }
    if (!isCacheHit || isCalledFromDeleteFlow) {
      // cache miss. hit the backing store via API or cascaded to the delete history. Hence a fresh fetch is needed to update the cache entry
      try {
        const selectedCustomer = getState().customer.selectedCustomer;
        request.BusinessUnitKey = selectedCustomer?.BusinessUnitKey;
        request.CustomerId = selectedCustomer?.CustomerId;
        const { data: result } = await typeAheadService.getTypeAheadSearchResults(request);
        const typeAheadAPIMessages: APIMessages = {
          IsError: false,
        };
        if (result.IsSuccess) {
          const typeAheadResult: TypeAheadResponse = result.ResultObject;
          if (result.InformationMessages && result.InformationMessages.length > 0) {
            typeAheadAPIMessages.InformationMessages = result.InformationMessages;
            dispatch(similarProductSearchSlice.actions.setTypeAheadAPIMessages(typeAheadAPIMessages));
          }
          if (request.SearchValue.toLowerCase() !== '') {
            dispatch(
              similarProductSearchSlice.actions.setSearchCache(
                updateCache(cache, isCalledFromDeleteFlow, request.SearchValue, result.ResultObject)
              )
            );
          }

          dispatch(similarProductSearchSlice.actions.setTypeAheadResults(typeAheadResult));
        } else {
          if (result.ErrorMessages && result.ErrorMessages.length > 0) {
            typeAheadAPIMessages.ErrorMessages = result.ErrorMessages;
            typeAheadAPIMessages.IsError = true;
            dispatch(similarProductSearchSlice.actions.setTypeAheadAPIMessages(typeAheadAPIMessages));
          }
        }
      } catch (error) {
        appInsightsLogger.trackException({
          exception: error,
          severityLevel: SeverityLevel.Error,
        });
      }
    }
  };

/**
 * Calls the searchSimilarProductCatalog method
 *
 * @param queryText - The query to the SearchSimilarProducts API call
 * @param history - The history to be used to update the URL after the API call
 * @param productKey - The product key to be used in the SearchSimilarProducts API call
 * @returns NULL
 */
export const filterSimilarProductResult =
  (queryText: string, history: History<ProductSearchHistoryState>, productKey: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (queryText === '') {
      dispatch(searchSimilarProductCatalog({ productKey, history }));
    } else {
      dispatch(searchSimilarProductCatalog({ productKey, history, queryText }));
    }
  };

/**
 * Resets the replacementProducts value in the slice to its initial value
 *
 * @returns NULL
 */
export const resetReplacementProducts = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(similarProductSearchSlice.actions.resetDiscontinuedReplacementProducts());
};

/**
 * Calls the removeProductFromOrder method from orders.thunks.ts if:
 *      - fromProduct has a value,
 *      - findSimilarMode contains references to order confirmation or order entry
 *      - There is some value in the replacementProducts.ids value from the similar products search slice
 *      - showRemoveProductQuantityButton returns true
 *
 * @returns NULL
 */
export const exitSimiliarSearch =
  (findSimilarMode: FindSimilarMode, fromProduct?: ProductData): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    if (
      fromProduct &&
      ['order-confirmation', 'order-entry'].includes(findSimilarMode) &&
      getState().similarProductSearch.replacementProducts.ids.length > 0 &&
      showRemoveProductQuantityButton(fromProduct)
    )
      dispatch(removeProductFromOrder(fromProduct));
  };

/**
 * Updates the cache
 *
 * @param cache - cache to be updated
 * @param isCalledFromDeleteFlow - Boolean checking if the user is deleting from an input
 * @param searchValue - Value being searched for
 * @param resultObject - the result of the GetTypeAhead API call
 * @returns NULL
 */
const updateCache = (
  cache: SearchCacheEntry[] | undefined,
  isCalledFromDeleteFlow: boolean,
  searchValue: string,
  resultObject: TypeAheadResponse
): SearchCacheEntry[] => {
  // update cache with new data
  let cacheCopy: SearchCacheEntry[] = [];
  if (cache) {
    if (isCalledFromDeleteFlow) {
      cacheCopy = cache.filter((f) => f.key.toLowerCase() !== searchValue);
    } else {
      cacheCopy = cache.map((c) => {
        return c;
      });
    }
  }
  const searcCacheEntry: SearchCacheEntry = {
    key: searchValue,
    value: resultObject,
  };
  cacheCopy.push(searcCacheEntry);
  return cacheCopy;
};

/**
 * Calls the DeleteSearchHistory API call
 *
 * @param searchValue - The value being search for
 * @param userCustomerSearchHistoryId - The id of the search history to be deleted
 * @returns NULL
 */
export const deleteSimilarSearchHistory =
  (searchValue: string, userCustomerSearchHistoryId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data: result } = await typeAheadService.deleteSearchHistory({
        UserCustomerSearchHistoryId: userCustomerSearchHistoryId,
      });
      if (result.IsSuccess) {
        dispatch(getSimilarTypeAheadSearchResults(searchValue));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/* Replace Discontinued Modal */

/**
 * Verifies that the given product is on a custom list based on the GetProductListHeadersByProductKey API call
 *
 * @param originalProduct - The product being verified
 * @returns NULL
 */
export const verifyProductIsOnCustomList =
  (originalProduct: ProductData): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (originalProduct && originalProduct.ProductKey) {
      dispatch(similarProductSearchSlice.actions.setIsReplaceDiscontinuedModalLoading(true));
      dispatch(
        getProductListHeadersByProductKey(originalProduct.ProductKey, (product) => {
          if (product.length > 0) {
            dispatch(similarProductSearchSlice.actions.setReplaceDiscontinuedProductIsOnCustomList(true));
          } else {
            dispatch(similarProductSearchSlice.actions.setReplaceDiscontinuedProductIsOnCustomList(false));
          }
          dispatch(similarProductSearchSlice.actions.setIsReplaceDiscontinuedModalLoading(false));
        })
      );
    }
  };
