import { InvoiceReportResultRow, InvoiceReportSearchResultData } from '../../api';
import { InvoiceReportResultRowCustom, InvoiceReportSearchResultCustom } from '../../models/insights.models';
import { InsightsReportState } from '../../store/finances/insights-report.slice';
import { toLocalISOString } from './date';

const getInvoiceResultRowId = (parentId: string, sequence: number) => `${parentId}-${sequence}`;
const getNormalizedInvoiceResultRows = (
  row: InvoiceReportResultRow,
  sequence: number,
  level: number,
  parentRowId?: string
): InvoiceReportResultRowCustom[] => {
  const parentId = parentRowId ?? `${level}`;
  const id = getInvoiceResultRowId(parentId, sequence);

  const childRows: InvoiceReportResultRowCustom[] = [];
  if (row.HasChildRows && row.ChildRows) {
    row.ChildRows.forEach((childRow, index) => {
      childRows.push(...getNormalizedInvoiceResultRows(childRow, index, level + 1, id));
    });
  }

  const currentRow = {
    CriteriaType: row.CriteriaType,
    CriteriaValue: row.CriteriaValue,
    CriteriaDisplayOne: row.CriteriaDisplayOne,
    CriteriaDisplayTwo: row.CriteriaDisplayTwo,
    CriteriaDisplayThree: row.CriteriaDisplayThree,
    CriteriaDisplayFour: row.CriteriaDisplayFour,
    CriteriaDisplayFive: row.CriteriaDisplayFive,
    CriteriaDisplaySix: row.CriteriaDisplaySix,
    CriteriaDisplaySeven: row.CriteriaDisplaySeven,
    Cells: row.Cells ?? [],
    HasChildRows: row.HasChildRows,
    DrilldownDisplay: row.DrilldownDisplay,
    DrilldownParameters: row.DrilldownParameters,

    Id: id,
    Sequence: sequence,
    Level: level,
    IsHidden: false,
    IsCollapsed: false,
    ChildRowIds: row.ChildRows?.map?.((cr, index) => getInvoiceResultRowId(id, index)) ?? [],
  } as InvoiceReportResultRowCustom;

  return [currentRow, ...childRows];
};

export const getNormalizedInvoiceReportSearchResult = (
  data: InvoiceReportSearchResultData
): { result: InvoiceReportSearchResultCustom; rows: InvoiceReportResultRowCustom[] } => {
  const rows: InvoiceReportResultRowCustom[] = [];
  data.Rows?.forEach?.((rootRow, index) => rows.push(...getNormalizedInvoiceResultRows(rootRow, index, 0)));
  return {
    result: {
      SearchHeaderId: data.SearchHeaderId,
      SearchName: getNormalizedSearchName(data.SearchName, data.IsFavorite),
      SearchDirectoryId: data.SearchDirectoryId,
      ColumnTitles: data.ColumnTitles ?? [],
      BreadcrumbElements: data.BreadcrumbElements ?? [],
      SearchKPITypes: data.SearchKPITypes ?? [],
      DrilldownOptions: data.DrilldownOptions ?? [],
      SortByKpiType: data.SortByKpiType,
      SortByOrderType: data.SortByOrderType,

      StartDate: data.StartDate,
      EndDate: data.EndDate,
      CompareStartDate: data.CompareStartDate,
      CompareEndDate: data.CompareEndDate,
      SearchType: data.SearchType,
      TrendType: data.TrendType,
      TemplateType: data.TemplateType,
      IsFavorite: data.IsFavorite,

      FilteredCount: data.FilteredCount,
      TotalCount: data.TotalCount,
      GrandTotals: data.GrandTotals,
    } as InvoiceReportSearchResultCustom,
    rows: rows,
  };
};

export const getNormalizedSearchName = (searchName: string | undefined, isFavorite: boolean): string | undefined => {
  if (!isFavorite && searchName) {
    const date = Date.parse(searchName);
    if (!isNaN(date)) {
      const utcDate = new Date(date);
      return toLocalISOString(utcDate);
    }
  }
  return searchName;
};

export const setInvoiceReportResultRowIsHidden = (
  state: InsightsReportState,
  id: string,
  isHidden: boolean,
  isCollapsed?: boolean
): void => {
  const rootRow = state.searchResultRows.entities[id];
  if (!rootRow) return;

  if (isCollapsed !== undefined) rootRow.IsCollapsed = isCollapsed;
  rootRow.IsHidden = isHidden;

  const childIsHidden = rootRow.IsHidden || rootRow.IsCollapsed;

  rootRow.ChildRowIds.forEach((id) => setInvoiceReportResultRowIsHidden(state, id, childIsHidden, isCollapsed));
};

export const setInvoiceReportResultRowIsCollapsed = (
  state: InsightsReportState,
  id: string,
  isCollapsed: boolean,
  collapseChildren?: boolean
): void => {
  const rootRow = state.searchResultRows.entities[id];
  if (!rootRow) return;

  rootRow.IsCollapsed = isCollapsed;

  const childIsHidden = isCollapsed;
  const childIsCollapsed = collapseChildren ? isCollapsed : undefined;

  rootRow.ChildRowIds.forEach((id) => setInvoiceReportResultRowIsHidden(state, id, childIsHidden, childIsCollapsed));
};
