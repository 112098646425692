import { AppRoutes, RouteName } from '../models/routing.models';

export const getRouteName = (): RouteName | undefined => getRouteNameByPathname(location.pathname);

export const getRouteNameByPathname = (pathname: string): RouteName | undefined => {
  if (pathname === '/') {
    return RouteName.Home;
  }
  let result: RouteName | undefined = undefined;
  for (const name in RouteName) {
    if (name === RouteName.Home) continue;
    const value = AppRoutes[name as RouteName];
    if (value && pathname.toLocaleLowerCase().startsWith(value.Path.toLocaleLowerCase())) {
      result = name as RouteName;
    }
  }
  return result;
};

// eslint-disable-next-line @typescript-eslint/ban-types
interface match<Params extends { [K in keyof Params]?: string } = {}> {
  params: Params;
  isExact: boolean;
  path: string;
  url: string;
}

export const getRouteNameByMatch = (match: match): RouteName | undefined => {
  let result: RouteName | undefined = undefined;
  for (const name in RouteName) {
    const value = AppRoutes[name as RouteName];
    if (value && match.path === value.Path) {
      result = name as RouteName;
    }
  }
  return result;
};
