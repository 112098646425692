import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
  ApplicationInsights,
  ICustomProperties,
  IMetricTelemetry,
  IPageViewPerformanceTelemetry,
  IPageViewTelemetry,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { getBuildInfo } from '../helpers';

class AppInsightsLogger {
  private static logger: AppInsightsLogger;
  private appInsights: ApplicationInsights;
  public reactPlugin = new ReactPlugin();

  constructor() {
    const isTestEnvironment = process.env.NODE_ENV === 'test';
    const instrumentationKey = !isTestEnvironment
      ? process.env.REACT_APP_INSTRUMENTATION_KEY
      : '00000000-0000-0000-0000-000000000000';
    const browserHistory = !isTestEnvironment ? createBrowserHistory({ basename: '' }) : createMemoryHistory();

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        extensions: [this.reactPlugin],
        extensionConfig: {
          [this.reactPlugin.identifier]: { history: browserHistory },
        },
      },
    });

    this.appInsights.loadAppInsights();
  }

  public trackMetric(metric: IMetricTelemetry, customProperties?: ICustomProperties): void {
    this.appInsights.trackPageViewPerformance;
    return this.appInsights.trackMetric(metric, customProperties);
  }

  public trackEvent(eventName: string, customProperties?: ICustomProperties): void {
    return this.appInsights.trackEvent({ name: eventName }, customProperties);
  }

  public trackTrace(message: string, severityLevel?: SeverityLevel, customProperties?: ICustomProperties): void {
    return this.appInsights.trackTrace({ message: message, severityLevel: severityLevel }, customProperties);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public trackException(exception: any, severityLevel?: SeverityLevel): void {
    return this.appInsights.trackException({
      exception: exception,
      severityLevel: severityLevel,
      // getBuildInfo errors out when run by Jest
      ...(process.env.JEST_WORKER_ID ? {} : { properties: getBuildInfo() }),
    });
  }

  public trackPageView(pageView?: IPageViewTelemetry): void {
    this.appInsights.trackPageView(pageView);
  }

  public trackPageViewPerformance(pageViewPerformance: IPageViewPerformanceTelemetry): void {
    this.appInsights.trackPageViewPerformance(pageViewPerformance);
  }

  public static getInstance(): AppInsightsLogger {
    if (!this.logger) this.logger = new AppInsightsLogger();
    return this.logger;
  }
}

export function useAppInsightsLogger(): AppInsightsLogger {
  return AppInsightsLogger.getInstance();
}
