import { Button } from '@mui/material';
import { FC } from 'react';
import { CartIcon } from '../common/CartIcon';

export interface CartButtonProps {
  totalQuantity?: number;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  testId?: string;
  onClick?: () => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>;
}

export const CartButton: FC<CartButtonProps> = (props: CartButtonProps) => {
  const { totalQuantity, onClick, onKeyDown } = props;

  return (
    <Button
      ref={props.buttonRef}
      data-testid={props.testId}
      variant='text'
      disableElevation={true}
      sx={{ color: 'black', minWidth: { xs: 23, lg: 30 } }}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      <CartIcon badgeContent={totalQuantity ?? 0} />
    </Button>
  );
};
