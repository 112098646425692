import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppNotification, NotificationKeys } from '../../models/notifications.models';

// State
interface GlobalState {
  networkError: boolean;
  notifications: AppNotification[];
  errorDialogTitle: string;
  errorDialogMessages: string[];
  errorPageRefresh: boolean;
  informationDialogTitle: string;
  informationDialogMessages: string[];
}

const initialState: GlobalState = {
  networkError: false,
  notifications: [],
  errorDialogTitle: '',
  errorDialogMessages: [],
  errorPageRefresh: false,
  informationDialogTitle: '',
  informationDialogMessages: [],
};

// Reducers
export const globalSlice = createSlice({
  name: 'global',
  initialState: initialState,
  reducers: {
    setNetworkError: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.networkError = action.payload;
    },
    addAppNotification: (state: GlobalState, action: PayloadAction<AppNotification>) => {
      state.notifications.push(action.payload);
    },
    removeAppNotification: (state: GlobalState, action: PayloadAction<AppNotification>) => {
      state.notifications = state.notifications.filter((alert) => alert.Id !== action.payload.Id);
    },
    removeAppNotificationByKey: (state: GlobalState, action: PayloadAction<NotificationKeys>) => {
      state.notifications = state.notifications.filter((alert) => alert.Key !== action.payload);
    },
    removeAppNotificationById: (state: GlobalState, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter((alert) => alert.Id !== action.payload);
    },
    upsertAppNotification: (state: GlobalState, action: PayloadAction<AppNotification>) => {
      const index = state.notifications.findIndex((alert) => alert.Key === action.payload.Key);
      index === -1 ? state.notifications.push(action.payload) : (state.notifications[index] = action.payload);
    },
    upsertAppNotificationByKey: (state: GlobalState, action: PayloadAction<AppNotification>) => {
      const index = state.notifications.findIndex((alert) => alert.Key === action.payload.Key);

      index === -1 ? state.notifications.push(action.payload) : (state.notifications[index] = action.payload);
    },
    upsertAppNotificationByKeyAndId: (state: GlobalState, action: PayloadAction<AppNotification>) => {
      const index = state.notifications.findIndex(
        (alert) => alert.Key === action.payload.Key && alert.Id === action.payload.Id
      );

      index === -1 ? state.notifications.push(action.payload) : (state.notifications[index] = action.payload);
    },
    setErrorDialogContent: (
      state: GlobalState,
      action: PayloadAction<{ title?: string; messages: string[]; pageRefresh?: boolean }>
    ) => {
      state.errorDialogTitle = action.payload.title || 'Error occurred';
      state.errorDialogMessages = action.payload.messages;
      state.errorPageRefresh = action.payload.pageRefresh || false;
    },
    resetErrorDialogContent: (state: GlobalState) => {
      state.errorDialogTitle = '';
      state.errorDialogMessages = [];
    },
    setInformationDialogContent: (state: GlobalState, action: PayloadAction<{ title: string; messages: string[] }>) => {
      state.informationDialogTitle = action.payload.title;
      state.informationDialogMessages = action.payload.messages;
    },
    resetInformationDialogContent: (state: GlobalState) => {
      state.informationDialogTitle = '';
      state.informationDialogMessages = [];
    },
  },
});
