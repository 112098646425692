import { ServiceResult } from '../models/api-shared.models';
import {
  GetOrderCartRequest,
  GetOrderHeadersRequest,
  GetOrderRequest,
  GetOrderResultData,
  OrderCart,
  OrderHeader,
} from '../models/order.models';
import ApiService from './api-service';

class OrderService extends ApiService {
  private static classInstance: OrderService;

  private constructor() {
    super('Order');
  }

  public getOrder(request: GetOrderRequest): ServiceResult<GetOrderResultData> {
    return this.get(`${this.baseUrl}/GetOrder`, request);
  }

  public getOrderHeaders(request?: GetOrderHeadersRequest): ServiceResult<OrderHeader[]> {
    return this.get(`${this.persistUrl}/GetOrderHeaders`, request);
  }

  public getOrderCart(request: GetOrderCartRequest): ServiceResult<OrderCart> {
    return this.get(`${this.baseUrl}/GetOrderCart`, request);
  }

  public static getInstance(): OrderService {
    if (!this.classInstance) {
      this.classInstance = new OrderService();
    }
    return this.classInstance;
  }
}

export default OrderService;
