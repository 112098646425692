import { Box, Typography } from '@mui/material';
import { NotificationKeys } from 'common';
import { DeviceType, useBreakpoint } from '../../../../../hooks/useBreakpoint';
import ComponentNotification from '../../../../site/components/notifications/ComponentNotifications/ComponentNotification';
import { EditableHeaderName } from './EditableHeaderName';
import { OrderSummaryValidation } from './OrderSummaryValidation/OrderSummaryValidation';

interface OrderSummaryHeaderProps {
  title: string;
  inputAltTestId?: string;
  testId?: string;
  isEditable?: boolean;
}

export const OrderSummaryHeader = (props: OrderSummaryHeaderProps): JSX.Element => {
  // hooks
  const { deviceType } = useBreakpoint();
  const { title, inputAltTestId, testId, isEditable } = props;

  return (
    <>
      <Box sx={{ mb: 2 }}>
        {deviceType === DeviceType.Tablet ? (
          <>
            <Typography variant='h3' data-testid={`${testId}-text`}>
              {title}
            </Typography>
          </>
        ) : (
          <EditableHeaderName size='small' inputAltTestId={`${inputAltTestId}`} variant='h3' isEditable={isEditable} />
        )}
      </Box>
      <ComponentNotification
        notificationKeys={[NotificationKeys.PORequired, NotificationKeys.InvalidDeliveryDate]}
        messageComponent={<OrderSummaryValidation />}
      />
    </>
  );
};
