import { ButtonPropsSizeOverrides, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

import { OverridableStringUnion } from '@mui/types';
import { AppRoutes, RootState, RouteName, selectUserPermissionSetting, toCurrencyString, useAppSelector } from 'common';
import { OrderEntryType } from 'common/src/models/order.enums';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { CfContainedButton } from '../../../../../cf-ui/buttons/CfContainedButton';

interface OrderSummaryReviewButtonProps {
  onNavigateToOrderReview?: () => void;
  showPrice?: boolean;
  size?: OverridableStringUnion<'small' | 'medium' | 'large', ButtonPropsSizeOverrides>;
  sx?: SxProps<Theme>;
}

export const OrderSummaryReviewButton = (props: OrderSummaryReviewButtonProps): JSX.Element => {
  const history = useHistory();

  const orderEntryHeader = useAppSelector((x: RootState) => x.orders.order);
  const activeOrder = useAppSelector((s: RootState) => s.orders.activeOrder);
  const canViewPrice = useAppSelector((s: RootState) => selectUserPermissionSetting(s).CanViewPrices);
  const orderDetailsUpdating = useAppSelector((s: RootState) => s.orders.orderDetailsUpdating);
  const isFutureSubmission = useAppSelector((s) => s.orders.activeOrderIsFutureSubmission);

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const canOrderBeReviewedRef = useRef(false);
  canOrderBeReviewedRef.current =
    isFutureSubmission ||
    !(
      !activeOrder ||
      (orderEntryHeader?.OrderType !== OrderEntryType.Edit && activeOrder?.TotalQuantity === 0) ||
      !orderEntryHeader?.OrderEntryHeaderName
    );

  const handleClickProceedToOrderReview = () => {
    if (orderEntryHeader) {
      const action = () =>
        history.push(
          `${AppRoutes[RouteName.OrderReview].Path}/${orderEntryHeader.CustomerId}/${
            orderEntryHeader.OrderEntryHeaderId
          }`
        );

      if (orderDetailsUpdating && orderEntryHeader.TotalLines < 2) {
        if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(() => {
          if (canOrderBeReviewedRef.current) {
            action();
          }
        }, 1000);
      } else {
        action();
      }
      props.onNavigateToOrderReview?.();
    }
  };

  return (
    <CfContainedButton
      onClick={handleClickProceedToOrderReview}
      color='primary'
      isFullWidth
      data-testid='summary-review-order-button'
      isDisabled={!canOrderBeReviewedRef.current}
      size={props.size}
      sx={props.sx}
    >
      {orderEntryHeader?.OrderType === OrderEntryType.Edit ? 'Review updated order' : 'Review order'}
      {props.showPrice &&
        orderEntryHeader?.OrderType !== OrderEntryType.Edit &&
        canViewPrice &&
        `: ${toCurrencyString(orderEntryHeader?.TotalOrderPrice, 2)}`}
    </CfContainedButton>
  );
};
