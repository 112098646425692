import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { InvoiceReportSearchResultData } from '../../api';
import {
  getNormalizedInvoiceReportSearchResult,
  setInvoiceReportResultRowIsCollapsed,
} from '../../helpers/general/invoice-report';
import { InvoiceReportResultRowCustom, InvoiceReportSearchResultCustom } from '../../models/insights.models';

// Adapters
const searchResultRowsAdapter = createEntityAdapter<InvoiceReportResultRowCustom>({
  selectId: (row: InvoiceReportResultRowCustom) => row.Id,
});

export interface InsightsReportState {
  searchResults?: InvoiceReportSearchResultCustom;
  searchResultRows: EntityState<InvoiceReportResultRowCustom>;
  searchResultIsLoading: boolean;
  searchCriteriaGroupByCount: number;
  reportFileIsLoading: boolean;
}

const initialState: InsightsReportState = {
  searchResults: undefined,
  searchResultRows: searchResultRowsAdapter.getInitialState(),
  searchResultIsLoading: true,
  searchCriteriaGroupByCount: 0,
  reportFileIsLoading: false,
};

// Reducers
export const insightsReportSlice = createSlice({
  name: 'insights-report',
  initialState: initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    resetSearchResults: (state: InsightsReportState) => {
      state.searchResults = undefined;
      state.searchResultRows = searchResultRowsAdapter.getInitialState();
    },
    setSearchAsFavorite: (
      state: InsightsReportState,
      action: PayloadAction<{ SearchName: string; SearchDirectoryId: string }>
    ) => {
      if (state.searchResults) {
        state.searchResults = {
          ...state.searchResults,
          ...action.payload,
        };
      }
    },
    setSearchResults: (state: InsightsReportState, action: PayloadAction<InvoiceReportSearchResultData>) => {
      const { result, rows } = getNormalizedInvoiceReportSearchResult(action.payload);
      state.searchResults = result;
      searchResultRowsAdapter.setAll(state.searchResultRows, rows);
    },
    setSearchResultsIsLoading: (state: InsightsReportState, action: PayloadAction<boolean>) => {
      state.searchResultIsLoading = action.payload;
    },
    expandCollapseInvoiceReportResultRow: (
      state: InsightsReportState,
      action: PayloadAction<{ id: string; isCollapsed: boolean }>
    ) => setInvoiceReportResultRowIsCollapsed(state, action.payload.id, action.payload.isCollapsed),
    expandCollapseAllInvoiceReportResultRows: (state: InsightsReportState, action: PayloadAction<boolean>) =>
      selectInvoiceReportSearchResultRows(state.searchResultRows)
        .filter((row) => row.Level === 0)
        .forEach((row) => setInvoiceReportResultRowIsCollapsed(state, row.Id, action.payload, true)),
    setSearchCriteriaGroupByCount: (state: InsightsReportState, action: PayloadAction<number>) => {
      state.searchCriteriaGroupByCount = action.payload;
    },
    setReportFileIsLoading: (state: InsightsReportState, action: PayloadAction<boolean>) => {
      state.reportFileIsLoading = action.payload;
    },
  },
});

// Adapter Selectors
export const {
  selectAll: selectInvoiceReportSearchResultRows,
  selectById: selectInvoiceReportSearchResultRowByKey,
  selectIds: selectInvoiceReportSearchResultRowKeys,
} = searchResultRowsAdapter.getSelectors<EntityState<InvoiceReportResultRowCustom>>(
  (searchResultRows: EntityState<InvoiceReportResultRowCustom>) => searchResultRows
);

// Custom Selectors
export const selectFilteredInvoiceReportSearchResultRowKeys = createSelector(
  (state: RootState) => state.insightsReport.searchResultRows,
  (searchResultRows) =>
    selectInvoiceReportSearchResultRows(searchResultRows)
      .filter((r) => !r.IsHidden)
      .map((r) => r.Id)
);
