import { SeverityLevel } from '@microsoft/applicationinsights-web';
import {
  ApiResponse,
  CreateCustomerGroupRequest,
  CreateCustomerGroupResult,
  UpdateCustomerGroupRequest,
  UpdateCustomerGroupResult,
} from '../../api';
import CustomerGroupService from '../../api/services/customer-group.service';
import { generateId, normalizeKey } from '../../helpers';
import { validateStringEmptyOrMaxLength } from '../../helpers/validation/general';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { globalSlice } from '../common';
import { AppDispatch, AppThunk, RootState } from '../store';
import {
  customerGroupCreateSlice,
  selectAllCreateRecipientsKeyedByObjectValues,
  selectAllCustomerGroupCreateRecipients,
} from './customer-groups-create.slice';
import { selectAllCustomerGroups } from './customer-groups.slice';
import { getCustomerGroups, resetCustomerGroups } from './customer-groups.thunks';
import { CustomerRecipient } from './recipients.models';

const customerGroupService = CustomerGroupService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Sets the customerGroup's name to the specified name
 *
 * @param customerGroupName - The name of the customer group the customerGroup's name attribute is being set to
 * @returns NULL
 */
export const setCustomerGroupCreateName =
  (customerGroupName: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const customerGroup = getState().customerGroupCreate.customerGroup;
    dispatch(
      customerGroupCreateSlice.actions.setCustomerGroupBasic({
        CustomerGroupHeaderId: customerGroup?.CustomerGroupHeaderId || '',
        CustomerGroupName: customerGroupName,
        CustomerCount: 0,
      })
    );
    dispatch(validateCustomerGroupCreate());
  };

/**
 * Validates and sets the customerGroup's customer recipients
 *
 * @param customerRecipients - Array of the customer recipients that are being assigned to the customerGroup
 * @returns NULL
 */
export const setCustomerGroupCustomerCreateRecipients =
  (customerRecipients: CustomerRecipient[]): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const recipientsKeyedByObjectValue = selectAllCreateRecipientsKeyedByObjectValues(getState());
    customerRecipients.forEach((c) => {
      const key = normalizeKey(c.customerId);
      if (recipientsKeyedByObjectValue[key] && !c.selected) {
        delete recipientsKeyedByObjectValue[key];
      } else if (!recipientsKeyedByObjectValue[key] && c.selected) {
        recipientsKeyedByObjectValue[key] = {
          CustomerGroupHeaderId: key,
          CustomerGroupCustomerId: generateId(),
          CustomerId: key,
          CustomerName: c.customerName || '',
          CustomerGroupAdditionalDisplayOne: c.customerAddress || '',
          CustomerGroupAdditionalDisplayTwo: `${c.customerLocation} ${c.customerZip}`,
          CustomerGroupAdditionalDisplayThree: `${c.operationCompanyName} - ${c.customerNumber}`,
          OperationCompanyName: c.operationCompanyName,
        };
      }
    });
    const selectedReceipients = customerRecipients.map((r) => normalizeKey(r.customerId));
    const recipients = Object.values(recipientsKeyedByObjectValue).filter((r) =>
      selectedReceipients.includes(r.CustomerId)
    );

    dispatch(
      customerGroupCreateSlice.actions.setCustomerGroupRecipients({
        recipients,
      })
    );
  };

/**
 * Sets all values in the customer-group.create slice to their initial values
 *
 * @returns NULL
 */
export const resetCustomerGroupCreate = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(customerGroupCreateSlice.actions.resetState());
};

/**
 * Sets all values in the customer-group.create slice to their initial values
 *
 * @returns NULL
 */
export const resetValidtionMessages = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(customerGroupCreateSlice.actions.resetValidtionMessages());
};

/**
 * Validates the customer group's name
 *
 * @param customerGroupName - The name to be validated
 * @returns Returns the customers gotten from the validation
 */
export const validateCustomerGroupCreateName =
  (customerGroupName: string | undefined): AppThunk<Promise<string[]>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<string[]> => {
    const customers = validateStringEmptyOrMaxLength('Name', customerGroupName, 100);
    const errorCustomers = customers.map((item) => (item === 'Name is required' ? ' ' : item));

    if (errorCustomers.length === 0) {
      await dispatch(getCustomerGroups());
      const currentCustomerGroupHeaderId = getState().customerGroupSelected.customerGroup?.CustomerGroupHeaderId;
      if (
        selectAllCustomerGroups(getState()).filter(
          (mg) =>
            currentCustomerGroupHeaderId !== mg.CustomerGroupHeaderId &&
            mg.CustomerGroupName.trim().toLowerCase() === customerGroupName?.trim().toLowerCase()
        ).length > 0
      ) {
        errorCustomers.push('Name unavailable');
      }
      if (
        currentCustomerGroupHeaderId != null &&
        getState().customerGroupSelected.customerGroup?.CustomerGroupName.trim().toLowerCase() ===
          customerGroupName?.trim().toLowerCase()
      ) {
        errorCustomers.push(' ');
      }
    }
    return errorCustomers;
  };

/**
 * Sets validationCustomers to the result of the validateCustomerGroupName method
 *
 * @returns Boolean indicating if the length of the validation customer is 0
 */
export const setValidationMessages =
  (disable: boolean): AppThunk<Promise<boolean>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<boolean> => {
    const validationCustomers = await dispatch(
      validateCustomerGroupCreateName(getState().customerGroupCreate.customerGroup?.CustomerGroupName)
    );
    dispatch(
      customerGroupCreateSlice.actions.setValidationMessages({
        customerGroupName: validationCustomers,
        disableButton: disable,
      })
    );

    return validationCustomers.length === 0;
  };

/**
 * Sets validationCustomers to the result of the validateCustomerGroupName method
 *
 * @returns Boolean indicating if the length of the validation customer is 0
 */
export const validateCustomerGroupCreate =
  (): AppThunk<Promise<boolean>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<boolean> => {
    const validationCustomers = await dispatch(
      validateCustomerGroupCreateName(getState().customerGroupCreate.customerGroup?.CustomerGroupName)
    );
    dispatch(
      customerGroupCreateSlice.actions.setValidationMessages({
        customerGroupName: validationCustomers,
        disableButton: true,
      })
    );

    return validationCustomers.length === 0;
  };

/**
 * Creates or updates a message group depending on if the create message group has a group id
 *
 * @param successCallback - Method to call on success
 * @param errorCallback - Method to call on error
 * @returns NULL
 */
export const createOrUpdateCustomerGroup =
  (
    successCallback?: (CustomerGroupHeaderId: string) => void,
    errorCallback?: (errors: string[]) => void
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
      if (!(await dispatch(validateCustomerGroupCreate()))) {
        return;
      }
      const request: CreateCustomerGroupRequest | UpdateCustomerGroupRequest = {
        CustomerGroupHeaderId: getState().customerGroupCreate.customerGroup?.CustomerGroupHeaderId || '',
        CustomerGroupName: getState().customerGroupCreate.customerGroup?.CustomerGroupName || '',
        CustomerIds: selectAllCustomerGroupCreateRecipients(getState().customerGroupCreate.customerIds).map(
          (r) => r.CustomerGroupHeaderId
        ),
      };

      const priorRequest = getState().customerGroupCreate.saveCustomerGroupRequest;

      if (JSON.stringify(priorRequest) === JSON.stringify(request)) {
        return;
      }
      dispatch(customerGroupCreateSlice.actions.setSaveCustomerGroupApiRequest(request));

      let data: ApiResponse<CreateCustomerGroupResult | UpdateCustomerGroupResult>;

      if (request.CustomerGroupHeaderId) {
        data = (await customerGroupService.updateCustomerGroupName(request)).data;
      } else {
        data = (await customerGroupService.createCustomerGroup(request)).data;
      }

      if (data.IsSuccess) {
        // resetCustomerGroups is dispatched since we have to recall
        // whole customer group lists to fetch newly created group and refresh UI
        dispatch(resetCustomerGroups());
        dispatch(resetCustomerGroupCreate());
        successCallback?.(data.ResultObject.CustomerGroupHeaderId);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        errorCallback?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(customerGroupCreateSlice.actions.setSaveCustomerGroupApiRequest(undefined));
    }
  };
