import { ServiceResult } from '../models/api-shared.models';
import { CreateSubmittedEditOrderData, CreateSubmittedEditOrderRequest } from '../models/edit-order.models';
import { ReorderResult, ReorderSubmittedOrderRequest } from '../models/reorder.models';
import {
  GetSubmittedOrderHeadersRequest,
  GetSubmittedOrderRequest,
  OpenOrderHeadersResult,
  SubmittedOrderDetail,
} from '../models/submitted-order.models';
import ApiService from './api-service';

class SubmittedOrderService extends ApiService {
  private static classInstance: SubmittedOrderService;
  private getSubmittedOrderHeadersAbortController: AbortController;

  private constructor() {
    super('SubmittedOrder');
    this.getSubmittedOrderHeadersAbortController = new AbortController();
  }

  public abortGetSubmittedOrderHeaders = (): void => {
    this.getSubmittedOrderHeadersAbortController.abort();
  };

  public getSubmittedOrderHeaders(request: GetSubmittedOrderHeadersRequest): ServiceResult<OpenOrderHeadersResult[]> {
    this.getSubmittedOrderHeadersAbortController = new AbortController();
    return this.post(
      `${this.persistUrl}/GetSubmittedOrderHeaders`,
      request,
      undefined,
      this.getSubmittedOrderHeadersAbortController.signal
    );
  }

  public getSubmittedOrderHeader(request: GetSubmittedOrderRequest): ServiceResult<OpenOrderHeadersResult> {
    return this.post(`${this.baseUrl}/GetSubmittedOrderHeader`, request);
  }

  public getSubmittedOrderDetails(request: GetSubmittedOrderRequest): ServiceResult<SubmittedOrderDetail[]> {
    return this.post(`${this.baseUrl}/GetSubmittedOrderDetails`, request);
  }

  public reorderSubmittedOrder(request: ReorderSubmittedOrderRequest): ServiceResult<ReorderResult> {
    return this.post(`${this.persistUrl}/ReorderSubmittedOrder`, request);
  }

  public createSubmittedEditOrder(
    request: CreateSubmittedEditOrderRequest
  ): ServiceResult<CreateSubmittedEditOrderData> {
    return this.post(`${this.baseUrl}/CreateSubmittedEditOrder`, request);
  }

  public static getInstance(): SubmittedOrderService {
    if (!this.classInstance) {
      this.classInstance = new SubmittedOrderService();
    }
    return this.classInstance;
  }
}

export default SubmittedOrderService;
