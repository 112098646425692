import {
  AppRoute,
  AppRoutes,
  cacheTypeAheadSearchResults,
  clearProductSearchFilters,
  clearTypeAheadResults,
  clearTypeAheadSearchCache,
  deleteSearchHistory,
  getBreadCrumbsRouteName,
  getTypeAheadSearchResults,
  RootState,
  RouteName,
  useAppDispatch,
  useAppSelector,
} from 'common';
import { isAdvanceFilterUnset, ProductSearchHistoryState } from 'common/src/store/product-search-shared';
import React, { FC, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { debounce } from 'throttle-debounce';
import { CfSearchInputSize } from '../../../../../../cf-ui/SearchInput/CfSearchInput';
import { SuggestionItem } from '../../../../../../cf-ui/SuggestionList/Suggestion/CfSuggestion';
import { CfTypeAheadSearchInput } from '../../../../../../cf-ui/TypeAheadSearchInput/CfTypeAheadSearchInput';
import { useGa } from '../../../../../../hooks/useGa';
import { useQueryParams } from '../../../../../../hooks/useQueryParams';
import '../../../../../../routing/AppRouting';
import { Menus, setMenu } from '../../hooks/useHeaderMenus';

export interface TypeAheadSearchInputProps {
  size?: CfSearchInputSize;
  inputTestId: string;
}

const TypeAheadSearchInput: FC<TypeAheadSearchInputProps> = (props: TypeAheadSearchInputProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory<ProductSearchHistoryState>();
  const ga = useGa();

  const typeAheadAPIMessages = useAppSelector((s: RootState) => s.productSearch.typeAheadAPIMessages);
  const typeAheadResults = useAppSelector((s: RootState) => s.productSearch.typeAheadResults);
  const queryParams = useQueryParams<{ q?: string }>();
  const selectedCustomerId = useAppSelector((x) => x.customer.selectedCustomer?.CustomerId);

  let searchValue = '';
  const route = getBreadCrumbsRouteName();
  if (route?.Path === AppRoutes[RouteName.ProductSearch].Path && queryParams.q) {
    searchValue = queryParams.q;
  }

  useEffect(() => {
    clearList();
  }, [selectedCustomerId]);

  // Load history
  useEffect(() => {
    dispatch(cacheTypeAheadSearchResults(searchValue));
  }, [selectedCustomerId, searchValue]);

  // Map API results to state
  let errorItems = [] as SuggestionItem[];
  if (typeAheadAPIMessages?.ErrorMessages) {
    errorItems = typeAheadAPIMessages.ErrorMessages?.map((i: string) => {
      return {
        text: i,
        value: i,
        type: 'error-message',
      } as SuggestionItem;
    });
  }
  let infoItems = [] as SuggestionItem[];
  if (typeAheadAPIMessages?.InformationMessages) {
    infoItems = typeAheadAPIMessages.InformationMessages?.map((i: string) => {
      return {
        text: i,
        value: i,
        type: 'info-message',
      } as SuggestionItem;
    });
  }
  const messageItems = errorItems.concat(infoItems);

  const historyItems = (typeAheadResults?.SearchHistory?.slice(0, 3) ?? []).map((i) => {
    return {
      text: i.Term,
      value: i.UserCustomerSearchHistoryId,
      type: 'history',
      isRemovable: true,
      iconClassName: 'fa-clock',
    } as SuggestionItem;
  });
  const suggestionItems = (typeAheadResults?.Suggestions?.slice(0, 5) ?? []).map((i) => {
    return {
      text: i.Term,
      value: i.Term,
      type: 'suggestion',
      isRemovable: false,
      iconClassName: 'fa-search',
    } as SuggestionItem;
  });

  useEffect(() => {
    return () => {
      autoCompleteDebounced.cancel();
    };
  }, []);

  const autoCompleteDebounced = useCallback(
    debounce(200, (v: string) => {
      dispatch(getTypeAheadSearchResults(v));
    }),
    []
  );

  const handleTypeAhead = (searchValue: string): void => {
    if (!searchValue) {
      dispatch(getTypeAheadSearchResults(''));
    }
    autoCompleteDebounced(searchValue);
  };

  // Event handlers
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event) {
      const caret = event.target.selectionStart;
      const element = event.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = caret;
        element.selectionEnd = caret;
      });

      const val = event.target.value;
      handleTypeAhead(val);
    }
  };

  const handleClear = (): void => {
    clearList();
  };

  const handleFocus = (text: string): void => {
    dispatch(getTypeAheadSearchResults(text));
  };

  const handleSubmit = (text: string | undefined): void => {
    if (text && selectedCustomerId) {
      let parentRoute: AppRoute | undefined = getBreadCrumbsRouteName();

      if (
        parentRoute?.DisplayName === AppRoutes.Home.DisplayName ||
        parentRoute?.DisplayName === AppRoutes.ProductSearch.DisplayName ||
        parentRoute?.DisplayName === AppRoutes.ProductListCatalogSearch.DisplayName ||
        parentRoute?.DisplayName === AppRoutes.ProductList.DisplayName
      ) {
        parentRoute = undefined;
      }

      if (text === searchValue) {
        if (!isAdvanceFilterUnset(history.location.state.advanceFilter)) {
          dispatch(clearProductSearchFilters(history, parentRoute));
        }
      } else {
        clearList();
      }
      dispatch(clearTypeAheadSearchCache());
      dispatch(cacheTypeAheadSearchResults(text));
      ga?.search(text);
      history.push(`${AppRoutes[RouteName.ProductSearch].Path}/${selectedCustomerId}?q=${encodeURIComponent(text)}`, {
        parentRoute,
      });
    }
  };

  const handleOnItemRemove = (item: SuggestionItem, text: string | undefined) => {
    if (item.type === 'history') {
      dispatch(deleteSearchHistory(text || '', item.value));
    }
  };

  // Helper functions
  const clearList = (): void => {
    dispatch(clearTypeAheadResults());
  };

  return (
    <CfTypeAheadSearchInput
      placeholder='Search catalog'
      searchTextValue={searchValue}
      onSubmit={handleSubmit}
      onChange={handleChange}
      onClear={handleClear}
      items={[...historyItems, ...suggestionItems, ...messageItems]}
      onItemRemove={handleOnItemRemove}
      onFocus={handleFocus}
      size={props.size}
      inputTestId={props.inputTestId}
      onListOpen={() => setMenu(Menus.None)}
    />
  );
};

export default TypeAheadSearchInput;
