import { AppRoutes, RootState, RouteName, selectUserHasAcceptedEssentialTerms, useAppSelector } from 'common';
import { Suspense, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { StyledPageContainer, StyledPageContent } from './App.styles';
import { CfPageLoader } from './cf-ui/Loader/PageLoader/CfPageLoader';
import CustomerSupportOverviewDialog from './features/account/components/customer-support/components/dialog/CustomerSupportOverviewDialog';
import SkipOverviewDialog from './features/account/components/customer-support/components/dialog/SkipOverviewDialog';
import { EditOrderNotifications } from './features/orders/components/EditOrder/EditOrderNotifications';
import { SwitchFromEditedOrderDialog } from './features/orders/components/EditOrder/SwitchFromEditedOrderDialog';
import Footer from './features/site/components/Footer/Footer';
import Header from './features/site/components/Header/Header';
import WindowScrollToTopButton from './features/site/components/ScrollToTopButton/WindowScrollToTopButton';
import ErrorDialog from './features/site/components/dialogs/ErrorDialog';
import InformationDialog from './features/site/components/dialogs/InformationDialog';
import { AppRouting } from './routing/AppRouting';

const AppContent = (): JSX.Element => {
  const location = useLocation();

  const [userAcceptanceFlow, setUserAcceptanceFlow] = useState<boolean>(false);
  const hasTermsAccepted = useAppSelector((s) => selectUserHasAcceptedEssentialTerms(s));

  useEffect(() => {
    if (
      location.pathname.includes(AppRoutes[RouteName.CookieAcceptance].Path) ||
      location.pathname.includes(AppRoutes[RouteName.TermsOfService].Path)
    )
      setUserAcceptanceFlow(true);
    else setUserAcceptanceFlow(false);
  }, [location.pathname]);

  return (
    <>
      <AppPageLoader />

      {hasTermsAccepted && <Header />}

      <Suspense fallback={<CfPageLoader isLoading />}>
        <StyledPageContainer
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          wrap='nowrap'
          $userAcceptanceFlow={userAcceptanceFlow}
        >
          <StyledPageContent>
            <AppRouting />
          </StyledPageContent>
        </StyledPageContainer>
      </Suspense>

      {hasTermsAccepted && (
        <>
          <Footer />
          <WindowScrollToTopButton />
          <ErrorDialog />
          <InformationDialog />
          <CustomerSupportOverviewDialog />
          <SkipOverviewDialog />
          <SwitchFromEditedOrderDialog />
          <EditOrderNotifications />
        </>
      )}
    </>
  );
};

const AppPageLoader = (): JSX.Element => {
  const isReorderLoading = useAppSelector((s: RootState) => s.reorder.isReorderLoading);
  const isLoading = isReorderLoading;

  return <CfPageLoader isLoading={isLoading} />;
};

export default AppContent;
