import { Box, Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { RootState, useAppSelector, UserCustomer } from 'common';
import { FC, useState } from 'react';
import SideBarCard, { SideBarActionPlacement } from '../../../../finances/components/_shared/SideBarCard/SideBarCard';
import CommunicationsCustomerInfoCard from './CommunicationCustomerInfoCard';
import CommunicationsLocationDialog from './CommunicationsLocationSearchDialog';

export interface CommsLocationCardProps {
  availableCustomers?: UserCustomer[];
  defaultSelectedCustomer: UserCustomer;
  collapsedByDefault?: boolean;
  fetchCustomers?: () => void;
  onCustomerChange?: (customer?: UserCustomer) => void;
  isLoading?: boolean;
  hideCreditTerms?: boolean;
  checkboxListSx?: SxProps<Theme>;
  canHide?: boolean;
  titleTestId?: string;
  actionLabelTestId?: string;
  closeButtonTestId?: string;
  applyButtonTestId?: string;
  dialogTitleTestId?: string;
  inputTestId?: string;
  searchButtonTestId?: string;
  selectAllCheckboxTestId?: string;
  checkboxTestId?: string;
  customerAddressTestId?: string;
  customerZipTestId?: string;
  customerNumberTestId?: string;
  sx?: SxProps<Theme>;
}

const CommsLocation: FC<CommsLocationCardProps> = (props: CommsLocationCardProps) => {
  const {
    canHide,
    collapsedByDefault,
    defaultSelectedCustomer,
    onCustomerChange: handleCustomerChange,
    sx,
    titleTestId,
    actionLabelTestId,
    customerAddressTestId,
    customerZipTestId,
    customerNumberTestId,
  } = props;

  const userSiteCustomers = useAppSelector((s: RootState) => s.user.userSite?.UserCustomers || []);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleActionClick = (e?: React.MouseEvent<HTMLElement>): void => {
    e?.stopPropagation?.();
    setDialogOpen(true);
  };

  const handleModalClose = () => {
    setDialogOpen(false);
  };

  return (
    <SideBarCard
      sx={{ width: (theme) => ({ xl: theme.spacing(33.5) }), maxHeight: { xs: 'none' }, overflow: 'auto', ...sx }}
      title='LOCATION'
      actionLabel='Modify'
      actionPlacement={SideBarActionPlacement.header}
      actionSx={{
        flex: 'auto 1 0',
        display: 'flex',
        justifyContent: { xs: 'space-between', lg: 'flex-start' },
        pl: 0.5,
      }}
      onActionClick={handleActionClick}
      canHide={canHide}
      containerWidth={true}
      collapsedByDefault={collapsedByDefault}
      titleTestId={titleTestId}
      actionLabelTestId={actionLabelTestId}
      actionLabelSx={{
        ml: { lg: 13.5 },
        color: (theme: Theme) => theme.palette.coolGrey[600],
      }}
    >
      <Stack gap={1} sx={{ maxHeight: { xs: 'unset' } }}>
        <Box key={defaultSelectedCustomer.CustomerId}>
          <CommunicationsCustomerInfoCard
            customerAddressTestId={customerAddressTestId}
            customerZipTestId={customerZipTestId}
            customerNumberTestId={customerNumberTestId}
            customerId={defaultSelectedCustomer.CustomerId}
            data-testid='communications-customer-info-card'
          />
        </Box>
      </Stack>
      <CommunicationsLocationDialog
        isOpen={dialogOpen}
        onSubmit={handleCustomerChange}
        onClose={handleModalClose}
        userCustomers={userSiteCustomers}
        selectedUserCustomer={defaultSelectedCustomer}
      />
    </SideBarCard>
  );
};

export default CommsLocation;
