import { ServiceResult } from '../models/api-shared.models';
import { BusinessUnitLink, GetBusinessUnitLinksRequest } from '../models/business-unit.models';
import ApiService from './api-service';

class BusinessUnitLinkService extends ApiService {
  private static classInstance: BusinessUnitLinkService;

  private constructor() {
    super('BusinessUnitLink');
  }

  public getBusinessUnitLinks(request?: GetBusinessUnitLinksRequest): ServiceResult<BusinessUnitLink[] | undefined> {
    return this.get(`${this.baseUrl}/GetBusinessUnitLinks`, request);
  }

  public static getInstance(): BusinessUnitLinkService {
    if (!this.classInstance) {
      this.classInstance = new BusinessUnitLinkService();
    }
    return this.classInstance;
  }
}

export default BusinessUnitLinkService;
