import { FileResult, ImportErrorMessage } from './api-shared.models';
import { Customer } from './customer.models';

export interface GetProductListHeaderRequest {
  ProductListHeaderId: string;
  customerId: string;
}

export interface GetCopyToProductListHeadersRequest {
  CustomerId: string;
  ToProductListHeaderId: string;
}

export interface GetProductListHeadersRequest {
  excludeReadOnlyLists: boolean;
  customerId: string;
  isForOrderEntry: boolean;
}

export interface GetProductListHeadersByProductKeyRequest {
  ProductKey?: string;
  BusinessUnitERPKey: number;
  CustomerId: string;
  ExcludeReadOnlyLists: boolean;
}

export interface GetProductListHeadersForAdditionByProductKeyRequest {
  ProductKey?: string;
  BusinessUnitERPKey: number;
  CustomerId: string;
}

export interface CreateProductListHeaderRequest {
  ProductListTitle: string;
  IsPrivate: boolean;
  CustomerId: string;
}

export interface CopyProductListRequest {
  ProductListHeaderId: string;
  CustomerIds: string[];
  ProductListTitle?: string;
  IsPrivate: boolean;
}

export interface UpdateProductListHeaderRequest extends CreateProductListHeaderRequest {
  ProductListHeaderId: string;
}

export interface UpdateProductListCustomerRequest {
  ProductListHeaderId: string;
  CustomerId: string;
  DisableNotifications: boolean;
  IsReadOnly: boolean;
}

export interface DeleteProductListHeaderRequest {
  ProductListHeaderId: string;
}

export interface ProductListHeader {
  ProductListHeaderId: string;
  ProductListTitle?: string;
  GroupName?: string;
  ProductListType: ProductListType;
  CreateUserAlternateKey?: string;
  IsPrivate: boolean;
  LastNotificationDate?: string | Date;
  ProductListDetailCount: number;
}

export interface ProductListHeaderWithProductExistence extends ProductListHeader {
  IsOnProductList?: boolean;
}

export interface ProductListHeaderForAddition extends ProductListHeader {
  IsProductOnList: boolean;
}

export interface ProductListHeaderCustom {
  ProductListHeaderId: string;
  ProductListTitle: string;
  ProductListType: ProductListType;
  CreateUserAlternateKey: string;
  IsPrivate: boolean;
  IsReadOnly: boolean;
  IsNotificationsDisabled: boolean;
  CanShare: boolean;
  CanCopy: boolean;
  CanEdit: boolean;
  CanDelete: boolean;
  CanManageNotification: boolean;
  HasCustomCategorySequence: boolean;
  IsProductListOwner: boolean;
  ProductListDetailCount: number;
}

export interface ProductListHeaderCustomer {
  ProductListCustomerId: string;
  ProductListHeaderId: string;
  CustomerId: string;
  IsReadOnly: boolean;
  DisableNotifications: boolean;
}

export enum ProductListType {
  Partial = 0,
  Marketing = 1,
  Managed = 2,
  Custom = 3,
  PurchaseHistory = 4,
  AllItems = 5,
  ParGuide = 6,
}

export interface GetShareProductListHeaderCustomersRequest {
  ProductListHeaderId: string;
}

export interface GetShareProductListHeaderCustomersResponse {
  Customer: Customer;
  ProductListCustomerId: string;
  IsShared: boolean;
  IsReadOnly: boolean;
}

export interface UpdateShareProductListHeader {
  ProductListHeaderId: string;
  CustomerId: string;
  IsReadOnly: boolean;
  IsShared: boolean;
}

export interface UpdateShareProductListHeaderRequest {
  RequestObject: UpdateShareProductListHeader[];
}

export interface ReportSpecifications {
  IncludeAlternateRowShading: boolean;
  IncludeDoubleSpacing: boolean;
  IncludeCategoryBreaks: boolean;
  IncludePrices: boolean;
  IncludeCustomProductNumbersAndDescriptions: boolean;
  IncludeLastPurchase: boolean;
  IncludeBadgesAndAdditonalInfo: boolean;
  IncludeParValues: boolean;
  ParListId?: string;
}

export type ReportSpecificationProps = keyof ReportSpecifications;

export enum ProductListReportSortOptionType {
  CustomCategories = 0,
  Categories = 1,
  Alphabetical = 2,
  ProductNumber = 3,
}

export interface GetProductListReportCategoryOptionsRequest {
  ProductListHeaderId: string;
  CustomerId: string;
  ProductListReportSortOptionType: ProductListReportSortOptionType;
}

export interface GetProductListReportRequest {
  ProductListHeaderId: string;
  CustomerId: string;
  ReportType: number;
  PageOrientation: number;
  Specifications: ReportSpecifications;
  Sort: number;
  Categories: string[];
  TimeZone: string;
}

export interface GetProductListReportSortOptionResponse {
  Title: string;
  ProductListReportSortOptionType: ProductListReportSortOptionType;
}

export interface GetExportOptionDefaultsResultData {
  ReportType: number;
  PageOrientation: number;
  Sort: number;
  IncludeAlternateRowShading: boolean;
  IncludeDoubleSpacing: boolean;
  IncludeCategoryBreaks: boolean;
  IncludePrices: boolean;
  IncludeCustomProductNumbersAndDescriptions: boolean;
  IncludeLastPurchase: boolean;
  IncludeBadgesAndAdditonalInfo: boolean;
  IncludeParValues: boolean;
  ParListId: string;
  Categories: string[];
}

export interface CreateImportProductListRequest {
  ImportFile?: string;
  IsPrivate: boolean;
  ProductListTitle?: string;
  CustomerId: string;
  IgnoreErrors: boolean;
}

export interface CreateImportProductListResponse {
  ProductListHeaderId: string;
  ProductListTitle?: string;
  ImportListErrorMessages?: ImportErrorMessage[];
  ErrorFile: FileResult;
}
