import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FileResult } from '../../api';
import { Customer } from '../../api/models/customer.models';
import {
  OrderConfirmationNotification,
  OrderConfirmationProduct,
  OrderConfirmationResult,
  SubmittedOrderData,
} from '../../api/models/order-confirmation.models';
import { GetOrderEntrySearchResultDetail } from '../../api/models/order-entry-search.models';

// State
interface OrderConfirmationState {
  orderConfirmation: OrderConfirmationResult | undefined;
  submittedOrderData: SubmittedOrderData | undefined;
  submittedProducts: GetOrderEntrySearchResultDetail | undefined;
  customer: Customer | undefined;
  orderConfirmationLoading: boolean;
  orderConfirmationNotifications: OrderConfirmationNotification[] | undefined;
  orderConfirmationNotificationsLoading: boolean;
  orderConfirmationReportFileData: FileResult | undefined;
  orderConfirmationReportFileDataLoading: boolean;
}

const initialState: OrderConfirmationState = {
  orderConfirmation: undefined,
  submittedOrderData: undefined,
  submittedProducts: undefined,
  customer: undefined,
  orderConfirmationLoading: false,
  orderConfirmationNotifications: undefined,
  orderConfirmationNotificationsLoading: false,
  orderConfirmationReportFileData: undefined,
  orderConfirmationReportFileDataLoading: false,
};

// Reducers
export const orderConfirmationSlice = createSlice({
  name: 'orderConfirmation',
  initialState: initialState,
  reducers: {
    setSubmittedOrderData: (state: OrderConfirmationState, action: PayloadAction<SubmittedOrderData | undefined>) => {
      state.submittedOrderData = action.payload;
    },
    setOrderConfirmation: (
      state: OrderConfirmationState,
      action: PayloadAction<OrderConfirmationResult | undefined>
    ) => {
      state.orderConfirmation = action.payload;
    },
    setSubmittedProducts: (state: OrderConfirmationState, action: PayloadAction<GetOrderEntrySearchResultDetail>) => {
      state.submittedProducts = action.payload;
    },
    setOrderConfirmationCustomer: (state: OrderConfirmationState, action: PayloadAction<Customer>) => {
      state.customer = action.payload;
    },
    setOrderConfirmationLoading: (state: OrderConfirmationState, action: PayloadAction<boolean>) => {
      state.orderConfirmationLoading = action.payload;
    },
    setOrderConfirmationNotifications: (
      state: OrderConfirmationState,
      action: PayloadAction<OrderConfirmationNotification[]>
    ) => {
      state.orderConfirmationNotifications = action.payload;
    },
    setOrderConfirmationNotificationsLoading: (state: OrderConfirmationState, action: PayloadAction<boolean>) => {
      state.orderConfirmationNotificationsLoading = action.payload;
    },
    setOrderConfirmationReportFileData: (state: OrderConfirmationState, action: PayloadAction<FileResult>) => {
      state.orderConfirmationReportFileData = action.payload;
    },
    setOrderConfirmationReportFileDataLoading: (state: OrderConfirmationState, action: PayloadAction<boolean>) => {
      state.orderConfirmationReportFileDataLoading = action.payload;
    },
    resetOrderConfirmation: () => {
      return initialState;
    },
  },
});

export const { setOrderConfirmation, setOrderConfirmationLoading } = orderConfirmationSlice.actions;

export const selectOrderConfirmationGridData = createSelector(
  (state: OrderConfirmationState) => state.orderConfirmation?.OrderConfirmationProducts,
  (products) => {
    const standardItems: OrderConfirmationProduct[] = [];
    const infoItems: OrderConfirmationProduct[] = [];
    const errorItems: OrderConfirmationProduct[] = [];
    const shippedSeparatelyItems: OrderConfirmationProduct[] = [];

    if (products) {
      [...products]
        .sort((a, b) => {
          if (a.IsShippedSeparately === b.IsShippedSeparately) {
            return 0;
          } else {
            return Number(a.IsShippedSeparately) - Number(b.IsShippedSeparately);
          }
        })
        .forEach((p) => {
          if (!p.IsShippedSeparately) standardItems.push(p);
          if (p.InformationMessages.length > 0) infoItems.push(p);
          if (p.ErrorMessages.length > 0) errorItems.push(p);
          if (p.IsShippedSeparately) shippedSeparatelyItems.push(p);
        });
    }
    return { standardItems, infoItems, errorItems, shippedSeparatelyItems };
  }
);
