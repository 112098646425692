import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { BusinessUnitKeyType } from '../../api/models/api-shared.enums';
import { UnitOfMeasureOrderQuantity } from '../../api/models/api-shared.models';
import { UpdateCustomProductRequest } from '../../api/models/customer-product.models';
import { ProductDetailTransfer } from '../../api/models/product-detail.models';
import { ProductListProduct, SearchProductListCatalogFieldRequest } from '../../api/models/product-list-search.models';
import { normalizeProductKey, updateProductCustomAttributesFromPayload } from '../../helpers/general/product';
import { getSearchProductListCatalogFieldRequestDefault } from '../../helpers/general/product-list';
import { RootState } from '../store';

// Adapters
const productsAdapter = createEntityAdapter<ProductListProduct>({
  selectId: (product: ProductListProduct) => normalizeProductKey(product.ProductKey),
});

// State
export interface ItemOrderEntryState {
  products: EntityState<ProductListProduct>;
  pageIsLoading: boolean;
  searchProductListCatalogFieldRequest: SearchProductListCatalogFieldRequest;
  searchingProductList: boolean;
  loadingDefaultSearchType: boolean;
  selectableProducts: ProductListProduct[];
  noSelectableProducts: boolean;
  focus: { rowIndex: number; elementIndex?: number } | undefined;
  prevQuery: string | undefined;
}

const initialState: ItemOrderEntryState = {
  products: productsAdapter.getInitialState(),
  pageIsLoading: false,
  searchProductListCatalogFieldRequest: getSearchProductListCatalogFieldRequestDefault(),
  searchingProductList: false,
  loadingDefaultSearchType: false,
  selectableProducts: [],
  noSelectableProducts: false,
  focus: undefined,
  prevQuery: undefined,
};

// Reducers
export const itemOrderEntrySlice = createSlice({
  name: 'itemOrderEntry',
  initialState: initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    addProduct: (state: ItemOrderEntryState, action: PayloadAction<ProductListProduct>) => {
      productsAdapter.upsertOne(state.products, action.payload);
    },
    setProducts: (state: ItemOrderEntryState, action: PayloadAction<ProductListProduct[]>) => {
      productsAdapter.setAll(state.products, action.payload);
    },
    setSearchProductListCatalogFieldRequest: (
      state: ItemOrderEntryState,
      action: PayloadAction<SearchProductListCatalogFieldRequest>
    ) => {
      state.prevQuery = state.searchProductListCatalogFieldRequest.Query;
      state.searchProductListCatalogFieldRequest = action.payload;
    },
    setPageIsLoading: (state: ItemOrderEntryState, action: PayloadAction<boolean>) => {
      state.pageIsLoading = action.payload;
    },
    setSelectableProducts: (state: ItemOrderEntryState, action: PayloadAction<ProductListProduct[]>) => {
      state.selectableProducts = action.payload;
    },
    setNoSelectableProducts: (state: ItemOrderEntryState, action: PayloadAction<boolean>) => {
      state.noSelectableProducts = action.payload;
    },
    clearRequestQuery: (state: ItemOrderEntryState) => {
      state.prevQuery = state.searchProductListCatalogFieldRequest.Query;
      state.searchProductListCatalogFieldRequest.Query = '';
    },
    setSearchingProductList: (state: ItemOrderEntryState, action: PayloadAction<boolean>) => {
      state.searchingProductList = action.payload;
    },
    setLoadingDefaultSearchType: (state: ItemOrderEntryState, action: PayloadAction<boolean>) => {
      state.loadingDefaultSearchType = action.payload;
    },
    setFocus: (
      state: ItemOrderEntryState,
      action: PayloadAction<{
        productKey?: string;
        productNumber?: string;
        customProductNumber?: string;
      }>
    ) => {
      const productKey = normalizeProductKey(action.payload.productKey);
      const productNumber = action.payload.productNumber;
      const customProductNumber = action.payload.customProductNumber;

      const existingProduct = state.products.entities[productKey];
      if (existingProduct) {
        let elementIndex = 0;

        if (
          productNumber &&
          productNumber.length > 0 &&
          existingProduct.BusinessUnitKey === BusinessUnitKeyType.ReinhartFoodService
        ) {
          // Set focus based on product number

          const lastChar = productNumber[productNumber.length - 1];
          const charNumeric = +lastChar;
          if (charNumeric % 2 === 1) elementIndex = 1;
        } else if (customProductNumber !== undefined) {
          // Set focus based on custom product number
          elementIndex = Math.max(
            existingProduct.UnitOfMeasureOrderQuantities.findIndex(
              (u) => u.ProductNumberDisplay === customProductNumber
            ),
            0
          );
        }
        const rowIndex = Math.max(state.products.ids.indexOf(productKey), 0);
        state.focus = {
          rowIndex,
          elementIndex,
        };
      } else {
        state.focus = undefined;
      }
    },
    updateProduct: (state: ItemOrderEntryState, action: PayloadAction<ProductDetailTransfer>) => {
      const product = state.products.entities[normalizeProductKey(action.payload.productKey)];
      if (product) {
        product.UnitOfMeasureOrderQuantities.forEach((uom: UnitOfMeasureOrderQuantity) => {
          if (uom.UnitOfMeasure === action.payload.uom) {
            uom.ExtendedPrice = action.payload.extendedPrice;
            uom.Quantity = action.payload.quantity;
            uom.ShowOrderQuantityAlert = action.payload.showOrderQuantityAlert;
          }
        });
      }
    },
    updateProductCustomAttributes: (state: ItemOrderEntryState, action: PayloadAction<UpdateCustomProductRequest>) => {
      const product = state.products.entities[normalizeProductKey(action.payload.ProductKey)];
      if (!product) return;

      updateProductCustomAttributesFromPayload(product, action.payload);
    },
  },
});

// Adapter Selectors
export const {
  selectAll: selectAllItemOrderEntryProducts,
  selectById: selectItemOrderEntryProductsByKey,
  selectIds: selectAllItemOrderEntryProductKeys,
} = productsAdapter.getSelectors<RootState>((state: RootState) => state.itemOrderEntry.products);

const selectOrderEntryProductsLoaded = createSelector(
  (state: RootState) => selectAllItemOrderEntryProducts(state),
  (products) => products.length > 0
);
export const selectOrderEntryProductsWithPrice = createSelector(
  (state: RootState) => selectAllItemOrderEntryProducts(state),
  (products) =>
    products.flatMap((p) =>
      p.UnitOfMeasureOrderQuantities.map((uom) => ({ product: p, uom })).filter((data) => data.uom.Price)
    ).length > 0
);

export const isItemOrderEntryProductsPagePricesLoaded = createSelector(
  [selectOrderEntryProductsWithPrice, selectOrderEntryProductsLoaded],
  (didOrderEntryPriceLoad, didOrderEntryProductsLoad): boolean => {
    return !didOrderEntryPriceLoad && didOrderEntryProductsLoad;
  }
);
