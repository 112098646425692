import { CustomerSupportType, OverviewSlideGroups } from '../../../api/models/customer-support.enums';
import { CustomerSupportTutorialItem } from '../../../models';

export const listManagementTutorials: CustomerSupportTutorialItem[] = [
  {
    tutorialTitle: 'Viewing, creating and managing lists',
    tutorialText:
      'Within List Management, you can access your custom lists, product history, and PFG Managed Lists. View the tutorial to learn how to build and maintain custom lists.',
    customerSupportType: CustomerSupportType.ListManagement,
    overviewGroup: OverviewSlideGroups.ListManagement,
  },
];
