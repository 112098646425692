import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { EntityState } from '@reduxjs/toolkit';
import {
  ApiResponse,
  CreateDraftMessageRequest,
  CreateDraftMessageResult,
  CreateMessageGroupRequest,
  MessageRecipient,
  MessageRecipientType,
  SendMessageRequest,
  UpdateDraftMessageRequest,
  UpdateDraftMessageResult,
} from '../../api';
import MessageGroupService from '../../api/services/message-group.service';
import MessageService from '../../api/services/message.service';
import { generateId, normalizeKey } from '../../helpers';
import { validateStringEmptyOrMaxLength } from '../../helpers/validation/general';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { NotificationKeys } from '../../models';
import { addAppNotification, globalSlice } from '../common';
import { AppDispatch, AppThunk, RootState } from '../store';
import {
  messageCreateSlice,
  selectAllMessageCreateRecipientsKeyedByObjectValue,
  selectAllMessageRecipients,
  selectCanSaveMessageDraft,
  selectCanSendMessage,
  selectMessageAttachmentIdsToRemove,
} from './message-create.slice';
import { validateMessageGroupName } from './message-group-selected.thunks';
import { getMessageGroups, resetMessageGroups } from './message-group.thunks';
import { resetMessages } from './message.thunks';
import { CustomerRecipient, MessageGroupRecipient, UserRecipient } from './recipients.models';

const appInsightsLogger = useAppInsightsLogger();
const messageService = MessageService.getInstance();
const messageGroupService = MessageGroupService.getInstance();

/**
 * Resets MessageCreateState properties to their initial values
 * @returns void
 */
export const resetMessageCreate = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(messageCreateSlice.actions.resetState());
};

/**
 * Sends a request to the api to retrieve a draft message
 * @param messageId - the id representing the draft message
 * @returns void
 */
export const getMessageDraft =
  (messageId: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (
        getState().messageCreate.messageId?.toLowerCase() === messageId.toLowerCase() &&
        getState().messageCreate.messageLoading
      ) {
        return;
      }

      if (!getState().messageCreate.messageLoading) {
        dispatch(messageCreateSlice.actions.setMessageLoading(true));
      }
      const { data } = await messageService.getMessage(messageId);
      if (data.IsSuccess) {
        const message = data.ResultObject.Message;
        dispatch(messageCreateSlice.actions.setMessage(message));
      } else {
        dispatch(resetMessageCreate());
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      dispatch(resetMessageCreate());
      console.error(error);
    } finally {
      dispatch(messageCreateSlice.actions.setMessageLoading(false));
    }
  };

/**
 * Updates the MessageCreateState's subject property and dispatches the validateMessage thunk
 * @param subject - the value to set the message's subject to
 * @returns void
 */
export const setMessageSubject =
  (subject: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(messageCreateSlice.actions.setMessageSubject(subject));
    dispatch(validateMessage());
  };

/**
 * Updates the MessageCreateState's isHighPriority property and dispatches the validateMessage thunk
 * @param isHighPriority - the value to set the message's isHighPriority property to
 * @returns void
 */
export const setMessageIsHighPriority =
  (isHighPriority: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(messageCreateSlice.actions.setMessageIsHighPriority(isHighPriority));
    dispatch(validateMessage());
  };

/**
 * Updates the MessageCreateState's body property and dispatches the validateMessage thunk
 * @param body - the value to set the message's body to
 * @returns void
 */
export const setMessageBody =
  (body: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(messageCreateSlice.actions.setMessageBody(body));
    dispatch(validateMessage());
  };

/**
 * Updates the body property of the MessageCreateState's originalMessage property
 * @param body - the value to set the message's body to
 * @returns void
 */
export const setOriginalMessageBody =
  (body: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(messageCreateSlice.actions.setOriginalMessageBody(body));
  };

/**
 * Adds a set of recipients of MessageRecipientType.Customer to the MessageCreateState's recipients property and dispatches the validateMessage thunk
 * @param customerRecipients - the set of customers to receive the message
 * @returns void
 */
export const setMessageCustomerRecipients =
  (customerRecipients: CustomerRecipient[]): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const recipientType = MessageRecipientType.Customer;
    const recipientsKeyedByObjectValue = selectAllMessageCreateRecipientsKeyedByObjectValue(getState());
    customerRecipients.forEach((c) => {
      const key = normalizeKey(c.customerId).toLowerCase();
      if (recipientsKeyedByObjectValue[key] && !c.selected) {
        delete recipientsKeyedByObjectValue[key];
      } else if (!recipientsKeyedByObjectValue[key] && c.selected) {
        recipientsKeyedByObjectValue[key] = {
          MessageRecipientId: generateId(),
          MessageRecipientNameDisplay: c.customerName || '',
          MessageRecipientAdditionalDisplayOne: c.customerAddress || '',
          MessageRecipientAdditionalDisplayTwo: `${c.customerLocation} ${c.customerZip}`,
          MessageRecipientAdditionalDisplayThree: `${c.operationCompanyName} - ${c.customerNumber}`,
          MessageRecipientType: recipientType,
          ObjectValue: key,
        };
      }
    });

    const recipients = Object.values(recipientsKeyedByObjectValue);

    dispatch(
      messageCreateSlice.actions.setMessageRecipients({
        recipients,
        setOnlyRecipientsOfType: recipientType,
      })
    );
    dispatch(validateMessage());
  };

/**
 * Adds a set of recipients of MessageRecipientType.User to the MessageCreateState's recipients property and dispatches the validateMessage thunk
 * @param userRecipients - the set of users to receive the message
 * @returns void
 */
export const setMessageUserRecipients =
  (userRecipients: UserRecipient[]): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const recipientType = MessageRecipientType.User;
    const recipientsKeyedByObjectValue = selectAllMessageCreateRecipientsKeyedByObjectValue(getState());

    userRecipients.forEach((u) => {
      const key = normalizeKey(u.userId);
      if (recipientsKeyedByObjectValue[key] && !u.selected) {
        delete recipientsKeyedByObjectValue[key];
      } else if (!recipientsKeyedByObjectValue[key] && u.selected) {
        recipientsKeyedByObjectValue[key] = {
          MessageRecipientId: generateId(),
          MessageRecipientNameDisplay: u.userDisplayName,
          MessageRecipientAdditionalDisplayOne: u.userName,
          MessageRecipientAdditionalDisplayTwo: '',
          MessageRecipientAdditionalDisplayThree: '',
          MessageRecipientType: recipientType,
          ObjectValue: key,
        };
      }
    });

    const recipients = Object.values(recipientsKeyedByObjectValue);

    dispatch(
      messageCreateSlice.actions.setMessageRecipients({
        recipients,
        setOnlyRecipientsOfType: recipientType,
      })
    );
    dispatch(validateMessage());
  };

/**
 * Adds a set of recipients of MessageRecipientType.MessageGroup to the MessageCreateState's recipients property and dispatches the validateMessage thunk
 * @param messageGroupRecipients - the set of message groups to receive the message
 * @returns void
 */
export const setMessageMessageGroupRecipients =
  (messageGroupRecipients: MessageGroupRecipient[]): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const recipientType = MessageRecipientType.MessageGroup;
    const recipientsKeyedByObjectValue = selectAllMessageCreateRecipientsKeyedByObjectValue(getState());

    messageGroupRecipients.forEach((mg) => {
      const key = normalizeKey(mg.messageGroupId);
      if (recipientsKeyedByObjectValue[key] && !mg.selected) {
        delete recipientsKeyedByObjectValue[key];
      } else if (!recipientsKeyedByObjectValue[key] && mg.selected) {
        recipientsKeyedByObjectValue[key] = {
          MessageRecipientId: generateId(),
          MessageRecipientNameDisplay: `${mg.messageGroupName} (${mg.recipientCount})`,
          MessageRecipientAdditionalDisplayOne: '',
          MessageRecipientAdditionalDisplayTwo: '',
          MessageRecipientAdditionalDisplayThree: '',
          MessageRecipientType: recipientType,
          ObjectValue: key,
        };
      }
    });

    const recipients = Object.values(recipientsKeyedByObjectValue);

    dispatch(
      messageCreateSlice.actions.setMessageRecipients({
        recipients,
        setOnlyRecipientsOfType: recipientType,
      })
    );
    dispatch(validateMessage());
  };

/**
 * Updates the MessageCreateState's recipients property by removing the specified record
 * @param recipientId - the id representing the record of the recipient (e.g. a customer, user, or message group)
 * @returns void
 */
export const removeMessageRecipientById =
  (recipientId: string): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(messageCreateSlice.actions.removeMessageRecipientById(recipientId));
  };

/**
 * Updates the MessageCreateState's showAllRecipients property that determines the expanded/collapsed state of the MessageRecipientsEditor component
 * @param showAllRecipients - the value to set showAllRecipients to
 * @returns void
 */
export const setMessageCreateShowAllRecipients =
  (showAllRecipients: boolean): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(messageCreateSlice.actions.setShowAllRecipients(showAllRecipients));
  };

/**
 * Evaluates if at least one recipient has been set to receive the message
 * @param recipients - the recipients to receive the message
 * @returns void
 */
const validateMessageRecipients = (recipients: EntityState<MessageRecipient>): string[] =>
  recipients.ids.length ? [] : ['At least on recipient is required'];

/**
 * Evaluates if the message's subject is empty or exceeds the maximum length threshold
 * @param subject - the subject of the message
 * @returns void
 */
const validateMessageSubject = (subject: string | undefined): string[] =>
  validateStringEmptyOrMaxLength('Subject', subject);

/**
 * Updates the validationMessages property of the MessageCreateState
 * @returns Promise<boolean> true if any validation messages present
 */
export const validateMessage =
  (): AppThunk<Promise<boolean>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<boolean> => {
    const validationMessages = {
      recipients: validateMessageRecipients(getState().messageCreate.recipients),
      subject: validateMessageSubject(getState().messageCreate.subject),
      body: [],
    };

    dispatch(messageCreateSlice.actions.setValidationMessages(validationMessages));

    return Object.values(validationMessages).flatMap((ar) => ar).length === 0;
  };

const maxAttachmentSize = 2 * 1024 * 1024; // 2 MB

export const allowedMessageAttachmentFileExtensions = [
  '.pdf',
  '.csv',
  '.xlsx',
  '.xls',
  '.txt',
  '.jpg',
  '.jpeg',
  '.png',
];

/**
 * Adds a record to the attachments property of the MessageCreateState and presents error messages if the attachment is too large or has an invalid extension
 * @param file - file data selected by the user
 * @returns void
 */
export const addMessageAttachment =
  (file: { fileName: string; fileData: string; fileMimeType: string; size: number }): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch): Promise<void> => {
    if (file.size > maxAttachmentSize) {
      dispatch(
        globalSlice.actions.setErrorDialogContent({
          messages: [`${file.fileName} is larger than maximum attachment size of 2 MB`],
        })
      );
      return;
    }
    if (!allowedMessageAttachmentFileExtensions.filter((ext) => file.fileName.toLowerCase().endsWith(ext)).length) {
      dispatch(
        globalSlice.actions.setErrorDialogContent({
          messages: [`${file.fileName} is not an allowed file type for attachments`],
        })
      );
      return;
    }
    dispatch(
      messageCreateSlice.actions.addAttachment({
        FileName: file.fileName,
        FileData: file.fileData,
        FileMimeType: file.fileMimeType,
        MessageAttachmentId: generateId(),
      })
    );
  };

/**
 * Removes an attachment from the MessageCreateState
 * @param messageAttachmentId - the id representing the attachment record to be removed
 * @returns void
 */
export const removeMessageAttachment =
  (messageAttachmentId: string): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(messageCreateSlice.actions.removeAttachment(messageAttachmentId));
  };

/**
 * Sends a request to the api to create or update a draft message constructed from the MessageCreateState's properties
 * @param successCallback - callback method executed if the response is successful
 * @param errorCallback - callback method executed if the repsonse is unsuccessful
 * @returns void
 */
export const createOrUpdateMessageDraft =
  (
    successCallback?: (messageId: string) => void,
    errorCallback?: (errors: string[]) => void
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
      await dispatch(validateMessage());
      if (!selectCanSaveMessageDraft(getState())) {
        return;
      }
      const {
        messageId,
        subject,
        isHighPriority,
        body,
        attachments,
        saveMessageDraftApiRequest: priorRequest,
      } = getState().messageCreate;

      const request: CreateDraftMessageRequest | UpdateDraftMessageRequest = {
        MessageId: messageId,
        Recipients: selectAllMessageRecipients(getState().messageCreate.recipients).map((r) => ({
          MessageRecipientType: r.MessageRecipientType,
          ObjectValue: r.ObjectValue,
        })),
        Subject: subject,
        IsHighPriority: isHighPriority,
        Body: body,
        AddAttachments: attachments
          .filter((a) => !a.MessageAttachmentURL)
          .map((a) => ({
            FileName: a.FileName,
            FileData: a.FileData,
            FileMimeType: a.FileMimeType,
            MessageAttachmentURL: undefined,
          })),
        RemoveAttachments: selectMessageAttachmentIdsToRemove(getState()),
      };

      if (JSON.stringify(priorRequest) === JSON.stringify(request)) {
        return;
      }

      dispatch(messageCreateSlice.actions.setSaveMessageDraftApiRequest(request));

      let data: ApiResponse<CreateDraftMessageResult | UpdateDraftMessageResult>;
      if (request.MessageId) {
        data = (await messageService.updateDraftMessage(request)).data;
      } else {
        data = (await messageService.createDraftMessage(request)).data;
      }

      if (data.IsSuccess) {
        dispatch(addAppNotification(NotificationKeys.Default, '', 'Draft saved'));
        successCallback?.(data.ResultObject.MessageId);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        errorCallback?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(messageCreateSlice.actions.setSaveMessageDraftApiRequest(undefined));
    }
  };

/**
 * Sends a request to the api to send a message constructed from the MessageCreateState's properties
 *  (the resetMessageCreate thunk is dispatched if response is successful)
 * @param successCallback - callback method executed if the response is successful
 * @param errorCallback - callback method executed if the repsonse is unsuccessful
 * @returns void
 */
export const sendMessage =
  (
    successCallback?: (messageId: string) => void,
    errorCallback?: (errors: string[]) => void
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
      await dispatch(validateMessage());
      if (!selectCanSendMessage(getState())) {
        return;
      }
      const {
        messageId,
        subject,
        isHighPriority,
        body,
        attachments,
        sendMessageApiRequest: priorRequest,
      } = getState().messageCreate;
      const request: SendMessageRequest = {
        MessageId: messageId,
        Recipients: selectAllMessageRecipients(getState().messageCreate.recipients).map((r) => ({
          MessageRecipientType: r.MessageRecipientType,
          ObjectValue: r.ObjectValue,
        })),
        Subject: subject,
        IsHighPriority: isHighPriority,
        Body: body,
        AddAttachments: attachments
          .filter((a) => !a.MessageAttachmentURL)
          .map((a) => ({
            FileName: a.FileName,
            FileData: a.FileData,
            FileMimeType: a.FileMimeType,
            MessageAttachmentURL: undefined,
          })),
        RemoveAttachments: selectMessageAttachmentIdsToRemove(getState()),
      };

      if (JSON.stringify(priorRequest) === JSON.stringify(request)) {
        return;
      }

      dispatch(messageCreateSlice.actions.setSendMessageApiRequest(request));

      const { data } = await messageService.sendMessage(request);

      if (data.IsSuccess) {
        dispatch(resetMessageCreate());
        successCallback?.(data.ResultObject.MessageId);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        errorCallback?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(messageCreateSlice.actions.setSendMessageApiRequest(undefined));
    }
  };

/**
 * Sends a request to the api to delete the message represented by the value stored as the messageId property of MessageCreateState
 * (the resetMessageCreate and resetMessages thunks are dispatched if response is successful)
 * @param successCallback - callback method executed if the response is successful
 * @param errorCallback - callback method executed if the repsonse is unsuccessful
 * @returns void
 */
export const deleteMessageDraft =
  (
    successCallback?: (messageId: string | undefined) => void,
    errorCallback?: (errors: string[]) => void
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
      const { messageId } = getState().messageCreate;

      if (!messageId) {
        successCallback?.(undefined);
        return;
      }

      const { data } = await messageService.deleteDraftMessage({ MessageId: messageId });

      if (data.IsSuccess) {
        dispatch(resetMessageCreate());
        dispatch(resetMessages());
        successCallback?.(messageId);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        errorCallback?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sends a request to the api to create a new message group composed of the values of MessageCreateState's recipients property
 * (the resetMessageGroups and getMessageGroups thunks are dispatched if the response is successful)
 * @param messageGroupName - the name of the new message group
 * @param successCallback - callback method executed if the response is successful
 * @param errorCallback - callback method executed if the repsonse is unsuccessful
 * @returns void
 */
export const saveRecipientsAsMessageGroup =
  (
    messageGroupName: string,
    successCallback?: (messageGroupId: string) => void,
    errorCallback?: (errors: string[]) => void
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
      if ((await dispatch(validateMessageGroupName(messageGroupName))).length > 0) {
        return;
      }
      const request: CreateMessageGroupRequest = {
        MessageGroupName: messageGroupName,
        Recipients: selectAllMessageRecipients(getState().messageCreate.recipients).map((r) => ({
          MessageGroupRecipientType: r.MessageRecipientType,
          ObjectValue: r.ObjectValue,
        })),
      };

      const data = (await messageGroupService.createMessageGroup(request)).data;

      if (data.IsSuccess) {
        dispatch(addAppNotification(NotificationKeys.Default, '', 'Message Group created'));
        dispatch(resetMessageGroups());
        dispatch(getMessageGroups());
        successCallback?.(data.ResultObject.MessageGroupId);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        errorCallback?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };
