import { Customer } from './customer.models';

//#region Invoice Report Models
export enum InvoiceReportSearchType {
  Standard = 0,
  Comparison = 1,
  Trend = 2,
}

export enum InvoiceReportTrendType {
  Summary = 0,
  Week = 1,
  Month = 2,
}

export enum SearchCriteriaType {
  Customer = 0,
  Invoice = 1,
  Product = 2,
  Manufacturer = 3,
  ProductCategory = 4,
  Brand = 5,
}

export enum SearchKPIType {
  Quantity = 0,
  Sales = 1,
  NetWeight = 2,
  SalesPerCase = 3,
  NetWeightPerCase = 4,
  CatchWeight = 5,
  CatchWeightPerCase = 6,
}

export enum SortOrderType {
  Ascending = 0,
  Descending = 1,
}

export enum SearchKPIDisplayType {
  CurrencyDollars = 0,
  CurrencyPounds = 1,
  WeightPounds = 2,
  Number = 3,
}

export enum TemplateType {
  UsageTrends = 0,
  CategoryUsageYoY = 1,
  PurchaseHistory = 2,
  DescendingDollar = 3,
}

export type TemplateSelectableOptions = {
  dateSelectType: boolean;
  dates: boolean;
  trendTypes: boolean;
  customers: boolean;
  quantity: boolean;
  dollars: boolean;
  weight: boolean;
  canSortFilter: boolean;
  canModifyGroups: boolean;
  canRemoveGroups: boolean;
  canFilter: boolean;
};

export type InvoiceReportSearchHeader = {
  InvoiceReportSearchHeaderId: string;
  SearchName?: string;
  SearchDescription?: string;
  UserId: string;
  IsFavorite: boolean;
  SearchType: InvoiceReportSearchType;
  SearchDirectoryId?: string;
  StartDate: Date | string;
  EndDate: Date | string;
  BreadcrumbDisplay?: string;
  ParentSearchHeaderId?: string;
  IsTemplate: boolean;
  CreateDate: Date | string;
  ChangeDate: Date | string;
  DeleteDate?: Date | string;
};

export type InvoiceReportSearchDirectory = {
  InvoiceReportSearchDirectoryId: string;
  UserId: string;
  FolderName?: string;
  SearchDirectoryParentId?: string;
  ChangeDate: Date | string;
  DeleteDate?: Date | string;
  IsTemplate: boolean;
};

export type InvoiceReportBreadcrumbElement = {
  SearchHeaderId: string;
  BreadcrumbDisplay?: string;
  Sequence: number;
};

export type InvoiceReportKPI = {
  KPIType: SearchKPIType;
  KPIDisplayType: SearchKPIDisplayType;
  KPIValue: number;
};

export type InvoiceReportResultCell = {
  KPIs?: InvoiceReportKPI[];
  ColumnSequence: number;
};

export type InvoiceReportResultRow = {
  CriteriaType: SearchCriteriaType;
  CriteriaValue?: string;
  CriteriaDisplayOne?: string;
  CriteriaDisplayTwo?: string;
  CriteriaDisplayThree?: string;
  CriteriaDisplayFour?: string;
  CriteriaDisplayFive?: string;
  CriteriaDisplaySix?: string;
  CriteriaDisplaySeven?: string;
  Cells?: InvoiceReportResultCell[];
  HasChildRows: boolean;
  ChildRows?: InvoiceReportResultRow[];
  DrilldownDisplay?: string;
  DrilldownParameters?: SearchDrilldownParameter[];
};

export type SearchDrilldown = {
  DrilldownCriteriaType: SearchCriteriaType;
  DrilldownDisplay?: string;
  IsDisabled: boolean;
};

export type SearchCriteriaData = {
  InvoiceReportSearchHeaderId: string;
  SearchName?: string;
  SearchDescription?: string;
  UserId: string;
  IsFavorite: boolean;
  SearchType: InvoiceReportSearchType;
  SearchDirectoryId?: string;
  StartDate: string | Date;
  EndDate: string | Date;
  BreadcrumbDisplay?: string;
  ParentSearchHeaderId?: string;
  IsTemplate: boolean;
  CreateDate: string | Date;
  ChangeDate: string | Date;
  DeleteDate?: string | Date;
  TemplateType?: TemplateType;
  TrendType: InvoiceReportTrendType;
  CompareStartDate?: string | Date;
  CompareEndDate?: string | Date;
  ShowCatchWeight: boolean;
  ShowDollars: boolean;
  ShowNetWeight: boolean;
  ShowQuantity: boolean;
};

export type SearchCriteriaCustomersResultCustomer = {
  Customer: Customer;
  IsSelected: boolean;
};

export type SaveSearchCriteriaResultData = {
  CustomerIds: string[];
  StartDate: string | Date;
  EndDate: string | Date;
  TrendType: InvoiceReportTrendType;
  CompareStartDate?: string | Date;
  CompareEndDate?: string | Date;
  ShowCatchWeight: boolean;
  ShowDollars: boolean;
  ShowNetWeight: boolean;
  ShowQuantity: boolean;
  SearchHeaderId: string;
};

export type SearchGroupBy = {
  GroupByDisplay: string;
  GroupByType: number;
};

export type CriteriaGroupBy = SearchGroupBy & {
  FilterDisplay?: string;
  CanUpdateCriteria: boolean;
};

export type GetSearchCriteriaGroupBysResult = {
  GroupBys: CriteriaGroupBy[];
  CustomerFilterDisplay?: string;
};

export type GenericCriteriaFilterOption = {
  CriteriaType: number;
  CriteriaValue?: string;
  CriteriaDisplayOne?: string;
  CriteriaDisplayTwo?: string;
  CriteriaDisplayThree?: string;
  CriteriaDisplayFour?: string;
  CriteriaDisplayFive?: string;
  CriteriaDisplaySix?: string;
  CriteriaDisplaySeven?: string;
  ConcatenatedDisplay?: string;
  TotalRecords: number;
  IsSelected: boolean;
};

export type ResequenceSearchGroupBy = {
  GroupByType: number;
  Sequence: number;
};

//#endregion

//#region API Request Models

export type BaseSearchRequest = {
  searchHeaderId?: string;
};

export type DeleteSearchHeaderRequest = {
  InvoiceReportSearchHeaderId?: string;
};

export type SaveSearchCriteriaRequest = {
  CustomerIds: string[];
  StartDate: string | Date;
  EndDate: string | Date;
  TrendType: InvoiceReportTrendType;
  CompareStartDate?: string | Date;
  CompareEndDate?: string | Date;
  ShowCatchWeight: boolean;
  ShowDollars: boolean;
  ShowNetWeight: boolean;
  ShowQuantity: boolean;
  SearchHeaderId?: string;
  Name?: string;
  SearchName?: string;
  SearchDescription?: string;
  InvoiceReportSearchDirectoryId?: string;
  IsFavorite?: boolean;
};

export type CreateInvoiceReportSearchDirectoryRequest = {
  FolderName?: string;
  SearchDirectoryParentId?: string;
};

export type UpdateInvoiceReportSearchDirectoryRequest = {
  InvoiceReportSearchDirectoryId: string;
  FolderName?: string;
  SearchDirectoryParentId?: string;
};

export type DeleteInvoiceReportSearchDirectoryRequest = {
  InvoiceReportSearchDirectoryId: string;
};

export type GetSearchResultsRequest = {
  SearchHeaderId: string;
  SortByKPIType?: SearchKPIType;
  SortByOrderType?: SortOrderType;
};

export type SearchDrilldownRequest = {
  SearchHeaderId: string;
  DrilldownDisplay?: string;
  DrilldownGroupByType: SearchCriteriaType;
  DrilldownParameters?: SearchDrilldownParameter[];
};

export type SearchDrilldownParameter = {
  DrilldownCriteriaType: SearchCriteriaType;
  DrilldownCriteriaValue?: string;
};

export type SetSearchAsFavoriteRequest = {
  SearchHeaderId: string;
  SearchDirectoryId?: string;
  SearchName?: string;
  SearchDescription?: string;
  AddNewDirectory: boolean;
  NewDirectoryName?: string;
  EditOriginalSearch: boolean;
};

export type AddSearchCriteriaResultData = {
  SearchType: SearchCriteriaType;
  CustomerIds: string[];
  StartDate: string | Date;
  EndDate: string | Date;
  TrendType: InvoiceReportTrendType;
  CompareStartDate: string | Date;
  CompareEndDate: string | Date;
  ShowCatchWeight: boolean;
  ShowDollars: boolean;
  ShowNetWeight: boolean;
  ShowQuantity: boolean;
  SearchHeaderId: string;
};

export type BaseGroupByRequest = {
  SearchHeaderId: string;
  GroupByType: number;
};

export type GetCriteriaFilterOptionsRequest = {
  searchHeaderId: string;
  searchCriteriaType: number;
};

export type UpdateSearchDetailOptionsBatchOption = {
  SearchHeaderId: string;
  GroupByType: number;
  SelectedOptions: string[];
};

export type UpdateSearchDetailOptionsBatchRequest = {
  Options: UpdateSearchDetailOptionsBatchOption[];
};

export type UpdateSearchDetailOptionsRequest = BaseGroupByRequest & {
  SelectedOptions: string[];
};

export type ResequenceSearchGroupBysRequest = {
  SearchHeaderId: string;
  GroupBys: ResequenceSearchGroupBy[];
};

export type GetInvoiceInsightsReportPDFRequest = {
  SearchHeaderId: string;
  SortByKPIType: SearchKPIType;
  SortByOrderType: SortOrderType;
  TimeZone: string;
};

export type GetInvoiceInsightsReportExcelRequest = {
  SearchHeaderId: string;
  SortByKPIType: SearchKPIType;
  SortByOrderType: SortOrderType;
  TimeZone: string;
};

//#endregion

//#region API Result Models

export type InvoiceReportSearchResultData = {
  SearchHeaderId: string;
  SearchName?: string;
  SearchDirectoryId?: string;
  SortByKpiType: SearchKPIType;
  SortByOrderType: SortOrderType;

  StartDate: string | Date;
  EndDate: string | Date;
  CompareStartDate: string | Date | undefined;
  CompareEndDate: string | Date | undefined;
  SearchType: InvoiceReportSearchType;
  TrendType: InvoiceReportTrendType | undefined;
  TemplateType: TemplateType | undefined;
  IsFavorite: boolean;

  ColumnTitles?: string[];
  BreadcrumbElements?: InvoiceReportBreadcrumbElement[];
  Rows?: InvoiceReportResultRow[];
  SearchKPITypes?: SearchKPIType[];
  DrilldownOptions?: SearchDrilldown[];
  FilteredCount: number;
  TotalCount: number;
  GrandTotals: InvoiceReportResultCell[];
};

export type ResequenceSearchGroupBysResult = ResequenceSearchGroupBysRequest;

//#endregion
