import { ClickAwayListener, Icon, Typography } from '@mui/material';
import { NotificationType } from 'common';
import { CfTooltip } from '../../../../../../cf-ui/Tooltip/CfTooltip';
import {
  NotificationContent,
  NotificationHeader,
} from '../../../../../site/components/notifications/ComponentNotifications/ComponentNotification.styles';
import { useState } from 'react';

interface OrderSummaryValidationMobileProps {
  message?: string;
  title?: string;
  notificationType?: NotificationType;
}

export const OrderSummaryValidationMobile = ({
  message,
  title,
  notificationType,
}: OrderSummaryValidationMobileProps): JSX.Element => {
  const [openTooltip, setOpenTooltip] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
      <NotificationHeader
        $notificationType={notificationType}
        sx={{ mt: { xs: 0, lg: 2 } }}
        onClick={() => setOpenTooltip(!openTooltip)}
      >
        <NotificationContent>
          <Typography variant='paragraphSmallBold'>{title}</Typography>
        </NotificationContent>
        &nbsp;
        <NotificationContent data-testid={`order-summary-validation-banner-${title}`}>
          <CfTooltip
            title={<Typography variant='paragraphSmall'>{message as string}</Typography>}
            placement='bottom'
            enterDelay={200}
            toolTipStyle={{
              maxWidth: '170px',
              margin: '12px',
            }}
            open={openTooltip}
          >
            <Icon
              className='fa-exclamation-circle'
              color='inherit'
              sx={{ textAlign: 'center', fontWeight: '900 !important', fontSize: 12, padding: 0 }}
            />
          </CfTooltip>
        </NotificationContent>
      </NotificationHeader>
    </ClickAwayListener>
  );
};
