import { ServiceResult } from '../models/api-shared.models';
import {
  EditUserCustomerOrderEntrySettingRequest,
  UserCustomerOrderEntrySetting,
} from '../models/user-customer.models';
import ApiService from './api-service';

class UserCustomerService extends ApiService {
  private static classInstance: UserCustomerService;

  private constructor() {
    super('UserCustomer');
  }

  public editUserCustomerSettings(
    request: EditUserCustomerOrderEntrySettingRequest
  ): ServiceResult<UserCustomerOrderEntrySetting> {
    return this.post(`${this.baseUrl}/EditUserCustomerOrderEntrySettings`, request);
  }

  public static getInstance(): UserCustomerService {
    if (!this.classInstance) {
      this.classInstance = new UserCustomerService();
    }
    return this.classInstance;
  }
}

export default UserCustomerService;
