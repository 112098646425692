import { Box, Link } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledFooterSection = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    paddingTop: theme.custom.footerHeights['xs'],
  },
  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.custom.footerHeights['sm'],
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.custom.footerHeights['md'],
  },
  [theme.breakpoints.up('lg')]: {
    paddingTop: theme.custom.footerHeights['lg'],
  },
  [theme.breakpoints.up('xl')]: {
    paddingTop: theme.custom.footerHeights['xl'],
  },
}));

export const StyledFooterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  bottom: 0,
  paddingLeft: 'calc(100vw - 100%)',
  width: '100%',
  backgroundColor: theme.palette.grey[200],
  alignItems: 'center',

  [theme.breakpoints.up('xs')]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  [theme.breakpoints.up('lg')]: {
    maxHeight: theme.custom.footerHeights['lg'],
    height: theme.custom.footerHeights['lg'],
  },
  [theme.breakpoints.up('xl')]: {
    maxHeight: theme.custom.footerHeights['xl'],
    height: theme.custom.footerHeights['xl'],
  },
}));

export const StyledFooterContent = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 32px)',
  maxWidth: theme.custom.contentWidths['xl'],
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  ...theme.typography.responsiveParagraphSmallBold,
  color: theme.palette.common.black,
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));
