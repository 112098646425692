import { CircularProgress } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { FC } from 'react';
import { circularProgressPropsBase } from '../Shared/constants';
import { StyledCenteredBox } from '../Shared/Shared.styles';
import { StyledOverlayBox } from './CfContentLoader.styles';

export interface CfContentLoaderProps {
  isLoading: boolean;
  sx?: SxProps<Theme>;
}

/**
 * Wraps the MUI CircularProgress API so it is centered and styled consistently
 *
 * @param isLoading - Boolean indicating if the component should be hidden
 * @param sx - Additional styling for the overylaying box container
 */
export const CfContentLoader: FC<CfContentLoaderProps> = (props: CfContentLoaderProps) => {
  return (
    <StyledOverlayBox hidden={!props.isLoading} sx={props.sx}>
      <StyledCenteredBox>
        <CircularProgress color='primary' {...circularProgressPropsBase} />
      </StyledCenteredBox>
    </StyledOverlayBox>
  );
};
