import { ParListDetail, ProductListHeaderCustom } from '../api';
import { CatalogProduct } from '../api/models/api-shared.models';
import { OrderEntryProduct } from '../api/models/order-entry-search.models';
import { ProductListProduct } from '../api/models/product-list-search.models';

export type ProductData = ProductListProduct | CatalogProduct | OrderEntryProduct;

export const instanceOfCatalogProduct = (value: ProductData): value is CatalogProduct => {
  return !!value;
};

export interface AddFromListHistoryState {
  productListHeader?: ProductListHeaderCustom;
  fromProductListHeader?: ProductListHeaderCustom;
}

export interface ProductListHistoryState {
  editMode?: boolean;
  manageCategoriesMode?: boolean;
  productListHeader?: ProductListHeaderCustom;
}

export interface ProductListCategoryCustom {
  Id: string;
  ParentId?: string;
  CategoryProductIds: string[];
  ProductListCategoryId: string | undefined;
  CategoryTitle?: string;
  Sequence: number;
  ParentProductListCategoryId?: string;
  Level: number;
  SortValue: number;
  ProductListDetailIds: string[];
  IsCollapsed: boolean;
}
export interface ProductListCategoryProduct {
  Id: string;
  CategoryId: string;
  ProductListDetailId: string;
  ProductListCategoryId: string | undefined;
  Sequence: number;
  ProductKey: string;
}

export interface ProductListGridData {
  category?: ProductListCategoryCustom;
  categoryProduct?: ProductListCategoryProduct;
  product?: ProductListProduct;
}

export interface ParListGridData {
  category?: ProductListCategoryCustom;
  categoryProduct?: ProductListCategoryProduct;
  product?: ProductListProduct;
  parDetail?: ParListDetail[];
}

export type ReplaceMode = 'list' | 'notification';
export type ProductListCatalogSearchMode = 'add' | 'replace';

export interface ReplaceDialogOptions {
  fromProduct?: ProductData;
  toProduct?: ProductData;
  notificationToDismiss?: string;
}
