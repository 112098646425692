import {
  ManageUser,
  UserCustomer,
  UserManagementAdvanceFilter,
  UserManagementFilter,
  UserManagementFilterType,
} from '../api';
export interface UserManagementHistoryState {
  customers?: ManageUser[];
  manageUserGridAdvanceFilter?: UserManagementAdvanceFilter;
  removeUserGridAdvanceFilter?: UserManagementAdvanceFilter;
  isRemoveMultipleUsersMode?: boolean;
  selectedCustomerIds?: string[];
  locationsSelected?: string[];
  removeLocationSelected?: UserCustomer;
  appliedLastActiveOptionForManageUserGridPane?: number;
  appliedLastActiveOptionForRemoveUserGridPane?: number;
}

export function updateUserManagementAdvanceFilterObject(
  advanceFilter: UserManagementAdvanceFilter | undefined,
  filters: UserManagementFilter[]
): UserManagementAdvanceFilter {
  const result: UserManagementAdvanceFilter = {
    IncludeActiveUsers: advanceFilter?.IncludeActiveUsers ?? true,
    IncludePendingUsers: advanceFilter?.IncludePendingUsers ?? true,
    IncludeExpiredUsers: advanceFilter?.IncludeExpiredUsers ?? true,
    IncludeInactiveUsers: advanceFilter?.IncludeInactiveUsers ?? true,
    FilterText: advanceFilter?.FilterText ?? '',
  };
  filters.forEach((filter) => {
    switch (filter.position) {
      case UserManagementFilterType.Status:
        (result[filter.name as keyof UserManagementAdvanceFilter] as unknown) = filter.value;
        break;
    }
  });

  return result;
}
