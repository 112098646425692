import {
  AccountsRecievableStatementsHistoryState,
  AppRoute,
  AppRoutes,
  createOrderEntryHeader,
  getAccountReceivableStatementsURL,
  getInvoicesURL,
  getQueryParamsString,
  InvoiceHistoryState,
  logUserActivity,
  OrderEntryQueryParams,
  RootState,
  RouteName,
  selectUserAreaSetting,
  selectUserFeatureSetting,
  selectUserPermissionSetting,
  setActiveOrderIsFutureSubmission,
  useAppDispatch,
  useAppSelector,
  UserActivityAction,
  UserActivityActionSummary,
  UserActivityPageName,
} from 'common';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { evaluateSwitchFromEditedOrder } from '../../../../../../hooks/orders/order-entry/useSwitchFromEditedOrder';
import { DeviceType, useBreakpoint } from '../../../../../../hooks/useBreakpoint';
import { getResolution } from '../../../../../../utilities/resolution';
import { Menus } from '../../hooks/useHeaderMenus';
import { NavigationBar } from './components/NavigationBar/NavigationBar';
import { NavigationDrawer } from './components/NavigationDrawer/NavigationDrawer';
import { NavButtonOptions, NavigationOptionVariant, NavigationProps, NavMenuConfig } from './NavigationProps';

export const Navigation: FC<NavigationProps> = (props: NavigationProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory<InvoiceHistoryState & AccountsRecievableStatementsHistoryState>();
  const { deviceType } = useBreakpoint();

  const userSite = useAppSelector((s: RootState) => s.user.userSite);
  const selectedCustomer = useAppSelector((s: RootState) => s.customer.selectedCustomer);

  const isOrderEntryEnabled = useAppSelector((s) => selectUserAreaSetting(s).IsOrderEntryEnabled);
  const isInvoicesEnabled = useAppSelector((s) => selectUserAreaSetting(s).IsInvoicesEnabled);

  const canViewInvoiceInsights = useAppSelector((s: RootState) => selectUserFeatureSetting(s).CanViewInvoiceInsights);

  const canViewInvoiceInsightsArea = useAppSelector((s) => selectUserPermissionSetting(s).CanViewInvoiceInsightsArea);
  const canAccessInvoiceArea = useAppSelector((s) => selectUserPermissionSetting(s).CanAccessInvoiceArea);
  const canAccessARStatementArea = useAppSelector((s) => selectUserPermissionSetting(s).CanAccessARStatementArea);
  const canAccessParManagement = useAppSelector((s) => selectUserPermissionSetting(s).CanAccessParManagement);
  const canAccessReports = false;
  const canAccessInventoryManagement = false;
  const canAccessListManagement = useAppSelector((s) => selectUserPermissionSetting(s).CanAccessListMangement);
  const canCreateOrder = useAppSelector((s) => selectUserPermissionSetting(s).CanCreateOrder);
  const canSendMessages = useAppSelector((s) => selectUserPermissionSetting(s).CanSendMessages);
  const canManageUserAccounts = useAppSelector((s) => selectUserPermissionSetting(s).CanManageUserAccounts);

  const handleClickOption = ({ navigationAction, navigationRoute }: NavButtonOptions) => {
    if (history.location.pathname !== navigationRoute) {
      if (navigationRoute) {
        history.push(navigationRoute);
      } else {
        if (navigationAction) navigationAction();
      }
    }
  };

  const handleCreateNewOrderAction = () => {
    dispatch(setActiveOrderIsFutureSubmission(false));
    evaluateSwitchFromEditedOrder(() => {
      const appRoutes = Object.values(AppRoutes);
      const routeFrom = appRoutes.find(
        (appRoute: AppRoute) => appRoute.Path.toLocaleLowerCase() === location.pathname.toLocaleLowerCase()
      );
      dispatch(
        logUserActivity({
          action: UserActivityAction.CreateOrder,
          pageName: routeFrom?.UserActivityPageName || UserActivityPageName.Home,
          actionSummary: UserActivityActionSummary.CreateOrderFromHeader,
          resolution: getResolution(),
        })
      );
      dispatch(
        createOrderEntryHeader((orderId: string) => history.push(`${AppRoutes[RouteName.OrderEntry].Path}/${orderId}`))
      );
    });
  };

  const handleCreateNewItemOrderAction = () => {
    evaluateSwitchFromEditedOrder(() => {
      const appRoutes = Object.values(AppRoutes);
      const routeFrom = appRoutes.find(
        (appRoute: AppRoute) => appRoute.Path.toLocaleLowerCase() === location.pathname.toLocaleLowerCase()
      );
      dispatch(
        logUserActivity({
          action: UserActivityAction.CreateItemOrder,
          pageName: routeFrom?.UserActivityPageName || UserActivityPageName.Home,
          actionSummary: UserActivityActionSummary.CreateItemOrderFromHeader,
          resolution: getResolution(),
        })
      );
      dispatch(
        createOrderEntryHeader((orderId: string) =>
          history.push(
            `${AppRoutes[RouteName.OrderEntry].Path}/${orderId}` +
              getQueryParamsString({ itemOrderEntry: 'true' } as OrderEntryQueryParams)
          )
        )
      );
    });
  };

  let navigationConfiguration: NavMenuConfig[] = [];

  if (userSite && selectedCustomer) {
    const drawerOptions: NavMenuConfig[] =
      deviceType === DeviceType.Mobile
        ? [
            {
              label: 'My Account',
              testId: 'my-account-nav-btn',
              variant: NavigationOptionVariant.Menu,
              menuItems: [
                {
                  label: 'Profile',
                  testId: 'my-account-profile-nav-option',
                  navigationRoute: AppRoutes[RouteName.UserProfile].Path,
                },
                {
                  label: 'Manage users',
                  testId: 'my-account-manage-users-nav-option',
                  navigationRoute: AppRoutes[RouteName.ManageUsers].Path,
                  isDisabled: !canManageUserAccounts,
                },
                {
                  label: 'Communications',
                  testId: 'my-account-communication-nav-option',
                  navigationRoute: AppRoutes[RouteName.Communications].Path,
                },
                {
                  label: 'Message Center',
                  testId: 'my-account-messagecenter-nav-option',
                  navigationRoute: AppRoutes[RouteName.InboxMessages].Path,
                },
                {
                  label: 'Inbox',
                  testId: 'my-account-messagecenter-inbox-nav-option',
                  navigationRoute: AppRoutes[RouteName.InboxMessages].Path,
                  childOption: true,
                },
                {
                  label: 'Sent',
                  isDisabled: !canSendMessages,
                  testId: 'my-account-messagecenter-sent-nav-option',
                  navigationRoute: AppRoutes[RouteName.SentMessages].Path,
                  childOption: true,
                },
                {
                  label: 'Customer support',
                  testId: 'my-account-customersupport-nav-option',
                  navigationRoute: AppRoutes[RouteName.CustomerSupport].Path,
                },
              ],
            },
          ]
        : [];
    navigationConfiguration = [
      {
        label: 'Orders',
        isDisabled: !isOrderEntryEnabled && !isInvoicesEnabled,
        testId: 'orders-nav-btn',
        variant: NavigationOptionVariant.Menu,
        menuItems: [
          {
            label: 'Create new order',
            isDisabled: !canCreateOrder,
            testId: 'orders-createnew-nav-option',
            navigationAction: handleCreateNewOrderAction,
          },
          {
            label: 'Import order',
            isDisabled: !canCreateOrder,
            testId: 'orders-importorder-nav-option',
            navigationRoute: AppRoutes[RouteName.OrderImport].Path,
          },
          {
            label: 'Item order',
            isDisabled: !canCreateOrder,
            testId: 'orders-itemorder-nav-option',
            navigationAction: handleCreateNewItemOrderAction,
          },
          {
            label: 'Repeat order',
            isDisabled: !canCreateOrder,
            testId: 'orders-repeatorder-nav-option',
            navigationRoute: AppRoutes[RouteName.RepeatOrder].Path,
          },

          {
            label: 'Order history',
            isDisabled: !isOrderEntryEnabled && !isInvoicesEnabled,
            testId: 'orders-orderhistory-nav-option',
            navigationRoute: AppRoutes[RouteName.OrderHistory].Path,
          },
        ],
        menuHookValue: Menus.OrdersMenu,
      },
      {
        label: 'Invoices',
        isDisabled: !(
          canAccessInvoiceArea ||
          canAccessARStatementArea ||
          (canViewInvoiceInsights &&
            canViewInvoiceInsightsArea &&
            canAccessInvoiceArea &&
            deviceType !== DeviceType.Mobile)
        ),
        testId: 'invoices-nav-btn',
        variant: NavigationOptionVariant.Menu,
        menuItems: [
          {
            label: 'Invoices',
            testId: 'invoices-invoices-nav-option',
            isDisabled: !canAccessInvoiceArea,
            navigationAction: () => history.push(getInvoicesURL(undefined), { customers: [selectedCustomer] }),
          },
          {
            label: 'A/P statements',
            testId: 'invoices-arstatements-nav-option',
            isDisabled: !canAccessARStatementArea,
            navigationAction: () =>
              history.push(getAccountReceivableStatementsURL(undefined), { customers: [selectedCustomer] }),
          },
          {
            label: 'Invoice insights',
            testId: 'invoices-invoiceinsights-nav-option',
            isDisabled: !(
              canViewInvoiceInsights &&
              canViewInvoiceInsightsArea &&
              canAccessInvoiceArea &&
              deviceType !== DeviceType.Mobile
            ),
            navigationAction: () => history.push(AppRoutes[RouteName.Insights].Path),
          },
        ],
        menuHookValue: Menus.FinancesMenu,
      },
      {
        label: 'Lists',
        isDisabled: !canAccessListManagement,
        testId: 'list-mgmt-nav-btn',
        variant: NavigationOptionVariant.Button,
        navigationAction: () => {
          if (history.location.pathname !== AppRoutes[RouteName.ProductListManagement].Path)
            history.push(AppRoutes[RouteName.ProductListManagement].Path);
        },
      },
      {
        label: 'Inventory',
        isDisabled: !(canAccessParManagement || canAccessReports || canAccessInventoryManagement),
        testId: 'inventory-nav-btn',
        variant: NavigationOptionVariant.Menu,
        menuItems: [
          {
            label: 'Inventory management',
            testId: 'inventory-management-nav-option',
            isDisabled: !canAccessInventoryManagement,
          },
          {
            label: 'Par management',
            testId: 'inventory-par-management-nav-option',
            isDisabled: !canAccessParManagement,
            navigationRoute: AppRoutes[RouteName.ParManagement].Path,
          },
          {
            label: 'Reports',
            testId: 'inventory-reports-nav-option',
            isDisabled: !canAccessReports,
          },
        ],
        menuHookValue: Menus.InventoryMenu,
      },
      // {
      //   label: 'Recipes',
      //   disabled: !userSite.BusinessUnitSetting.CanViewRecipes,
      //   testId: 'recipes-nav-btn',
      //   variant: NavigationOptionVariant.Menu,
      //   menuItems: [],
      // },
      // {
      //   label: 'Promotion',
      //   disabled: !userSite.BusinessUnitSetting.CanViewPromotionsAndRewards,
      //   testId: 'promos-rewards-nav-btn',
      //   variant: NavigationOptionVariant.Menu,
      //   menuItems: [],
      // },
      ...(props.variant === 'drawer' ? drawerOptions : []),
    ];
  }

  return (
    <>
      {props.variant === 'bar' ? (
        <NavigationBar navigationConfig={navigationConfiguration} onClickOption={handleClickOption} />
      ) : (
        <NavigationDrawer navigationConfig={navigationConfiguration} onClickOption={handleClickOption} />
      )}
    </>
  );
};
