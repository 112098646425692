import { ServiceResult } from '../models/api-shared.models';
import { OverviewSlideGroups } from '../models/customer-support.enums';
import { GetOverviewSlidesRequest, Slide, UpdateOverviewViewStatusRequest } from '../models/customer-support.models';
import ApiService from './api-service';

class CustomerSupportService extends ApiService {
  private static classInstance: CustomerSupportService;

  private constructor() {
    super('CustomerSupport');
  }

  public getOverviewSlides(request: GetOverviewSlidesRequest): ServiceResult<Slide[]> {
    return this.get(`${this.baseUrl}/GetOverviewSlides`, request);
  }

  public getOverviewViewedSlideGroups(): ServiceResult<OverviewSlideGroups[]> {
    return this.get(`${this.baseUrl}/GetOverviewViewedSlideGroups`);
  }

  public updateOverviewViewStatus(request: UpdateOverviewViewStatusRequest): ServiceResult<number[]> {
    return this.post(`${this.baseUrl}/UpdateOverviewViewStatus`, request);
  }

  public static getInstance(): CustomerSupportService {
    if (!this.classInstance) {
      this.classInstance = new CustomerSupportService();
    }
    return this.classInstance;
  }
}

export default CustomerSupportService;
