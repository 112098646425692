import { Typography } from '@mui/material';
import { RootState, useAppSelector } from 'common';
import { useEffect, useState } from 'react';
import { CfDialog } from '../../../../cf-ui/dialogs/Dialog/CfDialog';

const InformationDialog = (): JSX.Element => {
  const title = useAppSelector((x: RootState) => x.global.informationDialogTitle);
  const messages = useAppSelector((x: RootState) => x.global.informationDialogMessages);

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (messages.length > 0) setOpen(true);
  }, [messages, title]);

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <CfDialog isOpen={open} title={title} onClose={closeDialog}>
      {messages.map((msg, idx) => (
        <Typography
          key={`info-msg-${idx}`}
          variant='paragraph'
          data-testid={`alert-dialog-info-${idx}`}
          sx={{ whiteSpace: 'pre-wrap' }}
        >
          {msg}
        </Typography>
      ))}
    </CfDialog>
  );
};

export default InformationDialog;
