import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { ParListDetail } from '../../api';
import { ParListHeader } from '../../api/models/par-list-header.models';
import { RootState } from '../store';

// Adapters
const parListHeadersAdapter = createEntityAdapter<ParListHeader>({
  selectId: (header: ParListHeader) => header.ParListHeaderId.toString(),
});

const parListDetailsByProductAdapter = createEntityAdapter<ParListDetail>({
  selectId: (parListDetail: ParListDetail) => `${parListDetail.ProductKey}-${parListDetail.UnitOfMeasure}`,
});

// State
interface ParManagementState {
  isLoadingParListHeaders: boolean;
  parListHeaders: EntityState<ParListHeader>;
  isLoadingCreateCopyList: boolean;
  isLoadingUpdateParListHeader: boolean;
  isLoadingDeleteList: boolean;
  isLoadingParDetailsByProduct: boolean;
  parListDetailsByProduct: EntityState<ParListDetail>;
}

const initialState: ParManagementState = {
  isLoadingParListHeaders: true,
  parListHeaders: parListHeadersAdapter.getInitialState(),
  isLoadingCreateCopyList: false,
  isLoadingUpdateParListHeader: false,
  isLoadingDeleteList: false,
  isLoadingParDetailsByProduct: true,
  parListDetailsByProduct: parListDetailsByProductAdapter.getInitialState(),
};

// Reducers
export const parManagementSlice = createSlice({
  name: 'parManagement',
  initialState: initialState,
  reducers: {
    setIsLoadingParListHeaders: (state: ParManagementState, action: PayloadAction<boolean>) => {
      state.isLoadingParListHeaders = action.payload;
    },
    setParListHeaders: (state: ParManagementState, action: PayloadAction<ParListHeader[]>) => {
      parListHeadersAdapter.setAll(state.parListHeaders, action.payload);
    },
    resetParListHeaders: (state: ParManagementState) => {
      state.parListHeaders = initialState.parListHeaders;
    },
    setIsLoadingCreateCopyList: (state: ParManagementState, action: PayloadAction<boolean>) => {
      state.isLoadingCreateCopyList = action.payload;
    },
    setIsLoadingUpdateParListHeader: (state: ParManagementState, action: PayloadAction<boolean>) => {
      state.isLoadingUpdateParListHeader = action.payload;
    },
    setIsLoadingDeleteList: (state: ParManagementState, action: PayloadAction<boolean>) => {
      state.isLoadingDeleteList = action.payload;
    },
    addParListHeader: (state: ParManagementState, action: PayloadAction<ParListHeader>) => {
      parListHeadersAdapter.addOne(state.parListHeaders, action.payload);
    },
    deleteParListHeader: (state: ParManagementState, action: PayloadAction<string>) => {
      parListHeadersAdapter.removeOne(state.parListHeaders, action.payload);
    },
    updateParList: (state: ParManagementState, action: PayloadAction<ParListHeader>) => {
      parListHeadersAdapter.updateOne(state.parListHeaders, {
        id: action.payload.ParListHeaderId.toString(),
        changes: { ParListTitle: action.payload.ParListTitle },
      });
    },
    resetParListHeader: (state: ParManagementState) => {
      state.parListHeaders = initialState.parListHeaders;
    },
    setIsLoadingParListDetailsByProduct: (state: ParManagementState, action: PayloadAction<boolean>) => {
      state.isLoadingParDetailsByProduct = action.payload;
    },
    setParListDetailsByProduct: (state: ParManagementState, action: PayloadAction<ParListDetail[]>) => {
      parListDetailsByProductAdapter.setMany(state.parListDetailsByProduct, action.payload);
    },
    resetParListDetailsByProduct: (state: ParManagementState) => {
      state.parListDetailsByProduct = initialState.parListDetailsByProduct;
    },
  },
});

const getState = (state: RootState): RootState => state;
const getProductKey = (state: RootState, key: string): string => key;

// Selectors
export const { selectAll: selectAllParListHeaders, selectById: selectParListHeaderById } =
  parListHeadersAdapter.getSelectors<RootState>((state: RootState) => state.parManagement.parListHeaders);

export const { selectAll: selectAllParDetailsByProduct, selectById: selectParDetailsByProductById } =
  parListDetailsByProductAdapter.getSelectors<RootState>(
    (state: RootState) => state.parManagement.parListDetailsByProduct
  );

//Custom selectors
export const selectParDetailsByProductByIdWithUOM = createSelector([getState, getProductKey], (state, productKey) => {
  const result: ParListDetail[] = [];
  const allParDetail = selectAllParDetailsByProduct(state);
  allParDetail.forEach((parDetail) => {
    if (parDetail.ProductKey === productKey) {
      result.push(parDetail);
    }
  });
  return result;
});
