import { getOrderCart, RootState, selectUserAreaSetting, useAppDispatch, useAppSelector } from 'common';
import { FC, useEffect, useState } from 'react';
import { Menus, setMenu, useHeaderMenus } from '../../hooks/useHeaderMenus';
import { CartDrawer } from './CartDrawer/CartDrawer';
import { CartMenu } from './CartMenu/CartMenu';

export type CartVariant = 'menu' | 'drawer';

export interface CartProps {
  variant: CartVariant;
}

const Cart: FC<CartProps> = (props: CartProps) => {
  const dispatch = useAppDispatch();
  const { openedMenu } = useHeaderMenus();

  const isLoading = useAppSelector((s: RootState) => s.orders.cartLoading);
  const activeOrder = useAppSelector((s: RootState) => s.orders.activeOrder);
  const cartData = useAppSelector((s: RootState) => s.orders.orderCart);

  const isOrderEntryEnabled = useAppSelector((s) => selectUserAreaSetting(s).IsOrderEntryEnabled);

  const [cartChanged, setCartChanged] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      closeShoppingCart(); //Cleanup on dismount
    };
  }, []);

  useEffect(() => {
    if (activeOrder) {
      setCartChanged(true);
    }
  }, [activeOrder]);

  const closeShoppingCart = () => {
    setMenu(Menus.None);
  };

  const handleMenuOpen = () => {
    setMenu(Menus.CartMenu);
    if (cartChanged) {
      dispatch(getOrderCart());
      setCartChanged(false);
    }
  };

  if (!isOrderEntryEnabled) return <></>;
  if (props.variant === 'menu') {
    return (
      <CartMenu
        closeShoppingCart={closeShoppingCart}
        onMenuOpen={handleMenuOpen}
        onMenuClose={closeShoppingCart}
        activeOrder={activeOrder}
        cartData={cartData}
        isLoading={isLoading}
        onClickDelete={closeShoppingCart}
      />
    );
  } else {
    return (
      <CartDrawer
        onClose={closeShoppingCart}
        isOpen={openedMenu === Menus.CartMenu}
        onDrawerOpen={handleMenuOpen}
        onDrawerClose={closeShoppingCart}
        activeOrder={activeOrder}
        cartData={cartData}
        isLoading={isLoading}
      />
    );
  }
};

export default Cart;
