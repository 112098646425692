import { CustomerSupportType, OverviewSlideGroups } from '../../../api/models/customer-support.enums';
import { CustomerSupportTutorialItem } from '../../../models';

export const orderingTutorials: CustomerSupportTutorialItem[] = [
  {
    tutorialTitle: 'Creating a new order',
    tutorialText: 'Learn about how to create, edit, review and submit an order.',
    customerSupportType: CustomerSupportType.Ordering,
    overviewGroup: OverviewSlideGroups.NewOrder,
  },
  {
    tutorialTitle: 'Importing an order',
    tutorialText:
      'See how to import orders from a spreadsheet, download a template file or use a delimited text format file. ',
    customerSupportType: CustomerSupportType.Ordering,
    overviewGroup: OverviewSlideGroups.ImportOrder,
  },
  {
    tutorialTitle: 'Item order',
    tutorialText: 'Learn how to enter your orders by entering Product, Manufacturer or Custom ID. ',
    customerSupportType: CustomerSupportType.Ordering,
    overviewGroup: OverviewSlideGroups.ItemEntry,
  },
];
