import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { History } from 'history';
import { getResolution } from 'web/src/utilities/resolution';
import {
  ReorderDeliveryRequest,
  ReorderInvoiceRequest,
  ReorderOrderConfirmationRequest,
  ReorderSubmittedOrderRequest,
} from '../../api/models/reorder.models';
import DeliveryService from '../../api/services/delivery.service';
import InvoiceService from '../../api/services/invoice.service';
import OrderConfirmationService from '../../api/services/order-confirmation.service';
import SubmittedOrderService from '../../api/services/submitted-order.service';
import { getRouteName } from '../../helpers';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import {
  AppRoutes,
  RouteName,
  UserActivityAction,
  UserActivityActionSummary,
  UserActivityPageName,
} from '../../models';
import { logUserActivity, setErrorDialogContent } from '../common';
import { AppDispatch, AppThunk, RootState, store } from '../store';
import { orderReviewSlice } from './order-review.slice';
import { setActiveCartById, updateCurrentOrderEntryHeaderName, updateOrderDeliveryDate } from './orders.thunks';
import { reorderSlice } from './reorder.slice';

//service
const reorderDeliveryService = DeliveryService.getInstance();
const reorderInvoiceService = InvoiceService.getInstance();
const reorderSubmittedService = SubmittedOrderService.getInstance();
const reorderOrderConfirmationService = OrderConfirmationService.getInstance();
const errorDialogTitle = 'Repeat order unavailable';

// hooks
const appInsightsLogger = useAppInsightsLogger();

/**
 * Navigates the user to the order review page
 *
 * @param history - Used to change the URL
 * @param customerId - Id to call on the correct order reivew
 * @param orderEntryHeaderId - Id to call on the correct order reivew
 * @returns NULL
 */
const navigateToRepeatOrderReview = (history: History, customerId: string, orderEntryHeaderId: string) => {
  history.push(`${AppRoutes[RouteName.OrderReview].Path}/${customerId}/${orderEntryHeaderId}`);
};

/**
 * Logs the repeat order activity
 *
 * @param pageName - The name of the page that that activity occurs on
 * @returns NULL
 */
export const logRepeatOrder =
  (pageName: UserActivityPageName): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(
      logUserActivity({
        action: UserActivityAction.RepeatOrder,
        pageName: pageName,
        actionSummary: UserActivityActionSummary.CreateRepeatOrder,
        resolution: getResolution(),
        customerId: getState().customer.selectedCustomer?.CustomerId,
      })
    );
  };

/**
 * Calls and stores the result of the ReorderDelivery API call
 *
 * @param deliveryRequest - The information needed to make a request to the API
 * @param history - Used to navigate to the order review page
 * @returns NULL
 */
export const reorderDelivery =
  (deliveryRequest: ReorderDeliveryRequest, history: History): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(logRepeatOrder(UserActivityPageName.DeliveryDetails));
      dispatch(reorderSlice.actions.setIsReorderLoading(true));
      dispatch(reorderSlice.actions.setIsProductsNotFoundDialogLoading(true));

      const { data } = await reorderDeliveryService.reorderDelivery(deliveryRequest);

      if (data.IsSuccess) {
        dispatch(reorderSlice.actions.setReorderResult(data.ResultObject));
        dispatch(orderReviewSlice.actions.setProductListLoading(true));
        await dispatch(
          setActiveCartById(data.ResultObject.OrderEntryHeaderId, () => {
            navigateToRepeatOrderReview(history, deliveryRequest.CustomerId, data.ResultObject.OrderEntryHeaderId);
            if (data.ResultObject.ProductsNotFound.length > 0) {
              dispatch(toggleProductNotFoundDialog(true));
            }
          })
        );
      } else if (!data.IsSuccess) {
        store.dispatch(setErrorDialogContent(errorDialogTitle, data.ErrorMessages));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(reorderSlice.actions.setIsReorderLoading(false));
      dispatch(reorderSlice.actions.setIsProductsNotFoundDialogLoading(false));
    }
  };

/**
 * Calls and stores the result of the ReorderInvoice API call
 *
 * @param invoiceRequest - The information needed to make a request to the API
 * @param history - Used to navigate to the order review page
 * @returns NULL
 */
export const reorderInvoice =
  (invoiceRequest: ReorderInvoiceRequest, history: History): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(logRepeatOrder(UserActivityPageName.InvoiceDetail));
      dispatch(reorderSlice.actions.setIsReorderLoading(true));
      dispatch(reorderSlice.actions.setIsProductsNotFoundDialogLoading(true));

      const { data } = await reorderInvoiceService.reorderInvoice(invoiceRequest);

      if (data.IsSuccess) {
        dispatch(reorderSlice.actions.setReorderResult(data.ResultObject));

        dispatch(orderReviewSlice.actions.setProductListLoading(true));
        await dispatch(
          setActiveCartById(data.ResultObject.OrderEntryHeaderId, () => {
            navigateToRepeatOrderReview(history, invoiceRequest.CustomerId, data.ResultObject.OrderEntryHeaderId);
            if (data.ResultObject.ProductsNotFound.length > 0) {
              dispatch(toggleProductNotFoundDialog(true));
            }
          })
        );
      } else if (!data.IsSuccess) {
        store.dispatch(setErrorDialogContent(errorDialogTitle, data.ErrorMessages));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(reorderSlice.actions.setIsReorderLoading(false));
      dispatch(reorderSlice.actions.setIsProductsNotFoundDialogLoading(false));
    }
  };

/**
 * Calls and stores the result of the ReorderOrderConfirmation API call
 *
 * @param orderConfirmationRequest - The information needed to make a request to the API
 * @param successCallback - Method to call on success
 * @param newDate - The new delivery date to reorder the order with
 * @returns NULL
 */
export const reorderOrderConfirmation =
  (
    orderConfirmationRequest: ReorderOrderConfirmationRequest,
    successCallback: (orderId: string) => void,
    newDate?: string,
    newHeaderName?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(logRepeatOrder(UserActivityPageName.OrderConfirmation));
      dispatch(reorderSlice.actions.setIsReorderLoading(true));
      dispatch(reorderSlice.actions.setIsProductsNotFoundDialogLoading(true));

      const { data } = await reorderOrderConfirmationService.reorderOrderConfirmation(orderConfirmationRequest);

      if (data.IsSuccess) {
        dispatch(reorderSlice.actions.setReorderResult(data.ResultObject));

        dispatch(orderReviewSlice.actions.setProductListLoading(true));

        await dispatch(
          setActiveCartById(data.ResultObject.OrderEntryHeaderId, async () => {
            if (newDate) {
              await dispatch(updateOrderDeliveryDate(newDate, getRouteName()));
            }
            if (newHeaderName) {
              await dispatch(updateCurrentOrderEntryHeaderName(newHeaderName));
            }
            successCallback(data.ResultObject.OrderEntryHeaderId);
            if (data.ResultObject.ProductsNotFound.length > 0) {
              dispatch(toggleProductNotFoundDialog(true));
            }
          })
        );
      } else if (!data.IsSuccess) {
        store.dispatch(setErrorDialogContent(errorDialogTitle, data.ErrorMessages));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(reorderSlice.actions.setIsReorderLoading(false));
      dispatch(reorderSlice.actions.setIsProductsNotFoundDialogLoading(false));
    }
  };

/**
 * Calls and stores the result of the ReorderSubmittedOrder API call
 *
 * @param submittedOrderRequest - The information needed to make a request to the API
 * @param history - Used to navigate to the order review page
 * @returns NULL
 */
export const reorderSubmittedOrder =
  (submittedOrderRequest: ReorderSubmittedOrderRequest, history: History): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(
        logRepeatOrder(
          history.location.pathname.includes(AppRoutes[RouteName.RepeatOrder].Path)
            ? UserActivityPageName.RepeatOrder
            : UserActivityPageName.OrderDetails
        )
      );
      dispatch(reorderSlice.actions.setIsReorderLoading(true));
      dispatch(reorderSlice.actions.setIsProductsNotFoundDialogLoading(true));

      const { data } = await reorderSubmittedService.reorderSubmittedOrder(submittedOrderRequest);

      if (data.IsSuccess) {
        dispatch(reorderSlice.actions.setReorderResult(data.ResultObject));

        dispatch(orderReviewSlice.actions.setProductListLoading(true));
        await dispatch(
          setActiveCartById(data.ResultObject.OrderEntryHeaderId, () => {
            navigateToRepeatOrderReview(
              history,
              submittedOrderRequest.CustomerId,
              data.ResultObject.OrderEntryHeaderId
            );
            if (data.ResultObject.ProductsNotFound.length > 0) {
              dispatch(toggleProductNotFoundDialog(true));
            }
          })
        );
      } else if (!data.IsSuccess) {
        store.dispatch(setErrorDialogContent(errorDialogTitle, data.ErrorMessages));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(reorderSlice.actions.setIsReorderLoading(false));
      dispatch(reorderSlice.actions.setIsProductsNotFoundDialogLoading(false));
    }
  };

/**
 * Resets the isProductsNotFoundDialogLoading, isReorderLoading, showProductsNotFoundDialog and reorderResult values in the reorder slice to their initial values
 *
 * @returns NULL
 */
export const resetReorderState = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(reorderSlice.actions.resetReorderState());
};

/**
 * Sets the showProductsNotFoundDialog value in the reorder slice
 *
 * @param open - The value showProductsNotFoundDialog will be set to
 * @returns NULL
 */
export const toggleProductNotFoundDialog =
  (open: boolean): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(reorderSlice.actions.setShowProductsNotFoundDialog(open));
  };
