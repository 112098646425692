import { Box, Stack, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import { customerSupportSlice, RootState, updateViewedSlideGroup, useAppDispatch, useAppSelector } from 'common';
import React, { FC, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { CfContainedButton } from '../../../../../../cf-ui/buttons/CfContainedButton';
import { StyledBasicDialog } from '../../../../../../cf-ui/dialogs/common/Shared.styles';
import { CfContentLoader } from '../../../../../../cf-ui/Loader/ContentLoader/CfContentLoader';
import { DeviceType, useBreakpoint } from '../../../../../../hooks/useBreakpoint';
import CustomerSupportOverviewSlideIndicator from './components/CustomerSupportOverviewSlideIndicator';
import CustomerSupportOverviewSlides from './components/CustomerSupportOverviewSlides';

const CustomerSupportOverviewDialog: FC = () => {
  const { deviceType } = useBreakpoint();
  const dispatch = useAppDispatch();

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  const isOverviewDialogOpen = useAppSelector((s: RootState) => s.customerSupport.showOverviewDialog);
  const isOverviewDialogLoading = useAppSelector((s: RootState) => s.customerSupport.isOverviewDialogLoading);
  const slides = useAppSelector((s: RootState) => s.customerSupport.currentSlides);
  const currentSlideGroup = useAppSelector((s: RootState) => s.customerSupport.currentSlideGroup);

  useEffect(() => {
    setCurrentSlideIndex(0);
  }, [slides]);

  const handleSkipClick = () => {
    dispatch(customerSupportSlice.actions.setShowSkipDialog(true));
  };

  const handlePreviousClick = (event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
    if ('key' in event && event.key === 'Enter') {
      event.preventDefault();
    }
    if (currentSlideIndex > 0) {
      setCurrentSlideIndex((currentSlideIndex) => --currentSlideIndex);
    }
  };

  const handleNextClick = (event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
    if ('key' in event && event.key === 'Enter') {
      event.preventDefault();
    }
    if (slides && currentSlideGroup !== undefined) {
      if (currentSlideIndex < slides.length - 1) {
        setCurrentSlideIndex((currentSlideIndex) => ++currentSlideIndex);
      } else if (currentSlideIndex === slides.length - 1) {
        dispatch(updateViewedSlideGroup(currentSlideGroup));
        dispatch(customerSupportSlice.actions.resetSlideGroup());
      }
    }
  };

  const markDown: string | undefined = slides && slides[currentSlideIndex].Body.replace('<br>', '\n &nbsp;  \n &nbsp;');

  return (
    <StyledBasicDialog
      aria-labelledby='dialog'
      data-testid='overview-tutorial-dialog'
      open={isOverviewDialogOpen}
      PaperProps={{
        sx: {
          height: 'auto',
          width: '100%',
          maxWidth: {
            xs: 340,
            lg: 624,
            xl: 1074,
          },
        },
      }}
      sx={{
        mx: { xs: -2, lg: 0 },
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: 'minmax(0px, min-content) min-content',
          overflow: 'hidden',
        }}
      >
        <CfContentLoader
          isLoading={isOverviewDialogLoading && !slides && !currentSlideGroup}
          sx={{ borderRadius: (theme) => `${theme.shape.borderRadius}px` }}
        />
        {slides && (
          <>
            <CustomerSupportOverviewSlides slides={slides} currentIndex={currentSlideIndex} />

            <Stack sx={{ gap: 1, p: 2 }}>
              <CustomerSupportOverviewSlideIndicator indicators={slides.length} currentIndex={currentSlideIndex} />

              <Stack sx={{ gap: 1, minHeight: { xs: 170, lg: 123, xl: 131 }, pt: 0.5 }}>
                <Typography
                  data-testid='overview-dialog-title'
                  variant={deviceType === DeviceType.Mobile ? 'mobileLabel' : 'h3'}
                >
                  {slides[currentSlideIndex]?.Title ?? ''}
                </Typography>
                {markDown && (
                  <Typography
                    variant='responsiveParagraph'
                    sx={{ a: { color: (theme: Theme) => theme.palette.primary.main }, ul: { pl: 2.5 } }}
                  >
                    <ReactMarkdown>{markDown}</ReactMarkdown>
                  </Typography>
                )}
              </Stack>

              <Stack
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  gap: 1,
                  width: '100%',
                  mt: 1,
                }}
              >
                {currentSlideIndex !== slides.length - 1 && (
                  <CfContainedButton
                    color='secondary'
                    onClick={handleSkipClick}
                    data-testid='skip-overview-btn'
                    sx={{ p: 0, width: { xs: '132px', xl: '120px' } }}
                  >
                    Skip overview
                  </CfContainedButton>
                )}
                {currentSlideIndex !== 0 && (
                  <CfContainedButton
                    color='secondary'
                    onClick={handlePreviousClick}
                    data-testid='overview-dialog-prev-btn'
                  >
                    Previous
                  </CfContainedButton>
                )}
                <CfContainedButton color='primary' onClick={handleNextClick} data-testid='overview-dialog-next-btn'>
                  {currentSlideIndex === slides.length - 1 ? 'Done' : 'Next'}
                </CfContainedButton>
              </Stack>
            </Stack>
          </>
        )}
      </Box>
    </StyledBasicDialog>
  );
};
export default CustomerSupportOverviewDialog;
