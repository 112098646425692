import { ActionType, ProductListNotificationType } from './api-shared.enums';
import { CatalogProduct } from './api-shared.models';

export interface ProductListNotification {
  ProductListNotificationId: string;
  ActionType: ActionType;
  ActionDate: Date | string;
  Product: CatalogProduct;
  ReplacedWithProduct: CatalogProduct;
  ProductListTitle?: string;
  CanViewOnly: boolean;
}

export interface ProductListNotificationGroup {
  IsActionNeeded: boolean;
  NotificationType: ProductListNotificationType;
  Notifications: ProductListNotification[];
}

export interface ExtendedProductListNotification extends ProductListNotification {
  IsActionNeeded: boolean;
  NotificationType: ProductListNotificationType;
}

export const ProductListNotificationTypes: Record<ProductListNotificationType, { display: string; message?: string }> =
  {
    [ProductListNotificationType.DiscontinuedProduct]: {
      display: 'Discontinued products',
    },
    [ProductListNotificationType.ProductAddedToManagedList]: {
      display: 'Product(s) added to managed lists',
      message: 'Add these products to your custom lists to keep them in sync',
    },
    [ProductListNotificationType.ProductRemovedFromManagedList]: {
      display: 'Product(s) removed from managed lists',
      message: 'Remove these products from your custom lists to keep them in sync',
    },
    [ProductListNotificationType.ProductRecentlyNotPurchased]: {
      display: 'Product not purchased in 13 months',
    },
    [ProductListNotificationType.ForcedSubstitution]: {
      display: 'Forced Substitution',
    },
    [ProductListNotificationType.FindAndReplace]: {
      display: 'Find and Replace',
      message: 'This item has been replaced on all of your custom lists',
    },
  };

export interface ProductListNotificationTotal {
  IsActionNeeded: boolean;
  NotificationCount: number;
}

export type UpdateProductListNotificationRequest = {
  ProductListHeaderId: string;
  CustomerId: string;
  DisableNotifications: boolean;
};
