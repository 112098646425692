import { FileResult, ServiceResult } from '../models/api-shared.models';
import {
  GetInvoiceInsightsReportExcelRequest,
  GetInvoiceInsightsReportPDFRequest,
} from '../models/invoice-report.models';
import ApiService from './api-service';

class InvoiceInsightsReportService extends ApiService {
  private static classInstance: InvoiceInsightsReportService;

  private constructor() {
    super('InvoiceInsightsReport');
  }

  public getInvoiceInsightsReportExcel(request: GetInvoiceInsightsReportExcelRequest): ServiceResult<FileResult> {
    return this.get(`${this.persistUrl}/GetInvoiceInsightsReportExcel`, request);
  }

  public getInvoiceInsightsReportPDF(request: GetInvoiceInsightsReportPDFRequest): ServiceResult<FileResult> {
    return this.get(`${this.persistUrl}/GetInvoiceInsightsPDFReport`, request);
  }

  public static getInstance(): InvoiceInsightsReportService {
    if (!this.classInstance) {
      this.classInstance = new InvoiceInsightsReportService();
    }
    return this.classInstance;
  }
}

export default InvoiceInsightsReportService;
