import { Button, Grid, Icon, Typography } from '@mui/material';
import { RootState, useAppSelector } from 'common';
import { FC } from 'react';
import { CfTooltip } from '../../../../../../cf-ui/Tooltip/CfTooltip';
import { DeviceType, useBreakpoint } from '../../../../../../hooks/useBreakpoint';

export interface LocationBarMobileProps {
  onClick?: () => void;
  iconClassName: string;
  dataTestId?: string;
  setRef?: (instance: HTMLSpanElement | null) => void;
  toolTipTitle?: React.ReactNode;
  forcedToggleState?: boolean;
  caretUpIcon?: string;
  caretDownIcon?: string;
}

export const LocationBarMobile: FC<LocationBarMobileProps> = (props: LocationBarMobileProps) => {
  const { deviceType } = useBreakpoint();
  const selectedCustomer = useAppSelector((s: RootState) => s.customer.selectedCustomer);
  return (
    <Button
      variant='text'
      disableRipple={true}
      disableElevation={true}
      sx={{
        color: 'black',
        minWidth: { xs: 23, lg: 30 },
        borderRadius: 0,
      }}
      onClick={() => props.onClick?.()}
    >
      {props.toolTipTitle ? (
        <CfTooltip title={props.toolTipTitle} placement='left' enterDelay={200}>
          <>
            <Icon className={props.iconClassName} fontSize='medium' sx={{ pl: 1 }} />
            {deviceType === DeviceType.Tablet && (
              <Grid
                container
                sx={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  pl: 1,
                }}
              >
                <Grid item sx={{ maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  <Typography
                    component='span'
                    display='inline'
                    sx={{ typography: { xs: 'mobileParagraphBold', xl: 'paragraphBold' } }}
                  >
                    {`${selectedCustomer?.CustomerName}`}
                  </Typography>
                </Grid>
                <Grid item sx={{ maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  <Typography
                    component='span'
                    display='inline'
                    sx={{ typography: { xs: 'mobileParagraphSmall', xl: 'paragraph' } }}
                  >{`${selectedCustomer?.CustomerAddress}, ${selectedCustomer?.CustomerLocation} ${selectedCustomer?.CustomerZip}`}</Typography>
                  <Typography
                    component='span'
                    display='inline'
                    sx={{ typography: { xs: 'mobileParagraphSmall', xl: 'paragraph' }, pr: 1, pl: 1 }}
                  >
                    |
                  </Typography>
                  <Typography
                    component='span'
                    display='inline'
                    sx={{ typography: { xs: 'mobileParagraphSmall', xl: 'paragraph' } }}
                  >{`${selectedCustomer?.OperationCompanyName} - ${selectedCustomer?.CustomerNumber}`}</Typography>
                </Grid>
              </Grid>
            )}
            {deviceType === DeviceType.Mobile && (
              <Grid
                container
                sx={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  pl: 1,
                }}
              >
                <Grid item sx={{ maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  <Typography
                    component='span'
                    display='inline'
                    sx={{ typography: { xs: 'mobileParagraphBold', xl: 'paragraphBold' } }}
                  >
                    {`${selectedCustomer?.CustomerName}`}
                  </Typography>
                </Grid>
                <Grid item sx={{ maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  <Typography
                    component='span'
                    display='inline'
                    sx={{ typography: { xs: 'mobileParagraphSmall', xl: 'paragraph' } }}
                  >{`${selectedCustomer?.CustomerAddress}, ${selectedCustomer?.CustomerLocation} ${selectedCustomer?.CustomerZip}`}</Typography>
                </Grid>
                <Grid item sx={{ maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  <Typography
                    component='span'
                    display='inline'
                    sx={{ typography: { xs: 'mobileParagraphSmall', xl: 'paragraph' } }}
                  >{`${selectedCustomer?.OperationCompanyName} - ${selectedCustomer?.CustomerNumber}`}</Typography>
                </Grid>
              </Grid>
            )}
            {props.forcedToggleState ? (
              <Icon className={props.caretUpIcon} fontSize='medium' sx={{ pr: 2, pl: 1 }} />
            ) : (
              <Icon className={props.caretDownIcon} fontSize='medium' sx={{ pr: 2, pl: 1 }} />
            )}
          </>
        </CfTooltip>
      ) : (
        <>
          <Icon className={props.iconClassName} fontSize='medium' sx={{ pl: 1 }} />
          {deviceType === DeviceType.Tablet && (
            <Grid
              container
              sx={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                pl: 1,
              }}
            >
              <Grid item sx={{ maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                <Typography
                  component='span'
                  display='inline'
                  sx={{ typography: { xs: 'mobileParagraphBold', xl: 'paragraphBold' } }}
                >
                  {`${selectedCustomer?.CustomerName}`}
                </Typography>
              </Grid>
              <Grid item sx={{ maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                <Typography
                  component='span'
                  display='inline'
                  sx={{ typography: { xs: 'mobileParagraphSmall', xl: 'paragraph' } }}
                >{`${selectedCustomer?.CustomerAddress}, ${selectedCustomer?.CustomerLocation} ${selectedCustomer?.CustomerZip}`}</Typography>
                <Typography
                  component='span'
                  display='inline'
                  sx={{ typography: { xs: 'mobileParagraphSmall', xl: 'paragraph' }, pr: 1, pl: 1 }}
                >
                  |
                </Typography>
                <Typography
                  component='span'
                  display='inline'
                  sx={{ typography: { xs: 'mobileParagraphSmall', xl: 'paragraph' } }}
                >{`${selectedCustomer?.OperationCompanyName} - ${selectedCustomer?.CustomerNumber}`}</Typography>
              </Grid>
            </Grid>
          )}
          {deviceType === DeviceType.Mobile && (
            <Grid
              container
              sx={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                pl: 1,
              }}
            >
              <Grid item sx={{ maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                <Typography
                  component='span'
                  display='inline'
                  sx={{ typography: { xs: 'mobileParagraphBold', xl: 'paragraphBold' } }}
                >
                  {`${selectedCustomer?.CustomerName}`}
                </Typography>
              </Grid>
              <Grid item sx={{ maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                <Typography
                  component='span'
                  display='inline'
                  sx={{ typography: { xs: 'mobileParagraphSmall', xl: 'paragraph' } }}
                >{`${selectedCustomer?.CustomerAddress}, ${selectedCustomer?.CustomerLocation} ${selectedCustomer?.CustomerZip}`}</Typography>
              </Grid>
              <Grid item sx={{ maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                <Typography
                  component='span'
                  display='inline'
                  sx={{ typography: { xs: 'mobileParagraphSmall', xl: 'paragraph' } }}
                >{`${selectedCustomer?.OperationCompanyName} - ${selectedCustomer?.CustomerNumber}`}</Typography>
              </Grid>
            </Grid>
          )}
          {props.forcedToggleState ? (
            <Icon className={props.caretUpIcon} fontSize='medium' sx={{ pr: 2, pl: 1 }} />
          ) : (
            <Icon className={props.caretDownIcon} fontSize='medium' sx={{ pr: 2, pl: 1 }} />
          )}
        </>
      )}
    </Button>
  );
};
