import { ServiceResult } from '../models/api-shared.models';
import { SearchSimilarProductsData, SearchSimilarProductsRequest } from '../models/product-catalog.models';
import ApiService from './api-service';

class SimilarProductSearchService extends ApiService {
  private static classInstance: SimilarProductSearchService;

  private constructor() {
    super('SearchSimilarProducts');
  }

  public getSimilarProducts(request: SearchSimilarProductsRequest): ServiceResult<SearchSimilarProductsData> {
    return this.post(`${this.baseUrl}/SearchSimilarProducts `, request);
  }

  public static getInstance(): SimilarProductSearchService {
    if (!this.classInstance) {
      this.classInstance = new SimilarProductSearchService();
    }
    return this.classInstance;
  }
}

export default SimilarProductSearchService;
