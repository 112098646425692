import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import {
  CustomerProductPriceResult,
  ProductDetailTransfer,
  ProductListProduct,
  UpdateCustomProductRequest,
} from '../../api';
import { APIMessages, UnitOfMeasureOrderQuantity } from '../../api/models/api-shared.models';
import {
  ProductSearchResult,
  SearchCacheEntry,
  SearchSimilarProductsData,
  SearchSimilarProductsRequest,
  SimilarCatalogProduct,
  TypeAheadResponse,
} from '../../api/models/product-catalog.models';
import { normalizeProductKey, updateProductCustomAttributesFromPayload } from '../../helpers';
import { ProductData } from '../../models';
import { createPagedEntityAdapter, EntityPageLoading, PagedEntityState } from '../paged-entity-adapter';

const replacementProductsAdapter = createEntityAdapter<ProductData>({
  selectId: (product: ProductData) => normalizeProductKey(product.ProductKey),
});

const productsAdapter = createPagedEntityAdapter<SimilarCatalogProduct>({
  selectId: (product: SimilarCatalogProduct) => normalizeProductKey(product.Product.ProductKey),
});

export interface ReplacementProducts {
  product: ProductData;
  quantity: number;
  uom: UnitOfMeasureOrderQuantity;
}

export interface ResetReplacementProducts {
  products?: ProductData[];
}

export interface SearchSimilarProductSearchState {
  apiRequest?: SearchSimilarProductsRequest;
  productSearchResult?: ProductSearchResult;
  products: PagedEntityState<SimilarCatalogProduct>;

  searchCache?: SearchCacheEntry[];
  typeAheadResults: TypeAheadResponse | undefined;
  typeAheadAPIMessages?: APIMessages;
  replacementProducts: EntityState<ProductData>;

  isReplaceDiscontinuedModalLoading: boolean;
  replaceDiscontinuedProductIsOnCustomList: boolean | undefined;
}

export const initialSimilarSearchState: SearchSimilarProductSearchState = {
  apiRequest: undefined,
  productSearchResult: undefined,
  products: productsAdapter.getInitialState(true),
  typeAheadResults: undefined,
  typeAheadAPIMessages: undefined,
  searchCache: undefined,
  replacementProducts: replacementProductsAdapter.getInitialState(),
  isReplaceDiscontinuedModalLoading: false,
  replaceDiscontinuedProductIsOnCustomList: undefined,
};
// Reducers
export const similarProductSearchSlice = createSlice({
  name: 'similarProductSearch',
  initialState: initialSimilarSearchState,
  reducers: {
    resetProductSearchResults: (state: SearchSimilarProductSearchState) => {
      state.products = initialSimilarSearchState.products;
      state.productSearchResult = initialSimilarSearchState.productSearchResult;
      state.apiRequest = initialSimilarSearchState.apiRequest;
    },
    setFromProduct: (state: SearchSimilarProductSearchState, action: PayloadAction<SimilarCatalogProduct>) => {
      productsAdapter.setLoadedPage(state.products, {
        pageIndex: 0,
        entities: [action.payload, ...selectSimilarProductSearchCatalogProductsByPage(state, 0)],
      });
    },
    setApiRequest: (
      state: SearchSimilarProductSearchState,
      action: PayloadAction<SearchSimilarProductsRequest | undefined>
    ) => {
      state.apiRequest = action.payload;
    },
    setProductSearchResults: (
      state: SearchSimilarProductSearchState,
      action: PayloadAction<{ request: SearchSimilarProductsRequest; result: SearchSimilarProductsData }>
    ) => {
      const { request, result } = action.payload;
      const productSearchResult: ProductSearchResult = {
        CurrentPageNumber: result.CurrentPageNumber,
        NumberOfPages: result.NumberOfPages,
        PageSize: result.PageSize,
        QueryText: result.QueryText,
        TotalNumberOfItems: result.TotalNumberOfItems,
        Skip: result.Skip,
        Facets: result.Facets,
        HasLoadMore: result.HasLoadMore,
      };
      state.apiRequest = request;
      state.productSearchResult = productSearchResult;

      productsAdapter.setLoadedPage(state.products, {
        pageIndex: result.CurrentPageNumber,
        entities: result.CatalogProducts,
      });
    },
    setPageLoaded: (
      state: SearchSimilarProductSearchState,
      action: PayloadAction<{ pageIndex: number; catalogProducts: SimilarCatalogProduct[] }>
    ) => {
      productsAdapter.setLoadedPage(state.products, {
        pageIndex: action.payload.pageIndex,
        entities: action.payload.catalogProducts,
      });
    },
    setPageLoading: (state: SearchSimilarProductSearchState, action: PayloadAction<EntityPageLoading>) => {
      productsAdapter.setLoadingPage(state.products, action.payload);
    },
    setTypeAheadAPIMessages: (state: SearchSimilarProductSearchState, action: PayloadAction<APIMessages>) => {
      state.typeAheadAPIMessages = action.payload;
    },
    setTypeAheadResults: (state: SearchSimilarProductSearchState, action: PayloadAction<TypeAheadResponse>) => {
      state.typeAheadResults = action.payload;
    },
    setSearchCache: (state: SearchSimilarProductSearchState, action: PayloadAction<SearchCacheEntry[]>) => {
      state.searchCache = action.payload;
    },
    setProductPrices: (state: SearchSimilarProductSearchState, action: PayloadAction<CustomerProductPriceResult[]>) => {
      action.payload.forEach((priceResult: CustomerProductPriceResult) => {
        if (priceResult.ProductKey) {
          const product = state.products.entities[normalizeProductKey(priceResult.ProductKey)];
          if (product) {
            (product.Product as ProductListProduct).PriceLoaded = true;
            product.Product.UnitOfMeasureOrderQuantities.forEach((uom: UnitOfMeasureOrderQuantity) => {
              if (uom.UnitOfMeasure === priceResult.UnitOfMeasureType) {
                uom.Price = priceResult.Price;
                if (priceResult.ProductAverageWeight > 0) {
                  uom.ProductAverageWeight = priceResult.ProductAverageWeight;
                }
              }
            });
          }
        }
      });
    },
    updateProduct: (state: SearchSimilarProductSearchState, action: PayloadAction<ProductDetailTransfer>) => {
      const product = state.products.entities[normalizeProductKey(action.payload.productKey)];
      if (product) {
        product.Product.UnitOfMeasureOrderQuantities.forEach((uom: UnitOfMeasureOrderQuantity) => {
          if (uom.UnitOfMeasure === action.payload.uom) {
            uom.ExtendedPrice = action.payload.extendedPrice;
            uom.Quantity = action.payload.quantity;
            uom.ShowOrderQuantityAlert = action.payload.showOrderQuantityAlert;
          }
        });
      }
    },
    updateReplacementProduct: (
      state: SearchSimilarProductSearchState,
      action: PayloadAction<ProductDetailTransfer>
    ) => {
      // Find existing, updated product
      const productKey = normalizeProductKey(action.payload.productKey);
      const product = state.products.entities[productKey]?.Product;
      if (!product) return;

      const updatedUoms = product.UnitOfMeasureOrderQuantities.map((uom) => {
        if (uom.UnitOfMeasure === action.payload.uom) {
          return { ...uom, ExtendedPrice: action.payload.extendedPrice, Quantity: action.payload.quantity };
        } else return uom;
      });

      if (updatedUoms.findIndex((uom) => uom.Quantity > 0) >= 0) {
        replacementProductsAdapter.upsertOne(state.replacementProducts, {
          ...product,
          UnitOfMeasureOrderQuantities: updatedUoms,
        });
      } else {
        replacementProductsAdapter.removeOne(state.replacementProducts, productKey);
      }
    },
    resetDiscontinuedReplacementProducts: (state: SearchSimilarProductSearchState) => {
      state.replacementProducts = initialSimilarSearchState.replacementProducts;
    },
    setIsReplaceDiscontinuedModalLoading: (state: SearchSimilarProductSearchState, action: PayloadAction<boolean>) => {
      state.isReplaceDiscontinuedModalLoading = action.payload;
    },
    setReplaceDiscontinuedProductIsOnCustomList: (
      state: SearchSimilarProductSearchState,
      action: PayloadAction<boolean>
    ) => {
      state.replaceDiscontinuedProductIsOnCustomList = action.payload;
    },
    updateProductCustomAttributes: (
      state: SearchSimilarProductSearchState,
      action: PayloadAction<UpdateCustomProductRequest>
    ) => {
      const product = state.products.entities[normalizeProductKey(action.payload.ProductKey)]?.Product;
      if (!product) return;

      updateProductCustomAttributesFromPayload(product, action.payload);
    },
  },
});

export const {
  selectAll: selectAllSimilarCatalogProducts,
  selectById: selectSimilarCatalogProductById,
  selectIsLoading: selectSimilarProductSearchProductListIsLoading,
  selectByPage: selectSimilarProductSearchCatalogProductsByPage,
  selectTotal: selectSimilarProductSearchTotalCatalogProducts,
} = productsAdapter.getSelectors<SearchSimilarProductSearchState>((state) => state.products);

export const {
  selectAll: selectAllSimilarProductSearchReplacementProducts,
  selectById: selectSimilarProductSearchReplacementProductById,
} = replacementProductsAdapter.getSelectors<RootState>((state) => state.similarProductSearch.replacementProducts);
