import { CheckboxProps, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { isString } from 'common/src/helpers/general/string';
import { FC, RefObject, useEffect, useRef, useState } from 'react';
import { DeviceType, useBreakpoint } from '../../../../hooks/useBreakpoint';
import { StyledCheckbox, StyledFormControlLabel, StyledIcon, StyledLabel } from './CfCheckbox.styles';
import { CfLoadingCheckbox } from './CfLoadingCheckbox';

export interface CheckboxItem {
  label: string | JSX.Element;
  checked: boolean;
  name?: string;
  additionalValue?: unknown;
  disabled?: boolean;
}

export interface CheckboxTypedItem<T> extends CheckboxItem {
  additionalValue?: T;
}

export type CfCheckboxIcon = 'check' | 'minus';
export type CfCheckboxSize = 'small' | 'medium';
export type CfCheckboxVariant = 'primary' | 'secondary';

export type CfCheckboxProps =
  | {
      item: CheckboxItem;
      onCheckedChanged: (item: CheckboxItem) => void;
      icon?: CfCheckboxIcon;
      size?: CfCheckboxSize;
      variant?: CfCheckboxVariant;
      hidden?: boolean;
      checkboxProps?: CheckboxProps;
      dataTestId?: string;
      dataAltTestId?: string;
      stopPropagation?: boolean;
      sx?: SxProps<Theme>;
      loadingSkeletonContainerSx?: never;
      loadingPlaceholder?: never;
      checkboxRef?: RefObject<HTMLInputElement>;
    }
  | {
      size?: CfCheckboxSize;
      checkboxProps?: CheckboxProps;
      sx?: SxProps<Theme>;
      loadingSkeletonContainerSx?: SxProps<Theme>;
      loadingPlaceholder: true;
    };

/**
 * There are two types
 *
 * @param item - The item in the checkbox
 * @param onCheckedChanged - Method to call when a checkbox item is checked
 * @param icon - The icon to indicate that the item is selected (check, minus)
 * @param size - The size of the checkbox (small, medium)
 * @param variant - The styling variant to use (primary, secondary)
 * @param hidden - Boolean indicating if the checkbox is hidden
 * @param checkboxProps - Additional props for the checkbox
 * @param dataTestId - Test id of the checkbox
 * @param dataAltTestId - Alternative test id of the checkbox
 * @param stopPropagation - Boolean indicating if event.stopPropagation(); should be called before handling a click
 * @param sx - Additional styling of the the checkbox
 * @param loadingPlaceholder - Boolean indicating if the loading placeholder should be showing
 *
 * ----------------------------------------------------------------------------------------
 * or
 * @param size - The size of the checkbox (small, medium)
 * @param checkboxProps - Additional props for the checkbox
 * @param sx - Additional styling of the the checkbox
 * @param loadingPlaceholder - Boolean indicating if the loading placeholder should be showing
 */
export const CfCheckbox: FC<CfCheckboxProps> = (props: CfCheckboxProps) => {
  const { deviceType } = useBreakpoint();

  const size = props.size || (deviceType === DeviceType.Desktop ? 'small' : 'medium');
  if (props.loadingPlaceholder) {
    return (
      <CfLoadingCheckbox containerSx={props.sx} skeletonContainerSx={props.loadingSkeletonContainerSx} size={size} />
    );
  }

  const icon = props.icon || 'check';

  const [isChecked, setIsChecked] = useState<boolean>(Boolean(props.item.checked));

  useEffect(() => {
    setIsChecked(Boolean(props.item.checked));
  }, [props.item.checked]);

  const handleClick = () => {
    const checked = !isChecked;
    setIsChecked(checked);
    const newItem: CheckboxItem = { ...props.item, checked };
    props.onCheckedChanged(newItem);
  };

  const checkboxSize = size === 'medium' ? 25 : 16; // px

  const primaryCheckedIcon = (
    <StyledIcon
      checkboxSize={checkboxSize}
      baseClassName='fas'
      className={`fa-${icon}`}
      fontSize='extraSmall'
      sx={{
        padding: '1.5px',
        color: (theme: Theme) => theme.palette.common.white,
        backgroundColor: (theme: Theme) => theme.palette.primary.main,
        border: (theme: Theme) => `1.5px solid ${theme.palette.primary.main}`,
      }}
    />
  );

  const secondaryCheckedIcon = (
    <StyledIcon
      checkboxSize={checkboxSize}
      baseClassName='fas'
      className={`fa-${icon}`}
      fontSize='extraSmall'
      sx={{
        color: (theme: Theme) => theme.palette.primary.main,
        backgroundColor: (theme: Theme) => theme.palette.common.white,
        border: (theme: Theme) => `1.5px solid ${theme.palette.primary.main}`,
      }}
    />
  );

  const label = isString(props.item.label) ? <StyledLabel>{props.item.label}</StyledLabel> : props.item.label ?? <></>;

  const inputRef = props.checkboxRef ?? useRef<HTMLInputElement>(null);

  return (
    <StyledFormControlLabel
      {...(props.hidden ? { sx: { display: 'none' } } : { sx: props.sx })}
      control={
        <StyledCheckbox
          inputRef={inputRef}
          checkboxSize={checkboxSize}
          data-testid={props.dataTestId}
          data-alt-testid={props.dataAltTestId}
          checked={isChecked}
          role='checkbox'
          aria-checked={isChecked}
          disabled={props.item.disabled}
          onClick={(event) => {
            if (props.stopPropagation) event.stopPropagation();
            handleClick();
          }}
          onKeyDown={(event) => {
            if (props.stopPropagation) event.stopPropagation();
            if (event.key === 'Enter') {
              handleClick();
            }
          }}
          size={size}
          color='primary'
          disableRipple
          icon={
            <StyledIcon checkboxSize={checkboxSize} baseClassName='far' className='fa-square' fontSize='extraSmall' />
          }
          indeterminateIcon={<StyledIcon checkboxSize={checkboxSize} className='fa-square' />}
          checkedIcon={props.variant === 'secondary' ? secondaryCheckedIcon : primaryCheckedIcon}
          {...props.checkboxProps}
        />
      }
      label={label}
    />
  );
};
