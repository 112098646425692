import { Box, Typography } from '@mui/material';
import { UserCustomer } from 'common';
import { StyledCustomerNameListItemDiv, StyledDiv } from './TooltopContent.styles';

export interface TooltipContentProps {
  customer: UserCustomer | undefined;
  dataTestId?: string;
}

const TooltipContent = (props: TooltipContentProps): JSX.Element => {
  return (
    <Box data-testid={props.dataTestId}>
      <StyledCustomerNameListItemDiv>
        <Typography variant='paragraphSmall'>{props.customer?.CustomerName}</Typography>
      </StyledCustomerNameListItemDiv>
      <StyledDiv>
        <Typography variant='paragraphSmall'>{props.customer?.CustomerAddress}</Typography>
      </StyledDiv>
      <StyledDiv>
        <Typography variant='paragraphSmall'>
          {props.customer?.CustomerLocation} {props.customer?.CustomerZip}
        </Typography>
      </StyledDiv>
      <StyledDiv>
        <Typography variant='paragraphSmall'>
          {props.customer?.OperationCompanyName} - {props.customer?.CustomerNumber}
        </Typography>
      </StyledDiv>
    </Box>
  );
};

export default TooltipContent;
